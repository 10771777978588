// NPM Dependencies
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import DropDown from 'common/Dropdown/DropDown';
import Input from 'common/Input/Input';

// Styles
import { FlexDiv, GenericDiv, GenericP, GenericSpan, SeachIcon } from 'commonStyles';
import { SearchIconWrapper, QueryFieldsWrapper } from './supportStyles';

// Config & Constants
import { MSR_CONFIG, SUPPORT_REQUESTS_CONFIG } from '../../config';
import { APPLICATION_CONSTANTS, FILTER_CONSTANTS } from 'AppConstants';

// Util Methods
import { getEmployeeList } from 'apis/commonApis';
import { debounce, isAlphaNumeric, isNumeric, validEmail } from 'UtilityComponents/Util';

// Redux Actions
import { fetchMSRRedirectionUrl, resetMSRDetails } from 'reduxStore/actions/bizHeaderActions';

// Omniture
import { trackMSREvent } from '../../utils';

const Support = () => {
	const dispatch = useDispatch();
	const [selectedOption, setSelectedOption] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [selectedEmail, setSelectedEmail] = useState('');
	const [suggestions, setSuggestions] = useState({ failed: false, list: [], loading: false });
	const [errorMsg, setErrorMsg] = useState('');
	const [expandMSR, setExpandMSR] = useState(false);
	const componentRef = useRef(null);
	const msrRedirection = useSelector((state) => state?.bizHeader?.msrRedirectionUrl);
	const { details: msrDetails = {}, error: msrError = false } = msrRedirection;
	const isSearchEnabled = searchValue && selectedOption[SUPPORT_REQUESTS_CONFIG.value];

	/**
	 * HandleSelection
	 *
	 * @author - MMT11933
	 * @description - This function is used to handle the selection of the dropdown
	 * @param {Object} selection - The selection object
	 * @return {void}
	 */
	const handleSelection = (selection) => {
		setSelectedOption(selection);
		setSuggestions({ failed: false, list: [], loading: false });
		errorMsg && dispatch(resetMSRDetails());
		setSearchValue('');
		setErrorMsg('');
	};

	/**
	 * HandleEmailSelection
	 *
	 * @author - MMT11933
	 * @description - This function is used to handle the email selection from dropdown
	 * @param {Object} selection - The selection object
	 * @return {void}
	 */
	const handleEmailSelection = (selection) => {
		setSelectedEmail(selection);
		setSearchValue(selection?.value);
	};

	/**
	 * ChangeHandler
	 *
	 * @author - MMT11933
	 * @description - This function is used to handle the change in the input field
	 * @param {Object} event
	 * @return {void}
	 */
	const changeHandler = (event) => {
		setSearchValue(event.target.value);
		errorMsg && dispatch(resetMSRDetails());
		setErrorMsg('');
	};

	/**
	 * UserSearch
	 *
	 * @author - MMT11933
	 * @description- Debounced search function to query employee list based on the search string.
	 * It triggers an API call if the search string is longer than 2 characters.
	 * Upon receiving the response, it updates the suggestions state with the new list of employees,
	 * and handles loading and error states accordingly.
	 * @param {string} searchString The input string used for querying the employee list.
	 * @return {void}
 */
	const userSearch =
		debounce(async (searchString) => {
			if (searchString && searchString.length > SUPPORT_REQUESTS_CONFIG.minSearchLength) {
				setSuggestions((suggestions) =>({
					...suggestions,
					loading: true,
					failed: false
				}));

				const response = await getEmployeeList(searchString);
				setSuggestions((suggestions) =>({
					...suggestions,
					loading: false,
					list: response,
					failed: !response
				}));
			}
		}, 500);

	/**
	 * SearchClickHandler
	 *
	 * @author - MMT11933
	 * @description - This function is used to handle the search click
	 * @param {Boolean} viewAll - This is a boolean value to check if the view all is clicked
	 * @return {void}
	 */
	const searchClickHandler = (viewAll = false) => {
		let reqBody = {}; let isValidSearch = true; let trackEvent;

		if (viewAll) {
			reqBody = SUPPORT_REQUESTS_CONFIG.viewAllRequest;
			trackEvent = SUPPORT_REQUESTS_CONFIG.omnitureConfig.props.viewAll;
		} else {
			reqBody = {
				searchKey: selectedOption?.key,
				searchValue: searchValue
			};

			switch (selectedOption[SUPPORT_REQUESTS_CONFIG.value]) {
				case SUPPORT_REQUESTS_CONFIG.emailOption:
					isValidSearch = validEmail(searchValue);
					!isValidSearch && setErrorMsg(SUPPORT_REQUESTS_CONFIG.emailError);
					trackEvent = SUPPORT_REQUESTS_CONFIG.omnitureConfig.props.email;
					break;
				case SUPPORT_REQUESTS_CONFIG.bookingOption:
					isValidSearch = isAlphaNumeric(searchValue);
					!isValidSearch && setErrorMsg(SUPPORT_REQUESTS_CONFIG.bookingIdError);
					trackEvent = SUPPORT_REQUESTS_CONFIG.omnitureConfig.props.booking;
					break;
				case SUPPORT_REQUESTS_CONFIG.requestOption:
					isValidSearch = isNumeric(searchValue);
					!isValidSearch && setErrorMsg(SUPPORT_REQUESTS_CONFIG.requestIdError);
					trackEvent = SUPPORT_REQUESTS_CONFIG.omnitureConfig.props.service;
					break;
				case SUPPORT_REQUESTS_CONFIG.nameOption:
					trackEvent = SUPPORT_REQUESTS_CONFIG.omnitureConfig.props.name;
				default:
					break;
			}
		}

		/* Omniture Event */
		trackMSREvent(trackEvent);

		if (isValidSearch) {
			dispatch(fetchMSRRedirectionUrl(reqBody));
		}
	};

	/**
	 * FormatOptionLabel
	 *
	 * @author - MMT11933
	 * @description - Formats the option label for display in a select component. This function
	 * returns JSX elements that include both the name and ID of an option.
	 * The name is displayed with a 'name' class and the ID with an 'email' class.
	 * @param {Object} label The object containing the name and ID to be formatted.
	 * @return {JSX.Element} A fragment containing two paragraphs: one for the name and one for the ID.
	 */
	const formatOptionLabel = (label) => (
		<>
			<GenericP className='name' color='#4A4A4A' fsize='12px' $lHeight='16px' f_weight='900' bottom_M='4px'>{label.name}</GenericP>
			<GenericP className='email' color='#4A4A4A' fsize='12px' $lHeight='16px'>{label.id}</GenericP>
		</>
	);

	/**
	 * RenderQueryFields
	 *
	 * @author - MMT11933
	 * @description - Renders form fields based on the selected option within a support request form. It dynamically generates form fields
	 * such as dropdowns or input fields based on the configuration provided by `SUPPORT_REQUESTS_CONFIG.fieldsConfig`. This configuration
	 * depends on the currently selected option in the form. The function utilizes various utilities and configurations to determine the
	 * appropriate field type and its properties, then returns the corresponding JSX element for rendering.
	 * @return {JSX.Element} A JSX element representing the form field to be rendered, either a Dropdown or an Input field, based on the selected option's configuration.
	*/
	const renderQueryFields = () => {
		const utils = { handleEmailSelection,
			changeHandler,
			suggestions,
			selectedEmail,
			formatOptionLabel,
			selectedOption,
			SUPPORT_REQUESTS_CONFIG,
			userSearch,
			searchValue,
			loadingMessage: suggestions.loading && SUPPORT_REQUESTS_CONFIG.loadingText };

		const config = SUPPORT_REQUESTS_CONFIG.fieldsConfig(utils);

		const { type, props } = config[selectedOption[SUPPORT_REQUESTS_CONFIG.value]] || config.default;

		if (type === FILTER_CONSTANTS.DROPDOWN) {
			return (
				<div className='nameInput'>
					<DropDown {...props} />
				</div>
			);
		} else if (type === APPLICATION_CONSTANTS.INPUT_FIELD) {
			return <Input {...props} />;
		}
	};

	/**
	 * ToggleExpansion
	 *
	 * @author - MMT11933
	 * @description - Toggles the expansion state of a UI component, such as a dropdown, accordion, or any expandable container.
   * This function is typically called in response to a user interaction, such as clicking on an expand/collapse button.
   * It should update the component's state to reflect the new expansion state, which will then be used to control
   * the visibility or display of the expandable content.
   * @return {void}
  */
	const toggleExpansion = () => {
		setExpandMSR(!expandMSR);
		searchValue && setSearchValue('');
		if (errorMsg) {
			setErrorMsg('');
			dispatch(resetMSRDetails());
		}

		/* Omniture Event */
		trackMSREvent(SUPPORT_REQUESTS_CONFIG.omnitureConfig.props.support);
	};

	useEffect(() => {
		/**
		 * CloseOnOutsideClick
		 * @author - MMT11933
		 * @description - Closes the msr component when a click occurs outside of it.
		 * This function should be attached to a click event listener that monitors clicks throughout the document.
		 * When a click is detected outside the component's boundaries, this function triggers the logic to close the component.
		 * @param {Event} event - The DOM event triggered by a click.
		 * @return {void} - This function does not return a value.
		*/
		const closeOnOutsideClick = (event) => {
			if (componentRef.current && !componentRef.current.contains(event.target)) {
				setExpandMSR(false); // To close/remove the component
			}
		};

		// Attach event listener
		document.addEventListener('mousedown', closeOnOutsideClick);

		// Cleanup function to remove the event listener
		return () => {
			document.removeEventListener('mousedown', closeOnOutsideClick);
		};
	}, [expandMSR]);

	useEffect(()=>{
		if (Object.keys(msrError).length !== 0) {
			const { message = '' } = msrError;
			setErrorMsg(message);

			let trackEvent;
			switch (selectedOption[SUPPORT_REQUESTS_CONFIG.value]) {
				case SUPPORT_REQUESTS_CONFIG.bookingOption:
					trackEvent = SUPPORT_REQUESTS_CONFIG.omnitureConfig.evars.booking;
					break;
				case SUPPORT_REQUESTS_CONFIG.requestOption:
					trackEvent = SUPPORT_REQUESTS_CONFIG.omnitureConfig.evars.service;
					break;
				case SUPPORT_REQUESTS_CONFIG.emailOption:
					trackEvent = SUPPORT_REQUESTS_CONFIG.omnitureConfig.evars.email;
					break;
				case SUPPORT_REQUESTS_CONFIG.nameOption:
					trackEvent = SUPPORT_REQUESTS_CONFIG.omnitureConfig.evars.name;
					break;
				default:
					break;
			}

			/* Omniture Event */
			trackMSREvent(trackEvent, 'eVar22');
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [msrDetails, msrError]);

	return (
		<>
			<GenericSpan fsize='12px' color='#4A4A4A' $cursor='pointer' onClick={toggleExpansion}>{MSR_CONFIG.text}</GenericSpan>
			{expandMSR && (
				<GenericDiv
					$bg='#fff' position='absolute' $width='425px'
					bdrRad='12px' boxShadow='0 2px 16px 0 rgba(0,0,0,0.2)'
					padding='16px' $top='38px' $left='8px' $zIndex='11'
					ref={componentRef}>
					<GenericSpan
						position='absolute' $top='-7px' $right='90%'
						$height='15px' $width='15px' $opacity='1' $transform='rotate(45deg)'
						$border='1px solid rgb(241, 241, 241)' $borderWidth='1px 0 0 1px' $bg='rgb(255, 255, 255)'
					/>
					<GenericP bottom_M='12' color='#000000' fsize='14px' f_weight='900' $lHeight='20px'>{SUPPORT_REQUESTS_CONFIG.heading}</GenericP>
					<FlexDiv justify='space-between'>
						<GenericP color='#000000' fsize='12px' right_M='20' $lHeight='16px'>{SUPPORT_REQUESTS_CONFIG.searchText}</GenericP>
						<GenericP
							color='#008CFF' fsize='12px' f_weight='700'
							$cursor='pointer' $lHeight='16px'
							onClick={() => searchClickHandler(true)}>
							{SUPPORT_REQUESTS_CONFIG.viewAll}
						</GenericP>
					</FlexDiv>
					<GenericP color='#000000' fsize='12px'>{SUPPORT_REQUESTS_CONFIG.orgText}</GenericP>
					<QueryFieldsWrapper top_M='8' bdrRad='4px'>
						<DropDown
							handleChange={handleSelection}
							field={{ options: SUPPORT_REQUESTS_CONFIG.options, selectedValue: selectedOption[SUPPORT_REQUESTS_CONFIG.value] }}
							value={selectedOption}
							customStyles={SUPPORT_REQUESTS_CONFIG.selectFieldStyles}
						/>
						{renderQueryFields()}
						<SearchIconWrapper
							bdr='1px solid #E3E7EB'
							bdrRad='0 4px 4px 0'
							height='32px'
							width='32px'
							position='relative'
							onClick={() => isSearchEnabled && searchClickHandler()}
							isActive={isSearchEnabled}>
							<SeachIcon $left='8px' $top='7px' $whiteIcon />
						</SearchIconWrapper>
					</QueryFieldsWrapper>
					{errorMsg && <GenericP color='#eb2026' fsize='12px' top_M='8'>{errorMsg}</GenericP>}
				</GenericDiv>
			)}
		</>
	);
};

export default Support;
