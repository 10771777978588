import styled, { css } from 'styled-components';

import { ListWrapper } from 'common/ListStyles';
import { SummaryIcons } from '../../../reporting/pages/summary/Styles';

export const ListingWrapper = styled(ListWrapper)`
	& .report_cell_head {
		font-style: normal;
		font-weight: 500;
		text-transform: uppercase;
		color: #9B9B9B;
	}

	& .report_table {
    box-shadow: none;
    background: #fff;
    position: relative;
	}

	& .report_cell {
		font-size: 12px;
	}
`;

export const LobIcons = styled(SummaryIcons) `
background-size: 200px 200px;
${(props) => {
		switch (props.icon) {
			case 'flt':
				return css`
				background-position: -1px -77px;
				width: 24px;
				height: 15px;`;
			case 'htl':
				return css`
				background-position: -41px -74px;
				width: 20px;
				height: 21px;`;
			case 'ic_bus':
				return css`
				background-position: -171px -70px;
				width: 15px;
				height: 15px;
 `;
			case 'ic_cab':
				return css`
				background-position: -171px -85px;
				width: 18px;
				height: 16px;`;
			case 'ic_requisition':
				return css`
				background-position: -117px -42px;
				width: 31px;
				height: 25px;`;
			default:
				return css`
				background-position: -41px -74px;
				width: 20px;
				height: 21px;`;
		}
	}}
`;
