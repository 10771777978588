import formatOptionLabel from '../policy/components/optionLabel';

export const CONSTANTS = {
	REQUIRED_ERROR_MESSAGE: 'Required',
	GENERIC_ERROR_MESSAGE: 'We’re facing some technical issue. Please retry in sometime.',
	MINIMUM_VALUES_MESSAGE: 'Minimum 1 value is required!!.',
	ORGANIZATION_LEVEL_GROUP_ID: '-9',
	GROUP_ID_URL_PARAM: 'group_id',
	ORG_ID_URL_PARAM: 'orgId',
	TRIP_TAG_UUID_URL_PARAM: 'trip_tag_uuid',
	SUCCESS_MESSAGE: 'Trip Tag is saved successfully',
	ERROR_MODAL_TYPE: 'Error',
	INFO_MODAL_TYPE: 'info',
	CSV_MODAL_TYPE: 'Csv',
	LEAVE_MODAL_TYPE: 'Leave',
	DELETE_MODAL_TYPE: 'delete',
	REQUISITION_INFO_MODAL: 'requisitionInfo',
	APPROVAL_MATRIX_MODAL: 'approvalMatrix',
	APPROVAL_MATRIX_MAP_MODAL: 'approvalMatrixMap',
	LANDING_PAGE_NAME: 'mybusinessadmin:triptags:landing_new_v3|corporate',
	CREATE_PAGE_NAME: 'mybusinessadmin:triptags:create_new_v3|corporate',
	EDIT_PAGE_NAME: 'mybusinessadmin:triptags:edit_new_v3|corporate',
	USER_COUCH_KEY: 'couchKey',
	USER_MMT_AUTH: 'mmt-auth',
	TRIP_TAGE_MAX_LENGTH: 250,
	SAVE_AND_CONTINUE: 'SAVE & CONTINUE',
	PROCEED_AND_CONTINUE: 'PROCEED TO APPROVAL MATRIX',
	GO_BACK: 'GO BACK',
	ANOTHER_LOB_SELECTED_MSG: 'Approval based trip tags can be used only on Requisition. Kindly disable Approval Matrix to select other options.',
	APPROVAL_MATRIX_HEADING: 'Select the values for which approval matrix is to be set',
	NON_AVAILABLE_MSG: 'Some Approver values are missing in the uploaded csv. Approver for these values will be set to default approver',
	ENDING_POINT_CSV: 'Instructions:',
	SEARCH_HEADING: 'Search For Trip Value'
};

export const TRIP_LOBS = {
	HOTEL: 'HOTEL',
	FLIGHT: 'FLIGHT',
	CABS: 'CABS',
	BUS: 'BUS',
	REQUISITION: 'REQUISITION',
	TRAIN: 'TRAIN'
};

export const INPUT_TYPE_VALUES = {
	DropDown: 'DropDown',
	MultiSelect: 'MultiSelect',
	FreeText: 'FreeText'
};

export const LIMIT = {
	CHECKBOX_MAX_LIMIT: 5,
	RADIO_MAX_LIMIT: 5,
	MIN_LIMIT: 1
};

export const SampleData1 = [
	'1 Values', '2 Values', '3 Values', '4 Values', '5 Values', '6 Values'
];

export const SampleData2 = [
	'Head Office - Mumbai', 'Sales Office - Delhi', 'R&D Center - Bangalore'
];

export const DummyTripTagAttributeList = [
	{
		'attributeId': '673',
		'attributeName': '[Sample Trip Tag] Project Name',
		'attributeDescription': 'Description - Select the name of the project that you are travelling for',
		'attributePossibleValues': SampleData1,
		'attributeType': 'DropDown',
		'mandatoryCheck': true,
		'lobs': [
			'FLIGHT',
			'HOTEL'
		],
		'lastUpdatedOn': '2020-05-08 22:09:38',
		'lastUpdatedBy': 'abcdef@gmail.com',
		'tripTagUUID': 'ass112d-bbdasda-3dsdasd-ghhuty'
	},
	{
		'attributeId': '674',
		'attributeName': '[Sample Trip Tag] Branch Name',
		'attributeDescription': 'Select your office location',
		'attributePossibleValues': SampleData2,
		'attributeType': 'RadioButton',
		'mandatoryCheck': true,
		'lobs': [
			'FLIGHT',
			'HOTEL'
		],
		'lastUpdatedOn': '2020-05-08 22:09:38',
		'lastUpdatedBy': 'abcdef@gmail.com',
		'tripTagUUID': 'ass112d-bbdasda-3dsdasd-ghhuty'
	}
];

export const contentTripTag = {
	fieldName: {
		heading: 'Trip Tag Name',
		subHeading: 'This is seen while bookings are made. It cannot be changed later.'
	},
	fieldDesc: {
		heading: 'Description'
	},
	fieldLob: {
		heading: 'Applicable on'
	},
	fieldMandatoryCheck: {
		heading: 'Set Trip Tag as Mandatory',
		subHeading: 'Setting Trip Tag as mandatory will allow employees to book only when they have selected a tag name.'
	},
	enableAprovalmatrix: {
		heading: 'Enable Approval Matrix ',
		subHeading: 'Enabling this option will allow you to set customised approval matrix on the basis of trip tags selected by the user.',
		rankContent: 'The priority of a trip tag sets the order of the approval matrix in case of multiple trip tags with approval matrix.'
	},
	fieldType: {
		heading: 'Input Type'
	},
	fieldPossibleValues: {
		heading: 'Values for Trip Tag',
		subHeading: 'If you would like to enter multiple options in a single go, upload data on excel sheet.'
	}
};

export const TRIP_TAG_FIELDS = {
	ATTRIBUTE_NAME: 'attributeName',
	ATTRIBUTE_DESCRIPTION: 'attributeDescription',
	ATTRIBUTE_TYPE: 'attributeType',
	ATTRIBUTE_POSSIBLE_VALUES: 'attributePossibleValues',
	ATTRIBUTE_POSSIBLE_VALUES_V2: 'attributePossibleValuesV2',
	MANDATORY_CHECK: 'mandatoryCheck',
	ENABLE_APPROVAL_MATRIX: 'approvalMatrixEnabled',
	DEFINE_APPROVAL_MATRIX: 'defineApprovalMatrix',
	LOBS: 'lobs',
	LAST_UPDATED_ON: 'lastUpdatedOn',
	LAST_UPDATED_BY: 'lastUpdatedBy',
	TRIP_TAG_UUID: 'tripTagUUID'

};

export const TRIP_TAG_TYPES = {
	TEXT: 'text',
	TEXTAREA: 'textArea',
	CHECKBOX: 'checkbox',
	DROPDOWN: 'dropdown',
	SwitchBtn: 'switchBtn'
};

export const DropDownListData = [
	{
		inpVal: 'Single Select - one option can be selected',
		SelctInpVal: 'DropDown',
		value: INPUT_TYPE_VALUES.DropDown,
		icon: 'radioBtnIcon'
	},
	{
		inpVal: 'Multi Select - many options can be selected',
		SelctInpVal: 'MultiSelect',
		value: INPUT_TYPE_VALUES.MultiSelect,
		icon: 'chkBoxIcon'
	},
	{
		inpVal: 'Text Box - free text to be entered',
		SelctInpVal: 'FreeText',
		icon: 'freeTxtIcon',
		value: INPUT_TYPE_VALUES.FreeText
	}
];

export const approvalMatrixField = {
	name: TRIP_TAG_FIELDS.ENABLE_APPROVAL_MATRIX,
	type: TRIP_TAG_TYPES.SwitchBtn,
	error: '',
	value: false
};

export const tripForm = [
	{
		name: TRIP_TAG_FIELDS.ATTRIBUTE_NAME,
		type: TRIP_TAG_TYPES.TEXT,
		error: '',
		value: '',
		placeholder: 'Field Name',
		maxLength: 50,
		isEditable: 'true'
	},
	{
		name: TRIP_TAG_FIELDS.ATTRIBUTE_DESCRIPTION,
		type: TRIP_TAG_TYPES.TEXTAREA,
		error: '',
		value: '',
		placeholder: 'Enter trip tag description. This will be visible to employees while booking.',
		maxLength: 250
	},
	{
		name: TRIP_TAG_FIELDS.LOBS,
		type: TRIP_TAG_TYPES.CHECKBOX,
		error: '',
		value: null,
		options: [
			{
				name: TRIP_LOBS.HOTEL,
				status: '',
				checked: false
			},
			{
				name: TRIP_LOBS.FLIGHT,
				status: '',
				checked: false
			},
			{
				name: TRIP_LOBS.CABS,
				status: '',
				checked: false
			},
			{
				name: TRIP_LOBS.BUS,
				status: '',
				checked: false
			},
			{
				name: TRIP_LOBS.REQUISITION,
				status: '',
				checked: false
			},
			{
				name: TRIP_LOBS.TRAIN,
				status: '',
				checked: false
			}
		]
	},
	{
		name: TRIP_TAG_FIELDS.MANDATORY_CHECK,
		type: TRIP_TAG_TYPES.SwitchBtn,
		error: '',
		value: false
	},
	{ ...approvalMatrixField },
	{
		name: TRIP_TAG_FIELDS.ATTRIBUTE_TYPE,
		type: TRIP_TAG_TYPES.DROPDOWN,
		error: '',
		value: 'DropDown',
		options: DropDownListData,
		childNode: [],
		showChild: false
	},
	{
		name: TRIP_TAG_FIELDS.TRIP_TAG_UUID,
		type: TRIP_TAG_FIELDS.TRIP_TAG_UUID,
		value: ''
	}

];

export const childForm = {
	name: TRIP_TAG_FIELDS.ATTRIBUTE_POSSIBLE_VALUES,
	type: TRIP_TAG_TYPES.TEXT,
	error: '',
	value: '',
	placeholder: 'Possible Value',
	maxLength: 50
};

export const FIELD_TYPE = {
	DROPDOWN: 'DropDown',
	RADIOBUTTON: 'RadioButton',
	MULTITEXT: 'MultiSelect',
	FREETEXT: 'FreeText',
	CHECKBOX: 'CheckBox'
};

export const existingTripTagResp = {
	'editTripTagRequest': {
		'attributeName': 'Is client billing',
		'attributeDescription': 'New Description',
		'attributeType': 'DropDown',
		'attributePossibleValues': [
			'aa',
			'bb',
			'cc'
		],
		'mandatoryCheck': 'No',
		'lobs': [
			'HOTEL'
		]
	}
};

export const TRIP_DATA = {
	TRIP_HEADING: 'How this Trip Tag will look to the employee',
	ATTRIBUTE_NAME: 'Trip Tag Name',
	ATTRIBUTE_DESCRIPTION: 'Description will appear here'
};

export const rankOptions = [
	{ value: 1, label: 'Priority 1' },
	{ value: 2, label: 'Priority 2' },
	{ value: 3, label: 'Priority 3' }
];

export const managerOptions = [
	{
		'icon': 'lock',
		'value': 1,
		'label': 'Approval required from single manager'
	},
	{
		'icon': 'lock',
		'value': 2,
		'label': 'Approval required from two manager'
	},
	{
		'icon': 'lock',
		'value': 3,
		'label': 'Approval required from three manager'
	},
	{
		'icon': 'lock',
		'value': 4,
		'label': 'Approval required from four manager'
	}
];

export const rankFieldConf = {
	isMulti: false,
	label: '',
	outLabel: true,
	isMandatory: false,
	value: rankOptions[0],
	name: 'rankType',
	key: 'rankType',
	type: 'select',
	trackVal: '',
	options: rankOptions
};

export const enableMatrixManagerField = {
	'type': 'dropdown',
	'width': '65%',
	'isMulti': false,
	'placeholder': 'Select no of managers',
	'formatOptionLabel': formatOptionLabel,
	'options': managerOptions,
	'hideSelectedOptions': false,
	'icon': 'class-name',
	'value': managerOptions[0]
};

export const tripValueConfigOption = {
	name: '',
	value: false,
	placeHolder: '',
	options: [],
	managers: [],
	type: 'checkbox',
	error: '',
	customClass: 'boldText zeroMargin tripTag'
};

export const managerEmailOption = {
	id: '',
	label: '',
	name: '',
	subText: '',
	tabIndex: 1,
	value: ''
};

export const POSSIBLE_MANAGERS = ['Manager 1', 'Manager 2', 'Manager 3', 'Manager 4', 'Manager 5'];

export const managerList = [
	{
		'fkey': 'mgr1_val',
		'icon': 'mgr_add_icon',
		'value': '1',
		'label': 'Manager 1',
		'iconText': '1',
		'tabIndex': 0
	},
	{
		'fkey': 'mgr2_val',
		'icon': 'mgr_add_icon',
		'value': '2',
		'label': 'Manager 2',
		'iconText': '2'
	},
	{
		'fkey': 'mgr3_val',
		'icon': 'mgr_add_icon',
		'value': '3',
		'label': 'Manager 3',
		'iconText': '3'
	},
	{
		'fkey': 'mgr4_val',
		'icon': 'mgr_add_icon',
		'value': '4',
		'label': 'Manager 4',
		'iconText': '4'
	},
	{
		'fkey': 'mgr5_val',
		'icon': 'mgr_add_icon',
		'value': '5',
		'label': 'Manager 5',
		'iconText': '5'
	}
];

export const tripManagerConfigOption = {
	'type': 'tabDropdown',
	'isMulti': false,
	'data': [
		{
			'tabName': 'manager',
			'isSearchable': false,
			'key': 'mgr',
			'list': managerList
		},
		{
			'tabName': 'employee',
			'isSearchable': true,
			'key': 'emp',
			'list': []
		}
	],
	'name': 'approving_managers_in_policy',
	'key': 'approving_managers_in_policy',
	'hideSelectedOptions': false,
	// 'icon': 'class-name',
	'dynamic': true,
	'subKey': 'all',
	// 'repeatCount': 4,
	'hide': false,
	'value': null,
	'selectedValue': null
};

export const DEFINE_MATRIX_CONSTANTS = {
	heading: 'Defining Approval Matrix',
	subHeading: `You have defined approval matrix for 4 values of the Trip Tag  #Travel for-GSTN.
	which are shown with a checkmark in the list for values. You can modify it with the button below.`,
	ctaEdit: 'EDIT MATRIX',
	ctaCreate: 'DEFINE MATRIX'
};

export const UPLOAD_CSV_CONSTANTS = {
	ADD_VALUES_HEADING: 'Add Multiple Values',
	STEP_1: 'Step 1 - Download the sample spreadsheet to add values',
	DOWNLOAD_CSV: 'Download CSV',
	STEP_2: 'Step 2 - Upload updated .csv file',
	ATTACH_CSV: 'Attach CSV'
};

export const CsvLinkStyle = { color: '#f17531',
	textDecoration: 'none',
	border: '1px solid rgba(241, 117, 49, 0.5)',
	borderRadius: '5px',
	padding: '13px 20px',
	display: 'inline-block' };

export const ERROR_FIELD_MAP = {
	[TRIP_TAG_FIELDS.ATTRIBUTE_NAME]: 'Trip Tag Name',
	[TRIP_TAG_FIELDS.ATTRIBUTE_DESCRIPTION]: 'Description',
	[TRIP_TAG_FIELDS.LOBS]: 'Applicable on',
	[TRIP_TAG_FIELDS.ATTRIBUTE_POSSIBLE_VALUES]: 'Values for Trip Tag',
	[TRIP_TAG_FIELDS.ENABLE_APPROVAL_MATRIX]: `Approval matrix has been enabled but not set for any trip tag value. 
			Kindly click on ‘Edit Approval Matrix’ to set approval matrix or Disable Approval Matrix 
			to save the trip tag.`,
	[TRIP_TAG_FIELDS.TRIP_TAG_UUID]: ''
};

export const APPROVAL_MATRIX_CONFIG = [{
	heading: 'In Policy Domestic Travel Requests',
	lob: 'IP',
	inPolicy: true
}, {
	heading: 'Out of Policy Domestic Travel Requests',
	lob: 'OOP',
	inPolicy: false
}, {
	heading: 'In Policy International Travel Requests',
	lob: 'INTL_IP',
	inPolicy: true
}, {
	heading: 'Out of Policy International Travel Requests',
	lob: 'INTL_OOP',
	inPolicy: false
}];

export const MGR_COUNTS = {
	['IP']: [],
	['OOP']: [],
	['INTL_IP']: [],
	['INTL_OOP']: []
};
