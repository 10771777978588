import { getAxiosConfig } from '../apisSetup/apiConfig';
import { EMPLOYEE_CONSTANTS, PATHS } from 'AppConstants';

const ccbAxiosApiCaller = getAxiosConfig(PATHS.CCB_URL, true, {
}, false);

export const getEmpList = (apidata, isSearch) => {
	const api = isSearch ? PATHS.SEARCH_EMPLOYEE_LIST : PATHS.GET_EMPLOYEE_LIST;
	return ccbAxiosApiCaller.post(api, apidata);
};

export const updateBulkData = (apidata) => ccbAxiosApiCaller.put(PATHS.BULK_UPDATE_EMP, apidata);

export const downloadEmpData = (apidata) => ccbAxiosApiCaller.post(PATHS.DWL_EMP_DATA, apidata);

export const downloadCSv = (apidata) => ccbAxiosApiCaller.get(PATHS.DWL_EMP_JOB, { params: apidata });

export const getUpdateStatusApi = (apidata) => ccbAxiosApiCaller.post(PATHS.UPDATE_POLL, apidata);

export const updateJobStatus = (apidata) => ccbAxiosApiCaller.post(PATHS.UPDATE_JOB_STATUS, apidata);

export const downloadDataFromurl = (apidata) => ccbAxiosApiCaller.get(PATHS.DWL_MAILER_URL, { params: apidata });

export const getInviteChannel = (apidata) => ccbAxiosApiCaller.get(PATHS.INVITE_CHANNELS);

export const getHrmsToken = async (orgId) => {
	try {
		const savedToken = JSON.parse(localStorage.getItem(EMPLOYEE_CONSTANTS.HRMS_TOKEN_KEY))?.[orgId];
		if (savedToken && savedToken.access_token && savedToken.expiry > new Date().getTime()) {
			return Promise.resolve(savedToken.access_token);
		}
	} catch (error) {
		console.error(error);
	}
	const response = await ccbAxiosApiCaller.get(PATHS.GET_PARTNER_HRMS_TOKEN);
	if (response && response.data && response.data.access_token) {
		localStorage.setItem(EMPLOYEE_CONSTANTS.HRMS_TOKEN_KEY, JSON.stringify({ [orgId]: { ...response.data, expiry: new Date().getTime() + response.data.exp * 1000 } }));
		return Promise.resolve(response.data.access_token);
	} else {
		throw ({ response });
	}
};

export const getHrmsStatusApi = () => ccbAxiosApiCaller.get(PATHS.GET_HRMS_STATUS);

export const updateInviteChannel = (apidata) => ccbAxiosApiCaller.post(PATHS.INVITE_CHANNELS, apidata);
