import styled from 'styled-components';
import { GenericP } from 'commonStyles';

export const HelpWrapper = styled.div`
    position: relative;
		@media(max-width: 768px){
			padding : 20px;
		}
`;

export const HelpSection = styled.a`
    & img{width: 24px; height: 24px;}
    background-color: rgba(127, 127, 127, 0.05);
    border-radius:4px;
    display: flex; 
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    float: left;
    margin-right: 10px;

`;

export const HelpDropdown = styled.div`
    background: #fff;
    position: absolute;
    width: 270px;
    border-radius: 6px;
    z-index: 11;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.2);
    top: 59px;
    left: -107px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 35px;
    
    & .tipIcon{
      position: absolute;
      top: -7px;
      right: 50%;
      width: 15px;
      height: 15px;
      transform: rotate(45deg);
      opacity: 1;
      border-left: 1px solid rgb(241, 241, 241);
      border-top: 1px solid rgb(241, 241, 241);
      background: rgb(255, 255, 255);
    }
`;
export const RequestButton = styled.a`
    border-radius: 34px;
    box-shadow: 0 1px 7px 0 rgba(0,0,0,0.2);
    background-color: #ffffff;
    color: #f17531;
    font-size: 16px;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    margin-bottom: 12px;
`;
export const RequestShared = styled.p`
    margin-top: 12px;
    border-radius: 6px;
    background-color: rgba(36, 153, 149, 0.1);
    color: #249995;
    font-size: 12px;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    margin-bottom: 12px;
`;
export const CallbackInputText = styled.input`
    height: 41px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: solid 1px rgba(255, 102, 75, 0.5);
    background-color: #ffffff;
    padding: 0 15px;
    outline: 0;
    font-size: 14px;
    font-weight: 400;
    color: #4a4a4a;
    font-family: lato;
    width: 100%;
    padding-left: ${(props) => props.paddingLeft ? props.paddingLeft : '15'}px; 
`;

export const RadioWithTextArea = styled.textarea`
    height: 74px;
    border-radius: 4px;
    border: 1px #e8e8e8 solid;
    padding: 15px;
    outline: 0;
    font-size: 14px;
    font-weight: 400;
    color: #4a4a4a;
    font-family: lato;
    width: 100%;
    resize: none;
    font-weight: 700;
    `;

export const OkGotItCta = styled.a`
    font-weight: 700;
    font-size:16px; 
    cursor: pointer;
    line-height:16px;
    `;

export const GenericModalDiv = styled.div`
    max-width: 370px;
    min-height: 470px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
`;

export const GenericModalImg = styled.img`
max-width: 100%;
height: 180px;
`;

export const PhoneSpan = styled.span`
position: absolute;
text-align: center;
position: absolute;
left: 5px;
top: 12px;
height: 41px;
font-size: 14px;
font-weight: 700;
color: #9b9b9b;
`;

export const HelpModalWrapper = styled.div`
	& .materialInput {
		margin-bottom: 0px;
	}
	& .helpButton {
		box-shadow: rgb(0 0 0 / 20%) 0px 1px 7px 0px;
		border: unset;
    border: 0px;
		&.disableRqstBtn {
			background-image: unset;
			background-color: rgb(0, 0, 0);
			opacity: 0.2;
		}
	}
	@media(max-width: 768px){
		padding : 20px;
		margin-top: 40px;
	}
`;

export const LnBehind = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: 10px;
  color: #9b9b9b;
  margin-bottom: 10px;
  width: 100%;
  margin: 0 auto 10px;
  &:before {
    border-top: 1px solid #e6e6e6;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 95%;
    z-index: -1;
  }
  & span {
    background: #ffffff;
    padding: 0 9px;
  }
`;

export const WhatsAppFooter = styled(GenericP)`
	background: rgba(15, 157, 88, 0.1);
	border: 1px solid #0F9D58;
	border-radius: 4px;
	color: #0F9D58;
	padding: 12px 12px 12px 8px;
	img {
		width: 22px;
		height: 22px;
		vertical-align: middle;
	}
`;

export const BackButton = styled.div `
 position: relative;
 width: 1em;
 height: 14px;
 font-size: 20px;
 padding-left: 15px;
 margin-bottom: 20px;
 cursor: pointer;
 .back-arrow {
	 position: absolute;
   top: 0px;
   left: 0;
	 height: 14px;
	 background-size: 400px 400px;
	 background-position: -11px -7px;
   right: auto;
	 transform: rotateZ(180deg);
 }
 width: 12%;
`;

export const WhatsappCallBackFooter = styled(GenericP)`
	border-top: 1px solid #D8D8D8;
`;
