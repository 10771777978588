import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import { APPLICATION_CONSTANTS, FILTER_CONSTANTS, MRB_CONSTANTS, LOB_TYPES } from 'AppConstants';
import { customLocation, dateFilterConfig, destinationField, MRB_FILTERS_CONSTANTS, filterTypes, TRAVEL_REQUEST_CONFIG, ICON_MAP, MRB_FILTER_CONFIG, DATE_OF_TRAVEL_CONFIG } from '../manageRequestBookings/config';
import { updateFltClickCount, updateAppliedFilterObj } from 'UtilityComponents/filterUtils';
import { getPluralText } from './Util';

/**
 * get date in epoch for earlier dates based on noOfDays
 * @author - mmt9855
 * @param {Object} dateObj - date object
 * @param {Number} noOfDays - number of days to be subtracted
 * @return {Number} - updated date in milliseconds
 */
export const getEarlierDate = (dateObj, noOfDays) => {
	try {
		return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() - noOfDays).getTime();
	} catch (error) {
		saveServerLogs(error && error.stack ? error.stack : error, 'error');
	}
};

/**
 * get date in epoch for the end of the day based on the number of days
 * @author - mmt9855
 * @param {Object} dateObj - date object
 * @param {Number} noOfDays - number of days to be subtracted
 * @return {Number} - updated date in milliseconds
 */
export const getEndingDate = (dateObj, noOfDays) => {
	try {
		return Number(moment(dateObj).subtract(noOfDays, 'days').endOf('day').format('x'));
	} catch (error) {
		saveServerLogs(error && error.stack ? error.stack : error, 'error');
	}
};

/**
 * get date in epoch based on noOfDays in advance
 * @author - mmt9855
 * @param {Object} dateObj - date object
 * @param {Number} noOfDays - number of days to be subtracted
 * @return {Number} - updated date in milliseconds
 */
export const getAdvanceDate = (dateObj, noOfDays) => {
	try {
		return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() + noOfDays).getTime();
	} catch (error) {
		saveServerLogs(error && error.stack ? error.stack : error, 'error');
	}
};

const getInitDateQuery = (startKey, endKey) => {
	const dateQuery = {};
	const dateObj = APPLICATION_CONSTANTS.TODAY;
	dateQuery[startKey] = getEarlierDate(dateObj, MRB_CONSTANTS.PENDING_NO_OF_DAYS);
	dateQuery[endKey] = dateObj.getTime();
	return dateQuery;
};

export const getDayStartDate = (dateObj, day)=>{
	try {
		const start = moment(dateObj).add(day, 'days').startOf('day').format('x');
		return start;
	} catch (err) {
		saveServerLogs(err && err.stack);
	}
};
export const getDayEndDate = (dateObj, day)=>{
	try {
		const end = moment(dateObj).add(day, 'days').endOf('day').format('x');
		return end;
	} catch (err) {
		saveServerLogs(err && err.stack);
	}
};

/**
 * get date filter and query object
 * @author - mmt9855
 * @param {String} requestType - request type based on tab
 * @return {Object} - get date filter and query object
 */
export const createDateFiltersOptions = (key = '') => {
	const dateObj = APPLICATION_CONSTANTS.TODAY;
	const dateQuery = getInitDateQuery('startDate', 'endDate');
	const options = [];
	const config = TRAVEL_REQUEST_CONFIG[key];
	const len = config.length;
	for (let i = 0; i < len; i++) {
		const option = { ...dateFilterConfig };
		option.index = i;
		option.label = config[i].label;
		const fromDate = key === MRB_FILTERS_CONSTANTS.DATE_OF_TRAVEL && (config[i].startDay ? getDayStartDate(dateObj, config[i].startDay) : dateObj.getTime());
		const toDate = key === MRB_FILTERS_CONSTANTS.DATE_OF_TRAVEL && getDayEndDate(dateObj, config[i].endDay);
		option.start = fromDate ? fromDate : getEarlierDate(dateObj, config[i].startDay);
		option.end = toDate ? toDate : typeof config[i].endDay === 'undefined' ? dateObj.getTime() :
			getEndingDate(dateObj, config[i].endDay);
		options.push(option);
	}
	/* if (true) {
		dateQuery.startDate = getEarlierDate(dateObj, MRB_CONSTANTS.PENDING_NO_OF_DAYS);
		dateQuery.endDate = dateObj.getTime();
	} else {
		dateQuery.startDate = dateObj.getTime();
		dateQuery.endDate = getAdvanceDate(dateObj, MRB_CONSTANTS.UPCOMING_NO_OF_DAYS);
	} */
	return {
		options,
		dateQuery
	};
};

/**
 * get all the filters based on request type
 * @author - mmt9855
 * @param {Object} filtersConfig - filter config
 * @param {String} requestType - request type based on tab
 * @return {Array} - get all the filters in array object
 */
export const getFilters = (filtersConfig, requestType = '') => {
	const filters = cloneDeep(filtersConfig);
	const filtersData = [];

	for (let key in filters) {
		switch (key) {
			case MRB_FILTERS_CONSTANTS.APPROVER:
				requestType === MRB_CONSTANTS.PENDING_REQUEST_TYPE && filtersData.push(filters[key]);
				break;
			case MRB_FILTERS_CONSTANTS.DATE_OF_TRAVEL:
			case MRB_FILTERS_CONSTANTS.DATE_OF_BOOKING: {
				const { options: newOptions = [] } = createDateFiltersOptions(key);
				filters[key].options = [...newOptions, ...filters[key].options];
				requestType === MRB_CONSTANTS.UPCOMING_REQUEST_TYPE && filtersData.push(filters[key]);
				break;
			}
			case MRB_FILTERS_CONSTANTS.DATE_OF_REQUEST: {
				const { options: newOptions = [] } = createDateFiltersOptions(key);
				filters[key].options = [...newOptions, ...filters[key].options];
				requestType === MRB_CONSTANTS.PENDING_REQUEST_TYPE && filtersData.push(filters[key]);
				break;
			}
			case MRB_FILTERS_CONSTANTS.TRAVEL_AGE: {
				requestType === MRB_CONSTANTS.PENDING_REQUEST_TYPE && filtersData.push(filters[key]);
				break;
			}
			case MRB_FILTERS_CONSTANTS.TRIP_TYPE: {
				const opts = filters[key].options;
				filters[MRB_FILTERS_CONSTANTS.TRIP_TYPE].options = requestType === MRB_CONSTANTS.UPCOMING_REQUEST_TYPE ?
					filters[key].options.filter((item) => item.value !== MRB_FILTERS_CONSTANTS.FLIGHT_MOD_TYPE) :
					opts;
				filtersData.push(filters[key]);
				break;
			}
			default:
				filtersData.push(filters[key]);
		}
	}
	return filtersData;
};

/**
 * get initial query object for the request
 * @author - mmt9855
 * @param {String} key - request type based on tab
 * @param {String} value - search value if any
 * @return {Object} - returns query for the data and count api
 */
export const getInitialQuery = (key = '', value) => {
	const dateQuery = getInitDateQuery('startDate', 'endDate');

	return {
		...dateQuery,
		'searchQuery': value
	};
};

/**
 * Create updatedQuery & updatedFilter object for checkbox filters
 * @author - mmt9855
 * @param {Object} query - previous query object
 * @param {Object} filter - selected filter
 * @param {Object} queryOption - queryOptions containing {checked ad value}
 * @param {Object} index  - queryOptions containing {checked ad value}
 * @param {Object} filters - queryOptions containing {checked ad value}
 * @return {Object} - Updated query and filters
 */
const setCheckboxQuery = (query = {}, filter = {}, queryOption = {}, index, filters) => {
	const { key, singleSelect = false } = filter;
	const updatedQuery = cloneDeep(query);
	const { checked, value } = queryOption;
	const updatedFilter = cloneDeep(filter);
	if (checked) {
		updatedQuery[key] = updatedQuery[key] && Array.isArray(updatedQuery[key]) && !singleSelect ?
			[...updatedQuery[key], value] : value || value === 0 ? [value] : [];
	} else {
		updatedQuery[key] = updatedQuery[key].filter((item) => item !== value);
		if (updatedQuery[key].length === 0) {
			delete updatedQuery[key];
		}
	}

	updatedFilter.showClear = (!updatedFilter.hideClear) && updatedQuery[key] && updatedQuery[key].length > 0;
	const updatedFilters = cloneDeep(filters);
	updatedFilters[index] = updatedFilter;
	if (key === MRB_CONSTANTS.TRIP_TYPE) {
		const { fltrs, newQuery } = addNewField(updatedFilters, updatedFilter, updatedQuery);
		return { updatedQuery: newQuery, updatedFilters: fltrs };
	}
	return { updatedQuery, updatedFilters };
};

/**
 * Create updatedQuery & updatedFilter object for date range filters
 * @author - mmt9855
 * @param {Object} query - previous query object
 * @param {Object} filter - selected filter
 * @param {Object} queryOption - queryOptions containing {checked ad value}
 * @return {Object} - Updated query and filter
 */
const setDateRangeQuery = (query = {}, filter = {}, queryOption = {}) => {
	const appliedFilter = [];
	const { listHead = '' } = queryOption;
	updateAppliedFilterObj(appliedFilter, [queryOption]);
	const updatedQuery = cloneDeep(query);
	const dateRange = appliedFilter[0] || {};
	const { start = '', end = '' } = dateRange;
	const { startKey = '', endKey = '' } = filter;
	updateFltClickCount(filter, queryOption.checked);
	return {
		updatedQuery: { ...updatedQuery, [startKey]: start, [endKey]: end },
		updatedFilter: { ...filter, selectedHead: listHead }
	};
};
/**
 * Create updatedQuery & updatedFilter object for date range filters
 * @author - mmt9855
 * @param {Object} query - previous query object
 * @param {Object} filter - selected filter
 * @param {Object} queryOption - queryOptions containing {checked ad value}
 * @return {Object} - Updated query and filter
 */
const setAgeValue = (query = {}, filter = {}, queryOption = {}) => {
	const { startKey, endKey } = filter;
	const updatedQuery = cloneDeep(query);
	updatedQuery[startKey] = queryOption[startKey];
	updatedQuery[endKey] = queryOption[endKey];
	return {
		updatedQuery,
		updatedFilter: cloneDeep(filter)
	};
};

/**
 * Create updatedQuery & updatedFilter object for input field filter
 * @author - mmt9855
 * @param {Object} query - previous query object
 * @param {Object} filter - selected filter
 * @param {String} queryValue - queryValue containing the text user has entered
 * @param {Object} index - current index for the filter
 * @param {Object} filters - all the filters
 * @param {String} travelKey - travelKey in case of source and destination filter
 * @return {Object} - Updated query and filter
 */
const setInputFieldQuery = (query = {}, filter = {}, queryValue = '', index, filters, travelKey) => {
	const { key = '' } = filter;
	const updatedQuery = cloneDeep(query);
	if (travelKey) {
		updatedQuery[key] = {
			...updatedQuery[key],
			[travelKey]: queryValue
		};
	} else {
		updatedQuery[key] = {
			stayLocation: queryValue
		};
	}
	const updatedFilters = cloneDeep(filters);
	updatedFilters[index] = filter;
	return {
		updatedQuery,
		updatedFilters: updatedFilters
	};
};

/**
 * Create updatedQuery & updatedFilter object for dropdown filter
 * @author - mmt9855
 * @param {Object} query - previous query object
 * @param {Object} filter - selected filter
 * @param {Array} queryValue - queryValue containing
 * @param {Object} index - current index for the filter
 * @param {Object} filters - all the filters
 * @return {Object} - Updated query and filter
 */
const setDropDownQuery = (query = {}, filter = {}, queryValue = [], index, filters) => {
	const key = filter.key;
	const updatedQuery = cloneDeep(query);
	const updatedFilter = cloneDeep(filter);

	if (queryValue && queryValue.length > 0) {
		updatedQuery[key] = queryValue.map((item) => item.value);
	} else {
		updatedQuery[key] && delete updatedQuery[key];
	}

	updatedFilter.showClear = updatedQuery[key] && updatedQuery[key].length > 0;
	const updatedFilters = cloneDeep(filters);
	updatedFilters[index] = updatedFilter;
	return { updatedQuery, updatedFilters };
};

/**
 * Create updatedQuery & updatedFilter object which is used in API Call
 * @author - mmt9855
 * @param {Object} query - previous query object
 * @param {Object} filter - selected filter
 * @param {Object} queryData - queryOptions containing {option ad value}
 * @param {Object} index - current index for the filter
 * @param {Object} filters - all the filters
 * @param {String} travelKey - travelKey for the location filters
 * @return {Object} - Updated query and filter for selected filter
 */
export const getFilterQuery = (query = {}, filter = {}, queryData = {}, index, filters, travelKey) => {
	const key = filter.key;
	const { value: queryValue, option: queryOption } = queryData;
	switch (key) {
		case MRB_CONSTANTS.POLICY:
		case MRB_CONSTANTS.TRIP_TYPE: {
			return setCheckboxQuery(query, filter, queryOption, index, filters);
		}
		case MRB_CONSTANTS.APPROVER: {
			return setDropDownQuery(query, filter, queryValue, index, filters);
		}
		case filterTypes.location:
		case filterTypes.customLocation:
			return setInputFieldQuery(query, filter, queryValue, index, filters, travelKey);
		case FILTER_CONSTANTS.DATE_FILTER: {
			return setDateRangeQuery(query, filter, queryValue, index, filters);
		}
		default: {
			return query;
		}
	}
};

/**
 * Clear Checkbox Filtes and Update query
 * @author - mmt9855
 * @param {Object} query - previous query object
 * @param {string} key - key of the filter
 * @return {Object} - Updated query and filter for selected filter
 */
const clearCheckBoxFilter = (query = {}, key) => {
	const updatedQuery = cloneDeep(query);
	updatedQuery[key] && delete updatedQuery[key];
	if (key === MRB_CONSTANTS.TRIP_TYPE) {
		updatedQuery?.[filterTypes.location] && delete updatedQuery[filterTypes.location];
	}
	return {
		updatedQuery
	};
};

/**
 * Clear Date Filtes and Update query
 * @author - mmt9855
 * @param {Object} query - previous query object
 * @param {Object} filtersState - current reducer filter object
 * @return {Object} - Updated query and filter for selected filter
 */
const clearDateFilter = (query = {}, filtersState, index) => {
	let updatedQuery = cloneDeep(query);
	const dateQuery = getInitDateQuery('startDate', 'endDate');
	const dateFlt = filtersState[index];
	const { startKey, endKey } = filtersState[index];
	if (dateFlt) {
		dateFlt.options.forEach((opts) => {
			opts.checked = false;
		});
		dateFlt.showClear = false;
		delete (updatedQuery[startKey]);
		delete (updatedQuery[endKey]);
	}
	updatedQuery = { ...updatedQuery, ...dateQuery };
	return {
		updatedQuery,
		updatedFilter: dateFlt
	};
};

/**
 * Clear Filtes and Update query
 * @author - mmt9855
 * @param {Object} query - previous query object
 * @param {Object} clearedFilter - filter which needs to be cleared
 * @param {String} filtersState - request type which is needed for clear date filter
 * @return {Object} - Updated query and filter for selected filter
 */
export const clearFilterNQuery = (query = {}, clearedFilter = {}, filtersState, index) => {
	const key = clearedFilter.key;

	let updatedFilter = cloneDeep(clearedFilter);
	updatedFilter.showClear = false;

	switch (key) {
		case MRB_CONSTANTS.POLICY:
		case MRB_CONSTANTS.TRIP_TYPE: {
			return {
				updatedFilter,
				...clearCheckBoxFilter(query, key)
			};
		}
		case FILTER_CONSTANTS.DATE_FILTER: {
			return clearDateFilter(query, filtersState, index);
		}
		case MRB_CONSTANTS.APPROVER:
			updatedFilter[key] = { ...clearedFilter, showClear: false };
			const updatedQuery = cloneDeep(query);
			updatedQuery[key] && delete updatedQuery[key];

			return {
				updatedFilter,
				updatedQuery
			};
		default: {
			return {
				updatedFilter,
				updatedQuery: query
			};
		}
	}
};

/**
 * add options based on api call for approver filter
 * @author - mmt9855
 * @param {Array} filters - all the present filter
 * @param {String} filterKey - selected filter
 * @param {Array} optionsData - options array containing {value and la}
 * @return {Array} - updated filters with options added for the approver filter
 */
export const formatFiltersOptions = (filters = [], filterKey = '', optionsData = []) => {
	let filterData = filters;
	if (optionsData) {
		filterData = cloneDeep(filterData);
		filterData[filterKey].options = [...optionsData];
	}
	return filterData;
};

const removeLocationFilter = (fltrs, newQuery, arrIndex) => {
	fltrs.splice(arrIndex, 1);
	if (newQuery['location']) {
		delete newQuery['location'];
	}
};

/**
 * add new filter in case of any trip type is selected
 * @author - mmt9855
 * @param {Array} changedFilters - all the present filter
 * @param {Object} filter - selected filter
 * @param {Object} updatedQuery - updatedQuery containing {option and value}
 * @return {Array} - updated filters with addition of new filter for location
 */
export const addNewField = (changedFilters = [], filter = {}, updatedQuery = {}) => {
	const fltkey = filter.key;
	const selectedValues = updatedQuery[fltkey];
	const fltrs = [...changedFilters];
	let locfltKey = -1;
	const fltrsLen = fltrs.length;
	for (let i = 0; i < fltrsLen; i++) {
		if (fltrs[i].key === customLocation.key) {
			locfltKey = i;
			break;
		}
	}
	const isLocationEnabled = locfltKey > 0;
	const newQuery = cloneDeep(updatedQuery);
	if (!selectedValues && isLocationEnabled) {
		removeLocationFilter(fltrs, newQuery, locfltKey);
		return {
			fltrs,
			newQuery
		};
	}
	const onlyselVal = selectedValues && selectedValues[0];
	if (!isLocationEnabled && selectedValues && selectedValues.length === 1 &&
		onlyselVal !== MRB_FILTERS_CONSTANTS.REQUISTION_TYPE) {
		fltrs[changedFilters.length] = onlyselVal === MRB_FILTERS_CONSTANTS.HOTEL_TYPE ?
			destinationField : customLocation;
	} else if (selectedValues && selectedValues.length > 1) {
		const htlsel = selectedValues.some((item) => item === MRB_FILTERS_CONSTANTS.HOTEL_TYPE);
		const cabsel = selectedValues.some((item) => item === MRB_FILTERS_CONSTANTS.CAB_TYPE);
		const requisitionSelected = selectedValues.some((item) => item === MRB_FILTERS_CONSTANTS.REQUISTION_TYPE);
		if (!isLocationEnabled && !(htlsel || requisitionSelected || cabsel)) {
			fltrs[changedFilters.length] = customLocation;
		} else if (isLocationEnabled) {
			removeLocationFilter(fltrs, newQuery, locfltKey);
		}
	}
	return {
		fltrs,
		newQuery
	};
};

/**
 * Format Filters with count data
 * @author - mmt9855
 * @param {Object} countData - countData from API
 * @param {Array} filters - all filters for a particular report
 * @return {Array} - Updated Filters with count
 */
export const formatFilterCounts = (countData, filters = []) => {
	let filterData = cloneDeep(filters);
	filterData.forEach((filter) => {
		const { key = '' } = filter;
		if (key === MRB_CONSTANTS.POLICY || key === MRB_CONSTANTS.TRIP_TYPE) {
			const countVals = countData?.[key];
			filter?.options?.forEach((option) => {
				option.count = countVals?.[option?.countKey];
			});
		}
	});
	return filterData;
};

const formatListing = (data = [], requestType, isRequisitionService) => data.map((item = {}) => {
	const apprItem = {};
	const isUpcomingBooking = requestType === MRB_CONSTANTS.UPCOMING_REQUEST_TYPE;
	const isRequisitionWrapper = item.tripType === LOB_TYPES.REQUISITION;
	apprItem.isOpen = true;
	const reqsnLabel = MRB_FILTER_CONFIG[MRB_FILTERS_CONSTANTS.REQUISTION_TYPE]?.label;
	apprItem.location = isRequisitionWrapper && !isUpcomingBooking ? { source: reqsnLabel } : item.location;
	apprItem.tripType = ICON_MAP[item.tripType];
	apprItem.services = item.services ? formatListing(item.services, requestType, isRequisitionWrapper) : [];
	apprItem.id = item.id;
	apprItem.bookingId = item.bookingId || (isUpcomingBooking && reqsnLabel);
	apprItem.dateData = {
		travelDate: (isUpcomingBooking ? item.dateOfBooking : item.dateOfTravelling) || (!isRequisitionWrapper && '-'),
		nights: `${item.totalNights > 0 ? `(${item.totalNights + getPluralText(' night', item.totalNights)})` : ''}`
	};
	apprItem.ageInHrs = item.ageInHrs;
	apprItem['dateOfTravelling'] = item.dateOfTravelling;
	const traveLen = item.travellers?.length;
	const { firstName = '', lastName = '' } = item.travellers?.[0] || {};
	apprItem.traveller = traveLen > 0 ? `${firstName} ${lastName} ${traveLen > 1 ? `+${traveLen - 1}` : ''}` : '';
	apprItem.approver = item.approver?.name || '';
	if (isRequisitionService) {
		apprItem.bodyCellProps = {
			padding: '0px 16px'
		};
	}
	if (isRequisitionWrapper) {
		apprItem.bodyCellProps = {
			noBdr: true
		};
	}

	const showAction = (!isRequisitionWrapper && !isRequisitionService) || (isUpcomingBooking && isRequisitionService) || (isRequisitionWrapper && !isUpcomingBooking);

	apprItem.action = !showAction ?
		false : { bookingId: item.bookingId, url: item.actionUrl, type: APPLICATION_CONSTANTS.BTN_TYPE.BUTTON, value: isUpcomingBooking ? 'MODIFY' : 'VIEW DETAILS' };
	return apprItem;
});

export const formatPendingData = (data = [], currentList = [], requestType) => {
	const retData = { data: [], error: false, loading: false, hasMore: false };
	if (data.length > 0) {
		retData.hasMore = true;
	}
	currentList = currentList.concat(formatListing(data, requestType));
	retData.data = currentList;
	return retData;
};
