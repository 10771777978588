import { ascend, descend, prop, sort, compose, toLower } from 'ramda';

const byAsc = (sortByKey) => ascend(prop(sortByKey));

const byDesc = (sortByKey) => descend(prop(sortByKey));

const caseInSensitiveAsc = (sortByKey) => ascend(compose(toLower, prop(sortByKey)));

const caseInSensitiveDesc = (sortByKey) => descend(compose(toLower, prop(sortByKey)));

export const sortCaseSensitiveData = (key, order) => order === 'desc' ?
	caseInSensitiveDesc(key) :
	caseInSensitiveAsc(key);

export const sortNumericData = (key, order) => order === 'desc' ? byDesc(key) : byAsc(key);

export const sortData = (data, key, order, isNumericField) =>{
	const orderBy = isNumericField ? sortNumericData(key, order) : sortCaseSensitiveData(key, order);
	return sort(orderBy, data);
};
