import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import {
	LabelText,
	BoxWrapperRow,
	InfoText
} from './InputStyles';

const TextArea = ({
	value,
	onChange,
	label,
	required,
	inputProps = {},
	forwardedRef,
	disabled,
	message,
	...props
}) => (
	<BoxWrapperRow className='materialInput' {...props}>
		<textarea
			$width='100%'
			{...inputProps}
			required
			value={value}
			onChange={onChange}
			ref={forwardedRef}
			disabled={disabled}
			className={value ? 'textarea valid' : 'textarea'}
		/>
		{label && (
		<LabelText>
			{label}
			{required && (
			<span className='redText font14 latoBold'>*</span>
			)}
		</LabelText>
		)}
		{message && (
		<InfoText color={message.color}>{message.text}</InfoText>
		)}
	</BoxWrapperRow>
);

TextArea.propTypes = {
	label: PropTypes.string,
	onChange: PropTypes.func,
	type: PropTypes.oneOf(['text', 'number'])
};

export default forwardRef((props, ref) => <TextArea {...props} forwardedRef={ref} />);
