import React, { Component } from 'react';
import { CheckBoxGroup, ChkBoxLabel, Info, Count, Text, SubText, SideSection } from './CheckBoxGrpStyles';

class CheckBoxGrp extends Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleMouseDown = this.handleMouseDown.bind(this);
		this.state = {
			isChecked: this.props.isChecked || false
		};
	}
	handleChange(e) {
		const { checked } = e.target;
		if (!this.props.checkOnly || checked) {
			this.props.handleChange(e, this.props.field);
			this.setState({
				isChecked: checked
			});
		}
	}

	handleMouseDown(e) {
		if (this.props.onMouseDown) {
			this.props.onMouseDown();
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.isChecked !== this.props.isChecked) {
			this.setState({
				isChecked: nextProps.isChecked
			});
		}
	}

	render() {
		const { field = {}, value, isDisabled, customStyle, type } = this.props;
		const { isChecked } = this.state;
		const { showCount = true, count, label, subLabel, sideSection } = field;
		return (
			<CheckBoxGroup className={`checkboxGrp__container ${customStyle}`} type={type}>
				<ChkBoxLabel isDisabled={isDisabled} className={customStyle} onMouseDown={this.handleMouseDown}>
					<input
						disabled={isDisabled}
						checked={isChecked}
						data-val={value}
						onChange={this.handleChange}
						type='checkbox'
					/>
					<Text className={customStyle}>
						{label &&
							<Info
								className={customStyle ? `checklabel ${customStyle}` : `checklabel`}>
									{label}
							</Info>}
						{subLabel &&
							<SubText
								className={customStyle}>
								{subLabel}
							</SubText>}
						{showCount && typeof count !== 'undefined' && count !== '' &&
							<Count className={customStyle}>({count})</Count>}
					</Text>
					{sideSection && <SideSection className={customStyle}>{sideSection}</SideSection>}
				</ChkBoxLabel>
			</CheckBoxGroup>
		);
	}
}

export default CheckBoxGrp;
