import { filter,
	curry,
	cond,
	compose,
	prop,
	always,
	remove,
	insertAll, flip, head, transduce, append, map } from 'ramda';

const notMatchingEmail = curry((emailId, item) => item.emailId !== emailId);

const getKeyVal = (predicateAll, obj, key) => ({
	key,
	val: predicateAll ? [] : prop(key, obj)
});

const getConditionFunc = (cond) => () => cond;

const getValidationTranform = curry((config, validationData) => {
	const { isValid = false,
		isInvalid = false,
		isAlreadyExist = false,
		isInSameGroup = false,
		isNotFound = false,
		predicateAll = false } = config;

	return cond([
		[
			getConditionFunc(isValid),
			always(
				getKeyVal(predicateAll, validationData, 'validUsers')
			)
		],
		[
			getConditionFunc(isInvalid),
			always(
				getKeyVal(predicateAll, validationData, 'invalidUsers')
			)
		],
		[
			getConditionFunc(isInSameGroup),
			always(
				getKeyVal(predicateAll, validationData, 'existInSameGroup')
			)
		],
		[
			getConditionFunc(isAlreadyExist),
			always(
				getKeyVal(predicateAll, validationData, 'alreadyExist')
			)
		],
		[
			getConditionFunc(isNotFound),
			always(
				getKeyVal(predicateAll, validationData, 'notFoundUsers')
			)
		]
	])();
});

const notMatchingEmailFilter = curry((emailId, data) => {
	const getResult = compose(filter(notMatchingEmail(emailId)), prop('val'));
	return {
		...data,
		val: getResult(data)
	};
});

export const removeItem = (config = {}, item = {}, validationData = {}) => {
	const { emailId } = item;

	const { key, val } = compose(
		notMatchingEmailFilter(emailId),
		getValidationTranform
	)(config, validationData, true);

	return { ...validationData, [key]: val };
};

const setNotFoundStatus = (isNotFound) => (item) => ({ ...item, isNotFound });

export const formatValidationData = (validationData = {}) => {
	let { alreadyExistInGroup = [], invalidUsers = [], notFoundUsers = [], validUsers = [], existInSameGroup = [], key = '' } = validationData;
	alreadyExistInGroup = alreadyExistInGroup.map(setNotFoundStatus(false));
	invalidUsers = invalidUsers.map(setNotFoundStatus(false));
	notFoundUsers = notFoundUsers.map(setNotFoundStatus(true));
	validUsers = validUsers.map(setNotFoundStatus(false));
	existInSameGroup = existInSameGroup.map(setNotFoundStatus(false));

	return {
		...validationData,
		alreadyExist: alreadyExistInGroup,
		invalidUsers,
		notFoundUsers,
		validUsers,
		existInSameGroup,
		key
	};
};

export const addItemsToValid = (config = {}, items = [], validationData = {}) => {
	const { emailId } = items[0] || {};

	const { key, val } = compose(
		notMatchingEmailFilter(emailId),
		getValidationTranform
	)(config, validationData, true);

	return {
		...validationData,
		validUsers: [...validationData.validUsers, ...items],
		[key]: val
	};
};

const segregateValidData = (data) => data.reduce((acc, curr) => {
	const { isNotFound, emailId } = curr;
	const { notFoundUsers = [], validUsers = [] } = acc;
	return isNotFound ?
		{ ...acc, notFoundUsers: [...notFoundUsers, emailId] } :
		{ ...acc, validUsers: [...validUsers, emailId] };
}, {
	notFoundUsers: [],
	validUsers: []
});

export const getSubmitGroupData = compose(segregateValidData, prop('validUsers'));

export const mergeGroups = (groups, index, id) => groups[index].reduce((acc, item) => {
	const { groupId, empCount } = item || {};
	let { list, count } = acc;
	count = count + Number(empCount);
	if (id === groupId) {
		list = [...list, { ...item }];
	}
	return { list, count };
}, { list: [], count: 0 });

export const seperateGroup = (groups, index) => {
	const updatedGrpSet = groups[index].map((item) => [{ ...item }]);
	return compose(insertAll(index, updatedGrpSet), remove(index, 1));
};

export const mergeGroupPayload = ({ groups, index, id, isSeperate }) => groups[index].reduce((acc, item) => {
	let { to, from, ignore } = acc;
	const { groupId } = item;
	if (isSeperate) {
		ignore = [...ignore, groupId];
	} else if (groupId === id) {
		to = groupId;
	} else {
		from = [...from, groupId];
	}
	return {
		to,
		from,
		ignore
	};
}, {
	to: '',
	from: [],
	ignore: []
});

export const getConflictSubmitData = (groups) => {
	const getFlatIdTrans = compose(map(head), map(prop('grpId')));
	return transduce(getFlatIdTrans, flip(append), [], groups);
};

export const shouldCheckEmployee = (validationData, userEmailId) => {
	const { notFoundUsers = [], alreadyExist = [], invalidUsers = [], validUsers = [], existInSameGroup = [] } = validationData;
	const allUsersData = [...notFoundUsers, ...alreadyExist, ...invalidUsers, ...validUsers, ...existInSameGroup];
	const foundUsers = allUsersData.filter((user) => user.emailId === userEmailId);
	return !foundUsers.length;
};
