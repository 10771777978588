import Axios from "axios";
import {APPLICATION_CONSTANTS} from '../../src/AppConstants';

export const getUserServiceAxiosConfig = (customHeaders) => {
    let userConfig = {
        baseURL: '//mapi.makemytrip.com',
        timeout: 30000,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': APPLICATION_CONSTANTS.HCS_USER_SERVICE_AUTHORIZATION,
            'Accept': 'application/json',
            'User-Identifier' : "{\"type\": \"mmt-auth\" ,\"profileType\": 1,\"deviceId\" : \"deviceId\", \"os\":\"os\", \"osVersion\":\"osVersion\", \"appVersion\":\"appVersion\", \"imie\":\"imie\", \"ipAddress\":\"ipAddress\", \"timeZone\":\"timeZone\",\"value\": \"\"}"
        }
    }

    if(customHeaders) {
        userConfig["headers"] = {...userConfig["headers"],...customHeaders}
    }
    return Axios.create(userConfig);
}
