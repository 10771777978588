import styled from 'styled-components';

import { FlexDiv, Container, GenericDiv, GenericSpan } from 'commonStyles';
import { ASSET_PATH } from 'assetsPath';

export const Bar = styled(FlexDiv)`
    background: #fff;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    border-top: solid #f5f5f5 1px;
    height: 60px;
`;

export const Wrapper = styled(Container)`
    display: flex;
    flex-direction: row;
		@media(max-width: 768px){
			justify-content: space-between;
			padding: 0 15px;
  	}
`;

export const LeftMenu = styled(FlexDiv)`
    height: 55px;
    max-width: calc(100% - 350px);
    box-sizing: border-box;
		@media(max-width: 768px){
			max-width: calc(100% - 200px);
  	}
`;

export const RightMenuLarge = styled(FlexDiv)`
    color: #4a4a4a;
    font-size: 10px;
    max-width: 350px;
    @media(max-width: 768px){
			max-width: 200px;
  	}
`;

export const Logo = styled.div`
    width: 190px;
    padding-top: 10px;
    padding-bottom: 7px;
    & .logoHeading{    color: #ff3e5e;
      margin-left: 5px;
      font-size: 18px;
      font-weight: 900;
      margin-top: 10px;
			@media (max-width: 768px) {
				font-size: 14px;
			}
			}
    & img{
			width: 61px;
			height: 31px;
			@media (max-width: 768px) {
				width: 50px;
				height: 25px;
			};
		}
`;
export const ReactMenuLogo = styled.p`
  display: none;
  z-index: 999;
  width: ${(props) => props.width ? props.width : 23}px!important;
  height: ${(props) => props.height ? props.height : 16}px!important;
  margin-left: ${(props) => props.margin_L ? props.margin_L : 20}px;
  padding-top: 16px;
  @media(max-width: 768px){
    display: block;
  }
`;
export const RightMenu = styled(ReactMenuLogo)`
  width: 10px;
  height: 15px;
`;
export const LogoImg = styled.img`
  max-width: 100% !important;
  cursor: pointer;
  width: ${(props) => props.width ? props.width : 'auto'};
`;
export const MenuList = styled(FlexDiv)`
  margin-top: 10px;
  list-style: none;
  padding-left: 0px;
	@media(max-width: 768px){
    display: none;
  }
`;

export const MainItem = styled.div`
  position: relative;
  display: inline-block;
  .submenu {
    transition: all 0.2s;
    position: absolute;
    background-color: #fff;
    top: ${(p) => p.type === 'ver' ? '0' : '105%'};
    left: ${(p) => p.type === 'ver' ? '100%' : 0};
    width: ${(p) => p.type === 'ver' ? '0' : '200px'};
    min-width: ${(p) => p.type === 'ver' ? '0' : '200px'};
    max-height: ${(p) => p.type === 'ver' ? '300px' : '0'};
    border-radius: 0px 0 6px 6px;
    border-top-right-radius: ${(p) => p.type === 'ver' ? '6px' : '0'};
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 0;
    overflow: hidden;
    li {
      width: 100%;
    }
  }
	.myClaims {
		transition: all 0.2s;
    position: absolute;
		background-color: #fff;
		display: none;
		top: ${(p) => p.type === 'ver' ? '0' : '105%'};
    left: ${(p) => p.type === 'ver' ? '100%' : 0};
		z-index: 1;
		padding : 20px;
		border-radius: 4px;
		box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
	}
  ${({ active }) => active && `
    .submenu {
      display: block;
      min-width: 200px;
      max-height: 300px;
			padding: 10px 0 24px;
      overflow: visible;
		}
		.myClaims {
			display:none !important;
		}
  `}
  &:hover {
    .submenu {
      display: block;
      min-width: 200px;
      max-height: 300px;
			padding: 10px 0 24px;
      overflow: visible;
		}
		.myClaims{
			display: block;
			min-width: 285px
		}
    .arrow {
      transform: ${(props) => props.type == 'ver' ? 'rotateZ(-45deg)' : 'rotateZ(-135deg)'};
      top: ${(props) => props.type == 'ver' ? '0' : '4px'};
    }
	}
`;
export const RightMenuList = styled.ul`
 list-style: none;
  @media (max-width: 768px) {
      padding-left: 0px;
    };
`;
export const LeftMenuItemLink = styled.a`
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    color: #4a4a4a;
    display: block;
    text-decoration: none;
    padding: 12px 8px;
    &:hover + .dropSection{display: block;}
    &:hover.TextActive{font-weight: 700;}
    &:active{
     background-color: #371f30;
     color:#fff;
     text-decoration: none;
    }
    @media (max-width: 768px) {
     font-size: 10px;
    };
`;

export const MyBizSection = styled.a`
  border-radius: 4px;
  background-color: rgba(0, 145, 255, 0.1);
  padding: 12px 10px;
  font-size: 12px; 
  color: #0091ff; 
  font-weight: 900;
  margin-right: 20px;
  white-space:nowrap;
`;

export const CallSection = styled.a`
    width: 190px;
    white-space: nowrap;
    padding: 7px;
    margin-right: 10px;
    cursor: pointer;
    color: #4a4a4a;
    text-transform: uppercase;
    font-size: 10px;
    border: 1px solid #b4b4b4;
    display: inline-block;
    border-radius: 3px;
    @media(max-width: 768px){
      opacity: 0;
      display: none;
    }
`;
export const FloatLeftSpan = styled.span`
  float: left;
  clear: left;
  text-transform: uppercase;
  font-size: 10px;
  color: #4a4a4a;
`;

export const HeaderContainer = styled(FlexDiv)`
    position: fixed;
    top: 0;
    height: 0;
    z-index: 4;
    height: 60px;
    width: 100%;
`;

export const ExpenseContainer = styled(GenericDiv)`
    position: absolute;
    background-color: #fff;
		display: block;
		`;

export const MybizLog = styled(GenericSpan)`
	background:url(${ASSET_PATH.IMAGES}myBizEmpAdminSprite.png) no-repeat;
	display: inline-block;
	background-size: 200px 450px;
	vertical-align: baseline;
	width: 27px;
	height: 26px;
`;
