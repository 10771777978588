import styled from 'styled-components';

export const Radiolabel = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
    color:#4a4a4a;
    &.disabledStyle{
        pointer-events:none;
        & span{
            border-color:#9b9b9b;
            & .inner{
                background-color: #9b9b9b !important;
            }
				}
				&.invoice{
					border-color:#9b9b9b;
					cursor: not-allowed;
					opacity: 0.55
				}

    }
    & input[type="radio"]{
				width: 0;
				height: 0;
        opacity: 0;
        position: static;
        overflow: hidden;
        margin-left: 0px;
        cursor: pointer;
    }
		& input:checked + span .inner{
				transform: scale(1);
				opacity: 1;
				background-color: #f17531;
		}
		& input[type="radio"][disabled] {
			cursor: not-allowed;
		}
		&.invoice{
			margin-bottom: 10px;
			font-weight: normal;
		}
		&.requisition{
			width: auto;
			margin-right: 20px;
		}

`;
export const RadioCustom = styled.span`
    height: 18px;
    width: 18px;
    display: block;
    border: 1px solid #f17531;
    border-radius: 50%;
    background-color: #fff;
		margin-right: 10px;
    & .inner{
        -webkit-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
        height: 12px !important;
        width: 12px !important;
        -webkit-transform: scale(0);
        transform: scale(0);
        display: block;
        margin: 2px;
        border-radius: 50%;
        background-color: #2E3092;
        opacity: 0;
		}
		&.invoice{
			border: 1px solid #9b9b9b;
		}

`;

export const RadioSpanText = styled.span`
	word-break: break-word;
`;

export const Text = styled.span `
		&.invoice{
			display:flex;
			justify-content:space-between;
			width: 100%;
			font-size: 14px;
			color: #4a4a4a;
			font-weight: normal;
		}
`;

export const RadioSubText = styled.span`
    font-size: 14px;
    margin-left: 10px;
		color: #777777;
		&.invoice{
			font-size: 12px;
			font-weight: 700;
			color:#9b9b9b;
		}
`;
