import React from 'react';

import { ASSET_PATH } from 'assetsPath';
import { ArrowWrapper } from './FeatureInfoStyles';

const ARROW_PATH = `${ASSET_PATH.IMAGES}arrows/`;
const ArrowTopLeft = `${ARROW_PATH}arw-left-top.png`;
const ArrowTopRight = `${ARROW_PATH}arw-right-top.png`;
const ArrowBottom = `${ARROW_PATH}arw-right-btm.png`;

/**
	* Arrow component is used to render bent arrow
	* @author mmt8067
	* @param {String} [arrowPos="top-right"] - Direction of arrow ['top-left', 'top-right', 'bottom']
	* @param {String} arrowLeft
	* @param {String} arrowRight
	* @param {String} arrowTop
	* @param {String} arrowBtm
	* @param {Number} rotateDeg
	* @return {JSX} return Arrow

*/
const Arrow = ({
	arrowPos,
	arrowLeft,
	arrowRight,
	arrowTop,
	arrowBtm,
	rotateDeg
}) => {
	let imgPath = '';
	let isBottom = false;
	switch (arrowPos) {
		case 'top-left':
			imgPath = ArrowTopLeft;
			break;
		case 'bottom':
			isBottom = true;
			imgPath = ArrowBottom;
			break;
		case 'bottom-right':
			isBottom = true;
			imgPath = ArrowTopRight;
			rotateDeg = '135';
			break;
		default:
			imgPath = ArrowTopRight;
	}

	return (
		<ArrowWrapper
			rotateDeg={rotateDeg}
			arrowBtm={arrowBtm ? arrowBtm : (isBottom ? '-45px' : 'auto')}
			arrowLeft={arrowLeft}
			arrowTop={arrowTop ? arrowTop : (!isBottom ? '-45px' : 'auto')}
			arrowRight={arrowRight}>
			<img
				className='guideArw'
				src={imgPath}
				alt='feature-arrow'
			/>
		</ArrowWrapper>
	);
};

export default Arrow;
