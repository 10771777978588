import {
	getFormattedDate,
	getUrlParamsMap,
	getShortMonth,
	getDaysDiff,
	getDateFilterKeys,
	getDefaultDate
} from './Util';
import {
	bundleObject,
	bundleData,
	textsConfig,
	dateConfig,
	InvoiceAirlines
} from '../invoice/config';
import {
	INVOICE_CONSTANTS, APPLICATION_CONSTANTS, FILTER_CONSTANTS
} from '../AppConstants';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { logOmniClick } from '../logging/omnitureService';

/**
 * params {}.
 * returns object for invoice list query
 */
const getInvoiceListParams = (params) => {
	delete (params.isDownload);
	delete (params.resource);
	const dateObj = getDefaultDate(APPLICATION_CONSTANTS.TODAY, INVOICE_CONSTANTS.API_DATE_FORMAT, false, APPLICATION_CONSTANTS.DEFAULT_QTR);
	const paramsObj = {
		'pageLimit': INVOICE_CONSTANTS.PAGE_LIMIT * 4,
		'sort': {
			[INVOICE_CONSTANTS.SORT_KEY]: INVOICE_CONSTANTS.DESCENDING,
			lastIndexedDate: null
		}
	};
	if (params.gstn) {
		paramsObj[INVOICE_CONSTANTS.GST_FILTER] = params.gstn.split(INVOICE_CONSTANTS.PARAM_SEPERATOR);
	}
	if (params.lob) {
		paramsObj.lob = params.lob.split(INVOICE_CONSTANTS.PARAM_SEPERATOR);
	}
	if (params.status) {
		paramsObj.invoiceStatus = params.status.split(INVOICE_CONSTANTS.PARAM_SEPERATOR);
	}
	if (params.startDate) {
		paramsObj.startDate = params.startDate;
	}
	if (params.endDate) {
		paramsObj.endDate = params.endDate;
	}
	if (params.ttl) {
		paramsObj.tripTags = updateDropdownQuery(params, 'tag', parseInt(params.ttl));
	}
	if (params.type) {
		paramsObj.invoiceType = {};
		const queryType = params.type.split(INVOICE_CONSTANTS.PARAM_SEPERATOR);
		queryType.forEach((type) => {
			paramsObj.invoiceType[type] = true;
			if (type === INVOICE_CONSTANTS.GST_TYPE && params.vln) {
				paramsObj.invoiceType.vendorDetails = updateDropdownQuery(params, 'vn', params.vln);
			}
		});
	}
	return {
		...paramsObj,
		...dateObj
	};
};

/**
 * set drdopdown query from url
 */
const updateDropdownQuery = (params, query, queryLen) => {
	let apiObj = [];
	for (let i = 1; i <= queryLen; i++) {
		if (params[query + i]) {
			let options = params[query + i].split(INVOICE_CONSTANTS.PARAM_SEPERATOR);
			const head = options[0];
			options.shift();
			const queryParams = [{
				header: head,
				value: options
			}];
			apiObj = [...apiObj, ...queryParams];
		}
	}
	return apiObj;
};

/**
 * params : datefilter object, start date, end date
 * returns formatted date filter object with months and quarter value
 */
const getDateFilterObj = (dateFilter, from, to, appliedFilterKey, key) => {
	const month = getDateFilterKeys(APPLICATION_CONSTANTS.TODAY);
	const monthLength = month.length;
	const filterObj = month.slice(0, monthLength - APPLICATION_CONSTANTS.QUARTER).map((m, i) => {
		const monthFlt = getMonthFilter(m, key, appliedFilterKey);
		monthFlt.index = i;
		return monthFlt;
	});
	dateFilter[0].countKey = FILTER_CONSTANTS.CUSTOM_DATE_FILTER;
	textsConfig[FILTER_CONSTANTS.CUSTOM_DATE_FILTER] = getDateText(from, to, true);
	dateConfig[FILTER_CONSTANTS.CUSTOM_DATE_FILTER] = {
		startDate: from,
		endDate: to
	};
	const qtrFilter = getQtrFilter(month[monthLength - 1], key, appliedFilterKey);
	qtrFilter.index = filterObj.length;
	filterObj.push(qtrFilter);
	dateFilter[0].index = filterObj.length;
	filterObj.push(dateFilter[0]);
	return filterObj;
};

const getDateText = (start, end, showYear) => {
	let text = '';
	try {
		const startDate = moment(new Date(start));
		const endDate = moment(new Date(end));
		text = `${startDate.format('MMM')}${showYear ? `'` + startDate.format('YY') : ''}`;
		if (end) {
			text += `- ${endDate.format('MMM')}${showYear ? `'` + endDate.format('YY') : ''}`;
		}
	} catch (error) {

	}
	return text;
};
/**
 * params : quarter start month
 * returns formatted quarter filter object quarter value
 */
const getMonthFilter = (m, key, appliedFilterKey) => {
	const year = APPLICATION_CONSTANTS.TODAY.getFullYear();
	const month = APPLICATION_CONSTANTS.TODAY.getMonth();
	const text = `${getShortMonth(m)} ${year}`;
	textsConfig[m] = text;
	const start = getFormattedDate(new Date(year, m, 1), INVOICE_CONSTANTS.API_DATE_FORMAT);
	const end = getFormattedDate(new Date(year, m + 1, 0), INVOICE_CONSTANTS.API_DATE_FORMAT);
	let trackText = 'currentmonth';
	if (m === month - 1) {
		trackText = 'previousmonth';
	}
	if (m === month - 2) {
		trackText = 'previousmonth2';
	}
	dateConfig[m] = {
		startDate: start,
		endDate: end
	};
	return {
		type: m,
		label: text,
		value: m,
		checked: false,
		start: start,
		end: end,
		key: key ? key : INVOICE_CONSTANTS.DATE_FILTER,
		filterKey: key ? key : INVOICE_CONSTANTS.DATE_FILTER,
		trackVal: 'date_' + trackText,
		appliedFilterKey: appliedFilterKey
	};
};

/**
 * params : quarter start month
 * returns formatted quarter filter object quarter value
 */
const getQtrFilter = (qtrStart, key, appliedFilterKey) => {
	const currDate = APPLICATION_CONSTANTS.TODAY;
	const currMonth = currDate.getMonth();
	const year = currMonth > qtrStart ? currDate.getFullYear() : currDate.getFullYear() - 1;
	const start = getFormattedDate(new Date(year, qtrStart, 1), INVOICE_CONSTANTS.API_DATE_FORMAT);
	const end = getFormattedDate(new Date(year, qtrStart + INVOICE_CONSTANTS.QUARTER, 0), INVOICE_CONSTANTS.API_DATE_FORMAT);
	const text = `QTR ${(qtrStart) / INVOICE_CONSTANTS.QUARTER + 1 } - (${getDateText(start, end, false)})`;
	textsConfig[INVOICE_CONSTANTS.QTR_FILTER] = text;
	dateConfig[INVOICE_CONSTANTS.QTR_FILTER] = {
		startDate: start,
		endDate: end
	};
	return {
		type: INVOICE_CONSTANTS.QTR_FILTER,
		label: text,
		start: start,
		key: key ? key : INVOICE_CONSTANTS.DATE_FILTER,
		end: end,
		value: INVOICE_CONSTANTS.QTR_COUNT_NAME,
		checked: false,
		filterKey: key ? key : INVOICE_CONSTANTS.DATE_FILTER,
		trackVal: 'date_lastquarter',
		appliedFilterKey: appliedFilterKey
	};
};

/**
 * params : list api response
 * format invoice data to be rendered on UI
 */
const formatListData = (data, isAllSelected, mmtCount = 0) => {
	const apiList = data.bookingDetailList;
	const selectedList = {};
	if (!apiList || apiList.length == 0) {
		return {
			list: [],
			lastIndex: false,
			selectedList: selectedList,
			mmtCount: mmtCount
		};
	}
	const listArr = apiList.map((invoice) => {
		const invoiceObj = {};
		invoiceObj.bookDate = getFormattedDate(invoice.created, INVOICE_CONSTANTS.BOOK_DATE_FORMAT);
		invoiceObj.bookId = invoice.bookingId;
		invoiceObj.id = invoice.id;
		invoiceObj.gstn = invoice.customerGSTNo;
		invoiceObj.travDate = getFormattedDate(invoice.travelStartDate, INVOICE_CONSTANTS.BOOK_DATE_FORMAT);
		invoiceObj.issuer = invoice.issuer;
		const dCount = invoice.bookingInvoices ? invoice.bookingInvoices.length : 0;
		invoiceObj.downloadCount = dCount;
		invoiceObj.countTxt = `${dCount} of ${invoice.invoiceExpected}`;
		invoiceObj.isAllAvailble = dCount === invoice.invoiceExpected;
		invoiceObj.type = invoice.lob ? invoice.lob.toLocaleLowerCase() : '';
		invoiceObj.isNew = invoice.newInvoiceExists;
		invoiceObj.invoiceType = invoice.invoiceType ? invoice.invoiceType.toLocaleLowerCase() : '';
		invoiceObj.counTooltip = invoice.airlineCode && InvoiceAirlines.includes(invoice.airlineCode.toUpperCase()) ? false : (invoice.airlineCode ? `Please visit the ${invoice.issuer} website to get the vendor invoice` : false );
		if (isAllSelected) {
			selectedList[invoice.id] = true;
			mmtCount += (invoiceObj.invoiceType === INVOICE_CONSTANTS.MMT_TYPE ? dCount : 0);
		}
		invoiceObj.invoiceTooltips = invoice.invoiceTooltips;
		return invoiceObj;
	});
	return {
		list: listArr,
		lastIndex: apiList[apiList.length - 1].created,
		selectedList: selectedList,
		mmtCount: mmtCount
	};
};

/**
 * params : count api response, current filter obj
 * format invoice bundle data and filters count
 */
const formatCount = (data, filters, query, lastFlt) => {
	const invoiceResponseCount = data ? data.invoiceResponseCount : false;
	const invoiceBundles = cloneDeep(bundleObject);
	filters.initCount = {};
	if (invoiceResponseCount && invoiceResponseCount.monthWiseInvoices && !isEmpty(invoiceResponseCount.monthWiseInvoices)) {
		const countObj = invoiceResponseCount.monthWiseInvoices;
		const filterMonths = getDateFilterKeys(APPLICATION_CONSTANTS.TODAY);
		invoiceBundles.filterMonths = filters.dateRange.value && filters.dateRange.value !== '' ? [filters.dateRange.value] : [...filterMonths];
		for (const key in countObj) {
			const filterLength = filterMonths.length;
			const lastQuarter = filterMonths.slice(filterLength - INVOICE_CONSTANTS.QUARTER, filterLength);
			let monthint = 0;
			try {
				monthint = Math.abs(parseInt(key));
			} catch (error) {
				monthint = parseInt(key);
			}
			const monthObject = countObj[key];
			formatBundle(monthint, monthObject, lastQuarter, invoiceBundles, query, filters.initCount, lastFlt);
		}
		// remove all bundle from date and qtr bundles if only one lob is present
		const allBundles = invoiceBundles[INVOICE_CONSTANTS.CUSTOM_DATE_FILTER];
		const qtBundle = invoiceBundles.monthData ? invoiceBundles.monthData[INVOICE_CONSTANTS.QTR_COUNT_NAME] : false;
		if (allBundles && allBundles.bundleCount === 2 && allBundles.bundleData[INVOICE_CONSTANTS.ALL_COUNTS]) {
			delete (allBundles.bundleData[INVOICE_CONSTANTS.ALL_COUNTS]);
			allBundles.bundleCount--;
		}
		if (qtBundle && qtBundle.bundleCount === 2 && qtBundle.bundleData[INVOICE_CONSTANTS.ALL_COUNTS]) {
			delete (qtBundle.bundleData[INVOICE_CONSTANTS.ALL_COUNTS]);
			qtBundle.bundleCount--;
		}
	}
	// const updatedFilters = setFilterCount(invoiceBundles, cloneDeep(filters), query, lastFlt);
	return {
		bundleData: invoiceBundles,
		filters: cloneDeep(filters),
		isAllCount: isAllFilterCount(query)
	};
};

const isAllFilterCount = (query) => {
	const fltDates = getDefaultDate(APPLICATION_CONSTANTS.TODAY, INVOICE_CONSTANTS.API_DATE_FORMAT);
	if (getDaysDiff(query.startDate, fltDates.startDate) <= 0 && getDaysDiff(query.endDate, fltDates.endDate) >= 0) {
		return true;
	}
	return false;
};

/**
 * params : month, count obj, last qaurter months array, main bundleobject
 * format invoice bundle for month and quarter
 */

const formatBundle = (key, monthObj, lastQuarter, invoiceBundles, query, initCount, lastFlt) => {
	let isQtr = false;
	let count = 0;
	if (lastQuarter.includes(key)) {
		invoiceBundles.monthData[INVOICE_CONSTANTS.QTR_COUNT_NAME] = invoiceBundles.monthData[INVOICE_CONSTANTS.QTR_COUNT_NAME] || cloneDeep(bundleData);
		isQtr = true;
	}
	invoiceBundles.monthData[key] = cloneDeep(bundleData);
	const allBundles = invoiceBundles[INVOICE_CONSTANTS.CUSTOM_DATE_FILTER];
	const showAllInvoiceBundle = monthObj && monthObj.length > 2;
	monthObj.forEach((countObj) => {
		filterBundleObject(countObj, query, key, lastFlt);
		if (countObj.orderType && countObj.totalInvoices) {
			const type = countObj.orderType;
			count += type !== INVOICE_CONSTANTS.ALL_COUNTS ? countObj[INVOICE_CONSTANTS.MMT_TYPE_KEY] + countObj[INVOICE_CONSTANTS.GST_TYPE_KEY] : 0;
			if (showAllInvoiceBundle && type === INVOICE_CONSTANTS.ALL_COUNTS || type !== INVOICE_CONSTANTS.ALL_COUNTS) { // show only one bundle if no other lob bundles are present
				upadateTotalCount(countObj, invoiceBundles.totalCounts);
				invoiceBundles.monthData[key].bundleCount++;
				invoiceBundles.monthData[key].bundleData[type] = countObj;
			}
			if (isQtr) { // get qtr bundle
				const qtBundle = invoiceBundles.monthData[INVOICE_CONSTANTS.QTR_COUNT_NAME].bundleData;
				if (typeof qtBundle[type] === 'undefined') {
					invoiceBundles.monthData[INVOICE_CONSTANTS.QTR_COUNT_NAME].bundleCount++;
				}
				qtBundle[type] = getBundleUIObject(countObj, qtBundle[type]);
				qtBundle[type].dateText = textsConfig[INVOICE_CONSTANTS.QTR_FILTER];
				qtBundle[type].dates = dateConfig[INVOICE_CONSTANTS.QTR_FILTER];
			}
			// get all bundle
			if (typeof allBundles.bundleData[type] === 'undefined') {
				allBundles.bundleCount++;
			}
			allBundles.bundleData[type] = getBundleUIObject(countObj, allBundles.bundleData[type]);
			allBundles.bundleData[type].dateText = textsConfig[INVOICE_CONSTANTS.CUSTOM_DATE_FILTER];
			allBundles.bundleData[type].dates = dateConfig[INVOICE_CONSTANTS.CUSTOM_DATE_FILTER];
		}
	});
	invoiceBundles.monthData[key].count = count;
	// initCount[key] = count;
	if (isQtr) {
		invoiceBundles.monthData[INVOICE_CONSTANTS.QTR_COUNT_NAME].count += count;
		// initCount[INVOICE_CONSTANTS.QTR_COUNT_NAME] = invoiceBundles.monthData[INVOICE_CONSTANTS.QTR_COUNT_NAME].count;
	}
	allBundles.count += count;
};

/**
 * params : bundleobject, current query, month
 * format invoice bundle for according to filter applied
 */

const filterBundleObject = (countObj, query, key, lastFlt = {}) => {
	let mmtKey = INVOICE_CONSTANTS.MMT_TYPE_KEY;
	let gstKey = INVOICE_CONSTANTS.GST_TYPE_KEY;
	let showGst = true;
	let showMmt = true;
	let newInvoice = true;
	if (query.invoiceStatus && query.invoiceStatus.length == 1) {
		mmtKey = query.invoiceStatus[0] === 'NEW' ? INVOICE_CONSTANTS.NEW_MMT_KEY : INVOICE_CONSTANTS.DOWNLOAD_MMT_KEY;
		gstKey = query.invoiceStatus[0] === 'NEW' ? INVOICE_CONSTANTS.NEW_GST_KEY : INVOICE_CONSTANTS.DOWNLOAD_GST_KEY;
		newInvoice = query.invoiceStatus[0] === 'NEW';
	}
	countObj[INVOICE_CONSTANTS.DOWNLOAD_MMT_KEY] = countObj[INVOICE_CONSTANTS.MMT_TYPE_KEY] - countObj[INVOICE_CONSTANTS.NEW_MMT_KEY];
	countObj[INVOICE_CONSTANTS.DOWNLOAD_GST_KEY] = countObj[INVOICE_CONSTANTS.GST_TYPE_KEY] - countObj[INVOICE_CONSTANTS.NEW_GST_KEY];
	if (query.invoiceType) {
		showMmt = query.invoiceType[INVOICE_CONSTANTS.MMT_TYPE];
		showGst = query.invoiceType[INVOICE_CONSTANTS.GST_TYPE];
	}
	countObj[INVOICE_CONSTANTS.MMT_TYPE_KEY] = countObj[mmtKey];
	countObj[INVOICE_CONSTANTS.GST_TYPE_KEY] = countObj[gstKey];
	let mmtInvoiceCount = showMmt ? countObj[mmtKey] : 0;
	let gstInvoiceCount = showGst ? countObj[gstKey] : 0;
	countObj.totalInvoices = mmtInvoiceCount + gstInvoiceCount;
	countObj.newInvoice = newInvoice ? countObj.newInvoice : false;
	let year = APPLICATION_CONSTANTS.TODAY.getFullYear();
	const month = APPLICATION_CONSTANTS.TODAY.getMonth();
	if (key > month) {
		year--;
	}
	countObj.dates = {
		startDate: getFormattedDate(new Date(year, key, 1), INVOICE_CONSTANTS.API_DATE_FORMAT),
		endDate: getFormattedDate(new Date(year, key + 1, 0), INVOICE_CONSTANTS.API_DATE_FORMAT)
	};
	countObj.dateText = `${getShortMonth(key)}'${year.toString().substr(2, 2)}`;
	if (query.lob && query.lob.indexOf(countObj.orderType) === -1 && lastFlt.filterKey == 'orderType') {
		countObj.totalInvoices = 0;
	}
};

/**
 * params : invoice bundle api obj, main bundleobject counts
 * format count values for invoice
 */
const upadateTotalCount = (countObj, totalCounts) => {
	const type = countObj.orderType;
	if (totalCounts[type]) {
		totalCounts[type] += countObj.totalInvoices;
	} else {
		totalCounts[type] = countObj.totalInvoices;
	}
	if (type !== INVOICE_CONSTANTS.ALL_COUNTS) {
		totalCounts[INVOICE_CONSTANTS.MMT_TYPE] += countObj.mmtInvoices;
		totalCounts[INVOICE_CONSTANTS.GST_TYPE] += countObj.gstInvoices;
		totalCounts.total += countObj.totalInvoices;
		totalCounts.new += (countObj[INVOICE_CONSTANTS.NEW_GST_KEY] + countObj[INVOICE_CONSTANTS.NEW_MMT_KEY]);
		totalCounts.mmtDownloaded += (countObj[INVOICE_CONSTANTS.MMT_TYPE_KEY] - countObj[INVOICE_CONSTANTS.NEW_MMT_KEY]);
		totalCounts.gstDownloaded += (countObj[INVOICE_CONSTANTS.GST_TYPE_KEY] - countObj[INVOICE_CONSTANTS.NEW_GST_KEY]);
	}

	totalCounts.lastUpdate = totalCounts.lastUpdate > countObj.lastUpdate ? countObj.lastUpdate : totalCounts.lastUpdate;
};

/**
 * params : api invoice api object, bundle
 * format invoice bundle for each card
 */
const getBundleUIObject = (countObj, bundle) => {
	if (typeof bundle === 'undefined') {
		return {
			...countObj
		};
	}
	bundle[INVOICE_CONSTANTS.MMT_TYPE_KEY] += countObj[INVOICE_CONSTANTS.MMT_TYPE_KEY];
	bundle[INVOICE_CONSTANTS.GST_TYPE_KEY] += countObj[INVOICE_CONSTANTS.GST_TYPE_KEY];
	bundle.totalInvoices += countObj.totalInvoices;
	bundle[INVOICE_CONSTANTS.NEW_GST_KEY] += countObj[INVOICE_CONSTANTS.NEW_GST_KEY];
	bundle[INVOICE_CONSTANTS.NEW_MMT_KEY] += countObj[INVOICE_CONSTANTS.NEW_MMT_KEY];
	bundle.newInvoice = bundle.newInvoice || countObj.newInvoice;
	bundle.lastUpdate = bundle.lastUpdate > countObj.lastUpdate ? countObj.lastUpdate : bundle.lastUpdate;
	return bundle;
};

/**
 * params : formatted bundle object
 * update count values for each filter
 */
const setFilterCount = (invoiceBundles, filters, query, lastFlt) => {
	const monthData = invoiceBundles.monthData;
	filters.dateRange.options.forEach((dateObj) => {
		dateObj.count = dateObj.type === INVOICE_CONSTANTS.CUSTOM_DATE_FILTER ?
			(dateObj.checked ? invoiceBundles.totalCounts.total : '' ) :
			(monthData[dateObj.value] && monthData[dateObj.value].count);
	});
	filters.orderType.options.forEach((orderObj) => {
		if (query.lob && lastFlt.filterKey === 'orderType') {
			orderObj.count = query.lob.includes(orderObj.value) ? (invoiceBundles.totalCounts[orderObj.value] || 0) : orderObj.count;
		} else {
			orderObj.count = invoiceBundles.totalCounts[orderObj.value] || 0;
		}
	});
	filters.invoiceType.options.forEach((invoiceObj) => {
		if (query.invoiceType && lastFlt.filterKey === 'invoiceType') {
			invoiceObj.count = query.invoiceType[invoiceObj.value] || !invoiceObj.count ? (invoiceBundles.totalCounts[invoiceObj.value] || 0) : invoiceObj.count;
		} else {
			invoiceObj.count = invoiceBundles.totalCounts[invoiceObj.value] || 0;
		}
	});
	return filters;
};

/**
 * params : current api query, filter object, get default initial query
 * get months value array for date filter
 */
const getFilteredQuery = (filterQuery, filter, initialQuery) => {
	const key = filter.key;
	let isDateChanged = false;
	filterQuery[INVOICE_CONSTANTS.START_DATE] = initialQuery[INVOICE_CONSTANTS.START_DATE];
	filterQuery[INVOICE_CONSTANTS.END_DATE] = initialQuery[INVOICE_CONSTANTS.END_DATE];
	filterQuery[INVOICE_CONSTANTS.INVOICE_START_DATE] = initialQuery[INVOICE_CONSTANTS.INVOICE_START_DATE];
	filterQuery[INVOICE_CONSTANTS.INVOICE_END_DATE] = initialQuery[INVOICE_CONSTANTS.INVOICE_END_DATE];
	if (key === INVOICE_CONSTANTS.DATE_FILTER && filter.selectedCount === 0) { // reset Date
		if (!(initialQuery[INVOICE_CONSTANTS.INVOICE_START_DATE] && initialQuery[INVOICE_CONSTANTS.INVOICE_END_DATE])) {
			const dates = getDefaultDate(APPLICATION_CONSTANTS.TODAY, INVOICE_CONSTANTS.API_DATE_FORMAT);
			filterQuery[INVOICE_CONSTANTS.START_DATE] = dates[INVOICE_CONSTANTS.START_DATE];
			filterQuery[INVOICE_CONSTANTS.END_DATE] = dates[INVOICE_CONSTANTS.END_DATE];
			filter.value = '';
		}
		isDateChanged = true;
	} else if (key === INVOICE_CONSTANTS.INVOICE_DATE_FILTER && filter.selectedCount === 0) {
		delete filterQuery[INVOICE_CONSTANTS.INVOICE_START_DATE];
		delete filterQuery[INVOICE_CONSTANTS.INVOICE_END_DATE];
		if (!(initialQuery[INVOICE_CONSTANTS.START_DATE] && initialQuery[INVOICE_CONSTANTS.END_DATE])) {
			const dates = getDefaultDate(APPLICATION_CONSTANTS.TODAY, INVOICE_CONSTANTS.API_DATE_FORMAT);
			filterQuery[INVOICE_CONSTANTS.START_DATE] = dates[INVOICE_CONSTANTS.START_DATE];
			filterQuery[INVOICE_CONSTANTS.END_DATE] = dates[INVOICE_CONSTANTS.END_DATE];
			filter.value = '';
		}
		isDateChanged = true;
	} else {
		filterQuery[key] = [];
	}

	if (filter.key === INVOICE_CONSTANTS.TRIP_TAG_FILTER && filter.value) {
		filterQuery[key] = getMultiDropdownQuery(filter.value);
	} else if (filter.key === INVOICE_CONSTANTS.INVOICE_FILTER) {
		filterQuery[key] = {};
		filter.options.forEach((option) => {
			if (option.checked) {
				filterQuery[key][option.value] = true;
				if (option.value === 'gst') {
					filterQuery[key]['vendorDetails'] = getMultiDropdownQuery(option.invoiceValue);
				} else if (option.value === 'mmt') {
					filterQuery[key]['invoiceActions'] = getMultiDropdownQuery(option.invoiceValue);
				}
			}
		});
	} else if (filter.key === INVOICE_CONSTANTS.LOB_TYPE_FILTER) {
		filterQuery['lobs'] = {};
		filter.options.forEach((option) => {
			filterQuery['lobs'][option.value] = { isSelected: false };
			if (option.checked ) {
				filterQuery['lobs'][option.value].isSelected = true;
				if (option.type === INVOICE_CONSTANTS.CUSTOM_DATE_FILTER) {
					if (option.start && option.end) {
						filterQuery['lobs'][option.value].travelDateRange = {
							startDate: `${option.start} ${INVOICE_CONSTANTS.START_TIME}`,
							endDate: `${option.end} ${INVOICE_CONSTANTS.END_TIME}`
						};
					}
				}
			}
		});
	} else if (filter.type === INVOICE_CONSTANTS.DROPDOWN) {
		filter.value && filter.value.forEach((val) => {
			filterQuery[key].push(val.value);
		});
	} else if (filter.type === INVOICE_CONSTANTS.CHECKBOX ) {
		filter.options.forEach((option) => {
			if (option.checked && (option.key === FILTER_CONSTANTS.DATE_FILTER ||
					option.key === FILTER_CONSTANTS.INVOICE_DATE_FILTER)) {
				filterQuery[filter.startkey] = option.start;
				filterQuery[filter.endKey] = option.end;
				if (option.type === FILTER_CONSTANTS.CUSTOM_DATE_FILTER) {
					textsConfig[FILTER_CONSTANTS.CUSTOM_DATE_FILTER] = getDateText(option.start, option.end, true);
					dateConfig[FILTER_CONSTANTS.CUSTOM_DATE_FILTER] = {
						[filter.startkey]: option.start,
						[filter.endKey]: option.end
					};
				}
				isDateChanged = true;
			} else if (option.checked) {
				filterQuery[key].push(option.value);
			}
		});
	}
	if (isEmpty(filterQuery[key])) {
		delete filterQuery[key];
	}
	filterQuery.isDateChanged = isDateChanged;
	return filterQuery;
};

/**
 * params : trip tag query object, trip tag value
 * return triptag query
 */
const getMultiDropdownQuery = (value) => {
	const query = {};
	value.map((opt) => {
		if (!query[opt.head]) {
			query[opt.head] = {
				header: opt.head,
				value: [opt.value]
			};
		} else {
			query[opt.head].value.push(opt.value);
		}
	});
	return Object.keys(query).map((key) => (query[key]));
};

/**
 * params : filter object
 * returns filter with options set from url query
 */
const presetFilters = (filters, appliedFilters) => {
	let query = getDefaultDate(APPLICATION_CONSTANTS.TODAY, INVOICE_CONSTANTS.API_DATE_FORMAT, false, APPLICATION_CONSTANTS.DEFAULT_QTR);
	const queryParams = getUrlParamsMap(typeof window !== 'undefined' && window.location.search);
	query = {
		...query,
		...queryParams
	};
	if (query.lob) {
		setFilter(query.lob, filters.orderType, appliedFilters);
	}
	if (query.status) {
		setFilter(query.status, filters.invoiceStatus, appliedFilters);
	}
	if (query.payment) {
		setFilter(query.payment, filters.paymentMethod, appliedFilters);
	}
	if (query.others) {
		setFilter(query.others, filters.others, appliedFilters);
	}
	if (query.type) {
		setFilter(query.type, filters.invoiceType, appliedFilters);
	}
	filters.dateRange.options = getDateFilterObj(filters.dateRange.options, query.startDate, query.endDate, filters.dateRange.appliedFilterKey);
	filters.invoiceDateRange.options = getDateFilterObj(filters.invoiceDateRange.options, query.startDate, query.endDate, filters.invoiceDateRange.appliedFilterKey, filters.invoiceDateRange.key);
	if (queryParams.startDate && queryParams.endDate) {
		filters.dateRange.options.forEach((opt) => {
			if (opt.type === INVOICE_CONSTANTS.CUSTOM_DATE_FILTER) {
				opt.checked = true;
				opt.start = query.startDate;
				opt.end = query.endDate;
			}
		});
	}

	let defaultSelected = filters.dateRange.options.filter((filterItem) => {
		if (filterItem.type === APPLICATION_CONSTANTS.DEFAULT_QTR) {
			filterItem.checked = true;
			filterItem.headText = 'Last Quarter';
			return filterItem;
		}
	});
	if (defaultSelected && defaultSelected[0]) {
		appliedFilters.push(defaultSelected[0]);
	}

	return {
		filters: filters,
		appliedFilters: appliedFilters
	};
};

/**
 * params : input query, filter to be set
 * returns update filter object with query
 */
const setFilter = (query = '', filter, appliedFilters) => {
	const val = query.split(INVOICE_CONSTANTS.PARAM_SEPERATOR);
	filter.options.forEach((opt) => {
		if (val.includes(opt.value)) {
			opt.checked = true;
			appliedFilters.push(opt);
		}
	});
};

/**
 * params : input filter options from api, main filter object
 * returns update filter options(gst,vendor,triptag)
 */
const fetchFilterOptions = (options, filters, appliedFilters) => {
	if (!options) {
		return {
			filters: filters,
			appliedFilters: appliedFilters
		};
	}
	const query = getUrlParamsMap(window.location.search);
	const flightOptions = options['flightSummary'] && formatOptions(options['flightSummary'], INVOICE_CONSTANTS.FLIGHT_TYPE, INVOICE_CONSTANTS.INVOICE_FILTER);
	const hotelOptions = options['hotelSummary'] && formatOptions(options['hotelSummary'], INVOICE_CONSTANTS.HOTEL_TYPE, INVOICE_CONSTANTS.INVOICE_FILTER);
	const gstOptions = options['customerGstNos'] && formatOptions(options['customerGstNos'], 'GSTN', 'gstn');
	let tagCount = 0;
	const tripTags = options['tripTags'] && options['tripTags'].map((trip) => {
		tagCount += trip.attributeValues ? trip.attributeValues.length : 0;
		return {
			label: trip.attributeName,
			options: formatOptions(trip.attributeValues, trip.attributeName, INVOICE_CONSTANTS.TRIP_TAG_FILTER)
		};
	});
	const mmtOptions = options['invoiceActions'] && mmtInvoiceFilter(options['invoiceActions'], 'MMT', 'MMT');
	filters.invoiceType.options[0].optionCount = 0;
	filters.invoiceType.options[0].options = [];
	if (mmtOptions && mmtOptions.length) {
		filters.invoiceType.options[0].optionCount = mmtOptions.length;
		filters.invoiceType.options[0].options.push({
			label: 'Invoice Actions',
			options: mmtOptions
		});
	}
	if (tripTags && tripTags.length) {
		filters.tripTags.options = tripTags;
		filters.tripTags.optionCount = tagCount;
	}
	filters.invoiceType.options[1].optionCount = 0;
	filters.invoiceType.options[1].options = [];
	if (flightOptions && flightOptions.length) {
		filters.invoiceType.options[1].optionCount += flightOptions.length;
		filters.invoiceType.options[1].options.push({
			label: 'Flight',
			options: flightOptions
		});
	}
	if (hotelOptions && hotelOptions.length) {
		filters.invoiceType.options[1].optionCount += hotelOptions.length;
		filters.invoiceType.options[1].options.push({
			label: 'Hotel',
			options: hotelOptions
		});
	}
	// preset triptag, gst, vendors filter according to url
	if (query.ttl) {
		const querylen = parseInt(query.ttl);
		const param = 'tag';
		filters.tripTags.value = [];
		const allOptions = tripTags;
		filters.tripTags.value = setDropdowFilterVal(query, param, querylen, allOptions, appliedFilters);
	}
	if (query.vln) {
		const querylen = parseInt(query.vln);
		const param = 'vnd';
		const allOptions = filters.invoiceType.options[1].options;
		filters.invoiceType.options[1].invoiceValue = setDropdowFilterVal(query, param, querylen, allOptions, appliedFilters);
	}
	if (gstOptions && gstOptions.length) {
		filters.gstn.options = gstOptions;
		filters.gstn.value = [];
		if (gstOptions.length === 1) {
			filters.gstn.value = gstOptions;
		}
		filters.gstn.optionCount = gstOptions.length;
		if (query.gstn) {
			const gstn = query.gstn.split(INVOICE_CONSTANTS.PARAM_SEPERATOR);
			filters.gstn.value = filters.gstn.options.filter((gst) => {
				if (gstn.indexOf(gst.value) > -1) {
					appliedFilters.push(gst);
					return true;
				}
				return false;
			});
		}
	}
	return {
		filters: filters,
		appliedFilters: appliedFilters
	};
};
/**
 * params : current query, queryparam name for filter, filteroptions, applied filters object
 * returns dropdown filter values according to url
 */

const setDropdowFilterVal = (query, param, querylen, allOptions, appliedFilters) => {
	let value = [];
	for (let i = 1; i <= querylen; i++) {
		if (!query[param + i]) {
			break;
		}
		let options = query[param + i].split(INVOICE_CONSTANTS.PARAM_SEPERATOR);
		const head = options[0];
		options.shift();
		allOptions.forEach((filter) => {
			const values = filter.options.filter((opt) => {
				if (options.indexOf(opt.value) > -1 && opt.head === head) {
					appliedFilters.push(opt);
					return true;
				}
			});
			value = [...value, ...values];
		});
	}
	return value;
};
/**
 * params : input filter options from api, heading text
 * returns formatted filter dropdown options
 */
const formatOptions = (options, head, type) => {
	if (!options) {
		return [];
	}
	return options.map((opt, i) => {
		let value = opt;
		let label = opt;
		if (typeof opt == 'object' && !Array.isArray(opt)) {
			const keys = Object.keys(opt);
			value = keys[0];
			label = opt[keys[0]];
		}
		return {
			'value': value,
			'label': label,
			'head': head,
			'filterKey': type,
			'type': 'dropdown',
			'index': i
		};
	});
};

/**
 * format mmt invoice type filter
 * @param {object} options
 * @param {String} head
 * @param {String} type
 * @return {Array} formattedResult
 */
const mmtInvoiceFilter = (options, head, type) => {
	if (!options) {
		return [];
	}
	let formattedResult = [];
	let count = 0;
	for (let opt in options) {
		const value = options[opt];
		const label = opt;
		formattedResult.push( {
			'value': value,
			'label': label,
			'head': head,
			'filterKey': type,
			'type': 'dropdown',
			'index': count
		});
		count += 1;
	}
	return formattedResult;
};

const getFilterCount = (data, filters, query) => {
	const initCount = filters.initCount;
	const invoiceResponseCount = data ? data.invoiceResponseCount : false;
	if (invoiceResponseCount && invoiceResponseCount.monthWiseInvoices && !isEmpty(invoiceResponseCount.monthWiseInvoices)) {
		const filterMonths = getDateFilterKeys(APPLICATION_CONSTANTS.TODAY);
		const filterLength = filterMonths.length;
		const lastQuarter = filterMonths.slice(filterLength - INVOICE_CONSTANTS.QUARTER, filterLength);
		let qtrCount = 0;
		for (let m in invoiceResponseCount.monthWiseInvoices) {
			const monthObj = invoiceResponseCount.monthWiseInvoices[m];
			if (!initCount[m] || true) {
				initCount[m] = 0;
				monthObj.forEach((countObj) => {
					if (countObj.orderType !== INVOICE_CONSTANTS.ALL_COUNTS) {
						filterBundleObject(countObj, query, m);
						initCount[m] += countObj.totalInvoices;
					}
				});
			}
			if (lastQuarter.includes(parseInt(m))) {
				qtrCount += initCount[m];
			}
		}
		initCount[INVOICE_CONSTANTS.QTR_FILTER] = qtrCount;
		filters.dateRange.options.forEach((dateObj) => {
			if ((dateObj.type !== INVOICE_CONSTANTS.CUSTOM_DATE_FILTER || dateObj.checked) && initCount[dateObj.type]) {
				dateObj.count = initCount[dateObj.type];
			}
		});
	}
	return filters;
};

// track Invoice Events
const trackInvoiceEvents = (eventType) => {
	logOmniClick(INVOICE_CONSTANTS.LINK_NAME, {
		prop54: eventType
	});
};

/**
 * update selected no of options of filter
 * params : current filter object, value of the filter
 */
const updateCount = (filter, value) => {
	if (value === false && filter.selectedCount > 0) {
		filter.selectedCount--;
	} else if (value === true) {
		filter.selectedCount++;
	}
	if (filter.type === INVOICE_CONSTANTS.DROPDOWN) {
		filter.selectedCount = value.length;
	}
	filter.showClear = filter.type === INVOICE_CONSTANTS.CHECKBOX && filter.selectedCount > 0;
};

/**
 * rmove filter from applied filters array
 * params : appliedFilters, option/filter to be removed
 */
const removeAppliedFilter = (appliedFilters, option) => {
	let arrIndex = false;
	for (let i in appliedFilters) {
		if (appliedFilters[i].key === option.key) {
			arrIndex = i;
			break;
		}
	}
	appliedFilters.splice(arrIndex, 1);
};

/**
 * clear subfilter if parent filter is unchecked
 * params : appliedFilters, changed filter value, option/filter to be removed, if clear filter clicked
 * returns applied filter array
 */
const handleSubFilter = (appliedFilters, value, option, isCLear) => {
	let selOption = false;
	if (isCLear && option.options) {
		selOption = option.options.filter((opt) => (opt.type === 'select'));
	} else {
		selOption = option;
	}
	if (!value && selOption) {
		selOption.forEach((opt) => {
			if (opt.type === 'select') {
				let valueKey = 'value';
				if (opt.filterKey === INVOICE_CONSTANTS.INVOICE_FILTER) {
					valueKey = INVOICE_CONSTANTS.INVOICE_VALUE;
				}
				opt[valueKey].forEach((selectopt) => {
					removeAppliedFilter(appliedFilters, selectopt);
				});
				opt[valueKey] = [];
			}
		});
	}
	return appliedFilters;
};

/**
 * params : remindersettings to be applied
 * return formatted reminder settings dispaled of UI
 */
const getReminderSettings = (setting = {}) => ({
	frequency: setting.frequency,
	granular: setting.is_granular,
	granularDay: setting.granular_data.on_day,
	granularPeriod: setting.granular_data.every_period,
	enable: setting.is_enable
});

/**
 * formats the request params
 * @param {Object} params requestParams
 * @param {Object} appliedFilter appliedFilter
 * @return {Object} formatted request params
 */
const formatRequestPayload = (params, appliedFilter) => {
	const request = { ...params, dateRange: {} };
	if (appliedFilter && appliedFilter.length > 0) {
		if (params.invoiceStartDate && params.invoiceEndDate) {
			request.dateRange['invoiceDateRange'] = {
				startDate: `${params.invoiceStartDate} ${INVOICE_CONSTANTS.START_TIME}`,
				endDate: `${params.invoiceEndDate} ${INVOICE_CONSTANTS.END_TIME}`
			};
		}
		const hasIDR = hasInvoiceDateRange(appliedFilter);
		const hasBDR = hasBookingDateRange(appliedFilter);
		if (hasIDR.length === 0 || hasBDR.length > 0) {
			request.dateRange['bookingDateRange'] = {
				startDate: `${params.startDate} ${INVOICE_CONSTANTS.START_TIME}`,
				endDate: `${params.endDate} ${INVOICE_CONSTANTS.END_TIME}`
			};
		}
	} else if (params.startDate && params.endDate) {
		request.dateRange['bookingDateRange'] = {
			startDate: `${params.startDate} ${INVOICE_CONSTANTS.START_TIME}`,
			endDate: `${params.endDate} ${INVOICE_CONSTANTS.END_TIME}`
		};
	}

	delete (request.invoiceStartDate);
	delete (request.invoiceEndDate);
	delete (request.startDate);
	delete (request.endDate);

	return request;
};

const hasInvoiceDateRange = (appliedFilter) => appliedFilter.filter((ftr) => ftr.key === 'invoiceDateRange');

const hasBookingDateRange = (appliedFilter) => appliedFilter.filter((ftr) => ftr.key === 'dateRange');
/**
 * formats and returns the formatted invoice sumaary
 * @author mmt8054
 * @param { Object } summary frequency field from redux store
 * @return {Object} return array of summary
 */
const formatSummaryData = (summary) => summary.map((item) => {
	const { lob, data } = item;
	return ({
		lob: lob.toLowerCase(),
		data
	});
});

const getaddedFilter = (filter, appliedFilters) => {
	let arrIndex = -1;
	for (let i in appliedFilters) {
		if (appliedFilters[i].value === filter.value && appliedFilters[i].key === filter.key) {
			arrIndex = i;
			break;
		}
	}
	return arrIndex;
};

export default {
	getInvoiceListParams,
	formatListData,
	formatCount,
	getFilteredQuery,
	presetFilters,
	fetchFilterOptions,
	getDefaultDate,
	getFilterCount,
	trackInvoiceEvents,
	updateCount,
	handleSubFilter,
	removeAppliedFilter,
	getReminderSettings,
	formatRequestPayload,
	formatSummaryData,
	getaddedFilter
};
