import React, { Component } from 'react';
import { Container } from 'commonStyles';
import { HeaderWrapper, Logo, HeaderInner } from './HeaderComponentStyles';
import { ASSET_PATH } from 'assetsPath';

const logo = ASSET_PATH.IMAGES + 'logo-header.png';

export default class Header extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<HeaderWrapper>
				<Container>
					<HeaderInner justify='space-between' align='center'>
						<a href={'/' + APPLICATION_PATH}><Logo src={logo} /></a>
					</HeaderInner>
				</Container>
			</HeaderWrapper>
		);
	}
}
