import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { GenericP, DropDownArrow } from '../../../common/Styles';
import { LoggedInUserAnchor, ProfileMenuItem, TextDetails, UserName } from './ProfileSectionStyles';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import { PATHS } from '../../../AppConstants';

const ProfileSection = () => {
	const profileData = useSelector((state) => state.bizHeader.profileData) || { loggedOut: true };

	const clickProfile = useCallback(() => {
		if (profileData.loggedOut) {
			window.location = PATHS.MYBIZ_LOGIN_EXPIRE_URL;
		}
		return;
	}, [profileData]);
	const { name, email, firstLetter } = profileData;
	return (
		<ProfileMenuItem align='center' onClick={clickProfile}>
			<LoggedInUserAnchor>
				<span className='profileName'>
					{firstLetter}
				</span>
				<TextDetails fsize='10'>
					<div>
						<GenericP f_weight='bold' fsize='10px'>
							{name ?
								<UserName className='ellipses'>
									Hey {name}
								</UserName> :
								<span>LOG IN!</span>}
						</GenericP>
						{typeof window === 'undefined' || window.innerWidth > 768 && <p>{email} </p>}
						{!profileData.loggedOut && <DropDownArrow className='arrow' />}
					</div>
				</TextDetails>
			</LoggedInUserAnchor>
			{!profileData.loggedOut && <ProfileMenu />}
		</ProfileMenuItem>
	);
};

export default ProfileSection;
