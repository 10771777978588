import React, { memo, useEffect, useRef, useState } from 'react';
import { components, createFilter } from 'react-select';

import {
	FieldWrapper,
	ErrorFld,
	InfoText,
	InputBoxStyle,
	FloatLabel,
	StyledReactSelect,
	TextAreaStyle,
	TextAreaWrapper
} from './FormFieldStyles';
import { isNumberKey, isActionKey, isDeviceMobile } from 'UtilityComponents/Util';
import { GenericSpan, GenericP, ClickText } from '../Styles';
import CheckBox from '../CheckBoxGrp/CheckBoxGrp';
import { SeachIcon } from 'commonStyles';
import { trackClickEventSimple } from 'omnitures';
import Radio from 'common/RadioBtn/index';

const isMobile = isDeviceMobile();

const isRange = (e, validation = {}) => {
	let isValid = false;
	const { min, max } = validation;
	const { target, keyCode, key } = e;
	let value = target.value;
	const isDecimal = keyCode === 190;
	try {
		value = (value + key);
		value = parseFloat(value);
	} catch (error) {

	}
	const isMaxUndefined = typeof max === 'undefined';
	const isMinUndefined = typeof min === 'undefined';
	const isNum = !isNaN(value);
	if (!isNum || (isMinUndefined && isMaxUndefined)) {
		return true;
	}
	isValid = isMinUndefined ? isValid : value >= min;
	isValid = isMaxUndefined ? isValid : value <= max;
	if (isDecimal && !isMaxUndefined && value === max) {
		isValid = false;
	}
	return isValid;
};

const scrollTo = (field) => {
	setTimeout(() => {
		field.scrollIntoView && isMobile && field.ref.current.scrollIntoView({ behavior: 'smooth' });
	}, 500);
};
const isError = (validation, value, defVal) => {
	let isValid = value && (!validation.validator || (validation.validator && validation.validator(value, defVal)));
	if (isValid && validation.regex) {
		const regex = typeof validation.regex === 'string' ? new RegExp(validation.regex) : validation.regex;
		isValid = regex.test(value);
	}
	if (typeof isValid === 'string' && isValid.length > 4 && !validation.msg) {
		return isValid;
	}
	return (!isValid ? validation.msg : false);
};

const SingleValue = (props = {}) => {
	const { data = {}, selectProps } = props;
	const { labelKey = 'label' } = selectProps;
	return (
		<components.SingleValue {...props}>
			{data && typeof data[labelKey] === 'object' ? data[labelKey].labelHead : data[labelKey]}
		</components.SingleValue>
	);
};

const ValueContainer = ({ children, ...props }) => (
	components.ValueContainer && (
		<components.ValueContainer {...props}>
			{!!children && (
				<SeachIcon style={{ position: 'static', marginRight: '10px' }} />
			)}
			{children}
		</components.ValueContainer>
	)
);

const Input = (props) => <components.Input {...props} isHidden={false} />;

export const InputBox = (props) => {
	const { field, handleChange, removeAction, mobileView, handleBlur, changeOnblurOnly } = props;
	const { customClass, label, outLabel, isMandatory, className, defaultValue, name, placeHolder,
		type, validation, maxLen, infoText, nocHangeError, errorOnBlur, ref: fieldref, isRemove, mmtTheme, rows, cols,
		showLimit, isDisabled = false, prefix, inputClass, inputHeight, fSize, width: propwidth, isOpenInput, noBlurChange = false } = field;
	const inputRef = fieldref || useRef();
	const [active, setActive] = useState(false);
	const [inputVal, setInputVal] = useState(field.value);
	const [error, setError] = useState(field.error);

	useEffect(() => {
		setError(field.error);
	}, [field.error]);

	useEffect(() => {
		setInputVal(field.value);
	}, [field.value]);

	const onFocus = () => {
		scrollTo(field);
		setActive(true);
	};
	const onBlur = (validation) => (e) => {
		const targetval = e.target.value;
		if (!noBlurChange) {
			if (targetval !== field.value) {
				onChange(validation, e);
				return;
			}
			if (targetval && validation && (field.type === 'tel' || nocHangeError) && validation.custom) {
				field.error = isError(validation.custom, targetval, defaultValue);
			}
			if (validation && validation.required && !targetval && errorOnBlur && !field.error) {
				field.error = error || validation.required.msg;
			}
		}

		setActive(false);
		setError(field.error);
		if (handleBlur) {
			handleBlur(field);
		}
		if (!noBlurChange && !nocHangeError && handleChange) {
			handleChange(targetval, e, field);
		}
	};
	const onChange = (validation, e) => {
		let targetval = e.target.value;
		if (field.formatVal) {
			targetval = field.formatVal(targetval);
		}
		field.value = targetval;
		field.error = false;
		if (targetval && validation && validation.custom && (!nocHangeError || error)) {
			field.error = isError(validation.custom, targetval);
		}
		if (validation && validation.required && !field.value && errorOnBlur && !field.error) {
			field.error = error || validation.required.msg;
		}
		setError(field.error);
		setInputVal(field.value);
		handleChange && !changeOnblurOnly && handleChange(targetval, e, field);
	};
	const onKeyDown = (e) => {
		const { type, focusNextField, validation } = field;
		if ((e.keyCode == 9 && focusNextField) || (e.keyCode == 13 && isMobile)) {
			e.preventDefault();
			if (focusNextField) {
				focusNextField();
			}
		}
		if ((type == 'tel' || type === 'number') && ((!isNumberKey(e) && !isActionKey(e)) || !isRange(e, validation))) {
			e.preventDefault();
			return false;
		}
		if (type == 'tel' && maxLen && e.target.value.length >= maxLen && isNumberKey(e)) {
			e.preventDefault();
			return false;
		}
	};

	const focusField = (e) => {
		inputRef && inputRef.current.focus();
	};

	const onRemoveAction = () => {
		field.value = '';
		field.error = false;
		setActive(false);
		setError(false);
		setInputVal(false);
		removeAction && removeAction();
	};

	const isMobileTheme = isMobile && !isOpenInput;

	const valueLength = (inputVal && inputVal.length) || 0;
	return (
		<FieldWrapper isOpenInput={isOpenInput} $width={propwidth} {...props} className={customClass} isDisabled={isDisabled}>
			{!isMobileTheme &&
				!mobileView &&
				label &&
				outLabel &&
				<GenericP className='fld-label' fsize={mmtTheme ? '0.857rem' : '1rem'} f_weight='bold' bottom_M={mmtTheme ? '6' : '10'}>{label}
					<GenericSpan left_M={isMobileTheme ? '2' : '5'} className='redText'>{isMandatory ? '*' : ''}</GenericSpan>
				</GenericP>}
			<label htmlFor={name} className={`${className || ''} ${prefix ? 'prefix-lbl' : ''}`}>
				{prefix && <span className='prefix'>{prefix}</span>}
				{type === 'textArea' ?
					<TextAreaWrapper mmtTheme={mmtTheme} ifError={(error) ? true : false}>
						<TextAreaStyle
							mmtTheme={mmtTheme}
							ifError={(error) ? true : false}
							ref={inputRef}
							placeholder={!isMobileTheme && !mobileView &&
								((!outLabel && label) ||
									placeHolder && (placeHolder + (placeHolder && !label && isMandatory ? '*' : '')))}
							type='textArea'
							name={name}
							onFocus={onFocus}
							onBlur={onBlur(validation)}
							maxLength={maxLen}
							onKeyDown={onKeyDown}
							value={inputVal}
							rows={rows}
							cols={cols}
							onChange={(e) => onChange(validation, e)}
							className='custom-scroll formField-textArea'
						/>
						{showLimit && (<span className='limitWrap'>{valueLength}/{maxLen}</span>)}
					</TextAreaWrapper> :
					(
						<InputBoxStyle
							isOpenInput={isOpenInput}
							greyTheme={inputClass}
							height={inputHeight}
							fSize={fSize}
							ref={inputRef}
							placeholder={!isMobileTheme && !mobileView && ((!outLabel && label) ||
								placeHolder && (placeHolder + (placeHolder && !label && isMandatory ? '*' : '')))}
							type={type}
							disabled={isDisabled}
							name={name}
							onFocus={onFocus}
							onBlur={onBlur(validation)}
							maxLength={maxLen}
							onKeyDown={onKeyDown}
							ifError={(error) ? true : false}
							value={inputVal}
							onChange={(e) => onChange(validation, e)}
							mmtTheme={mmtTheme}
							isMobile={isMobileTheme}
						/>
					)}
				{isRemove && <ClickText f_size='14' className='removeAct' onClick={onRemoveAction}>Remove</ClickText>}
				{(isMobileTheme || mobileView) &&
					<FloatLabel className='float-label' greyTheme={inputClass} focus={active} onClick={focusField} active={inputVal || active}>{label || placeHolder}
						<GenericSpan left_M={isMobileTheme ? '2' : '5'} className='redText'>{isMandatory ? '*' : ''}</GenericSpan>
					</FloatLabel>}
			</label>
			{(error) && (
				<>
					<ErrorFld className='error'>{error}</ErrorFld>
				</>
			)}
			{active && infoText && <InfoText>{infoText}</InfoText>}
		</FieldWrapper>
	);
};

export const SelectBox = (props) => {
	const { field, noScroll, focusNextField, handleInputChange, onChange, clearOnFocus, searchEditable = false } = props;
	const { isOpenTop, classPrefix = 'custom-styles-select', customClass, label, outLabel, isMandatory, options,
		name, infoText, ref, placeHolder, mmtTheme, labelKey = 'label',
		isDisabled = false, showSearchIcon, isLoading, selectStyles = {}, isClearable = false, isMulti = false, theme } = field;
	const [active, setActive] = useState(false);

	const isMMTtheme = theme == 'mmt' || mmtTheme;
	const isCMNtheme = theme == 'cmn';
	const isOutLabel = isCMNtheme;

	let selectRef = useRef();
	if (ref) {
		selectRef = ref;
	}

	const [inputVal, setInputVal] = useState();
	const [value, setValue] = useState(field.value);
	const [error, setError] = useState(field.error);

	useEffect(() => {
		setError(field.error);
	}, [field.error]);

	useEffect(() => {
		setValue(field.value);
	}, [field.value]);

	const renderLabel = ({ label = '', subText }) => {
		if (typeof label === 'string' && !subText) {
			return (<div>{label}</div>);
		} else {
			const labelHead = label.labelHead || label;
			const labelInfo = label.labelInfo || subText;
			return (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div style={{ marginBottom: '6px', fontWeight: '900', color: '#000' }}>{labelHead}</div>
					<div style={{ fontSize: '12px', color: '#4a4a4a' }}>{labelInfo}</div>
				</div>
			);
		}
	};
	const onFocus = () => {
		if (!noScroll) {
			scrollTo(field);
		}
		if (value && clearOnFocus) {
			setValue('');
		}
		value && selectRef.current?.inputRef?.select();
		infoText && setActive(true);
	};
	const handleChange = (val) => {
		field.error = false;
		field.value = val;
		setActive(false);
		setError(false);
		setValue(val);
		if (searchEditable) {
			const newInputVal = val && typeof val[labelKey] === 'object' ? val[labelKey].labelHead : val[labelKey];
			setInputVal(newInputVal);
		}
		focusNextField && focusNextField(name, val);
		onChange && onChange(val);
	};
	const handleOnInputChange = (value, action) => {
		if (action.action !== 'input-blur' && action.action !== 'menu-close' && action.action !== 'set-value') {
			handleInputChange && handleInputChange(value);
			searchEditable && setInputVal(value);
			!isMulti && searchEditable && setValue(value);
		}
	};

	const components = showSearchIcon ? { Input, ValueContainer, SingleValue } : { SingleValue };
	return (
		<FieldWrapper {...props} className={customClass}>
			{!isMobile && label && outLabel &&
				<GenericP className='fld-label' fsize='1rem' f_weight='bold' bottom_M='10'>{label}
					<GenericSpan left_M='5' className='redText'>{isMandatory ? '*' : ''}</GenericSpan>
				</GenericP>}
			<label htmlFor={name}>
				<StyledReactSelect
					isMulti={isMulti}
					ref={selectRef}
					placeholder={placeHolder || label}
					onChange={handleChange}
					ifError={!!(field.error)}
					classNamePrefix={classPrefix}
					options={options}
					autoComplete='off'
					onFocus={onFocus}
					formatOptionLabel={renderLabel}
					components={components}
					filterConfig={createFilter({ matchFrom: 'start' })}
					isSearchable={field.isSearchable}
					value={value}
					isOpenTop={isOpenTop}
					mmtTheme={isMMTtheme}
					onInputChange={handleOnInputChange}
					isDisabled={isDisabled}
					labelKey={labelKey}
					isLoading={isLoading}
					inputValue={inputVal}
					styles={selectStyles}
					isClearable={isClearable}
				/>
			</label>
			{isOutLabel &&
				<FloatLabel f_weight='700' color='#7A6A7D' fsize='11px'>{label || placeHolder}
					<GenericSpan fsize='11px' left_M='5' className='redText'>{isMandatory ? '*' : ''}</GenericSpan>
				</FloatLabel>}
			{error && <ErrorFld className='error'>{error}</ErrorFld>}
			{active && infoText && <InfoText>{infoText}</InfoText>}
		</FieldWrapper>
	);
};

export const LabelText = (props) => {
	const { field } = props;
	const { customClass, label, infoText, isMandatory, outLabel } = field;
	return (
		<FieldWrapper {...props} className={customClass}>
			{!isMobile && label && outLabel &&
				<GenericP className='fld-label' fsize='1.15rem' f_weight='900' bottom_M='10' top_M='10'>{label}
					<GenericSpan left_M='5' className='redText'>{isMandatory ? '*' : ''}</GenericSpan>
				</GenericP>}
			{infoText && <InfoText>{infoText}</InfoText>}
		</FieldWrapper>
	);
};

export const CheckBoxInput = (props) => {
	const { field, onChange, handleClick } = props;
	const [error, setError] = useState(field.error);

	useEffect(() => {
		setError(field.error);
	}, [field.error]);

	const onChangeInput = (index) => (e) => {
		const { checked } = e.target;
		field.value = checked;
		field.options[index].value = checked;
		setError(false);
		if (onChange) {
			onChange(field.value);
		}
	};

	const onClick = (option) => () => {
		if (option.ref) {
			option.ref.current.click();
		}

		if (handleClick) {
			handleClick(option);
		}
	};

	return (
		<FieldWrapper bottom_m={20} className={field.customClass}>
			{field.options.map((option, i) => (
				<CheckBox
					field={option}
					key={option.label}
					isChecked={option.value}
					isDisabled={option.isDisabled}
					checkOnly={option.checkOnly}
					ref={field.ref}
					handleChange={onChangeInput(i)}
					onMouseDown={onClick(option)}
					customStyle={field.customClass + ' ' + (option.customClass ? option.customClass : '')}
				/>
			))}
			{error && <ErrorFld className='error'>{error}</ErrorFld>}
		</FieldWrapper>
	);
};

export const RadioButtonGroup = (({ field, onChange }) => {
	const { error: fieldError, focusNextField, customClass, name } = field;

	const [error, setError] = useState(fieldError);
	const [value, setValue] = useState(field.value);
	useEffect(() => {
		setValue(field.value);
	}, [field.value?.value]);

	useEffect(() => {
		setError(fieldError);
	}, [fieldError]);

	const onRadioChange = (option) => (e) => {
		field.value = option;
		setError(false);
		setValue(option);

		if (option.trackVal) {
			trackClickEventSimple(option.trackVal.trim() + '_selected');
		}
		if (focusNextField) {
			focusNextField('role', option.value);
		}
		if (onChange) {
			onChange(option.value, option);
		}
	};
	const onClick = (option) => () => {
		if (option.ref) {
			option.ref.current.click();
		}
	};

	return (
		<FieldWrapper bottom_m={20} className={customClass}>
			{field.options.map((option, i) => (
				<div key={option.value} ref={field.ref} onMouseDown={onClick(option)} value={value?.value}>
					<Radio
						{...option}
						name={name}
						isChecked={value?.value == option.value}
						changeHandler={onRadioChange(option)}
					/>
				</div>
			))}
		</FieldWrapper>
	);
});
