// Omniture
import { logOmniClick } from 'omnitures';

// Config
import { SUPPORT_REQUESTS_CONFIG } from './config';

/**
 * Tracks an MSR event using Omniture.
 *
 * @param {string} trackEvent - The event to be tracked.
 * @param {string} [trackVar='prop50'] - The variable to be used for tracking. Defaults to 'prop50'.
 */
export const trackMSREvent = (trackEvent, trackVar = 'prop50') => {
	logOmniClick(SUPPORT_REQUESTS_CONFIG.omnitureConfig.eventName, {
		[trackVar]: trackEvent
	});
};
