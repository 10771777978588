import React, { useEffect } from 'react';

import Modal from '../Modal/Modal';
import { Heading, GenericSpan, GenericP, FlexDiv } from '../Styles';
import { BackButton, WhatsappCallBackFooter } from './HelpSectionStyles';
import { ShortListIcons } from '../../shortListNShare/Styles';
import { logOmniClick } from 'omnitures';

const WhatsAppCallback = ({ closeModal, backHandler, whatsappDetails = {} }) => {
	useEffect(()=>{
		logOmniClick('whatsapp_shown', {
			prop54: 'whatsapp_shown'
		});
	}, []);

	return (
		<Modal
			$width='505px'
			btnType='Flat'
			btnName='Primary'
			handleModal={closeModal}>
			<BackButton onClick={backHandler}>
				<ShortListIcons icon='icRightArrow' className='back-arrow' color='#9b9b9b' left />
			</BackButton>
			<Heading marBt='20px' as='h3' f_weight='900'>
				Chat with us on <GenericSpan fsize='inherit' color='#0F9D58'>Whatsapp!</GenericSpan>
			</Heading>
			<FlexDiv>
				<FlexDiv $direction='column' justify='space-between' right_M='5'>
					<div>
						<GenericP fsize='11px' bottom_M='3'>Let us help you know more about myBiz.</GenericP>
						<GenericP right_P='50' fsize='16px' f_weight='700'>Scan the QR Code on your phone to chat with us on Whatsapp!</GenericP>
					</div>
					<WhatsappCallBackFooter top_P='16' fsize='12px'>Or, drop us a “Hi” on {whatsappDetails.phone} on Whatsapp!</WhatsappCallBackFooter>
				</FlexDiv>

				<img $width='150' height='150' loading='lazy' src={whatsappDetails.qrCode} />
			</FlexDiv>

		</Modal>
	);
};

export default WhatsAppCallback;
