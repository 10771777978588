import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HeaderWrpapper } from './Styles';
import { getHeaderOptions, getProfile, updateHeaderOptions } from 'reduxStore/actions/bizHeaderActions';
import { getCookie, redirectTologinWithUrl, saveServerLogs } from '../UtilityComponents/Util';
import BarComponent from './components/HeaderBar/HeaderBar';
import DHeader from '../MMTHeader';
import { updatePopup } from 'reduxStore/actions/popupActions';
import { ADMIN_REQ_POPUP } from './config';
import { APPLICATION_CONSTANTS, PATHS } from 'AppConstants';
import { getAbExperiment } from 'apis/commonApis';

const onLogout = () => typeof window !== 'undefined' && window.location.reload();

const Header = (props) => {
	const {
		mmtHeader,
		blockClick,
		showHeader = true,
		showNav = true,
		showBreadCrumb,
		reInitializeHeader,
		onLoginFlowComplete,
		helpInHeader,
		showHeaderOptionsBar = true
	} = props;

	const dispatch = useDispatch();

	const profileData = useSelector((state) => state.bizHeader.profileData);
	const profileApiFailed = useSelector((state) => state.bizHeader.profileApiFailed);
	const profileApiSucces = useSelector((state) => state.bizHeader.profileApiSucces);
	const profileApiCalling = useSelector((state) => state.bizHeader.profileApiCalling);
	const headerApiCall = useSelector((state) => state.bizHeader.headerApiCall);

	const headerOptions = useSelector((state) => state.bizHeader.headerOptions);
	const { isAdmin, isLoggedIn } = headerOptions;

	useEffect(() => {
		if (profileApiFailed || profileApiSucces) {
			if (isLoggedIn && !profileData) {
				redirectTologinWithUrl();
				saveServerLogs('login check failed invalid mmtauth no profile data found', 'error', window.location.pathname);
			}
			if (isAdmin && !profileData.isAdmin && false) { // need to handle superadmin redirection
				dispatch(updatePopup({
					showPop: true,
					popData: {
						...ADMIN_REQ_POPUP,
						redirectionUrl: PATHS.MMT_BIZ_PROFILE_URL
					}
				}));
			}
		}
	}, [profileApiFailed, profileApiSucces, profileData, isAdmin, isLoggedIn]);

	useEffect(() => {
		headerApiCall && !mmtHeader && dispatch(getHeaderOptions());
	}, [isLoggedIn, headerApiCall]);

	useEffect(() => {
		const mmtauth = getCookie('mmt-auth');
		if (mmtauth && isLoggedIn && profileApiCalling) {
			dispatch(getProfile());
		}
	}, [isLoggedIn, profileApiCalling]);

	useEffect(() => {
		const fetchAbData = async () => {
			if (!profileApiCalling) {
				let diceEnabled = true;
				let typeEnabledOrg = false;
				let hrmsEnabled = false;
				const abPlatformApi = APPLICATION_CONSTANTS.MYBIZ_CORE;
				try {
					const res = await getAbExperiment(abPlatformApi);
					diceEnabled = typeof res?.diceEnabled === 'undefined' ? true : res.diceEnabled;
					typeEnabledOrg = typeof res?.vip_enabled_org === 'undefined' ? false : res.vip_enabled_org;
					hrmsEnabled = typeof res?.['mmt.desktop.mybiz_core.default.default.default.hrms_enable'] === 'undefined' ? false : res['mmt.desktop.mybiz_core.default.default.default.hrms_enable'];
				} catch (error) { } finally {
					dispatch(updateHeaderOptions({ diceEnabled, typeEnabledOrg, hrmsEnabled }));
				}
			}
		};
		fetchAbData();
	}, [profileApiCalling]);

	const onLoginCompleteAction = useCallback(() => {
		if (!onLoginFlowComplete) {
			window.location.reload();
		} else {
			onLoginFlowComplete();
		}
	}, [onLoginFlowComplete]);

	return (
		<HeaderWrpapper mmtHeader={mmtHeader} showHeader={showHeader} showNav={showNav}>
			{mmtHeader ? <DHeader myBizOnlyLogin {...props} onLoginFlowComplete={onLoginCompleteAction} onLogout={onLogout} /> :
			<BarComponent
				headerOptions={headerOptions}
				blockClick={blockClick}
				showBreadCrumb={showBreadCrumb}
				reInitializeHeader={reInitializeHeader}
				helpInHeader={helpInHeader}
				showHeaderOptionsBar={showHeaderOptionsBar}
			/>}
		</HeaderWrpapper>
	);
};

export default Header;
