import { getXrequestId, isValidGSTNUmber } from 'UtilityComponents/Util';

const xreqId = getXrequestId();

export const PAGE_HEAD = 'GST Details';

export const formConfig = {
	heading: 'Please enter GST details',
	editHeading: 'Please update GST details',
	formFields: [{
		isMulti: false,
		errorOnBlur: true,
		placeHolder: '',
		value: '',
		name: 'gstn',
		label: 'GST Number',
		validation: {
			required: {
				msg: 'Please fill this to proceed.'
			},
			custom: {
				validator: isValidGSTNUmber,
				msg: 'Enter Correct GST Number'
			}
		},
		type: 'text',
		customClass: 'oneThird',
		padding: '20px 20px 0 0',
		outLabel: true
	}, {
		isMulti: false,
		errorOnBlur: true,
		placeHolder: '',
		value: '',
		name: 'address1',
		label: 'Billing address line 1',
		validation: {
			required: {
				msg: 'Please fill this to proceed.'
			}
		},
		type: 'text',
		customClass: 'oneThird',
		padding: '20px 20px 0 0',
		outLabel: true
	}, {
		isMulti: false,
		errorOnBlur: true,
		placeHolder: '',
		value: '',
		name: 'address2',
		label: 'Billing address line 2',
		type: 'text',
		customClass: 'oneThird',
		padding: '20px 0 0 0',
		outLabel: true
	},
	{
		isMulti: false,
		errorOnBlur: true,
		placeHolder: '',
		value: '',
		name: 'city',
		label: 'City',
		validation: {
			required: {
				msg: 'Please fill this to proceed.'
			}
		},
		type: 'text',
		customClass: 'oneThird',
		padding: '0 20px 0  0',
		outLabel: true
	},
	{
		isMulti: false,
		errorOnBlur: true,
		placeHolder: '',
		value: '',
		name: 'pinCode',
		label: 'PIN Code',
		validation: {
			required: {
				msg: 'Please fill this to proceed.'
			}
		},
		type: 'number',
		customClass: 'oneThird',
		padding: '0 20px 0  0',
		outLabel: true
	}
	],
	subHeading: 'You can enter multiple GST numbers for decentralized billing.',
	submitTxt: 'Add',
	editSubmitTxt: 'Update'
};

export const gstListConfig = {
	heading: 'List of added GST numbers',
	subHeading: '(For decentralized billing, add additional GST numbers)'
};

export const editBtnTxt = 'Edit';

export const noGstDataTxt = 'No Gst added for your organisation';

export const gstSuccessPopupTxt = {
	heading: 'GST Updated',
	content: 'GSTN details updated successfully. You will be able to see the changes in the list.'
};

export const gstFailedText = `Error updating GST details ${xreqId ? ` (error:${xreqId})` : ''}`;
