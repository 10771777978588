import React from 'react';

import { MoreWrapper, MoreContent } from './MoreOptionsStyles';
import LinkItem from '../LinkItem/LinkItem';
import { MainItem } from '../HeaderBar/HeaderBarStyles';
import { DropDownArrow } from '../../../common/Styles';

const MoreOptions = ({ moreData = {}, clickAction, totalFeatures, nextFeature }) => {
	const { options = [], active = false } = moreData;
	if (options.length === 0) {
		return null;
	}
	return (
		<MoreWrapper active={active}>
			<span className='text'>
				More <DropDownArrow className='arrow' />
			</span>
			<MoreContent className='content'>
				{options.map((item, idx) => (
					<MainItem key={idx} type='ver'>
						<LinkItem totalFeatures={totalFeatures} nextFeature={nextFeature} clickAction={clickAction} type='ver' key={item.key} item={item} />
						{item.options &&
						<div className='submenu'>
							{item.options.map((subopt) => (
								<LinkItem totalFeatures={totalFeatures} nextFeature={nextFeature} clickAction={clickAction} type='ver' key={subopt.key} item={subopt} />
							))}
						</div>}
					</MainItem>
				))}
			</MoreContent>
		</MoreWrapper>
	);
};

export default MoreOptions;
