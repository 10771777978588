import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import DayPicker from '../DateCalander/DayPicker/DayPicker';
import { SpriteIcon } from '../../policy/Style';
import {
	LabelText,
	DateRangeOuter,
	DateFields,
	DayPickerWrap,
	BoxWrapperRow
} from './InputStyles';
import { conditionalClassNames } from '../../UtilityComponents/Util';
import { FlexDiv, TransparentLayer } from '../Styles';

const defaultCalendarOptions = {
	year: 'numeric',
	month: 'short',
	day: 'numeric'
};

const SingleCalendar = ({
	value,
	onChange,
	label,
	required,
	inputProps = {},
	disabled,
	top_M_F,
	bottom_M,
	onCalenderSelect,
	bgColor,
	color,
	f_weight,
	top_M_D: topPos,
	padding,
	leftPos,
	rightPos,
	...props
}) => {
	const [activeCalander, setActiveCalander] = useState(false);
	const containerRef = useRef(null);

	useEffect(() => {
		const outsideClickHandler = () => {
			const elClicked = document.activeElement;
			if (containerRef && containerRef.current && !containerRef.current.contains(elClicked)) {
				setActiveCalander(false);
			}
		};
		window.addEventListener('click', outsideClickHandler);

		return () => {
			window.removeEventListener('click', outsideClickHandler);
		};
	}, []);

	useEffect(() => {
		onCalenderSelect && onCalenderSelect(activeCalander);
	}, [activeCalander, onCalenderSelect]);

	const handleCalander = useCallback((event) => {
		event && event.stopPropagation();
		setActiveCalander((c) => !c);
	}, []);

	const dateChangeHandler = useCallback((event) => {
		onChange(event);
		handleCalander();
	}, [onChange, handleCalander]);

	const { disabledDays = {}, calendarOptions = defaultCalendarOptions, customClass } = inputProps;

	const calendarRowClasses = conditionalClassNames('calOuter', 'materialInput', {
		'filled': !!value,
		'active': activeCalander && !disabled,
		[customClass]: customClass
	});

	return (
		<BoxWrapperRow
			className={calendarRowClasses}
			ref={containerRef}
			bottom_M={bottom_M}
			bgColor={bgColor}
			padding={padding}
			{...props}>
			<FlexDiv justify='space-between' onClick={handleCalander}>
				<div>
					{label && (
					<LabelText>
						{label}
						{required && (
						<span className='redText font14 latoBold'>*</span>
						)}
					</LabelText>
					)}

					<DateRangeOuter align='center'>
						<DateFields top_M={top_M_F} color={color} f_weight={f_weight}>
							{value && `${value.toLocaleDateString('en-GB', calendarOptions)}`}
						</DateFields>
					</DateRangeOuter>
				</div>
				<SpriteIcon icon='calender' />
			</FlexDiv>
			{activeCalander && !disabled && (
				<>
					<TransparentLayer onClick={handleCalander} zIndex={3} />
					<DayPickerWrap top_M={topPos} leftPos={leftPos} rightPos={rightPos}>
						<DayPicker
							from={value || new Date()}
							updateFromDate={dateChangeHandler}
							selectedType='from'
							isSingle
							disabledDays={disabledDays}
						/>
					</DayPickerWrap>
				</>
			)}
		</BoxWrapperRow>
	);
};

SingleCalendar.propTypes = {
	value: PropTypes.instanceOf(Date),
	label: PropTypes.string,
	onChange: PropTypes.func
};

export default SingleCalendar;
