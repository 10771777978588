import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { FeatureInfoWrapper,
	FeatureBackground,
	FeatureActiveBg,
	SubHeading,
	OnboardingTooltip } from './FeatureInfoStyles';
import { FlexDiv, GenericP } from '../Styles';
import Button from '../Button/Button';
import Arrow from './Arrow';
import StatusTag from 'common/statusTag';
/**
	* FeatureInfNew is used for arrow based coachmarks
	* @author mmt8067
	*`@param {Function} nextFeature to move to next feature description
	* @param {(String | JSX)} heading
	* @param {(String | JSX)} content
	* @param {Boolean} [last = false]
	* @param {String} step (count/totalfeatures)
	* @param {ref} scrollContainer
	* @param {Boolean} showFeature
	* @param {Number} totalFeatures
	* @param {String} [arrowPos="top-right"] - Direction of arrow ['top-left', 'top-right', 'bottom']
*/

const FeatureInfo = ({ nextFeature,
	heading,
	content,
	prevFeature,
	last = false,
	step,
	scrollContainer,
	showFeatures,
	totalFeatures,
	arrowPos,
	subText,
	scrollOffset = 100,
	arrowDir,
	...props }) => {
	const activeFeature = useRef();

	useEffect(() => {
		const outsideClickHandler = () => {
			nextFeature(true);
		};
		window.addEventListener('click', outsideClickHandler);
		return () => {
			window.removeEventListener('click', outsideClickHandler);
		};
	}, []);

	useEffect(() => {
		if (activeFeature.current !== null) {
			let scrollContainer = scrollContainer;
			if (!scrollContainer) {
				scrollContainer = {
					current: window
				};
			}
			setTimeout(() => {
				if (activeFeature.current !== null) {
					const top = activeFeature.current.offsetParent.offsetTop - scrollOffset;
					scrollContainer.current.scrollTo({
						top: top,
						left: 0,
						behavior: 'smooth'
					});
				}
			});
		}
	}, [scrollOffset]);

	const nextClickHandler = (shouldClose) => () => {
		nextFeature(shouldClose);
	};

	const wrapperHandler = (e) => {
		e.stopPropagation();
	};
	const transFormVal = (function() {
		switch (arrowDir) {
			case 'bottom':
				return 'translateY(-100%)';
			case 'left':
				return 'translateX(-100%)';
			default:
				return 'translateY(0)';
		}
	})();
	return (
		<>
			<FeatureBackground />
			<FeatureActiveBg {...props} ref={activeFeature} />
			<FeatureInfoWrapper {...props} onClick={wrapperHandler}>
				<OnboardingTooltip transform={transFormVal} {...props}>
					<Arrow arrowPos={arrowPos} {...props} />
					{subText && <StatusTag gradient='linear-gradient(87.29deg, #C86DD7 0%, #3023AE 100%)' margin='12px 0'>{subText}</StatusTag>}
					<FlexDiv bottom_M='10' justify='space-between'>
						<SubHeading>{heading}</SubHeading>
						{totalFeatures > 1 && <span>{step}</span>}
					</FlexDiv>
					<GenericP color='#fff' bottom_M='10'>
						{content}
					</GenericP>
					<FlexDiv justify='space-between'>
						{false && prevFeature && totalFeatures > 1 &&
						<Button
							btnTxt='Previous'
							btnName='Primary'
							btnWidth='90px'
							btnType='Capsule'
							btnClass='padgVertical'
							handleClick={prevFeature}
						/>}
						<Button
							btnTxt={last ? (totalFeatures > 1 ? 'Finish' : 'Ok Got It') : 'Next'}
							btnName='Primary'
							btnWidth='82px'
							btnType='Capsule'
							btnClass='padgVertical'
							handleClick={nextClickHandler(last)}
						/>
					</FlexDiv>
				</OnboardingTooltip>
			</FeatureInfoWrapper>
		</>
	);
};

FeatureInfo.propTypes = {
	arrowPos: PropTypes.oneOf(['top-left', 'top-right', 'bottom', 'bottom-right'])
};
export default FeatureInfo;
