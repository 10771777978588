import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';

import {
	STATUS_COLOR,
	STATUS_CTA,
	STATUS,
	LOB
} from '../../config';
import { getDate, capitalizeFirstLetter, getDaysDiff, saveServerLogs } from '../../../UtilityComponents/Util';
import { trackShortListEvents, validHotelDate, getTravDetTxt } from '../../../UtilityComponents/ShortlistUtils';
import { SHORTLIST_SHARE_CONSTANTS } from '../../../AppConstants';
import { sortFlightData, sortHotelData } from '../../../UtilityComponents/ShortlistUtils';

export const formatHotelDate = (dateStr, format = SHORTLIST_SHARE_CONSTANTS.LATO_DATE) => getDate(validHotelDate(dateStr), format);

export const formatFlightDate = (date, format = SHORTLIST_SHARE_CONSTANTS.LATO_DATE) => {
	if (!date || typeof date !== 'string') {
		return '';
	}
	return getDate(date, format);
};

export const nestedGroup = (seq, keys) => {
	if (!keys.length) {
		return seq;
	}
	let first = keys[0];
	let rest = keys.slice(1);
	return mapValues(groupBy(seq, first), function(value) {
		return nestedGroup(value, rest);
	});
};

export const getCBCardHeaderProps = (grpDetail = {}, statusInfo = {}, cabinClassGrp = {}) => {
	const grpType = grpDetail.type || grpDetail.lob;
	const groupStatus = grpDetail.status;

	let headerProps = {};

	const approvalText = '';
	const ctaText = '';
	const cta = STATUS_CTA[groupStatus] || '';
	const travDets = getTravDetTxt(grpDetail);

	if (grpType === LOB.FLIGHT) {
		const { depCity, arrCity, depDateTime, cabinClass } = grpDetail;

		const depature = (depCity && depCity.name) || '';
		const arrival = (arrCity && arrCity.name) || '';
		const depDate = formatFlightDate(depDateTime);
		const cc = cabinClassGrp[cabinClass] || '';

		headerProps = {
			icon: 'flightLarge',
			heading: `Flight from ${depature} to ${arrival}`,
			statusText: statusInfo[groupStatus] || '',
			statusTheme: STATUS_COLOR[groupStatus] || 'blue',
			subHeading: `${depDate} | ${travDets}, ${cc}`,
			margin: '0 0 30px 0',
			approvalText,
			ctaText,
			cta
		};
	} else {
		const { roomCount, cityName, checkin, checkout } = grpDetail;
		const checkinDate = formatHotelDate(checkin);
		const checkoutDate = formatHotelDate(checkout);

		headerProps = {
			icon: 'hotelLarge',
			heading: `Hotel in ${cityName}`,
			statusText: statusInfo[groupStatus],
			statusTheme: STATUS_COLOR[groupStatus] || 'blue',
			subHeading: `${checkinDate} - ${checkoutDate} | ${roomCount} Room, ${travDets}`,
			margin: '0 0 30px 0',
			approvalText,
			ctaText,
			cta
		};
	}
	return headerProps;
};

export const getRejectedOptionsCbProps = (grpDetail, travellerName, status) => {
	let props = {};
	const { lob, moreOptionComment } = grpDetail;
	const lobName = capitalizeFirstLetter(LOB[lob]);
	if (status === STATUS.MORE_OPTIONS_REQUESTED) {
		props = {
			heading: `${travellerName} has requested for more ${lobName} options.`,
			reasonDescription: moreOptionComment,
			reasonTitle: `Reason for more ${lobName} options :`,
			rejectedOptionTitle: `Rejected ${lobName} Options :`
		};
	} else if (status === STATUS.SHARED) {
		props = {
			heading: `Awaiting confirmation from ${travellerName} on below shared options.`,
			rejectedOptionTitle: `Shared ${lobName} Options :`
		};
	}

	return props;
};

export const getEmpCardHeaderProps = (grpDetail = {}, statusInfo = {}, cabinClassGrp = {}, isConfirmed, isMobile) => {
	const grpType = grpDetail.type || grpDetail.lob;

	let headerProps = {};

	const travDets = getTravDetTxt(grpDetail);
	const isMoreOptionsEmp = grpDetail.status === STATUS.MORE_OPTIONS_REQUESTED;
	let statusData = {};
	if (isMoreOptionsEmp) {
		statusData = getRejectedOptionsEmpProps(grpDetail, cabinClassGrp);
	}

	if (grpType === LOB.FLIGHT) {
		const { depCity, arrCity, depDateTime, cabinClass, status } = grpDetail;

		const depature = (depCity && depCity.name) || '';
		const arrival = (arrCity && arrCity.name) || '';
		const depDate = formatFlightDate(depDateTime);
		const cc = cabinClassGrp[cabinClass] || '';
		const preTextHead = isMobile ? (isConfirmed ? 'Confirmed ' : '') : `${isConfirmed ? 'Confirmed Flight Option' : 'Select Preferred Flight'} - `;
		headerProps = {
			heading: `${preTextHead}${depature} to ${arrival} ${isMobile ? 'Flight' : ''}`,
			subHeading: ` ${isMobile ? '' : '|'} ${depDate} | ${travDets}, ${cc}`,
			margin: '0 0 30px 0',
			status: statusInfo[status],
			lob: grpType,
			pageHead: 'Flight Options',
			subPageHead: `${depature} to ${arrival}`,
			statusData
		};
	} else {
		const { roomCount, cityName, checkin, checkout } = grpDetail;
		const checkinDate = formatHotelDate(checkin);
		const checkoutDate = formatHotelDate(checkout);
		const preTextHead = isMobile ? `${isConfirmed ? 'Confirmed' : ''} Hotel in ` : `${isConfirmed ? 'Confirmed Hotel Option' : 'Select Preferred Hotel'} in `;
		headerProps = {
			heading: `${preTextHead}${cityName} `,
			subHeading: ` ${isMobile ? '' : '|'} ${checkinDate} - ${checkoutDate} | ${roomCount} Room, ${travDets}`,
			margin: '0 0 30px 0',
			lob: grpType,
			pageHead: 'Hotel Options',
			subPageHead: cityName,
			statusData
		};
	}
	return headerProps;
};

export const getRejectedOptionsEmpProps = (grpDetail = {}, cabinClassGrp = {}) => {
	const grpType = grpDetail.type || grpDetail.lob;

	let headerProps = {};

	if (grpType === LOB.FLIGHT) {
		const { depCity, arrCity, depDateTime, travelCount, cabinClass } = grpDetail;

		const depature = (depCity && depCity.name) || '';
		const arrival = (arrCity && arrCity.name) || '';
		const depDate = formatFlightDate(depDateTime);
		const cc = cabinClassGrp[cabinClass] || '';

		headerProps = {
			heading: 'More flight options required',
			subHeading: `<span class='latoBlack'>${depature}</span> To <span class='latoBlack'>${arrival}</span> | ${depDate} | ${travelCount} Adult${travelCount > 1 ? 's' : ''}, ${cc}`,
			infoText: `To select from the previously shared flight options`,
			cta: 'click here',
			comment: grpDetail.moreOptionComment
		};
	} else {
		const { roomCount, travelCount, cityName, checkin, checkout } = grpDetail;
		const checkinDate = formatHotelDate(checkin);
		const checkoutDate = formatHotelDate(checkout);

		headerProps = {
			heading: 'More hotel options required',
			subHeading: `<span class='latoBlack'>${cityName}</span> | ${checkinDate} - ${checkoutDate} | ${roomCount} Room, ${travelCount} Adult${travelCount > 1 ? 's' : ''}`,
			infoText: `To select from the previously shared hotel options`,
			cta: 'click here',
			comment: grpDetail.moreOptionComment
		};
	}
	return headerProps;
};

export const cardHeaderClickHandler = (detailsUrl, searchUrl, status, lob = '') => () => {
	if (status === STATUS.CONFIRMED && detailsUrl) {
		try {
			trackShortListEvents('cb_continuebooking_' + lob.toLowerCase());
		} catch (error) {
			saveServerLogs(error && error.stack ? error.stack : error, 'error');
		}
		window.open(detailsUrl, 'new');
	} else if (status === STATUS.MORE_OPTIONS_REQUESTED && searchUrl) {
		try {
			trackShortListEvents('cb_shortlistagain_' + lob.toLowerCase());
		} catch (error) {
			saveServerLogs(error && error.stack ? error.stack : error, 'error');
		}
		window.open(searchUrl, 'new');
	}
};

const sortLobWiseData = (wishlistData) => {
	const flightGroup = [];
	const hotelGroup = [];
	for (const wishlist of wishlistData) {
		const { productMetadata = {} } = wishlist;
		let { lob } = productMetadata;
		if (lob && lob === LOB.HOTEL) {
			hotelGroup.push(wishlist);
		} else if (lob && lob === LOB.FLIGHT) {
			flightGroup.push(wishlist);
		}
	}
	const wishlistData1 = sortFlightData(flightGroup);
	const wishlistData2 = sortHotelData(hotelGroup);
	return [...wishlistData1, ...wishlistData2] || wishlistData;
};

export const formatWishlistData = (data, userType, isMobile) => {
	if (!data) {
		return {};
	}

	const payload = {};
	let result = {};

	const wishlistData = sortLobWiseData(data.wishlist.wishlistItems);

	if (userType === SHORTLIST_SHARE_CONSTANTS.CB) {
		result = nestedGroup(wishlistData, ['productMetadata.subGroupId', 'productMetadata.groupId']);
	} else {
		result = nestedGroup(wishlistData, ['productMetadata.lob', 'productMetadata.groupId']);
	}

	const wishList = {
		...data.wishlist,
		products: result,
		metadata: data.wishlist.wishlistMetadata
	};

	delete wishList.wishlistMetadata;
	delete wishList.wishlistItems;

	payload.createdAt = getDate(wishList.createdAt, 'ddd, DD MMM\' YYYY');
	payload.lobCount = {
		[LOB.FLIGHT]: 0,
		[LOB.HOTEL]: 0
	};

	if (wishList.metadata) {
		const { bookingFor, grpDetails, cc = {}, statusInfo = {}, goingToCityInfo = {}, ownerDetails, cancelRequestText = [], recipientDetails } = wishList.metadata;

		payload.recipientDetails = recipientDetails;
		payload.bookingFor = bookingFor;

		if (ownerDetails) {
			payload.ownerName = ownerDetails.name;
		}

		payload.cancelRequestText = cancelRequestText.map((option, index) => {
			if (option.toLowerCase() === 'other') {
				return ({
					id: index,
					text: 'Other',
					inputBox: true,
					placeholderText: 'Enter your reason here'
				});
			}

			return ({
				id: index,
				text: option
			});
		});

		const goingToKeys = Object.keys(goingToCityInfo);
		if (goingToKeys.length > 2) {
			payload.goingTo = '<span class="font32 blackText latoBlack multicity">Multiple Cities</span>';
		} else {
			let goingToStr = '';
			goingToKeys.forEach((key) => {
				let keyStr = key;
				if (goingToStr) {
					keyStr = `& ${key}`;
				}
				goingToStr += `<span class="font32 blackText latoBlack goingKey"> ${keyStr}</span>`;
			});

			goingToStr += '<span class="font18 appendLeft3 latoBold country">, India</span>';
			payload.goingTo = goingToStr;
		}

		payload.cc = cc;
		payload.statusInfo = statusInfo;

		let maxCount = 1;
		let moreOptions = [];
		let flightGroup = [];
		let hotelGroup = [];
		grpDetails && Object.keys(grpDetails).forEach((grpKey) => {
			const grp = grpDetails[grpKey] || {};
			const { lob, status } = grp;
			let headerProps = {};
			const isProductDisabled = userType === SHORTLIST_SHARE_CONSTANTS.PRIMARY_PAX && grp.status === STATUS.CONFIRMED;
			if (userType === SHORTLIST_SHARE_CONSTANTS.PRIMARY_PAX) {
				headerProps = getEmpCardHeaderProps(grp, statusInfo, cc, isProductDisabled, isMobile);
			} else {
				headerProps = getCBCardHeaderProps(grp, statusInfo, cc);
			}
			if (typeof payload.lobCount[lob] !== 'undefined') {
				payload.lobCount[lob]++;
			}
			const travelCount = (grp.travelCount && +grp.travelCount) || 0;
			if (travelCount > maxCount) {
				maxCount = travelCount;
			}

			const optionObj = { id: grpKey, lob: lob };
			let shouldAddOption = false;
			if (lob === LOB.FLIGHT && status === STATUS.SHARED) {
				shouldAddOption = true;
				const { arrCity, depCity, depDateTime } = grp;

				const depDate = formatFlightDate(depDateTime, 'ddd, DD MMM\' YYYY');
				optionObj.text = `Flight - ${depCity.name} to ${arrCity.name} (${depDate})`;
				optionObj.date = depDateTime;
				optionObj.inputBox = true;
				shouldAddOption && flightGroup.push(optionObj);
			} else if (lob === LOB.HOTEL && status === STATUS.SHARED) {
				const { checkin } = grp;
				const checkinDate = formatHotelDate(checkin, 'ddd, DD MMM\' YYYY');
				shouldAddOption = true;
				const { cityName } = grp;
				optionObj.text = `Hotel in ${cityName} (${checkinDate})`;
				optionObj.date = validHotelDate(checkin);
				optionObj.inputBox = true;
				shouldAddOption && hotelGroup.push(optionObj);
			}
			grp.headerProps = headerProps;
		});
		flightGroup = sortFlightData(flightGroup);
		hotelGroup = sortHotelData(hotelGroup);
		moreOptions.push(...flightGroup, ...hotelGroup);
		payload.travellerCount = maxCount;
		payload.moreOptions = moreOptions;
	}

	payload.wishList = wishList;
	return payload;
};

export const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

export const formatWishlistConfirmData = (data) => {
	const confirmProductIds = Object.values(data).filter(Boolean);
	return ({
		confirmProductIds
	});
};

export const getFlightFarePayload = (group = [], status) => {
	const lobProductIds = [];
	let crIdStr = '';
	let itineraryStr = '';
	const rKeyMap = {};

	group.forEach((item) => {
		const { productId, productMetadata } = item;
		const { rKey, crId, itinerary, productStatus } = productMetadata;
		if (productStatus === status) {
			crIdStr = crId;
			itineraryStr = itinerary;
			lobProductIds.push(rKey);
			rKeyMap[rKey] = productId;
		}
	});

	return ({
		lobProductIds,
		params: {
			crId: crIdStr,
			itinerary: itineraryStr
		},
		lob: LOB.FLIGHT,
		rKeyMap
	});
};

export const formatFlightFareResp = (data, grpId, rKeyMap) => {
	const { latestFares = {} } = data;
	const fareProductMap = {};
	Object.keys(latestFares).forEach((rKey) => fareProductMap[rKeyMap[rKey]] = latestFares[rKey]);
	return ({
		fareProductMap,
		grpId
	});
};

export const getHotelFarePayload = (group = [], status, grpId, wishId, authCode) => {
	const retData = {
		lobProductIds: [],
		groupId: grpId,
		lob: LOB.HOTEL
	};
	const params = {};
	if (authCode) {
		params.authCode = authCode;
	}
	if (wishId) {
		params.wishlistId = wishId;
	}
	const idMap = {};
	group.forEach((item) => {
		const { productId, productMetadata } = item;
		const { lobProductId, productStatus } = productMetadata;
		if (productStatus === status) {
			retData.lobProductIds.push(lobProductId);
			idMap[lobProductId] = productId;
		}
	});
	return {
		postData: retData,
		params,
		idMap
	};
};

export const formatHotelFareResp = (data, grpId, lobProductIdMap) => {
	const { response = {} } = data;
	const hotelsData = (response.personalizedSections && response.personalizedSections[0] && response.personalizedSections[0].hotels) || [];
	const fareProductMap = {};
	hotelsData.forEach((hotel) => {
		const hotelProductId = lobProductIdMap[hotel.id];
		if (hotelProductId) {
			const { soldOut, priceDetail = {}, corpApprovalInfo = {} } = hotel;
			fareProductMap[hotelProductId] = {
				soldOut,
				fare: priceDetail.displayPrice,
				fareWithTax: priceDetail.discountedPriceWithTax,
				inPolicy: corpApprovalInfo.withinPolicy,
				policyFailureReason: corpApprovalInfo.failureReasons
			};
		}
	});
	return ({
		fareProductMap,
		grpId
	});
};

export const flightFareLoading = (grpId, rKeyMap, loadingState) => {
	const loadingMap = {};
	Object.keys(rKeyMap).forEach((rKey) => loadingMap[rKeyMap[rKey]] = { loading: loadingState });
	return ({
		loadingMap,
		grpId
	});
};

export const hotelFareLoading = (grpId, lobProductIdMap, loadingState) => {
	const loadingMap = {};
	Object.keys(lobProductIdMap).forEach((id) => loadingMap[lobProductIdMap[id]] = { loading: loadingState });
	return ({
		loadingMap,
		grpId
	});
};
