import React from 'react';

import { GenericSpan } from 'commonStyles';

const DateData = ({ travelDate, nights }) => (
	<GenericSpan className='nowrap'>
		<GenericSpan>{travelDate}</GenericSpan>
		{nights && <GenericSpan left_M='5' fsize='11px'>{nights}</GenericSpan>}
	</GenericSpan>

);

export default DateData;
