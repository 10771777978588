import moment from 'moment';

import { APPLICATION_CONSTANTS, REQUISITION_CONSTS } from 'AppConstants';
import { ASSET_PATH } from 'assetsPath';

export const TRAV_SUBMIT_TXT = 'PROCEED TO ADD SERVICES';

export const ONE_WAY = 'O';
export const ROUND_TRIP = 'R';
export const MULTI_CITY = 'M';

export const FLIGHT_TYPE = {
	oneWay: {
		id: ONE_WAY,
		label: 'ONE WAY'
	},
	roundTrip: {
		id: ROUND_TRIP,
		label: 'ROUNDTRIP'
	}/* ,
	multiCity: {
		id: MULTI_CITY,
		label: 'MULTI CITY'
	} */
};

export const FLIGHT_TRIP_MANDATORY_FIELD = {
	[ONE_WAY]: ['from', 'to', 'depDate'],
	[ROUND_TRIP]: ['from', 'to', 'depDate', 'arrDate'],
	[MULTI_CITY]: ['from', 'to', 'depDate']
};

export const HOTEL_TRIP_MANDATORY_FIELD = ['destination', 'checkin', 'checkout', 'roomDetails'];
export const BUS_TRIP_MANDATORY_FIELD = ['from', 'to', 'fromDate', 'paxCount'];

export const FlightRowFieldDet = {
	rowIdField: 'iata',
	rowTitleField: 'cityName',
	rowSubTitleField: 'airportName',
	rowShortNameField: 'iata'
};

export const HotelRowFieldDet = {
	rowIdField: 'id',
	rowTitleField: 'displayName',
	rowShortNameField: 'htype'
};

export const BusRowFieldDet = {
	rowIdField: 'id',
	rowTitleField: 'dn'
};

export const cabRowFieldDet = {
	rowIdField: 'id',
	rowTitleField: 'title',
	rowSubTitleField: 'subText'
};

export const DEFAULT_FLIGHT_DESTINATION = {
	from: {
		'icon': 'https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png',
		'cityName': 'Select City',
		'country': '',
		'iata': '',
		'airportName': '',
		'countryCode': ''
	},
	to: {
		'icon': 'https://imgak.mmtcdn.com/flights/assets/media/dt/common/icons/ic-flight-onward.png',
		'cityName': 'Select City',
		'country': '',
		'iata': '',
		'airportName': '',
		'countryCode': ''
	}
};

export const DATE_TYPE = {
	to: 'to',
	from: 'from'
};

export const TIME_TYPE = {
	to: 'to',
	from: 'from'
};

export const ECONOMY = 'E';
export const PREMIUM_ECONOMY = 'PE';
export const BUSINESS = 'B';

export const CLASS_TYPE = {
	[ECONOMY]: {
		id: ECONOMY,
		label: 'Economy'
	},
	[PREMIUM_ECONOMY]: {
		id: PREMIUM_ECONOMY,
		label: 'Premium Economy'
	},
	[BUSINESS]: {
		id: BUSINESS,
		label: 'Business'
	}
};

export const DOMESTIC_COUNTRY_CODE = ['India', 'IN'];

export const POPULAR_PARAMS = {
	region: 'in',
	language: 'eng',
	currency: 'inr'
};

export const BUS_PARAMS = {
	limit: 10,
	version: 'v2',
	type: 'city'
};

export const CAB_TYPES = {
	ONE_WAY: 'OW',
	ROUND_TRIP: 'RT',
	AIRPORT_TRANSFER: 'AT'
};

export const CAB_SEARCH_CONFIG = {
	CAB_TYPE: {
		oneWay: {
			id: CAB_TYPES.ONE_WAY,
			label: 'OUTSTATION ONE-WAY'
		},
		roundTrip: {
			id: CAB_TYPES.ROUND_TRIP,
			label: 'OUTSTATION ROUND TRIP'
		},
		airportTransfer: {
			id: CAB_TYPES.AIRPORT_TRANSFER,
			label: 'AIRPORT CABS'
		}
	}
};

export const MAX_TRAV_ERROR = 'Maximun Traveller added!';
export const TRAV_FORM_CONFIG = (profileData = {}, bookingFor = REQUISITION_CONSTS.BOOKFOR.MYSELF)=>({
	submitTxt: 'PROCEED TO ADD SERVICES',
	fields: { bookingFor: {
		isMulti: false,
		value: '',
		name: 'bookingFor',
		type: 'select',
		label: 'BOOKING FOR',
		classPrefix: 'trav_bf custom-styles-select',
		validation: {
			required: {
				msg: 'Required!'
			}
		},
		value: [{ value: bookingFor, label: bookingFor === REQUISITION_CONSTS.BOOKFOR.MYSELF ? `${profileData?.name ? profileData?.name : ''} (self)` : REQUISITION_CONSTS.BOOKFORTXT[bookingFor] }],
		options: [
			{ value: REQUISITION_CONSTS.BOOKFOR.MYSELF, label: `${profileData?.name ? profileData?.name : ''} (self)` },
			{ value: REQUISITION_CONSTS.BOOKFOR.GROUP, label: 'Group' },
			{ value: REQUISITION_CONSTS.BOOKFOR.GUEST, label: 'Guest' }
		],
		errorOnBlur: true,
		isOpenTop: false,
		theme: 'cmn',
		isMandatory: true
	} }
});

export const GRP_TRAV_MODAL = {
	title: 'Add travellers',
	submitTxt: 'Add Travellers to group',
	altSubmitTxt: 'Update Travellers in group'
};

export const GUEST_DTLS_FIELDS = [{
	value: '',
	name: 'fname',
	label: 'FIRST & MIDDLE NAME',
	placeHolder: '',
	type: 'text',
	validation: {
		required: {
			msg: 'Required!'
		}
	},
	errorOnBlur: true,
	nocHangeError: true,
	isMandatory: true,
	mmtTheme: true,
	width: '38%',
	customClass: 'reqflds'
},
{
	value: '',
	name: 'lname',
	label: 'LAST NAME',
	placeHolder: '',
	type: 'text',
	errorOnBlur: true,
	nocHangeError: true,
	mmtTheme: true,
	width: '38%',
	customClass: 'reqflds'
},
{
	name: 'gender',
	label: '',
	value: '',
	validation: {
		required: {
			msg: 'Required!'
		}
	},
	width: 'auto',
	options: [{
		value: 'M',
		label: {
			labelHead: 'M'
		}
	},
	{
		value: 'F',
		label: {
			labelHead: 'F'
		}
	}
	],
	type: 'tab',
	isMandatory: true,
	mmtTheme: true,
	noLabel: true,
	customClass: 'reqflds'
}];

export const GUEST_TRAV_MODAL = {
	title: 'Add Guest Travellers',
	submitTxt: 'Add Travellers for Trip',
	altSubmitTxt: 'Update Travellers for Trip'
};

export const SEARCH_INPUT = {
	value: '',
	label: '',
	placeholder: 'Enter traveller name or email ID',
	type: 'text',
	textType: 'search'
};

export const RIGHT_BANER_CONFIG = {
	heading: 'Raising a travel Request',
	steps: ['Raise a request', 'Get approval on itinerary', 'Book services at your convinience']
};

export const FROM_TO_SAME_AIRPORT = 'From & To destinations cannot be same.';

export const GENERIC_DATA_ERROR = 'Please fill all the mandatory details*';

export const SAME_DATE_ERROR = 'Checkin & Checkout dates cannot be same';

export const HOTEL_PLACEHOLDER = {
	dstnLabel: 'City/Hotel/Area/Building',
	dstnSrchPlaceholder: 'Enter City/Hotel/Area/Building',
	dstnPlaceholder: 'Search City',
	dateFromLabel: 'Check-in',
	dateToLabel: 'Check-out',
	dateFrmPlaceholder: 'Please select check-in date',
	dateToPlaceholder: 'Please select check-out date'
};

export const FLIGHT_PLACEHOLDER = {
	dstnFrmLabel: 'From',
	dstnFrmSrchPlaceholder: 'Departure City',
	dstnPlaceholder: 'Search City',
	dstnToLabel: 'To',
	dstnToSrchPlaceholder: 'To City',
	dateFromLabel: 'Departure',
	dateToLabel: 'Return',
	dateFrmPlaceholder: 'Please select departure date',
	dateToPlaceholder: 'Please select return date'
};

export const BUS_PLACEHOLDER = {
	dstnFrmLabel: 'From',
	dstnFrmSrchPlaceholder: 'From',
	dstnPlaceholder: 'Search City',
	dstnToLabel: 'To',
	dstnToSrchPlaceholder: 'To',
	dateFromLabel: 'Travel Date',
	dateFrmPlaceholder: 'Please select travel date'
};

export const CAB_PLACEHOLDER = {
	dstnFrmLabel: {
		[CAB_TYPES.AIRPORT_TRANSFER]: 'AIRPORT',
		[CAB_TYPES.ONE_WAY]: 'FROM',
		[CAB_TYPES.ROUND_TRIP]: 'FROM'
	},
	dstnFrmSrchPlaceholder: {
		[CAB_TYPES.AIRPORT_TRANSFER]: 'AIRPORT',
		[CAB_TYPES.ONE_WAY]: 'FROM',
		[CAB_TYPES.ROUND_TRIP]: 'FROM'
	},
	destFrmPlaceholder: {
		[CAB_TYPES.AIRPORT_TRANSFER]: 'AIRPORT',
		[CAB_TYPES.ONE_WAY]: 'Search City',
		[CAB_TYPES.ROUND_TRIP]: 'Search City'
	},
	dstnPlaceholder: 'Search City',
	dstnToLabel: 'To',
	dstnToSrchPlaceholder: 'To City',
	dateFromLabel: 'Departure',
	dateToLabel: 'Return',
	dateFrmPlaceholder: 'Please select departure date',
	dateToPlaceholder: 'Please select return date',
	droptime: { label: 'Drop Up Time', placeholder: 'Select Drop Up Time', type: 'drop' },
	pickuptime: { label: 'Pick Up Time', placeholder: 'Select Pickup Up Time', type: 'pickup' }
};

export const REVIEW_BTN_TXT = 'PROCEED TO REVIEW ITINERARY';

export const SUBMIT_APPR_TXT = 'SUBMIT FOR APPROVAL';

export const TRAV_ERROR_MSG = 'Traveller details are required';

export const REASONS_HEADING = 'Reason for Travel';

export const TIME_GAP = { [CAB_TYPES.AIRPORT_TRANSFER]: 1, defaultIntialGap: 4 };

export const PICKUP_TYPE_OPTIONS = [{ id: 'FA', label: 'From the Airport' }, { id: 'TA', label: 'To the Airport' }];

export const defaultCabTravelDetails =	{
	searchObj: {
		address: '',
		code: ''
	},
	date: moment().toDate()
};

export const SuccessPoptxt = {
	apiStatus: 'SUCCESS',
	heading: 'Itinerary Request Sent!',
	imgClass: 'trav-req-success',
	content: 'Your travel itinerary has been created and sent to approvername for approval. Once the request is approved, your booking will be completed by the travel desk.',
	imgPath: `${ASSET_PATH.IMAGES}/travreqform/reqSuccess.png`
};

export const FailedPoptxt = {
	apiStatus: 'ERROR',
	heading: 'Itinerary Request Not Sent!',
	content: APPLICATION_CONSTANTS.GENERIC_API_ERROR
};
