import React, { forwardRef } from 'react';

import { INPUT_TYPES } from './inputConfig';
import SingleCalendar from './SingleCalendar';
import TextNumberInput from './TextNumberInput';
import TextArea from './TextArea';

const Input = ({ value, onChange, label, required, type, inputProps, forwardedRef, disabled, message, width, ...props }) => {
	const renderInput = () => {
		switch (type) {
			case INPUT_TYPES.DATE: {
				return (
					<SingleCalendar
						inputProps={inputProps}
						required={required}
						onChange={onChange}
						value={value}
						label={label}
						disabled={disabled}
						{...props}
					/>
				);
			}
			case INPUT_TYPES.TEXT_AREA: {
				return (
					<TextArea
						inputProps={inputProps}
						required={required}
						onChange={onChange}
						value={value}
						label={label}
						ref={forwardedRef}
						disabled={disabled}
						message={message}
						{...props}
					/>
				);
			}
			default: {
				return (
					<TextNumberInput
						inputProps={inputProps}
						required={required}
						onChange={onChange}
						value={value}
						label={label}
						type={type}
						ref={forwardedRef}
						disabled={disabled}
						message={message}
						$width={width}
						{...props}
					/>
				);
			}
		}
	};

	return (
		renderInput()
	);
};

export default forwardRef((props, ref) => <Input {...props} forwardedRef={ref} />);
