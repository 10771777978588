import React, { useCallback } from 'react';
import { Wrapper, BreadCrumb, ListItem } from './BreadCrumbStyles';
import { Container, FlexDiv } from '../Styles';

const BreadCrumbSection = ({ breadCrumbList, clickAction, type, $bg = '#efefef', bottom_M, bottom_P = '20' })=> {
	const clickLink = useCallback((item) => () => {
		clickAction(item);
	});

	return (
		<FlexDiv top_M={type === 'header' ? '60' : '0'} type={type} $bg={$bg} bottom_M={bottom_M} bottom_P={bottom_P}>
			<Wrapper>
				<BreadCrumb bottom_M={bottom_M} isFlex as='ul'>
					{breadCrumbList && breadCrumbList.map((list, index)=>(
						<ListItem key={index}><a href={list.href} onClick={clickLink(list)}>{list.text}</a></ListItem>
					))}
				</BreadCrumb>
			</Wrapper>
		</FlexDiv>
	);
};

export default BreadCrumbSection;
