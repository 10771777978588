import Theme from 'common/Theme';

export const LEFT_CONTAINER_HEADING = 'Services available for booking';
export const RIGHT_CONTAINER_HEADING = 'Travel Information';
export const RIGHT_CONTAINER_SUB_HEADING = 'Additional Information';
export const BOOKED_STATUS = 'MARK AS BOOKED';
export const NON_BOOKED_STATUS = 'VIEW DETAILS';

export const LOB = {
	FLIGHT: 'FLIGHT',
	HOTEL: 'HOTEL',
	CABS: 'CABS',
	BUS: 'BUS'
};

export const LOB_TITLE = {
	FLIGHT: 'Flights',
	HOTEL: 'Accomodation',
	CABS: 'Cabs',
	BUS: 'Bus'
};

export const breadCrumbItems = ['Travel Requests'];

export const markedData = {
	title: 'Booking Completed?',
	disc: 'By clicking on proceed, you are confirming that the ticket has been booked and the request for this service shall be closed.',
	btnTxt: 'YES, PROCEED',
	titleAlign: 'center'
};

export const LOB_STYLE_COLOR = {
	'BOOKED': '#18A160',
	'NOT BOOKED': '#CE832A'
};
export const LOB_STYLE_BG_COLOR = {
	'BOOKED': 'green',
	'NOT BOOKED': 'orange'
};

export const NON_COMMENT_STYLE = {
	bgColor: '#C1F1DD',
	color: '#1A7971'
};
export const COMMENT_STYLE = {
	bgColor: '#FFEBEE',
	color: '#FF1515'
};

export const PENDING_STYLE = {
	bgColor: Theme.colors.pastelYellow,
	color: Theme.colors.yellowText
};

export const btnLobTxt = 'BOOK TICKETS';
export const btnApproveTxt = 'APPROVE REQUEST';
export const btnRejectTxt = 'REJECT REQUEST';

export const MarkModalBtnText = 'YES, PROCEED';

export const toastDataConfig = {
	'APPROVED': {
		heading: 'Travel Request Approved',
		endText: 'has been successfully approved.',
		icon: 'greenNotification'
	},
	'PENDING': {
		heading: 'Travel Request Approved',
		endText: 'has been successfully approved, and forwarded for further approvals',
		icon: 'greenNotification'
	},
	'REJECTED': {
		heading: 'Travel Request Rejected',
		endText: 'has been rejected',
		icon: 'RedNotification'
	}
};

export const approveData = {
	title: 'Approve This Request?',
	subTitle:
		'On approval, the booking will be enabled to confirm & the details will be shared with the requestor and the travel desk.',
	extraHeading: 'Any Comments? (Optional)',
	btnName: 'YES, APPROVE'
};
export const approveViewSubTitle = {
	primary: 'Any Comments?',
	secondary: '(Optional)',
	placeholder: 'ENTER YOUR COMMENTS'
};

export const rejectData = {
	title: 'Reject This Request?',
	subTitle:
		'Select your reason for rejecting this request, which will be sent to the requester and the travel desk along with the rejection mail.',
	btnName: 'YES, REJECT'
};
export const rejectViewSubTitle = {
	primary: 'Reason for Rejection',
	placeholder: 'ENTER YOUR REASONS'
};

export const CANCELLATION_TEXT = 'CANCEL REQUEST';

export const RejectField = {
	isMulti: false,
	errorOnBlur: true,
	placeHolder: 'ENTER YOUR REASONS',
	value: '',
	name: 'name',
	displayLabel: 'Name',
	validation: {
		required: {
			msg: 'Please fill this to proceed.'
		}
	},
	type: 'textArea'
};
export const ApproveField = {
	isMulti: false,
	errorOnBlur: true,
	placeHolder: 'ENTER YOUR COMMENTS',
	value: '',
	name: 'name',
	displayLabel: 'Name',
	type: 'textArea'
};

export const CANCEL_MODAL_DATA = {
	msg: 'Request Cancelled SuccessFully',
	heading: 'Request Cancelled'
};
