/* eslint-disable camelcase */
/**
 * utility functions for handling policy config and policy unit data
 * @author mybizFe
 * @param { Object } formattedConfig fields object rendered on UI created from config and masterpolicy which is mapped through BE config
 * @param { Object } formattedOrgConfig policy config object with inputs nested according to policy units, to be used for tier and as original config backup to be reused when group is changed
 *
 * @param { Object } formattedConfigGroup:formattedConfig[grpId] policy config of particular selected group  with inputs according to policy unit obj format used  to render fields on page
 * @returns exports util functions { formatConfigData }
 */

/**
 * one complete field section to be rendered on UI like employee eligibility
 * @author MMT8270
 * @typedef {Object} InputValues
 * @property {String} attrValue value to set on selection in policy
 * @property {String} displayTxt title text to shown for option
 * @property {String} subText subtext to be shown in checkbox input
 * @property {Array[SubField]} subFields of subfields
 */

/**
 * InputFields Object
 * @author MMT8270
 * @typedef {Object} InputFields
 * @property {String} input type of input to be rendered
												* textbox: text input
												* dropdown: select box
												* checkbox: checkbox type input
												* button : sinmple button with text like for add conditional
												* delete_button : cross icon for deleting dynamic input
												* customCheckbox: for custom multiselct UI for travelClass
												* toggleSwitch: toggle button on UI like for wallet ancillary,
												* icon: render icon and some data like for info icon and tooltip after inputs
												* freeText: to just render static text on UI,
												* tabDropdown: to render dropdown with tabs like for manager dropdown
												* star: render rating selection input
 * @property {InputValues} values  options for dropdown/checkbox/customCheckbox
 * @property {Boolean} multiSelect if dropdown/tabdropdown values can be multiselecy or singleselect
 * @property {String} attrKey last level attribute value in policy json if deep nesting then to be combined with subfield attribute
 * @property {String} key for inputs which has two values associated with one attribute like min max calendar
 * @property {String} icon icon to be rendered on input UI
 * @property {Boolean} dynamic if multiple input field can be replicated on UI like manager flds
 * @property {String} displayTxt to be shown in inputs like toggleSwitch, freeText, button
 * @property {String} subTxt subtext/description/validation to be shown for textbox, toggleSwitch
 * @property {String} inputSuffix suffix text to be shown on right inside textbox
 * @property {String} suffix suffix text to be shown on right outside textbox
 * @property {String} prefix to be showed to left inside text box in dark bg
 * @property {String} selected toggleSwitch, checkbox if to default selected
 * @property {String} display hide/disabled to hide or disable input on UI
 * @property {String} defautlVal defautlValue of input textbox on UI
 * @property {String} countKey key for no of managers to be selected on UI
 * @property {String} value value to be shown/selected on inputs on UI
 * @property {String} repeatCount no of times dynamic input can be rendered
 * @property {String} infoIcon info icon and some data like for tooltip after inputs
 * @property {String} validation validation for UI not used currently
 * @property {String} infoTxt info data like for tooltip after inputs
 */

/**
 * Subfield Object
 * @author MMT8270
 * @typedef {Object} SubField
 * @property {String} icon icon to be showed on UI for field
 * @property {String} heading left side title to be shown for each subsection
 * @property {String} subHeading left side description to be shown for each subsection
 * @property {String} fkey used for hiding specific fields on UI based on some user selection
 * @property {String} attrKey one level attribute value in policy json if deep nesting then to be combined with inputField attribute
 * @property {String} allDynamic if inputfields in subfield can be replicated dynamically on UI like conditional approval
 * @property {String} display hide subfield and all inside inputs on UI
 * @property {Array[inputFields]} InputFields user inputs inside each subfield to rendered on UI
 */

/**
 * one complete field section to be rendered on UI like employee eligibility
 * @author MMT8270
 * @typedef {Object} PolicyField
 * @property {String} icon icon to be showed on UI for field
 * @property {String} heading heading to be shown for each section
 * @property {String} fkey to be used to give unique seaction and show default switcher for those field section on UI and save in policy metadata
 * @property {Array[SubField]} subFields of subfields
 */

import cloneDeep from 'lodash/cloneDeep';
import result from 'lodash/result';
import update from 'lodash/update';
import get from 'lodash/get';

import formatOptionLabel from '../policy/components/optionLabel';
import { APPROVAL_CONFIG, APPROVAL_KEY_CONFIG, fkey_map_omniture, mandatoryFields, lob_icon_map } from '../policy/config';
import { getOrgId, scrollOnWindow, saveServerLogs } from './Util';
import { APPLICATION_CONSTANTS, PATHS, POLICY_CONSTANTS } from '../AppConstants';
import { logOmniClick } from '../logging/omnitureService';
import { LOB_CONSTANTS, multiplierTxts } from '../policy/config';

const PRICE_MAX = 10000000;
const AP_WIN_MAX = 365;
const MIN_PRISE_RISE_THRESHOLD = 10;

/**
 * get the index of selected group in group config list
 * @author mybizFe
 * @param { Object } groups group list from config
 * @param { String|Number }selectedGroupId id  of seelcted group on  policy page /url
 * @return {number}
 */
export const findGroupIndex = (groups, selectedGroupId) => {
	for (let i = 0; i < groups.length; i++) {
		let grp = groups[i];
		if (grp.groupId == selectedGroupId) {
			return i;
		}
	}
};

/**
 * create configuration for  rendering the page
 * @author mybizFe
 * @param { Object } policyConfigData { groups, fields, orgTierMapping, lobs,
 * masterPolicyJson, pageHeading, pageSubHeading, switcherConfig } object from get config call
 * @param { String|Number } selectedGroupId id  of seelcted group on  policy page
 * @param { String } lob default selected lob
 * @return { Object }
 * defaultPolicyGroupId
 * groupCount
 * groupCount
 * formattedOrgConfig - policy config with inputs according to policy unit format global to be used for tier
 * policyConfig {} - policy config with inputs according to policy unit obj format used  to render fields on page
 * fields {} - used to get order of input field and no of fields to render the page based on config from api
 * allDefaultSections {} - to set setdefault switcher sectionkeys and attribue keys to be saved in policy metadata
 * policyChange -  to check if there  is any change in policy based on which change popup in shown
 */
export const formatConfigData = (policyConfigData, selectedGroupId, lob) => {
	let responseObj = {};
	if (policyConfigData) {
		for (let config in policyConfigData) {
			switch (config) {
				case 'groups':
					let defaultGroup = policyConfigData[config][0];
					let defaultPolicyGroupId = defaultGroup.groupId;
					try {
						defaultPolicyGroupId = String(defaultGroup.groupId);
					} catch (error) {

					}
					responseObj['defaultPolicyGroupId'] = defaultPolicyGroupId;
					responseObj['groupCount'] = policyConfigData[config].length;
					responseObj[config] = policyConfigData[config];
					responseObj['selectedGroupIndex'] = findGroupIndex(policyConfigData[config], selectedGroupId) + 1;
					break;
				case 'fields':
					const data = formatFieldConfig(policyConfigData[config], policyConfigData, lob, policyConfigData.policyConfigMetaData);
					responseObj['formattedOrgConfig'] = data.fields;
					responseObj['policyConfig'] = {};
					responseObj['policyConfig'][selectedGroupId] = cloneDeep(data.fields);
					responseObj['allDefaultSections'] = {
						sectionKeys: data.sectionKeys,
						sectionAttrKeys: data.sectionAttrKeys
					};
					responseObj[config] = policyConfigData[config];
					responseObj['addBtnMapKeys'] = data.addBtnMapKeys;
					break;
				case 'orgTierMapping':
					const mappingConfig = policyConfigData[config];
					const tierData = mappingConfig?.[lob_icon_map[lob].policyKey];
					const orgConfig = formatTierMapping(tierData?.tierMapping?.mappingList, responseObj['formattedOrgConfig']);
					if (tierData.blockedHotelMapping && orgConfig['exclude_properties']) {
						orgConfig['exclude_properties'].value = tierData.blockedHotelMapping.hotelList.map((val) =>({
							value: val.code,
							label: val.name
						}));
					}
					responseObj['formattedOrgConfig'] = orgConfig;
					responseObj['policyConfig'][selectedGroupId] = orgConfig;
					break;
				case 'lobs':
					const policyConfigMetaData = policyConfigData['policyConfigMetaData'];
					const { currency = '' } = policyConfigMetaData;
					const lobs = policyConfigData[config];
					for (let i = 0; i < lobs.length; i++) {
						const lob = lobs[i];
						if (lob_icon_map[lob.key]?.isInrOnly && currency !== APPLICATION_CONSTANTS.DEFAULT_CURRENCY.cCode) {
							lobs[i].isDisabled = true;
						}
					}
					responseObj[config] = lobs;
					break;
				default:
					responseObj[config] = policyConfigData[config];
					break;
			}
		}
	} else {
		responseObj = null;
	}
	responseObj['policyChange'] = {};
	responseObj['policyChange'][selectedGroupId] = 0;

	return responseObj;
};

/**
 * create tabs input for multitab dropdown input
 * @author mmt8270
 * @param { Object } inputField input field object for which tab data has to be created
 * @return { Object }
 */
export const getTabData = (inputField) => {
	const { tabs = [] } = inputField;
	return tabs.map((tab) => ({
		tabName: tab.name,
		formatOptionLabel: formatOptionLabel,
		isSearchable: tab.isSearchable,
		key: tab.key,
		list: tab.values ? tab.values.map((val) => {
			const retVal = {
				...val,
				value: val.attrValue,
				label: val.displayTxt
			};
			delete (retVal.attrValue);
			delete (retVal.displayTxt);
			return retVal;
		}) : []
	}));
};

/**
 * create input config object to render and save the policy according to policy format
 * iterate on each config field => iterate field.subfield => iterate field.subfield.inputfield
 * create UI data for each inputfield and its state, tooltips
 * create sectionkeys with proper attribute keys mapped to policy structure
 * @author mybizFe
 * @param { Object } fields object from get config call
 * @param { Object } policyConfigData { groups, fields, orgTierMapping, lobs,
 * @param { String } lob default selected lob
 * @param { Object } metaData
 * masterPolicyJson, pageHeading, pageSubHeading, switcherConfig } object from get config call
 * @return { Object }
 * fields - policy config with inputs according to policy unit used  to render fields on page
 * sectionKeys -  to save default policy attr for a section when setdefault selelcted
 * sectionattrKeys - attr keys of the inputs located in section which has set default on
 */
export const formatFieldConfig = (fields, policyConfigData, lob, metaData = {}) => {
	const { currency = '' } = metaData;
	const formattedUiFields = {};
	const sectionKeys = [];
	const sectionAttrKeys = {};

	const addBtnMapKeys = {};

	fields.forEach((field) => {
		const fieldAttrkey = field.attrKey;
		const fldattrKeyArr = fieldAttrkey ? fieldAttrkey.split('.') : false;
		let fldKeys = formattedUiFields;
		if (fldattrKeyArr) {
			for (let i = 0; i < fldattrKeyArr.length; i++) {
				const key = fldattrKeyArr[i];
				if (!fldKeys[key]) {
					fldKeys[key] = {};
				}
				fldKeys = fldKeys[key];
			}
		}

		field.attrKeys = field.attrKeys || [];
		field.subFields.forEach((subField) => {
			const allDynamic = subField.allDynamic;
			let subFldAttkey = subField.attrKey;
			const subFldAttkeyyArr = subFldAttkey ? subFldAttkey.split('.') : false;
			let formattedSubfield = fldKeys || setNestedObjectValue(fldKeys, subField.attrKey, {});
			if (subFldAttkeyyArr.length > 0) {
				for (let j = 0; j < subFldAttkeyyArr.length; j++) {
					const subKey = subFldAttkeyyArr[j];
					if (!formattedSubfield[subKey]) {
						formattedSubfield[subKey] = {};
					}
					formattedSubfield = formattedSubfield[subKey];
				}
			}
			subField.searchKey = fieldAttrkey && subFldAttkey ? [fieldAttrkey, subFldAttkey].join('.') : (fieldAttrkey || subFldAttkey);

			/* Setting section key for each subfield */
			formattedSubfield['sectionKey'] = field.fkey;

			/* Setting staticCount for a subfield : cabs */
			if (subField.staticCount) {
				formattedSubfield['staticCount'] = subField.staticCount;
			}

			if (allDynamic) {
				formattedSubfield['dynamicCount'] = 0;
				formattedSubfield['maxDynamicCount'] = subField.maxDynamicCount;
			}

			subField.inputFields && subField.inputFields.forEach((inputField) => {
				let policyAttrKey = subField.searchKey;
				let data = {};
				let {
					input,
					values,
					multiSelect,
					attrKey,
					key,
					icon,
					dynamic,
					displayTxt,
					subTxt,
					suffix,
					inputSuffix,
					prefix,
					selected,
					display,
					defautlVal,
					countKey,
					value,
					repeatCount,
					infoIcon,
					validation,
					infoTxt,
					onInputChange,
					btnType,
					placeholder,
					label,
					compareKey,
					disableSubFields
				} = inputField;

				const isDisabled = inputField.isDisabled || display === 'disabled';
				const showkey = attrKey || key;
				switch (input) {
					case 'textbox':
						data = {
							isMulti: false,
							value: defautlVal || '',
							defautlVal,
							name: showkey,
							key: showkey,
							type: attrKey === 'flight_price' || attrKey === 'ap_window' ? 'number' :
								(attrKey === 'hotel_mf' || attrKey === 'threshold' || attrKey?.includes('fwd_threshold_price') ? 'tel' : 'text'),
							icon: icon,
							dynamic: dynamic,
							suffix: suffix,
							inputSuffix: inputSuffix,
							prefix: prefix,
							repeatCount,
							infoIcon: infoIcon,
							subTxt: subTxt,
							validation: validation
						};
						if (icon === 'rupeeSym') {
							data.prefix = currency;
							data.icon = '';
							inputField.icon = '';
						}
						break;
					case 'dropdown':
						data = {
							compareKey,
							type: 'dropdown',
							isMulti: multiSelect,
							options: values.map((val) => {
								const retVal = {
									...val,
									value: val.attrValue,
									label: val.displayTxt,
									min: val.min,
									max: val.max
								};
								delete (retVal.attrValue);
								delete (retVal.displayTxt);
								return retVal;
							}),
							name: showkey,
							prefix: prefix,
							key: showkey,
							formatOptionLabel: icon && formatOptionLabel,
							hideSelectedOptions: false,
							icon: icon,
							placeholder: placeholder,
							dynamic: dynamic,
							countKey: countKey,
							value: typeof defautlVal === 'object' && !Array.isArray(defautlVal) ? {
								...defautlVal,
								value: defautlVal.attrValue,
								label: defautlVal.displayTxt
							} : Array.isArray(defautlVal) ? defautlVal.map((item) => ({
								...item,
								value: item.attrValue,
								label: item.displayTxt
							})) : '',
							subKey: subField.fkey,
							infoIcon,
							repeatCount,
							display,
							isDisabled,
							label: label,
							onInputChange
						};
						break;
					case 'checkbox':
						data = {
							key: showkey,
							type: 'checkbox',
							value: showkey,
							options: values && values.map((val) => ({
								subLabel: val.subText,
								label: val.displayTxt,
								value: val.attrValue
							})),
							icon: icon,
							isChecked: selected,
							display: display,
							defautlVal: defautlVal,
							subKey: subField.fkey,
							repeatCount,
							disableSubFields
						};
						break;
					case 'radio':
						data = {
							value: { value: defautlVal || '' },
							name: policyAttrKey,
							key: showkey,
							type: 'radio',
							customClass: policyAttrKey,
							options: values && values.map((val) => ({
								subLabel: val.subText,
								label: val.displayTxt,
								value: val.attrValue,
								infoIcon: val.infoIcon,
								iconToolTipData: val.infoIcon ? setToolTip(attrKey, {}, val.infoIcon, val.infoTxt, false, false) : false
							})),
							subKey: subField.fkey,
							display,
							repeatCount,
							infoIcon: infoIcon
						};
						break;
					case 'button':
						data = {
							name: showkey,
							key: showkey,
							type: 'button',
							icon: icon,
							displayTxt: displayTxt,
							repeatCount,
							btnType
						};
						if (allDynamic) {
							data.dynamicSubField = subField.searchKey;
						}
						break;
					case 'delete_button':
						data = {
							name: showkey,
							key: showkey,
							type: 'delete_button',
							icon: icon,
							subfield: subField.searchKey
						};
						break;
					case 'customCheckbox':
						data = {
							type: 'customCheckbox',
							value: {},
							// key: showkey,
							options: values.map((val) => {
								const retVal = {
									...val,
									value: val.attrValue,
									label: val.displayTxt
								};
								delete (retVal.attrValue);
								delete (retVal.displayTxt);
								return retVal;
							}),
							repeatCount
						};
						break;
					case 'groupCheckbox':
						data = {
							type: 'groupCheckbox',
							value: {},
							options: values.map((val) => {
								const retVal = {
									...val,
									value: val?.defautlVal || false,
									attrValue: val.attrValue,
									label: val.displayTxt
								};
								delete (retVal.displayTxt);
								return retVal;
							}),
							customClass: 'groupCheckbox'
						};
						break;
					case 'toggleSwitch':
						data = {
							value: false,
							name: showkey,
							key: showkey,
							subKey: subField.fkey,
							type: 'toggleSwitch',
							dynamic: dynamic,
							isSelected: selected,
							options: values,
							displayTxt: displayTxt,
							subTxt: subTxt,
							repeatCount
						};
						break;
					case 'icon':
						data = {
							type: 'icon',
							icon: icon,
							key: showkey,
							selected: selected
						};
						break;
					case 'freeText':
						data = {
							type: 'freeText',
							key: showkey,
							displayTxt: displayTxt
						};
						break;
					case 'tabDropdown':
						data = {
							type: 'tabDropdown',
							isMulti: multiSelect,
							data: getTabData(inputField),
							name: showkey,
							defaultPrefix: prefix,
							key: showkey,
							hideSelectedOptions: false,
							icon: icon,
							dynamic: dynamic,
							subKey: subField.fkey,
							repeatCount,
							infoIcon
						};
						break;
					case 'star':
						data = {
							type: 'star',
							value: '',
							key: showkey
						};
						break;
					default:
						break;
				}
				data.hide = display === 'hide';
				data.sectionKey = field.fkey;

				data.iconToolTipData = setToolTip(attrKey, policyConfigData, infoIcon, infoTxt, showkey, lob);

				if (!sectionKeys.includes(field.fkey)) {
					sectionKeys.push(field.fkey);
				}

				if (data.countKey) {
					data.subFldAttkey = subFldAttkey;
				}
				if (attrKey) { // nested policy attribute key
					if (key) {
						setNestedObjectValue(formattedSubfield, `${attrKey}.${key}`, data);
						if (formattedSubfield[attrKey]) {
							formattedSubfield[attrKey].sectionKey = field.fkey; // not used currently get the location of input field in field config and give a unique key to each seaction of subfields
						}
						inputField.searchKey = `${subFldAttkey ? `${subFldAttkey}.` : ''}${attrKey}.${key}`; // used when rendering the field from fields(api obj) to retrieve the required input obj the formattedconfig element
					} else {
						if (inputField.dynamic) {
							formattedSubfield['dynamicCount'] = 0;
							formattedSubfield['tierMapCount'] = 0;
						}
						setNestedObjectValue(formattedSubfield, attrKey, data);
						inputField.searchKey = `${subFldAttkey ? `${subFldAttkey}.` : ''}${attrKey}`;
					}
					policyAttrKey = policyAttrKey && attrKey ? [policyAttrKey, attrKey].join('.') : (policyAttrKey || attrKey);// final key in policy object
				} else if (key) {
					formattedSubfield[key] = data;
					inputField.searchKey = `${subFldAttkey ? `${subFldAttkey}.` : ''}${key}`;
				} else if (subFldAttkey) {
					fldKeys[subFldAttkey] = data;
					inputField.searchKey = subFldAttkey;
				}
				inputField.searchKey = fieldAttrkey ? `${fieldAttrkey}.${inputField.searchKey}` : inputField.searchKey;
				let pushSearchKey = inputField.searchKey && inputField.searchKey.split('.');
				if (pushSearchKey.length > 1) {
					pushSearchKey = [pushSearchKey[0], (pushSearchKey[1] || '')].join('.');
				} else {
					pushSearchKey = pushSearchKey[0];
				}
				if (subFldAttkey === 'conditional') {
					if (fieldAttrkey) {
						policyAttrKey = pushSearchKey = `${fieldAttrkey}.approval_policy.conditional_approval`;
					} else {
						policyAttrKey = pushSearchKey = 'approval_policy.conditional_approval';
					}
				}
				if (pushSearchKey.includes('block_skip_approval')) {
					policyAttrKey = pushSearchKey = 'block_skip_approval';
				}
				if (pushSearchKey.includes('block_oop_booking')) {
					policyAttrKey = pushSearchKey = 'block_oop_booking';
				}
				sectionAttrKeys[field.fkey] = sectionAttrKeys[field.fkey] || [];
				if (!sectionAttrKeys[field.fkey].includes(pushSearchKey)) {
					sectionAttrKeys[field.fkey].push(pushSearchKey);
					field.attrKeys.push(pushSearchKey);
				}
				if (typeof subField['defautlVal'] !== 'undefined' && typeof subField['display'] !== 'undefined' &&
					typeof data['defautlVal'] === 'undefined' && typeof data['display'] === 'undefined') {
					data['defautlVal'] = subField['defautlVal'];
					data['display'] = subField['display'];
				}
				data.policyAttrKey = policyAttrKey;
				addDynamicBtnKeys(inputField, addBtnMapKeys);
			});
			addDynamicBtnKeys(subField, addBtnMapKeys);
		});
		addDynamicBtnKeys(field, addBtnMapKeys);
	});

	return { fields: formattedUiFields, sectionKeys: sectionKeys, sectionAttrKeys: sectionAttrKeys, addBtnMapKeys };
};

const addDynamicBtnKeys = (field, addBtnMapKeys) => {
	if (field.addBtnKey) {
		addBtnMapKeys[field.addBtnKey] = addBtnMapKeys[field.addBtnKey] ? addBtnMapKeys[field.addBtnKey].push(field.searchKey) : [field.searchKey];
	}
};

/**
 * sets tool tip data for hover icons
 * @author mmt8054
 * @param { String } attrKey attribute keys from policy
 * @param { Object } policyConfigData policy config objects
 * @param { String } infoIcon
 * @param { String } infoTxt
 * @param { String } showkey
 * @param { String } lob default lob
 * @return {Object}
 */
const setToolTip = (attrKey, policyConfigData, infoIcon, infoTxt, showkey, lob) => {
	let iconToolTipData = false;
	if (attrKey === 'cabIcon') {
		iconToolTipData = {
			content: '',
			type: 'cabpolicy',
			tableData: getCabTypeTableData(policyConfigData?.policyConfigMetaData?.cabCategoriesDetails),
			heading: 'Cabs offered by myBiz',
			footer: ''
		};
	} else if (showkey === 'hotel_mf') {
		iconToolTipData = {
			content: 'Type in a number greater than 0 and less than equal to 1 to see how your organization’s hotel budget gets optimized when there are more than one employee travelling!',
			tableData: {},
			footer: ''
		};
	} else if (attrKey === 'priceRiseInfo') {
		let priceRise = priceRise = policyConfigData?.policyConfigMetaData?.priceRise || {};
		iconToolTipData = {
			content: '',
			tableData: {},
			heading: priceRise.header,
			footer: priceRise.footer,
			type: 'priceRise'
		};
	} else if (attrKey === 'cheap_recommendation') {
		iconToolTipData = {
			content: infoTxt,
			type: 'cheaperRecommendations'
		};
	} else if (infoIcon && infoTxt) {
		iconToolTipData = {
			content: infoTxt,
			type: 'infotooltip'
		};
	} else if (!iconToolTipData) {
		const tooltipData = policyConfigData?.policyConfigMetaData?.attrKey;
		if (tooltipData) {
			iconToolTipData = {
				content: tooltipData.content,
				tableData: tooltipData.tableData || {},
				heading: tooltipData.header,
				footer: tooltipData.footer,
				type: attrKey
			};
		}
	}
	return iconToolTipData;
};

/**
 * add the default approval policy inputs if no policy available for these
 * @author mybizFe
 * @param { Object } policyData policydata from api
 * @param { Object } inputFields policy config with inputs according to policy
 * @param { String } policyLobkey selelcted lob type
 */
const setDefaultApprFlds = (policyDataObj = {}, inputFields) => {
	if (inputFields['approval_policy'] && (!policyDataObj || !policyDataObj['approval_policy'])) {
		const polRule = policyDataObj && policyDataObj['approval_policy'] ? policyDataObj['approval_policy'] : {};
		setManagerPolicyVals(polRule['approving_managers_in_policy'], inputFields['approval_policy'], 'count_level_in');
		if (inputFields['approval_policy']['count_level_oo']) {
			setManagerPolicyVals(polRule['approving_managers_oo_policy'], inputFields['approval_policy'], 'count_level_oo');
		}
	}
};

/**
 * Updates the attribute rules in the configuration based on the provided rule and input fields.
 *
 * @author mmt8270
 * @param {Object} rule - The rule object containing attribute_key and operation_value.
 * @param {Object} inputFields - The input fields object where the rules will be applied.
 * @param {string} key - The key to identify the specific input field to update.
 * @param {boolean} isDefaultPolicy - Flag indicating if the policy is a default policy.
 */
const updateAttrbiuteRulesinConfig = (rule, inputFields, key, isDefaultPolicy, policyConfigMetaData) => {
	if (inputFields[key][rule.attribute_key]) {
		if (rule.attribute_key.includes('addons_')) {
			inputFields[key][rule.attribute_key].isChecked = rule.operation_value && rule.operation_value.max > 0;
		} else if (rule.attribute_key === 'ap_window' || rule.attribute_key === 'flight_duration') {
			if (inputFields[key][rule.attribute_key].type === 'dropdown') {
				inputFields[key][rule.attribute_key].value = inputFields[key][rule.attribute_key].options.filter((opt) => opt.value == (rule.operation_value.min || rule.operation_value.max))[0];
				return;
			}
			inputFields[key][rule.attribute_key].min.value = rule.operation_value && rule.operation_value.min;
			inputFields[key][rule.attribute_key].max.value = rule.operation_value && rule.operation_value.max;
		} else if (rule.attribute_key === 'travel_class' || rule.attribute_key === 'linked_travel_class') {
			const valuesList = rule.operation_value.list;
			let values = {};
			inputFields[key][rule.attribute_key].options.forEach((opt) => {
				if (valuesList.includes(opt.value)) {
					values[opt.value] = true;
				}
			});
			inputFields[key][rule.attribute_key].value = values;
			inputFields[key][rule.attribute_key]['inputFieldkey'] = rule.attribute_key;
		} else if (rule.attribute_key === 'cab_class') {
			const valuesList = rule?.operation_value?.list;
			const cabOptionsField = inputFields[key] && inputFields[key][rule.attribute_key];
			if (cabOptionsField) {
				let cabOptn = cabOptionsField?.options?.filter((opt) => valuesList.includes(opt.value));
				inputFields[key][rule.attribute_key].value = cabOptn;
			}
		} else if (rule?.attribute_key === 'bus_class') {
			const valuesList = rule?.operation_value?.list;
			const busOptionsField = inputFields[key] && inputFields[key][rule.attribute_key];
			if (busOptionsField) {
				let busoptn = busOptionsField?.options?.filter((opt) => valuesList.includes(opt.value));
				inputFields[key][rule.attribute_key].value = busoptn;
			}
		} else if (rule.attribute_key.includes('mybiz_assured') ||
							rule.attribute_key.includes('cheapest_flight') ||
							rule.attribute_key.includes('cheaper_flight_rcmd') ||
							rule.attribute_key.includes('hotel_cloud')
		) {
			inputFields[key][rule.attribute_key].isChecked = rule.operation_value ? rule.operation_value.min == 1 : false;
		} else if (rule.attribute_key.includes('cab_price_per_km') ||
							rule.attribute_key.includes('price_per_day') ||
							rule.attribute_key.includes('package_cost') ||
							rule.attribute_key.includes('linked_cab_price_per_km') ||
							rule.attribute_key.includes('linked_package_cost') ||
							rule.attribute_key.includes('linked_price_per_day')) {
			inputFields[key][rule.attribute_key].max.value = rule.operation_value && rule.operation_value.max;
		} else if (rule.attribute_key === 'star_category') {
			inputFields[key][rule.attribute_key].value = rule.operation_value && rule.operation_value.max;
		} else if (rule.attribute_key.includes('hotel_price') && inputFields.hotel_mf) {
			inputFields[key][rule.attribute_key].value = rule.operation_value && rule.operation_value.max;
			inputFields['hotel_mf'].iconToolTipData = getHotelMultiplierData(rule.operation_value && rule.operation_value.max, inputFields['hotel_mf'].value, policyConfigMetaData);
		} else if (rule.attribute_key === 'journey_class') {
			const valuesList = rule?.operation_value?.list;
			if (valuesList?.length > 0) {
				inputFields[key][rule.attribute_key].options.forEach((opt) => {
					if (valuesList.includes(opt.attrValue)) {
						opt['value'] = true;
					}
				});
			}
		} else {
			inputFields[key][rule.attribute_key].value = rule.operation_value && rule.operation_value.max;
		}
	} else if (rule.attribute_key.startsWith('tier')) {
		let tier = cloneDeep(inputFields[key]['tier_hotel_price']);
		if (tier) {
			let indx = rule.attribute_key.charAt(4);
			tier.key = rule.attribute_key;
			tier.name = rule.attribute_key;
			tier.value = rule.operation_value && rule.operation_value.max;
			tier.prefix = isDefaultPolicy ? null : `Tier ${indx}`;
			inputFields[key]['dynamicCount'] = tier.dynamic ? inputFields[key]['dynamicCount'] + 1 : inputFields[key]['dynamicCount'];
		}
		inputFields[key][rule.attribute_key] = tier;
	}
};

/**
 * Sets linked attributes for input fields based on provided rules.
 *
 * @author mmt8270
 * @param {Object} rules - The rules containing linked attributes and their values.
 * @param {Object} inputFields - The input fields to be updated with linked attributes.
 * @param {boolean} isDefaultPolicy - Flag indicating if the default policy is being used.
 *
 * @return {void}
 */
const setLinkedAttributes = (rules, inputFields, isDefaultPolicy) => {
	if (!inputFields) {
		return;
	}
	const linkedAttr = rules;
	for (let key in linkedAttr) {
		const subField = inputFields[key];
		const linkedAttrRule = linkedAttr[key];
		if (subField) {
			let dynamicFldsLength = 0;
			if (subField.staticCount) {
				dynamicFldsLength = subField.staticCount;
			} else {
				dynamicFldsLength = subField.dynamicCount = linkedAttrRule.values?.length || 0;
			}
			if (subField.isdisable) {
				subField.isdisable.isChecked = !linkedAttrRule.is_disable;
			}
			for (let i = 0; i < dynamicFldsLength; i++) {
				const inputFieldKey = `${i}_${key}`;
				if (!inputFields[inputFieldKey]) {
					inputFields[inputFieldKey] = cloneDeep(subField);
				}
				inputFields[inputFieldKey].disable = linkedAttrRule.is_disable;
				const compareFld = inputFields[inputFieldKey].compareFn;
				const currentRule = linkedAttrRule.values[i];
				const { attribute_rules, operation_value } = currentRule || {};
				const conditions = operation_value?.conditions;
				if (compareFld && currentRule) {
					const compareKey = compareFld.compareKey;
					const compareLinkedAttr = attribute_rules?.find((rule) => rule.attribute_key === compareKey)?.operation_value || conditions?.find((condition) => condition.attribute_key === compareKey)?.operation_value;
					const { max, min } = compareLinkedAttr || {};
					const compareFun = max == min ? POLICY_CONSTANTS.COMPARE_FNS.EQUAL : ((max > min) && min != 0) ? POLICY_CONSTANTS.COMPARE_FNS.GREATER_THAN_EQUAL : POLICY_CONSTANTS.COMPARE_FNS.LESS_THAN_EQUAL;
					compareFld.value = compareFld.options?.filter((opt) => opt.value === compareFun)[0];
				}
				if (attribute_rules) {
					attribute_rules.forEach((rule) => {
						updateAttrbiuteRulesinConfig(rule, inputFields[inputFieldKey], 'attribute_rules', isDefaultPolicy);
					});
				}
				if (conditions) {
					conditions.forEach((rule) => {
						updateAttrbiuteRulesinConfig(rule, inputFields[inputFieldKey], 'attribute_rules', isDefaultPolicy);
					});
				}
			}
		}
	}
};

/**
 * Updates policy values in the configuration based on the provided policy data object.
 *
 * @author mmt8270
 *
 * @param {Object} policyDataObj - The policy data object containing various policy rules.
 * @param {Object} inputFields - The input fields object where the policy values will be updated.
 * @param {boolean} isDefaultPolicy - Flag indicating if the policy is a default policy.
 * @param {Object} policyMetaData - Metadata related to the policy.
 * @param {Object} policyConfigMetaData - Configuration metadata related to the policy.
 * @param {Object} hideKeys - Object containing keys to be hidden based on policy rules.
 * @param {string} defaultPolicyGroupId - The ID of the default policy group.
 * @param {string} selectedGroupId - The ID of the selected policy group.
 * @param {Object} defaultPolicy - The default policy object.
 * @param {Function} setDefaultVals - Function to set default values.
 * @return {Object} - An object containing keys to be hidden based on policy rules.
 */
const updatePolicyValuesInConfig = (policyDataObj, inputFields, isDefaultPolicy, policyMetaData, policyConfigMetaData, hideKeys, defaultPolicyGroupId, selectedGroupId, defaultPolicy, setDefaultVals) => {
	let hidePolicyKeys = {};
	for (let key in policyDataObj) {
		let rules = policyDataObj[key];
		switch (key) {
			case 'attribute_rules':
				if (!inputFields[key]) {
					break;
				}
				rules.forEach((rule) => {
					updateAttrbiuteRulesinConfig(rule, inputFields, key, isDefaultPolicy, policyConfigMetaData);
				});
				break;
			case 'allowed_fares':
				if (!inputFields[key]) {
					break;
				}
				if (rules && rules.length > 0) {
					let option = inputFields[key].options.filter((opt) => rules.includes(opt.value));
					if (!inputFields[key].isMulti) {
						option = option[0];
					}
					inputFields[key].value = option;
					inputFields[key].selectedValue = option;
				}

				break;
			case 'cab_class':
				if (!inputFields[key]) {
					break;
				}
				const classOpt = rules;
				let optn = inputFields[key].options.filter((opt) => classOpt.includes(opt.value));
				inputFields[key].value = optn;
				inputFields[key].selectedValue = optn;
				break;
			case 'cheaper_flights':
				if (inputFields[key]?.display) {
					const dispval = rules.display === true || rules.display === 'true' ? 'true' : 'false';
					inputFields[key].display.value = inputFields[key].display.options.filter((opt) => dispval == (opt.value))[0];
					inputFields[key].display.selectedValue = cloneDeep(inputFields[key].display.value);
				}
				if (inputFields[key]?.threshold && rules.threshold) {
					inputFields[key].threshold.defaultVal = inputFields[key].threshold.value = (rules.threshold);
				}
				if (inputFields[key]?.allowed) {
					inputFields[key].allowed.defaultVal = inputFields[key].allowed.value = inputFields[key].allowed.options.filter((opt) => rules.allowed === (opt.value))[0];
					upateCheaperFlightFld(inputFields);
				}

				break;
			case 'approval_policy':
				if (!inputFields[key]) {
					break;
				}
				rules.conditional_approval && rules.conditional_approval.sort((a, b) => (a.priority - b.priority)).forEach((condition, i) => {
					udpateConditionalApprFields(inputFields, i, condition); // update/create approval/condiotional  input optionns
				});
				setManagerPolicyVals(rules && rules['approving_managers_in_policy'], inputFields[key], 'count_level_in');
				if (inputFields[key]['count_level_oo']) {
					setManagerPolicyVals(rules && rules['approving_managers_oo_policy'], inputFields[key], 'count_level_oo');
				}
				break;
			case 'approval':
				if (!inputFields[key]) {
					break;
				}
				let ipVal = APPROVAL_KEY_CONFIG[rules];
				let opVal = APPROVAL_KEY_CONFIG[rules];
				if (rules === 'NO_APPROVAL_REQUIRED_WITHIN_POLICY') {
					ipVal = 'ANR';
					opVal = 'AAR';
				} else if (rules === 'NO_APPROVAL_REQUIRED_WITHIN_POLICY' && policyDataObj['block_oop_booking'] === 1) {
					ipVal = 'AAR';
				}

				inputFields[key].ip_val.selectedValue = inputFields[key].ip_val.value = inputFields[key].ip_val.options.filter((opt) => ipVal === (opt.key))[0];
				if (policyDataObj['block_oop_booking'] === 1) {
					inputFields[key].oop_aprvl_ip.selectedValue = inputFields[key].oop_aprvl_ip.value = inputFields[key].oop_aprvl_ip.options.filter((opt) => 'NA' === (opt.key))[0];
				} else {
					inputFields[key].oop_aprvl_ip.selectedValue = inputFields[key].oop_aprvl_ip.value = inputFields[key].oop_aprvl_ip.options.filter((opt) => opVal === (opt.key))[0];
				}
				if (inputFields['approval'].block_skip_approval && typeof policyDataObj['block_skip_approval'] !== 'undefined') {
					inputFields['approval'].block_skip_approval.isChecked = policyDataObj['block_skip_approval'] === 0;
				}
				hidePolicyKeys = { ...hidePolicyKeys, ...updateApprovalFieldsOptions(inputFields) };
				break;
			case 'eligible_states': {
				if (!inputFields[key]) {
					break;
				}
				const stateMetaValues = policyMetaData ? policyMetaData.hotelCloudEligibleStates : false;
				const stateCodesArr = rules['hotel_cloud_state'];
				if (stateCodesArr.length > 0) {
					inputFields[key]['hotel_cloud_state'].value = [];
					for (let i = 0; i < stateCodesArr.length; i++) {
						const stateCode = stateCodesArr[i];
						inputFields[key]['hotel_cloud_state'].value.push({ checked: true, label: (stateMetaValues?.[stateCode] || stateCode), value: stateCode });
					}
				}
				break;
			}
			case 'block_skip_approval':
				if (!inputFields['approval'] || !inputFields['approval'].block_skip_approval) {
					break;
				}
				if (typeof rules !== 'undefined') {
					inputFields['approval'].block_skip_approval.isChecked = rules === 0;
				}
				hidePolicyKeys = { ...hidePolicyKeys, ...updateApprovalFieldsOptions(inputFields) };
				break;
			case 'capture_all_pax_details':
				if (!inputFields[key]) {
					break;
				}
				inputFields[key]['isChecked'] = rules;
				break;
			case 'payment_options':
				if (!inputFields[key]) {
					break;
				}
				inputFields[key]['WALLET'].selectedValue = inputFields[key]['WALLET'].value = inputFields[key]['WALLET'].options.filter((opt) => rules['WALLET'] === (opt.value))[0];
				hidePolicyKeys = { ...hidePolicyKeys, ...updateApprovalFieldsOptions(inputFields) };
				break;
			case 'block_odc':
				if (!inputFields[key]) {
					break;
				}
				inputFields[key].selectedValue = inputFields[key].value = rules === 1 ? inputFields[key].options[0] : inputFields[key].options[1];
				break;
			case 'dom_htl_price_tax_eligibility':
			case 'intl_htl_price_tax_eligibility':
				if (!inputFields[key]) {
					break;
				}
				inputFields[key].value = inputFields[key].options?.filter((opt)=>opt.value === rules)[0];
				break;
			case 'ancillary_payment_options':
				if (!inputFields[key]) {
					break;
				}
				inputFields[key]['wallet'].value = inputFields[key]['wallet'].isSelected = inputFields[key]['wallet'].options[0].attrValue === rules['WALLET'];
				break;
			case 'hotel_mf':
				if (!inputFields[key]) {
					break;
				}
				inputFields[key].value = rules || 1;
				inputFields[key].iconToolTipData = getHotelMultiplierData(inputFields['attribute_rules']['hotel_price']?.value, parseFloat(rules), policyConfigMetaData);
				break;
			case 'wallet_enabled_for_other_booking':
			case 'autobook_add_on_failure':
			case 'insurance_auto_select':
				if (!inputFields[key]) {
					break;
				}
				inputFields[key].value = inputFields[key].isSelected = rules;
				break;
			case 'personal_booking_policy':
				if (!inputFields[key]) {
					break;
				}
				setmultipleCheckboxFields(inputFields[key], rules);
				if (policyDataObj?.['travel_option'] === 'POST_APPROVAL') {
					hidePolicyKeys['personal_book'] = true;
				}
				break;
			case 'auto_book_approval':
				if (!inputFields['auto_book_approval']) {
					break;
				}
				inputFields['auto_book_approval']['isChecked'] = rules;
				if (rules == false && typeof policyDataObj['price_rise_threshold'] === 'undefined') {
					hidePolicyKeys['price_rise_per'] = true;
				}
				if (rules == false && typeof policyDataObj['autobook_add_on_failure'] === 'undefined') {
					hidePolicyKeys['f_autobook_add_on_failure'] = true;
				}
				break;
			case 'price_rise_threshold':
				if (!inputFields[key]) {
					break;
				}
				inputFields['price_rise_threshold']['value'] = rules;
				break;
			case 'dom_htl_fwd_threshold_price':
			case 'dom_flt_fwd_threshold_price':
			case 'intl_htl_fwd_threshold_price':
			case 'intl_flt_fwd_threshold_price':
				if (!inputFields[key]) {
					break;
				}
				inputFields[key]['value'] = rules;
				break;
			case 'trf_enabled':
				if (!inputFields[key]) {
					break;
				}
				inputFields[key].block.value = inputFields[key].block.isChecked = rules;
				break;
			case 'budget_stays_enable':
			case 'disable_non_req_official':
			case 'req_hide':
			case 'unblock_hotels':
				if (!inputFields[key]) {
					break;
				}
				inputFields[key].value = inputFields[key].isChecked = rules;
				break;
			case 'default_filters':
				if (!inputFields[key]) {
					break;
				}
				const filterOpt = rules;
				let fltOpt = inputFields[key].options.filter((opt) => filterOpt.includes(opt.value));
				inputFields[key].value = fltOpt;
				inputFields[key].selectedValue = fltOpt;
				break;
			case 'booking_completion':
			case 'travel_option':
				if (!inputFields[key]) {
					break;
				}
				const selOpt = inputFields[key].options.filter((opt) => rules == (opt.value));
				inputFields[key].selectedValue = inputFields[key].value = selOpt[0];
				updateHideRequisitionFields(inputFields);
				// hideKeys = { ...hideKeys, ...temp };
				break;
			case 'guest_booking':
			case 'employee_booking_eligibility':
			case 'acknowledge_to_mail_type':
				if (!inputFields[key]) {
					break;
				}
				inputFields[key].value = { ...inputFields[key].options?.filter((values) => values?.value === (rules))[0] };
				break;
			case 'policy_operations':
				if (!inputFields[key]) {
					break;
				}
				if (inputFields[key]['travel_class'] && rules['travel_class']) {
					inputFields[key]['travel_class']['isChecked'] = rules['travel_class']['block_oop_booking'];
				}
				if (inputFields[key]['block_booking_operation'] && (rules['hotel_mybiz_assured'] || rules['hotel_cloud'] || rules['guest_house_policy'])) {
					const opts = inputFields[key]['block_booking_operation'].options;
					let uiSelectedeVal = [];
					let dropownoptions = [];
					for (let i = 0; i < opts.length; i++) {
						const updatedObj = { ...opts[i], checked: rules[opts[i].value]?.['block_oop_booking'] };
						dropownoptions.push(updatedObj);
						if (rules[opts[i].value]?.['block_oop_booking']) {
							uiSelectedeVal.push(updatedObj);
						}
					}
					inputFields[key]['block_booking_operation']['value'] = uiSelectedeVal;
					inputFields[key]['block_booking_operation']['options'] = dropownoptions;
				}
				break;
			case 'requisition_services':
			case 'guest_house':
				if (!inputFields[key]) {
					break;
				}
				setmultipleCheckboxFields(inputFields[key], rules);
				break;
			case 'trf_modification_enabled':
				if (!inputFields[key]) {
					break;
				}
				inputFields[key]['isChecked'] = rules?.length > 0;
				break;
			case 'offline_booking_payment_options':
				if (!inputFields[key]) {
					break;
				}
				if (inputFields[key]['WALLET']?.options) {
					inputFields[key]['WALLET'].value = { ...inputFields[key]['WALLET'].options.filter((values) => values?.value === (rules['WALLET']))[0] };
				}
				break;
			case 'free_cancellation_addon_eligibility':
				if (!inputFields[key]) {
					break;
				}
				inputFields[key].value = inputFields[key].options?.filter((values) => (values?.value) === (rules?.toString()))[0];
				break;
			case 'linked_attributes':
				setLinkedAttributes(rules, inputFields[key], isDefaultPolicy);
				break;
			case 'intl_policy':
				hidePolicyKeys = updatePolicyValuesInConfig(rules, inputFields['intl_policy'], isDefaultPolicy, policyMetaData, policyConfigMetaData, hidePolicyKeys);
				break;
			default:
				break;
		}
	}
	return { ...hideKeys, ...hidePolicyKeys };
};

export const updateApprovalHideOpts = (inputFields, hideKeys) =>{
	if (inputFields['approval_policy']) { // if to show approval input
		if (inputFields['approval_policy']['count_level_in']) {
			hideKeys[inputFields['approval_policy']['count_level_in'].subKey] = true;
		}
		if (inputFields['approval_policy']['count_level_oo']) {
			hideKeys[inputFields['approval_policy']['count_level_oo'].subKey] = true;
		}
	}
};

/**
 * add the policy values to formatted input config object
 * @author mybizFe
 * @param { Object } policyConfigMetaData cab category price anem details and enableDontChargeEmployeeOption flag
 * @param { Object } formattedOrgConfig policy config with inputs according to policy unit format global to be used for tier
 * @param { Object } grpPolicy saved policy for the selected group
 * @param { String } lob selelcted lob type
 * @param { Object } inputFields formatted input config object created using api config data
 * @param { String|Number } defaultPolicyGroupId
 * @param { String|Number } selectedGroupId
 * @param { Object } defaultPolicy default group -9 policy data
 * @param { Object } setDefaultVals to decide whether to update default policy val in config or group policy(when grp policy not available)
 * @return { Object }
 * fields {} - policy config with inputs according to policy unit used  to render fields on page
 * sectionKeys -  to save default policy attr for a section when setdefault selelcted
 * sectionattrKeys - attr keys of the inputs located in section which has set default on
 */
export const formatPolicyData = (policyConfigMetaData = {}, formattedOrgConfig, grpPolicy, lob, inputFields = false, defaultPolicyGroupId, selectedGroupId, defaultPolicy, setDefaultVals) => {
	const policyObj = !isCabsPolicy(lob) && setDefaultVals ? defaultPolicy : grpPolicy;
	let policyMetaData = {};
	let sectionKeys = [];
	let hideKeys = {};
	let hideBlockLobField = false;
	let policyLobkey = lob_icon_map[lob] ? lob_icon_map[lob].policyKey : false;
	let policyData = policyObj && policyObj.policy && policyObj.policy.policy_units;
	const isDefaultPolicy = selectedGroupId === defaultPolicyGroupId;

	if (policyObj && policyObj.policy && inputFields) {
		const block_lobs = policyObj.policy.block_lobs ? [...policyObj.policy.block_lobs] : [];
		const hide_lobs = policyObj.policy.hide_lobs ? [...policyObj.policy.hide_lobs] : [];
		let blockLobKey = lob_icon_map[lob].blockLob;
		const blockInput = block_lobs && block_lobs.includes(blockLobKey);
		let hideLob = false;
		if (!blockInput) {
			hideLob = hide_lobs && hide_lobs.includes(blockLobKey);
		}
		if (inputFields['block_lobs']) { // set value for block lob dropdown
			const key = 'block_lobs';
			inputFields[key].block.value = inputFields[key].block.options.filter((opt) => {
				if (blockInput) {
					return opt.value === 'true';
				} else if (hideLob) {
					return opt.value === 'hide';
				}
				return opt.value === 'false';
			})[0];
			inputFields[key].block.selectedValue = cloneDeep(inputFields[key].block.value);
			hideBlockLobField = inputFields[key].block.value?.value === 'true';
		}
	}
	if (policyData && inputFields) {
		updateApprovalHideOpts(inputFields, hideKeys);
		if (inputFields['intl_policy']) {
			updateApprovalHideOpts(inputFields['intl_policy'], hideKeys);
		}
		const block_skip_approval = policyData.block_skip_approval;
		const block_oop_booking = policyData.block_oop_booking;
		policyMetaData = policyObj.policyMetaData && policyObj.policyMetaData.policyUnitsMetaData ? policyObj.policyMetaData.policyUnitsMetaData[policyLobkey] : false;
		if (policyMetaData) {
			sectionKeys = policyMetaData.defaultSections;
		}
		let policyDataObj = (policyData[policyLobkey]);
		if (policyDataObj && block_skip_approval && typeof policyDataObj.block_skip_approval === 'undefined') {
			policyDataObj.block_skip_approval = block_skip_approval;
		}
		if (policyDataObj && block_oop_booking && typeof policyDataObj.block_oop_booking === 'undefined') {
			policyDataObj.block_oop_booking = block_oop_booking;
		}
		// / for loop
		hideKeys = updatePolicyValuesInConfig(policyDataObj, inputFields, isDefaultPolicy, policyMetaData, policyConfigMetaData, hideKeys);

		setDefaultApprFlds(policyData[policyLobkey], inputFields, policyLobkey);
		if (inputFields['intl_policy']) {
			setDefaultApprFlds(policyData[policyLobkey]?.['intl_policy'], inputFields['intl_policy'], policyLobkey);
		}

		if ((isDefaultPolicy || setDefaultVals) && lob === LOB_CONSTANTS.DOM_HOTELS) {
			tierFallBack(inputFields, formattedOrgConfig, isDefaultPolicy);
		}
		if (isCabsPolicy(lob)) {
			const { cabCategoriesDetails = [], enableDontChargeEmployeeOption, currency } = policyConfigMetaData || {};
			if (inputFields.attribute_rules.min_fare && cabCategoriesDetails.length > 0) {
				const fareData = onCabsTypeChange(inputFields.cab_class, cabCategoriesDetails);
				updateCabdMinfare(fareData, inputFields, lob, currency);
			}
			chargeEmpHandler(grpPolicy, defaultPolicy, inputFields, enableDontChargeEmployeeOption, lob);
		}
		if (isFlightPolicy(lob) || isHotelPolicy(lob)) {
			let autoBookApproval = inputFields['auto_book_approval'];
			if (policyDataObj &&
        typeof policyDataObj['auto_book_approval'] === 'undefined' &&
        typeof policyDataObj['price_rise_threshold'] === 'undefined' &&
        autoBookApproval &&
        inputFields['price_rise_threshold'] &&
        inputFields['autobook_add_on_failure']
			) {
				let status = autoBookApproval['isChecked'];
				if (status) {
					inputFields['price_rise_threshold']['value'] = MIN_PRISE_RISE_THRESHOLD;
					inputFields['autobook_add_on_failure']['value'] = false;
				} else {
					hideKeys['price_rise_per'] = true;
					hideKeys['f_autobook_add_on_failure'] = false;
				}
			}
		}
	} else {
		if (isCabsPolicy(lob)) {
			const { enableDontChargeEmployeeOption } = policyConfigMetaData || {};
			chargeEmpHandler(grpPolicy, defaultPolicy, inputFields, enableDontChargeEmployeeOption, lob);
		}
		setDefaultApprFlds(policyData, inputFields, policyLobkey);
		return {
			inputFields: (inputFields),
			hideKeys: hideKeys,
			sectionKeys: sectionKeys,
			hideAllfields: hideBlockLobField
		};
	}
	return {
		inputFields: (inputFields),
		hideKeys: hideKeys,
		sectionKeys: sectionKeys,
		hideAllfields: hideBlockLobField
	};
};

/**
 * charge_emp field of cabs will be set based on the assumption
 * 1. cabs will never have default policy set from backend
 * 2. check if groupLevel policy exists, if exists then check charge_emp field exists if not then fallback to config
 * 3. if group level does not exists fallback to default policy.
 * 4. if default policy exists, if exists then check charge_emp field exists if not then fallback to config .
 *
 * @author mmt8054
 * @param {Object} grpPolicy
 * @param {Object} defaultPolicy
 * @param {Object} inputFields
 * @param {Boolean} enableDontChargeEmployeeOption
 * @param {String} lob
 */
export const chargeEmpHandler = (grpPolicy, defaultPolicy, inputFields, enableDontChargeEmployeeOption, lob) => {
	let grpPolicyExists = grpPolicy?.policy?.policy_units;
	let cabPolicy = grpPolicyExists && grpPolicyExists[lob];

	let defaultPolicyExists = defaultPolicy?.policy?.policy_units;
	let defaultCabPolicy = defaultPolicyExists && defaultPolicyExists[lob];
	const policy = cabPolicy ? cabPolicy : defaultCabPolicy;
	chargeEmpExists(policy, inputFields, enableDontChargeEmployeeOption);
};

/**
 * common case check if policy exists and  charge_emp field exists in policy if not fallback to config
 * @author mmt8054
 * @param {Object} policy  could be default or group level
 * @param {Object} inputFields
 * @param {Boolean} enableDontChargeEmployeeOption flag from config
 */
export const chargeEmpExists = (policy, inputFields, enableDontChargeEmployeeOption) => {
	if (policy && typeof policy['charge_emp'] !== 'undefined' &&
		inputFields['payment_options'] &&
		inputFields['payment_options']['charge_emp']) {
		inputFields['payment_options']['charge_emp']['isChecked'] = !policy['charge_emp'];
		inputFields['payment_options']['charge_emp']['enabled'] = true;
		inputFields['payment_options']['charge_emp']['options'].splice(1, 1);
	} else {
		cabPolicyFallBackToConfig(inputFields, enableDontChargeEmployeeOption);
	}
};

/**
 * fallback function when  charge_emp is not available in group or default policy
 * @author mmt8054
 * @param {Object} inputFields
 * @param {Boolean} enableDontChargeEmployeeOption
 */
const cabPolicyFallBackToConfig = (inputFields, enableDontChargeEmployeeOption) => {
	if (inputFields['payment_options'] &&
		inputFields['payment_options']['charge_emp']) {
		inputFields.payment_options.charge_emp.isChecked = false;
		inputFields.payment_options.charge_emp.enabled = enableDontChargeEmployeeOption;
		if (inputFields.payment_options.charge_emp.options.length > 1) {
			const deleteIndex = enableDontChargeEmployeeOption ? 1 : 0;
			inputFields.payment_options.charge_emp.options.splice(deleteIndex, 1);
		}
	}
};

export const onCabsTypeChange = (input, cabCategoriesDetails) => {
	if (input && input.value && input.value.length > 0) {
		let minFarePerKm = 10000;
		let minFarePerDay = 10000;
		for (let obj of input.value) {
			const filterResult = cabCategoriesDetails.filter((f) => f.Group.toLowerCase() === obj.label.toLowerCase());
			const minPricePerKM = Math.min(...filterResult.map((elt) => Number(elt['Min Price per KM'])));
			const minPricePerDay = Math.min(...filterResult.map((elt) => Number(elt['Min Price per day'])));
			minFarePerKm = Math.min(minFarePerKm, minPricePerKM);
			minFarePerDay = Math.min(minPricePerDay, minFarePerDay);
		}
		return {
			minFarePerKm: minFarePerKm === 10000 ? null : minFarePerKm,
			minFarePerDay: minFarePerDay === 10000 ? null : minFarePerDay
		};
	}
};

export const isCabsPolicy = (lob) => lob === LOB_CONSTANTS.AIRPORT_CABS || lob === LOB_CONSTANTS.OUTSTATION_CABS || lob === LOB_CONSTANTS.RENTAL_CABS;
export const isHotelPolicy = (lob) => lob === LOB_CONSTANTS.DOM_HOTELS || lob === LOB_CONSTANTS.INTL_HOTELS;
export const isFlightPolicy = (lob) => lob === LOB_CONSTANTS.DOM_FLIGHTS || lob === LOB_CONSTANTS.INTL_FLIGHTS;

/**
 * scroll policy page to selected group accordion
 * @author mybizFe
 * @param { String|Number } selectedGroupId
 */
export const handleScrolling = (selectedGroupId) => {
	let selector = document.getElementById(`ref_${selectedGroupId}`);
	scrollOnWindow(selector, 60 + 136);
};

const isValid = (key) => (input) => {
	if (key === 'ap_window') {
		return input > AP_WIN_MAX ? `should be less than ${AP_WIN_MAX}` : true;
	}
	return true;
};

/**
 * proceed to policy/group page
 * @author mybizFe
 */
export const proceedToPolicyListing = () => {
	let orgId = getOrgId();
	let url = PATHS.GROUPS_PAGE;
	if (orgId) {
		url = PATHS.GROUPS_PAGE;
	}
	window.location.href = url;
};

/**
 * updated tierdata in input config
 * @author mmt8054
 * @param {Object} tierData
 * @param {Object} orgConfig policy config with inputs according to policy unit format global to be used for tier
 * @return {Object} formattedOrgConfig with tier data updated
 */
export const formatTierMapping = (tierData, orgConfig = {}) => {
	if (tierData && orgConfig['attribute_rules'] && orgConfig['attribute_rules'][`tier_city`]) {
		tierData.forEach((tier) => {
			const {
				tierName,
				locList
			} = tier;
			addNewTier(orgConfig, tierName.substr(tierName.length - 1), locList, tierName);
		});
	}
	return orgConfig;
};

export const addNewTier = (formattedOrgConfig, renderedCount, locList, tier_name) => {
	let searchKey = '';
	let tierCity = cloneDeep(formattedOrgConfig['attribute_rules']['tier_city']);
	tierCity['tier_name'] = tier_name;

	const opt = locList.map((val) => {
		const retVal = {
			...val,
			value: val.code,
			label: val.name
		};
		delete (retVal.name);
		delete (retVal.code);
		return retVal;
	});

	tierCity['selectedValue'] = cloneDeep(opt);
	tierCity['value'] = cloneDeep(tierCity['selectedValue']);
	searchKey = `attribute_rules.tier_city${renderedCount}`;
	tierCity['searchKey'] = searchKey;
	tierCity['prefix'] = `Tier ${renderedCount}`;
	tierCity['key'] = `tier_city${renderedCount}`;
	formattedOrgConfig['attribute_rules'][`tier_city${renderedCount}`] = tierCity;
	const count = formattedOrgConfig['attribute_rules']['tierMapCount'];
	formattedOrgConfig['attribute_rules']['tierMapCount'] = count + 1;
};

export const updateCabdMinfare = (data, configField, lob, currency = '') => {
	if (data) {
		const { minFarePerKm, minFarePerDay } = data;
		configField.attribute_rules.min_fare['minFarePerKm'] = minFarePerKm;
		configField.attribute_rules.min_fare['minFarePerDay'] = minFarePerDay;
		let text = `For your selected cab type fare starts at ${currency} ${minFarePerKm}/km`;
		text = lob === LOB_CONSTANTS.OUTSTATION_CABS ? `${text} & ${currency} ${minFarePerDay}/day` : text;
		configField.attribute_rules.min_fare['displayTxt'] = text;
	} else {
		configField.attribute_rules.min_fare['minFarePerKm'] = '';
		configField.attribute_rules.min_fare['minFarePerDay'] = '';
		configField.attribute_rules.min_fare['displayTxt'] = '';
	}
};

const tierFallBack = (inputFields, formattedOrgConfig, isDefaultPolicy) => {
	let tier_keys = Object.keys(formattedOrgConfig['attribute_rules']).filter(function(tier) {
		return tier.indexOf('tier_city') == 0;
	});

	for (let index in tier_keys) {
		let key_obj = tier_keys[index];
		if (key_obj !== 'tier_city') {
			let indx = key_obj.charAt(9);
			let key = `tier${indx}_hotel_price`;
			if (!inputFields['attribute_rules'][key]) {
				let tier = cloneDeep(inputFields['attribute_rules']['tier_hotel_price']);
				if (tier) {
					tier.key = key;
					tier.name = key;
					tier.prefix = isDefaultPolicy ? null : `Tier ${indx}`;
					inputFields['attribute_rules']['dynamicCount'] = tier.dynamic ? inputFields['attribute_rules']['dynamicCount'] + 1 : inputFields[key]['dynamicCount'];
				}
				inputFields['attribute_rules'][key] = tier;
			}
		}
	}
};

export const tierResponseConverter = (formattedOrgConfig) => {
	let resp = {
		tierMapping: {
			'levelType': 'ORG_LEVEL',
			'mappingList': []

		}
	};

	let mapping = [];
	let tier_keys = Object.keys(formattedOrgConfig['attribute_rules']).filter(function(tier) {
		return tier.indexOf('tier_city') == 0;
	});

	for (let index in tier_keys) {
		let key_obj = tier_keys[index];
		if (key_obj !== 'tier_city') {
			let tierObj = {};
			let tier = formattedOrgConfig['attribute_rules'][key_obj];

			let opt = tier.value.map((val) => {
				const retVal = {
					...val,
					code: val.value,
					name: val.label
				};
				delete (retVal.value);
				delete (retVal.label);
				delete (retVal.checked);
				return retVal;
			});
			if (opt && opt.length > 0) {
				tierObj['locList'] = opt;
				tierObj['tierName'] = tier.tier_name;
				mapping.push(tierObj);
			}
		}
	}

	if (mapping.length > 0) {
		resp.tierMapping.mappingList = mapping;
	}

	return resp;
};

/**
 * create policy data to be saved and sent in api
 * @author mybizFe
 * @param { String|Number } defaultPolicyGroupId
 * @param { Object } policyChange
 * @param { Object } formattedOrgConfig
 * @param { Object } policyConfig formatted input config object created using api config data
 * @param { Object } masterPolicyJsonObj master policy which is used to get policy response structure/keys
 * @param { Object } policyData saved policy for the selected group
 * @param { String } lob selelcted lob type
 * @param { Object } sectionKeys
 * @param { Object } sectionAttrKeys
 * @param { Object } hideAllfields  {id: Boolean} block lob object to show or  hide fields if block lob input selected
 * @param { Object } policyConfigMetaData cab category price anem details and enableDontChargeEmployeeOption flag
 * @return { Object } policy opject for all the groups for which which policy has to be updated
 */
export const createResponse = (defaultPolicyGroupId, policyChange, formattedOrgConfig, policyConfig, masterPolicyJsonObj, policyData = {}, lob, sectionKeys, sectionAttrKeys, hideAllfields, policyConfigMetaData) => {
	let resp = {
		createOrUpdatePolicyV2BulkRequest: []
	};
	const defaultPolicyData = policyData?.[defaultPolicyGroupId]?.policy;
	const defaultPolicyMetaData = policyData?.[defaultPolicyGroupId]?.policyMetaData;
	const lobMap = lob_icon_map[lob];
	const defaultPolicy = lobMap ? defaultPolicyData?.policy_units?.[lobMap.policyKey] : false;
	for (const grpId in policyConfig) { // get policy for each group
		if (policyChange[grpId] > 0) {
			const grpPolicy = policyData[grpId];
			if (defaultPolicy) {
				const policyBlockLob = grpPolicy?.policy?.block_lobs || defaultPolicyData?.block_lobs;
				const policyHideLob = grpPolicy?.policy?.hide_lobs || defaultPolicyData?.hide_lobs;
				defaultPolicy.block_lobs = getBlockHideArray(defaultPolicyData?.block_lobs, policyBlockLob, lobMap.blockLob);
				defaultPolicy.hide_lobs = getBlockHideArray(defaultPolicyData?.hide_lobs, policyHideLob, lobMap.blockLob);
			}
			let policyResp = responseConverter(defaultPolicyGroupId, grpId, policyConfig[grpId], masterPolicyJsonObj, grpPolicy, lob, defaultPolicy, sectionKeys[grpId], sectionAttrKeys, hideAllfields[grpId], policyConfigMetaData, defaultPolicyMetaData);
			resp.createOrUpdatePolicyV2BulkRequest.push(policyResp);
		}
	}
	if (lob === LOB_CONSTANTS.DOM_HOTELS || lob === LOB_CONSTANTS.INTL_HOTELS) {
		resp.createUpdateOrDeletePolicyConfigMapping = { [lobMap.policyKey]: tierResponseConverter(formattedOrgConfig) };
		let hotelMapping = [];
		const orgConfig = policyConfig[defaultPolicyGroupId];
		if (orgConfig && orgConfig['exclude_properties'] && orgConfig['exclude_properties'].value && policyConfig[-9]['exclude_properties'].value.length > 0) {
			hotelMapping = orgConfig['exclude_properties'].value.map((val) => {
				const retVal = {
					...val,
					code: val.value,
					name: val.label
				};
				delete (retVal.value);
				delete (retVal.label);
				delete (retVal.checked);
				return retVal;
			});
			resp.createUpdateOrDeletePolicyConfigMapping[lobMap.policyKey].blockedHotelMapping = { levelType: 'ORG_LEVEL', hotelList: hotelMapping };
		}
	}
	return resp;
};

/**
 * get block lob and hide values from user input or derive from default policy
 * @author mmt8270
 * @param { Array } defaultArr default policy block/hide lob array
 * @param { Array } policyArr current group policy block/hide lob array
 * @param { String } lob selected block/Hide lob key
 * @return { Array } hide block lob array incorporating setasdefault case
 */
const getBlockHideArray = (defaultArr = [], policyArr = [], lob) => {
	let retArr = policyArr;
	const lobIndefault = defaultArr.includes(lob);
	if (lobIndefault && !policyArr.includes(lob)) {
		retArr = [...policyArr, lob];
	} else if (!lobIndefault) {
		retArr = removeBlockHideLob([...policyArr], [lob]);
	}
	return retArr;
};

/**
 * remove block hide lob array from policy of cuurent lob
 * @author mmt8270
 * @param { Array } lobArray policy block/hide lob array
 * @param { Array } lobKeys selected block/Hide lob key
 * @return { Array } hide block lob array after removing lob
 */
const removeBlockHideLob = (lobArray = [], lobKeys) => {
	lobKeys.forEach((key) => {
		const ind = lobArray.indexOf(key);
		if (ind > -1) {
			lobArray.splice(ind, 1);
		}
	});
	return lobArray;
};

/**
 * get block lob and hide values from user input or derive from default policy
 * @author mybizFe
 * @param { Object } uiInputField  formattedConfig[block_lobs][block] employee eligibility field
 * @param { Object } grpPolicy grp policy to be Updated
 * @param { Object } defaultPolicy default grp policy
 * @param { Boolean } isDefault if default policy is upated by user
 * @param { Array } sectionKeys meta keys for selected group that stores keys of input to be set as default
 * @param { Object } lobKeyConfig lob key config data
 * @return { Object } hidelob and blocklob field
 */
const setBlockLobHidePolicy = (uiInputField, grpPolicy = {}, defaultPolicy = {}, isDefault, sectionKeys, lobKeyConfig) => {
	let blockLob = grpPolicy['block_lobs'] || [];
	let hideLob = grpPolicy['hide_lobs'] || [];
	if (uiInputField) {
		if (!isDefault && sectionKeys.includes(uiInputField.sectionKey) && defaultPolicy) {
			blockLob = defaultPolicy.block_lobs;
			hideLob = defaultPolicy.hide_lobs;
		} else {
			let attrValue = uiInputField?.value?.value;
			const blockLobKeys = [lobKeyConfig?.blockLob];

			// attrvalue=='false'
			removeBlockHideLob(blockLob, blockLobKeys);
			removeBlockHideLob(hideLob, blockLobKeys);

			if (attrValue == 'true') {// show lob
				blockLob = [...blockLob, ...blockLobKeys];
			} else if (attrValue === 'hide') { // hide lob
				hideLob = [...hideLob, ...blockLobKeys];
			}
		}
	}
	return { hideLob, blockLob };
};

export const convertPolicyConfigtoPolicyUnit = (masterpolicyObj, mandatoryFields, hideAllGrpfields, currentPolicy, policyJson, defaultPolicy, formattedConfig = {}, isDefault, sectionKeys, policyConfigMetaData, stateCodeMap, defaultlobMeta) => {
	for (let key in masterpolicyObj) {
		let input = {};
		const masterPolicyVal = masterpolicyObj[key];
		if (mandatoryFields.includes(key) && hideAllGrpfields) { // set policyval for mandatory fields when all fields hiiden
			policyJson[key] = (!currentPolicy || typeof currentPolicy[key] === 'undefined') ?
				(defaultPolicy && typeof defaultPolicy[key] !== 'undefined' ?
					defaultPolicy[key] : masterPolicyVal) :
				currentPolicy[key];
		}
		switch (key) {
			case 'attribute_rules':
				if (formattedConfig[key]) {
					policyJson[key] = [];
				}
				formattedConfig[key] && masterPolicyVal.forEach((rule) => {
					let newRule = cloneDeep(rule);
					const attrKey = rule.attribute_key;
					const input = formattedConfig[key]?.[attrKey];
					const defRule = defaultPolicy?.[key] && defaultPolicy[key].filter((rl) => rl.attribute_key === attrKey)[0];
					newRule = mandatoryFields.includes(attrKey) && defRule ? defRule : newRule;
					if (input) {
						if (!isDefault && sectionKeys.includes(input.sectionKey) && defRule) {
							policyJson[key].push(defRule);
						} else {
							setAtributeRules(attrKey, policyJson[key], input, newRule, mandatoryFields.includes(attrKey) && hideAllGrpfields);
						}
					}
				});
				if (policyJson[key] && policyJson[key].length === 0) { // if no attributes are present delete node
					delete (policyJson[key]);
				}
				break;
			case 'charge_emp':
				const defValue = defaultPolicy && typeof defaultPolicy.charge_emp !== 'undefined' ? defaultPolicy.charge_emp : masterPolicyVal;
				saveEmpCharge(policyConfigMetaData, formattedConfig, isDefault, sectionKeys, defValue, policyJson, currentPolicy);
				break;
			case 'cab_class':
			case 'allowed_fares':
			case 'default_filters':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				let fare = [];
				for (let index in input.value) {
					let fies = input.value[index];
					let v1 = fies.value;
					fare.push(v1);
				}
				if (fare.length > 0) {
					policyJson[key] = fare;
				}
				break;
			case 'cheaper_flights':
				const cheaperVal = defaultPolicy?.[key] || masterPolicyVal;
				policyJson[key] = upateCheaperFlightPolicy(formattedConfig[key], cheaperVal, masterPolicyVal, isDefault, sectionKeys);
				break;
			case 'acknowledge_to_mail_type':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				policyJson[key] = input.value?.value || 'NONE';
				break;
			case 'guest_booking':
			case 'employee_booking_eligibility':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				policyJson[key] = input.value?.value;
				break;
			case 'approval_policy':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.count_level_in.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				setManagerAndConditionalApprovals(formattedConfig, policyJson, key, masterpolicyObj);
				break;
			case 'approval':
				input = formattedConfig[key];
				if (!input || (!isDefault && sectionKeys.includes(input.ip_val.sectionKey)) || hideAllGrpfields) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				if (formattedConfig[key]) {
					setApprovals(formattedConfig[key], policyJson, key);
				}
				break;
			case 'block_skip_approval':
				input = formattedConfig['approval']?.['block_skip_approval'];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				policyJson[key] = input.isChecked ? 0 : 1;
				if (policyJson['approval'] === 'NO_APPROVAL_REQUIRED') {
					policyJson[key] = 0;
				}
				break;
			case 'capture_all_pax_details':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				policyJson[key] = input.isChecked ? 1 : 0;
				break;
			case 'dom_htl_fwd_threshold_price':
			case 'dom_flt_fwd_threshold_price':
			case 'intl_htl_fwd_threshold_price':
			case 'intl_flt_fwd_threshold_price':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				policyJson[key] = input.value;
				break;
			case 'block_oop_booking':
				input = formattedConfig['approval'];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.oop_aprvl_ip.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				policyJson[key] = 0;
				if (input.oop_aprvl_ip && input.oop_aprvl_ip.value) {
					policyJson[key] = input.oop_aprvl_ip.value.key === 'NA' ? 1 : 0;
				}
				break;
			case 'policy_operations':
				input = formattedConfig['policy_operations'];
				const travelClassInput = input?.['travel_class'];
				const blockBookingsInput = input?.['block_booking_operation'];
				const isHotelOpns = !!blockBookingsInput;
				if (!input || (!travelClassInput && !blockBookingsInput)) {
					break;
				}
				policyJson[key] = {};
				if (travelClassInput && ((!isDefault && sectionKeys.includes(travelClassInput?.sectionKey)) || hideAllGrpfields)) {
					policyJson[key] = {};
					policyJson[key]['travel_class'] = {};
					policyJson[key]['travel_class']['block_oop_booking'] = defaultPolicy?.[key]?.['travel_class']?.['block_oop_booking'] ||
																											masterPolicyVal?.['travel_class']?.['block_oop_booking'];
					break;
				}
				// handling for hotel policy
				if (isHotelOpns && (!isDefault && sectionKeys.includes(blockBookingsInput?.sectionKey)) || hideAllGrpfields) {
					policyJson[key]['hotel_cloud'] = {};
					policyJson[key]['hotel_mybiz_assured'] = {};
					policyJson[key]['hotel_cloud']['block_oop_booking'] = typeof (defaultPolicy?.[key]?.['hotel_cloud']['block_oop_booking']) === undefined ? masterPolicyVal?.['hotel_cloud']['block_oop_booking'] :
						defaultPolicy?.[key]?.['hotel_cloud']['block_oop_booking'];
					policyJson[key]['hotel_mybiz_assured']['block_oop_booking'] = typeof (defaultPolicy?.[key]?.['hotel_mybiz_assured']['block_booking_operation']) === undefined ? masterPolicyVal?.['hotel_mybiz_assured']['block_oop_booking'] :
						defaultPolicy?.[key]?.['hotel_mybiz_assured']['block_oop_booking'];
					break;
				}

				isHotelOpns ? blockBookingsInput?.options.forEach((item)=>{
					policyJson[key][item.value] = {};
					policyJson[key][item.value]['block_oop_booking'] = item.checked ? item.checked : false;
				}) :
					policyJson[key] = {
						'travel_class': {
							'block_oop_booking': travelClassInput?.isChecked
						}
					};
				break;
			case 'payment_options':
				input = formattedConfig[key];
				if (!input || (!isDefault && sectionKeys.includes(input.WALLET.sectionKey)) || hideAllGrpfields) {
					policyJson[key] = policyJson[key] || {};
					policyJson[key]['WALLET'] = defaultPolicy && defaultPolicy[key] ? defaultPolicy[key]['WALLET'] : 'NEVER_ALLOWED';
					break;
				}
				if (input.WALLET.value) {
					policyJson[key] = policyJson[key] || {};
					policyJson[key]['WALLET'] = input.WALLET.value.value;
				}
				break;
			case 'block_odc':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				if (input.value) {
					policyJson[key] = Number(input.value.value);
				}
				break;
			case 'dom_htl_price_tax_eligibility':
			case 'intl_htl_price_tax_eligibility':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				if (input.value) {
					policyJson[key] = input.value.value;
				}
				break;
			case 'ancillary_payment_options':
				input = formattedConfig[key]['wallet'];
				if (!input) {
					break;
				}
				if (input && !isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				policyJson[key] = {};
				policyJson[key]['WALLET'] = input.value ? input.options[0].attrValue : input.options[1].attrValue;
				break;
			case 'hotel_mf':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				policyJson[key] = input.value;
				break;
			case 'wallet_enabled_for_other_booking':
			case 'insurance_auto_select':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				policyJson[key] = input.value ? input.options[0].attrValue : input.options[1].attrValue;
				policyJson[key] = (policyJson[key] === 'true' || policyJson[key] === 'false') ? policyJson[key] === 'true' : input.value.value;
				break;
			case 'trf_enabled':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				policyJson[key] = input.block ? input.block.isChecked : false;
				break;
			case 'personal_booking_policy':
				input = formattedConfig[key];
				if (!input) {
					break;
				} else {
					policyJson[key] = {};
				}
				if (!isDefault && sectionKeys.includes(input[Object.keys(input)[0]].sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				setmultipleCheckboxFields(policyJson[key], input, true);
				break;
			case 'auto_book_approval':
				input = formattedConfig[key];
				if (!(input && formattedConfig['price_rise_threshold'])) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					policyJson['price_rise_threshold'] = defaultPolicy?.['price_rise_threshold'];
					policyJson['autobook_add_on_failure'] = defaultPolicy?.['autobook_add_on_failure'];
					break;
				}
				policyJson[key] = input.isChecked;
				if (input.isChecked) {
					try {
						policyJson['price_rise_threshold'] = Number(formattedConfig['price_rise_threshold']['value']);
						policyJson['autobook_add_on_failure'] = formattedConfig['autobook_add_on_failure']?.['value'];
					} catch (error) {
						saveServerLogs(error.stack, 'error', `auto_book_approval`);
					}
				}
				break;
			case 'booking_completion':
			case 'travel_option':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				if (input.value) {
					policyJson[key] = (input.value.value);
				}
				break;
			case 'req_hide':
			case 'disable_non_req_official':
			case 'budget_stays_enable':
			case 'unblock_hotels':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				policyJson[key] = input ? input.isChecked : false;
				break;
			case 'requisition_services':
			case 'guest_house':
				input = formattedConfig[key];
				if (!input) {
					break;
				} else {
					policyJson[key] = {};
				}
				const sectionkey = Object.keys(input)[0];
				if (!isDefault && sectionKeys.includes(input[sectionkey].sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				setmultipleCheckboxFields(policyJson[key], input, true);
				break;
			case 'trf_modification_enabled':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				const modificationLobs = getModificationLobs(formattedConfig['requisition_services']);
				policyJson[key] = input.isChecked ? modificationLobs : [];
				break;
			case 'offline_booking_payment_options':
				input = formattedConfig[key]?.['WALLET'];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				policyJson[key] = policyJson[key] || {};
				policyJson[key]['WALLET'] = input.value?.value;
				break;

			case 'free_cancellation_addon_eligibility':
				input = formattedConfig[key];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = defaultPolicy?.[key] || masterPolicyVal;
					break;
				}
				if (input.value && input.value.value) {
					policyJson[key] = (input.value.value === 'true' || input.value.value === 'false') ? input.value.value === 'true' : input.value.value;
				}
				break;
			case 'eligible_states': {
				input = formattedConfig[key]?.['hotel_cloud_state'];
				if (!input) {
					break;
				}
				if (!isDefault && sectionKeys.includes(input.sectionKey)) {
					policyJson[key] = (defaultPolicy?.[key] || masterPolicyVal);
					stateCodeMap = defaultlobMeta?.hotelCloudEligibleStates;
					break;
				}
				for (let i = 0; i < input.value?.length; i++) {
					stateCodeMap[input.value[i].value] = input.value[i].label;
				}
				const toSaveVal = !(input.value) ? [] : input.value?.map((item)=>item.value);
				policyJson[key] = {
					'hotel_cloud_state': toSaveVal
				};
				break;
			}
			case 'linked_attributes':
				/* Formatted config of input fields to maintain */
				const linkedAttrInputs = formattedConfig[key];
				const linkedPolicyKeys = Object.keys(masterPolicyVal);
				const linkedPolicyJson = {};
				linkedPolicyKeys.forEach((linkedKey) => {
					/* Default template for linked attribute input field */
					const defaultLinkedAttrTemplateInput = linkedAttrInputs[linkedKey];
					if (defaultLinkedAttrTemplateInput) {
						if (!isDefault && sectionKeys.includes(defaultLinkedAttrTemplateInput.sectionKey)) {
							policyJson[key] = (defaultPolicy?.[key]) || {};
							policyJson[key][linkedKey] = defaultPolicy?.[key]?.[linkedKey] || masterPolicyVal[linkedKey];
						} else {
							/* Dynamic count or static count for number of times the linked attribute fields will be repeating */
							const dynamicCount = defaultLinkedAttrTemplateInput?.dynamicCount || defaultLinkedAttrTemplateInput?.staticCount;
							if (dynamicCount > 0) {
								linkedPolicyJson[linkedKey] = linkedPolicyJson[linkedKey] || masterPolicyVal[linkedKey] || { values: [] };
								/* Setting is disabled flag for disabling or enabling fields  */
								linkedPolicyJson[linkedKey]['is_disable'] = linkedAttrInputs[linkedKey]?.isdisable?.isChecked !== undefined ? !linkedAttrInputs[linkedKey].isdisable.isChecked : false;
							}
							for (let i = 0; i < dynamicCount; i++) {
								/* The json of the linked attribute that needs to be updated */
								const dynamicLinkedJson = linkedPolicyJson[linkedKey].values[i] = linkedPolicyJson[linkedKey].values[i] || cloneDeep(linkedPolicyJson[linkedKey].values[0]);
								/* Setting priority in dynamic json which can be static (0 in cabs) or dynamic */
								dynamicLinkedJson.priority = defaultLinkedAttrTemplateInput?.staticCount ? 0 : i;
								/* Dynamic input from the formatted config of input fields */
								const linkedAttrDynamicInput = linkedAttrInputs[`${i}_${linkedKey}`];
								if (linkedAttrDynamicInput) {
									const compareVal = linkedAttrDynamicInput?.compareFn?.value?.value;
									const compareAttr = linkedAttrDynamicInput?.compareFn?.compareKey;
									for (let attrKey in linkedAttrDynamicInput.attribute_rules) {
										/* inputCond: the config of input field with value and other details based on attribute key */
										const inputCond = linkedAttrDynamicInput.attribute_rules[attrKey];
										/* attrJson: the json object for the attribute key in which the field values need to be updated */
										const attrJson = dynamicLinkedJson.attribute_rules?.filter((rule) => rule.attribute_key == attrKey)[0] ||
										dynamicLinkedJson?.operation_value?.conditions?.filter((condition) => condition.attribute_key === attrKey)[0] || {};
										if (attrKey === compareAttr) {
											const attrVal = inputCond.value?.value || inputCond.value;
											inputCond.min = {
												value: ((compareVal == POLICY_CONSTANTS.COMPARE_FNS.EQUAL) || (compareVal == POLICY_CONSTANTS.COMPARE_FNS.GREATER_THAN_EQUAL)) ? attrVal : 0
											};
											inputCond.max = {
												value: ((compareVal == POLICY_CONSTANTS.COMPARE_FNS.EQUAL) || (compareVal == POLICY_CONSTANTS.COMPARE_FNS.LESS_THAN_EQUAL)) ? attrVal : PRICE_MAX
											};
										}
										if (!inputCond) {
											continue;
										}
										const policyVal = setAtributeRules(attrKey, [], inputCond, attrJson)[0];
									}
								}
							}
							policyJson[key] = linkedPolicyJson;
						}
					}
				});
				break;
			case 'intl_policy':
				policyJson['intl_policy'] = policyJson['intl_policy'] || {};
				convertPolicyConfigtoPolicyUnit(masterPolicyVal, mandatoryFields, hideAllGrpfields, currentPolicy?.['intl_policy'], policyJson?.['intl_policy'], defaultPolicy?.['intl_policy'], formattedConfig?.['intl_policy'], isDefault, sectionKeys, policyConfigMetaData, stateCodeMap, defaultlobMeta);
				break;
			default:
				continue;
		}
	}
};

/**
 * create policy for one group to be saved and sent in api
 * @author mybizFe
 * @param { String|Number } defaultPolicyGroupId
 * @param { String|Number } groupId
 * @param { Object } formattedConfig fields rendered on UI
 * @param { Object } masterPolicyJson master policy which is used to get policy response structure/keys
 * @param { Object } policyObj saved policy for the selected group
 * @param { String } lob selelcted lob type
 * @param { Object } defaultPolicy
 * @param { Object } sectionKeys
 * @param { Object } sectionAttrKeys
 * @param { Object } hideAllGrpfields  {id: Boolean} block lob object to show or  hide fields if block lob input selected
 * @param { Object } policyConfigMetaData cab category price anem details and enableDontChargeEmployeeOption flag
 * @param { Object } defaultPolicyMetaData policy metadata for default group to be used for sending states metadata
 * @return { Object } returs policy object for the groupId
 */
const responseConverter = (defaultPolicyGroupId, groupId, formattedConfig, masterPolicyJson, policyObj, lob, defaultPolicy, sectionKeys = [], sectionAttrKeys, hideAllGrpfields, policyConfigMetaData, defaultPolicyMetaData) => {
	let masterPolicyJsonObj = cloneDeep(masterPolicyJson.policy_units);
	let policy = policyObj && policyObj.policy;
	const blockOopBooking = policy?.block_oop_booking;
	const blockSkipAppr = policy?.block_skip_approval;
	const lobKeyConfig = lob_icon_map[lob];
	let policyLobkey = lobKeyConfig?.policyKey;
	const currentPolicy = policy?.policy_units?.[policyLobkey];
	let policyResponse = {};
	let policyJson = {};

	const isDefault = Number(defaultPolicyGroupId) === Number(groupId);
	let approvalInputsVal = {};
	let stateCodeMap = {};
	const { blockLob, hideLob } = setBlockLobHidePolicy(formattedConfig.block_lobs?.block, policy, defaultPolicy, isDefault, sectionKeys, lobKeyConfig);
	if (masterPolicyJsonObj) {
		let masterPolicyDataObj = masterPolicyJsonObj[policyLobkey];
		const defaultlobMeta = defaultPolicyMetaData?.policyUnitsMetaData?.[policyLobkey];
		convertPolicyConfigtoPolicyUnit(masterPolicyDataObj, mandatoryFields, hideAllGrpfields, currentPolicy, policyJson, defaultPolicy, formattedConfig, isDefault, sectionKeys, policyConfigMetaData, stateCodeMap, defaultlobMeta);
	}

	const metaData = getUnitsMetaDataResponse(sectionKeys, sectionAttrKeys, policyLobkey, approvalInputsVal, stateCodeMap);

	policyResponse = {
		'groupId': groupId,
		'orgLevelPolicy': groupId == defaultPolicyGroupId,
		'policyUnitTypeToCreateOrUpdate': policyLobkey,
		'policy': {
			'policy_id': policy ? policy.policy_id : null,
			'policy_name': policy ? policy.policy_name : 'testpolicy',
			'policy_version': 3,
			'policy_status': 'active',
			'advance_policy_version': 2,
			'block_lobs': blockLob || [],
			'hide_lobs': hideLob || [],
			'policy_units': {
				[policyLobkey]: policyJson
			}
		},
		'policyMetaData': {
			'policyUnitsMetaData': metaData
		}
	};
	if (blockSkipAppr) {
		policyResponse['policy']['block_skip_approval'] = blockSkipAppr;
	}
	if (blockOopBooking) {
		policyResponse['policy']['block_oop_booking'] = blockOopBooking;
	}
	return policyResponse;
};

/**
 * check if user has explicitly mapped non org group policy to default policy
 * 1. if yes the then check if default policy exists if yes save based on default and if not then fallback
 * to config (enableDontChargeEmployeeOption)
 * 2. if default is not mappped the check if current policy already exists, if not fallback to default
 * meaning if enableDontChargeEmployeeOption is true the only save to policy json
 * 3. if yes exists then check if property exists if not the fallback to config to save
 * 4. last if above cases fail check fo default policy and save fallback
 *
 * @author mmt8054
 * @param { Object } policyConfigMetaData cab category price anem details and enableDontChargeEmployeeOption flag
 * @param {Object} formattedConfig
 * @param {Boolean} isDefault
 * @param {Object} sectionKeys
 * @param {Object} defaultRule
 * @param {Object} policyJson
 * @param {Object} currentPolicy
 */
const saveEmpCharge = (policyConfigMetaData = {}, formattedConfig, isDefault, sectionKeys, defaultRule, policyJson, currentPolicy) => {
	const enableDontChargeEmployeeOption = policyConfigMetaData.enableDontChargeEmployeeOption;
	let input = formattedConfig['payment_options'] && formattedConfig['payment_options']['charge_emp'];

	if (input && !isDefault && sectionKeys.includes(input.sectionKey)) {
		shouldSavechargeEmp(defaultRule, enableDontChargeEmployeeOption, input, policyJson);
	} else if (currentPolicy && input) {
		if ((typeof currentPolicy['charge_emp'] !== 'undefined') || enableDontChargeEmployeeOption) {
			policyJson['charge_emp'] = !input.isChecked;
		}
	} else if (input) {
		shouldSavechargeEmp(defaultRule, enableDontChargeEmployeeOption, input, policyJson);
	}
};

/**
 * save emp charge for default policy
 * @author mmt8054
 * @param {Object} defaultRule
 * @param {Boolean} enableDontChargeEmployeeOption
 * @param {Object} input
 * @param {Object} policyJson
 */
const shouldSavechargeEmp = (defaultRule, enableDontChargeEmployeeOption, input, policyJson) => {
	if (typeof defaultRule !== 'undefined') {
		policyJson['charge_emp'] = defaultRule;
	} else if (enableDontChargeEmployeeOption) {
		policyJson['charge_emp'] = !input.isChecked;
	}
};

/**
* metadata response to be saved other than policy data used to render and update policy
* @author mybizFe
* @param { Object } sectionKeys
* @param { Object } sectionAttrKeys
* @param { String } lob selelcted lob type
* @param { Object } policyValues block lob array
* @param { Object } stateCodeMap for hotel cloud state mapping
* @return { Object } returs policy object for the groupId
*/
const getUnitsMetaDataResponse = (sectionKeys, sectionAttrKeys, lob, policyValues = {}, stateCodeMap) => {
	const retData = {
		defaultPolicyAttributes: [],
		defaultSections: sectionKeys,
		policyValues: policyValues
	};
	if (stateCodeMap) {
		retData['hotelCloudEligibleStates'] = stateCodeMap;
	}
	for (let i in sectionKeys) {
		const sectionAttributes = sectionAttrKeys[sectionKeys[i]] ? sectionAttrKeys[sectionKeys[i]] : [];
		retData.defaultPolicyAttributes = [...retData.defaultPolicyAttributes, ...sectionAttributes];
	}
	return {
		[lob]: retData
	};
};

/**
 * update attribute rules in policy json
 * @author mybizFe
 * @param { String } rule_key
 * @param { Object } policyJson policy json to be updated with attribute rules
 * @param { Object } input input object from which data values to be used
 * @param { Object } newRule
 * @param { Boolean } mandatory if policy  attr val is mandatory and its hidden on UI due to block lob
 * @return { Object } returs policy object for the groupId
 */
const setAtributeRules = (rule_key, policyJson, input, newRule = {}, mandatory = false) => {
	let set = false;
	if (!rule_key) {
		return {};
	}
	if (rule_key.includes('addons_')) {
		if (input.isChecked) {
			newRule.operation_value.max = PRICE_MAX;
			newRule.operation_value.min = 0;
		} else {
			newRule.operation_value.max = 0;
			newRule.operation_value.min = 0;
		}
		policyJson.push(newRule);
		set = true;
	} else if (rule_key.includes('linked_travel_class')) {
		let list = [];
		const cabinTypes = input.value;
		for (let val in cabinTypes) {
			if (cabinTypes[val]) {
				list.push(val);
			}
		}
		newRule.operation_value.list = list;
		policyJson.push(newRule);
		set = true;
	} else if (rule_key === 'travel_class') {
		let list = [];
		const cabinTypes = input.value;
		for (let val in cabinTypes) {
			if (cabinTypes[val]) {
				list.push(val);
			}
		}
		if (list.length > 0) {
			newRule.operation_value.list = list;
			policyJson.push(newRule);
			set = true;
		}
	} else if (rule_key.includes('bus_class')) {
		let list = [];
		for (let index in input.value) {
			let busClasses = input.value[index];
			let busClassesValues = busClasses.value;
			list.push(busClassesValues);
		}
		if (list.length > 0) {
			newRule.operation_value.list = list;
			policyJson.push(newRule);
			set = true;
		}
	} else if (checkForKeysInArray(rule_key,
		['flight_price', 'cabs_price', 'bus_price', 'trip_hours', 'star_category', 'offline_book_price', 'offline_mod_price', 'train_price', 'trip_itinerary_price'])) {
		if (input.value) {
			newRule.operation_value.max = input.value;
			newRule.operation_value.min = 0;
			policyJson.push(newRule);
			set = true;
		}
	} else if (rule_key.includes('hotel_price')) {
		if (rule_key.startsWith('tier')) {
			if (input.value) {
				newRule.operation_value.max = input.value;
				newRule.operation_value.min = 0;
				policyJson.push(newRule);
				set = true;
			}
		} else if (rule_key.startsWith('hotel_price')) {
			if (input.value) {
				newRule.operation_value.max = input.value;
				policyJson.push(newRule);
				set = true;
			}
		}
	} else if (rule_key.includes('ap_window')) {
		let min = input.min && input.min.value;
		let max = input.max && input.max.value;
		min = min && Number(min);
		max = max && Number(max);
		if (typeof min !== 'undefined' && typeof max !== 'undefined' && min !== '' && max !== '' && !isNaN(min) && !isNaN(max)) {
			newRule.operation_value.min = min;
			newRule.operation_value.max = max;
			policyJson.push(newRule);
			set = true;
		}
	} else if (rule_key.includes('flight_duration')) {
		let min = input.min && input.min.value;
		let max = input.max && input.max.value;
		min = min && Number(min);
		max = max && Number(max);
		if (typeof min !== 'undefined' && typeof max !== 'undefined' && min !== '' && max !== '' && !isNaN(min) && !isNaN(max)) {
			if (min == 0 && max == PRICE_MAX) {
				newRule.operation_value = null;
			} else {
				newRule.operation_value.min = min;
				newRule.operation_value.max = max;
			}
		} else {
			newRule.operation_value = null;
		}
		policyJson.push(newRule);
		set = true;
	} else if (rule_key.includes('bus_price')) {
		if (input.value) {
			newRule.operation_value.max = input.value;
			newRule.operation_value.min = 0;
			policyJson.push(newRule);
			set = true;
		}
	} else if (rule_key.includes('trip_hours')) {
		if (input.value) {
			newRule.operation_value.max = input.value;
			newRule.operation_value.min = 0;
			policyJson.push(newRule);
			set = true;
		}
	} else if (checkForKeysInArray(rule_key, ['cab_price_per_km', 'price_per_day', 'package_cost', 'linked_cab_price_per_km', 'linked_package_cost', 'linked_price_per_day'])) {
		let min = 0;
		let max = input.max && input.max.value;
		/* Shared Cabs scenario : when rule key is one among 'linked_cab_price_per_km', 'linked_package_cost', 'linked_price_per_day' */
		if (rule_key.includes('linked')) {
			if (input.max.value === '') {
				newRule.operation_value = null;
			} else {
				min = min && Number(min);
				max = max && Number(max);
				if (typeof min !== 'undefined' && typeof max !== 'undefined' && min !== '' && max !== '' && !isNaN(min) && !isNaN(max)) {
					newRule.operation_value = { 'type': 'RangeOperationValue' };
					newRule.operation_value.min = min;
					newRule.operation_value.max = max;
				}
				policyJson.push(newRule);
				set = true;
			}
		} else {
			min = min && Number(min);
			max = max && Number(max);
			if (typeof min !== 'undefined' && typeof max !== 'undefined' && min !== '' && max !== '' && !isNaN(min) && !isNaN(max)) {
				newRule.operation_value.min = min;
				newRule.operation_value.max = max;
				policyJson.push(newRule);
				set = true;
			}
		}
	} else if (rule_key.includes('trip_duration')) {
		let min = 0;
		let max = input.value;
		if (typeof min !== 'undefined' && typeof max !== 'undefined' && min !== '' && max !== '' && !isNaN(min) && !isNaN(max)) {
			newRule.operation_value.min = min;
			newRule.operation_value.max = max;
			policyJson.push(newRule);
			set = true;
		}
	} else if (checkForKeysInArray(rule_key, ['mybiz_assured', 'cheapest_flight', 'cheaper_flight_rcmd', 'hotel_cloud', '_oop', 'guest_house_policy'])) {
		set = true;
		if (input.display && input.display === 'hide') {
			bizAssuredValidation(input.defautlVal, newRule);
		} else {
			bizAssuredValidation(input.isChecked, newRule);
		}
		policyJson.push(newRule);
	} else if (rule_key === 'journey_class') {
		let list = [];
		for (let opt of input.options) {
			if (opt.value) {
				list.push(opt.attrValue);
			}
		}
		if (list.length > 0) {
			newRule.operation_value.list = list;
			policyJson.push(newRule);
			set = true;
		}
	} else if (rule_key.includes('cheapest_flight') || rule_key.includes('mybiz_assured') || rule_key.includes('cheaper_flight_rcmd')) {
		bizAssuredValidation(true, newRule);
		policyJson.push(newRule);
		set = true;
	} else if (rule_key === 'cab_class') {
		let list = [];
		for (let opt of input.value) {
			if (opt.value) {
				list.push(opt.value);
			}
		}
		newRule.operation_value.list = list;
		policyJson.push(newRule);
		set = true;
	} else if (rule_key === 'pax_count') {
		if (input.type === 'freeText' && input.displayTxt) {
			newRule.operation_value.max = input.displayTxt;
			newRule.operation_value.min = input.displayTxt;
			policyJson.push(newRule);
			set = true;
		}
	}
	if (mandatory && !set) {
		policyJson.push(newRule);
	}

	return policyJson;
};

const bizAssuredValidation = (condition, newRule) => {
	if (condition) {
		newRule.operation_value.max = 1;
		newRule.operation_value.min = 1;
	} else {
		newRule.operation_value.max = 1;
		newRule.operation_value.min = -1;
	}
};

/**
 * update manager approval values for inpolicy/outpolicy and conditional approvals
 * @author mybizFe
 * @param { Object } formattedConfig
 * @param { Object } policyJson
 * @param { String } key
 * @param { Object } masterPolicyDataObj
 * @return { Object } returns policy json object for selected group
 */
const setManagerAndConditionalApprovals = (formattedConfig, policyJson, key, masterPolicyDataObj) => {
	const conditions = formattedConfig['conditional'] && formattedConfig['conditional'].dynamicCount;
	const inApprs = getManagerApprVals(formattedConfig[key], 'count_level_in');

	let ooApprs = [];
	if (formattedConfig[key]['count_level_oo']) {
		ooApprs = getManagerApprVals(formattedConfig[key], 'count_level_oo');
	}

	if (conditions || inApprs.length > 0 || ooApprs.length > 0) {
		policyJson[key] = {};
		if (inApprs.length > 0) {
			policyJson[key]['approving_managers_in_policy'] = {
				'all': inApprs
			};
		}
		if (ooApprs.length > 0) {
			policyJson[key]['approving_managers_oo_policy'] = {
				'all': ooApprs
			};
		}
		if (conditions) {
			policyJson[key]['conditional_approval'] = [];
			for (let i = 0; i < conditions; i++) {
				let attrJson = [];
				const apprCond = {};
				const inputCond = formattedConfig[i + '_conditional'];
				const comprFn = inputCond.compareFn.value.value;
				const attrKey = inputCond.attribute_key.value.attrKey;
				const newRule = cloneDeep(masterPolicyDataObj['attribute_rules'].filter((attrs) => attrs.attribute_key === attrKey)[0]);
				apprCond.attribute_rule = setAtributeRules(attrKey, attrJson, inputCond.typeVal, newRule)[0] || newRule;
				if (comprFn === 'SEL') {
					inputCond.typeVal.value = 1;
				}
				if (newRule.operation === 'RANGE' && apprCond.attribute_rule) {
					const maxVal = attrKey.includes('ap_window') ? AP_WIN_MAX : PRICE_MAX;
					const compareFnconfigVal = inputCond.compareFn.value;
					const inoutTypeVal = inputCond.typeVal?.value || 0;
					apprCond.attribute_rule.operation_value = {
						type: newRule.operation_value.type,
						max: comprFn === 'GE' ? (typeof compareFnconfigVal?.max === 'undefined' ? maxVal : compareFnconfigVal.max ) : Number(inoutTypeVal),
						min: comprFn === 'LE' ? (typeof compareFnconfigVal?.min === 'undefined' ? 0 : compareFnconfigVal.max ) : Number(inoutTypeVal)
					};
				}
				if (inputCond.typeVal.value === '') {
					apprCond.attribute_rule.operation_value = null;
				}
				const allApprs = getManagerApprVals(inputCond, 'count_level_cond');
				apprCond.all = allApprs.length ? allApprs : [];
				apprCond.priority = inputCond.priority;
				policyJson[key]['conditional_approval'].push(apprCond);
			}
		}
	}
	return policyJson;
};

/**
 * set approval value in policy json based on inpolicy / outpolicy dropdown value
 * @author mybizFe
 * @param { Object } approvalInput
 * @param { Object } policyJson
 * @param { String } key
 * @return { Object } returns inpolicy / outpolicy values for metadata of policy json
 */
const setApprovals = (approvalInput, policyJson, key) => {
	const ipVal = approvalInput.ip_val && approvalInput.ip_val.value ? approvalInput.ip_val.value.key : '';
	const opVal = approvalInput.oop_aprvl_ip && approvalInput.oop_aprvl_ip.value ? approvalInput.oop_aprvl_ip.value.key : '';
	const aprVal = ipVal + opVal;
	if (APPROVAL_CONFIG[aprVal]) {
		policyJson[key] = APPROVAL_CONFIG[aprVal];
	}
	return {
		[approvalInput.ip_val.name]: ipVal,
		[approvalInput.oop_aprvl_ip.name]: opVal
	};
};

/**
 * update metadata values for existing lobs other than user selected lob
 * @author mybizFe
 * @param { Object } policyData policy object of all groups and lobs
 * @param { Object } newPolicyData updated policy object of all groups sent in save api
 * @param { Object } saveApiResp response of save policy api
 * @return { Object } returns updated policy object merged with changes
 * new policy id and policy metadata to be updated in existing object
 * to be used for subsequent rendering
 */
export const formatSaveResp = (policyData, newPolicyData, saveApiResp) => {
	let updatedPolicyData = cloneDeep(policyData);
	let groupPolicyMap = saveApiResp.groupIdToPolicyIdResponseMap;
	let newPolicyJson = newPolicyData.createOrUpdatePolicyV2BulkRequest;

	newPolicyJson.map((newPolicy) => {
		let { groupId, policy, policyMetaData } = newPolicy;
		let oldPolicy = updatedPolicyData[groupId];
		policy.policy_id = groupPolicyMap[groupId];
		const lob = Object.keys(policy.policy_units)[0];
		const currPolicyMeta = oldPolicy.policyMetaData && oldPolicy.policyMetaData.policyUnitsMetaData;
		if (currPolicyMeta && policyMetaData) {
			for (let key in currPolicyMeta) {
				if (key !== lob) {
					policyMetaData.policyUnitsMetaData[key] = currPolicyMeta[key];
				}
			}
		}
		oldPolicy.policyMetaData = policyMetaData ? policyMetaData : oldPolicy.policyMetaData;
		if (oldPolicy && oldPolicy.policy) {
			if (oldPolicy.policy.policy_units) {
				for (let key in oldPolicy.policy.policy_units) {
					if (key !== lob) {
						policy.policy_units[key] = oldPolicy.policy.policy_units[key];
					}
				}
			}
			oldPolicy.policy = cloneDeep(policy);
		} else {
			updatedPolicyData[groupId] = { groupId, policy: cloneDeep(policy) };
		}
	});
	return updatedPolicyData;
};

/**
 * create and set input field values (conditions/managers) for conditional approvals based on policy or
 * create and updated dyamic conditional fields when add condition button clicked
 * @author mybizFe
 * @param { Object } inputFields rendered on UI
 * @param { Number } i updated policy object of all groups sent in save api (optional)
 * @param { Object|Boolean } condition object of individual codition from policy object (optional)
 */
export const udpateConditionalApprFields = (inputFields, i, condition = false) => {
	const key = typeof i === 'undefined' ? inputFields['conditional'].dynamicCount : i;
	inputFields['conditional'].dynamicCount++;
	inputFields[key + '_conditional'] = cloneDeep(inputFields['conditional']);
	const udpateField = inputFields[key + '_conditional'];
	let compareFn = false;
	if (condition && condition.attribute_rule && condition.attribute_rule.operation_value) {
		compareFn = condition.attribute_rule.operation_value.max === condition.attribute_rule.operation_value.min ? 'EQ' :
			(condition.attribute_rule.operation_value.max > condition.attribute_rule.operation_value.min &&
				condition.attribute_rule.operation_value.min == 0 ? 'LE' : 'GE');
	}

	udpateField.attribute_key.selectedValue = udpateField.attribute_key.value = condition && condition.attribute_rule ?
		(udpateField.attribute_key.options.filter((opt) => condition.attribute_rule.attribute_key == (opt.attrKey))[0]) :
		udpateField.attribute_key.options[1];
	udpateField['compareFn'].subFldAttkey = udpateField['attribute_key'].subFldAttkey = udpateField['count_level_cond'].subFldAttkey = `${key}_${udpateField['count_level_cond'].subFldAttkey}`;
	updateConditionalInputsConfig(inputFields, udpateField.attribute_key);
	udpateField.compareFn.selectedValue = udpateField.compareFn.value = condition && condition.attribute_rule ?
		(udpateField.compareFn.options.filter((opt) => compareFn == (opt.value))[0]) :
		udpateField.compareFn.options[2];

	udpateField.typeVal.value = condition && condition.attribute_rule && condition.attribute_rule.operation_value ?
		(compareFn === 'LE' ?
			condition.attribute_rule?.operation_value?.max : condition.attribute_rule?.operation_value?.min) :
		'';
	if (udpateField.all) {
		setManagerPolicyVals(condition, udpateField, 'count_level_cond');
	}
	udpateField.priority = key;
};

/**
 * remove condition and UI fields when delete button clicked and update priority of other conditions accordingly
 * @author mybizFe
 * @param { Object } inputFields rendered on UI
 * @param { Number } i index of condition to be removed
 */
export const deleteApprovalFields = (inputFields, i) => {
	const dynCount = inputFields['conditional'].dynamicCount;
	for (let key = i; i < dynCount - 1; i++) {
		let tmp = cloneDeep(inputFields[key + '_conditional']);
		inputFields[key + '_conditional'] = inputFields[key + 1 + '_conditional'];
		inputFields[key + '_conditional'].priority = key;
		inputFields[key + 1 + '_conditional'] = tmp;
	}
	delete (inputFields[dynCount + '_conditional']);
	inputFields['conditional'].dynamicCount--;
};

/**
 * Updates the conditional inputs/comparefn values configuration based on the provided formatted config group and input field.
 * @author mmt8270
 * @param {object} formattedConfigGroup - The formatted configuration group object.
 * @param {object} inputField - The input field object.
 */
export const updateConditionalInputsConfig = (formattedConfigGroup, inputField) => {
	const updateSubKey = inputField.subFldAttkey;
	const levelKey = inputField.key;
	const compareFn = formattedConfigGroup[updateSubKey].compareFn;
	if (inputField.value?.subFields && inputField.value.subFields.length > 0) {
		inputField.value.subFields.forEach((subField) => {
			const attrKey = subField.key;
			const toUpdateInput = formattedConfigGroup[updateSubKey][attrKey];
			if (toUpdateInput) {
				toUpdateInput.isDisabled = subField.isDisabled;
				toUpdateInput.hide = false;
				toUpdateInput.value = subField.value;
				if (subField.hide) {
					toUpdateInput.hide = subField.hide;
					// toUpdateInput.value = subField.value;
				} else if (toUpdateInput.type == 'dropdown') {
					toUpdateInput.options = subField.values.map((val) => {
						const retVal = {
							...val,
							value: val.attrValue,
							label: val.displayTxt,
							min: val.min,
							max: val.max
						};
						delete (retVal.attrValue);
						delete (retVal.displayTxt);
						return retVal;
					});
					toUpdateInput.value = toUpdateInput.options[0];
				}
			}
		});
	}
};

/**
 * update conditional approval/ inpolicy mgr/outpolicy mgr/ skip approval checkbox/ wallet appr options hide show on UI
 * when any of approval inputs are changed
 * @author mybizFe
 * @param { Object } formattedConfigGroup rendered on UI
 * @param { Number } input input field changed on UI
 * @return { Object } object with subfield values to be hidden
 */
export const updateApprovalFieldsOptions = (formattedConfigGroup, input) => {
	const temp = {};
	const walletVal = formattedConfigGroup['payment_options'] &&
										formattedConfigGroup['payment_options']['WALLET'].value &&
										formattedConfigGroup['payment_options']['WALLET'].value.value;
	if (formattedConfigGroup['wallet_enabled_for_other_booking']) {
		temp[formattedConfigGroup['wallet_enabled_for_other_booking'].subKey] = walletVal === 'NEVER_ALLOWED';
	}
	if (input && formattedConfigGroup['trf_enabled']) { // added for trf policy as no approval field present so to update manager count this is required
		temp[formattedConfigGroup['approval_policy']['count_level_in'].subKey] = false;
		const updateSubKey = input.subFldAttkey;
		const levelKey = input.key;
		updateSubKey && updateManagerFldShow(formattedConfigGroup[updateSubKey], levelKey);
	}
	if (input && input.key === 'auto_book_approval' &&
	(formattedConfigGroup['price_rise_threshold'] || formattedConfigGroup['autobook_add_on_failure'])) {
		if (input.isChecked) {
			temp['price_rise_per'] = false;
			temp['f_autobook_add_on_failure'] = false;
			formattedConfigGroup['price_rise_threshold']['value'] = formattedConfigGroup['price_rise_threshold'].defautlVal;
			if (formattedConfigGroup['autobook_add_on_failure']) {
				formattedConfigGroup['autobook_add_on_failure']['value'] = false;
			}
		} else {
			temp['price_rise_per'] = true;
			temp['f_autobook_add_on_failure'] = true;
		}
		return temp;
	}
	if (input && input.key === 'WALLET' || !formattedConfigGroup['approval']) {
		return temp;
	}
	const ipVal = formattedConfigGroup['approval']['ip_val'].value ?
		formattedConfigGroup['approval']['ip_val'].value.key : '';
	const opVal = formattedConfigGroup['approval']['oop_aprvl_ip'].value ?
		formattedConfigGroup['approval']['oop_aprvl_ip'].value.key : '';
	const skipblockAppr = formattedConfigGroup?.['approval']?.['block_skip_approval']?.isChecked;
	if ((!input || input.key === 'ip_val')) { // render and when ipval is changed
		formattedConfigGroup['approval']['oop_aprvl_ip'].options.forEach((opt) => { // control oop dropdown based on IP dropdown
			if (opt.key === 'ANR') {
				opt.hide = ipVal === 'AAR';
				if (opt.key === opVal && opt.hide) { // if hidden option is selected then update value same as IP dropdown option
					formattedConfigGroup['approval']['oop_aprvl_ip'].value = formattedConfigGroup['approval']['oop_aprvl_ip'].options.filter((op) => op.key === ipVal)[0];
				}
			}
		});
	}
	// show manager dropdowns only when approval required
	if (formattedConfigGroup['approval_policy']?.['count_level_in']?.subKey) {
		temp[formattedConfigGroup['approval_policy']['count_level_in'].subKey] = ipVal !== 'AAR';
	}
	if (formattedConfigGroup['approval_policy']?.['count_level_oo']?.subKey) {
		temp[formattedConfigGroup['approval_policy']['count_level_oo'].subKey] = opVal !== 'AAR';
	}

	if (input) { // when UI value changed
		const updateSubKey = input.subFldAttkey;
		const levelKey = input.key;
		updateSubKey && updateManagerFldShow(formattedConfigGroup[updateSubKey], levelKey);
	}
	if (formattedConfigGroup['approval']['block_skip_approval']) {
		formattedConfigGroup['approval']['block_skip_approval'].hide = formattedConfigGroup['approval']['block_skip_approval'].display === 'hide' || !(ipVal === 'AAR' || opVal === 'AAR');
	}
	if (formattedConfigGroup['approval']['block_skip_approval']) {
		formattedConfigGroup['approval']['block_skip_approval'].hide = formattedConfigGroup['approval']['block_skip_approval'].display === 'hide' || !(ipVal === 'AAR' || opVal === 'AAR');
	}

	if (formattedConfigGroup['conditional']) {
		formattedConfigGroup['add_cond_btn'].hide = formattedConfigGroup['add_cond_btn'].display === 'hide' || !(ipVal === 'AAR' || opVal === 'AAR');
		temp[formattedConfigGroup['conditional']['count_level_cond'].subKey] = !(ipVal === 'AAR' || opVal === 'AAR');
	}

	formattedConfigGroup?.['payment_options']?.['WALLET']?.options.forEach((opt) => {
		switch (opt.value) {
			case 'ALLOWED_ALWAYS':
				opt.hide = ipVal === 'AAR' && !skipblockAppr;
				break;
			case 'ALLOWED_WITHIN_POLICY':
				opt.hide = opVal === 'NA';
				break;
			case 'ALLOWED_WHEN_APPROVED':
				opt.hide = ipVal !== 'AAR';
				break;
			case 'ALLOWED_OOP_APPROVED':
				opt.hide = !(opVal === 'AAR' && skipblockAppr);
				break;
			default:
				opt.hide = false;
				break;
		}
		if (walletVal === opt.value && opt.hide) { // if hidden value is selected then empty the wallet value
			formattedConfigGroup['payment_options']['WALLET'].value = '';
		}
	});
	return temp;
};

/**
 * update manager fields selected value based on policy vals or set manager 1 if no policy val is present.
 * create manager field based repeatcount for the field in config
 * @author mmt8270
 * @param { Object } rules - approval_policy rule object from policy
 * @param { Object } fields - approval_policy fields object from formattedcofig
 * @param { String } countFldkey level manager dropdown key to select which(inpolicy/oopolicy/condmgrs..) manager fields to be updated
 */
const setManagerPolicyVals = (rules = {}, fields, countFldkey) => {
	const maxLevels = fields[countFldkey].options.length;
	const mgrKey = fields[countFldkey].countKey;
	const apprMgrs = rules.all;
	fields[countFldkey].value = apprMgrs && apprMgrs.length > 0 ?
		fields[countFldkey].options.filter((opt) => parseInt(opt.value) === apprMgrs.length)[0] :
		(fields[countFldkey].value || fields[countFldkey].options[0]);
	createMgrFields(fields, fields[countFldkey].value.value, mgrKey);
	for (let i = 1; i <= maxLevels; i++) {
		const mgrFld = fields[`${mgrKey}_${i}`];
		let mgrRuleVal = apprMgrs?.[i - 1];
		if (mgrRuleVal && !isNaN(parseInt(mgrRuleVal))) {
			mgrRuleVal = mgrFld && mgrFld.data[0].list.filter((opt) => parseInt(mgrRuleVal) === parseInt(opt.value))[0];
		} else if (mgrRuleVal && typeof mgrRuleVal == 'string') {
			mgrRuleVal = {
				label: mgrRuleVal,
				value: mgrRuleVal
			};
		}
		mgrFld.selectedValue = mgrFld.value = mgrRuleVal || mgrFld.value;
	}
};

/**
 * update which manger fields to show/hide based on number of levels selected.
 * @author mmt8270
 * @param { Object } fields - approval_policy fields object from formattedcofig
 * @param { countLevel } countLevel - key to select which(inpolicy/oopolicy/condmgrs..) manager fields to be updated
 */
const updateManagerFldShow = (fields, countLevel = 'count_level_in') => {
	const maxLevels = fields[countLevel].options.length;
	const showNo = fields[countLevel].value.value;
	const mgrKey = fields[countLevel].countKey;
	for (let i = 1; i <= maxLevels; i++) {
		const mgrFld = fields[`${mgrKey}_${i}`];
		if (mgrFld) {
			mgrFld.hide = i > showNo;
		}
	}
};

/**
 * get manager apprroval array based on user selection
 * @author mmt8270
 * @param { Object } fields - approval_policy fields object from formattedcofig
 * @param { String } countLevel - key to select which(inpolicy/oopolicy/condmgrs..) manager fields to be updated
 * @return { Array } array of strings (email/mgr level number)
 */
const getManagerApprVals = (fields, countLevel = 'count_level_in') => {
	const showNo = parseInt(fields[countLevel].value.value);
	const mgrKey = fields[countLevel].countKey;
	const apprVals = [];
	for (let i = 1; i <= showNo; i++) {
		const mgrFld = fields[`${mgrKey}_${i}`];
		let val = mgrFld && mgrFld.value && mgrFld.value.value ? mgrFld.value.value : '1';
		try {
			if (!isNaN(val)) {
				val = parseInt(val);
			}
		} catch (e) {
			saveServerLogs(e.stack, 'error', 'getManagerApprVals');
		}
		apprVals.push(val);
	}
	return apprVals;
};

/**
 * create all manager fields to be rendered on UI.
 * @author mmt8270
 * @param { Object } fields - approval_policy fields object from formattedcofig
 * @param { Number } showNo - number of fields to be shown on UI
 * @param { String } mgrKey - policy appr key to be used
 */
export const createMgrFields = (fields, showNo, mgrKey = 'approving_managers_in_policy') => {
	if (!fields) {
		return;
	}
	const maxLevels = fields[mgrKey].repeatCount && fields[mgrKey].dynamic ? fields[mgrKey].repeatCount : 0;
	fields[mgrKey].data[0].list.forEach((opt) => {
		opt.iconText = opt.value;
	});
	for (let i = 1; i <= maxLevels; i++) {
		const defMgrFld = cloneDeep(fields[mgrKey]);
		defMgrFld.value = defMgrFld.data[0].list[0];
		defMgrFld.value.tabIndex = 0;
		defMgrFld.selectedValue = defMgrFld.value;
		defMgrFld.key = defMgrFld.name = defMgrFld.attrKey = `${mgrKey}_${i}`;
		defMgrFld.fkey = `${defMgrFld.fkey}_${i}`;
		defMgrFld.defaultPrefix = defMgrFld.prefix = `Level ${i} by:`;
		if (defMgrFld.iconToolTipData && defMgrFld.iconToolTipData.content) {
			defMgrFld.iconToolTipData.content = `<h5>Approval Level ${i}:</h5>${defMgrFld.iconToolTipData.content}`;
		}
		fields[`${mgrKey}_${i}`] = defMgrFld;
		fields[`${mgrKey}_${i}`].hide = i > showNo;
	}
	if (maxLevels) {
		// delete (fields[mgrKey]);
	}
};

export const logOmniture = (fkey, selectedValue, lob, groupIndex, groupCount, checkBoxValues) => {
	let omnitureKey = fkey_map_omniture[fkey];
	let omni = '';
	let values = '';
	let selectedState = '';
	switch (fkey) {
		case 'add_tier':
		case 'add_conditional_approval':
			omni = `Policy_${lob}_${groupIndex}_${groupCount}_${omnitureKey}_${values}_clicked`;
			break;
		case 'block':
		case 'emp_elg_ip':
			values = selectedValue.value === 'true' ? 'Booking Allowed' :
				selectedValue.value === 'hide' ? 'Hide' : 'Not allowed';
			omni = `Policy_${lob}_${groupIndex}_${groupCount}_${omnitureKey}_${values}_selected`;
			break;
		case 'tier_city':
			values = '';
			for (let indx in selectedValue) {
				let v1 = selectedValue[indx];
				values = values + v1.label + '_';
			}
			omni = `Policy_${lob}_${groupIndex}_${groupCount}_${omnitureKey}_${values}selected`;
			break;
		case 'f_type_ip':
		case 'budget_pay':
			values = '';
			for (let indx in selectedValue) {
				let v1 = selectedValue[indx];
				values = values + v1.value + '_';
			}
			omni = `Policy_${lob}_${groupIndex}_${groupCount}_${omnitureKey}_${values}selected`;
			break;
		// case "cmft_conv": //??
		case 'in_pol_def_mgr_ip':
		case 'oop_def_mgr_ip':
		case 'display':
			values = selectedValue.label;
			omni = `Policy_${lob}_${groupIndex}_${groupCount}_${omnitureKey}_${values}_selected`;
			break;
		case 'in_pol_aprvl_ip':
		case 'oop_aprvl_ip':
		case 'wlt_optns_ip':

		case 'cond_aprvl_attr_ip':
		case 'cond_aprvl_range_ip':
		case 'cond_aprvl_mgr_ip':
			values = selectedValue.value;
			omni = `Policy_${lob}_${groupIndex}_${groupCount}_${omnitureKey}_${values}_selected`;
			break;
		case 'cond_aprvl_val_ip':
		case 'cab_price_ip':
		case 'flt_price_ip':
		case 'bus_price_ip':
		case 'htl_price':
		case 'tier_price':
			omni = `Policy_${lob}_${groupIndex}_${groupCount}_${omnitureKey}_${selectedValue}_entered`;
			break;
		case 'cab_class_ip':
		case 'cabin_ip':
			for (let val in selectedValue) {
				if (selectedValue[val]) {
					values = values + val + '_';
				}
			}
			omni = `Policy_${lob}_${groupIndex}_${groupCount}_${omnitureKey}_${values}selected`;
			break;
		case 'skp_aprvl_1_ip':
		case 'a_meal':
		case 'a_cab':
		case 'a_seats':
		case 'a_bag':
			selectedState = selectedValue ? 'selected' : 'unselected';
			values = checkBoxValues[0].displayTxt;
			omni = `Policy_${lob}_${groupIndex}_${groupCount}_${omnitureKey}_${values}_${selectedState}`;
			break;
		default:
			break;
	}
	omnitureLogging(omni);
};

export const omnitureLogging = (eventName) => {
	let pageName = 'mybusinessadmin:policies:landing_new_v3|corporate';
	logOmniClick(eventName, {
		prop54: eventName,
		pageName: pageName
	});
};

/**
 * not used
 */
const updateManagerList = (options, managerData) => {
	options.forEach((opt) => {
		const count = managerData.filter((count) => String(count.managerLevel) === opt.value);
		opt.count = count.length > 0 && count[0].employeeCount;
		opt.iconText = opt.value;
		if (opt.count && opt.count > 0) {
			opt.subText = `(Not set for ${opt.count} employees)`;
			opt.isDisabled = opt.value !== '1';
		}
	});
	return options;
};

/**
 * not used
 */
export const updateManagerCounts = (inputFields, managerData) => {
	if (!managerData || !inputFields) {
		return inputFields;
	}
	if (inputFields && inputFields['approval_policy']) {
		inputFields['approval_policy'].approving_managers_in_policy_1.selectedValue = inputFields['approval_policy'].approving_managers_in_policy_1.value = inputFields['approval_policy'].approving_managers_in_policy_1.value || (inputFields['approval_policy'].approving_managers_in_policy_1.options && inputFields['approval_policy'].approving_managers_in_policy_1.data[0].list[0]);
		inputFields['approval_policy'].approving_managers_oo_policy.selectedValue = inputFields['approval_policy'].approving_managers_oo_policy.value = inputFields['approval_policy'].approving_managers_oo_policy.value || (inputFields['approval_policy'].approving_managers_oo_policy.options && inputFields['approval_policy'].approving_managers_oo_policy.options[0]);
	}
	if (inputFields['conditional']) {
		inputFields['conditional'].all.selectedValue = inputFields['conditional'].all.value = inputFields['conditional'].all.options[0];
	}
	try {
		if (inputFields['conditional']) {
			const condCount = inputFields['conditional'].dynamicCount;
			inputFields['conditional'].all.options = updateManagerList(inputFields['conditional'].all.options, managerData);
			for (let key = 0; key < condCount; key++) {
				inputFields[key + '_conditional'].all.options = updateManagerList(inputFields[key + '_conditional'].all.options, managerData);
			}
		}
		if (inputFields['approval_policy']) {
			inputFields['approval_policy'].approving_managers_in_policy_1.options = updateManagerList(inputFields['approval_policy'].approving_managers_in_policy_1.data[0].list, managerData);
			inputFields['approval_policy'].approving_managers_oo_policy.options = updateManagerList(inputFields['approval_policy'].approving_managers_oo_policy.options, managerData);
		}
		return inputFields;
	} catch (error) {
		saveServerLogs(error && error.stack ? error.stack : error, 'error');
		return inputFields;
	}
};

/**
 * create tootip table data for different fares
 * @author mmt8270
 * @param {Number} price
 * @param {Number} val
 * @param {Object} policyConfigMetaData
 * @return {Object} tooltipdata
 */
export const getHotelMultiplierData = (price = 5000, val, policyConfigMetaData = {}) => {
	const { currency = '' } = policyConfigMetaData;
	if (!val) {
		return {
			type: 'hotelpolicy',
			footer: multiplierTxts.noValFooter
		};
	}
	const header = { list: [{ text: 'PAX' }, { text: 'Budget/night (x=1)' }, { text: `Budget/night (x=${val})` }, { text: 'Savings/night' }] };
	const list = [];
	for (let i = 1; i < 6; i++) {
		let fatorPrice = price * (1 + (i - 1) * val);
		let savings = price * i - fatorPrice;
		try {
			fatorPrice = fatorPrice.toFixed(2);
			savings = savings.toFixed(2);
		} catch (error) {
		}
		list.push({
			'pax': i,
			'x1': `${currency} ${price * i}`,
			'xval': `${currency} ${fatorPrice}`,
			'savings': `${currency} ${savings}`
		});
	}
	const heading = `Your optimized budget with a factor of  <b>${val}</b> if maximum elligible amount per person is <b>${currency} ${price} per night</b>.`;
	return {
		tableData: { header, list },
		heading,
		type: 'hotelpolicy',
		footer: multiplierTxts.footer
	};
};

/**
 * create tootip table data for different cab fares
 * @author mmt8270
 * @param {Array} data
 * @return {Object} {header, list}
 */
export const getCabTypeTableData = (data = []) => {
	const header = (() => {
		const list = [];
		const head = data[0];
		const headerKeys = head && Object.keys(head);

		for (let indx = 0; headerKeys && indx < 5; indx++) {
			list.push({ text: headerKeys[indx] });
		}
		return { list };
	})();
	const list = data.map((type) => {
		const { Group, Models, AC } = type;
		return {
			Group,
			'Cab Type': type['Cab Type'],
			Models,
			'Fuel Type': type['Fuel Type'],
			AC
		};
	});
	return {
		header, list
	};
};

/**
 * get and set personal booking policy/ requisition service deatils in input fields
 * @author mmt8270
 * @param { Object } toSet - input fields or policy rule where data to be set
 * @param { Object } datatoRead - policy values/ input field to read for personal booking
 * @param { Boolean } setPolicy - if policy read or set while saving
 */
export const setmultipleCheckboxFields = (toSet, datatoRead, setPolicy) => {
	if (datatoRead && toSet) {
		for (let key in datatoRead) {
			if (setPolicy) {
				toSet[key] = datatoRead[key].isChecked && !datatoRead[key].hide;
			} else if (toSet[key]) {
				toSet[key].isChecked = datatoRead[key];
			}
		}
	}
};

/**
 * set hide input fields and subfields on change of req inputs
 * @author mmt8270
 * @param { Object } formattedConfigGroup - rendered on UI
 * @return { Object } hide subfield object keys and input config with hide attributes
 */
export const updateHideRequisitionFields = (formattedConfigGroup) => {
	let temp = {};
	const bookCmpInp = formattedConfigGroup['booking_completion'];
	const travInput = formattedConfigGroup['travel_option'];
	const serviceInputs = formattedConfigGroup['requisition_services'];
	const personalBook = formattedConfigGroup['personal_booking_policy'];
	const bookCmpvalue = bookCmpInp?.value?.value;
	const travCmpvalue = travInput?.value?.value;
	// const isPreApproval = travCmpvalue === 'PRE_APPROVAL';
	try {
		// serviceInputs.CAB.hide = isPreApproval;
		// iterate and check for all service inputs to hide if value is "PRE"
		for (let key in serviceInputs) {
			const values = serviceInputs[key].options[0].value;
			serviceInputs[key].hide = values.length > 1 && !values.includes(travCmpvalue);
		}
	} catch (error) {

	}
	// let temp = { 'req_hide': false, 'travel_option': false };
	const empopt = bookCmpInp?.options ? (bookCmpInp.options.filter((opt) => opt.value === 'TRAVELLER')[0] || {}) : {};
	empopt.hide = false;
	if (travCmpvalue === 'POST_APPROVAL') {
		temp['personal_book'] = true;
		if (personalBook) {
			personalBook['allowed']['isChecked'] = false;
		}
	}
	if (travCmpvalue === 'PRE_APPROVAL') {
		if (bookCmpvalue === 'TRAVELLER') {
			// temp = { 'req_hide': true, 'travel_option': true };
		}
		temp['personal_book'] = false;
	} else {
		empopt.hide = true;
		bookCmpInp.value = bookCmpInp.options.filter((opt) => opt.value === 'TRAVEL_PLANNER')[0];
	}
	return { formattedConfigGroup, temp };
};

/**
 * set cheaper threshold field
 * @author mmt8270
 * @param { Object } formattedConfigGroup - rendered on UI
 * @return { Object } hide subfield object keys and input config with hide attributes
 */
export const upateCheaperFlightFld = (formattedConfigGroup = {}) => {
	const fld = formattedConfigGroup.cheaper_flights?.allowed;
	const thresHldFld = formattedConfigGroup.cheaper_flights?.threshold;
	thresHldFld.isDisabled = fld?.value?.value !== POLICY_CONSTANTS.CHEAPER_VALS.CHEAPER_WITH_DELTA;
	return { formattedConfigGroup };
};

/**
 * set cheaper threshold field
 * @author mmt8270
 * @param { Object } cheaperInput - cheaper flight input fields rendered
 * @param { Object } cheaperVal - cheaper flight default or master policyval
 * @param { Object } masterPolicyVal - masterpolicyValue to map from
 * @param { Boolean } isDefault - if default policy is being changed by user
 * @param { Object } sectionKeys - keys to be set as default policy
 * @return { Object } hide subfield object keys and input config with hide attributes
 */
const upateCheaperFlightPolicy = (cheaperInput, cheaperVal, masterPolicyVal, isDefault, sectionKeys) => {
	const policyVal = cheaperVal;

	for (let attr in masterPolicyVal) {
		const input = cheaperInput[attr];
		if (input) {
			if (!isDefault && sectionKeys.includes(input.sectionKey)) {
				policyVal[attr] = cheaperVal[attr];
			} else {
				let outputVal = input.value?.value || input.value;
				if (outputVal) {
					policyVal[attr] = outputVal;
				}
			}
		}
	}
	return policyVal;
};

/**
 * set Modification lobs based on Requisition lobs
 * @author mmt9855
 * @param { Object } reqLobs - selected requisition lobs
 * @return { Array } get all checked lobs for modification
 */
const getModificationLobs = (reqLobs = {})=>{
	const lobs = [];
	for (let key in reqLobs) {
		if (reqLobs[key].isChecked) {
			lobs.push(reqLobs[key].value);
		}
	}
	return lobs;
};

/**
 * check for available keys in array
 * @author mmt9855
 * @param { Object } rule_key - key for checking
 * @param { Array } arr - array of keys
 * @return { boolean } true if key exits otherwise, false
 */
export const checkForKeysInArray = (rule_key, arr = [])=>arr.some((attrKeys)=>rule_key.includes(attrKeys));

/**
 * Utility function to create an object within an existing object.
 * @param {Object} obj - The existing object.
 * @param {String} path - The path to the property in dot notation.
 * @param {*} value - The value to set at the specified path.
 */
export const setNestedObjectValue = (obj = {}, path, value) => {
	if (!path) {
		return obj;
	}
	const keys = path.split('.');
	let current = obj;

	for (let i = 0; i < keys.length - 1; i++) {
		if (!current[keys[i]]) {
			current[keys[i]] = {};
		}
		current = current[keys[i]];
	}

	current[keys[keys.length - 1]] = value;
	current = current[keys[keys.length - 1]];
	return current;
};

/**
 * @method updateSharedCabsLinkedAttributes
 *
 * @author MMT11933
 * @description - Updates the 'disable' attribute of specified keys in the formatted organization configuration.
 * @param {Object} formattedOrgConfig - The organization configuration object.
 * @param {Array<string>} keysToUpdate - An array of keys whose 'disable' attribute needs to be updated.
 * @param {boolean} disable - The value to set for the 'disable' attribute.
 * @return {Object} The updated organization configuration object.
 */
export const updateSharedCabsLinkedAttributes = (formattedOrgConfig, keysToUpdate, disable) => {
	keysToUpdate.forEach((key) => {
		if (result(formattedOrgConfig, key)) {
			update(formattedOrgConfig, `${key}.disable`, () => disable);
		}
	});

	return formattedOrgConfig;
};

/**
 * @method checkDisabledSubField
 *
 * @author MMT11933
 * @description - Checks if a subfield is disabled based on the provided configuration.
 * @param {Object} formattedConfigGroup - The configuration group object.
 * @param {Object} subField - The subfield object containing the search key.
 * @param {Array} SHARED_CABS_CONFIG.SUBFIELD_DISABLE - Array of subfield keys that are disabled.
 * @return {boolean} - Returns true if the subfield is disabled, otherwise false.
 */
export const checkDisabledSubField = (formattedConfigGroup, subField, subFieldsToCheck) => {
	let isDisabled = get(formattedConfigGroup, subField?.searchKey)?.disable ?? false;
	if (isDisabled === false && !get(formattedConfigGroup, subField?.searchKey)?.hasOwnProperty('disable')) {
		isDisabled = subFieldsToCheck.includes(subField.searchKey);
	}
	return isDisabled;
};
