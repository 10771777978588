import { autoSuggestApi } from 'reduxStore/actions/policyActionDispatcher';

export const APPROVAL_CONFIG = {
	'ANRNA': 'NO_APPROVAL_REQUIRED',
	'ANRANR': 'NO_APPROVAL_REQUIRED',
	'ANRAAR': 'NO_APPROVAL_REQUIRED_WITHIN_POLICY',
	'AARNA': 'APPROVAL_ALWAYS_REQUIRED',
	'AARAAR': 'APPROVAL_ALWAYS_REQUIRED',
	'AAR': 'APPROVAL_ALWAYS_REQUIRED',
	'ANR': 'NO_APPROVAL_REQUIRED'
};

export const searchType = {
	city: 'city',
	country: 'country',
	hotel: 'hotel',
	state: 'state'
};

export const apiFormat = {
	[searchType.city]: {
		label: 'cityName',
		value: 'cityCode'
	},
	[searchType.country]: {
		label: 'countryName',
		value: 'countryCode'
	},
	[searchType.hotel]: {
		label: 'displayName',
		value: 'id'
	},
	[searchType.state]: {
		label: 'name',
		value: 'id'
	}
};

export const lob_icon_map = {
	DOM_FLIGHTS: {
		icon: 'flightIcon',
		disabledClass: 'flightGrayIcon',
		policyKey: 'DOM_FLIGHT',
		blockLob: 'FLIGHTS',
		apiKey: 'dom_flight'
	},
	DOM_HOTELS: { icon: 'hotelIcon',
		disabledClass: 'hotelGrayIcon',
		policyKey: 'HOTEL',
		blockLob: 'HOTEL',
		apiKey: 'dom_htl',
		searchType: searchType.city,
		tierMapKey: 'dom_mapping' },
	AIRPORT_CABS: {
		icon: 'cabIcon',
		disabledClass: 'cabGrayIcon',
		policyKey: 'AIRPORT_CABS',
		blockLob: 'AIRPORT_CABS',
		isInrOnly: true,
		apiKey: 'airport_cab'
	},
	OUTSTATION_CABS: {
		icon: 'cabIcon',
		disabledClass: 'cabGrayIcon',
		policyKey: 'OUTSTATION_CABS',
		blockLob: 'OUTSTATION_CABS',
		isInrOnly: true,
		apiKey: 'outstation_cab'
	},
	BUS: {
		icon: 'busIcon',
		disabledClass: 'busGrayIcon',
		policyKey: 'BUS',
		blockLob: 'BUS',
		isInrOnly: true,
		apiKey: 'bus'
	},
	INTL_FLIGHTS: {
		icon: 'flightIcon',
		disabledClass: 'flightGrayIcon',
		policyKey: 'INTL_FLIGHT',
		blockLob: 'INTL_FLIGHTS',
		apiKey: 'intl_flight'
	},
	INTL_HOTELS: {
		icon: 'hotelIcon',
		disabledClass: 'hotelGrayIcon',
		policyKey: 'INTL_HOTEL',
		blockLob: 'INTL_HOTEL',
		apiKey: 'intl_htl',
		searchType: searchType.country,
		tierMapKey: 'intl_mapping'
	},
	TRAIN: {
		icon: 'trainIcon',
		disabledClass: 'trainGrayIcon',
		policyKey: 'TRAIN',
		blockLob: 'TRAIN',
		apiKey: 'dom_flight'
	},
	RENTAL_CABS: {
		icon: 'cabIcon',
		disabledClass: 'cabGrayIcon',
		policyKey: 'RENTAL_CABS',
		blockLob: 'RENTAL_CABS',
		isInrOnly: true,
		apiKey: 'rental_cab'
	},
	REQUISITION: {
		icon: 'reportActiveIcon',
		disabledClass: 'reportIcon',
		policyKey: 'REQUISITION',
		blockLob: 'REQUISITION',
		apiKey: 'requisition'
	},
	GIFT_CARDS: {
		icon: 'giftCardIcon',
		disabledClass: 'giftCardGrayIcon',
		policyKey: 'GIFT_CARDS',
		blockLob: 'GIFT_CARDS',
		apiKey: 'gift_cards'
	},
	MICE: {
		icon: 'miceIcon',
		disabledClass: 'miceGrayIcon',
		policyKey: 'MICE',
		blockLob: 'MICE',
		apiKey: 'mice'
	}
};

export const fkey_map_omniture = {
	'emp_elg_ip': 'EmployeeEligibility',
	'block': 'EmployeeEligibility',
	'display': 'CheaperFare',
	'f_type_ip': 'fare_type',
	'in_pol_aprvl_ip': 'approval_IP',
	'oop_aprvl_ip': 'approval_OOP',
	'wlt_optns_ip': 'Wallet',
	'in_pol_def_mgr_ip': 'manager_IP',
	'oop_def_mgr_ip': 'manager_OOP',
	'cond_aprvl_attr_ip': 'Conditional',
	'cond_aprvl_range_ip': 'Conditional',
	'cond_aprvl_val_ip': 'Conditional',
	'cond_aprvl_mgr_ip': 'Conditional',
	'skp_aprvl_1_ip': 'skipapproval',
	'tier_city': 'Tier_n_city',
	'tier_price': 'Tier_n_budget',
	'budget_pay': 'Budget',
	'flight_price': 'Budget',
	'cab_price_ip': 'Budget',
	'htl_price': 'Budget',
	'flt_price_ip': 'Budget',
	'bus_price_ip': 'Budget',
	'cab_class_ip': 'cabClass',
	'cabin_ip': 'CabinType',
	'a_cab': 'Ancillary',
	'a_seats': 'Ancillary',
	'a_meal': 'Ancillary',
	'a_bag': 'Ancillary',
	'add_tier': 'Tier',
	'add_conditional_approval': 'Conditional'
};

export const CONSTANTS = {
	ORG_ID_URL_PARAM: 'orgId',
	ERROR: 'We are facing some technical issue!',
	DETAIL_TYPE: 'policy'
};

export const mandatoryFields = [
	'approval', 'payment_options'
];

export const LOB_CONSTANTS = {
	DOM_HOTELS: 'DOM_HOTELS',
	INTL_HOTELS: 'INTL_HOTELS',
	DOM_FLIGHTS: 'DOM_FLIGHTS',
	INTL_FLIGHTS: 'INTL_FLIGHTS',
	AIRPORT_CABS: 'AIRPORT_CABS',
	OUTSTATION_CABS: 'OUTSTATION_CABS',
	TRAIN: 'TRAIN',
	RENTAL_CABS: 'RENTAL_CABS',
	REQUISITION: 'REQUISITION',
	MICE: 'MICE',
	GIFT_CARDS: 'GIFT_CARDS'
};

export const PLATFORMS = [
	'ANDROID', 'IOS', 'DESKTOP'
];

export const APPROVAL_KEY_CONFIG = {
	'APPROVAL_ALWAYS_REQUIRED': 'AAR',
	'NO_APPROVAL_REQUIRED': 'ANR',
	'NOT_ALLOWED': 'NA'
};

export const multiplierTxts = {
	footer: 'Setting up a multiplying factor less than 1 helps you optimize your organization’s hotel budget when there are more than one employees travelling!',
	noValFooter: 'Type in a number greater than 0 and less than equal to 1 to see how your organization’s hotel budget gets optimized when there are more than one employee travelling!'
};

export const toolTipConfig = {
	width: '610px',
	posTop: '-20px',
	posLeft: '16px',
	left: '75%',
	transform: 'translate(-75%,-100%)',
	bg_color: '#fff',
	color: '#000',
	zIndex: '2',
	padding: '0',
	boxShadow: '0 3px 10px 0 rgb(0 0 0 / 10%)'
};

export const autoSuggestConfigs = {
	[lob_icon_map['DOM_HOTELS'].tierMapKey]: async (val, defaultOptions)=>await autoSuggestApi({ q: val, t: searchType.city }, apiFormat[searchType.city], defaultOptions),
	[lob_icon_map['INTL_HOTELS'].tierMapKey]: async (val, defaultOptions)=>await autoSuggestApi({ q: val, t: searchType.country }, apiFormat[searchType.country], defaultOptions),
	'state': async (val, defaultOptions)=>await autoSuggestApi({ q: val, t: searchType.state }, apiFormat[searchType.state], defaultOptions),
	'hotel': async (val, defaultOptions)=>await autoSuggestApi({ q: val, t: searchType.hotel }, apiFormat[searchType.hotel], defaultOptions)
};

export const SHARED_CABS_CONFIG = {
	SUBFIELD_DISABLE: ['linked_attributes.0_class_price', 'linked_attributes.1_class_price', 'linked_attributes.2_class_price', 'linked_attributes.3_class_price']
};
