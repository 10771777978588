import {
	getFormattedDate,
	getShortMonth,
	getDaysDiff,
	getDefaultDate,
	getDateFilterKeys,
	getDateText,
	formatCurrency
} from './Util';
import {
	textsConfig,
	dateConfig,
	FILTERS_COUNTS_CONFIG,
	FILTERS_VALUES,
	defaultCountConfig
} from '../wallet/config';
import {
	WALLET_CONSTANTS,
	APPLICATION_CONSTANTS,
	FILTER_CONSTANTS
} from '../AppConstants';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import {
	logOmniClick
} from '../logging/omnitureService';
import { isreportsPage } from './reports/reportSummaryUtil';

/**
 * set filter start date as per reports and wallet page
 * @return {Integer}
 */
const getFilterStartDate = () => isreportsPage() ? false : WALLET_CONSTANTS.DEFAULT_MONTHS;

/**
 * params {}.
 * returns object for invoice list query
 */
export const getDefaultParams = () => {
	const month = getFilterStartDate();
	const dateObj = getDefaultDate(APPLICATION_CONSTANTS.TODAY, WALLET_CONSTANTS.API_DATE_FORMAT, month);
	const paramsObj = {
		'pageLimit': WALLET_CONSTANTS.PAGE_LIMIT * 4,
		'sort': {
			[WALLET_CONSTANTS.SORT_KEY]: WALLET_CONSTANTS.DESCENDING,
			lastTxnDate: null
		}
	};
	return {
		...paramsObj,
		...dateObj,
		fltTxt: isreportsPage() ? APPLICATION_CONSTANTS.LAST_QUARTER_TILL_DATE : APPLICATION_CONSTANTS.LAST_MONTH_TILL_DATE
	};
};

/**
 * params : quarter start month
 * returns formatted quarter filter object quarter value
 */
const getMonthFilter = (m) => {
	let year = APPLICATION_CONSTANTS.TODAY.getFullYear();
	const month = APPLICATION_CONSTANTS.TODAY.getMonth();
	if (month < m) {
		year--;
	}
	const text = `${getShortMonth(m)} ${year}`;
	textsConfig[m] = text;
	const start = getFormattedDate(new Date(year, m, 1), WALLET_CONSTANTS.API_DATE_FORMAT);
	const end = getFormattedDate(new Date(year, m + 1, 0), WALLET_CONSTANTS.API_DATE_FORMAT);
	let trackText = 'currentmonth';
	let monthTxt = 'This Month';
	if (m === month - 1) {
		trackText = 'previousmonth';
		monthTxt = 'Last Month';
	}
	if (m === month - 2) {
		trackText = 'previousmonth2';
		monthTxt = text;
	}
	dateConfig[m] = {
		startDate: start,
		endDate: end
	};
	return {
		type: m,
		label: text,
		value: m,
		checked: false,
		start: start,
		end: end,
		key: FILTER_CONSTANTS.DATE_FILTER,
		filterKey: FILTER_CONSTANTS.DATE_FILTER,
		trackVal: 'date_' + trackText,
		countKey: m,
		monthTxt
	};
};

/**
 * params : quarter start month
 * returns formatted quarter filter object quarter value
 */
const getQtrFilter = (qtrStart) => {
	const currDate = APPLICATION_CONSTANTS.TODAY;
	const currMonth = currDate.getMonth();
	const year = currMonth > qtrStart ? currDate.getFullYear() : currDate.getFullYear() - 1;
	const start = getFormattedDate(new Date(year, qtrStart, 1), WALLET_CONSTANTS.API_DATE_FORMAT);
	const end = getFormattedDate(new Date(year, qtrStart + APPLICATION_CONSTANTS.QUARTER, 0), WALLET_CONSTANTS.API_DATE_FORMAT);
	const text = `${getDateText(start, end, true)}`;
	textsConfig[FILTER_CONSTANTS.QTR_FILTER] = text;
	dateConfig[FILTER_CONSTANTS.QTR_FILTER] = {
		startDate: start,
		endDate: end
	};
	return {
		type: FILTER_CONSTANTS.QTR_FILTER,
		label: text,
		start: start,
		key: FILTER_CONSTANTS.DATE_FILTER,
		end: end,
		countKey: WALLET_CONSTANTS.QTR_COUNT,
		value: WALLET_CONSTANTS.QTR_COUNT,
		checked: false,
		filterKey: FILTER_CONSTANTS.DATE_FILTER,
		trackVal: 'date_lastquarter',
		monthTxt: 'Last Quarter'
	};
};

/**
 * params : datefilter object, start date, end date
 * returns formatted date filter object with months and quarter value
 */
const getDateFilterObj = (dateFilter, from, to) => {
	const month = getDateFilterKeys(APPLICATION_CONSTANTS.TODAY);
	const monthLength = month.length;
	const filterObj = month.slice(0, monthLength - APPLICATION_CONSTANTS.QUARTER).map((m, i) => {
		const monthFlt = getMonthFilter(m);
		monthFlt.index = i;
		return monthFlt;
	});
	dateFilter[0].countKey = FILTER_CONSTANTS.CUSTOM_DATE_FILTER;
	textsConfig[FILTER_CONSTANTS.CUSTOM_DATE_FILTER] = getDateText(from, to, true);
	dateConfig[FILTER_CONSTANTS.CUSTOM_DATE_FILTER] = {
		startDate: from,
		endDate: to
	};
	const qtrFilter = getQtrFilter(month[monthLength - 1]);
	qtrFilter.index = filterObj.length;
	filterObj.push(qtrFilter);
	dateFilter[0].index = filterObj.length;
	dateFilter[0].monthTxt = 'Selected date';
	filterObj.push(dateFilter[0]);
	return filterObj;
};

/**
 * params : filter object
 * returns filter with options set from url query
 */
export const presetFilters = (filters, appliedFilters) => {
	const query = getDefaultDate(APPLICATION_CONSTANTS.TODAY, WALLET_CONSTANTS.API_DATE_FORMAT, WALLET_CONSTANTS.DEFAULT_MONTHS);
	filters.dateRange.options = getDateFilterObj(filters.dateRange.options, query.startDate, query.endDate);
	return {
		filters: filters,
		appliedFilters: appliedFilters
	};
};

// track wallet Events
export const trackWalletEvents = (eventType) => {
	logOmniClick(WALLET_CONSTANTS.LINK_NAME, {
		prop54: eventType
	});
};

/**
 * params : list api response
 * format invoice data to be rendered on UI
 */
export const formatListData = (data, isAllSelected) => {
	const apiList = data && data.walletTxns;
	const selectedList = {};
	if (!apiList || apiList.length === 0) {
		return {
			list: [],
			lastIndex: false,
			selectedList: selectedList
		};
	}
	const listArr = apiList.map((transaction) => {
		const transObj = {
			id: transaction.id,
			bookId: transaction.bookingId,
			emailId: transaction.emailId || 'N-A',
			lob: transaction.lob || 'default',
			type: transaction.type,
			txnDate: getFormattedDate(transaction.txnDate, WALLET_CONSTANTS.BOOK_DATE_FORMAT),
			txnAmount: transaction.txnAmount,
			reason: transaction.reason
		};
		if (isAllSelected) {
			selectedList[transaction.id] = true;
		}
		return transObj;
	});
	return {
		list: listArr,
		lastIndex: apiList[apiList.length - 1].txnDate,
		selectedList: selectedList
	};
};

/**
 * params : queryfilter, current type, isanyfilterapplied
 * return corresponding counts
 */
const getFilteredCountTypes = (filterType, type, isApplied) => {
	let ret = isApplied > 0 ? [] : FILTERS_VALUES[type];
	if (filterType && filterType.length > 0) {
		ret = filterType.map((fltType) => FILTERS_COUNTS_CONFIG[fltType]);
	}
	return ret;
};

/**
 * params : bundleobject, current query, month
 * format invoice bundle for according to filter applied
 */

const filterCountObject = (countObj, query, key) => {
	const {
		lob,
		txnType = []
	} = query;
	const filterCountObj = {
		[countObj.lob]: 0,
		totalTxnCount: 0
	};
	txnType.forEach((trxn) => {
		filterCountObj[countObj.lob] += countObj[trxn] || 0;
	});
	if (lob && lob.includes(countObj.lob)) {
		filterCountObj.totalTxnCount = filterCountObj[countObj.lob];
		FILTERS_VALUES['txnType'].forEach((trxn) => {
			filterCountObj[trxn] = countObj[trxn] || 0;
		});
		FILTERS_VALUES['topup'].forEach((method) => {
			filterCountObj[method] = countObj[method] || 0;
		});
	}
	return filterCountObj;
};

const getMonthCounts = (month, walletRespCount, queryFilters, isDateOnly, dateCounts, lastQuarter, countConfig) => {
	if (walletRespCount[month].length > 0 && walletRespCount.hasOwnProperty(month)) {
		const monthObj = walletRespCount[month];
		let monthInt = 0;
		try {
			monthInt = Math.abs(parseInt(month));
		} catch (error) {
			monthInt = parseInt(month);
		}
		dateCounts[monthInt] = 0;
		monthObj.forEach((countObj) => {
			const filteredCounts = filterCountObject(countObj, queryFilters, monthInt);
			const {
				totalTxnCount
			} = filteredCounts;
			dateCounts[monthInt] += totalTxnCount;
			dateCounts[FILTER_CONSTANTS.CUSTOM_DATE_FILTER] += totalTxnCount;
			if (lastQuarter.includes(monthInt)) {
				dateCounts[FILTER_CONSTANTS.QTR_FILTER] += totalTxnCount;
			}
			if (!isDateOnly) {
				const types = Object.keys(filteredCounts);
				types.forEach((type) => {
					if (typeof countConfig[type] !== 'undefined') {
						countConfig[type] += filteredCounts[type];
					}
				});
			}
		});
	}
	return dateCounts[FILTER_CONSTANTS.CUSTOM_DATE_FILTER];
};

/**
 * params : count api response, current filter obj
 * format filters count
 */

export const formatCount = (data, filters = {}, query = {}, isNofilter = false, isDateOnly = false) => {
	const walletRespCount = data ? data.monthWiseTxns : false;
	const queryFilters = {
		lob: getFilteredCountTypes(query.lob, 'lob'),
		txnType: getFilteredCountTypes(query.txnType, 'txnType'),
		topup: getFilteredCountTypes(query.topup, 'topup', filters.transType && filters.transType.showClear)
	};
	const countConfig = { ...defaultCountConfig };
	const dateCounts = {
		[FILTER_CONSTANTS.QTR_FILTER]: 0,
		[FILTER_CONSTANTS.CUSTOM_DATE_FILTER]: 0
	};
	let totalCounts = 0;
	if (walletRespCount && Object.keys(walletRespCount).length) {
		const filterMonths = getDateFilterKeys(APPLICATION_CONSTANTS.TODAY);
		const filterLength = filterMonths.length;
		const lastQuarter = filterMonths.slice(filterLength - APPLICATION_CONSTANTS.QUARTER, filterLength);
		for (const month in walletRespCount) {
			totalCounts = getMonthCounts(month, walletRespCount, queryFilters, isDateOnly, dateCounts, lastQuarter, countConfig);
		}
	}
	const walletCounts = {
		...countConfig,
		...dateCounts
	};
	const updatedFilters = updateFilterCounts(walletCounts, cloneDeep(filters), isDateOnly, isNofilter, totalCounts);
	const { isAllCount = false, isMTD } = isAllDateCount(query);
	return {
		counts: {
			...countConfig,
			...dateCounts
		},
		filters: updatedFilters,
		isAllCount: isAllCount,
		isMTD: isMTD
	};
};

/**
 * params : current filter query
 * returns if all filters count are available in api and if current filter is Month till Date(MTD)
 */
export const isAllDateCount = (query) => {
	let isAllCounts = false;
	let isMTD = false;
	const fltDates = getDefaultDate(APPLICATION_CONSTANTS.TODAY, WALLET_CONSTANTS.API_DATE_FORMAT);
	const defaultQueryDates = getDefaultDate(APPLICATION_CONSTANTS.TODAY, WALLET_CONSTANTS.API_DATE_FORMAT, WALLET_CONSTANTS.DEFAULT_MONTHS);
	if (getDaysDiff(query.startDate, fltDates.startDate) <= 0 && getDaysDiff(query.endDate, fltDates.endDate) >= 0) {
		isAllCounts = true;
	}
	if (query.startDate === defaultQueryDates.startDate && query.endDate === defaultQueryDates.endDate) {
		isMTD = true;
	}
	return {
		isAllCounts,
		isMTD
	};
};

/**
 * update filter counts
 * params : walletcount from api, current filter object
 */
const updateFilterCounts = (walletCounts, filters = {}, isDateOnly, isNofilter, totalCount) => {
	filters.dateRange && filters.dateRange.options.forEach((month) => {
		const value = month.countKey;
		let fltCount = walletCounts[value] || 0;
		if (month.type === FILTER_CONSTANTS.CUSTOM_DATE_FILTER) {
			fltCount = !month.checked ? '' : (isDateOnly ? month['count'] : walletCounts[value] || 0);
		}
		month['count'] = fltCount;
	});
	if (!isDateOnly) {
		filters.orderType && filters.orderType.options.forEach((order) => {
			const value = order.countKey;
			order['count'] = walletCounts[value] || 0;
		});
		filters.transType && filters.transType.options.forEach((trans) => {
			const value = trans.countKey;
			trans['count'] = walletCounts[value] || 0;
		});
		filters.paymentMethod && filters.paymentMethod.options.forEach((pay) => {
			pay['count'] = isNofilter ? totalCount : true;
		});
	}

	return filters;
};

/**
 * params : current api query, filter object, get default initial query
 * get months value array for date filter
 */
export const getFilteredQuery = (filterQuery, filter, initialQuery) => {
	const key = filter.key;
	filterQuery[WALLET_CONSTANTS.START_DATE] = initialQuery[WALLET_CONSTANTS.START_DATE];
	filterQuery[WALLET_CONSTANTS.END_DATE] = initialQuery[WALLET_CONSTANTS.END_DATE];
	if (filter.selectedCount === 0 && filter.trackVal === 'date') {
		const defaultDate = getDefaultDate(APPLICATION_CONSTANTS.TODAY, WALLET_CONSTANTS.API_DATE_FORMAT, WALLET_CONSTANTS.DEFAULT_MONTHS);
		filterQuery[WALLET_CONSTANTS.START_DATE] = defaultDate[WALLET_CONSTANTS.START_DATE];
		filterQuery[WALLET_CONSTANTS.END_DATE] = defaultDate[WALLET_CONSTANTS.END_DATE];
	}
	filterQuery[key] = [];
	if (filter.type === FILTER_CONSTANTS.DROPDOWN) {
		filter.value.forEach((val) => {
			filterQuery[key].push(val.value);
		});
	} else if (filter.type === FILTER_CONSTANTS.CHECKBOX) {
		filter.options.forEach((option) => {
			if (option.checked && option.key === FILTER_CONSTANTS.DATE_FILTER) {
				filterQuery.fltTxt = option.monthTxt;
				filterQuery[WALLET_CONSTANTS.START_DATE] = option.start;
				filterQuery[WALLET_CONSTANTS.END_DATE] = option.end;
				if (option.type === FILTER_CONSTANTS.CUSTOM_DATE_FILTER) {
					textsConfig[FILTER_CONSTANTS.CUSTOM_DATE_FILTER] = getDateText(option.start, option.end, true);
					dateConfig[FILTER_CONSTANTS.CUSTOM_DATE_FILTER] = {
						startDate: option.start,
						endDate: option.end
					};
				}
			} else if (option.checked) {
				filterQuery[key].push(option.value);
			}
		});
	}
	if (isEmpty(filterQuery[key])) {
		delete filterQuery[key];
	}
	return filterQuery;
};

/**
 * params : remindersettings to be applied
 * return formatted reminder settings dispaled of UI
 */
export const getReminderSettings = (setting = {}) => ({
	enable: setting.is_enable,
	daysLeft: setting.days_left,
	amountLimit: setting.amount_limit
});

/**
 * params : response of carddetail api
 * return acciunt details, remindersettings, walletdetails
 */
export const fromatAccDetails = (resp) => {
	const {
		walletBalance,
		runRate,
		noOfDaysLeft,
		walletStatus,
		minAmount,
		staticAccountDetails,
		reminderSettingResponse = {},
		minDays,
		rewardBonusDetails
	} = resp;
	const walletDtls = {
		daysLeft: noOfDaysLeft,
		rate: runRate,
		amount: walletBalance,
		minAmount: minAmount,
		minDays: minDays,
		walletStatus: walletStatus
	};
	const {
		accountNumber,
		ifsc,
		beneficiaryName,
		accountType,
		branchName,
		branchLocation,
		bankName
	} = staticAccountDetails;
	const accountDetails = {
		subTitle: textsConfig.ACCOUNT_DETAILS.SUB_HEADING,
		title: textsConfig.ACCOUNT_DETAILS.HEADING,
		accountCTA: textsConfig.ACCOUNT_DETAILS.ACCOUNT_CTA,
		mailCTA: textsConfig.ACCOUNT_DETAILS.MAIL_CTA,
		details: [{
			label: 'Account Number',
			value: accountNumber
		},
		{
			label: 'Beneficiary Name',
			value: beneficiaryName
		},
		{
			label: 'IFSC Code',
			value: ifsc
		},
		{
			label: 'Bank Name',
			value: bankName
		},
		{
			label: 'Account Type',
			value: accountType
		},
		{
			label: 'Branch Name',
			value: branchName
		},
		{
			label: 'Branch Location',
			value: branchLocation
		}
		]
	};
	const {
		frequency,
		amountLimit,
		daysLeft,
		enable
	} = reminderSettingResponse;
	let formattedrewardBonusDetails = false;
	if (rewardBonusDetails) {
		formattedrewardBonusDetails = {
			balance: rewardBonusDetails?.rewardBonusBalance && formatCurrency(rewardBonusDetails.rewardBonusBalance, true),
			expiry: rewardBonusDetails?.bonusAmountExpiryDate && getFormattedDate(rewardBonusDetails.bonusAmountExpiryDate, WALLET_CONSTANTS.CASHBACK_DATE_FORMAT),
			bonusToExpire: rewardBonusDetails?.expiringBonusAmount && formatCurrency(rewardBonusDetails.expiringBonusAmount, true)
		};
	}
	const reminderSettings = {};
	reminderSettings.frequency = frequency;
	reminderSettings.amountLimit = amountLimit;
	reminderSettings.daysLeft = daysLeft;
	reminderSettings.enable = enable;
	walletDtls.minAmount = enable && reminderSettings.amountLimit ? reminderSettings.amountLimit : walletDtls.minAmount;
	walletDtls.defMinAmount = minAmount;
	walletDtls.defMinDays = minDays;
	walletDtls.minDays = enable && reminderSettings.daysLeft ? reminderSettings.daysLeft : walletDtls.minDays;
	return {
		walletDtls,
		reminderSettings,
		accountDetails,
		rewardBonusDetails: formattedrewardBonusDetails
	};
};

/**
 * clear subfilter if parent filter is unchecked
 * params : appliedFilters, changed filter value, option/filter to be removed, if clear filter clicked
 * returns applied filter array
 */
export const handleSubFilter = (appliedFilters, value, option, isCLear) => {
	let selOption = false;
	if (!option) {
		return appliedFilters;
	}
	if (isCLear && option.options) {
		selOption = option.options.filter((opt) => (opt.type === 'select'));
	} else {
		selOption = option;
	}
	if (!value && selOption) {
		selOption.forEach((opt) => {
			if (opt.type === 'select') {
				let valueKey = 'value';
				opt[valueKey].forEach((selectopt) => {
					removeAppliedFilter(appliedFilters, selectopt);
				});
				opt[valueKey] = [];
			}
		});
	}
	return appliedFilters;
};

/**
 * update selected no of options of filter
 * params : current filter object, value of the filter
 */
export const updateSelectedCount = (filter, value) => {
	if (value === false && filter.selectedCount > 0) {
		filter.selectedCount--;
	} else if (value === true) {
		filter.selectedCount++;
	}
	if (filter.type === FILTER_CONSTANTS.DROPDOWN) {
		filter.selectedCount = value.length;
	}
	filter.showClear = filter.type === FILTER_CONSTANTS.CHECKBOX && filter.selectedCount > 0;
};

/**
 * rmove filter from applied filters array
 * params : appliedFilters, option/filter to be removed
 */
export const removeAppliedFilter = (appliedFilters, option) => {
	let arrIndex = false;
	for (let i in appliedFilters) {
		if ((appliedFilters[i].value === option.value) && (appliedFilters[i].key === option.key)) {
			arrIndex = i;
			break;
		}
	}
	if (arrIndex) {
		appliedFilters.splice(arrIndex, 1);
	}
};

/**
 * params : current applied filter object,options checked uncheck, if all filters cleared, if group filters applied
 * returns apllied filter object after removing the unchecked filter obj
 */
export const updateAppliedFilterObj = (appliedFilters, options, isClear, isGroup) => {
	options.forEach((opt) => {
		if (opt.checked && !isClear) {
			appliedFilters.push(opt);
		} else if (isGroup) {
			opt.options.forEach((opt) => removeAppliedFilter(appliedFilters, opt));
		} else {
			removeAppliedFilter(appliedFilters, opt );
		}
	});
	return appliedFilters;
};

/**
 * Transforms bonus back data by formatting dates, amounts, and other fields.
 *
 * @param {Array} data - The array of data objects to transform.
 * @param {Array} prevData - The array to which transformed data will be pushed.
 * @return {Array} The updated array with transformed data.
 *
 * @typedef {Object} DataItem
 * @property {string} reason - The reason for the transaction.
 * @property {string} lob - The line of business.
 * @property {string} txnDate - The transaction date.
 * @property {string} rewardBonusExpiry - The reward bonus expiry date.
 * @property {number} txnAmount - The transaction amount.
 * @property {string} type - The type of transaction (DEBIT or CREDIT).
 * @property {string} id - The transaction ID.
 * @property {string} bookingId - The booking ID.
 * @property {string} emailId - The email ID associated with the transaction.
 *
 * @typedef {Object} TransformedDataItem
 * @property {string} id - The transaction ID.
 * @property {string} txnDate - The formatted transaction date.
 * @property {string} expiry - The formatted reward bonus expiry date.
 * @property {string} txnAmount - The formatted transaction amount.
 * @property {Object} type - The type of transaction with additional properties.
 * @property {string} type.type - The lowercase type of transaction.
 * @property {string} type.dotColor - The color associated with the transaction type.
 * @property {Object} uniqueId - The unique identifier object.
 * @property {string} uniqueId.bookingId - The booking ID.
 * @property {string} uniqueId.lob - The line of business.
 * @property {string} uniqueId.reason - The reason for the transaction.
 * @property {string} lob - The line of business.
 * @property {string} emailId - The email ID associated with the transaction.
 */
export const transformBonusBackData = (data = [], prevData = []) => {
	data.forEach((item) => {
		const { reason, lob, txnDate, rewardBonusExpiry: expiry, txnAmount, type, id, bookingId, emailId } = item;
		prevData.push( {
			id,
			txnDate: txnDate && getFormattedDate(txnDate, APPLICATION_CONSTANTS.DEF_DATE_FORMAT),
			expiry: expiry && getFormattedDate(expiry, APPLICATION_CONSTANTS.DEF_DATE_FORMAT),
			txnAmount: formatCurrency(txnAmount, true),
			type: {
				type: type?.toLowerCase(),
				dotColor: type?.toUpperCase() === WALLET_CONSTANTS.DEBIT ? 'linear-gradient(225deg,#f89d19,#f3c852)' : 'linear-gradient(45deg,#43e1a8,#28beb2)'
			},
			uniqueId: { bookingId, lob, reason },
			lob,
			emailId
		});
	});
	return prevData;
};
