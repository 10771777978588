import React from 'react';

import { GenericSpan } from 'commonStyles';
import { SummaryIcons } from '../../../reporting/pages/summary/Styles';

const Location = ({ source, destination }) => (
	<GenericSpan>
		<GenericSpan text_align='center' valign='middle'>
			{source}
		</GenericSpan>
		{destination && source &&
		<GenericSpan left_M='10' right_M='10' valign='middle'>
			<SummaryIcons icon='arw' />
		</GenericSpan>}
		{destination &&
		<GenericSpan text_align='center' valign='middle'>
			{destination}
		</GenericSpan>}
	</GenericSpan>
);

export default Location;
