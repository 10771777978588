import styled, { css } from 'styled-components';
import { RowWrap } from '../ListStyles';

export const ListingHeader = styled(RowWrap)`
    display:flex;
    border-bottom: ${(props) => props.borderBottom ? props.borderBottom : '1px solid #dddddd'};
		height: ${(props) => props.height ? props.height : '50px'};
    align-items:center;
    padding:0 20px;
    color:${(props) => props.color ? props.color : '#9b9b9b'};
    font-weight: ${(props) => props.f_weight ? props.f_weight : 400};
    font-size:12px;
    text-transform: ${(props) => props.type !== 'hotelpolicy' ? 'uppercase' : 'none'};
    background: ${(props) => props.background ? props.background : '#ffff'};
    position: relative;
		overflow: auto;
    ${(props) => props.isDisabled && css`
        position: relative;
        &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            z-index: 1;
            background: #fff;
            width: 100%;
            height: 100%;
            opacity: 0.66;
        }`
}
`;

export const HeadingText = styled.span`
    margin-right:20px;
    &.width1{
        width:160px;
    }	
    &.width2{
        width:90px;
    }
    &.sortingBooked{
        cursor:pointer;
    }
    &.sortingBookedActive .sortArrow{
        transform: rotate(180deg);
    }
`;

export const SelctionList = styled.ul `
    background: #fff;
    border-radius: 5px;
    width: 150px;
    top:40px;
    position: absolute;
    border: 1px solid #f2f2f2;
    z-index: 1;
    box-shadow: 0px 4px 4px #ddd;

`;
export const SelctionItem = styled.li`
    border-bottom:1px solid #f2f2f2;
    padding:10px;
    cursor:pointer;

`;

export const CheckAllWrapper = styled.span`
    width: 65px;
    display: flex;
    .checkboxGrp__container {
        margin-bottom: 0;
        align-items: end;
    }
		label {
			margin-bottom: 0;
		}
		& input[type="checkbox"] + span {height:18px;}
`;

export const ChkBoxDownArrow = styled.span`
    width: 14px;
    height: 14px;
    display: inline-block;
    position: relative;
    top: 2px;
    cursor:pointer;
    &:after{
        width: 0;
        height: 0;
        content: "";
        position: absolute;
        border-top: 6px solid #fff;
        border-bottom: 6px solid transparent;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        top: 0px;
        right: 0px;
        z-index: 1;
    }
    &:before{
        width: 0;
        height: 0;
        content: "";
        position: absolute;
        border-top: 6px solid #9b9b9b;
        border-bottom: 6px solid transparent;
        border-left:6px solid transparent;
        border-right: 6px solid transparent;
        top: 2px;
        right:0px;
        z-index: 0;
    }
`;
