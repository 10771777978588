import styled, { css } from 'styled-components';

import { ASSET_PATH } from '../../assetsPath';
import { GenericSpan } from '../common/Styles';

export const ShortListIcons = styled(GenericSpan) `
    background: url(${ASSET_PATH.IMAGES}/shortlist/shortlist-sprite.png?v=1) no-repeat;
    background-size: 300px 300px;
    width: 19px;
    height: 19px;
    background-position: -102px -5px;
    display: inline-block;
    ${(props) => {
		switch (props.icon) {
			case 'myBizIcon':
				return css`
                    width: 19px;
                    height: 19px;
                    background-position: -102px -5px;`;
			case 'doubleTick':
				return css`
                    width: 14px;
                    height: 9px;
                    background-position: -276px -42px;`;
			case 'circularCross':
				return css`
                    width: 18px;
                    height: 18px;
                    background-position: -46px -5px;`;
			case 'icMyAccSelected':
				return css`
                width: 29px; 
                height: 28px; 
                background-position: -119px -119px;`;
			case 'icMyAccDefault':
				return css`
                width: 29px; 
                height: 28px; 
                background-position: -119px -86px;`;
			case 'icApproveSelected':
				return css`
                    width: 30px; 
                    height: 28px; 
                    background-position: -156px -119px;`;
			case 'icApproveDefault':
				return css`
                    width: 30px; 
                    height: 28px; 
                    background-position: -155px -87px;`;
			case 'icRequestSelected':
				return css`
                    width: 30px; 
                    height: 28px; 
                    background-position: -82px -119px;`;
			case 'icRequestDefault':
				return css`
                    width: 30px; 
                    height: 28px; 
                    background-position: -83px -86px;`;
			case 'icMyBiz':
				return css`
                     width: 41px; 
                     height: 41px; 
                     background-position: -185px -38px;`;
			case 'icHotelGrey':
				return css`
                    width: 54px; 
                    height: 54px; 
                    background-position: -8px -153px;`;
			case 'icFlightGrey':
				return css`
                    width: 54px; 
                    height: 54px; 
                    background-position: -68px -153px;`;
			case 'icCabsGrey':
				return css`
				width: 54px; 
				height: 54px; 
				background-position: -245px -245px`;
			case 'icBusGrey':
				return css`
				width: 54px; 
				height: 54px; 
				background-position: -44px -245px`;
			case 'icRightArrow':
				return css`
                    width: 14px; 
                    height: 10px; 
                    background-position: -5px -5px;`;
			case 'icSearch':
				return css`
                   width: 15px; 
                   height: 15px; 
                   background-position: -255px -64px;`;
			case 'icBothArrow':
				return css`
                  width: 14px; 
                  height: 17px; 
                  background-position: -25px -5px;`;
			case 'hotelLarge':
				return css`
                    width: 45px;
                    height: 35px;
                    background-position: -191px -88px;
                `;
			case 'flightLarge':
				return css`
                    width: 46px;
                    height: 29px;
                    background-position: -241px -94px;
                `;
			case 'travelreq':
				return css`
                    width: 26px;
                    height: 32px;
                    background-position: -13px -217px`;
			case 'profile':
				return css`
                    width: 26px;
                    height: 20px;
                    background-position: -142px -224px`;
			case 'trips':
				return css`
                    width: 26px;
                    height: 26px;
                    background-position: -57px -223px;`;
			case 'icSharedSelected':
				return css`
                   width: 29px; 
                   height: 28px; 
                   background-position: -44px -119px;`;
			case 'icSharedDefault':
				return css`
                    width: 29px; 
                    height: 28px; 
                    background-position: -45px -86px;`;
			case 'hotelSmall':
				return css`
                    width: 20px;
                    height: 16px;
                    background-position: -233px -63px;
                `;
			case 'flightOnwardDefault':
				return css`
                    width: 20px;
                    height: 16px;
                    background-position: -155px -4px;
                `;
			case 'greenCircularTick':
				return css `
                    width: 16px;
                    height: 17px;
                    background-position: -234px -38px;
                `;
			case 'info':
				return css`
                    width: 16px;
                    height: 17px;
                    background-position: -254px -38px;
                `;
			case 'hotelDefault':
				return css `
                    width: 29px;
                    height: 23px;
                    background-position: -8px -90px;
                `;
			case 'hotelSelected':
				return css`
                    width: 29px;
                    height: 23px;
                    background-position: -8px -119px;
                `;
			case 'flightOnwardActive':
				return css`
                    width: 20px;
                    height: 13px;
                    background-position: -182px -4px;
                `;
			case 'FLIGHT':
				return css`
                    width: 40px;
                    height: 40px;
                    background-position: -49px -39px;
                    `;
			case 'HOTEL':
				return css`
                    width: 40px;
                    height: 40px;
                    background-position: -4px -39px;
                    `;
			case 'BUS':
				return css`
                    width: 40px;
                    height: 40px;
                    background-position: -141px -39px;
                    `;
			case 'CABS':
				return css`
                    width: 40px;
                    height: 40px;
                    background-position:  -95px -39px;
                    `;
			case 'default':
				return css`
                    width: 19px;
                    height: 14px;
                    background-position: -77px -8px;`;
			default:
				break;
		}
	}}
`;

export const SoldoutText = styled.div`
    font-size: 32px;
    font-weight: 900;
    color: #e02020;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`;

export const HeaderContainer = styled.div`
    min-height: 68px;
`;
