import styled from 'styled-components';

export const AppHeaderStyles = styled.div`
  width: 100%;
  height: 60px;
  margin-top:0px!important;
  text-align:left;
  display:flex;
  background-image: linear-gradient(91deg, #f0772c, #f95776);
`;
export const AppIcon = styled.img`
    width: 65px;
    cursor:pointer;
`;
export const LeftAppIcon = styled.div`
  padding-top: 10px;
  padding-left: 45px;`;
export const MidSpace = styled.div`
  flex-grow:8;
  padding-top: 15px;
  padding-left: 45px;`;
export const RightAppMenu = styled.div`
  flex-grow:1;
  display:flex;
  align-items:center;
  justify-content:center;
  width: auto;
  height: 100%;`;
