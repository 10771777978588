import { getAxiosConfig } from '../../apisSetup/apiConfig';
import { PATHS } from 'AppConstants';

const ccbAxiosApiCaller = getAxiosConfig(PATHS.CCB_URL, true, {
}, false);

export const getSummaryData = (apidata) => ccbAxiosApiCaller.post( PATHS.GET_REPORTS_SUMMARY, apidata);
export const getGstrStatusData = () => ccbAxiosApiCaller.get( PATHS.GET_GSTR_STATUS);
export const getCustomReportsData = () => ccbAxiosApiCaller.get( PATHS.GET_CUSTOM_REPORTS);
export const getReportsData = (apiData) => ccbAxiosApiCaller.get( PATHS.GET_REPORT_DATA, { params: { ...apiData } });
export const saveReportsDataApi = (payload) => ccbAxiosApiCaller.post( PATHS.CUSTOM_REPORT_UPSERT, payload);
export const getReportAdditionalData = (apiData) => ccbAxiosApiCaller.get( PATHS.GET_ADDITIONAL_DATA, { params: { ...apiData } });
export const setSchedulerSettings = () => ccbAxiosApiCaller.get( PATHS.SCHEDULER_SETTINGS);
export const submitDwlReportParams = (apiData) => ccbAxiosApiCaller.post( PATHS.SUBMIT_REPORT_DWL_PARAMS, apiData);
export const downloadGstrReportApi = (apiData) => ccbAxiosApiCaller.post( PATHS.DOWNLOAD_GSTR_REPORT, apiData);
export const downloadLedgerReportApi = (apiData) => ccbAxiosApiCaller.post(PATHS.DOWNLOAD_LEDGER_REPORT, apiData);
