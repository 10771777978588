import axios from 'axios';

import { PATHS } from 'AppConstants';
import { getAxiosConfig } from '../apisSetup/apiConfig';
import { getmybizAxiosConfig } from '../apisSetup/myBizConfig';
import { getXrequestId } from 'UtilityComponents/Util';

const axiosApiCaller = getAxiosConfig(PATHS.CCB_URL, true);
const axiosApiCallerWithoutCookie = getAxiosConfig(PATHS.CCB_URL, true, {}, false);
const mybizAxiosApiCaller = getmybizAxiosConfig(true);

export const getIdentifyDetails = () =>axiosApiCaller.get(PATHS.GET_IDENTIFY_DETAILS);

export const postOrgDetails = (apidata, extraHeaders) =>mybizAxiosApiCaller.post(PATHS.POST_ORG_FORM_DATA_API, apidata, { headers: { ...extraHeaders } });

export const getImagesFromCDN = (svgEndPoints = [])=> axios.all(svgEndPoints.map((endpoint) => axios.get(endpoint )));

export const getCurrenciesApi = ()=>axiosApiCaller.get(PATHS.GET_CURRENCIES);
export const postIdentifierDetailsApi = (apiData)=>axiosApiCallerWithoutCookie.post(PATHS.POST_IDENTIFIER_DETAILS, apiData);

export const getClientKeyApi = () => axiosApiCaller.get(PATHS.GET_API_KEYS);
export const getProfileDetailsApi = ()=>axiosApiCaller.get(PATHS.GET_PROFILE);

export const updateClientKeyApi = (apidata) => axiosApiCaller.post(PATHS.UPDATE_API_KEYS, apidata);

export const getOrgDetailsApi = () => axiosApiCaller.get(PATHS.GET_ADDRESS_DETAILS);

export const updateAddOrgAddressApi = (apiData)=>axiosApiCallerWithoutCookie.post(PATHS.ADD_UPDATE_ADDRESS, apiData);
