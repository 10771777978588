// Component
import Support from './components/Support/support';

// Constants & Config
import {
	PATHS,
	APPLICATION_CONSTANTS,
	FILTER_CONSTANTS
} from 'AppConstants';
import {
	REPORT_PAGE_TYPE
} from '../reporting/pages/summary/config';

// Util
import {
	capitalizeFirstLetter
} from 'UtilityComponents/Util';

export const showOptions = 6;

export const MY_CLAIMS_CONFIG = {
	key: 'MYCLAIMS',
	text: 'MY CLAIMS'
};

export const MSR_CONFIG = {
	'key': 'MSR',
	'text': 'Support Requests',
	'component': Support
};

export const DEFAULT_OPTS = [{
	'key': 'DSH',
	'text': 'Dashboard'
},
{
	'key': 'COMPEMP',
	'text': 'Company & Employees',
	'options': [{
		'key': 'CD',
		'text': 'Company Details'
	},
	// {
	// 	'key': 'GST',
	// 	'text': 'Gst Details'
	// },
	{
		'key': 'EMP',
		'text': 'Employees'
	},
	{
		'key': 'GRP',
		'text': 'Groups'
	}
	]
},
{
	'key': 'MW',
	'text': 'myBiz Wallet'
},
{
	'key': 'TRVGRP',
	'text': 'Travel Management',
	'options': [{
		'key': 'REP',
		'text': 'Travel Reports'
	},
	{
		'key': 'INV',
		'text': 'Travel Invoices'
	},
	{
		'key': 'POL',
		'text': 'Travel Policy'
	},
	{
		'key': 'TT',
		'text': 'Trip Tags'
	}, {
		'key': 'TMB',
		'text': 'Manage Bookings'
	}
	]
},
MY_CLAIMS_CONFIG,
MSR_CONFIG
];

const isProv = () => {
	if (typeof window !== 'undefined') {
		return window.location.pathname === PATHS.PROVISIONAL_ADMIN_PAGE;
	}
	return false;
};

const reportsUrlMap = Object.values(REPORT_PAGE_TYPE).reduce((acc, val) => {
	const urlSuffix = val.toLowerCase();
	const pageCrumb = val.split('_');
	let crumb = pageCrumb.reduce((acc, val) => acc + ' ' + capitalizeFirstLetter(val), '');
	const urlKey = `REP_${val}`;
	const urlObj = {
		...acc,
		[urlKey]: {
			trackVal: 'Corporate_Menubar_ReportDetails',
			url: `${PATHS.REPORT_DETAIL_VIEW}${urlSuffix}`,
			links: [
				{ 'text': 'Admin', 'key': 'DSH' },
				{ 'text': 'Reports', 'key': 'REP' },
				{ 'text': `${crumb} Report`, 'key': urlKey }
			],
			isLoggedIn: true,
			pageName: `REPORT_${val}_DETAILS`
		}
	};
	return urlObj;
}, {});

export const CLAIMS_CONFIG_TXT = {
	'heading': 'myClaims| Powered By DICE',
	'claims': 'CLAIMS',
	'poweredBy': 'Powered By',
	'dice': 'DICE',
	'content': 'Manage all of your organisation expenses on a single view',
	'posLeft': '70px',
	'selected': 'false',
	'label': 'MY CLAIMS',
	'selectedIndex': 0
};

export const SUPPORT_REQUESTS_CONFIG = {
	'heading': 'We are here to help!',
	'searchText': 'Search support requests raised from',
	'orgText': 'your organisations',
	'viewAll': ' View all requests',
	'options': [
		{ label: 'Booking ID', key: 'BOOKING_ID', value: 'BOOKING_ID' },
		{ label: 'Service Request ID', key: 'REQUEST_ID', value: 'REQUEST_ID' },
		{ label: 'Employee Name', key: 'EMAIL_ID', value: 'NAME' },
		{ label: 'Employee Email ID', key: 'EMAIL_ID', value: 'EMAIL_ID' }
	],
	'placeholders': {
		'BOOKING_ID': 'Search for Booking ID',
		'REQUEST_ID': 'Search for Service Request ID',
		'NAME': 'Search for Employee Name',
		'EMAIL_ID': 'Search for Employee Email'
	},
	'minSearchLength': 2,
	'loadingText': 'Loading...',
	'emailError': 'Please enter a valid Email Id',
	'bookingIdError': 'Enter a correct Booking Id',
	'requestIdError': 'Enter a correct Service Id',
	'fieldsConfig': (data) => ({
		NAME: {
			type: FILTER_CONSTANTS.DROPDOWN,
			props: {
				handleChange: data.handleEmailSelection,
				field: { options: data.suggestions.list, selectedValue: data.selectedEmail?.id, formatOptionLabel: data.formatOptionLabel },
				value: data.selectedOption,
				placeholder: data.SUPPORT_REQUESTS_CONFIG.placeholders[data.selectedOption.value],
				customStyles: { height: '32px',
					dropdownIndicator: (provided) => ({ ...provided, display: 'none' }),
					input: (provided) => ({ ...provided,
						fontSize: '12px',
						lineHeight: '16px',
						fontWeight: 700 }),
					placeholder: (provided) =>({
						...provided,
						color: '#9B9B9B',
						fontSize: '12px',
						fontWeight: 700,
						lineHeight: '16px'
					}) },
				onInputChange: data.userSearch,
				loadingMessage: data.loadingMessage
			}
		},
		default: {
			type: APPLICATION_CONSTANTS.INPUT_FIELD,
			props: {
				onChange: data.changeHandler,
				inputProps: { name: 'search', placeholder: SUPPORT_REQUESTS_CONFIG.placeholders[data.selectedOption.value] },
				value: data.searchValue,
				height: '32px',
				f_weight: 700
			}
		}
	}),
	'viewAllRequest': {
		'searchKey': 'VIEW_ALL',
		'searchValue': 'ALL'
	},
	'emailOption': 'EMAIL_ID',
	'bookingOption': 'BOOKING_ID',
	'requestOption': 'REQUEST_ID',
	'nameOption': 'NAME',
	'value': 'value',
	'selectFieldStyles': {
		height: '32px',
		input: (provided) =>({
			...provided,
			border: 'none',
			outline: 'none',
			margin: 0,
			padding: 0
		}),
		option: (provided, state) =>({
			...provided,
			fontWeight: state.isSelected ? 900 : 'initial'
		}),
		placeholder: (provided) =>({
			...provided,
			fontSize: '14px',
			fontWeight: 700,
			lineHeight: '20px'
		}),
		singleValue: (provided) =>({
			...provided,
			fontSize: '14px',
			fontWeight: 900,
			lineHeight: '20px'
		})
	},
	'omnitureConfig': {
		'eventName': 'MSR_View_Admin',
		'props': {
			'support': 'MSR_View_Admin_Clicked',
			'booking': 'MSR_View_Admin_booking_id',
			'service': 'MSR_View_Admin_service_id ',
			'name': 'MSR_View_Admin_emp_name',
			'email': 'MSR_View_Admin_emp_email_id',
			'viewAll': 'MSR_View_Admin_veiw_all'
		},
		'evars': {
			'booking': 'MSR_View_Admin_booking_id_error',
			'service': 'MSR_View_Admin_service_id_error',
			'name': 'MSR_View_Admin_emp_name_ error',
			'email': 'MSR_View_Admin_emp_email_id_ error'
		}
	}
};

export const urlMap = (() => ({
	'DSH': {
		trackVal: 'Corporate_Menubar_Dashboard',
		url: isProv() ? PATHS.PROVISIONAL_ADMIN_PAGE : PATHS.DASHBOARD,
		isLoggedIn: true,
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'Performance Dashboard', 'key': 'DSH' }
		],
		pageName: 'DASHBOARD',
		isAdmin: true,
		showHelp: false
	},
	'REP': {
		trackVal: 'Corporate_Menubar_Reports',
		url: PATHS.REPORT_REVAMP,
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'Invoices', 'key': 'INV' }
		],
		isLoggedIn: true,
		pageName: 'REPORT',
		isAdmin: true,
		showHelp: false
	},
	'INV': {
		trackVal: 'Corporate_Menubar_Invoices',
		url: PATHS.INVOICE_PAGE,
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'Invoices', 'key': 'INV' }
		],
		isLoggedIn: true,
		pageName: 'INVOICE',
		isAdmin: true,
		showHelp: false
	},
	'MW': {
		trackVal: 'Corporate_Menubar_Payments',
		url: PATHS.WALLET_PAGE,
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'myBiz Wallet', 'key': 'MW' }
		],
		isLoggedIn: true,
		pageName: 'WALLET',
		isAdmin: true,
		showHelp: false
	},
	'MYCLAIMS': {
		feature: CLAIMS_CONFIG_TXT,
		isAdmin: true
	},
	'MSR': {
		feature: SUPPORT_REQUESTS_CONFIG,
		isAdmin: true
	},
	'EMP': {
		trackVal: 'Corporate_Menubar_ManageTeam',
		url: PATHS.EMPLOYEE_PAGE,
		links: [{
			'text': 'Admin',
			'key': 'DSH'
		},
		{
			'text': 'Employee',
			'key': 'EMP'
		}
		],
		isLoggedIn: true,
		pageName: 'EMPLOYEE',
		isAdmin: true,
		showHelp: false
	},
	'POL': {
		trackVal: 'Corporate_Menubar_Policies',
		url: (PATHS.POLICY_PAGE),
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'Policy', 'key': 'GRP' },
			{ 'text': 'Travel Policy', 'key': 'POL' }
		],
		isLoggedIn: true,
		pageName: 'POLICY',
		isAdmin: true,
		showHelp: false
	},
	'GRP': {
		trackVal: 'Corporate_Menubar_group_and_policies',
		url: PATHS.GROUPS_PAGE,
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'Groups', 'key': 'GRP' }
		],
		isLoggedIn: true,
		pageName: 'GROUPS',
		isAdmin: true,
		showHelp: false
	},
	'CD': {
		url: PATHS.ORGANIZATION_DETAILS_PAGE,
		trackVal: 'Corporate_Menubar_ManageDetails',
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'Company Details', 'key': 'CD' }
		],
		isLoggedIn: true,
		pageName: 'COMPANY_DETAILS',
		isAdmin: true,
		showHelp: false
	},
	// 'GST': {
	// 	url: PATHS.GST_PAGE,
	// 	trackVal: 'Corporate_Menubar_Gst',
	// 	links: [
	// 		{ 'text': 'Admin', 'key': 'DSH' },
	// 		{ 'text': 'Gst Details', 'key': 'GST' }
	// 	],
	// 	isLoggedIn: true,
	// 	pageName: 'GST',
	// 	isAdmin: true,
	// 	showHelp: false
	// },
	'TT': {
		trackVal: 'Add_TripTag_Organaization_Level',
		url: PATHS.TRIP_TAG,
		param: `group_id=${APPLICATION_CONSTANTS.ORGANIZATION_LEVEL_GROUP_ID}`,
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'Policy', 'key': 'GRP' },
			{ 'text': 'Trip Tag', 'key': 'TT' }
		],
		isLoggedIn: true,
		pageName: 'TRIPTAG',
		isAdmin: true,
		showHelp: false
	},
	'TTE': {
		trackVal: 'Edit_TripTag_Organaization_Level',
		url: PATHS.TRIP_TAG_EDIT,
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'Trip Tag', 'key': 'TT' }
		],
		isLoggedIn: true,
		pageName: 'TRIPTAG_EDIT',
		isAdmin: true,
		showHelp: false
	},
	'TMB': {
		url: PATHS.TRAVEL_REQUEST_AND_BOOKING,
		tagConfig: {
			text: 'NEW',
			left: '140px',
			top: '13px'
		},
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'Manage Bookings', 'key': 'TMB' }
		],
		isLoggedIn: true,
		isAdmin: true,
		pageName: 'TRAVEL_REQUEST_BOOKINGS'
	},
	/* 'EXPREIM': {
		trackVal: 'Corporate_expense_travel_reimburse',
		url: PATHS.EXPENSE_REIMBURSE,
		isLoggedIn: true,
		isAdmin: true,
		pageName: 'EXPENSE',
		showHelp: true
	},
	'EXPPOL': {
		trackVal: 'Corporate_expense_travel_policy',
		url: PATHS.EXPENSE_ADMIN_POLICY,
		pageName: 'EXPENSE',
		isLoggedIn: true,
		isAdmin: true,
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'Expense', 'key': 'EXPCAT' },
			{ 'text': 'Policy', 'key': 'EXPPOL' }
		],
		showHelp: true
	},
	'EXPLAND': {
		trackVal: 'Corporate_expense_travel',
		url: PATHS.TRAVEL_EXPENSE,
		isLoggedIn: true,
		pageName: 'EXPENSE',
		showHelp: true
	},
	'EXPCAT': {
		trackVal: 'Corporate_expense_category',
		url: PATHS.EXPENSE_ADMIN_CATEGORIES,
		pageName: 'EXPENSE',
		isLoggedIn: true,
		isAdmin: true,
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'Expense', 'key': 'EXPCAT' },
			{ 'text': 'Category', 'key': 'EXPCAT' }
		],
		tagConfig: {
			text: 'START HERE',
			left: '120px',
			top: '14px'
		},
		showHelp: true
	},
	'EXPTEFS': {
		trackVal: 'Corporate_expense_travel_form',
		url: PATHS.EXPENSE_ADMIN_TNE_FORM,
		pageName: 'EXPENSE',
		isLoggedIn: true,
		isAdmin: true,
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'Expense', 'key': 'EXPCAT' },
			{ 'text': 'Expense Form', 'key': 'EXPTEFS' }
		],
		showHelp: true
	},
	'EXPREP': {
		trackVal: 'Corporate_expense_travel_report',
		url: PATHS.EXPENSE_ADMIN_REPORTS,
		pageName: 'EXPENSE',
		isLoggedIn: true,
		isAdmin: true,
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'Expense', 'key': 'EXPCAT' },
			{ 'text': 'Expense Reports', 'key': 'EXPREP' }
		],
		showHelp: true
	}, */
	'TRVGRP': {
		feature: {
			'heading': 'Manage Business Travel From Here',
			'content': 'Download invoices, analyse travel reports and set travel policies from this section.',
			'posLeft': '70px',
			'selected': 'true',
			'selectedIndex': 0
		}
	},
	'COMPEMP': {},
	/* 'EXPGRP': {
		feature: {
			'heading': 'Introducing Expense Management',
			'content': 'Now skip reconciling expenses manually and easily capture spends made for official purposes.',
			'posLeft': '43px',
			'selected': 'true',
			'selectedIndex': 0
		}
	}, */
	'TRVREQ': {
		isLoggedIn: true,
		url: PATHS.TRAVEL_REQUEST_PAGE
	},
	'GRPDET': {
		isLoggedIn: true,
		isAdmin: true,
		url: PATHS.GROUPS_DETAIL_PAGE,
		links: [
			{ 'text': 'Admin', 'key': 'DSH' },
			{ 'text': 'Groups', 'key': 'GRP' },
			{ 'text': 'Group Detail', 'key': 'GRPDET' }
		],
		showHelp: false
	},
	'SHORTCART': {
		url: PATHS.SHORTILSTED_OPTIONS,
		isLoggedIn: true
	},
	'SHORTCB': {
		url: PATHS.CB_WISHLIST,
		isLoggedIn: true
	},
	'BIZLND': {
		isLoggedIn: false,
		url: PATHS.LANDING_PAGE,
		pageName: 'BIZLANDING'
	},
	...reportsUrlMap,
	'SMEFSTLND': {
		isLoggedIn: false,
		url: PATHS.LANDING_SME_FEST,
		pageName: 'SMEFESTLANDING'
	},
	'TRF': {
		isLoggedIn: true,
		url: PATHS.TRAVEL_REQ_FORM,
		pageName: 'TRF'
	}
}))();

export const ADMIN_REQ_POPUP = {
	heading: 'Admin privileges required',
	content: 'To view this page you require admin privileges, Please contact your company admin',
	apiStatus: 'ERR_DEFAULT',
	isFooter: true
};

export const CLAIMS_STATE_CONFIG = {
	'NOT_DONE': 'NOT_DONE',
	'IN_PROGRESS': 'IN_PROGRESS',
	'DONE': 'DONE'
};

export const CLAIMS_BTN_TXT = {
	[CLAIMS_STATE_CONFIG.NOT_DONE]: 'OPT IN FOR MY CLAIMS',
	[CLAIMS_STATE_CONFIG.IN_PROGRESS]: 'PENDING',
	[CLAIMS_STATE_CONFIG.DONE]: 'MANAGE EXPENSES'
};

export const CLAIMS_SUCCESS_POPUP_DATA = {
	heading: 'You have successfully opt in for myClaims',
	content: 'You will be contacted by our executive within the next 24 hours to get you started with myClaims.',
	apiStatus: 'SUCCESS',
	isFooter: true
};

export const optInRequest = {
	action: 'OPT_IN'
};

export const claimsAuthTokenApiHeaders = {
	'os': APPLICATION_CONSTANTS.DEVICE_TYPE,
	'source': 'ADMIN',
	'partner-code': 'X_01'
};

export const EXPENSE_KEYS = {
	MYCLAIMS: 'MYCLAIMS',
	EXPGRP: 'EXPGRP'
};
