import React from 'react';

import Button from 'common/Button/Button';
import { FlexDiv, GenericP, GenericSpan } from 'commonStyles';
import { MybizLog } from '../HeaderBar/HeaderBarStyles';
import { CLAIMS_BTN_TXT, CLAIMS_CONFIG_TXT, CLAIMS_STATE_CONFIG } from '../../../bizHeader/config';

const { claims, poweredBy, dice, content } = CLAIMS_CONFIG_TXT;

const MyClaims = ({ tneStatus, handleMyExpenseClick }) => (
	tneStatus ?
		<div className='myClaims'>
			<FlexDiv align='center' bottom_M='12'>
				<MybizLog />
				<GenericSpan left_M='5' color='#3A395B'>
					<GenericSpan fsize='20px' f_weight='900' color='#3A395B'>{claims} </GenericSpan>
					| {poweredBy}
					<GenericSpan f_weight='700' color='#3A395B'> {dice}</GenericSpan>
				</GenericSpan>
			</FlexDiv>
			<GenericP bottom_M='12' fsize='12px'>{content}</GenericP>
			{CLAIMS_BTN_TXT?.[tneStatus] && <Button
				btnTxt={CLAIMS_BTN_TXT[tneStatus]}
				btnName='Primary'
				handleClick={handleMyExpenseClick}
				disabled={tneStatus === CLAIMS_STATE_CONFIG.IN_PROGRESS}
			/>}
		</div> :
		null
);

export default MyClaims;
