import React, { Component } from "react";
import { BenifitInfo, Benifit, BenifitList, BenifitListHeader } from "./LoginPageStyles";

export default class SSOPageIntroComponent extends Component {

    render () {

        const SSO_REGISTRATION_PAGE_URL = "https://makemytrip.viewpage.co/SSO-registration-page";

        return (<div>
            <BenifitListHeader>myBiz Single Sign On</BenifitListHeader>
            <BenifitList style={{ paddingLeft: "0px" }}>
                <li>
                    <BenifitInfo>
                        SSO configured company users and admins can use
                        this SSO login page to sign in to myBiz.
                    </BenifitInfo>
                </li>
                <Benifit onClick={() => {
                    window.location.href = SSO_REGISTRATION_PAGE_URL;
                }} style={{ marginLeft: "0px", marginTop: "15px", cursor: "pointer" }}>Register for myBiz SSO</Benifit>
            </BenifitList>
        </div>);
    }
}
