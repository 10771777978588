import React, { memo } from 'react';

import { InfoList } from 'common/ListStyles';

const InfoRow = ({ field }) => (
	<>
		{field && Object.values(field).map((val, i) => (
			<InfoList top_P='5' bottom_P='5' key={i} title={val} fsize='12px' className='ellipses' dangerouslySetInnerHTML={{ __html: val }} />
		))}
	</>
);

export default memo(InfoRow);
