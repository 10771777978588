import styled from "styled-components";

export const HeaderWrapper = styled.div`
    width: 100%;
    height: 70px;
    margin-top: 0px!important;
    background: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);

    @media (max-width: 700px) {
        height: 70px;
        margin-bottom: 0;
    }
`;

export const HeaderInner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    @media (max-width: 1023px) {
        padding: 10px 20px;
    }
    @media (max-width: 700px) {
        padding: 12.5px 20px;
    }
`;

export const Logo = styled.img`
    width: 200px;
    height: 60px;
    cursor:pointer;
    margin-left: 45px;
    @media (max-width: 1023px) {
        margin-left: 0;
    }
    @media (max-width: 700px) {
        width: 150px;
        height: 45px;
        margin-left: 0;
    }
`;
export const HeaderHelpText = styled.a`
    color: #f17531;
    font-weight: 900;
    align-self: center;
    @media (max-width: 700px) {
        font-size: 14px;
    }
`;
