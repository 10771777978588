import styled, { css } from 'styled-components';

import { checkmarkOuter } from '../Styles';

export const CheckBoxGroup = styled(checkmarkOuter)`
    margin-bottom: 10px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    & input[type="checkbox"]:checked + span:first-child{font-weight:700}
    &.expenseStyle {
        width: 100%;
        margin-bottom: 0px;
		}
		&.empCsvUpload {
			margin-bottom: 0px;
			label {
				margin-bottom: 0px;
			}
		}
		&.gst {
			display:inline-flex;
			margin-bottom: 0px;
			label {
				margin: 0;
			}
	}

		&.report{
			background: #FFFFFF;
			box-shadow: 0px 1px 4px rgb(0 0 0 / 20%);
			border-radius: 2px;
			padding: 6px;
			margin-right: 13px;
			margin-bottom: 13px;
			&.mandatory{
				background: #FFF6F6;
			}
		}
		&.groupCheckbox{
			width: 50%;
			display:inline-flex;
		}
`;
export const ChkBoxLabel = styled.label`
    display:flex;
    justify-content:space-between;
    width:100%;
    input:checked + * {
        font-weight: 700;
    }
    ${(props) => props.isDisabled && css`
    opacity: 0.55;
    cursor: not-allowed;
    * {
        cursor: not-allowed;
    }
    *:before {
        cursor: not-allowed;
    }
    `}

`;

export const Text = styled.span`
    display:flex;
    justify-content:space-between;
		width: 100%;
		min-height: 18px;
`;

export const Info = styled.span`
    font-size:14px;
    color:#4a4a4a;
    font-weight: normal;
    &.expenseStyle {
        display: block;
        color:#000;
        width: 100%;
    }
		&.empCsvUpload {
			color: #4a4a4a;
			font-weight: bold;
		}
		&.invite {
			color:#000;
			font-weight: bold;
		}
		&.boldText{
			color: #000;
			font-weight: 700;
		}
`;
export const Count = styled.span`
    font-size:12px;
    color:#9b9b9b;
    &.expenseStyle {
        display: block;
        font-size:10px;
    }
`;

export const SubText = styled.span`
    margin-left: 5px;
    font-size: 12px;
    color: #9b9b9b;
    &.expenseStyle {
        color:#4a4a4a;
        display:flex;
        align-items:center;
        & .latoBlack{
            font-weight:900 !important;
        }
		}
		&.invite {
			color:#4a4a4a;
			font-weight: normal;
		}
`;

export const SideSection = styled.span`
    font-size:14px;
    color:#000;
    font-weight:900;
`;
