import { REPORT_PAGE_TYPE } from '../summary/config';
import {
	getFormattedAction,
	getBoldValue,
	formatNameValue,
	formatBookingId,
	formatHotelName,
	formatFlightArrw,
	formatCabArrw,
	formatEmptyValue,
	formatCabType,
	formatSavingBookingId,
	formatSavings,
	formatFieldColor
} from '../../components/details/detailListing/ListingUtilComps';
import {
	FILTER_CONSTANTS,
	SORT_KEY,
	REPORTS_CONSTANT
} from 'AppConstants';

export const HELP_TEXT = {
	why: 'Why should I use Report summary?',
	how: 'How to use Reports summary?'
};

const cellProps = (customConfig = {}) => {
	const { customBodyClasses = {},
		customHeadClass = {},
		customCellProps = {} } = customConfig;
	return ({
		bodyCellProps: {
			size: 'medium',
			padding: '16px 9px',
			classes: {
				root: 'report_cell',
				...customBodyClasses
			},
			...customCellProps
		},
		headerCellProps: {
			size: 'medium',
			padding: '16px 9px',
			classes: {
				root: 'report_cell report_cell_head',
				...customHeadClass
			},
			...customCellProps
		}
	});
};

const SAVING_SORTING_KEY = 'BOOKING_DATE';
const SORTING_KEY = 'CREATED';

const ALL_TABLE_COLUMNS = {
	checkBox: {
		checkBoxColumn: true,
		...(cellProps())
	},
	actionDate: {
		header: 'ACTION DATE',
		accessor: 'actionDate',
		key: SORTING_KEY,
		isSort: true,
		formatter: getFormattedAction,
		...(cellProps({ customCellProps: { width: '130px' } }))
	},
	savingsActionDate: {
		header: 'ACTION DATE',
		accessor: 'actionDate',
		key: SAVING_SORTING_KEY,
		isSort: true,
		formatter: getFormattedAction,
		...(cellProps({ customCellProps: { width: '130px' } }))
	},
	bookingId: {
		header: 'BOOKING ID',
		accessor: 'bookingId',
		formatter: formatBookingId,
		...(cellProps())
	},
	savingBookingId: {
		header: 'BOOKING ID',
		accessor: 'bookingId',
		formatter: formatSavingBookingId,
		...(cellProps())
	},
	bookerName: {
		header: 'BOOKER NAME',
		accessor: 'bookerName',
		isBooker: true,
		formatter: formatNameValue,
		...(cellProps({ customCellProps: { width: '90px' } }))
	},
	travellerName: {
		header: 'TRAVELLER NAME',
		accessor: 'travellerName',
		formatter: formatNameValue,
		...(cellProps({ customCellProps: { width: '110px' } }))
	},
	guestName: {
		header: 'GUEST NAME',
		accessor: 'travellerName',
		formatter: formatNameValue,
		...(cellProps({ customCellProps: { width: '110px' } }))
	},
	hotelCity: {
		header: 'HOTEL & CITY',
		accessor: 'hotelName',
		formatter: formatHotelName,
		...(cellProps())
	},
	fromCity: {
		header: 'FROM',
		accessor: 'fromCity',
		formatter: formatEmptyValue,
		...(cellProps())
	},
	toCity: {
		header: 'TO',
		accessor: 'toCity',
		formatter: formatEmptyValue,
		...(cellProps())
	},
	fromDate: {
		header: 'FROM',
		accessor: 'checkinDate',
		...(cellProps())
	},
	toDate: {
		header: 'TO',
		accessor: 'checkoutDate',
		...(cellProps())
	},
	boardingDate: {
		header: 'BOARDING DATE',
		accessor: 'boardingDate',
		...(cellProps())
	},
	arrwFlightColumn: {
		accessor: 'roundTrip',
		formatter: formatFlightArrw,
		...(cellProps())
	},
	arrwCabColumn: {
		accessor: 'roundTrip',
		formatter: formatCabArrw,
		...(cellProps())
	},
	departureDate: {
		header: 'DEPARTURE DATE',
		accessor: 'departureDate',
		...(cellProps())
	},
	travelDate: {
		header: 'TRAVEL DATE',
		accessor: 'travelDate',
		...(cellProps())
	},
	cabType: {
		header: 'CAB TYPE',
		accessor: 'cabType',
		formatter: formatCabType,
		...(cellProps())
	},
	pnr: {
		header: 'PNR NO.',
		accessor: 'pnrNo',
		...(cellProps())
	},
	suggestedPrice: {
		header: 'SUGGESTED PRICE',
		accessor: 'suggestedPrice',
		...(cellProps())
	},
	bookedPrice: {
		header: 'BOOKED PRICE',
		accessor: 'bookedPrice',
		...(cellProps())
	},
	savings: {
		header: 'MISSED/GAINED SAVINGS',
		accessor: 'savings',
		formatter: formatSavings,
		...(cellProps())
	},
	skipReason: {
		header: 'REASON FOR SKIPPING',
		accessor: 'skipReason',
		formatter: formatEmptyValue,
		...(cellProps())
	},
	amount: {
		header: 'AMOUNT',
		accessor: 'amount',
		formatter: getBoldValue,
		...(cellProps())
	},
	bookingType: {
		header: 'BOOKING TYPE',
		accessor: 'bookingType',
		formatter: formatEmptyValue,
		...(cellProps())
	},
	noOfDateModifications: {
		header: 'NO OF DATE MODIFICATIONS',
		accessor: 'noOfDateModifications',
		formatter: formatEmptyValue,
		...(cellProps())
	},
	noOfCancelledBookings: {
		header: 'NO OF CANCELLATION BOOKINGS',
		accessor: 'noOfCancelledBookings',
		formatter: formatEmptyValue,
		...(cellProps())
	},
	savingsOnCancellation: {
		header: 'SAVINGS ON CANCELLATION',
		accessor: 'savingsOnCancellation',
		formatter: formatEmptyValue,
		...(cellProps())
	},
	savingsOnDateChange: {
		header: 'SAVINGS ON DATE CHANGE',
		accessor: 'savingsOnDateChange',
		formatter: formatEmptyValue,
		...(cellProps())
	},
	totalSavings: {
		header: 'TOTAL SAVINGS',
		accessor: 'totalSavings',
		formatter: formatFieldColor,
		...(cellProps())
	},
	claimableGst: {
		header: 'CLAIMABLE GST',
		accessor: 'claimableGst',
		formatter: formatEmptyValue,
		...(cellProps())
	},
	convFeesSavings: {
		header: 'SAVINGS ON CONVENINCE FEE',
		accessor: 'convFeesSavings',
		formatter: formatEmptyValue,
		...(cellProps())
	},
	eCouponsSavings: {
		header: 'E-COUPON SAVINGS',
		accessor: 'eCouponsSavings',
		formatter: formatEmptyValue,
		...(cellProps())
	},
	missedSaving: {
		header: 'MISSED SAVINGS',
		accessor: 'missedSavings',
		formatter: formatFieldColor,
		...(cellProps({ customCellProps: { width: '90px' } }))
	}
};

export const FLIGHT_COLUMNS = [
	ALL_TABLE_COLUMNS.checkBox,
	ALL_TABLE_COLUMNS.actionDate,
	ALL_TABLE_COLUMNS.bookingId,
	ALL_TABLE_COLUMNS.bookerName,
	ALL_TABLE_COLUMNS.travellerName,
	ALL_TABLE_COLUMNS.fromCity,
	ALL_TABLE_COLUMNS.arrwFlightColumn,
	ALL_TABLE_COLUMNS.toCity,
	ALL_TABLE_COLUMNS.departureDate,
	ALL_TABLE_COLUMNS.amount
];

export const BUS_COLUMNS = [
	ALL_TABLE_COLUMNS.checkBox,
	ALL_TABLE_COLUMNS.actionDate,
	ALL_TABLE_COLUMNS.bookingId,
	ALL_TABLE_COLUMNS.bookerName,
	ALL_TABLE_COLUMNS.travellerName,
	ALL_TABLE_COLUMNS.fromCity,
	ALL_TABLE_COLUMNS.toCity,
	ALL_TABLE_COLUMNS.boardingDate,
	ALL_TABLE_COLUMNS.amount
];

export const TRAIN_COLUMNS = [
	ALL_TABLE_COLUMNS.checkBox,
	ALL_TABLE_COLUMNS.actionDate,
	ALL_TABLE_COLUMNS.bookingId,
	ALL_TABLE_COLUMNS.bookerName,
	ALL_TABLE_COLUMNS.travellerName,
	ALL_TABLE_COLUMNS.fromCity,
	ALL_TABLE_COLUMNS.arrwFlightColumn,
	ALL_TABLE_COLUMNS.toCity,
	ALL_TABLE_COLUMNS.departureDate,
	ALL_TABLE_COLUMNS.amount
];

export const HOTEL_COLUMNS = [
	ALL_TABLE_COLUMNS.checkBox,
	ALL_TABLE_COLUMNS.actionDate,
	ALL_TABLE_COLUMNS.bookingId,
	ALL_TABLE_COLUMNS.bookerName,
	ALL_TABLE_COLUMNS.guestName,
	ALL_TABLE_COLUMNS.hotelCity,
	ALL_TABLE_COLUMNS.fromDate,
	ALL_TABLE_COLUMNS.toDate,
	ALL_TABLE_COLUMNS.amount
];

export const CAB_COLUMNS = [
	ALL_TABLE_COLUMNS.checkBox,
	ALL_TABLE_COLUMNS.actionDate,
	ALL_TABLE_COLUMNS.bookingId,
	ALL_TABLE_COLUMNS.bookerName,
	ALL_TABLE_COLUMNS.travellerName,
	ALL_TABLE_COLUMNS.travelDate,
	ALL_TABLE_COLUMNS.cabType,
	ALL_TABLE_COLUMNS.fromCity,
	ALL_TABLE_COLUMNS.arrwCabColumn,
	ALL_TABLE_COLUMNS.toCity,
	ALL_TABLE_COLUMNS.amount
];

export const FLIGHT_PNR_COLUMNS = [
	ALL_TABLE_COLUMNS.checkBox,
	ALL_TABLE_COLUMNS.actionDate,
	ALL_TABLE_COLUMNS.bookingId,
	ALL_TABLE_COLUMNS.pnr,
	ALL_TABLE_COLUMNS.bookerName,
	ALL_TABLE_COLUMNS.fromCity,
	ALL_TABLE_COLUMNS.arrwFlightColumn,
	ALL_TABLE_COLUMNS.toCity,
	ALL_TABLE_COLUMNS.departureDate,
	ALL_TABLE_COLUMNS.amount
];

export const WALLET_COLUMNS = [];

export const SAVINGS_SUMMARY_COLUMNS = [
	ALL_TABLE_COLUMNS.bookingType,
	ALL_TABLE_COLUMNS.noOfDateModifications,
	ALL_TABLE_COLUMNS.noOfCancelledBookings,
	ALL_TABLE_COLUMNS.savingsOnCancellation,
	ALL_TABLE_COLUMNS.savingsOnDateChange,
	ALL_TABLE_COLUMNS.claimableGst,
	ALL_TABLE_COLUMNS.convFeesSavings,
	ALL_TABLE_COLUMNS.eCouponsSavings,
	ALL_TABLE_COLUMNS.totalSavings
];

export const SAVINGS_COLUMNS = [
	ALL_TABLE_COLUMNS.checkBox,
	ALL_TABLE_COLUMNS.bookingId,
	ALL_TABLE_COLUMNS.savingsActionDate,
	ALL_TABLE_COLUMNS.bookerName,
	ALL_TABLE_COLUMNS.travellerName,
	ALL_TABLE_COLUMNS.suggestedPrice,
	ALL_TABLE_COLUMNS.bookedPrice,
	ALL_TABLE_COLUMNS.missedSaving
];

export const PAGE_META_DATA = {
	[REPORT_PAGE_TYPE.HOTEL]: {
		pageHeading: 'Hotel',
		topHeading: 'Hotel Summary',
		columns: HOTEL_COLUMNS
	},
	[REPORT_PAGE_TYPE.FLIGHT]: {
		pageHeading: 'Flight',
		topHeading: 'Flight Summary',
		columns: FLIGHT_COLUMNS
	},
	[REPORT_PAGE_TYPE.BUS]: {
		pageHeading: 'Bus',
		topHeading: 'Bus Summary',
		columns: BUS_COLUMNS
	},
	[REPORT_PAGE_TYPE.TRAIN]: {
		pageHeading: 'Train',
		topHeading: 'Train Summary',
		columns: TRAIN_COLUMNS
	},
	[REPORT_PAGE_TYPE.CAB]: {
		pageHeading: 'Cab',
		topHeading: 'Cab Summary',
		columns: CAB_COLUMNS
	},
	[REPORT_PAGE_TYPE.FLIGHT_PNR]: {
		pageHeading: 'Flight PNR',
		topHeading: 'Flight PNR Summary',
		columns: FLIGHT_PNR_COLUMNS
	},
	[REPORT_PAGE_TYPE.WALLET]: {
		pageHeading: 'Wallet',
		topHeading: 'Wallet Summary',
		columns: WALLET_COLUMNS
	},
	[REPORT_PAGE_TYPE.SAVINGS_SUMMARY]: {
		pageHeading: 'Savings',
		topHeading: 'Savings Summary',
		columns: SAVINGS_SUMMARY_COLUMNS
	},
	[REPORT_PAGE_TYPE.SAVINGS]: {
		pageHeading: 'Savings',
		topHeading: 'Savings Missed',
		columns: SAVINGS_COLUMNS
	}
};

export const SAVINGS_FIELD = {
	totalSavings: {
		color: '#219393'
	},
	missedSavings: {
		color: '#219393'
	}
};

export const TABLE_PROPS = {
	container: {
		classes: { root: 'report_table' }
	},
	table: {
		stickyHeader: true
	},
	bodyRowChild: {
		classes: {
			root: 'report_table'
		}
	}
};

export const CAB_TRIP_TYPE = {
	AIRPORT: 'AIRPORT',
	OUTSTATION: 'OUTSTATION'
};

export const CAB_TRIP_COLOR = {
	[CAB_TRIP_TYPE.AIRPORT]: {
		bgColor: '#E9F3FD',
		color: '#008CFF'
	},
	[CAB_TRIP_TYPE.OUTSTATION]: {
		bgColor: '#FEF5E8',
		color: '#E08E17'
	}
};

export const DETAILS_CONFIG_CONST = {
	BOOKED: 'BOOKED',
	CANCELLED: 'CANCELLED',
	REFUND: 'REFUND',
	DATE_CHANGE: 'DATE_CHANGE',
	ADD_ONS: 'ADD_ONS',
	ALL: 'ALL',
	EXTRA_CHARGES: 'EXTRA_CHARGES',
	IN_POLICY: 'IN_POLICY',
	OUT_POLICY: 'OO_POLICY',
	ONE_WAY: 'ONE_WAY',
	ROUND_TRIP: 'ROUND_TRIP',
	OUTSTATION_CAB: 'OUTSTATION',
	AIRPORT_CAB: 'AIRPORT',
	SUGGESTION_BOOKED: 'SUGGESTIONS_BOOKED',
	SUGGESTION_SKIPPED: 'SUGGESTIONS_SKIPPED',
	DATE_CHANGE_CREDIT: 'DATE_CHANGE_CREDIT',
	DATE_CHANGE_DEBIT: 'DATE_CHANGE_DEBIT',
	DATE_CHANGE_PENALTY: 'DATE_CHANGE_PENALTY'
};

export const SUGGESTION_DETAILS = {
	[DETAILS_CONFIG_CONST.SUGGESTION_BOOKED]: {
		color: '#28BFB1',
		bgColor: '#EAF9F8',
		label: 'SUGGESTION BOOKED'
	},
	[DETAILS_CONFIG_CONST.SUGGESTION_SKIPPED]: {
		color: '#FF7527',
		bgColor: '#FFECEF',
		label: 'SUGGESTION SKIPPED'
	}
};

export const BOOKING_ACTIONS_CONFIG = {
	[DETAILS_CONFIG_CONST.BOOKED]: {
		bgColor: '#EAF9F8',
		color: '#28BFB1',
		label: 'Booked'
	},
	[DETAILS_CONFIG_CONST.ALL]: {
		bgColor: '#EAF9F8',
		color: '#28BFB1',
		label: 'Booked'
	},
	[DETAILS_CONFIG_CONST.CANCELLED]: {
		bgColor: '#FFECEF',
		color: '#FF7527',
		label: 'Cancelled'
	},
	[DETAILS_CONFIG_CONST.REFUND]: {
		bgColor: '#E9F3FD',
		color: '#008CFF',
		label: 'Refund'
	},
	[DETAILS_CONFIG_CONST.ADD_ONS]: {
		bgColor: '#E9F3FD',
		color: '#008CFF',
		label: 'Add-ons'
	},
	[DETAILS_CONFIG_CONST.EXTRA_CHARGES]: {
		bgColor: '#FEF5E8',
		color: '#E08E17',
		label: 'Extra Charges'
	},
	[DETAILS_CONFIG_CONST.DATE_CHANGE]: {
		bgColor: '#FEF5E8',
		color: '#E08E17',
		label: 'Date Change'
	},
	[DETAILS_CONFIG_CONST.DATE_CHANGE_CREDIT]: {
		bgColor: '#FEF5E8',
		color: '#E08E17',
		label: 'Date Change Credit'
	},
	[DETAILS_CONFIG_CONST.DATE_CHANGE_DEBIT]: {
		bgColor: '#FEF5E8',
		color: '#E08E17',
		label: 'Date Change Debit'
	},
	[DETAILS_CONFIG_CONST.DATE_CHANGE_PENALTY]: {
		bgColor: '#FEF5E8',
		color: '#E08E17',
		label: 'Date Change Penalty'
	}
};

export const RPRT_DETAIL_FLTR_CONSTANTS = {
	[DETAILS_CONFIG_CONST.BOOKED]: {
		label: 'Booked',
		value: DETAILS_CONFIG_CONST.BOOKED,
		countKey: 'booked'
	},
	[DETAILS_CONFIG_CONST.CANCELLED]: {
		label: 'Cancelled',
		value: DETAILS_CONFIG_CONST.CANCELLED,
		countKey: 'cancelled'
	},
	[DETAILS_CONFIG_CONST.REFUND]: {
		label: 'Refund',
		value: DETAILS_CONFIG_CONST.REFUND,
		countKey: 'refunds'
	},
	[DETAILS_CONFIG_CONST.ADD_ONS]: {
		label: 'Add-ons',
		value: DETAILS_CONFIG_CONST.ADD_ONS,
		countKey: 'addons'
	},
	[DETAILS_CONFIG_CONST.EXTRA_CHARGES]: {
		label: 'Extra Charges',
		value: DETAILS_CONFIG_CONST.EXTRA_CHARGES,
		countKey: 'extraCharges'
	},
	[DETAILS_CONFIG_CONST.DATE_CHANGE]: {
		label: 'Date Change',
		value: DETAILS_CONFIG_CONST.DATE_CHANGE,
		countKey: 'dateChanged'
	},
	[DETAILS_CONFIG_CONST.IN_POLICY]: {
		label: 'In-Policy',
		value: DETAILS_CONFIG_CONST.IN_POLICY,
		countKey: 'inPolicy'
	},
	[DETAILS_CONFIG_CONST.OUT_POLICY]: {
		label: 'Out-of-Policy',
		value: DETAILS_CONFIG_CONST.OUT_POLICY,
		countKey: 'outOfPolicy'
	},
	[DETAILS_CONFIG_CONST.OUTSTATION_CAB]: {
		label: 'Outstation Cabs',
		value: DETAILS_CONFIG_CONST.OUTSTATION_CAB,
		countKey: 'outstationCabs'
	},
	[DETAILS_CONFIG_CONST.AIRPORT_CAB]: {
		label: 'Airport Cabs',
		value: DETAILS_CONFIG_CONST.AIRPORT_CAB,
		countKey: 'airportCabs'
	},
	[DETAILS_CONFIG_CONST.SUGGESTION_BOOKED]: {
		label: 'Suggestion Booked',
		value: DETAILS_CONFIG_CONST.SUGGESTION_BOOKED,
		countKey: 'suggestionBooked'
	},
	[DETAILS_CONFIG_CONST.SUGGESTION_SKIPPED]: {
		label: 'Suggestion Skipped',
		value: DETAILS_CONFIG_CONST.SUGGESTION_SKIPPED,
		countKey: 'suggestionSkipped'
	},
	[DETAILS_CONFIG_CONST.ONE_WAY]: {
		label: 'Oneway',
		value: DETAILS_CONFIG_CONST.ONE_WAY,
		countKey: 'oneWayTrips'
	},
	[DETAILS_CONFIG_CONST.ROUND_TRIP]: {
		label: 'Round trip',
		value: DETAILS_CONFIG_CONST.ROUND_TRIP,
		countKey: 'roundTrips'
	},
	[DETAILS_CONFIG_CONST.ALL]: {
		label: 'All',
		value: '',
		countKey: 'all'
	}
};

export const SORT_PROP = {
	key: SORTING_KEY,
	direction: SORT_KEY.DESCENDING
};

export const SAVINGS_SORT_PROP = {
	key: SAVING_SORTING_KEY,
	direction: SORT_KEY.DESCENDING
};

export const BOOKING_ACTIONS_FLTR_CONF = {
	[DETAILS_CONFIG_CONST.BOOKED]: {
		value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.BOOKED].value,
		countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.BOOKED].countKey,
		label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.BOOKED].label,
		count: '',
		filterKey: REPORTS_CONSTANT.BOOKING_ACTION,
		index: 0,
		checked: false,
		trackVal: DETAILS_CONFIG_CONST.BOOKED
	},
	[DETAILS_CONFIG_CONST.REFUND]: {
		value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.REFUND].value,
		countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.REFUND].countKey,
		label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.REFUND].label,
		count: '',
		filterKey: REPORTS_CONSTANT.BOOKING_ACTION,
		index: 1,
		checked: false,
		trackVal: DETAILS_CONFIG_CONST.REFUND
	},
	[DETAILS_CONFIG_CONST.CANCELLED]: {
		value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.CANCELLED].value,
		countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.CANCELLED].countKey,
		label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.CANCELLED].label,
		count: '',
		filterKey: REPORTS_CONSTANT.BOOKING_ACTION,
		index: 2,
		checked: false,
		trackVal: DETAILS_CONFIG_CONST.CANCELLED
	},
	[DETAILS_CONFIG_CONST.ADD_ONS]: {
		value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.ADD_ONS].value,
		countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.ADD_ONS].countKey,
		label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.ADD_ONS].label,
		count: '',
		filterKey: REPORTS_CONSTANT.BOOKING_ACTION,
		index: 3,
		checked: false,
		trackVal: ''
	},
	[DETAILS_CONFIG_CONST.DATE_CHANGE]: {
		value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.DATE_CHANGE].value,
		countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.DATE_CHANGE].countKey,
		label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.DATE_CHANGE].label,
		count: '',
		filterKey: REPORTS_CONSTANT.BOOKING_ACTION,
		index: 4,
		checked: false,
		trackVal: ''
	},
	[DETAILS_CONFIG_CONST.EXTRA_CHARGES]: {
		value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.EXTRA_CHARGES].value,
		countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.EXTRA_CHARGES].countKey,
		label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.EXTRA_CHARGES].label,
		count: '',
		filterKey: REPORTS_CONSTANT.BOOKING_ACTION,
		index: 5,
		checked: false,
		trackVal: ''
	},
	[DETAILS_CONFIG_CONST.ALL]: {
		value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.ALL].value,
		countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.ALL].countKey,
		label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.ALL].label,
		count: '',
		filterKey: REPORTS_CONSTANT.BOOKING_ACTION,
		index: 6,
		checked: true,
		trackVal: ''
	}
};

export const filters = {
	[REPORTS_CONSTANT.BOOKING_ACTION]: {
		label: 'Booking Action',
		type: FILTER_CONSTANTS.CHECKBOX,
		key: REPORTS_CONSTANT.BOOKING_ACTION,
		filterType: 'bookingAction',
		value: '',
		hideClear: true,
		singleSelect: true,
		selectedCount: 0,
		trackVal: '',
		options: []
	},
	[REPORTS_CONSTANT.POLICY]: {
		label: 'Policy',
		type: FILTER_CONSTANTS.CHECKBOX,
		value: '',
		filterType: 'policy',
		key: REPORTS_CONSTANT.POLICY,
		selectedCount: 0,
		hideClear: true,
		trackVal: '',
		options: [{
			value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.IN_POLICY].value,
			countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.IN_POLICY].countKey,
			label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.IN_POLICY].label,
			checked: false,
			filterKey: REPORTS_CONSTANT.POLICY,
			count: '',
			trackVal: DETAILS_CONFIG_CONST.IN_POLICY,
			index: 0
		},
		{
			value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.OUT_POLICY].value,
			countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.OUT_POLICY].countKey,
			label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.OUT_POLICY].label,
			checked: false,
			filterKey: REPORTS_CONSTANT.POLICY,
			count: '',
			trackVal: DETAILS_CONFIG_CONST.OUT_POLICY,
			index: 0
		}]
	},
	[REPORTS_CONSTANT.CAB_TYPE]: {
		label: 'Cab Type',
		type: FILTER_CONSTANTS.CHECKBOX,
		hideClear: false,
		filterType: 'cabType',
		key: REPORTS_CONSTANT.TRIP_TYPE,
		value: '',
		selectedCount: 0,
		trackVal: '',
		singleSelect: false,
		options: [{
			value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.OUTSTATION_CAB].value,
			countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.OUTSTATION_CAB].countKey,
			label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.OUTSTATION_CAB].label,
			checked: false,
			filterKey: REPORTS_CONSTANT.CAB_TYPE,
			count: '',
			trackVal: DETAILS_CONFIG_CONST.OUTSTATION_CAB,
			index: 0
		},
		{
			value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.AIRPORT_CAB].value,
			countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.AIRPORT_CAB].countKey,
			label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.AIRPORT_CAB].label,
			checked: false,
			filterKey: REPORTS_CONSTANT.CAB_TYPE,
			count: '',
			trackVal: DETAILS_CONFIG_CONST.AIRPORT_CAB,
			index: 0
		}]
	},
	[REPORTS_CONSTANT.TRIP_TYPE]: {
		label: 'Trip Type',
		type: FILTER_CONSTANTS.CHECKBOX,
		hideClear: true,
		filterType: 'tripType',
		key: REPORTS_CONSTANT.TRIP_TYPE,
		value: '',
		selectedCount: 0,
		trackVal: '',
		singleSelect: false,
		options: [{
			value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.ONE_WAY].value,
			countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.ONE_WAY].countKey,
			label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.ONE_WAY].label,
			checked: false,
			filterKey: REPORTS_CONSTANT.TRIP_TYPE,
			count: '',
			trackVal: DETAILS_CONFIG_CONST.ONE_WAY,
			index: 0
		},
		{
			value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.ROUND_TRIP].value,
			countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.ROUND_TRIP].countKey,
			label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.ROUND_TRIP].label,
			checked: false,
			filterKey: REPORTS_CONSTANT.TRIP_TYPE,
			count: '',
			trackVal: DETAILS_CONFIG_CONST.ROUND_TRIP,
			index: 0
		}]
	},
	[REPORTS_CONSTANT.SYSTEM_SUGGESTED_BOOKING]: {
		label: 'System Suggested Booking',
		type: FILTER_CONSTANTS.CHECKBOX,
		hideClear: true,
		filterType: 'systemSuggestion',
		key: REPORTS_CONSTANT.SYSTEM_SUGGESTED_BOOKING,
		value: '',
		selectedCount: 0,
		trackVal: '',
		singleSelect: false,
		options: [{
			value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.SUGGESTION_BOOKED].value,
			countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.SUGGESTION_BOOKED].countKey,
			label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.SUGGESTION_BOOKED].label,
			checked: false,
			filterKey: REPORTS_CONSTANT.SYSTEM_SUGGESTED_BOOKING,
			count: '',
			trackVal: DETAILS_CONFIG_CONST.SUGGESTION_BOOKED,
			index: 0
		},
		{
			value: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.SUGGESTION_SKIPPED].value,
			countKey: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.SUGGESTION_SKIPPED].countKey,
			label: RPRT_DETAIL_FLTR_CONSTANTS[DETAILS_CONFIG_CONST.SUGGESTION_SKIPPED].label,
			checked: false,
			filterKey: REPORTS_CONSTANT.SYSTEM_SUGGESTED_BOOKING,
			count: '',
			trackVal: DETAILS_CONFIG_CONST.SUGGESTION_SKIPPED,
			index: 0
		}]
	}
};

export const coachmarkTextConf = {
	list: {
		heading: 'View & Download Data',
		subHeading: 'View summarised information from the selected report and download the required information.'
	},
	filter: {
		heading: 'View Filter Data',
		subHeading: 'View summarised information from the selected report and download the required information.'
	},
	help: {
		heading: 'Refer to Help Section',
		subHeading: 'Revisit the benefits and onboarding process of Report from the Help section here.'
	}
};

export const SEARCH_PLACEHOLDER = 'Search for Traveller Name / email ID / booking ID';

export const downloadListData = [{
	heading: 'Choose Download Type'
}, {
	heading: 'Download with all related actions',
	content: 'The related actions will be shown as separate lines in the report',
	backendKey: REPORTS_CONSTANT.DEAFULT_DOWNLOAD_REPORT_TYPE
}, {
	heading: 'Download with selected action only',
	content: 'The related actions won’t be shown in the report.',
	backendKey: REPORTS_CONSTANT.OTHER_DOWNLOAD_REPORT_TYPE
}
];

export const REPORTS_SETTING_CONFIG = 'Report Setting:';

export const reportLevelValues = {
	[REPORTS_CONSTANT.ACTION_LEVEL]: 'Action Level',
	[REPORTS_CONSTANT.INVOICE_LEVEL]: 'Invoice Level'
};

export const ReportSettingConfig = [
	{
		key: 'INVOICE',
		value: 'Invoice Level',
		heading: 'Your report will generate a row for every successful transaction',
		subHeading: 'For instance, date change will have a single row for the net amount'
	},
	{
		key: 'ACTION',
		value: 'Action Level',
		heading: 'Your report will generate a row for every unique debit/credit entry',
		subHeading: 'For instance, date change will have a credit, debit & penalty row'
	}
];
