import React from 'react';
import { ThemeProvider } from 'styled-components';
import loadable from 'react-loadable';
import { Switch, Route } from 'react-router-dom';

const ReportLanding = loadable({
	loader: () => import('./pages/summary/reportsSummary' /* webpackChunkName: 'reportsSummary' */),
	loading: () => null,
	modules: ['reportsSummary']
});

const ReportDetails = loadable({
	loader: () => import('./pages/details/ReportDetails' /* webpackChunkName: 'reportsDetails' */),
	loading: () => null,
	modules: ['reportsDetails']
});

import Theme from 'common/Theme';
import { PATHS } from 'AppConstants';

const MainPage = () => (
	<ThemeProvider theme={Theme}>
		<Switch>
			<Route exact path={PATHS.REPORT_REVAMP} component={ReportLanding} />
			<Route path={`${PATHS.REPORT_DETAIL_VIEW}:reportType`} component={ReportDetails} />
		</Switch>

	</ThemeProvider>
);

export default MainPage;
