// NPM Dependencies
import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

// Styles
import { DropDownArrow } from 'commonStyles';
import { Link, NewTag } from './LinkItemStyles';

// Util Methods
import { logOmniClick } from '../../../logging/omnitureService';

const LinkItem = ({ shouldSelect, selectIndex = false, item, type, blockClick, disabledLinkClick, clickAction }) => {
	const { label, active, component } = item;

	const [activeCss, setActiveCss] = useState(active);

	useEffect(() => {
		setActiveCss(active);
	}, [active]);

	const proceedToSection = () => {
		logOmniClick(item.trackVal, {
			prop54: item.trackVal + '|v2|corporate'
		});
		if (item.active && item.url) {
			window.location.reload();
			return;
		}
		if (blockClick) {
			disabledLinkClick();
			return;
		}
		clickAction(item);
	};

	const { tagConfig = {}, feature = {} } = item;
	const { selected = false } = feature;
	const { left, top, text } = tagConfig;

	/* To render component in case a component is present */
	const RenderComponent = component;

	return (
		<Link type={type} active={activeCss} onClick={proceedToSection} selected={(shouldSelect && selected) || selectIndex}>
			{component ? <RenderComponent /> :
				(
					<span>
						{label}
						{item.options && <DropDownArrow className='arrow' />}
					</span>
				)}
			{tagConfig && !isEmpty(tagConfig) && <NewTag $left={left} $top={top}>{text}</NewTag>}
		</Link>
	);
};

export default LinkItem;
