import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import {
	HelpWrapper,
	HelpSection,
	HelpDropdown,
	RequestShared,
	GenericModalImg,
	OkGotItCta,
	GenericModalDiv,
	PhoneSpan,
	HelpModalWrapper,
	LnBehind,
	WhatsAppFooter
} from './HelpSectionStyles';
import Button from '../Button/Button';
import { ErrorText, FlexDiv, GenericDiv, GenericP, GenericSpan, Heading } from '../Styles';
import { ASSET_PATH } from '../../../assetsPath';
import { logOmniClick } from '../../logging/omnitureService';
import Modal from '../Modal/Modal';
import NeedHelpRadioBtn from './NeedHelpRadioBtn';
import {
	getRequestCallback,
	getCallbackSupport,
	handlingModalOpeningClosing
} from '../../redux/actions/bizHeaderActions';
import Input from '../Input/Input';
import {
	Tilte,
	Subtitle
} from '../Modal/ModalStyles';
import { getProfileDataProps, isValidName, phone } from '../../UtilityComponents/Util';
import { urlMap } from '../../bizHeader/config';
import WhatsAppCallback from 'common/HelpSection/whatsappCallback';
import { whatsappDetails } from './callbackConfig';
import { getAbExperiment } from '../../apis/commonApis';
import { APPLICATION_CONSTANTS } from 'AppConstants';
import useOutsideClick from 'UtilityComponents/customeHooks/useOutsideClick';
import Loader from 'common/Loader/Loader';
import globalData from 'UtilityComponents/globalDataFactory';

const HelpCall = ({
	show = false,
	isHeader = false,
	disablePopUp,
	radioField,
	pathUrl = false,
	popupTitle,
	popupSubTitle,
	showSecondaryBtn = false,
	footerClickHandler,
	showFooter = false,
	showWhatsappLink = true,
	needHelpIn = '',
	isMobile,
	...props
}) => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const profileData = useSelector((state) => state.bizHeader.profileData);
	const isExperimentData = useSelector((state) => state.bizHeader?.isExperimentData);
	const [reqBodyData, setReqBodyData] = useState(() => {
		const { name = '', phone = '' } = profileData;
		return {
			name: name,
			phone: phone,
			needHelpIn
		};
	});

	const [showWhatsAppFooter, setShowWhatsappFooter] = useState(false);

	const [errorMsg, setErrorMsg] = useState({
		phone: '',
		name: ''
	});

	const ModalData = {
		customClass: 'helpModal',
		titleAlign: 'left'
	};

	const getAbDetails = useCallback(() => {
		const abPlatformApi = APPLICATION_CONSTANTS.MYBIZ_CORE;
		getAbExperiment(abPlatformApi).then((res) => {
			const mybizWhatsapp = res && res.mybiz_WA;
			setShowWhatsappFooter(mybizWhatsapp);
		}).catch((err) => {

		});
	}, []);

	const showPopUp = useSelector((state) => state.bizHeader.showPopUp);

	const callbackShared = useSelector((state) => state.bizHeader.callbackShared);
	const expiryTime = useSelector((state) => state.bizHeader.expiryTime);
	const title = useSelector((state) => state.bizHeader.title);
	const subTitle = useSelector((state) => state.bizHeader.text);
	const imageURL = useSelector((state) => state.bizHeader.imageURL);
	const headerClicked = useSelector((state) => state.bizHeader.headerClicked);
	const requestFormPopUp = useSelector((state) => state.bizHeader.requestFormPopUp);
	const showWhatsappPop = useSelector((state) => state.bizHeader.showWhatsappPopup);
	const requestCallBackApiCall = useSelector((state) => state.bizHeader.requestCallBackApiCall);

	const confirmPopUp = useSelector((state) => state.bizHeader.confirmPopUp);

	const isHeaderClicked = (!isHeader || (isHeader && headerClicked));

	const submitModalData = {
		title,
		customClass: 'sample',
		titleAlign: 'left',
		img: imageURL,
		subTitle
	};

	const nodeRef = useRef(null);

	const checkforButtonDisabled = () => {
		const { name, phone, needHelpIn } = reqBodyData;

		const isErrorMsg = errorMsg.phone || errorMsg.name;
		return isErrorMsg || !name || !phone || (!needHelpIn && radioField);
	};

	useOutsideClick(nodeRef, () => {
		closeModal();
	});

	const footerHandler = (e = {}) => {
		e.preventDefault && e.preventDefault();
		closeModal();
		footerClickHandler && footerClickHandler(e);
	};

	const changeHandler = (e, obj) => {
		let value = '';
		let name = '';
		if (e) {
			name = e.target.name;
			value = e.target.value;
		} else if (!e && obj && obj.name === 'needHelpIn') {
			name = obj.name;
			value = obj.value;
		}
		setReqBodyData({
			...reqBodyData,
			[name]: value
		});
	};

	const requestCallback = useCallback(() => {
		const currUrl = window.location.pathname;
		let hashUrl = window.location.hash || '';
		hashUrl = hashUrl && hashUrl.split('?');
		hashUrl = hashUrl ? hashUrl[0] : '';
		let isPage = '';
		for (let i in urlMap) {
			if ((currUrl + hashUrl) === urlMap[i].url) {
				isPage = urlMap[i].pageName;
			}
		}
		reqBodyData.needHelpIn = `${isPage}_${reqBodyData.needHelpIn}`;
		dispatch(getRequestCallback(reqBodyData, pathUrl));
		logOmniClick('MyBiz_Header_Call_Section_Clicked', {
			prop54: 'Requestcallback_submitted',
			eVar34: getProfileDataProps('email')
		});
	}, [reqBodyData]);

	const openSupportPopUp = (headerClicked = false) => () => {
		const payload = {
			requestFormPopUp: true,
			showPopUp: false,
			confirmPopUp: false,
			showWhatsappPopup: false,
			headerClicked
		};
		dispatch(handlingModalOpeningClosing(payload));
	};

	const closeModal = useCallback(() => {
		const payload = {
			requestFormPopUp: false,
			showPopUp: false,
			confirmPopUp: false,
			showCallbackTip: false,
			showWhatsappPopup: false,
			closeModal: false,
			headerClicked: false
		};
		dispatch(handlingModalOpeningClosing(payload));
		disablePopUp && disablePopUp();
	}, [disablePopUp]);

	const openWhatsAppPop = useCallback((e) => {
		e.preventDefault();
		if (isMobile) {
			window.location.replace(APPLICATION_CONSTANTS.WHATS_APP_DEEP_LINK);
		} else {
			const payload = {
				requestFormPopUp: false,
				showPopUp: false,
				showWhatsappPopup: true,
				confirmPopUp: false
			};
			dispatch(handlingModalOpeningClosing(payload));
			logOmniClick('whatsapp_clicked', {
				prop54: 'whatsapp_clicked'
			});
		}
	}, [isMobile]);

	const handlePhoneBlur = (e) => {
		const value = e.target.value;
		let error = '';
		if (e.target.name === 'phone') {
			error = phone(value);
		} else {
			error = isValidName(value);
		}
		setErrorMsg({
			...errorMsg,
			[e.target.name]: error
		});
	};

	useEffect(() => {
		if (showWhatsappLink && !showFooter && isExperimentData) {
			getAbDetails();
		}
	}, [showWhatsappLink, showFooter, isExperimentData]);

	const btnText = requestCallBackApiCall ? (<Loader margin='0' color='#fff' size='9' />) : 'REQUEST A CALL BACK';

	const renderRequestPopup = () => (
		<Modal
			close
			Modaldata={ModalData}
			width='510px'
			btnType='Flat'
			btnName='Primary'
			isMobile={isMobile}
			handleModal={closeModal}>
			<HelpModalWrapper>
				<Heading as='h3' color='#000' f_weight='900' marBt='15px'>
					{popupTitle ? popupTitle : (
						<>
							<GenericSpan fsize='inherit' f_weight='900' color='#f17531'>Need Help?</GenericSpan> We got it all covered!
						</>
					)}
				</Heading>
				<GenericP fsize='12px' color='#4a4a4a'>
					{popupSubTitle ? popupSubTitle : 'We’ll call you in 24 hours once you confirm your details and request a call back!'}
				</GenericP>
				<GenericP bottom_M={15} fsize='12px' color='#4a4a4a'>Alternatively, you can also call us at our 24*7 Customer Care Center Number '1800 103 9695'</GenericP>
				<FlexDiv justify='space-between' directionInMobile='column'>
					<GenericDiv $width={isMobile ? '100%' : '48%'} bottom_M={isMobile ? '15' : ''}>
						<Input
							label='Full Name'
							onChange={changeHandler}
							inputProps={{ name: 'name', onBlur: handlePhoneBlur }}
							value={reqBodyData.name}
							height='44px'
							f_weight={700}
						/>
						{errorMsg.name && <ErrorText>{errorMsg.name}</ErrorText>}
					</GenericDiv>
					<GenericDiv $width={isMobile ? '100%' : '48%'}>
						<GenericDiv position='relative'>
							<PhoneSpan>+91</PhoneSpan>
							<Input
								label='Contact No.'
								name='phone'
								height='44px'
								inputProps={{
									maxLength: '10',
									onBlur: handlePhoneBlur,
									name: 'phone'
								}}
								onChange={changeHandler}
								type='tel'
								value={reqBodyData.phone}
								paddingLeft='40'
								f_weight={700}
							/>
							{errorMsg.phone && <ErrorText>{errorMsg.phone}</ErrorText>}
						</GenericDiv>
					</GenericDiv>
				</FlexDiv>
				{radioField ? (
					<GenericDiv bottom_M='15'>
						<GenericP
							fsize='14px'
							bottom_M='10'
							top_M='25'
							color='#4a4a4a;'
							f_weight='700'> Where do you need our help?
						</GenericP>
						<NeedHelpRadioBtn
							radioField={radioField}
							radioButtonHandler={(obj) =>
								changeHandler(null, {
									value: obj.label,
									name: 'needHelpIn'
								})}
						/>
					</GenericDiv>
				) : null}
				{props.children}
				<FlexDiv justify={showSecondaryBtn ? 'space-between' : 'center'} bottom_M={15}>
					{showSecondaryBtn && (
						<Button
							btnTxt='I DON’T NEED HELP'
							btnName='Secondary'
							btnType='Flat'
							btnWidth='48.5%'
							btnClass='helpButton'
							handleClick={closeModal}
						/>
					)}
					<Button
						btnTxt={btnText}
						btnType='Flat'
						btnName='Primary'
						btnClass='helpButton'
						btnWidth={isMobile ? '100%' : showSecondaryBtn ? '48.5%' : '60%'}
						disableClass='disabledwithLoader'
						disabled={checkforButtonDisabled() || requestCallBackApiCall}
						handleClick={requestCallback}
					/>
				</FlexDiv>
				{showFooter ? (
					<>
						<LnBehind>
							<span>OR</span>
						</LnBehind>
						<GenericP fsize='12px' text_align='center' f_weight='bold'>
							<a href='' role='button' onClick={footerHandler}>Click here</a> to directly setup on your own
						</GenericP>
					</>
				) : null}
				{showWhatsAppFooter ? (
					<>
						<LnBehind>
							<span>OR</span>
						</LnBehind>
						<WhatsAppFooter fsize='12px' text_align='center' f_weight='bold'>
							<img src={ASSET_PATH.IMAGES + 'whatsapp.png'} />
							<GenericSpan valign='middle' fsize='11px' left_P='5' color='#0F9D58'>Our travel experts are also available on Whatsapp. <a href='' role='button' data-type='whatsapp' onClick={openWhatsAppPop}>Click here</a> to chat right now!</GenericSpan>
						</WhatsAppFooter>
					</>
				) : null}

			</HelpModalWrapper>
		</Modal>
	);
	const renderPopups = () =>
		<>
			{isHeader && showPopUp && false &&
				<HelpDropdown ref={nodeRef} className='dropSection'>
					<span className='tipIcon' />
					<GenericP
						fsize='12px'
						bottom_M='15'
						color='#000'
						f_weight='bold'>
						Your dedicated support manager
					</GenericP>
					<GenericP
						fsize='16px'
						bottom_M='9'
						color='#000'
						f_weight='bold'>
						myBiz Support
					</GenericP>

					<Button
						handleClick={openSupportPopUp()}
						btnTxt='REQUEST CALL BACK'
						disabled={callbackShared}
						btnName='Secondary'
						btnType='Capsule'
					/>
					{callbackShared &&
						<RequestShared href='#'>CALL REQUEST SHARED</RequestShared>}
					{!callbackShared ?
						<GenericP top_M='16' color='#9b9b9b' fsize='10px'>
							We’ll call you in{' '}
							<span className='latoBlack'>24 hours</span> once the
							request is placed.
						</GenericP> :
						<GenericP top_M='16' color='#9b9b9b' fsize='10px'>
							You will get a call back in
							{expiryTime &&
								<span className='latoBlack redText'>
									{expiryTime}
								</span>}
						</GenericP>}
				</HelpDropdown>}
			{!confirmPopUp && requestFormPopUp && isHeaderClicked && renderRequestPopup()}
			{confirmPopUp &&
				<Modal
					ref={nodeRef}
					handleModal={closeModal}>
					<GenericModalDiv>
						{submitModalData.img &&
							<GenericModalImg src={submitModalData.img} />}
						{submitModalData.title &&
							<Tilte
								className='modal-title'
								txt_align={submitModalData.titleAlign}>
								{submitModalData.title}
							</Tilte>}
						{submitModalData.subTitle &&
							<Subtitle className='subtitle'>
								{submitModalData.subTitle}
							</Subtitle>}
						<OkGotItCta onClick={closeModal}>
							Ok, Got it
						</OkGotItCta>
					</GenericModalDiv>
				</Modal>}
			{showWhatsappPop && <WhatsAppCallback ref={nodeRef} whatsappDetails={whatsappDetails} closeModal={footerHandler} backHandler={openSupportPopUp()} />}
		</>;

	return (
		<HelpWrapper>
			{isHeader &&
				<HelpSection onClick={openSupportPopUp(true)}>
					<img src={ASSET_PATH.IMAGES + 'help.png'} />
				</HelpSection>}
			{show && renderPopups()}
		</HelpWrapper>
	);
};

export default HelpCall;
