// In order to import util in non typescript extension file 

import cloneDeep from "lodash/cloneDeep";

import { ORGANIZATION_DETAILS_CONSTANT } from "AppConstants";
/**
 * formats currency object
 * @param {String} cCode
 * @param {String} currencyName
 * @return { Object }
 */
export const formatedCurencyField = (cCode, currencyName) => ({
	label: `${cCode} - ${currencyName}`,
	value: cCode,
	cCode,
	currencyName
});

/**
 * formats api key Data for dropdown
 * @param {Object} resp
 * @param {Array} fields
 * @return { Array } formatted prefilled field
 */

export const formatKeyDataFields = (resp, fields = []) => {
	const keysData = resp?.organizationSecretList?.[0];
	const apiKeysData = { client: keysData?.client };
	fields.forEach((fld) => {
		if (fld.name === ORGANIZATION_DETAILS_CONSTANT.CLIENT_KEY && resp?.expenseReportClientConfig) {
			fld.options = resp.expenseReportClientConfig.map((conf) => ({
				label: conf.name,
				value: conf.value
			}));
			fld.value = {
				label: keysData?.client,
				value: keysData?.client
			};
		} else {
			fld.value = keysData?.key?.[fld.name];
			apiKeysData[fld.name] = keysData?.key?.[fld.name];
			fld.hide = fld.name === ORGANIZATION_DETAILS_CONSTANT.EXP_CLIENT_ID && keysData?.client !== ORGANIZATION_DETAILS_CONSTANT.INTERNAL;
		}
	});
	return { clientApiFields: cloneDeep(fields), apiKeysData, apiKeys : false };
};

/**
 * formats currencies Data for dropdown
 * @param {Array} currencies
 * @return { Array } formatted currencies
 */
export const formatCurrencyData = (currencies) => currencies && currencies.map((currency) => formatedCurencyField(currency.shortName, currency.fullName));
