import styled, { css } from 'styled-components';

import { ASSET_PATH } from 'assetsPath';
import { GenericSpan, FlexDiv } from 'commonStyles';

export const SummaryIcons = styled(GenericSpan) `
    background: url(${ASSET_PATH.IMAGES}reports/summary.png) no-repeat;
  	background-size: 250px 250px;
    display: inline-block;
    ${(props) => {
		switch (props.icon) {
			case 'flt':
				return css`
					width: 30px;
					height: 17px;
					background-position: 0 -97px;`;
			case 'htl':
				return css`
					width: 30px;
					height: 23px;
					background-position: -48px -94px;`;
			case 'wlt':
				return css`
					width: 24px;
					height: 16px;
					background-position: -96px -102px;`;
			case 'svgs':
				return css`
					width: 28px;
					height: 21px;
					background-position: -132px -95px;
				`;
			case 'dblArw':
				return css`
					width: 16px;
					height: 24px;
					background-position: -86px -56px;
				`;
			case 'arw':
				return css`
					width: 16px;
					height: 24px;
					background-position: -115px -56px;
				`;
			case 'dwld':
				return css`
					width: 20px;
					height: 21px;
					background-position: -214px 0;
				`;
			case 'gstIcon':
				return css `
						width: 46px;
						height: 46px;
						background-position: -70px 0;
						margin-right:12px;`;
			case 'gstTickIcon':
				return css`
						width: 50px;
						height: 50px;
						background-position:-135px 0;
						margin-right:12px;
						`;
			case 'fileProcess':
				return css`
						width: 52px;
						height: 48px;
						display: inline-block;
						background-position: -195px -33px;
						margin-right:12px;
						`;
			case 'gstReport':
				return css`
						width: 107px;
						height: 70px;
						display:inline-block;
						background-position: 0 -130px;
						position: absolute;
						top:26px;
						right: 26px;
						`;
			case 'reportAddIcon':
				return css`
						width: 50px;
						height: 50px;
						display: inline-block;
						background-position: 0  0;
						margin-bottom:12px;
						`;
			case 'faqsIcon':
				return css`
						width: 16px;
						height: 16px;
						display: inline-block;
						background-position: -33px -64px;
						margin-right:8px;
						`;
			case 'addIcon':
				return css`
						width: 16px;
						height: 16px;
						display: inline-block;
						background-position: 0px -64px;
						margin-right:8px;
						`;
			case 'ic_bus':
				return css`
						background-position: -214px -87px;
						width: 20px;
						height: 20px;
		 `;
			case 'ic_train':
				return css`
						background-position: -94px -79px;
						width: 18px;
						height: 23px;
		 `;
			case 'ic_cab':
				return css`
						background-position: -214px -109px;
						width: 18px;
						height: 16px;`;
			case 'ic_savings':
				return css`
						background-position: -58px -207px;
						width: 29px;
						height: 23px;`;
			case 'ic_missedSavings':
				return css`
						background-position: -91px -207px;
						width: 35px;
						height: 23px;`;
			case 'ic_flighttPnr':
				return css`
						background-position: -62px -232px;
						width: 23px;
						height: 18px;`;
			case 'folder':
				return css`
						width: 107px;
						height: 90px;
						display:inline-block;
						background-position: -132px -130px;
						position: absolute;
						top:26px;
						right: 26px;
						`;
			default:
				return css`
					width: 24px;
					height: 24px;
					background-position: -105px -5px;`;
		}
	}}
`;

export const CreateNewReportListSec = styled.ul(
	({ theme: { down, breakpoints } }) => `
	display: flex;
	${down(breakpoints.md)} {
		flex-direction: column;
	}
			`
);

export const FaqsListLI = styled.li`
	margin-bottom:8px;
	position: relative;
	font-size:12px;
	& .greenText {
		color:#219393;
	}
	&:before{
		content:'';
		width: 4px;
		height: 9px;
		background-color: #F17531;
		position: absolute;
		left: -16px;
		top:3px;
	}
`;

export const CreateNewReportList = styled.li(
	({ theme: { down, breakpoints = {} }, mar_L = '' }) => `
			width: 345px;
			height: 130px;
			background: #FFFFFF;
			border: 1px solid #DDDDDD;
			border-radius: 5px;
			margin-left: ${mar_L ? '15px' : ''};
			padding: 16px;
			${down(breakpoints.md)} {
				width: 100%;
				margin-bottom: 15px;
			}
			`
);

export const LobReportWrapper = styled.div(
	({ theme: { down, breakpoints = {} } }) => `
			width: 223px;
			height: 130px;
			background: #FFFFFF;
			border: 1px solid #DDDDDD;
			border-radius: 5px;
			margin-left: 15px;
			padding: 0px;
			margin-bottom: 15px; 
			position:relative;
			&.zeroM{
				margin-left: 0px;
			}
			${down(breakpoints.md)} {
				width: 100%;
				margin-left: 0;
			}
			`
);

export const TopCard = styled.div`
	padding: 15px;
	border-bottom: 1px solid #ddd;
	border-radius: 4px 4px 0px 0px;
	height: 72px;
	&.DAILY{
		background: #FEF5E8;
	}
	&.WEEKLY{
		background: #FFF6F7;
	}
	&.QUARTERLY{
		background: #F8FFFF;
	}
	&.MONTHLY{
		background: #F7FBFF;
	}
`;

export const TagName = styled.span`
	border-radius: 4px;
	padding:1px 6px;
	margin-left:10px;
	text-transform:uppercase;
	font-weight:700;
	font-size:10px
	&.DAILY{
		background: #FEF5E8;
		color:#E08E17;
	}
	&.WEEKLY{
		background: #FFECEF;
		color:#FF7527;
	}
	&.QUARTERLY{
		background: #EAF9F8;
		color:#28BFB1;
	}
	&.MONTHLY{
		background: #E9F3FD;
		color:#008CFF;
	}
`;

export const CustomReportWrapper = styled(FlexDiv)(
	({ showmore, theme: { down, breakpoints = {} } }) => `
		padding: 8px 2px; 
		height: ${showmore ? '340' : '150'}px;
		position: relative;
		overflow-y: ${showmore ? 'scroll' : 'unset'};
		${down(breakpoints.md)} {
			height: 400px;
		}
		`
);

export const Disc = styled.span`
	display: inline-block;
	border: 2px solid;
	border-radius: 50%;
	vertical-align: middle;
	margin: 0 4px;
`;

export const ListWrapper = styled.div`
	font-size: 10px;
	padding: 7px 10px;
`;

export const ReportTopCard = styled(FlexDiv) `
	padding: 18px;
	position: relative;
`;
export const GstTextTitle = styled.p`
	font-size:18px;
	color:#000;
	margin-bottom:5px;
	font-weight: 900
	& .ready{
		color:#219393;
	}
	& .process{
		color: #FF604F;
	}
`;

export const MultiSelectReports = styled.div`
  border: 1px solid #ddd;
  padding: 15px;
	border-radius: 4px;
	margin-top: 10px;
`;

export const CheckboxWrapper = styled.div`
	background: #FFFFFF;
	box-shadow: 0px 1px 4px rgb(0 0 0 / 20%);
	border-radius: 2px;
	padding: 6px 6px 0px 6px;
	margin-right: 13px;
	margin-bottom: 13px;
`;

export const ReportType = styled.span`

    padding:13px 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-right:10px;
    display:inline-block;
    cursor:pointer;
    &.active{
      background: #FF664B;
      color:#fff
    }
`;

export const DropDownWrapper = styled.div`
	& .custom-styles-select__control {
		border: solid 1px #cacaca;
		height: 60px;
		padding-left: 16px;
	}
	& .custom-styles-select__dropdown-indicator {
		display: none;
	}
	& .custom-styles-select__placeholder, & .custom-styles-select__single-value {
		left: 25px;
	}
	& .custom-styles-select__value-container{
    height: 60px;
	}
`;

export const OtherInfo = styled.p`
  font-size:12px;
  border-top:1px solid #ddd;
  padding-top:27px;
	margin-top:10px;
	text-align: center;
`;

export const CreateNewWrapper = styled(FlexDiv)`
	>span{
		cursor: pointer;
	}
`;
