import { GST_CONSTANTS } from 'AppConstants';
/**
 * A location object
 *  @author MMT8270
 * @typedef {Object} requestObject
 * @property {Object} gst - gst detail object
 * @property {string} action - action for gst update
 */

/**
 * gst request creation for gst udpate
 * @author mmt8270
 * @param { requestObject } request object
 * @return { Object } gst object to be udpated
 */
export const formatGstRequest = ({ gst, action }) => {
	if (action === GST_CONSTANTS.DELETE) {
		gst.enabled = false;
	} else if (action == GST_CONSTANTS.MARK_HEADQTR) {
		gst.isHeadQuarter = true;
	}
	return { gst: { ...gst } };
};

/**
 * prefill gst form fields with data
 * @author mmt8270
 * @param { Array } fields array to be rendered on UI
 * @param { Object } data object to be updated
 * @return { Array } fields with prefilled data
 */
export const prefillFields = (fields = [], data) => {
	fields.map((fld) =>{
		if (fld.name === 'gstn') {
			fld.isDisabled = typeof data[fld.name] != 'undefined';
		}
		fld.noBlurChange = typeof data[fld.name] != 'undefined';
		fld.value = data[fld.name] || '';
	});
	return fields;
};

/**
 * format gst data to save in redux
 * @author mmt8270
 * @param { Object } gstData array to be rendered on UI
 * @return { Object } fields with prefilled data
 */
export const formatGstData = (gstData) => {
	let data = {};
	if (!gstData) {
		return data;
	}
	const { gstn, city, address1, address2, pinCode, isHeadQuarter, enabled } = gstData;
	data = { gstn, city, address1, address2, pinCode, isHeadQuarter, enabled };
	return data;
};
