import styled, { css } from 'styled-components';
import { ASSET_PATH } from '../../../assetsPath';

export const DropDownWrapper = styled.div`

${({ borderLess }) => borderLess && `
	& .InnerSelct__control{
		border: none;
	}
	.InnerSelct__multi-value__remove, .InnerSelct__indicator {
		color: #f17531
	}
`}


${({ borderLess }) => !borderLess && `
	& .InnerSelct__control:hover{
    border-color:#f17531;
    box-shadow: 0px 1px 2px #f17531;
	}
`}
	
& .InnerSelct__value-container {
    padding: ${(props) => props.padding ? props.padding : '2px 3px'};
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

}
& .InnerSelct__menu{
    z-index:2;
}

& .InnerSelct__option {
    background:#fff !important;
    border-bottom :${(props) => props.border_B ? props.border_B : ''};
    font-size:${(props) => props.f_Size ? props.f_Size : '12px'};
    padding: ${(props) => props.padding ? props.padding : ''};
		color: #4a4a4a;
}
& .InnerSelct__option:hover {
    background:rgba(241, 117, 49, 0.1) !important;
}
& .InnerSelct__control.css-1pahdxg-control{
     border-color:#f17531;
     box-shadow: 0px 1px 2px #f17531;
 }
& .InnerSelct__control--menu-is-open{
    border-color:#f17531;
    box-shadow: none;
}
& .InnerSelct__option--is-selected{
    background:rgba(241, 117, 49, 0.1) !important;
    color:#000;
}

& .InnerSelct__option--is-focused{
    background:rgba(241, 117, 49, 0.1) !important;
}

.InnerSelct__multi-value__remove, .InnerSelct__indicator {
		padding: 4px 0 !important;
}
.InnerSelct__indicator-separator {
    display: none;
}
.manageBookings .InnerSelct__placeholder{
		margin-left: 12px;
}

.InnerSelct__option--is-selected {
    background: none !important;
    color: #4a4a4a;
    &:after {
        width: 10px;
        height: 10px;
        content: "";
        display: inline-block;
        background: url(${ASSET_PATH.IMAGES}InvoiceSprite1.png) no-repeat;
        background-size: 150px 100px;
        background-position: 0 -65px;
        position: relative;
        left: 4px;
        top: 1px;
    }
}
& .InnerSelct__multi-value__remove:hover{
    background:none;
}

& .InnerSelct__multi-value{   
    background: #f2f2f2;
    border-radius: 4px;
    margin-left: 2px;
    display: flex;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
}
`;
