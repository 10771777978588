import styled, { css } from 'styled-components';
import { FlexDiv } from '../../../common/Styles';

export const ProfileMenuItem = styled(FlexDiv)`
    position: relative;
    height: 100%;
    max-width: 170px;
    .dropdown-content {
        display: none;
    }
    &:hover {
        .dropdown-content {
            display: block
        }
        .arrow {
            transform: rotate(225deg);
            top: 4px;
        }
    }
   
`;

export const TextDetails = styled(FlexDiv)`
    position: relative;
		cursor: pointer;
`;

export const LoggedInUserAnchor = styled.span`
    cursor: pointer;
    color: #4a4a4a;
    font-size: 10px;
    display: flex;
    & .profileName{
        min-width: 22px;
        max-height: 22px;
      color: #fff;
      background-image: linear-gradient(49deg,#f0772c -5%,#f95776 103%);
      font-size: 10px;
      font-weight: 900;
      border-radius: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;
    }
`;
export const LoginUserIcon = styled.span`
    background-position: -94px 0 !important;
    width: 13px;
    height: 12px;
    margin-right: 5px;
    
    display: inline-block;
`;

export const UserName = styled.span`
		width: 90px;
`;
