import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import { APPLICATION_CONSTANTS, EMPLOYEE_CONSTANTS } from 'AppConstants';
import { NEW_BULK_UPLD_EMP, inviteChannelsFields, ValidInternationalPhone, INDIA_FIELDS_CONSTANT, OTHER_COUNTRIES_FIELDS_CONSTANT } from './config';
import { validPhone } from 'UtilityComponents/Util';

export const formatEmployeeData = (data = [], isAllSelected) => {
	const selectedList = {};
	if (data.length > 0 && isAllSelected) {
		data.forEach((emp) => {
			emp.checked = true;
			selectedList[emp.employeeEmailCommId] = true;
		});
	}
	return {
		list: data,
		selectedList
	};
};

export const handleSelected = (selectedList = {}, payload = {}) => {
	const { items = [], checked } = payload;
	items.forEach((item) => {
		if (checked) {
			item.checked = true;
			selectedList[item.employeeEmailCommId] = true;
		} else {
			item.checked = false;
			delete selectedList[item.employeeEmailCommId];
		}
	});
	return { selectedList: items.length === 0 ? {} : { ...selectedList }, itemList: [...items] };
};

const setOptionsCount = (countData) => (option) => option.count = get(countData, option.countKey);

export const formatFilterCounts = (countData, filters) => {
	let filterData = filters;
	if (countData) {
		const setCount = setOptionsCount(countData);
		filterData = cloneDeep(filters);
		filterData[EMPLOYEE_CONSTANTS.ROLE_KEY].options.forEach(setCount);
		filterData[EMPLOYEE_CONSTANTS.TYPE_KEY].options.forEach(setCount);
		filterData[EMPLOYEE_CONSTANTS.STATE_KEY].options.forEach(setCount);
	}
	return filterData;
};

export const formatFiltersOptions = (filters, filterKey, optionsData) => {
	let filterData = filters;
	if (optionsData) {
		filterData = cloneDeep(filterData);
		filterData[filterKey].options = optionsData;
	}
	return filterData;
};

export const formatManagerData = (employees) => employees.map((employee) => {
	const { businessEmailId, name } = employee;
	return ({
		value: businessEmailId,
		label: name,
		subText: ` (${businessEmailId})`
	});
});

export const formatGroupData = (groups = []) => groups.map((group) => {
	const { groupId, groupName, empCount } = group;
	return ({
		value: groupId,
		label: groupName,
		empCount
	});
});

export const formatDesignationData = (designations) => designations.map((item) => ({
	value: item,
	label: item
}));

const setDropDownQuery = (query, filter, queryValue = []) => {
	const key = filter.key;
	const updatedQuery = cloneDeep(query);
	const updatedFilter = { ...filter };

	if (queryValue && queryValue.length > 0) {
		updatedQuery[key] = queryValue.map((item) => item.value);
	} else {
		updatedQuery[key] && delete updatedQuery[key];
	}
	updatedFilter.showClear = updatedQuery[key] && updatedQuery[key].length > 0;
	return { updatedQuery, updatedFilter };
};

const setCheckboxQuery = (query, filter, queryOption = {}) => {
	const { key, singleSelect = false } = filter;
	const updatedQuery = cloneDeep(query);
	const { checked, value } = queryOption;
	const updatedFilter = { ...filter };

	if (checked) {
		updatedQuery[key] = updatedQuery[key] && Array.isArray(updatedQuery[key]) && !singleSelect ?
			[...updatedQuery[key], value] :
			[value];
	} else {
		updatedQuery[key] = updatedQuery[key].filter((item) => item !== value);
		if (updatedQuery[key].length === 0) delete updatedQuery[key];
	}

	updatedFilter.showClear = (!updatedFilter.hideClear) && updatedQuery[key] && updatedQuery[key].length > 0;
	return { updatedQuery, updatedFilter };
};

export const getFilterQuery = (query, filter = {}, queryData = {}) => {
	const key = filter.key;
	const { value: queryValue, option: queryOption } = queryData;
	switch (key) {
		case EMPLOYEE_CONSTANTS.GROUPS_KEY:
		case EMPLOYEE_CONSTANTS.MANAGER_KEY:
		case EMPLOYEE_CONSTANTS.DESIGNATION_KEY: {
			return setDropDownQuery(query, filter, queryValue);
		}
		case EMPLOYEE_CONSTANTS.ROLE_KEY:
		case EMPLOYEE_CONSTANTS.TYPE_KEY:
		case EMPLOYEE_CONSTANTS.STATE_KEY: {
			return setCheckboxQuery(query, filter, queryOption);
		}
		default: {
			return query;
		}
	}
};

export const clearFilterNQuery = (filters, query, clearedFilter = {}) => {
	const key = clearedFilter.key;
	const updatedQuery = cloneDeep(query);
	const updatedFilters = cloneDeep(filters);
	updatedFilters[key] = { ...clearedFilter, showClear: false };
	updatedQuery[key] && delete updatedQuery[key];

	return {
		updatedQuery,
		updatedFilters
	};
};

export const getUpdatedSortQuery = (sortQuery, sortParam = {}) => {
	const updatedSortQuery = cloneDeep(sortQuery);
	updatedSortQuery.sort = sortParam;
	return updatedSortQuery;
};

export const prefillData = (inputFields = [], modifyData = {}, removableFields ) => {
	const fieldLen = inputFields.length;
	const empName = modifyData.name || '';
	const [fname, ...lname] = empName.split(' ');
	const groupVal = modifyData.groupDetails ? { value: modifyData.groupDetails.groupId, label: modifyData.groupDetails.groupName } :
		{ value: parseInt(APPLICATION_CONSTANTS.ORGANIZATION_LEVEL_GROUP_ID), label: APPLICATION_CONSTANTS.ORGANIZATION_LEVEL_GROUP_NAME };
	const grpId = groupVal.value;
	for (let i = 0; i < fieldLen; i++) {
		switch (inputFields[i].name) {
			case 'groupId':
				inputFields[i].value = inputFields[i].options.length > 0 ?
					inputFields[i].options.filter((grp) => grp.value == grpId)[0] || groupVal : groupVal;
				break;
			case 'fname':
				inputFields[i].value = fname;
				break;
			case 'gender':
				inputFields[i].value = inputFields[i].options.filter((el) => el.value === modifyData.gender)[0];
				break;
			case 'lname':
				inputFields[i].value = lname?.join(' ');
				break;
			case 'emailId':
				inputFields[i].value = modifyData.emailId;
				break;
			case 'mobileNo':
				inputFields[i].value = modifyData.mobileNo;
				inputFields[i].countryCode = modifyData.countryCode || APPLICATION_CONSTANTS.INDIA_COUNTRY_CODE;
				inputFields[i].maxLen = inputFields[i].countryCode === APPLICATION_CONSTANTS.INDIA_COUNTRY_CODE ?
					INDIA_FIELDS_CONSTANT.maxLen : OTHER_COUNTRIES_FIELDS_CONSTANT.maxLen;
				inputFields[i].validation.custom = inputFields[i].countryCode === APPLICATION_CONSTANTS.INDIA_COUNTRY_CODE ?
					INDIA_FIELDS_CONSTANT.customValidation : OTHER_COUNTRIES_FIELDS_CONSTANT.customValidation;
				break;
			default:
				break;
		}
	}
	const removableLen = removableFields.length;
	const removable = [];
	let removeCount = 0;
	for (let i = 0; i < removableLen; i++) {
		const name = removableFields[i] && removableFields[i].name;
		switch (name) {
			case 'dpt':
			case 'empId':
			case 'cc':
				if (modifyData[name]) {
					removeCount++;
					removableFields[i].set = modifyData[name] ? true : false;
					removableFields[i].value = modifyData[name];
					removableFields[i].set && removable.push(removableFields[i]);
				}

				break;
			default:
				break;
		}
	}
	if (removeCount) {
		inputFields.push({
			name: 'cd',
			label: 'Custom details',
			type: 'label',
			outLabel: true,
			customClass: 'mb0'
		});
	}
	return {
		rCount: removeCount,
		fields: [...inputFields, ...removable],
		updatedRemovable: removableFields
	};
};
export const getReminderSettings = (setting = {}) => ({
	frequency: setting.frequency,
	granular: setting.is_granular,
	granularDay: setting.granular_data.on_day,
	granularPeriod: setting.granular_data.every_period,
	enable: setting.is_enable
});

export const getCsvBulkUploadData = ({ fileData, sendInvitation, invitedId }) => {
	const fileBinary = fileData.fileBlob;
	const sendData = new FormData();
	const alertNewUser = sendInvitation && invitedId === NEW_BULK_UPLD_EMP;
	sendData.append('file', fileBinary);
	sendInvitation && sendData.append('alertNewUserOnly', alertNewUser);
	return sendData;
};

/**
 * formats invitation channel response as per rendering fields data
 * @param {Array} channels
 * @return {Object} fields
 */
export const formatInvitationChannels = (channels = []) => {
	const fields = cloneDeep(inviteChannelsFields);
	channels.map((channel) => {
		let ch = channel.toLowerCase();
		fields[ch].isChecked = true;
	});
	return fields;
};

/**
 * formats invitation Channel data as per api request
 * @param {Object} channelsFieldsConf
 * @return {Object} invitationChannels
 */
export const formatChannelRequest = (channelsFieldsConf) => {
	const channelConfig = Object.values(channelsFieldsConf);
	let channels = [];
	channelConfig && channelConfig.map((channel) => {
		const { isChecked, value } = channel;
		if (isChecked) {
			channels.push(value);
		}
	});
	const invitationChannels = {
		invitationChannels: channels
	};
	return invitationChannels;
};

/**
 * Formatted channels Data as per UI
 * @param {Array} invitationChannels
 * @return {Object}
 */
export const inviteChannelCTA = (invitationChannels) => {
	let inviteBtnCta = 'Invite via ';
	let channelText = 'The invitation to be shared via ';
	if (invitationChannels && invitationChannels.length > 0) {
		let len = invitationChannels.length - 1;
		for (let i = 0; i <= len; i++) {
			inviteBtnCta += ' ' + invitationChannels[i];
			channelText += `<span style=font-weight:700>${invitationChannels[i]}</span>`;
			let seperator = '';
			if (i == len - 1) {
				seperator = ' & ';
			} else if (i != len) {
				seperator = ', ';
			}
			inviteBtnCta += seperator;
			channelText += seperator;
		}
	}
	return { inviteBtnCta, channelText };
};
