import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { APPLICATION_CONSTANTS, FILTER_CONSTANTS, LOB_TYPES, MRB_CONSTANTS } from 'AppConstants';
import Location from './BookingsData/listing/location';
import Action from './BookingsData/listing/action';
import DateData from './BookingsData/listing/dateData';
import { LobIcons } from './BookingsData/listing/listingStyles';
import { GenericSpan } from 'commonStyles';

export const pageApiConfigs = {
	limit: MRB_CONSTANTS.PAGE_LIMIT,
	page: 1
};

export const SEARCH_SECTION_CONFIG = {
	heading: 'Manage Requests & Bookings',
	subHeading: 'Showing Results for',
	placeHolderTxt: 'Search for Traveller Name, Email ID or Booking ID',
	btnTxt: 'Search'
};

export const filterTypes = {
	'date': 'date',
	'location': 'location',
	'approver': 'approver',
	'customLocation': 'customLocation'
};

export const MRB_FILTERS_CONSTANTS = {
	POLICY_TYPE: 'POLICY_TYPE',
	APPROVER: 'APPROVER',
	DATE_OF_REQUEST: 'DATE_OF_REQUEST',
	DATE_OF_TRAVEL: 'DATE_OF_TRAVEL',
	TRIP_TYPE: 'TRIP_TYPE',
	STAY_LOCATION: 'STAY_LOCATION',
	TRAVEL_LOCATIONS: 'TRAVEL_LOCATIONS',
	IN_POLICY: 'IN_POLICY',
	OUT_POLICY: 'OO_POLICY',
	FLIGHT_TYPE: 'FLIGHT',
	HOTEL_TYPE: 'HOTEL',
	CAB_TYPE: 'CABS',
	BUS_TYPE: 'BUS',
	FLIGHT_MOD_TYPE: 'FLIGHT_MOD',
	REQUISTION_TYPE: 'REQUISITION',
	TRAVEL_AGE: 'TRAVEL_AGE',
	DATE_OF_BOOKING: 'DATE_OF_BOOKING'
};

export const ORG_ADMIN = {
	'id': 'ORG_ADMIN',
	'label': 'Organisation Admin',
	'name': 'Organisation Admin',
	'value': 'ADMIN'
};

export const MRB_FILTER_CONFIG = {
	[MRB_FILTERS_CONSTANTS.IN_POLICY]: {
		label: 'In Policy',
		value: 1,
		countKey: 'inPolicy'
	},
	[MRB_FILTERS_CONSTANTS.OUT_POLICY]: {
		label: 'Out of Policy',
		value: 0,
		countKey: 'outPolicy'
	},
	[MRB_FILTERS_CONSTANTS.FLIGHT_TYPE]: {
		label: 'Flight',
		value: MRB_FILTERS_CONSTANTS.FLIGHT_TYPE,
		countKey: MRB_FILTERS_CONSTANTS.FLIGHT_TYPE
	},
	[MRB_FILTERS_CONSTANTS.HOTEL_TYPE]: {
		label: 'Hotel',
		value: MRB_FILTERS_CONSTANTS.HOTEL_TYPE,
		countKey: MRB_FILTERS_CONSTANTS.HOTEL_TYPE
	},
	[MRB_FILTERS_CONSTANTS.CAB_TYPE]: {
		label: 'Cab',
		value: MRB_FILTERS_CONSTANTS.CAB_TYPE,
		countKey: MRB_FILTERS_CONSTANTS.CAB_TYPE
	},
	[MRB_FILTERS_CONSTANTS.BUS_TYPE]: {
		label: 'Bus',
		value: MRB_FILTERS_CONSTANTS.BUS_TYPE,
		countKey: MRB_FILTERS_CONSTANTS.BUS_TYPE
	},
	[MRB_FILTERS_CONSTANTS.REQUISTION_TYPE]: {
		label: 'Trip Request',
		value: MRB_FILTERS_CONSTANTS.REQUISTION_TYPE,
		countKey: MRB_FILTERS_CONSTANTS.REQUISTION_TYPE
	},
	[MRB_FILTERS_CONSTANTS.FLIGHT_MOD_TYPE]: {
		label: 'Flight Modification',
		value: MRB_FILTERS_CONSTANTS.FLIGHT_MOD_TYPE,
		countKey: MRB_FILTERS_CONSTANTS.FLIGHT_MOD_TYPE
	}
};

const today = cloneDeep(APPLICATION_CONSTANTS.TODAY);
export const filters = {
	[MRB_FILTERS_CONSTANTS.POLICY_TYPE]: {
		label: 'Policy Type',
		type: FILTER_CONSTANTS.CHECKBOX,
		key: MRB_CONSTANTS.POLICY,
		filterType: MRB_CONSTANTS.POLICY,
		value: '',
		hideClear: false,
		singleSelect: false,
		selectedCount: 0,
		trackVal: '',
		options: [{
			value: 1,
			countKey: 'inPolicy',
			label: 'In Policy',
			checked: false,
			filterKey: MRB_CONSTANTS.POLICY,
			count: '',
			trackVal: '',
			index: 0
		},
		{
			value: 0,
			countKey: 'outPolicy',
			label: 'Out of Policy',
			checked: false,
			filterKey: MRB_CONSTANTS.POLICY,
			count: '',
			trackVal: '',
			index: 0
		}]
	},
	[MRB_FILTERS_CONSTANTS.APPROVER]: {
		label: 'Approver',
		key: MRB_CONSTANTS.APPROVER,
		filterType: filterTypes.approver,
		isGrouped: true,
		type: FILTER_CONSTANTS.DROPDOWN,
		isMulti: true,
		value: [],
		selectedCount: 0,
		dynamic: true,
		placeholder: 'Search by Approver Email ID',
		selectedCount: 0,
		trackVal: '',
		options: [{ ...ORG_ADMIN }],
		hideIndicator: true,
		className: 'manageBookings'
	},
	[MRB_FILTERS_CONSTANTS.DATE_OF_REQUEST]: {
		label: 'Date of Request',
		type: FILTER_CONSTANTS.CHECKBOX,
		startKey: 'startDate',
		endKey: 'endDate',
		value: '',
		filterType: filterTypes.date,
		key: FILTER_CONSTANTS.DATE_FILTER,
		hideClear: false,
		selectedCount: 0,
		singleSelect: true,
		options: [],
		trackVal: ''
	},
	[MRB_FILTERS_CONSTANTS.DATE_OF_BOOKING]: {
		label: 'Date of Booking',
		type: FILTER_CONSTANTS.CHECKBOX,
		startKey: 'startDate',
		endKey: 'endDate',
		value: '',
		filterType: filterTypes.date,
		key: FILTER_CONSTANTS.DATE_FILTER,
		hideClear: false,
		selectedCount: 0,
		singleSelect: true,
		options: [{
			label: 'Your Dates',
			type: 'datePicker',
			value: '',
			trackVal: '',
			checked: false,
			text: 'selected dates',
			countKey: '',
			key: FILTER_CONSTANTS.DATE_FILTER,
			filterKey: FILTER_CONSTANTS.DATE_FILTER,
			disabledDays: {
				after: APPLICATION_CONSTANTS.TODAY,
				before: new Date(today.setDate(today.getDate() + 90))
			}
		}]
	},
	[MRB_FILTERS_CONSTANTS.TRAVEL_AGE]: {
		label: 'Age of Request',
		type: FILTER_CONSTANTS.CHECKBOX,
		startKey: 'startHour',
		endKey: 'endHour',
		value: '',
		filterType: filterTypes.date,
		key: FILTER_CONSTANTS.DATE_FILTER,
		hideClear: false,
		selectedCount: 0,
		singleSelect: true,
		options: [{
			label: 'Less than 3 hours',
			start: 0,
			end: 2
		},
		{
			label: '3- 12 hours',
			start: 3,
			end: 12
		},
		{
			label: 'Greater than 12 hours',
			start: 13
		}]
	},
	[MRB_FILTERS_CONSTANTS.DATE_OF_TRAVEL]: {
		label: 'Date of Travel',
		type: FILTER_CONSTANTS.CHECKBOX,
		startKey: 'fromTravelDate',
		endKey: 'toTravelDate',
		value: '',
		filterType: filterTypes.date,
		key: FILTER_CONSTANTS.DATE_FILTER,
		hideClear: false,
		selectedCount: 0,
		singleSelect: true,
		options: [{
			label: 'Your Dates',
			type: 'datePicker',
			value: '',
			trackVal: '',
			checked: false,
			text: 'selected dates',
			countKey: '',
			key: FILTER_CONSTANTS.DATE_FILTER,
			filterKey: FILTER_CONSTANTS.DATE_FILTER,
			disabledDays: {
				before: APPLICATION_CONSTANTS.TODAY
			}
		}]
	},
	[MRB_FILTERS_CONSTANTS.TRIP_TYPE]: {
		label: 'Trip Type',
		type: FILTER_CONSTANTS.CHECKBOX,
		key: MRB_CONSTANTS.TRIP_TYPE,
		filterType: MRB_CONSTANTS.TRIP_TYPE,
		value: '',
		hideClear: false,
		singleSelect: false,
		selectedCount: 0,
		trackVal: '',
		options: [{
			...MRB_FILTER_CONFIG[MRB_FILTERS_CONSTANTS.FLIGHT_TYPE],
			checked: false,
			filterKey: MRB_CONSTANTS.TRIP_TYPE,
			count: 0,
			trackVal: '',
			index: 0
		},
		{
			...MRB_FILTER_CONFIG[MRB_FILTERS_CONSTANTS.FLIGHT_MOD_TYPE],
			checked: false,
			filterKey: MRB_CONSTANTS.TRIP_TYPE,
			count: 0,
			trackVal: '',
			index: 1
		},
		{
			...MRB_FILTER_CONFIG[MRB_FILTERS_CONSTANTS.HOTEL_TYPE],
			checked: false,
			filterKey: MRB_CONSTANTS.TRIP_TYPE,
			count: 0,
			trackVal: '',
			index: 2
		},
		{
			...MRB_FILTER_CONFIG[MRB_FILTERS_CONSTANTS.CAB_TYPE],
			checked: false,
			filterKey: MRB_CONSTANTS.TRIP_TYPE,
			count: 0,
			trackVal: '',
			index: 3
		},
		{
			...MRB_FILTER_CONFIG[MRB_FILTERS_CONSTANTS.BUS_TYPE],
			checked: false,
			filterKey: MRB_CONSTANTS.TRIP_TYPE,
			count: 0,
			trackVal: '',
			index: 4
		},
		{
			...MRB_FILTER_CONFIG[MRB_FILTERS_CONSTANTS.REQUISTION_TYPE],
			checked: false,
			filterKey: MRB_CONSTANTS.TRIP_TYPE,
			count: 0,
			trackVal: '',
			index: 5
		}
		]
	}
};

export const destinationField = {
	name: '',
	label: '',
	type: 'text',
	outLabel: true,
	customClass: 'col-12',
	label: 'Stay Location',
	key: 'location',
	filterType: 'location',
	isGrouped: true,
	value: '',
	selectedCount: 0,
	dynamic: true,
	noBlurChange: true,
	placeHolder: 'Search by property city',
	singleSelect: true,
	selectedCount: 0,
	trackVal: '',
	options: []
};

export const fromDestinationField = {
	name: 'source',
	label: '',
	type: 'text',
	customClass: 'col-6',
	key: 'location',
	filterType: 'customLocation',
	value: '',
	dynamic: true,
	noBlurChange: true,
	placeHolder: 'From',
	singleSelect: true,
	selectedCount: 0,
	trackVal: ''
};
export const toDestinationField = {
	name: 'destination',
	label: '',
	type: 'text',
	customClass: 'col-6',
	label: '',
	key: 'location',
	filterType: 'customLocation',
	value: '',
	dynamic: true,
	noBlurChange: true,
	placeHolder: 'To',
	singleSelect: true,
	trackVal: ''
};

export const customLocation = {
	key: 'location',
	filterType: 'customLocation',
	options: [fromDestinationField, toDestinationField]
};

export const dateFilterConfig = {
	value: '',
	alwaysEnable: true,
	countKey: '',
	label: '',
	count: '',
	filterKey: FILTER_CONSTANTS.DATE_FILTER,
	start: '',
	end: '',
	index: '',
	checked: false,
	trackVal: ''
};

export const TRAVEL_REQUEST_CONFIG = {
	[MRB_FILTERS_CONSTANTS.DATE_OF_REQUEST]: [
		{
			label: 'Today',
			startDay: 0
		},
		{
			label: 'Yesterday',
			startDay: 1,
			endDay: 1
		},
		{
			label: 'Last 7 days',
			startDay: 7
		}
	],
	[MRB_FILTERS_CONSTANTS.DATE_OF_BOOKING]: [
		{
			label: 'Last 7 days',
			startDay: 7,
			endDay: 0
		},
		{
			label: 'Last 30 days',
			startDay: 30,
			endDay: 0
		}
	],
	[MRB_FILTERS_CONSTANTS.DATE_OF_TRAVEL]: [
		{
			label: 'Tomorrow',
			startDay: 1,
			endDay: 1
		},
		{
			label: 'Next Seven Days',
			endDay: 7
		}
	]
};

export const DATE_OF_TRAVEL_CONFIG = [{
	label: 'Tomorrow',
	startDay: 1,
	endDay: 1
},
{
	label: 'Next Seven Days',
	endDay: 7
}
];

export const TabList = [
	{
		key: MRB_CONSTANTS.PENDING_REQUEST_TYPE,
		value: 'PENDING REQUESTS',
		count: ''
	},
	{
		key: MRB_CONSTANTS.UPCOMING_REQUEST_TYPE,
		value: 'ALL BOOKINGS',
		count: ''
	}
];

export const TABLE_PROPS = {
	container: {
		classes: { root: 'report_table' }
	},
	table: {
		stickyHeader: true
	},
	bodyRowChild: {
		classes: {
			root: 'report_table'
		}
	}
};

export const ICON_MAP = {
	[LOB_TYPES.FLIGHT]: 'flt',
	[LOB_TYPES.HOTEL]: 'htl',
	[LOB_TYPES.BUS]: 'ic_bus',
	[LOB_TYPES.CABS]: 'ic_cab',
	[LOB_TYPES.REQUISITION]: 'ic_requisition'
};

export const coachmarkTextConf = {
	list: {
		heading: 'View & Download Data',
		subHeading: 'View summarised information from the selected report and download the required information.'
	},
	filter: {
		heading: 'View Filter Data',
		subHeading: 'View summarised information from the selected report and download the required information.'
	},
	help: {
		heading: 'Refer to Help Section',
		subHeading: 'Revisit the benefits and onboarding process of Report from the Help section here.'
	}
};

export const TABLE_COLUMNS = {
	[MRB_CONSTANTS.PENDING_REQUEST_TYPE]: [
		{
			header: '',
			accessor: 'tripType',
			bodyCellProps: {
				padding: '16px 5px 16px 16px',
				width: '20px'
			},
			formatter: (value) => <LobIcons icon={value} />
		},
		{
			header: 'LOCATION',
			accessor: 'location',
			formatter: (value) => <Location {...value} />
		},
		{
			header: 'TRAVELLER',
			accessor: 'traveller',
			formatter: (value) => <GenericSpan>{value}</GenericSpan>
		},
		{
			header: 'APPROVER',
			accessor: 'approver',
			formatter: (value) => <GenericSpan>{value}</GenericSpan>
		},
		{
			header: 'DATE OF TRAVEL',
			accessor: 'dateData',
			formatter: (value) => <DateData {...value} />
		},
		{
			header: 'AGE (HRS)',
			accessor: 'ageInHrs',
			formatter: (value) => value === 0 ? 1 : value
		},
		{
			header: 'ACTIONS',
			key: 1,
			accessor: 'action',
			formatter: (value) => <Action {...value} />
		}],
	[MRB_CONSTANTS.UPCOMING_REQUEST_TYPE]: [
		{
			header: '',
			accessor: 'tripType',
			bodyCellProps: {
				padding: '16px 5px 16px 16px',
				width: '20px'
			},
			formatter: (value) => <LobIcons icon={value} />
		},
		{
			header: 'BOOKING ID',
			accessor: 'bookingId',
			noExpand: true,
			formatter: (value) => value
		},
		{
			header: 'TRAVELLER',
			accessor: 'traveller',
			formatter: (value) => value
		},
		{
			header: 'LOCATION',
			accessor: 'location',
			formatter: (value) => <Location {...value} />
		},
		{
			header: 'DATE OF BOOKING',
			accessor: 'dateData',
			formatter: (value) => <DateData {...value} />
		},
		{
			header: 'DATE OF TRAVEL',
			accessor: 'dateOfTravelling',
			formatter: (value) => value
		},
		{
			header: '',
			key: 1,
			accessor: 'action',
			formatter: (value) => <Action {...value} type={APPLICATION_CONSTANTS.BTN_TYPE.CLICKTEXT} />
		}]
};

export const searchInput = (type) => ({
	value: '',
	label: '',
	info: '',
	placeholder: type === MRB_CONSTANTS.UPCOMING_REQUEST_TYPE ? 'Search for Traveller Email ID/Booking ID' : 'Search for Traveller Email ID',
	type: 'text',
	textType: 'search'
});

export const errorHeading = 'No Requests Found.';
export const errorContent = 'Try removing some filters to see results.';
