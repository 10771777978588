import styled, { css } from 'styled-components';

import { ASSET_PATH } from 'assetsPath';
import { GenericP, GenericSpan } from 'commonStyles';

export const SpriteIcon = styled(GenericSpan)`
	background: url(${ASSET_PATH.IMAGES}policy_sprites.png?v=1) no-repeat;
	width: 18px;
	height: 18px;
	display: inline-block;
	background-size: 145px 180px;
	${(props) => {
		switch (props.icon) {
			case 'infoIcon':
				return css`
						width: 20px;
						height: 20px;
						margin-left: 10px;
						background-position: -48px -140px ;
						`;
			default:
				return css`
						display:none`;
		}
	}}
`;

export const InfoIcon = styled.span`
	position: relative;
	cursor: pointer;
	color: #fff;
	.ico-pol {
		filter: invert(44%) sepia(62%) saturate(5122%) hue-rotate(191deg) brightness(103%) contrast(106%)
	}
	& .tooltip-wrap{
		display: none;
	}
	&:hover .tooltip-wrap {
		display: block;
	}
	.content {
		h5 {
			margin-bottom: 10px;
			font-weight: 700;
		}
	}
`;

export const InfoHeading = styled(GenericP)`

	${(props) => (props.type && props.type == 'priceRise') && css`
		&:after {
			content: '';
			border-bottom : 1px solid #d2d6d9;
			width: 100%;
			margin-top: 10px;
			display: block;
		}
	`}
`;
