import styled ,{css} from "styled-components";
import { FlexDiv } from '../../../common/Styles';

export const MoreWrapper = styled.div`
    padding: 12px 10px;
    font-size: 12px;
    color: ${props => props.active ? '#ff664b' : '#4a4a4a'};
    background-color: ${props => props.active ? 'rgba(255,102,75,0.05)' : '#fff'};
    display: block;
    position: relative;
    .text {
        position: relative;
        padding-right: 20px;
    }
    .content {
        display: flex;
        position: absolute;
        background-color: #fff;
        transition: all 0.2s;
        top: 85%;
        left: 0;
        min-width: 180px;
        border-radius: 6px;
        max-height: 0;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
        z-index: 1;
        padding: 0;
        width: auto;
        overflow: hidden;
    }
    &:hover {
        background-color: rgba(255,102,75,0.05);
        cursor: pointer;
        text-decoration: none;
        border-radius: 4px;
        font-weight: 900;
        position: relative;
        .text > .arrow {
            transform: rotate(225deg);
            top: 5px;
        }
        .content {
            overflow: visible;
            max-height: 300px;
            padding: 10px 0 24px;
        }
    }
`

export const MoreContent = styled.div`
    flex-direction:column;
`