import React from 'react';

import ToolTip from 'common/Tooltip/Tooltip';
import { NameWrapper, BookingActionWrapper } from './DetailsListingStyles';
import { GenericP, GenericSpan, FlexDiv, GenericDiv } from 'commonStyles';
import { BOOKING_ACTIONS_CONFIG,
	CAB_TRIP_COLOR,
	DETAILS_CONFIG_CONST,
	SUGGESTION_DETAILS,
	CAB_TRIP_TYPE,
	SAVINGS_FIELD } from '../../../pages/details/config';
import { SummaryIcons } from '../../../pages/summary/Styles';

/**
 * Formatter for Action Date column of DetailsListing
 * @author - mmt8760
 * @param {any} value - Value of a row for a particular column
 * @param {Object} item - Entire Row Data
 * @return {JSX} - Updated JSX for the action column
 */
export const getFormattedAction = (value, item = {}) => {
	const { bookingAction = '', bookingActionType = '' } = item;
	let { bgColor, color, label = '' } = BOOKING_ACTIONS_CONFIG[bookingAction] || {};
	const actionType = BOOKING_ACTIONS_CONFIG[bookingActionType];
	if (bookingAction === DETAILS_CONFIG_CONST.DATE_CHANGE && actionType) {
		label = actionType.label;
	}
	return (
		<>
			<GenericP color='#4A4A4A' f_weight='bold' fsize='11px'>
				{value}
			</GenericP>
			{bookingAction && (
			<BookingActionWrapper fsize='10px' bgColor={bgColor} color={color}>
				{(label && label.toUpperCase()) || bookingAction}
			</BookingActionWrapper>
			)}
		</>
	);
};

/**
 * Formatter for making column value bold
 * @author - mmt8760
 * @param {any} value - Value of a row for a particular column
 * @return {JSX} - Updated JSX for particular column
 */
export const getBoldValue = (value) => (
	<GenericP color='#4A4A4A' f_weight='bold' fsize='12px'>
		{value}
	</GenericP>
);

/**
 * Formatter for Booker Name or Traveller Name column
 * @author - mmt8760
 * @param {any} value - Value of a row for a particular column
 * @param {Object} rowData - Entire Row Data
 * @param {number} _ - index of row
 * @param {Object} column - Column Data of the Table
 * @return {JSX} - Updated JSX for particular column
 */
export const formatNameValue = (value, rowData, _, column) => {
	const { isBooker } = column;
	const { travellerEmail = '', bookerEmail = '' } = rowData || {};
	let travPartners = [];
	if (isBooker) {
		travPartners = [value];
	} else {
		travPartners = value || [];
	}
	const partnersNumber = travPartners.length - 1;
	return (
		<GenericDiv className='tooltip-wrapper' position='relative'>
			<FlexDiv justify='center'>
				<NameWrapper
					$width='70px'
					color='#4A4A4A'
					f_weight='bold'
					fsize='12px'
					className='ellipses'>
					{travPartners[0]}
				</NameWrapper>
				{partnersNumber > 0 && <GenericSpan color='#008CFF' fsize='12px' f_weight={900}>+{partnersNumber}</GenericSpan>}
			</FlexDiv>
			<ToolTip
				bg_color='#249995'
				color='#fff'
				$width='200px'
				posLeft='-70px'
				top_arr>
				<GenericP color='#D2D6D9' fsize='10px' f_weight={700}>
					{`• ${travPartners.join('  • ')}`}
				</GenericP>
				<GenericP fsize='10px' f_weight={700} top_M={10}>
					{isBooker ? bookerEmail : travellerEmail}
				</GenericP>
			</ToolTip>
		</GenericDiv>
	);
};

/**
 * Formatter for Booking Id
 * @author - mmt8760
 * @param {any} value - Value of a row for a particular column
 * @param {Object} rowData - Entire Row Data
 * @return {JSX} - Updated JSX for particular column
 */
export const formatBookingId = (value, rowData) => {
	const { inPolicy } = rowData;
	let policyText = '';
	if (inPolicy) {
		policyText = 'IN POLICY!';
	} else if (typeof inPolicy === 'boolean' && !inPolicy) {
		policyText = 'OUT OF POLICY!';
	}
	return (
		<>
			{value}
			{policyText &&
				<GenericP color={inPolicy ? '#1a7971' : '#EB2026'} fsize='10px' f_weight='bold'>
					{policyText}
				</GenericP>}
		</>
	);
};

/**
 * Get Suggestion Name based on boolean value
 * @author - mmt8760
 * @param {boolean} suggestionBooked - Data to denote whether suggestion is to book
 * @return {string} - Name of suggestion based on DETAILS_CONFIG_CONST
 */
const getSuggestionName = (suggestionBooked) => (suggestionBooked ?
	DETAILS_CONFIG_CONST.SUGGESTION_BOOKED :
	DETAILS_CONFIG_CONST.SUGGESTION_SKIPPED);

/**
 * Formatter for Booking Id in Savings Table
 * @author - mmt8760
 * @param {any} value - Value of a row for a particular column
 * @param {Object} rowData - Entire Row Data
 * @return {JSX} - Updated JSX for particular column
 */
export const formatSavingBookingId = (value, rowData) => {
	const { suggestionBooked = false } = rowData;
	const suggestion = getSuggestionName(suggestionBooked);
	const { bgColor, color, label } = SUGGESTION_DETAILS[suggestion] || {};
	return (
		<>
			{value}
			<BookingActionWrapper fsize='10px' bgColor={bgColor} color={color}>
				{label}
			</BookingActionWrapper>
		</>
	);
};

/**
 * Formatter for Missed/Gained Saving column in Savings Table
 * @author - mmt8760
 * @param {any} value - Value of a row for a particular column
 * @param {Object} rowData - Entire Row Data
 * @return {JSX} - Updated JSX for particular column
 */
export const formatSavings = (value, rowData) => {
	const { suggestionBooked = false } = rowData;
	const suggestion = getSuggestionName(suggestionBooked);
	const { color } = SUGGESTION_DETAILS[suggestion] || {};
	return (
		<GenericSpan fsize='12px' f_weight={900} color={color}>
			{value}
		</GenericSpan>
	);
};

/**
 * Formatter for Hotel Name
 * @author - mmt8760
 * @param {any} value - Value of a row for a particular column
 * @param {Object} rowData - Entire Row Data
 * @return {JSX} - Updated JSX for particular column
 */
export const formatHotelName = (value, rowData = {}) => {
	const { hotelCity = '' } = rowData;
	return (
		<>
			{value}
			<GenericP fsize='10px'>
				{hotelCity}
			</GenericP>
		</>
	);
};

/**
 * Formatter for Arrow that appear in Flight Table
 * @author - mmt8760
 * @param {any} value - Value of a row for a particular column
 * @return {JSX} - Updated JSX for particular column
 */
export const formatFlightArrw = (value) => (
	<SummaryIcons icon={value ? 'dblArw' : 'arw'} />
);

export const formatCabArrw = (value, rowData) => {
	const { tripType = '' } = rowData;
	const isOutStation = tripType === CAB_TRIP_TYPE.OUTSTATION;
	return (
		<>
			{isOutStation && (
			<SummaryIcons icon={value ? 'dblArw' : 'arw'} />
			)}
		</>
	);
};

/**
 * Formatter for Showing Dashes for Empty Values
 * @author - mmt8760
 * @param {any} value - Value of a row for a particular column
 * @param {Object} rowData - Value of a row for a particular column
 * @param {Object} _1 - index of the row
 * @param {Object} column - get the accessor for particular column
 * @return {JSX} - Updated JSX for particular column
 */
export const formatEmptyValue = (value, rowData = {}, _1, column) => {
	const { accessor } = column;
	const { fromCode = '', toCode = '' } = rowData;
	return (
		<>
			{value || '- -'}
			{fromCode && accessor === 'fromCity' && <GenericP fsize='10px' color='#4A4A4A'>{fromCode}</GenericP>}
			{toCode && accessor === 'toCity' && <GenericP fsize='10px' color='#4A4A4A'>{toCode}</GenericP>}
		</>
	);
};

/**
 * Formatter for Cab Type Column
 * @author - mmt8760
 * @param {any} value - Value of a row for a particular column
 * @param {Object} rowData - Entire Row Data
 * @return {JSX} - Updated JSX for particular column
 */
export const formatCabType = (value, rowData) => {
	const { tripType = '' } = rowData;
	const { bgColor, color } = CAB_TRIP_COLOR[tripType] || {};
	return (
		<>
			{value}
			{tripType && (
			<BookingActionWrapper fsize='10px' bgColor={bgColor} color={color}>
				{CAB_TRIP_TYPE[tripType] || tripType}
			</BookingActionWrapper>
			)}
		</>
	);
};

export const formatFieldColor = (value, _1, _2, column) => {
	const { accessor } = column;
	const field = SAVINGS_FIELD[accessor];
	const { color = '' } = field || {};
	return (
		<GenericSpan fsize='12px' f_weight={900} color={color}>
			{value}
		</GenericSpan>
	);
};
