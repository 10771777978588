import React from 'react';

import { FlexDiv, GenericP, GenericSpan } from '../../common/Styles';
import { SpriteIcon } from '../Style';

const formatOptionLabel = ({ label, icon, iconText, subText, hide }) => (
	<FlexDiv $cursor='pointer' justify='flex-start' align='center' $width='auto' className={hide ? 'hide' : ''}>
		{icon &&
		<SpriteIcon className='opt-icon' icon={icon}>
			{typeof iconText !== 'undefined' &&
			<span className='secondary-icon'>
				{iconText}
			</span>}
		</SpriteIcon>}
		<GenericP left_M={(icon || iconText) ? '10' : 0}>
			{label}{<GenericSpan left_M='5' fsize='12px'>{subText}</GenericSpan>}
		</GenericP>
	</FlexDiv>
);

export default formatOptionLabel;
