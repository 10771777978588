import React from 'react';

import { ClickText } from 'commonStyles';
import Button from 'common/Button/Button';
import { APPLICATION_CONSTANTS } from 'AppConstants';
import { useDispatch } from 'react-redux';
import { modifyBooking } from 'reduxStore/actions/manageRequestBookingActions';

const Action = ({ url, type, value, bookingId }) => {
	const dispatch = useDispatch();
	const redirectWithAccess = () => {
		window.open(url);
	};

	const modifyBookingData = () => (
		dispatch(modifyBooking(bookingId))
	);
	return value ? (
		type === APPLICATION_CONSTANTS.BTN_TYPE.CLICKTEXT ?
			<ClickText onClick={modifyBookingData}>
				{value}
			</ClickText> :
			<Button
				padding='12px 10px'
				btnTxt={value}
				btnType='Flat'
				btnName='Secondary'
				btnShadow
				btnWidth='120px'
				btnFont='14px'
				handleClick={redirectWithAccess}
			/>

	) : null;
};

export default Action;
