// NPM Dependencies
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import MoreOptions from '../MoreOptions/MoreOptions';
import LinkItem from '../LinkItem/LinkItem';
import ProfileSection from '../ProfileSection/ProfileSection';
import FeatureInfo from 'common/FeatureInfo/FeatureInfo';
import HelpSection from 'common/HelpSection/HelpSection';
import BreadCrumb from 'common/BreadCrumb/BreadCrumb';
import MyClaims from '../MyClaims/MyClaims';

// Styles
import { FlexDiv, FeatureWrapper } from 'commonStyles';
import { Wrapper,
	Bar, LeftMenu, Logo, LogoImg,
	MenuList, MyBizSection,
	RightMenuLarge, FloatLeftSpan,
	CallSection, HeaderContainer,
	MainItem } from './HeaderBarStyles';

// Util Methods
import { isDashBoard } from 'UtilityComponents/Util';
import { validateSessionCoachmark } from 'UtilityComponents/bizHeaderUtils';
import { logOmniClick } from '../../../logging/omnitureService';

// Constants & Config
import { PATHS, APPLICATION_CONSTANTS } from 'AppConstants';
import { ASSET_PATH } from 'assetsPath';
import { headerRadioField } from 'common/HelpSection/callbackConfig';
import { CLAIMS_CONFIG_TXT, urlMap } from '../../config';

// Redux Actions
import { claimsOptIn, nextFeatureDesc, skipFeatureDesc } from 'reduxStore/actions/bizHeaderActions';
import { updatePopup } from 'reduxStore/actions/popupActions';

const sessionName = 'newBizHeader';

const BarComponent = ({ blockClick, showBreadCrumb = true, headerOptions = {}, reInitializeHeader, helpInHeader, showHeaderOptionsBar = true }) => {
	const dispatch = useDispatch();
	const profileData = useSelector((state) => state.bizHeader.profileData) || { loggedOut: true };
	const orgDetails = useSelector((state) => state.bizHeader.orgDetails) || { loggedOut: true };
	const [headerOpts, setHeaderOpts] = useState(headerOptions);

	const { email, isAdmin } = profileData;
	const { tneStatus } = orgDetails;
	let showAdmin = true;
	if (!profileData.loggedOut && !isAdmin) {
		showAdmin = false;
	}
	const { options, links, more, totalFeatures, bg } = headerOpts;
	const feature = useSelector((state) => state.bizHeader.feature);

	const proceedToLink = (link, trackVal) => () => {
		if (trackVal) {
			logOmniClick(trackVal, {
				prop54: trackVal + '|v2|corporate',
				eVar34: email
			});
		}
		window.location = link;
	};
	const disabledLinkClick = useCallback(() => {
		dispatch(updatePopup({
			showPop: true,
			popData: {
				...APPLICATION_CONSTANTS.ADMIN_REQUIRED_POP,
				apiStatus: 'ERR_DEFAULT',
				isFooter: true
			}
		}));
	}, []);

	const skipFeature = useCallback(()=>{
		dispatch(skipFeatureDesc());
	}, [dispatch]);
	const nextFeature = useCallback((isClose)=>{
		dispatch(nextFeatureDesc(1, isClose));
	}, [dispatch]);

	useEffect(()=>{
		setHeaderOpts({ ...headerOptions });
	}, [headerOptions]);

	useEffect(()=>{
		if (totalFeatures > 0 && isDashBoard()) {
			const coachmarkCount = localStorage.getItem(sessionName) || 0;
			const count = Number(coachmarkCount);
			if (count < 2 && validateSessionCoachmark(sessionName)) {
				dispatch(nextFeatureDesc(1));
				localStorage.setItem(sessionName, count + 1);
			}
		}
	}, [totalFeatures, dispatch]);

	const navigateTolink = useCallback((item) => {
		let url = item.url;
		if (url && item.param) {
			url = `${url}?${item.param}`;
		} else if (url) {
			url = `${url}`;
		}

		if (window && url) {
			window.location = url;
		}
		if (reInitializeHeader) {
			reInitializeHeader();
		}
	}, [reInitializeHeader]);

	const clickBreadcrumb = useCallback((item) => {
		const key = item.key;
		const opt = urlMap[key];
		navigateTolink(opt);
	}, [navigateTolink]);

	const handleMyExpenseClick = useCallback(()=>{
		dispatch(claimsOptIn(tneStatus));
	}, [tneStatus, dispatch]);

	return (
		<>
			{showHeaderOptionsBar &&
			<HeaderContainer $direction='column'>
				<Bar>
					<Wrapper>
						<LeftMenu align='center' justify='flex-start'>
							<Logo>
								<FlexDiv justify='flex-start' align='center' onClick={proceedToLink(PATHS.DASHBOARD)}>
									<LogoImg src={ASSET_PATH.IMAGES + 'mybizlogo.png'} />
									{showAdmin && <span className='logoHeading'>. Admin</span>}
								</FlexDiv>
							</Logo>
							<MenuList justify='flex-start' align='flex-end' wrap='nowrap' $width='auto'>
								{options && options.map((item)=> {
									const { index: itemIndex, feature: itemFeature = {}, label, hide } = item;
									const active = feature == itemIndex;
									const { heading, content, selectedIndex } = itemFeature;
									const isMyClaim = label === CLAIMS_CONFIG_TXT.label;
									return !hide && (
									<MainItem active={active} key={label}>
										<FeatureWrapper active={active}>
											{active && <FeatureInfo
												nextFeature={nextFeature}
												prevFeature={feature > 1 ? () => nextFeature(-1) : null}
												active={active}
												$width='310px'
												bg_color='#361e2f'
												color='#fff'
												left_arr
												heading={heading}
												content={content}
												step={`${feature}/${totalFeatures}`}
												infoTop='200%'
												left_P={1}
												totalFeatures={totalFeatures}
												last={feature === totalFeatures}
												top_P={1}
												arrowPos='top-left'
												rotateDeg={!isMyClaim && '-45'}
												arrowLeft={!isMyClaim && '-35px'}
												arrowTop={!isMyClaim && '-15px'}
												infoLeft={isMyClaim ? '50%' : '145%'}
												subText={label === CLAIMS_CONFIG_TXT.label && APPLICATION_CONSTANTS.NEWLY_LAUNCHED}
											/>}
											<LinkItem
												shouldSelect={active}
												totalFeatures={totalFeatures}
												nextFeature={nextFeature}
												skipFeature={skipFeature}
												clickAction={navigateTolink}
												blockClick={blockClick}
												disabledLinkClick={disabledLinkClick}
												item={item}
											/>
											{label === CLAIMS_CONFIG_TXT.label &&
											<MyClaims tneStatus={tneStatus} handleMyExpenseClick={handleMyExpenseClick} />}
											{item.options &&
											<div className='submenu'>
												{item.options.map((subopt, subIndex) => (
													<LinkItem
														type='ver'
														selectIndex={active && subIndex == selectedIndex}
														totalFeatures={totalFeatures}
														nextFeature={nextFeature}
														skipFeature={skipFeature}
														clickAction={navigateTolink}
														blockClick={blockClick}
														disabledLinkClick={disabledLinkClick}
														key={subopt.label}
														item={subopt}
													/>
												))}
											</div>}
										</FeatureWrapper>
									</MainItem>
									);
								})}
								<MoreOptions
									totalFeatures={totalFeatures}
									nextFeature={nextFeature}
									skipFeature={skipFeature}
									clickAction={navigateTolink}
									moreData={more}
								/>
							</MenuList>
						</LeftMenu>
						<RightMenuLarge align='center' justify='flex-end'>
							{false &&
							<CallSection
								onClick={proceedToLink(PATHS.CALL_SUPPORT_URL, 'MyBiz_Header_Call_Section_Clicked')}
								href='javascript:;'>
								<FloatLeftSpan>24x7 support</FloatLeftSpan>
								<FloatLeftSpan>Call - 1800 103 9695</FloatLeftSpan>
							</CallSection>}
							<HelpSection show={helpInHeader} isHeader radioField={headerRadioField} />
							<MyBizSection
								href={PATHS.MMT_MYBIZ}>
								myBiz Home
							</MyBizSection>
							<ProfileSection />;
						</RightMenuLarge>
					</Wrapper>
				</Bar>
			</HeaderContainer>}
			{showBreadCrumb && links && <BreadCrumb
				$bg={bg}
				type={showHeaderOptionsBar ? 'header' : ''}
				breadCrumbList={links}
				clickAction={clickBreadcrumb}
			/>}
		</>
	);
};

export default BarComponent;
