import React from 'react';

import { SpriteIcon, InfoIcon, InfoHeading } from './iconsHoverStyle';
import ListHeader from 'common/ListHeader/ListHeader';
import ListRow from 'common/ListRow/ListRow';
import InfoRow from './InfoRow';
import { RowWrapper } from 'common/ListStyles';
import ToolTip from 'common/Tooltip/Tooltip';
import { Card, GenericP } from 'commonStyles';
import { getIconHoverConfig } from 'UtilityComponents/Util';

function IconHover({ input }) {
	const { iconToolTipData = {} } = input;
	const { tableData = {}, content, heading, footer, type } = iconToolTipData;

	const { header, list } = tableData;
	const config = getIconHoverConfig(type);

	return (
		<>
			<InfoIcon className='infotooltp'>
				<SpriteIcon className='ico-pol' icon='infoIcon' />
				<ToolTip {...config}>
					<Card boxPadding='15px 15px 16px' radius='4px' boxMargin='0'>
						{heading && <InfoHeading line_height='1.25' bottom_M='10' color='#351e2e' fsize={type == 'priceRise' ? '14px' : '16px'} dangerouslySetInnerHTML={{ __html: heading }} />}
						{list &&
							<div style={{ border: '1px solid #dddddd' }}>
								<ListHeader
									f_weight='700'
									showCheckBox={false}
									type={type}
									height='22px'
									background='#e7e7e7'
									color='#4a4a4a;00ppo9'
									headerConfig={header}
								/>
								<RowWrapper pageCount={6}>
									{list.map((val, index) => (
										<ListRow
											key={index}
											type={type}
											justify='flex-start'
											height='30px'
											left_P='20'
											right_P='20'>
											<InfoRow field={val} />
										</ListRow>
									))}
								</RowWrapper>
							</div>}
						{content && <GenericP className='content' dangerouslySetInnerHTML={{ __html: content }} fsize='12px' top_M='10' left_P='5' right_P='5' />}
						{footer && <GenericP fsize='12px' top_M='10'>{footer}</GenericP>}
					</Card>
				</ToolTip>
			</InfoIcon>
		</>
	);
}

export default IconHover;
