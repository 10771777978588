import styled, { css } from 'styled-components';
import { GenericP } from 'commonStyles';
import { ListWrapper } from 'common/ListStyles';

export const ListingWrapper = styled(ListWrapper)`
	& .report_cell_head {
		font-style: normal;
		font-weight: 500;
		text-transform: uppercase;
		color: #9B9B9B;
	}

	& .report_table {
    box-shadow: none;
    background: #fff;
    position: relative;
	}

	& .report_cell {
		font-size: 12px;
	}
`;

export const NameWrapper = styled(GenericP)`
	display: inline-block;
	width: ${(props) => props.$width ? props.$width : 'auto'};
`;

export const BookingActionWrapper = styled.div`
	display: inline-block;
	margin-top: 5px;
	border-radius: 4px;
	background-color: ${(props) => props.bgColor ? props.bgColor : '#E9F3FD'}; 
	padding:2px 8px;
	color:${(props) => props.color ? props.color : '#008CFF'};
	font-size:10px;
	font-weight:700;
	margin-right:5px
`;
