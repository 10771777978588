import { REGEX_PATTERNS } from "../AppConstants";
import { PATHS } from '../AppConstants';
import {logOmniClick} from '../logging/omnitureService';

const companyName = value => {
    return value && (value.trim().length <= 50)
}

const isValidGSTNUmber = value => value && REGEX_PATTERNS.GST_REGEX.test(value.trim());


const isValidName = value => value && /^[a-zA-Z ]+$/.test(value);

const phone = value => {
    if(!value || value.length === 0){
        return true;
    }
    return value && /^(0|[1-9][0-9]{9})$/i.test(value);
}

const required = value => !!value;

const email = value => value && /(?=^.{1,80}$)(^[A-Z0-9._%+-]+$)/i.test(value.trim());


export const API_CALL_CONSTANTS = {
    POST_ADMIN_REFER_API: '/refer/admin/other',
    SELF_ADMIN_REFER_DETAILS: '/refer/admin/self'
}


export const CONSTANTS = {
    POST_ADMIN_REFER_SUCCESS: 'POST_ADMIN_REFER_SUCCESS',
    ADMIN_REFER_FAILURE: 'ADMIN_REFER_FAILURE',
    SELF_ADMIN_REFER_DETAILS_FETCH_SUCCESS: 'SELF_ADMIN_REFER_DETAILS_FETCH_SUCCESS',
    SELF_ADMIN_REFER_DETAILS_POST_SUCCESS: 'SELF_ADMIN_REFER_DETAILS_POST_SUCCESS',
    CLEAR_ADMIN_STATE: 'CLEAR_ADMIN_STATE',
    GENERIC_ERROR: 'We are facing some technical issue. Please try after sometime',
    USER_REFFERED_ERROR: 'User is already referred',
    DOMAIN_MISMATCH_ERROR: 'Referred email should belong to same domain',
    EMPLOYEE_DATA_API_DATA_SUCCESS: 'EMPLOYEE_DATA_API_DATA_SUCCESS'
}

export const SUCCESS_STATUS_CODE = "600";

export const fields = [
    {
        isMulti: false,
        readOnly: false,
        showChange: true,
        name: "name",
        label: "Full Name",
        value: '',
        error: '',
        maxLength: 50,
        validations: [
            {
                validator: required,
                msg: "Required!"
            },
            {
                validator: isValidName,
                msg: "Invalid name"
            }
        ],
        type: "text",
        infoText: "Please enter the name as on Government ID. This will be used for booking flights, hotels and other services."
    },
    {
        isMulti: false,
        name: "email",
        label: "E-mail",
        value: '',
        readOnly: false,
        showChange: false,
        validations: [
            {
                validator: required,
                msg: "Required!"
            },
            {
                validator: email,
                msg: "Invalid email address"
            }
        ],
        maxLength: 250,
        type: "text",
        infoText: "Please enter valid email address",
    },
    {
        isMulti: false,
        name: "phoneNo",
        label: "Phone Number",
        isPhone: true,
        value: "",
        readOnly: false,
        showChange: false,
        maxLength: "10",
        validations: [
            {
                validator: phone,
                msg: "Invalid phone number, must be 10 digits"
            }
        ],
        type: "tel",
        infoText: "Please enter a mobile number. This will be used to send OTPs, and for future correspondence."
    }
];



export const adminForm = [
    {
        isMulti: false,
        readOnly: false,
        showChange: true,
        name: "name",
        label: "Full Name",
        value: '',
        error: '',
        maxLength: 50,
        validations: [
            {
                validator: required,
                msg: "Required!"
            },
            {
                validator: isValidName,
                msg: "Invalid name"
            }
        ],
        type: "text",
        infoText: "Please enter the name as on Government ID. This will be used for booking flights, hotels and other services."
    },
    {
        isMulti: false,
        name: "phoneNo",
        isPhone: true,
        label: "Phone Number",
        readOnly: false,
        showChange: false,
        value: "",
        error: '',
        maxLength: 10,
        validations: [
            {
                validator: required,
                msg: "Required!"
            },
            {
                validator: phone,
                msg: "Invalid phone number, must be 10 digits"
            }
        ],
        type: "tel",
        infoText: "Please enter a mobile number. This will be used to send OTPs, and for future correspondence."
    },
    {
        isMulti: false,
        value: "",
        name: "registeredCompanyName",
        label: "Registered Company Name",
        maxLength: 50,
        readOnly: false,
        showChange: false,
        error: '',
        validations: [
            {
                validator: required,
                msg: "Required!"
            },
            {
                validator: companyName,
                msg: "Company name can’t be longer than 50 characters. Try abbreviating the name if possible"
            }
        ],
        type: "text",
        infoText: "Please enter the name as on the company’s PAN Card. This will be used for future correspondence and invoicing purposes."
    },
    {
        isMulti: true,
        name: "companyDetails",
        readOnly: false,
        companyDetails: [
            {
                isFormTypeElement: true,
                name: "companySize",
                label: "Employee Size",
                validations: [
                    {
                        validator: required,
                        msg: "Required!"
                    },
                ],
                type: "select",
                value: "",
                error: '',
                readOnly: false,
                showChange: false,
                options: [
                    {
                        value: 'Less Than 25 Employee',
                        label: 'Less Than 25 Employee',
                    },
                    {
                        value: '25-100 Employee',
                        label: '25-100 Employee',
                    },
                    {
                        value: '100-500 Employee',
                        label: '100-500 Employee',
                    },
                    {
                        value: '500+ Employees',
                        label: '500+ Employees',
                    }
                ],
                infoText: "Please select a range to indicate number of employees."
            },
            {
                isMulti: false,
                name: "gstinNo",
                label: "Company GST Number",
                value: "",
                error: '',
                readOnly: false,
                showChange: false,
                validations: [
                    {
                        validator: required,
                        msg: "Required!"
                    },
                    {
                        validator: isValidGSTNUmber,
                        msg: "Invalid GST number"
                    }
                ],
                type: "text",
                infoText: "This will be used to verify your company. GST number is required for gst claim."
            },
        ]
    }
];


export const validate = (newFields, validStatus) => {
    newFields.map((field, index) => {
        if (field.isMulti) {
            validStatus = validate(field.companyDetails, validStatus);
        }
        else {
            let fieldStatus = true;
            fieldStatus = validateField(field, fieldStatus);
            validStatus = validStatus && fieldStatus;
        }
    });
    return validStatus;
}


export const validateField = (newField, validStatus) => {
    newField && newField.validations.map(validation => {
        if (validStatus) {
            let status = validation.validator(newField.value.trim());
            if (!status) {
                newField.error = validation.msg;
                validStatus = false;
            }
        }
    })
    return validStatus;
};

export const prepareRequestObj = (newFields, adminReferObj, domain) => {
    newFields.map((field, index) => {
        if (field.isMulti) {
            adminReferObj = prepareRequestObj(field.companyDetails, adminReferObj);
        }
        else {
            adminReferObj = fetchData(field, adminReferObj,domain);
        }
    });
    return adminReferObj;
}

const fetchData = (newField, adminReferObj, domain) => {
    const { name, value } = newField;
    if(name === "email"){
        let vEmail = value + domain;
         adminReferObj[name] = vEmail;
    }
    else {
        adminReferObj[name] = value;
    }
    return adminReferObj;
};

export const redirectToAdmin = (event) => {
    event.preventDefault();
    logOmniture('refer_admin_self_claim', 'mybiz:refer_admin_page');
    window.location = `${PATHS.REFERRAL_COMPANY_ADMIN_URL}?isAdmin=true`;
}

export const referCompanyAdmin = (event, omnitureEvent) => {
    event.preventDefault();
    logOmniture(`${omnitureEvent ? omnitureEvent : 'become_admin_refer_clicked'}`, 'mybiz:become_admin_page');
    window.location = `${PATHS.REFERRAL_COMPANY_ADMIN_URL}?isAdmin=false`;
}

export const logOmniture = (eventName, pageName)=> {
    logOmniClick(eventName, {
        prop54: eventName,
        pageName: pageName
    });
}
