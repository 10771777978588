import moment from 'moment';
import get from 'lodash/get';
import bowser from 'bowser';

import { FLIGHT_TRIP_MANDATORY_FIELD, ROUND_TRIP, DOMESTIC_COUNTRY_CODE, GENERIC_DATA_ERROR, FROM_TO_SAME_AIRPORT, CAB_TYPES, PICKUP_TYPE_OPTIONS, 	HOTEL_TRIP_MANDATORY_FIELD,
	BUS_TRIP_MANDATORY_FIELD, SAME_DATE_ERROR } from './pages/ReqForm/config';
import { getCookie, getFormattedDate, getDaysDiff, getPluralText } from 'UtilityComponents/Util';
import { PATHS, REQUISITION_CONSTS } from 'AppConstants';

// COMMON TYPE DEF DECLARATIONS
/**
 * A location object
 *  @author MMT8760
 * @typedef {Object} flightLocationObject
 * @property {string} iata - iata value of airport
 * @property {string} cityName - Name of Ciry
 * @property {string} airportname- Name of airport
 * @property {string} country - Name of Country
 * @property {string} countryCode- Country Code
 * @property {string} icon - URI to icon resource
 */

/**
 * A Flight Trip object
 * @author MMT8760
 * @typedef {Object} flightTripObject
 * @property {flightLocationObject} from - From Location
 * @property {flightLocationObject} to - To Location
 * @property {date} depDate- Date of Departure
 * @property {date} arrDate - Date of Arrival
 * @property {boolean} isError- To reperesent error state of this object
 */

/**
 * A Flight Trip Param Object
 * @author MMT8760
 * @typedef {Object} flightTripParam
 * @property {string} travelClass - Travel Class
 * @property {string} tripType - Type of trip
 * @property {number} paxCount - Number of Travellers
 */

/**
 * Trav Request Object
 * @author MMT8760
 * @typedef {Object} travRequestData
 * @property {Object} tripTagData - triptag data etered by user
 * @property {flightParams} flightParams - flight params selected by user
 * @property {travllerData} travllerData- travellers data selected  by user
 */

/**
 * A Hotel Trip object
 * @author MMT8760
 * @typedef {Object} hotelTripObject
 * @property {Object} destination - From Location object
 * @property {date} checkin- Date of Check In
 * @property {date} checkout - Date of checkout
 * @property {string} errorState- To reperesent error state of this object
 */

/**
 * A Bus Trip object
 * @author MMT8760
 * @typedef {Object} busTripObject
 * @property {Object} from - From Location object
 * @property {Object} to - to Location object
 * @property {date} fromDate- Date of Travel
 * @property {number} paxCount - Number of Travellers
 * @property {string} errorState- To reperesent error state of this object
 */

/**
 * @author MMT8760
 * @typedef {Object} popularLocation
 * @property {string} iata - iata value of airport
 * @property {string} city - Name of Ciry
 * @property {string} airport- Name of airport
 * @property {string} country - Name of Country
 * @property {string} cc - Country Code
 * @property {string} icon - URI to icon resource
 * @param {popularLocation[]} data
 * @return {flightLocationObject[]} Return modified locations array
 */
export const formatPopularData = (data = []) => data.map((item) => ({
	iata: item.iata,
	cityName: item.city,
	airportName: item.airport,
	country: item.country,
	countryCode: item.cc,
	icon: item.icon
}));

/**
 * @author MMT8760
 * @param {flightTripObject} data
 * @param {string} tripType
 * @return {boolean} Return true if any field is missing in data
 */
export const isErrorInFlightData = (data = {}, tripType = '') => {
	const mandatoryField = FLIGHT_TRIP_MANDATORY_FIELD[tripType];
	if (!mandatoryField) {
		return true;
	}
	let errorState = '';
	mandatoryField.forEach((field) => {
		const { from, to } = data;
		if (from && to && from.iata === to.iata) {
			errorState = FROM_TO_SAME_AIRPORT;
			return;
		}
		if (!data[field]) {
			errorState = GENERIC_DATA_ERROR;
			return;
		}
	});
	return errorState;
};

/**
 * @author MMT8760
 * @typedef {object} formattedDate
 * @property {string} fieldValue - String Containing Date & Month
 * @property {string} subText - String Containing day & year
 * @param {Date} date
 * @return {formattedDate}
 */
export const getDateString = (date) => {
	if (!date) {
		return '';
	}
	try {
		const toDate = moment(date);
		return {
			fieldValue: `${toDate.date()} ${toDate.format('MMM')}`,
			subText: `${toDate.format('ddd')}, ${toDate.format('YYYY')}`
		};
	} catch (err) {
		return '';
	}
};

/**
 * @author MMT8760
 * @param {flightTripParam} flightParams
 * @param {flightTripObject[]} flightDetails
 * @return {Object} - String of url generated using search details & Error State
 */
export const getFlightUrl = ({ flightParams, flightDetails }) => {
	let flightUrl = PATHS.FLIGHT_SEARCH_URL;
	let isInternational = false;
	let error = false;
	if (flightDetails && flightParams) {
		let itineraryStr = `&itinerary=`;
		const { tripType, travelClass, paxCount } = flightParams;
		flightDetails.forEach((detail, index) => {
			const { to, from, arrDate, depDate, errorState } = detail;
			if (errorState) {
				error = errorState;
				return;
			}

			if (!isInternational && !(DOMESTIC_COUNTRY_CODE?.includes(from.countryCode) &&
			DOMESTIC_COUNTRY_CODE?.includes(to.countryCode))) {
				isInternational = true;
			}

			const fromLocationValue = `${from.iata}-${to.iata}`;
			const depDateValue = getFormattedDate(depDate, 'DD/MM/YYYY');
			itineraryStr += `${index > 0 ? '_' : ''}${fromLocationValue}-${depDateValue}`;
			if (tripType === ROUND_TRIP) {
				const toLocationValue = `${to.iata}-${from.iata}`;
				const arrDateValue = getFormattedDate(arrDate, 'DD/MM/YYYY');
				itineraryStr += `_${toLocationValue}-${arrDateValue}`;
			}
		});
		const paxTypeStr = `&paxType=A-${paxCount}_C-${0}_I-${0}`;
		const cabinClassStr = `&cabinClass=${travelClass}`;
		const isIntl = isInternational ? `&intl=true` : ``;
		const tripTypeStr = `tripType=${tripType}`;

		flightUrl = `${flightUrl}${tripTypeStr}${itineraryStr}${paxTypeStr}${cabinClassStr}${isIntl}`;
	}
	return {
		flightUrl: error ? '' : flightUrl,
		errorState: error
	};
};

/**
 * @author MMT8760
 * @param {flightTripObject} locObj
 * @return {Object} - Return modified locObj
 */
const getLocationbject = (locObj = {}) => {
	const { iata, country, cityName, countryCode } = locObj;
	return {
		airportCode: iata,
		cityName,
		countryCode,
		countryName: country
	};
};

/**
 * @author MMT8760
 * @param {flightTripObject} locObj
 * @return {Object} - Return modified locObj
 */
const getTravelObject = ({ to, from, arrDate, depDate }) => {
	const travelObj = {};
	travelObj.from = getLocationbject(from);
	travelObj.to = getLocationbject(to);
	travelObj.depDate = depDate.getTime();
	if (arrDate) {
		travelObj.returnDate = arrDate.getTime();
	}
	return travelObj;
};

/**
 * @author MMT8760
 * @param {flightTripParam} flightParams
 * @param {flightTripObject[]} flightDetails
 * @return {Object} - Return Data which is needed for request
 */
export const getFlightRequestData = ({ flightParams, flightDetails }) => {
	const flightData = { errorState: '' };
	if (flightParams && flightDetails) {
		const { tripType, travelClass, paxCount } = flightParams;
		flightData.tripType = tripType;
		flightData.travelClass = travelClass;
		flightData.paxDetails = {
			'adult': paxCount,
			'child': 0,
			'infant': 0
		};
		flightData.travelDetails = flightDetails.filter((detail) => !detail.errorState).map(getTravelObject);
		const { errorState, flightUrl } = getFlightUrl({ flightParams, flightDetails });
		flightData.listingPageUrl = flightUrl;
		flightData.errorState = errorState;
	}

	return flightData;
};

/**
 * -first  Return trav name
 * @author MMT8270
 * @param {Array} travs
 * @return {String}
 */
export const travDispVal = (travs) =>{
	let retVal = '';
	const travLen = travs.length;
	if (travLen > 0) {
		retVal = travs[0].name;
		retVal += travLen > 1 ? ` +${travLen - 1}` : '';
	}
	return retVal;
};

/**
 * format and validate trav req data
 * @author MMT8270
 * @param {travRequestData} travData
 * @return {String} error type
 */
export const formatRequisitionData = (travData) =>{
	const { tripTagData, flightData, cabsData, busData, hotelData, travllerData, travelReasons, email } = travData;
	const { platform, os } = typeof window === undefined ? {} : bowser.getParser(window.navigator.userAgent).parsedResult;
	const dvid = typeof window === undefined ? false : getCookie('dvid');
	const retData = {
		'tripTags': { 'tripTagDetails': {
			primaryPaxEmail: email,
			attributeList: []
		} },
		'services': {},
		'reasonForTravel': {},
		'deviceDetails': {
			'version': os?.version,
			'deviceId': dvid,
			'osType': platform?.type
		},
		'travellerDetails': {
			bookingFor: '',
			paxDetails: []
		}
	};
	let travReqError = false;
	if (tripTagData) {
		for (const id in tripTagData) {
			if (tripTagData[id].error) {
				travReqError = REQUISITION_CONSTS.REVIEW_SECTION;
				retData.tripTags.attributeList = [];
				return {
					travReqError, retData
				};
				break;
			} else {
				const { attributeId, attributeName, attributeSelectedValue, mandatoryCheck } = tripTagData[id];
				retData.tripTags.tripTagDetails.attributeList.push({ attributeId, attributeName, attributeSelectedValue, mandatoryCheck });
			}
		}
	}
	if (!travReqError && travelReasons.error) {
		travReqError = REQUISITION_CONSTS.REVIEW_SECTION;
		return {
			travReqError, retData
		};
	} else {
		const { reason, comment } = travelReasons;
		retData.reasonForTravel = { reason, comment };
	}
	if (!travReqError && travllerData?.paxDetails.length === 0) {
		travReqError = REQUISITION_CONSTS.TRAVELLER_SECTION;
		return {
			travReqError, retData
		};
	} else {
		const { bookingFor, paxDetails } = travllerData;
		retData.travellerDetails = { bookingFor, paxDetails };
	}
	travReqError = REQUISITION_CONSTS.SERVICES_SECTION;
	if (flightData) {
		retData.services.FLIGHT = [flightData];
		travReqError = false;
	}

	if (cabsData) {
		retData.services.CABS = cabsData;
		travReqError = false;
	}

	if (busData) {
		retData.services.BUS = busData;
		travReqError = false;
	}

	if (hotelData) {
		retData.services.HOTEL = [hotelData];
		travReqError = false;
	}

	return { retData, travReqError };
};

/* Return a unique key
* @param {any} pre
* @return {string} - a unique key
*/
export const generateKey = (pre) => `${ pre }_${ new Date().getTime() }`;

/**
 * Check whether hotel data is complete or not
 * @author MMT8760
 * @param {busTripObject} data
 * @return {string} Return error state of hotel data
 */
export const isErrorInBusData = (data = {}) => {
	const mandatoryField = BUS_TRIP_MANDATORY_FIELD;
	if (!mandatoryField) {
		return '';
	}
	let errorState = '';
	mandatoryField.forEach((field) => {
		const { from, to } = data;
		if (from && to && from.id === to.id) {
			errorState = FROM_TO_SAME_AIRPORT;
			return;
		}
		if (!data[field]) {
			errorState = GENERIC_DATA_ERROR;
			return;
		}
	});
	return errorState;
};

/**
 * Check whether bus data is complete or not
 * @author MMT8760
 * @param {hotelTripObject} data
 * @return {string} Return error state of hotel data
 */
export const isErrorInHotelData = (data = {}) => {
	const mandatoryField = HOTEL_TRIP_MANDATORY_FIELD;
	if (!mandatoryField) {
		return '';
	}
	let errorState = '';
	mandatoryField.forEach((field) => {
		const { checkin, checkout } = data;
		if (!data[field]) {
			errorState = GENERIC_DATA_ERROR;
			return;
		}
		if (getDaysDiff(checkin, checkout) === 0) {
			errorState = SAME_DATE_ERROR;
			return;
		}
	});
	return errorState;
};

/**
 * Get Room details of hotel data
 * @param {Object} hotelRoomDetails
 * @return {object} containing the guest details & room stay qualifier
 */
export function getRoomDetails(hotelRoomDetails) {
	const roomDetails = {
		roomStayQualifier: '',
		totalGuestCount: '',
		roomCount: ''
	};
	let roomStayQualifier = '';
	let noOfRooms = 0;

	let noOfAdults = 0;

	let noOfChildren = 0;
	for (let i = 0; hotelRoomDetails && i < hotelRoomDetails.length; i++) {
		noOfRooms++;
		const value = hotelRoomDetails[i].value;
		noOfAdults += value;
		roomStayQualifier += `${value}e`;
		noOfChildren += 0;
		roomStayQualifier += `0e`;
	}
	roomDetails.roomStayQualifier = roomStayQualifier;
	roomDetails.totalGuestCount = noOfAdults + noOfChildren;
	roomDetails.roomCount = noOfRooms;
	return roomDetails;
}

/** Generate Hotel Listing URL
 * @author MMT8760
 * @param {hotelTripObject} request
 * @return {string} - String of url generated using search details & Error State
 */
export function getHotelUrl(request) {
	const {
		checkin,
		checkout,
		roomDetails: hotelRoomDetails
	} = request;
	let url = new URL(`https:${PATHS.MMT_PATH}${PATHS.HOTEL_LISTING_URL}`);
	const typesForCityAsContextId = ['area', 'poi', 'hotel', 'gpoi'];
	const requestPath = 'destination';
	const checkInDate = getFormattedDate(checkin, 'MMDDYYYY');
	const checkOutDate = getFormattedDate(checkout, 'MMDDYYYY');
	const id = get(request, `${requestPath}.id`, '');
	const countryCode = get(request, `${requestPath}.countryCode`, '');
	const type = get(request, `${requestPath}.type`, '');
	const typeLowerCase = type.toLowerCase();
	const isPoi = typeLowerCase === 'poi';
	const isGPoi = typeLowerCase === 'gpoi';
	const name = get(request, `${requestPath}.displayName`, '');
	const placeId = get(request, `${requestPath}.place_id`, '');
	const searchText = name || get(request, `${requestPath}.cityName`, '');
	const roomDetails = getRoomDetails(hotelRoomDetails);
	const contextIdData = get(request, `${requestPath}.contextId`, '');
	const regionNearByExp = get(request, 'regionNearByExp', '');
	let contextId = '';
	let contextType = '';
	let city = id;
	let locusType = type;
	const tagName = name.split(',')[0] || '';
	if (typeof contextIdData === 'object') {
		contextType = Object.keys(contextIdData)[0];
		contextId = contextIdData[contextType];
	}
	if (typesForCityAsContextId.includes(typeLowerCase) && contextId && contextType) {
		city = contextId;
		locusType = contextType;
	}
	if (typeLowerCase === 'hotel') {
		const hotelId = get(request, `${requestPath}.id`, '');
		const cityCode = get(request, `${requestPath}.cityCode`, '');
		url.searchParams.set('topHtlId', hotelId);
		url.searchParams.set('city', cityCode);
	} else {
		url.searchParams.set('city', city);
	}
	if (typeLowerCase === 'area') {
		const mmAreaTag = `${tagName}|${id}`;
		url.searchParams.set('mmAreaTag', mmAreaTag);
	} else if (placeId || isPoi || isGPoi) {
		let latitude = get(request, `${requestPath}.centre.lat`, '');
		let longitude = get(request, `${requestPath}.centre.lng`, '');
		if (!latitude && !longitude) {
			latitude = get(request, `${requestPath}.centre.latitude`, '');
			longitude = get(request, `${requestPath}.centre.longitude`, '');
		}
		const typeLabel = isPoi ? 'POI' : 'LPOI';
		const mmPoiTag = `${typeLabel}|${tagName}|${id}|${latitude}|${longitude}`;
		url.searchParams.set('mmPoiTag', mmPoiTag);
	}

	url.searchParams.set('checkin', checkInDate);
	url.searchParams.set('checkout', checkOutDate);
	url.searchParams.set('roomStayQualifier', roomDetails.roomStayQualifier);
	url.searchParams.set('locusId', city);
	url.searchParams.set('country', countryCode);
	url.searchParams.set('locusType', locusType);
	url.searchParams.set('searchText', searchText);
	if (regionNearByExp) {
		url.searchParams.set('regionNearByExp', regionNearByExp);
	}
	return url.toString();
}

/**
 * @author MMT8760
 * @param {hotelTripObject} hotelParams
 * @return {Object} - Return Data which is needed for request
 */
export const getHotelRequestData = ({ hotelParams }) => {
	const hotelData = { errorState: '' };
	if (hotelParams) {
		const { destination = {}, checkin, checkout, roomDetails, errorState } = hotelParams;
		if (errorState) {
			return {
				errorState
			};
		}
		const { cityCode, cityName, countryCode, countryName, id, displayName } = destination;
		hotelData.cityCode = cityCode || id;
		hotelData.cityName = cityName || displayName;
		hotelData.countryCode = countryCode;
		hotelData.countryName = countryName;
		hotelData.checkin = checkin.getTime();
		hotelData.checkout = checkout.getTime();
		hotelData.countryName = countryName;
		const paxCount = roomDetails.reduce((acc, detail) => acc + detail.value, 0);
		hotelData.paxDetails = {
			'adult': paxCount,
			'child': {
				count: 0,
				detail: []
			},
			'infant': 0
		};
		hotelData.roomCount = roomDetails.length;
		const hotelUrl = getHotelUrl(hotelParams);
		hotelData.listingPageUrl = hotelUrl;
		hotelData.errorState = errorState;
	}

	return hotelData;
};

/** Generate bus Listing URL
 * @author MMT8760
 * @param {busTripObject} busParams
 * @return {string} - String of url generated using search details & Error State
 */
export const getBusUrl = (busParams = {}) => {
	const { from = {}, to = {}, fromDate } = busParams;
	const { n: fromStop, id: fromCityMMTId } = from;
	const { n: toStop, id: toCityMMTId } = to;
	let baseUrl = `${PATHS.BUS_LISTING_URL}/${fromStop}/${toStop}/${getFormattedDate(fromDate, 'DD-MM-YYYY')}`;
	const url = new URL(baseUrl);
	if (fromCityMMTId && toCityMMTId) {
		url.searchParams.set('from_code', fromCityMMTId);
		url.searchParams.set('to_code', toCityMMTId);
	}
	return url.toString();
};

/**
 * Get Bus Request Object
 * @author MMT8760
 * @param {hotelTripObject} hotelParams
 * @return {Object} - Return Data which is needed for request
 */
export const getBusRequestData = ({ busParams }) => {
	const busData = { errorState: '' };
	if (busParams) {
		const { from = {}, to = {}, fromDate, errorState } = busParams;
		if (errorState) {
			return {
				errorState
			};
		}
		busData.pickupDetails = {
			'code': from.id,
			'name': from.n,
			'address': from.dn,
			'dateTime': fromDate.getTime()
		};
		busData.dropDetails = {
			'code': to.id,
			'name': to.n,
			'address': to.dn,
			'dateTime': 0
		};
		const busUrl = getBusUrl(busParams);
		busData.listingPageUrl = busUrl;
		busData.errorState = errorState;
	}

	return busData;
};

export const getCabRequestData = (cabParams = []) =>
	cabParams.map((params)=> {
		const { cabType, pickupDetails, dropDetails, pickupType } = params;
		let cabReqData = { cabType, pickupType };

		cabReqData.dropDetails = setTripData(dropDetails, cabType === CAB_TYPES.ROUND_TRIP, true);
		cabReqData.pickupDetails = setTripData(pickupDetails, true);
		if (cabReqData.dropDetails.error || cabReqData.pickupDetails.error) {
			cabReqData = { error: cabReqData.dropDetails.error || cabReqData.pickupDetails.error };
		} else {
			const cabUrl = makeCabUrl(params);
			cabReqData.listingPageUrl = cabUrl;
		}

		return cabReqData;
	});

const makeCabUrl = (params) => {
	const { cabType, pickupDetails, dropDetails, pickupType } = params;

	let selectedPickUpTime = pickupDetails.time.time;
	let selectedDropTime = dropDetails.time?.time;
	let selectedPickMeridiem = pickupDetails.time.meridiem;
	let selectedDropMeridiem = dropDetails.time?.meridiem;
	let selectedFromDate = pickupDetails.date;
	let selectedToDate = dropDetails.date;
	const toCity = dropDetails.searchObj?.title;
	const fromCity = pickupDetails.searchObj?.title;

	const selectedPickUpTimeArray = selectedPickUpTime && selectedPickUpTime.split(':');
	const selectedDropTimeArray = selectedDropTime && selectedDropTime.split(':');

	const isToAirport = pickupType === PICKUP_TYPE_OPTIONS[0].id;
	if (selectedPickMeridiem === 'PM' &&
		(selectedPickUpTimeArray && selectedPickUpTimeArray[0] != '12') ||
		selectedPickMeridiem === 'AM' &&
		(selectedPickUpTimeArray && selectedPickUpTimeArray[0] == '12')) {
		selectedPickUpTime = parseInt(selectedPickUpTimeArray[0]) + 12;
		if (selectedPickUpTime == 24) {
			selectedPickUpTime = 0;
			selectedPickUpTime = `${selectedPickUpTime}:${selectedPickUpTimeArray[1]}`;
		} else {
			selectedPickUpTime = `${selectedPickUpTime}:${selectedPickUpTimeArray[1]}`;
		}
	}

	if (selectedDropMeridiem === 'PM' && (selectedDropTimeArray && selectedDropTimeArray[0] != '12') ||
		selectedDropMeridiem === 'AM' && (selectedDropTimeArray && selectedDropTimeArray[0] == '12')) {
		selectedDropTime = parseInt(selectedDropTimeArray[0]) + 12;
		if (selectedDropTime == 24) {
			selectedDropTime = 0;
			selectedDropTime = `${selectedDropTime}:${selectedDropTimeArray[1]}`;
		} else {
			selectedDropTime = `${selectedDropTime}:${selectedDropTimeArray[1]}`;
		}
	}

	const formattedDepartDate = moment(selectedFromDate).format('DD-MM-YYYY');

	let url = 'http://cabs.makemytrip.com/dt_cabsListing?fromCity={"address":"${fromCityAddress}","latitude":${fromLatitude},"longitude":${fromLongitude},"pincode":"","place_id":"${fromPlaceId}","is_city":"true"}&toCity={}&tripType=${tripType}&departDate=${departDate}&pickupTime=${pickupTime}';

	if (toCity) {
		url = 'http://cabs.makemytrip.com/dt_cabsListing?fromCity={"address":"${fromCityAddress}","latitude":${fromLatitude},"longitude":${fromLongitude},"pincode":"","place_id":"${fromPlaceId}","is_city":"true"}&toCity={"address":"${toCityAddress}","latitude":${toLatitude},"longitude":${toLongitude},"pincode":"","place_id":"${toPlaceId}","is_city":"false"}&tripType=${tripType}&departDate=${departDate}&pickupTime=${pickupTime}';
	}

	url = url.replace('${fromCityAddress}', (fromCity));
	const fromCityLatitude = pickupDetails.searchObj.searchObj.latitude;
	const fromCityLongitude = pickupDetails.searchObj.searchObj.longitude;
	if (fromCityLatitude && fromCityLongitude) {
		url = url.replace('${fromLatitude}', fromCityLatitude);
		url = url.replace('${fromLongitude}', fromCityLongitude);
	}
	url = url.replace('${fromPlaceId}', pickupDetails.searchObj.id);

	if (toCity) {
		url = url.replace('${toCityAddress}', (toCity));
		const toCityLatitude = dropDetails.searchObj.searchObj.latitude;
		const toCityLongitude = dropDetails.searchObj.searchObj.longitude;
		if (toCityLatitude && toCityLongitude) {
			url = url.replace('${toLatitude}', toCityLatitude);
			url = url.replace('${toLongitude}', toCityLongitude);
		}
		url = url.replace('${toPlaceId}', dropDetails.searchObj.id);
	}
	url = url.replace('${departDate}', formattedDepartDate);
	url = url.replace('${pickupTime}', selectedPickUpTime);
	url = url.replace('${tripType}', cabType);
	switch (cabType) {
		case 'RT': {
			const formattedReturnDate = moment(selectedToDate).format('DD-MM-YYYY');
			url = `${url}&returnDate=${formattedReturnDate}`;
			url = url.replace('${tripType}', 'RT');
			url += `&dropTime=${selectedDropTime}`;
			break;
		}
		case 'AT': {
			url += `&toAirport=${isToAirport}&fromAirport=${!isToAirport}`;
			break;
		}
		default: url = url.replace('${tripType}', 'OW');
	}
	return encodeURI(url);
};

const setTripData = (tripData, allRequired, isDrop) => {
	const { time, date, searchObj } = tripData;
	if ((!searchObj || !searchObj.title)) {
		return { error: `Need to select ${isDrop ? 'drop' : 'pickup'} location` };
	}
	if (allRequired && (!date || !time)) {
		return { error: 'Need to select date and time' };
	}
	const dateTime = date ? moment(date) : '';
	const timeStr = time && time.time24 ? moment(time.time24, 'HH:mm') : '';
	if (timeStr) {
		dateTime.set({
			hour: timeStr.get('hour'),
			minute: timeStr.get('minute'),
			second: timeStr.get('second')
		});
	}

	return {
		code: searchObj.searchObj.city_code,
		name: searchObj.title,
		address: searchObj.address,
		dateTime: dateTime && typeof dateTime === 'object' ? dateTime.valueOf() : ''
	};
};

/**
 * utility function to get traveller text
 * @author mybizFe
 * @param { Number } paxCount total number of persons
 * @return { String } returns traveller text
 */
export const getTravCountText = (paxCount) => paxCount > 1 ? ` +${paxCount - 1} ${getPluralText('traveller', paxCount)}` : '';