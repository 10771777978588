import styled, { css } from 'styled-components';

export const Link = styled.div`
    font-size: 12px;
    font-weight: ${(props) => props.active ? '900' : '400'};
    color: ${(props) => props.active ? '#ff664b' : '#4a4a4a'};
    display: block;
    margin-right: 3px;
    text-decoration: none;
    padding: 12px 8px;
    position: relative;
    white-space: nowrap;
    background-color: ${(props) => props.selected ? 'rgba(255,102,75,0.05)' : ''};
    .arrow {
        transform: ${(props) => props.type == 'ver' ? 'rotateZ(-45deg)' : 'rotateZ(45deg)'};
        position: relative;
        margin-left: 8px;
    }
    &:hover {
        background-color: rgba(255,102,75,0.05);
        cursor: pointer;
        text-decoration: none;
        border-radius: 4px;
    }
    ${(props) => props.active && css`
        &:after {
            content: '';
            border-radius: 2px;
            display: inline-block;
            height: ${(props) => props.type == 'ver' ? '16px' : '2px'};
            background: #ff664b;
            width: ${(props) => props.type == 'ver' ? '2px' : '100%'};
            position: absolute;
            left: 0;
            bottom: ${(props) => props.type == 'ver' ? '12px' : '-8px'};;
        }`
}
`;

export const NewTag = styled.span`
    position: absolute;
    border-radius: 10px;
    background-image: linear-gradient(93deg, #ff4b59 0%, #ff674b 100%);
    padding: 1px 5px;
    top:  ${(props) => props.$top ? props.$top : '-7px'};
    font-size: 10px;
    font-weight: bold;
    left: ${(props) => props.$left ? props.$left : '8px'};
    color: #fff;
`;
