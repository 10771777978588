import styled, { css } from 'styled-components';

import { MainWrapper, GenericSpan } from 'commonStyles';
import { ASSET_PATH } from 'assetsPath';

export const EmployeeWrapper = styled(MainWrapper)`
`;

export const EmployeeIcons = styled(GenericSpan) `
    background: url(${ASSET_PATH.IMAGES}/employee/employee.png) no-repeat;
  	background-size: 254px 254px;
    display: inline-block;
    ${(props) => {
		switch (props.icon) {
			case 'yellowTick':
				return css`
					width: 24px;
					height: 24px;
					background-position: -99px -4px;`;
			case 'greenTick':
				return css`
					width: 24px;
					height: 24px;
					background-position: -5px -100px;`;
			case 'redCross':
				return css`
					width: 24px;
					height: 24px;
					background-position: -99px -37px;`;
			case 'addCircleIcon':
				return css`
					width: 40px;
					height: 40px;
					background-position: -5px -52px;
				`;
			case 'starCircleIcon':
				return css`
					width: 40px;
					height: 40px;
					background-position: -5px -5px;
				`;
			case 'shieldCircleIcon':
				return css`
					width: 40px;
					height: 40px;
					background-position: -53px -5px;
				`;
			case 'redExclaim':
				return css`
					width: 15px;
					height: 18px;
					background-position: -138px -9px;
				`;
			case 'gradGreenTick':
				return css`
				    width: 40px;
						height: 40px;
						background-position: -163px -5px;
				`;
			case 'blueUploadIcon':
				return css`
				    width: 20px;
						height: 20px;
						background-position: -94px -27px;
						background-size: 175px 175px;
				`;
			default:
				return css`
					width: 24px;
					height: 24px;
					background-position: -105px -5px;`;
		}
	}}
`;
