import { getAxiosConfig } from '../apisSetup/apiConfig';
import { PATHS } from 'AppConstants';

const ccbAxiosApiCaller = getAxiosConfig(PATHS.CCB_URL, true, {
}, false);

export const getPopularFlight = (apidata = {}, headers = {}) => ccbAxiosApiCaller.get(PATHS.POPULAR_FLIGHT_SEARCH, { params: { ...apidata }, headers });
export const fetchFlight = (apidata = {}, headers = {}) => ccbAxiosApiCaller.get(PATHS.SEARCH_FLIGHT, { params: { ...apidata }, headers });
export const fetchHotel = (apidata = {}, headers = {}) => ccbAxiosApiCaller.get(PATHS.SEARCH_HOTEL, { params: { ...apidata }, headers });
export const fetchPlaceDetails = (apidata = {}, headers = {}) => ccbAxiosApiCaller.get(PATHS.GET_PLACE_DETAILS, { params: { ...apidata }, headers });
export const fetchBus = (apidata = {}, headers = {}) => ccbAxiosApiCaller.get(PATHS.SEARCH_BUS, { params: { ...apidata }, headers });
export const fetchCabAutoSuggest = (key, headers = {}) => ccbAxiosApiCaller.get(PATHS.SEARCH_CAB_DATA.replace('searchKey', key), { headers });
