import styled, { css } from 'styled-components';

export const TooltipWrapper = styled.div`
    position: absolute;
    min-width: 100px;
    top:  ${(props) => props.posTop ? props.posTop : 'auto'};
		bottom:  ${(props) => props.posBtm ? props.posBtm : 'auto'};
    left:  ${(props) => props.posLeft ? props.posLeft : 'auto'};
    min-height: 50px;
    border-radius: 5px;
    padding: ${(props) => props.padding ? props.padding : '10px 15px'};
    width: ${(props) => props.$width ? props.$width : 'auto'};
    transform: ${(props) => props.transform ? props.transform : 'none'};
		z-index: ${(props) => props.zIndex ? props.zIndex : '11'};
    background:  ${(props) => props.bg_color ? props.bg_color : '#fff'};
		color:  ${(props) => props.color ? props.color : '#4a4a4a'};
		box-shadow:  ${(props) => props.boxShadow ? props.boxShadow : 'none'};
    display:  ${(props) => (props.display || 'none')};
    &:after {
        width: 0;
        height: 0;
        content: "";
        position: absolute;
        border: 6px solid transparent;
        z-index: 1;
				${(props) => props.no_arr && css`
					display: none;
				`}
        ${(props) => props.left_arr && css`
            left: -14px;
            border-right: ${(props) => ('8px solid' + props.bg_color)};
            top : ${(props) => props.$top ? props.$top : '20%'}`
}
        ${(props) => props.right_arr && css`
            right : -14px;
            border-left: ${(props) => ('8px solid' + props.bg_color)};
            top : ${(props) => props.$top ? props.$top : '20%'}`
}
        ${(props) => props.btm_arr && css`
            left : ${(props) => props.left ? props.left : '50%'};
            bottom: -14px;
            border-top: ${(props) => ('8px solid' + props.bg_color)};`
}
        ${(props) => props.top_arr && css`
            left : ${(props) => props.left ? props.left : '50%'};
            top: -14px;
            border-bottom: ${(props) => ('8px solid' + props.bg_color)};`
}
    }
`;
