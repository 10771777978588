import styled, { css } from 'styled-components';

import { ASSET_PATH } from 'assetsPath';

export const GroupIcons = styled.span`
	background: url(${ASSET_PATH.IMAGES}groupSprite.png) no-repeat;
	background-size: 250px 250px;
	width:32px;
	height:32px;
	background-position: -88px -66px;
	display: inline-block;
	${(props) => {
		switch (props.icon) {
			case 'success':
				return css`
					position: absolute;
					left: 22%;
					top: 21%;
					width:30px;
					height:30px;
					background-position: -192px 1px;
				`;
			case 'failure':
				return css`
					position: absolute;
					left: 22%;
					top: 21%;
					width:30px;
					height:30px;
					background-position: -97px 1px;
				`;
			case 'bigGreenTick':
				return css`
					width: 60px;
					height: 60px;
					margin: 30px auto 0px;
					padding: 15px;
					background-image: linear-gradient(6deg, rgba(172,225,67,1) 150px, rgba(33,147,147,1) 100%);
					border-radius: 50%;
					&:after {
						content: '';
						display: inline-block;
						transform: rotate(45deg);
						height: 25px;
						width: 12px;
						border-bottom: 4px solid #fff;
						border-right: 4px solid #fff;
					}
				`;
			case 'organization':
				return css`
					width:30px;
					height:30px;
					background-position: -50px 1px;
			`;
			case 'doubleTick':
				return css`
					width: 20px;
					height: 20px;
					background-position: -140px -26px;
				`;
			case 'circleIcon':
				return css`
					width:30px;
					height:30px;
					background-position: -5px -30px;
				`;
			case 'dowloadIcon':
				return css`
					width: 24px;
					height: 30px;
					background-position: -123px -1px;
				`;
			case 'successIcon':
				return css`
					width:25px;
					height:28px;
					background-position: -192px 1px;
				`;
			default:
				return css``;
		}
	}
}
`;

export const ContentWrapeer = styled.div`
	min-height: 73px;
	${(props) => props.active && css`
		position: relative;
		z-index: 2`
}
`;

export const UnorderedList = styled.ul`
	list-style-type: circle;
`;

export const ListItem = styled.li`
	margin: 10px 0px 0px 10px;
`;

export const PolicyAction = styled.span`
	cursor: pointer;
`;
