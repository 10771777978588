import { FILTER_CONSTANTS } from '../AppConstants';

export const updateFltClickCount = (filter, value) => {
	if (value === false && filter.selectedCount > 0) {
		filter.selectedCount--;
	} else if (value === true) {
		filter.selectedCount++;
	}
	if (filter.type === FILTER_CONSTANTS.DROPDOWN) {
		filter.selectedCount = value.length;
	}
	filter.showClear = filter.type === FILTER_CONSTANTS.CHECKBOX && filter.selectedCount > 0;
};

/**
 * rmove filter from applied filters array
 * params : appliedFilters, option/filter to be removed
 */
export const removeAppliedFilter = (appliedFilters, option) => {
	let arrIndex = false;
	for (let i in appliedFilters) {
		if (appliedFilters[i].value === option.value) {
			arrIndex = i;
			break;
		}
	}
	if (arrIndex) {
		appliedFilters.splice(arrIndex, 1);
	}
};

/**
 * params : current applied filter object,options checked uncheck, if all filters cleared, if group filters applied
 * returns apllied filter object after removing the unchecked filter obj
 */
export const updateAppliedFilterObj = (appliedFilters, options, isClear, isGroup) => {
	options && options.forEach((opt) => {
		if (opt.checked && !isClear) {
			appliedFilters.push(opt);
		} else if (isGroup) {
			opt.options.forEach((opt) => removeAppliedFilter(appliedFilters, opt));
		} else {
			removeAppliedFilter(appliedFilters, opt );
		}
	});
	return appliedFilters;
};

/**
 * clear subfilter if parent filter is unchecked
 * params : appliedFilters, changed filter value, option/filter to be removed, if clear filter clicked
 * returns applied filter array
 */
export const handleSubFilter = (appliedFilters, value, option, isCLear) => {
	let selOption = false;
	if (isCLear && option.options) {
		selOption = option.options.filter((opt) => (opt.type === 'select'));
	} else {
		selOption = option;
	}
	if (!value && selOption) {
		selOption.forEach((opt) => {
			if (opt.type === 'select') {
				let valueKey = 'value';
				opt[valueKey].forEach((selectopt) => {
					removeAppliedFilter(appliedFilters, selectopt);
				});
				opt[valueKey] = [];
			}
		});
	}
	return appliedFilters;
};
