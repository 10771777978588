import styled from 'styled-components';

import { FlexDiv } from '../Styles';

export const DateFields = styled.span`
    border: none;
    padding: 25px 0 9px 10px;
    color:  ${(props) => props.color ? props.color : '#000'};;
    border-radius: 7px;
    font-weight:  ${(props) => props.f_weight ? props.f_weight : '900'};
    position: absolute;
    width: 220px;
    left: -10px;
		margin-top: ${(props) => props.top_M ? props.top_M : '10px'};
`;
export const DateRangeOuter = styled(FlexDiv)`
    position: relative; 
    z-index: 0; 
`;

export const LabelText = styled.label`
color: #4a4a4a;
font-size: 14px;
text-transform: uppercase;
font-weight: 700;
margin-bottom: 3px;
position: absolute;
top: 50%;
transform: translateY(-50%);
transition:0.2s ease-in-out all 0.02s;
left: 10px; 
`;
export const BoxWrapperRow = styled.div`
    background-color: ${(props) => props.bgColor ? props.bgColor : '#f7f7f7'};
		margin-bottom: ${(props) => props.bottom_M ? props.bottom_M : '15px'};
		width: ${(props) => props.width ? props.width : '100%'};
    position: relative;
	&.materialInput textarea ~ label {
		top: 18px;
		transform: unset;
	}
  &.materialInput input,&.materialInput textarea{
    height: ${(props) => props.height ? props.height : 'inherit' };
    background: none;
    z-index: 1;
    width: 100%;
    color: #000;
    outline: none !important;
    border-radius: 4px;
    border: solid 1px #e7e7e7;
    padding: 25px 10px 9px 10px;
    position: relative;
    line-height: inherit;
    font-family:lato;
    font-weight:${(props) => props.f_weight ? props.f_weight : 900};
    transition:0.2s ease-in-out all 0.02s;
    &.focus {
      border:1px solid #ff6544; background-color: #fff9f7;
    }
    &.focus ~ label {
      top:5px; font-size:11px; color: #ff6544; z-index:2;
			transform: unset;
    }
    &.valid {
      border:1px solid #e7e7e7; background-color: #f7f7f7;
    }
    &.valid ~ label {
      top:5px; font-size:11px; color:#9b9b9b; z-index:2;
			transform: unset;
    }
    &:focus{border:1px solid #ff6544; background-color: #fff9f7;    }
    &:focus ~ label{
			top:5px;
			font-size:11px;
			color: #ff6544;
			z-index:2;
			transform: unset;
		}
  }
  &.materialInput textarea {
    resize: none;
    margin-bottom: -5px;
  }
  &.calOuter{
      padding: ${(props) => props.padding ? props.padding : '22px 10px 15px 10px'};
      border:1px solid #e7e7e7;
      border-radius:4px;
    &.active{border:1px solid #ff6544; background-color: #fff9f7;}
    &.active ~ label{top:5px; font-size:11px; color: #ff6544; z-index:2   }
    }
  &.calOuter .calendarIcon{}
  &.calOuter.active label{top:5px; font-size:11px; color: #ff6544; z-index:2; transform: unset;}
  &.calOuter.filled label{top:5px; font-size:11px; color: #9b9b9b; ; transform: unset;} 
`;

export const DayPickerWrap = styled.div`
	position: absolute; 
	top: ${(props) => props.top_M ? props.top_M : '32px'};
	right: ${(props) => props.rightPos ? props.rightPos : 'auto'};
	left: ${(props) => props.leftPos ? props.leftPos : '-10px'};
	background: #fff; 
	width: 324px; 
	height: 330px; 
	z-index: 999; 
	box-shadow: 0px 0px 6px #bbb;
	border-radius: 3px;
	& .DayPicker {width:100%}
	& .DayPicker-Months{width:100%}
	& .DayPicker-Month{width:100%}
`;

export const InfoText = styled.span`
  position: absolute;
  bottom: -15px;
  right: 0px;
  font-size: 10px;
  color: ${(props) => props.color ? props.color : '#4a4a4a'};
`;
