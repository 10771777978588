import React from 'react';

import { GenericP } from 'commonStyles';
import { FILTER_CONSTANTS, REPORTS_CONSTANT, APPLICATION_CONSTANTS } from 'AppConstants';
import { ASSET_PATH } from 'assetsPath';
import { OtherInfo } from './Styles';

const commonBtnConfig = {
	btnType: 'Flat',
	btnName: 'Primary',
	margin: '15px 0 0'
};

export const REPORT_SUCCESS_IMG = `${ASSET_PATH.IMAGES}reports/reportSuccess.png`;

export const PAGE_HEAD_TEXT = 'Reports';

export const HELP_TEXT = {
	why: 'Why should I use Report section?',
	how: 'How to use Reports section?'
};

export const LIST_HEADING = { head: 'Report Summary', subText: APPLICATION_CONSTANTS.LAST_QUARTER };

export const NO_RESULT_TXT = {
	heading: 'No Reports to show',
	desc: {
		[FILTER_CONSTANTS.FILTER_QUERY_FAILED]: 'Your reports will be shown here once you begin to make bookings on myBiz.',
		[FILTER_CONSTANTS.INIT_QUERY_FAILED]: 'Your reports will be shown here once you begin to make bookings on myBiz.'
	}
};

export const textsConfig = {
	FILTER_QUERY_FAILED: 'Try changing filters to see more results',
	INIT_QUERY_FAILED: 'Your Expenses will be shown here once they are available for selected Criterion.'
};

export const dateConfig = {
	'Today': 'fixedDayCounts.today',
	[FILTER_CONSTANTS.CUSTOM_DATE_FILTER]: 'enteredDateCounts.total',
	'Last 30 days': 'fixedDayCounts.thirtyDays',
	'Last 90 days': 'fixedDayCounts.ninetyDays',
	'Last 7 days': 'fixedDayCounts.sevenDays'

};

export const REPORT_PAGE_TYPE = {
	'FLIGHT': 'FLIGHT',
	'HOTEL': 'HOTEL',
	'WALLET': 'WALLET',
	'SAVINGS_SUMMARY': 'SAVINGS_SUMMARY',
	'BUS': 'BUS',
	'CAB': 'CAB',
	'FLIGHT_PNR': 'FLIGHT_PNR',
	'SAVINGS': 'SAVINGS',
	'TRAIN': 'TRAIN'
};

export const REPORT_TYPE_CONFIG = {
	[REPORT_PAGE_TYPE.FLIGHT]: {
		icon: 'flt',
		text: 'Flight',
		col2txt: 'Total Bookings',
		col3txt: 'Total Spend',
		col4txt: 'Out of Policy Bookings(%)',
		downloadConfig: {
			downloadOpts: [{
				label: 'Invoice Level Report',
				value: 'INVOICE'
			},
			{
				label: 'Action Level Report',
				value: 'ACTION'
			},
			{
				label: 'Traveller Level Report',
				value: 'PAX'
			}],
			customReport: true,
			dwlBtns: [
				{
					...commonBtnConfig,
					name: 'DOWNLOAD CUSTOM REPORT',
					trackEventKey: 'download_default'
				},
				{
					...commonBtnConfig,
					name: 'DOWNLOAD FULL REPORT',
					trackEventKey: 'download_full'
				}]
		}
	},
	[REPORT_PAGE_TYPE.FLIGHT_PNR]: {
		icon: 'ic_flighttPnr',
		text: 'Flight PNR',

		downloadConfig: {
			downloadOpts: [{
				label: 'Invoice Level Report',
				value: 'INVOICE'
			},
			{
				label: 'Action Level Report',
				value: 'ACTION'
			},
			{
				label: 'Traveller Level Report',
				value: 'PAX'
			}],
			customReport: true,
			dwlBtns: [
				{
					...commonBtnConfig,
					name: 'DOWNLOAD CUSTOM REPORT',
					trackEventKey: 'download_default'
				},
				{
					...commonBtnConfig,
					name: 'DOWNLOAD FULL REPORT',
					trackEventKey: 'download_full'
				}]
		}
	},
	[REPORT_PAGE_TYPE.HOTEL]: {
		icon: 'htl',
		text: 'Hotel',

		downloadConfig: {
			downloadOpts: [{
				label: 'Invoice Level Report',
				value: 'INVOICE'
			},
			{
				label: 'Action Level Report',
				value: 'ACTION'
			}],
			customReport: true,
			dwlBtns: [
				{
					...commonBtnConfig,
					name: 'DOWNLOAD CUSTOM REPORT',
					trackEventKey: 'download_default'
				},
				{
					...commonBtnConfig,
					name: 'DOWNLOAD FULL REPORT',
					trackEventKey: 'download_full'
				}]
		}
	},
	[REPORT_PAGE_TYPE.WALLET]: {
		icon: 'wlt',
		text: 'Wallet Transactions',
		downloadConfig: {
			dwlBtns: [{
				...commonBtnConfig,
				name: 'DOWNLOAD FULL REPORT',
				trackEventKey: 'download_full'
			}]
		}
	},
	[REPORT_PAGE_TYPE.SAVINGS_SUMMARY]: {
		icon: 'svgs',
		text: 'Savings',
		downloadConfig: {
			customReport: true,
			dwlBtns: [, {
				...commonBtnConfig,
				name: 'DOWNLOAD CUSTOM REPORT',
				trackEventKey: 'download_default'
			},
			{
				...commonBtnConfig,
				name: 'DOWNLOAD FULL REPORT',
				trackEventKey: 'download_full'
			}]
		}

	},
	[REPORT_PAGE_TYPE.BUS]: {
		icon: 'ic_bus',
		text: 'Bus',

		downloadConfig: {
			customReport: true,
			dwlBtns: [{
				...commonBtnConfig,
				name: 'DOWNLOAD CUSTOM REPORT',
				trackEventKey: 'download_default'
			},
			{
				...commonBtnConfig,
				name: 'DOWNLOAD FULL REPORT',
				trackEventKey: 'download_full'
			}]
		}

	},
	[REPORT_PAGE_TYPE.TRAIN]: {
		icon: 'ic_train',
		text: 'Train',

		downloadConfig: {
			customReport: true,
			dwlBtns: [{
				...commonBtnConfig,
				name: 'DOWNLOAD CUSTOM REPORT',
				trackEventKey: 'download_default'
			},
			{
				...commonBtnConfig,
				name: 'DOWNLOAD FULL REPORT',
				trackEventKey: 'download_full'
			}]
		}
	},
	[REPORT_PAGE_TYPE.CAB]: {
		icon: 'ic_cab',
		text: 'Cab',
		downloadConfig: {
			customReport: true,
			dwlBtns: [{
				...commonBtnConfig,
				name: 'DOWNLOAD CUSTOM REPORT',
				trackEventKey: 'download_default'
			},
			{
				...commonBtnConfig,
				name: 'DOWNLOAD FULL REPORT',
				trackEventKey: 'download_full'
			}]
		}

	},
	[REPORT_PAGE_TYPE.SAVINGS]: {
		icon: 'ic_missedSavings',
		text: 'Savings Missed',
		downloadConfig: {
			customReport: true,
			dwlBtns: [{
				...commonBtnConfig,
				name: 'DOWNLOAD FULL REPORT',
				trackEventKey: 'download_full'
			}]
		}

	}
};

export const filterConfig = {
	initCount: {},
	dateRange: {
		label: 'Action Date Range',
		type: FILTER_CONSTANTS.CHECKBOX,
		startkey: 'startDate',
		endKey: 'endDate',
		key: REPORTS_CONSTANT.DATE_RANGE,
		value: '',
		filterType: 'date',
		hideClear: false,
		selectedCount: 0,
		options: [{
			alwaysEnable: true,
			label: 'Enter Dates',
			type: FILTER_CONSTANTS.CUSTOM_DATE_FILTER,
			value: '',
			trackVal: 'date_customdate',
			checked: false,
			text: 'selected dates',
			countKey: 'enteredDateCounts.total',
			key: FILTER_CONSTANTS.DATE_FILTER,
			filterKey: 'dateRange'

		}

		]
	}
};

export const REPORT_TYPES = {
	'HOTEL_TYPE': 'HOTEL',
	'FLIGHT_TYPE': 'FLIGHT',
	'CAB_TYPE': 'CAB',
	'BUS_TYPE': 'BUS',
	'WALLET_TYPE': 'WALLET',
	'SAVINGS_SUMMARY_TYPE': 'SAVINGS_SUMMARY',
	'SAVINGS_TYPE': 'SAVINGS',
	'FLIGHT_PNR': 'FLIGHT_PNR',
	'TRAIN_TYPE': 'TRAIN'
};

export const reportFaqsData = [
	{
		iconClass: 'faqsIcon',
		ques: 'What is a custom report?',
		ans1: 'Reports are data heavy files with range of data sets.',
		ans2: 'We provide you the flexibility <span class=\'latoBold\'>to select your required data</span> for <span class=\'latoBold greenText\'>effective data handling.</span>'
	},
	{
		iconClass: 'addIcon',
		ques: 'How to create Your custom report?',
		ans1: 'Click on <span class=\'latoBold\'>“Create new”</span> to get a form.',
		ans2: 'Fill the form by providing the report type, frequency & required data. <span class=\'latoBold\'>We will timely mail you your Report!</span>'
	}

];

export const GRANUALITY_MAP = {
	'DAILY': {
		label: 'Daily',
		value: 'DAILY'
	},
	'WEEKLY': {
		label: 'Weekly',
		value: 'WEEKLY'
	},
	'MONTHLY': {
		label: 'Monthly',
		value: 'MONTHLY'
	},
	'QUARTERLY': {
		label: 'Quarterly',
		value: 'QUARTERLY'
	}
};

export const GRANUALITY = [
	GRANUALITY_MAP.MONTHLY, GRANUALITY_MAP.QUARTERLY
];

export const REPORT_CONFIG = {
	'flight': {
		icon: 'flt'
	},
	'hotel': {
		icon: 'htl'
	}
};

export const MONTH_DAYS_OPTIONS_MAP = {
	'1': {
		'value': '1',
		'label': '1st of every Month'
	},
	'2': {
		'value': '2',
		'label': '2nd of every Month'
	},
	'3': {
		'value': '3',
		'label': '3rd of every Month'
	},
	'4': {
		'value': '4',
		'label': '4th of every Month'
	},
	'5': {
		'value': '5',
		'label': '5th of every Month'
	},
	'6': {
		'value': '6',
		'label': '6th of every Month'
	},
	'7': {
		'value': '7',
		'label': '7th of every Month'
	},
	'8': {
		'value': '8',
		'label': '8th of every Month'
	},
	'9': {
		'value': '9',
		'label': '9th of every Month'
	},
	'10': {
		'value': '10',
		'label': '10th of every Month'
	},
	'11': {
		'value': '11',
		'label': '11th of every Month'
	},
	'12': {
		'value': '12',
		'label': '12th of every Month'
	},
	'13': {
		'value': '13',
		'label': '13th of every Month'
	},
	'14': {
		'value': '14',
		'label': '14th of every Month'
	},
	'15': {
		'value': '15',
		'label': '15th of every Month'
	},
	'16': {
		'value': '16',
		'label': '16th of every Month'
	},
	'17': {
		'value': '17',
		'label': '17th of every Month'
	},
	'18': {
		'value': '18',
		'label': '18th of every Month'
	},
	'19': {
		'value': '19',
		'label': '19th of every Month'
	},
	'20': {
		'value': '20',
		'label': '20th of every Month'
	},
	'21': {
		'value': '21',
		'label': '21st of every Month'
	},
	'22': {
		'value': '22',
		'label': '22nd of every Month'
	},
	'23': {
		'value': '23',
		'label': '23rd of every Month'
	},
	'24': {
		'value': '24',
		'label': '24th of every Month'
	},
	'25': {
		'value': '25',
		'label': '25th of every Month'
	},
	'26': {
		'value': '26',
		'label': '26th of every Month'
	},
	'27': {
		'value': '27',
		'label': '27th of every Month'
	},
	'28': {
		'value': '28',
		'label': '28th of every Month'
	},
	'29': {
		'value': '29',
		'label': '29th of every Month'
	},
	'30': {
		'value': '30',
		'label': '30th of every Month'
	},
	'31': {
		'value': '31',
		'label': '31st of every Month'
	}
};

export const WEEKDAYS_OPTIONS_MAP = {
	'7': { value: 7, label: 'Sundays' },
	'1': { value: 1, label: 'Monday' },
	'2': { value: 2, label: 'Tuesday' },
	'3': { value: 3, label: 'Wednesday' },
	'4': { value: 4, label: 'Thursday' },
	'5': { value: 5, label: 'Friday' },
	'6': { value: 6, label: 'Saturday' }
};

const getWeekdaysOptions = () => Object.values(WEEKDAYS_OPTIONS_MAP);

const getMontlyOptions = () => Object.values(MONTH_DAYS_OPTIONS_MAP);

export const reportTypeConf = {
	isMulti: false,
	label: 'Report Type',
	outLabel: true,
	isMandatory: true,
	value: '',
	name: 'reportType',
	key: 'reportType',
	type: 'select',
	trackVal: '',
	validation: {
		required: {
			msg: 'Required!'
		}
	},
	options: [
		{ value: REPORT_TYPES.HOTEL_TYPE, label: REPORT_TYPES.HOTEL_TYPE },
		{ value: REPORT_TYPES.FLIGHT_TYPE, label: REPORT_TYPES.FLIGHT_TYPE },
		{ value: REPORT_TYPES.FLIGHT_PNR, label: 'FLIGHT PNR' },
		{ value: REPORT_TYPES.BUS_TYPE, label: REPORT_TYPES.BUS_TYPE },
		{ value: REPORT_TYPES.CAB_TYPE, label: REPORT_TYPES.CAB_TYPE },
		{ value: REPORT_TYPES.TRAIN_TYPE, label: REPORT_TYPES.TRAIN_TYPE }
	]
};

export const reportNameConf = {
	value: '',
	name: 'reportName',
	label: 'Report Name',
	type: 'input',
	validation: {
		required: {
			msg: 'Required!'
		}
	},
	placeHolder: 'Report Name',
	isMandatory: true,
	outLabel: true
};

export const emailTypeConf = {
	isMulti: true,
	label: 'Email Address',
	outLabel: true,
	isMandatory: true,
	value: [],
	name: 'emailAddress',
	key: 'emailAddress',
	type: 'select',
	errorOnBlur: true,
	isSearchable: true,
	customClass: 'mgr-drop',
	labelKey: 'value',
	validation: {
		required: {
			msg: 'Required!'
		}
	},
	options: []
};

const frequencyOption = [
	{ value: GRANUALITY_MAP.DAILY.value, label: GRANUALITY_MAP.DAILY.label },
	{ value: GRANUALITY_MAP.WEEKLY.value, label: GRANUALITY_MAP.WEEKLY.label },
	{ value: GRANUALITY_MAP.QUARTERLY.value, label: GRANUALITY_MAP.QUARTERLY.label },
	{ value: GRANUALITY_MAP.MONTHLY.value, label: GRANUALITY_MAP.MONTHLY.label }
];

export const frequencyTypeConf = {
	isMulti: false,
	label: 'Frequency',
	outLabel: true,
	isMandatory: true,
	value: '',
	name: 'frequency',
	key: 'frequency',
	type: 'select',
	trackVal: '',
	validation: {
		required: {
			msg: 'Required!'
		}
	},
	options: frequencyOption,
	value: frequencyOption[1]
};

export const dayOfMonthConf = {
	isMulti: false,
	isMandatory: true,
	value: '',
	name: 'dayOfMonth',
	key: 'dayOfMonth',
	type: 'select',
	trackVal: '',
	validation: {
		required: {
			msg: 'Required!'
		}
	},
	options: getMontlyOptions()
};

export const dayOfWeekConf = {
	isMulti: false,
	isMandatory: true,
	value: '',
	name: 'dayOfWeek',
	key: 'dayOfWeek',
	type: 'select',
	trackVal: '',
	validation: {
		required: {
			msg: 'Required!'
		}
	},
	options: getWeekdaysOptions()
};

export const dailyFieldConf = {
	value: 1,
	name: 'daily',
	placeHolder: 'Before Noon '
};

export const quarterlyFieldConf = {
	value: 1,
	name: 'quaterly',
	placeHolder: '1st date of Quarter'
};

export const CHECK_LIST_CONF = {
	name: '',
	value: '',
	placeHolder: '',
	selectedCount: 0,
	options: [],
	type: 'checkbox',
	customClass: 'boldText zeroMargin',
	checkField: {
		name: '',
		value: '',
		placeHolder: '',
		options: [],
		type: 'checkbox',
		customClass: 'report'
	}
};

export const gstSetupModalData = {
	customWrapperClass: 'no-scroll',
	title: <GenericP fsize='26px' f_weight='900' bottom_M='5'>Schedule your GSTR-2A <br />Report for GST Filing</GenericP>,
	subTitle: `We will mail you a GSTR-2A Report on 15th of every month so that you can easily do reconciliation during filing of GST claims.`,
	closeBtnClass: 'white',
	customClass: 'reports'
};

export const downloadReportModalData = {
	customWrapperClass: 'no-scroll',
	title: <GenericP fsize='26px' f_weight='900' bottom_M='5'>Download your GSTR-2A <br />Report</GenericP>,
	subTitle: `We prepare a GSTR-2A Report so that you can easily do reconciliation during filing of GST claims. You can download the GSTR-2A Report as per your required timeline.`,
	closeBtnClass: 'white',
	customClass: 'reports'
};

export const newReportModalData = {
	title: <GenericP fsize='26px' f_weight='900' bottom_M='5'>Edit your Custom <br />Report</GenericP>,
	subTitle: `Tell us what all data you require & set the frequency to receive the report. We will mail them to you timely!`,
	closeBtnClass: 'white',
	customClass: 'reports'
};

export const setUpGstButtonConf = {
	btnType: 'Flat',
	btnName: 'Primary',
	btnTxt: 'SCHEDULE NOW',
	margin: '20px 0 0'
};

export const downloadReportBtnConf = {
	btnType: 'Flat',
	btnName: 'Primary',
	btnTxt: 'DOWNLOAD NOW',
	margin: '20px 0 0'
};

export const deleteModalConf = {
	showSec: true,
	btnTextSecondary: 'No, Go Back',
	btnTextPrimary: 'Yes, Delete',
	descMsg: `Are you sure you want to delete the report?`
};

export const newReportBtnConf = {
	btnType: 'Flat',
	btnName: 'Primary',
	btnTxt: 'SAVE & SCHEDULE NOW',
	margin: '35px 0 0'
};

export const successPopUpConf = {
	popupData: {
		title: '',
		imgStyle: 'successReport',
		btnTxt: '',
		img: REPORT_SUCCESS_IMG
	},
	showContent: false,
	customFooter: <OtherInfo>You can edit the Report settings whenever you require.</OtherInfo>,
	isFooter: true
};

export const additionalFieldConf = {
	heading: 'Select the Required Details',
	subHeading: 'You can pick multiple details (You can’t deselect the primary details)'
};
export const coachmarkTextConf = {
	list: {
		heading: 'View & Download Reports',
		subHeading: 'Get all your reports here based on your bookings. View any report by clicking the card & directly download through the icon.'
	},
	top: {
		heading: 'Get your GST report',
		subHeading: 'You can get a comprehensive report to directly upload on the GST Portal to quickly file your claims.'
	},
	help: {
		heading: 'Refer to Help Section',
		subHeading: 'Revisit the benefits and onboarding process of Invoice from the Help section here.'
	}
};

export const gstrStatusConf = {
	[REPORTS_CONSTANT.GST_STATUS_NOT_FILED]: {
		iconClass: 'gstIcon',
		btnText: 'SET UP NOW',
		menuOptionIcon: false,
		btnWidth: '140px'
	},
	[REPORTS_CONSTANT.GST_STATUS_READY]: {
		iconClass: 'gstTickIcon',
		btnText: 'DOWNLOAD NOW',
		menuOptionIcon: true,
		btnWidth: '185px'
	},
	[REPORTS_CONSTANT.GST_STATUS_PROCESSING]: {
		iconClass: 'fileProcess',
		btnText: 'DOWNLOAD NOW',
		menuOptionIcon: true,
		btnWidth: '185px'
	}
};

export const omnitureConfig = {
	'newCustomReportClick': {
		eventName: 'Schedule New custom Report click',
		prop: 'Schedule_clicked_customReport'
	},
	'newReportPopupOpen': {
		eventName: 'Schedule New Popup opened',
		prop: 'Schedule_opened_popup'
	},
	'scheduleClicked': {
		eventName: 'Schedule New Popup Clicked',
		prop: 'Schedule_clicked_'
	}
};

export const REPORT_DWNLD_FAIL = 'Report Failed To Download';

export const DOWNLOAD_MDL_SUBTITLE = 'Full report contains all possible details of the report type at invoice level. If you want lesser details or set default set of columns for your report, you can set/download the same.';
export const DOWNLOAD_CUSTOM_SUBTITLE = 'Tell us what all data you require in the report.';

export const defaultPreferenceField = {
	name: 'isDefaultPreference',
	value: '',
	placeHolder: '',
	options: [{
		value: true,
		label: 'Save these preference as default.'
	}],
	type: 'checkbox',
	error: '',
	customClass: 'boldText zeroMargin'
};

export const scheeduleGstRequest = {
	'reportType': 'GST_2A_REPORT',
	'reportName': 'test',
	'reportFieldDetails': [],
	'frequencyDay': '2'
};

export const DOWNLOAD_OPTIONS = [{
	label: 'Invoice Level Report',
	value: 'INVOICE'
},
{
	label: 'Action Level Report',
	value: 'ACTION'
},
{
	label: 'Traveller Level Report',
	value: 'PAX',
	enableOnlyLobs: [REPORT_PAGE_TYPE.FLIGHT, REPORT_PAGE_TYPE.FLIGHT_PNR]
}];

export const ledgerPopUpConf = {
	popupData: {
		title: '',
		imgStyle: 'successReport',
		btnTxt: '',
		img: REPORT_SUCCESS_IMG
	},
	showContent: false,
	isFooter: true
};

export const LEDGER_MODAL_CONFIG = {
	heading: 'Ledger report will be emailed to your inbox within 15 minutes!',
	subHeading: {
		text1: 'Your ledger report is being generated and we will shortly send you a download link on your email',
		text2: '. After clicking on the link, you will be redirected to our portal where your download will start automatically.'
	}
};
export const SUCCESS_MODAL_CONFIG = {
	subHeading: {
		text1: 'Your ',
		text2: ' report is scheduled to be mailed on a ',
		text3: ' basis'
	}
};

export const MAILER_SENT_MODAL_CONFIG = {
	heading: 'Report will be emailed to your inbox within 15 minutes!',
	subHeading: {
		text1: 'Your report is being generated and we will shortly send you a download link on your email',
		text2: '. After clicking on the link, you will be redirected to our portal where your download will start automatically.'
	}
};
