import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { matchPath } from 'react-router';

import { REPORTS_CONSTANT } from 'AppConstants';
import { BOOKING_ACTIONS_FLTR_CONF, DETAILS_CONFIG_CONST, SORT_PROP, SAVINGS_SORT_PROP } from '../../reporting/pages/details/config';
import { REPORT_PAGE_TYPE, filterConfig, REPORT_TYPES } from '../../reporting/pages/summary/config';
import { updateFltClickCount, updateAppliedFilterObj } from 'UtilityComponents/filterUtils';
import { logOmniClick } from '../../logging/omnitureService';
import { PATHS } from 'AppConstants';
import { presetFilters } from './reportSummaryUtil';
import { saveServerLogs } from 'UtilityComponents/Util';

/**
 * Get Initial Date State based on filterCOnfig
 * @author - mmt8760
 * @return {Object} - dateFilters & dateQuery
 */
export const getInitialDateState = () => {
	const { filters: dateFilters, query: dateQuery } = presetFilters(filterConfig, []);
	return {
		dateFilters,
		dateQuery
	};
};

export const INITIAL_DATE_STATE = getInitialDateState();

/**
 * Create updatedQuery & updatedFilter object for checkbox filters
 * @author - mmt8760
 * @param {Object} query - previous query object
 * @param {Object} filter - selected filter
 * @param {Object} queryOption - queryOptions containing {checked ad value}
 * @return {Object} - Updated query and filter
 */
const setCheckboxQuery = (query, filter, queryOption = {}) => {
	const { key, singleSelect = false } = filter;
	const updatedQuery = cloneDeep(query);
	const { checked, value } = queryOption;
	const updatedFilter = { ...filter };
	if (checked) {
		updatedQuery[key] = updatedQuery[key] && Array.isArray(updatedQuery[key]) && !singleSelect ?
			[...updatedQuery[key], value] : value ? [value] : [];
	} else {
		updatedQuery[key] = updatedQuery[key].filter((item) => item !== value);
		if (updatedQuery[key].length === 0) {
			delete updatedQuery[key];
		}
	}

	updatedFilter.showClear = (!updatedFilter.hideClear) && updatedQuery[key] && updatedQuery[key].length > 0;
	return { updatedQuery, updatedFilter };
};

/**
 * Create updatedQuery & updatedFilter object for date range filters
 * @author - mmt8760
 * @param {Object} query - previous query object
 * @param {Object} filter - selected filter
 * @param {Object} queryOption - queryOptions containing {checked ad value}
 * @return {Object} - Updated query and filter
 */
const setDateRangeQuery = (query, filter = {}, queryOption = {}) => {
	const appliedFilter = [];
	const { listHead = '' } = queryOption;
	updateAppliedFilterObj(appliedFilter, [queryOption]);
	const updatedQuery = cloneDeep(query);
	const dateRange = appliedFilter[0] || {};
	const { dateQuery } = INITIAL_DATE_STATE;
	const { start = dateQuery.startDate, end = dateQuery.endDate } = dateRange;
	updateFltClickCount(filter, queryOption.checked);
	return {
		updatedQuery: { ...updatedQuery, startDate: start, endDate: end },
		updatedFilter: { ...filter, selectedHead: listHead }
	};
};

/**
 * Create updatedQuery & updatedFilter object which is used in API Call
 * @author - mmt8760
 * @param {Object} query - previous query object
 * @param {Object} filter - selected filter
 * @param {Object} queryData - queryOptions containing {option ad value}
 * @return {Object} - Updated query and filter for selected filter
 */
export const getFilterQuery = (query, filter = {}, queryData = {}) => {
	const key = filter.key;
	const { value: queryValue, option: queryOption } = queryData;
	switch (key) {
		case REPORTS_CONSTANT.BOOKING_ACTION:
		case REPORTS_CONSTANT.POLICY:
		case REPORTS_CONSTANT.TRIP_TYPE:
		case REPORTS_CONSTANT.CAB_TYPE:
		case REPORTS_CONSTANT.SYSTEM_SUGGESTED_BOOKING: {
			return setCheckboxQuery(query, filter, queryOption);
		}
		case REPORTS_CONSTANT.DATE_RANGE: {
			return setDateRangeQuery(query, filter, queryValue);
		}
		default: {
			return query;
		}
	}
};

/**
 * Clear Checkbox Filtes and Update query
 * @author - mmt8760
 * @param {Object} query - previous query object
 * @param {string} key - key of the filter
 * @return {Object} - Updated query and filter for selected filter
 */
const clearCheckBoxFilter = (query, key) => {
	const updatedQuery = cloneDeep(query);
	updatedQuery[key] && delete updatedQuery[key];
	return {
		updatedQuery
	};
};

/**
 * Clear Date Filtes and Update query
 * @author - mmt8760
 * @param {Object} query - previous query object
 * @return {Object} - Updated query and filter for selected filter
 */
const clearDateFilter = (query) => {
	let updatedQuery = cloneDeep(query);
	const { dateQuery, dateFilters } = INITIAL_DATE_STATE;
	updatedQuery = { ...updatedQuery, ...dateQuery };
	return {
		updatedQuery,
		updatedFilter: dateFilters.dateRange
	};
};

/**
 * Clear Filtes and Update query
 * @author - mmt8760
 * @param {Object} query - previous query object
 * @param {Object} clearedFilter - filter which needs to be cleared
 * @return {Object} - Updated query and filter for selected filter
 */
export const clearFilterNQuery = (query, clearedFilter = {}) => {
	const key = clearedFilter.key;
	let updatedFilter = cloneDeep(clearedFilter);
	updatedFilter.showClear = false;

	switch (key) {
		case REPORTS_CONSTANT.BOOKING_ACTION:
		case REPORTS_CONSTANT.POLICY:
		case REPORTS_CONSTANT.TRIP_TYPE:
		case REPORTS_CONSTANT.CAB_TYPE:
		case REPORTS_CONSTANT.SYSTEM_SUGGESTED_BOOKING: {
			return {
				updatedFilter,
				...clearCheckBoxFilter(query, key)
			};
		}
		case REPORTS_CONSTANT.DATE_RANGE: {
			return clearDateFilter(query);
		}
		default: {
			return {
				updatedFilter,
				updatedQuery: query
			};
		}
	}
};

/**
 * Util for generating sort query which is used in API call
 * @author - mmt8760
 * @param {Object} sortQuery - previous sort query object
 * @param {Object} sortParam - Updated sorting object containing sorting key & direction
 * @return {Object} - Updated sort query
 */
export const getUpdatedSortQuery = (sortQuery, sortParam = {}) => {
	const updatedSortQuery = cloneDeep(sortQuery);
	updatedSortQuery.sort = sortParam;
	return updatedSortQuery;
};

/**
 * Util for generating filter options for Booking Action Filter
 * @author - mmt8760
 * @param {string} reportType
 * @return {Array} - Array of booking action options for particular report type
 */
export const getBookingActionOptions = (reportType) => {
	switch (reportType) {
		case REPORT_PAGE_TYPE.TRAIN:
		case REPORT_PAGE_TYPE.BUS: {
			return [
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.BOOKED],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.CANCELLED],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.REFUND]
			];
		}
		case REPORT_PAGE_TYPE.CAB: {
			return [
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.BOOKED],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.CANCELLED],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.EXTRA_CHARGES]
			];
		}
		case REPORT_PAGE_TYPE.FLIGHT_PNR: {
			return [
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.ALL],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.BOOKED],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.CANCELLED],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.DATE_CHANGE],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.ADD_ONS]
			];
		}
		case REPORT_PAGE_TYPE.HOTEL:
			return [
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.ALL],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.BOOKED],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.CANCELLED],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.REFUND]
			];
		case REPORT_PAGE_TYPE.FLIGHT: {
			return [
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.ALL],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.BOOKED],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.CANCELLED],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.DATE_CHANGE],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.ADD_ONS],
				BOOKING_ACTIONS_FLTR_CONF[DETAILS_CONFIG_CONST.REFUND]
			];
		}
		default: {
			return [];
		}
	}
};

/**
 * Util for generating filters based on report type
 * @author - mmt8760
 * @param {Object} filters - All filters available for details apge
 * @param {string} reportType
 * @return {Array} - Array of all filters for particular report type
 */
export const getFilters = (filters = {}, reportType) => {
	const bookingOptions = getBookingActionOptions(reportType);
	const bookingAction = { ...filters[REPORTS_CONSTANT.BOOKING_ACTION], options: bookingOptions };
	let filtersConfig = [];
	switch (reportType) {
		case REPORT_PAGE_TYPE.HOTEL: {
			filtersConfig = [
				bookingAction,
				filters[REPORTS_CONSTANT.POLICY]
			];
			break;
		}
		case REPORT_PAGE_TYPE.FLIGHT: {
			filtersConfig = [
				bookingAction,
				filters[REPORTS_CONSTANT.POLICY],
				filters[REPORTS_CONSTANT.TRIP_TYPE]
			];
			break;
		}
		case REPORT_PAGE_TYPE.FLIGHT_PNR: {
			filtersConfig = [
				bookingAction,
				filters[REPORTS_CONSTANT.POLICY],
				filters[REPORTS_CONSTANT.TRIP_TYPE]
			];
			break;
		}
		case REPORT_PAGE_TYPE.CAB: {
			filtersConfig = [
				bookingAction,
				filters[REPORTS_CONSTANT.CAB_TYPE],
				filters[REPORTS_CONSTANT.POLICY]
			];
			break;
		}
		case REPORT_PAGE_TYPE.BUS: {
			filtersConfig = [
				bookingAction
			];
			break;
		}
		case REPORT_PAGE_TYPE.TRAIN: {
			filtersConfig = [
				bookingAction,
				filters[REPORTS_CONSTANT.POLICY]
			];
			break;
		}
		default: {
			filtersConfig = [];
		}
	}

	return filtersConfig;
};

/**
 * Format incoming details List API Data as per all selected checkbox
 * @author - mmt8760
 * @param {Array} data - Incoming details list
 * @param {boolean} isAllSelected
 * @return {Object} - Selected List and the list
 */
export const formatDetailsData = (data = [], isAllSelected) => {
	const selectedList = {};
	if (data.length > 0 && isAllSelected) {
		data.forEach((detail) => {
			selectedList[detail.lineId] = true;
		});
	}
	return {
		selectedList
	};
};

/**
 * Format data according to which row in listing is selected
 * @author - mmt8760
 * @param {Object} selectedList - Previously Selected List
 * @param {Object} payload - payload of selected items & its checked state
 * @return {Object} - Selected List and the list
 */
export const handleSelected = (selectedList = {}, payload = {}) => {
	const { items = [], checked = false } = payload;
	items.forEach((item) => {
		if (checked) {
			selectedList[item.lineId] = true;
		} else {
			delete selectedList[item.lineId];
		}
	});
	const updatedSelectedList = items.length === 0 ? {} : { ...selectedList };
	return { selectedList: updatedSelectedList };
};

const setOptionsCount = (countData) => (option) => option.count = get(countData, option.countKey);

/**
 * Format Filters with count data
 * @author - mmt8760
 * @param {Object} countData - countData from API
 * @param {Array} filters - all filters for a particular report
 * @return {Array} - Updated Filters with count
 */
export const formatFilterCounts = (countData, filters) => {
	let filterData = filters;
	if (countData) {
		const setCount = setOptionsCount(countData);
		filterData = cloneDeep(filters);
		filterData.forEach((filter) => filter.options.forEach(setCount));
	}
	return filterData;
};

export const omnitureLogging = (prop, eventName) => {
	let pageName = 'mybusinessadmin:reports:revampdetails|corporate';
	logOmniClick(eventName, {
		prop54: prop,
		pageName: pageName
	});
};
/**
 * Generate download report payload
 * @author - mmt8760
 * @typedef {Object} RawPayload
 * @property {boolean} isAllSelected - Fields to denote all fields are selected or not
 * @property {Object} selectedRecord - Object containing key s all selected booking id
 * @property {Object} query - Filter query
 * @property {string} reportSearchStr - report search string,
 * @property {string} reportType - type of report
 *
 * @param {RawPayload} - raw payload data
 * @return {Object} - request payload data
 */
export const getDownloadReportPayload = ({ isAllSelected, selectedRecords, query, reportSearchStr, reportType, reportLevel, mode }) => {
	const payload = {
		type: reportType,
		reportLevel,
		reportsFieldPreference: REPORTS_CONSTANT.DEFAULT_REPORTS_FIELD_PREFERENCE
	};
	const allSelected = mode || isAllSelected || reportType === REPORT_PAGE_TYPE.SAVINGS_SUMMARY;
	if (allSelected) {
		payload.filter = reportSearchStr ? { reportSearchStr, isAllSelected: allSelected } : { ...query, isAllSelected: true };
	} else {
		const downloadIds = Object.keys(selectedRecords).filter((key) => selectedRecords[key]);
		payload.downloadIds = downloadIds;
	}
	if (mode) {
		payload.mode = mode;
	}
	return payload;
};

/**
 * Return Sort prop based on report type
 * @author - mmt8760
 * @typedef {Object} SortProp
 * @property {string} key - Unique column sorting key
 * @property {string} direction - Sorting Sirection ASC/DESC
 * @return {SortProp}
 */
export const getSortProp = () => {
	let prop = SORT_PROP;
	try {
		if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
			const { params = {} } = matchPath(window.location.pathname, {
				path: `${PATHS.REPORT_DETAIL_VIEW}:reportType`
			}) || {};
			const { reportType = '' } = params;
			if (reportType.toUpperCase() === REPORT_PAGE_TYPE.SAVINGS_SUMMARY || reportType.toUpperCase() === REPORT_PAGE_TYPE.SAVINGS) {
				prop = SAVINGS_SORT_PROP;
			}
		}
	} catch (error) {
		saveServerLogs(error.stack, 'error', 'getSortProp');
	}

	return prop;
};

/**
 * Return whether there are more rows or not based on report type
 * @author - mmt8760
 * @param {number} responseLength - length of response array
 * @param {number} pageLimit - page limit from api response
 * @param {string} reportType - type of report
 * @return {boolean} whether there are more rows or not
 */
export const isMoreRows = (responseLength, pageLimit, reportType) =>
	reportType === REPORT_TYPES.SAVINGS_TYPE ? responseLength > 0 && pageLimit <= responseLength : responseLength > 0;
