import styled, { css } from 'styled-components';
import { RowWrap } from '../ListStyles';

export const ListingDtlsRow = styled(RowWrap)`
		border : ${(props) => props.bdr};
		border-bottom: ${(props) => props.hideBorder ? 'none' : '1px solid #dddddd'};
		border-radius:${(prop) => prop.bdrRad}
    flex:1;
    color:#000;
    font-size:14px;
    
    background-color:${(props) => props.bgColor ? props.bgColor : 'transparent'};
		${(props) => props.hoverShadow && css`
			transition: ease all 0.3s;
			cursor: pointer;
			&:hover {
				box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
				border-radius: 5px;
				border: 1px solid transparent;
			}`}
    ${(props) => props.isDisabled && css`
			position: relative;
			&:after {
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				z-index: 1;
				background: #fff;
				width: 100%;
				height: 100%;
				opacity: 0.77;
				cursor: no-drop;
      }`}
`;
