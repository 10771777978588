import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { CheckinOutdates, DayPickerHeader, DateFiled, DateFiledSaperator, DaypickerWrapper } from './Styles';
export default class MMTDatepicker extends React.Component {
	constructor(props) {
		super(props);
		this.getInitialState = this.getInitialState.bind(this);
		this.isSelectingFirstDay = this.isSelectingFirstDay.bind(this);
		this.handleDayClick = this.handleDayClick.bind(this);
		this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
		this.state = this.getInitialState();
	}

	getInitialState() {
		return {
			from: this.props.from,
			to: this.props.to,
			enteredTo: this.props.to
		};
	}

	isSelectingFirstDay(from, to, day) {
		const isBeforeFirstDay = from && day && DateUtils.isDayBefore(day, from);
		const isRangeSelected = from && to;
		const isFrom = this.props.selectedType === 'from';
		return (from && isBeforeFirstDay && isRangeSelected) || isFrom;
	}

	handleDayClick(day, { disabled }, event) {
		event && event.stopPropagation();
		const {
			from,
			to,
			enteredTo
		} = this.state;
		const {
			updateFromDate,
			updateToDate,
			isSingle
		} = this.props;
		if (disabled) {
			return;
		}

		if (!isSingle) {
			const range = DateUtils.addDayToRange(day, this.state);
			if (!enteredTo) {
				this.setState({
					from: range.from,
					enteredTo: day
				});
				updateFromDate(range.from);
			} else {
				this.setState(range);
				updateFromDate(range.from);
				updateToDate(range.to);
			}
		} else {
			this.setState({
				from: day,
				enteredTo: null
			});
			updateFromDate(day);
		}
	}

	handleDayMouseEnter(day) {
		const {
			from,
			to
		} = this.state;
		if (!this.isSelectingFirstDay(from, to, day)) {
			this.setState({
				enteredTo: day
			});
		}
	}

	render() {
		const { isSingle, disabledDays } = this.props;
		const { from, to } = this.state;
		const modifiers = { start: from, end: to };
		const options = { year: 'numeric', month: 'short', day: 'numeric' };
		const today = new Date();
		let after = today;
		const isFrom = this.isSelectingFirstDay(from, to);
		let calMonth;
		if (!isSingle) {
			calMonth = this.props.from ?
				new Date(this.props.from.getFullYear(), this.props.from.getMonth()) :
				new Date();
			if (!isFrom && to) {
				calMonth = this.props.to ?
					new Date(this.props.to.getFullYear(), this.props.to.getMonth()) :
					new Date();
			}
		}
		return (
			<DaypickerWrapper>
				<DayPickerHeader>
					<div className='makeFlex'>
						<DateFiled className={(isFrom) ? 'active' : ''} onClick={this.props.selectDateType ? this.props.selectDateType.bind(this, 'from', true) : undefined}>
							<CheckinOutdates>  {!from && '--'} {from && `${from.toLocaleDateString('en-GB', options)}`} </CheckinOutdates>
						</DateFiled>
						{!isSingle && (
							<>
								<DateFiledSaperator>-</DateFiledSaperator>
								<DateFiled className={(!isFrom) ? 'active' : ''} onClick={this.props.selectDateType.bind(this, 'to', true)}>
									<CheckinOutdates> {!to && 'Select'} {to && `${to.toLocaleDateString('en-GB', options)}`}</CheckinOutdates>
								</DateFiled>
							</>
						)}

					</div>
					{/* {from && to && (<button className="link font10 latoBlack" onClick={this.handleResetClick}> CLEAR </button> )}*/}
				</DayPickerHeader>

				<DayPicker
					className='Selectable'
					numberOfMonths={isSingle ? 1 : 2}
					selectedDays={isSingle ? [from] : [from, { from, to }]}
					modifiers={modifiers}
					onDayClick={this.handleDayClick}
					disabledDays={disabledDays || { after: after }}
					month={calMonth}
				/>
			</DaypickerWrapper>
		);
	}
}
