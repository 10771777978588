import { PATHS } from '../AppConstants';
import { validEmail } from 'UtilityComponents/Util';
import { ASSET_PATH } from 'assetsPath';

export const cancelModalDtls = {
	title: 'Cancel This Request ?',
	subTitle: 'Provide your reason for cancellation to the travel desk.',
	customClass: 'cancelItenaryOverlay'
};

export const moreOptionsModalDtls = {
	title: 'Request for More Options',
	subTitle: 'Your request will be shared with the travel desk.',
	customClass: 'moreOptionsOverlay'
};

export const OTHER = 'Other';
export const CANCEL_REASON_PLACEHOLDER = 'Enter your reason here';
export const OPTIONS_REASON_PLACEHOLDER = 'Enter your reason for asking more Options';
export const CANCEL_ERR_MSG = 'Failed To Perform Cancel Operation';
export const MORE_OPTN_ERR_MSG = 'Failed to Perform This Action';
export const OPTION_MODAL_SUBHEADING = 'More Options Needed For';
export const CANCEL_MODAL_SUBHEADING = 'Reason for cancellation';
export const NO_REQ_TO_APPROVE = 'You don’t have any requests to approve';
export const NO_REQ_TO_APPROVE_SUBHEADING = 'You will be notified when you get travel requests from your reportees';
export const NO_REQ_RAISE = 'You have not raised any travel request';
export const NO_REQ_RAISE_SUBHEADING = 'All travel requests that are raised by you will appear here';
export const NO_OPEN_REQUESTS = 'You do not have any open requests';
export const NO_OPEN_REQUESTS_SUBHEADING = 'All travel requests that are raised by you will appear here';
export const AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION';
export const VIEW_TRAVEL_OPTIONS_CTA = 'VIEW TRAVEL OPTIONS';
export const FROM_TRAVEL_DESK = 'SHORTLISTED BY TRAVEL DESK';
export const SHARED_TO_COLLEAGUES = 'SHORTLISTED FOR COLLEAGUES';
export const YOUR_REQUESTS = 'YOUR TRAVEL REQUESTS';
export const FOR_YOU_TO_APPROVE = 'FOR YOU TO APPROVE';
export const OPEN_REQUESTS = 'OPEN REQUESTS';
export const HOTELS_TAB_HEADING = 'HOTELS';
export const FLIGHT_TAB_HEADING = 'FLIGHTS';
export const HOTEL_TAB_ID = 'hotelTab';
export const FLIGHT_TAB_ID = 'flightTab';
export const CANCEL_REQUEST_CTA = 'CANCEL REQUEST';
export const FLIGHT_HEADING_PRE = 'Flight to ';
export const HOTEL_HEADING_PRE = 'Hotel in ';
export const OOP = 'OUT OF POLICY';
export const MORE_OPTION_SPECIFY_ERR = 'Please specify the reason';

export const SHORTLIST_STATUS = {
	PARTIAL_CONFIRMED: 'PARTIAL_CONFIRMED',
	OPTIONS_REQUESTED: 'OPTIONS_REQUESTED',
	CONFIRMED: 'CONFIRMED',
	SHARED: 'SHARED',
	AWAITING_CONFIRMATION: 'AWAITING_CONFIRMATION'
};

export const STATUS = {
	ACTIVE: 'ACTIVE',
	SELECTED: 'SELECTED',
	BOOKED: 'BOOKED',
	SHARED: 'SHARED',
	['MORE_OPTIONS_REQUESTED']: 'MORE_OPTIONS_REQUESTED',
	CONFIRMED: 'CONFIRMED',
	['PENDING APPROVAL']: 'PENDING APPROVAL'
};

export const STATUS_COLOR = {
	CONFIRMED: 'green',
	['PENDING APPROVAL']: 'blue',
	['MORE_OPTIONS_REQUESTED']: 'yellow',
	['SHARED']: 'blue'
};

export const STATUS_CTA = {
	CONFIRMED: 'CONTINUE TO BOOK',
	['MORE_OPTIONS_REQUESTED']: 'SHORTLIST AGAIN'
};

export const STATUS_CTA_TEXT = {
	SHARED: 'BOOKING SUMMARY'
};

export const STATUS_APPROVAL_TEXT = {
	SHARED: 'Shared for approval'
};

export const LOB = {
	FLIGHT: 'FLIGHT',
	HOTEL: 'HOTEL',
	CABS: 'CABS',
	BUS: 'BUS'
};

export const lobCountDtls = {
	[LOB.FLIGHT]: {
		count: 0,
		searchUrl: PATHS.FLIGHTS_PAGE_URL,
		text: 'Flights'
	},
	[LOB.HOTEL]: {
		count: 0,
		searchUrl: PATHS.HOTEL_PAGE_URL,
		text: 'Hotels'
	}
};

export const ERROR_CONTENT = 'Something went wrong. Please try again';

export const ERROR_HEADING = 'OOPS!';

export const REMINDER_HEADING = 'Ok!';
export const REMINDER_CONTENT = 'Reminder Sent Successfully!';
export const GUEST_NAME = 'Guest';
export const GUEST_FLAG = 'G';

export const STATUS_TYPE = {
	ALL_REQUESTS: {
		label: 'ALL REQUESTS',
		value: 'ALL REQUESTS'
	}
};

export const SORT_TYPE = {
	TRAVEL_DATE: {
		label: 'Travel Date',
		value: 'travelDate'
	},
	REQUEST_DATE: {
		label: 'Request Date',
		value: 'requestDate'
	}
};

export const TRAVEL_DESK_FILTER_TYPES = {
	STATUS_TYPE_FILTER: 'statusType',
	SORT_BY_FILTER: 'sortBy',
	BY_NAME_FILTER: 'byName'
};

export const travelDeskFilters = {
	requestType: {
		key: TRAVEL_DESK_FILTER_TYPES.STATUS_TYPE_FILTER,
		type: 'DROPDOWN',
		value: ''
	},
	sortBy: {
		prefix: 'Sort By',
		key: TRAVEL_DESK_FILTER_TYPES.SORT_BY_FILTER,
		type: 'DROPDOWN',
		value: '',
		options: [
			{
				value: SORT_TYPE.TRAVEL_DATE.value,
				label: SORT_TYPE.TRAVEL_DATE.label,
				filterKey: TRAVEL_DESK_FILTER_TYPES.SORT_BY_FILTER,
				index: 0
			},
			{
				value: SORT_TYPE.REQUEST_DATE.value,
				label: SORT_TYPE.REQUEST_DATE.label,
				filterKey: TRAVEL_DESK_FILTER_TYPES.SORT_BY_FILTER,
				index: 1
			}
		]
	},
	byName: {
		key: TRAVEL_DESK_FILTER_TYPES.BY_NAME_FILTER,
		type: 'text',
		value: '',
		placeholderText: 'Search Employee name',
		searchIcon: 'icSearch'
	}
};

export const EmailField = {
	isMulti: false,
	errorOnBlur: true,
	label: 'Enter Traveller Email Id',
	value: '',
	name: 'name',
	displayLabel: 'Name',
	noChangeError: true,
	validation: {
		required: {
			msg: 'Please fill this to proceed.'
		},
		custom: {
			validator: validEmail,
			msg: 'Please Enter Valid Email'
		}
	},
	type: 'email'
};

export const approvalCardColors = {
	'approved': 'green',
	'pending': 'blue',
	'rejected': 'red',
	'bookingPending': 'green'
};
export const requisitionCardColors = {
	'APPROVED': 'reqgreen',
	'PENDING': 'reqyellow',
	'REJECTED': 'red',
	'RECALLED': 'reqgrey'
};

export const breadCrumbItems = ['Travel Requests'];
export const SHARED_CART_ERROR = 'This itinerary is already shared. Please see the shared itinerary details from your Travel Request section.';

export const MORE_REQ_CART_ERROR = 'You have not added any new Shortlisted Travel Option. Please add the same & then proceed.';

export const RAISE_CONTAINER_HEADING = 'Get a single approval on all the services of your work trip';

export const flightNoteImage = `${ASSET_PATH.IMAGES}travreqform/flightNote.png`;

export const RaiseBtnTxt = 'Raise a Request';

export const CTA_ROLE = {
	'APPROVER': 'TAKE ACTION',
	'CENTRAL_BOOKER': 'PROCEED TO BOOK',
	'REQUESTER': 'VIEW DETAILS'
};

export const REQUISITION_STATUS_TEXT = {
	'PENDING': 'AWAITING APPROVAL',
	'APPROVED': 'APPROVED',
	'REJECTED': 'REJECTED',
	'RECALLED': 'CANCELLED'
};

export const CONSTANTS = {
	CHECKIN: 'Check In',
	CHECKOUT: 'Check Out',
	DEPART: 'Depart',
	RETURN: 'Return'
};

export const iconin = {
	'HOTEL': 'icHotelGrey',
	'FLIGHT': 'icFlightGrey',
	'CABS': 'icCabsGrey',
	'BUS': 'icBusGrey'
};
