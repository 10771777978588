import styled, { css } from 'styled-components';
import { ASSET_PATH } from '../../assetsPath';
import { FlexDiv, GenericSpan } from '../common/Styles';
import { APPLICATION_CONSTANTS } from '../AppConstants';

export const LobItem = styled.li`
	display: flex;
	align-items: center;
	padding: 10px 12px;
	font-size: 16px;
	cursor: pointer;
	border-radius: 4px;
	color: ${(props) => (props.isActive ? '#351e2e' : '#4a4a4a')};
	font-weight: ${(props) => (props.isActive ? '900' : 'normal')};
	opacity: ${(props) => (props.isActive ? 1 : 0.5)};
`;

export const Icon = styled.span`
	width: 30px;
	height: 30px;
	margin-right: 14px;
`;

export const PolicySpriteIcon = styled.span`
	background:url(${ASSET_PATH.IMAGES}policy_sprites.png?v=1) no-repeat;
	background-size: 145px 180px;
	&.flightIcon {
		width: 30px;
		height: 30px;
		background-position: -5px -5px;
		display: inline-block;
	}
	&.flightGrayIcon {
		width: 30px;
		height: 30px;
		background-position: -85px -5px;
		display: inline-block;
	}
	&.hotelGrayIcon {
		width: 30px;
		height: 30px;
		background-position: -45px -45px;
		display: inline-block;
	}
	&.hotelIcon {
		width: 30px;
		height: 30px;
		background-position: -45px -5px;
		display: inline-block;
	}
	&.cabGrayIcon {
		width: 30px;
		height: 30px;
		margin-top:5px;
		background-position: -5px -45px;
		display: inline-block;
	}
	&.cabIcon {
		width: 22px;
		height: 20px;
		margin-top:5px;
		margin-left: 4px;
		background-position:-86px -45px;
		display: inline-block;
	}
	&.locationIcon {
		width: 14px;
		height: 18px;
		background-position: -125px -58px;
		display: inline-block;
	}
	&.eyeIcon {
		width: 18px;
		height: 18px;
		background-position: -34px -85px;
		display: inline-block;
	}
	&.busGrayIcon {
		width: 20px;
		height: 20px;
		margin-left: 7px;
		margin-top:5px;
		background-position: 0px -142px ;
		display: inline-block;
	}
	&.busIcon {
		width: 20px;
		height: 20px;
		margin-left: 7px;
		margin-top:5px;
		background-position: -23px -142px;
		display: inline-block;
	}
	&.trainIcon {
		width: 20px;
		height: 26px;
		margin-left: 7px;
		background-position: -72px -134px;
		display: inline-block;
	}
	&.trainGrayIcon {
		width: 20px;
		height: 26px;
		margin-left: 7px;
		background-position: -91px -136px;
		display: inline-block;
	}
	&.starDefault {
		width: 15px;
    height: 14px;
    background-position: -113px -142px;
		display: inline-block;
	}
	&.starActive {
		width: 15px;
    height: 14px;
    background-position: -129px -142px;
		display: inline-block;
	}
	&.reportIcon {
		width: 26px;
    height: 25px;
    margin-top: 5px;
    background-position: -99px -66px;
    display: inline-block;
	}
	&.reportActiveIcon {
		width: 17px;
    height: 25px;
    margin-top: 5px;
		margin-left: 4px;
    background-position: -108px -40px;
    display: inline-block;
	}
	&.miceIcon {
		width: 28px;
    height: 22px;
    margin-top: 5px;
    background-position: -33px -160px;
    display: inline-block;
	}
	&.miceGrayIcon{
		width: 25px;
    height: 20px;
    margin-top: 5px;
		margin-left: 2px;
    background-position: -3px -160px;
    display: inline-block;
	}
	&.giftCardIcon{
		width: 28px;
    height: 20px;
    margin-top: 5px;
    background-position: -95px -160px;
    display: inline-block;
	}
	&.giftCardGrayIcon{
		width: 25px;
    height: 20px;
    margin-top: 5px;
		margin-left: 2px;
    background-position: -67px -160px;
    display: inline-block;
	}
`;

export const SpriteIcon = styled(GenericSpan)`
	background: url(${ASSET_PATH.IMAGES}policy_sprites.png?v=1) no-repeat;
	width: 18px;
	height: 18px;
	display: inline-block;
	background-size: 145px 180px;
	${(props) => {
		switch (props.icon) {
			case 'book':
				return css`
							background-position: -125px -5px;`;
			case 'rupee':
				return css`
							width: 20px;
							height: 11px;
							background-position: -31px -115px;`;
			case 'calender':
				return css`
							width: 15px;
							height: 15px;
							background-position: -125px -88px;
							`;
			case 'rupeeSym':
				return css`
						background: transparent;
						&:before {
							content: '${APPLICATION_CONSTANTS.RUPEE}';
							font-size: 20px;
							line-height: 18px;
							color: #747474;
						}`;
			case 'priceDropIcon':
				return css`
							width: 20px;
							height: 20px;
							background-position: -5px -85px;
	`;
			case 'comfort':
				return css`
						width: 17px;
						height: 18px;
						background-position: -90px -85px;
	`;
			case 'block':
				return css`
						width: 16px;
						height: 16px;
						background-position: -5px -115px;`;
			case 'lock':
				return css`
						width: 15px;
						height: 18px;
						background-position: -125px -33px;`;
			case 'lockKey':
				return css`
						width: 18px;
						height: 18px;
						background-position: -62px -80px;`;
			case 'default':
				return css`
						background-position: -77px -8px;`;
			case 'deleteIconGrey':
				return css`
						width:16px;
						background-position: -125px -5px;
						margin-right: 15px;
						`;
			case 'mgr_add_icon':
				return css`
						background-position: -110px -114px;
						`;
			case 'approval':
				return css`
						background-position:-54px -115px;
						`;
			case 'usr_num_icon':
				return css`
						width: 20px;
						height: 20px;
						background-position: -112px -116px;
						`;
			case 'insurance':
				return css`
						width: 15px;
						height: 18px;
						background-position: -123px -160px;
						`;
			case 'infoIcon':
				return css`
						width: 20px;
						height: 20px;
						margin-left: 10px;
						background-position: -48px -140px ;
						`;
			default:
				return css`
						display:none`;
		}
	}}
`;

export const SectionContainer = styled(FlexDiv)`
	&.cmft_conv {
		.cntrl_cont {
			&.addons{
				flex-direction: column;
				width: auto;
				align-items: flex-start;
			}
			&.policy_optn {
				margin-top: -40px;
			}
		}   
	}
	.cntrl_cont{
		@media (max-width: 768px) {
			flex-wrap: wrap;
		}
	}
	&.req_form .emp_eligible {
		flex-direction: column;
    align-items: flex-start;
	}
`;

export const Container = styled(FlexDiv)`
padding: 34px;
padding-left: 24px;
padding-right: 0;
padding-bottom: 0;
justify-content: unset;
align-items: flex-start;

@media (max-width: 768px) {
	padding-left: 0;
}

&.sharedCabSubFld {
	padding-top: 0;
}

&.outstn {
	padding-top: 16px;
}

&.prc {
	padding-top: 8px;
}
`;

export const HeaderContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`;

export const Label = styled.div`
width: 270px;
font-family: Lato;
font-size: 16px;
font-weight: bold;
line-height: 1.25;
color: #351e2e;
margin-right: 21px;
`;
export const Description = styled.div`
margin-top: 3px;
font-family: Lato;
font-size: 13px;
line-height: 1.54;
color: #777777;
font-weight: normal;
`;
export const ControlContainer = styled(FlexDiv)`
	position: relative;
	flex-direction:${(props) => props.flexDirection || 'row'};
	opacity: ${(props) => (props.$isDisabled ? 0.5 : 1)};
	pointer-events: ${(props) => (props.$isDisabled ? 'none' : 'auto')};
	width: ${(props) => props.width || '335px'};

	@media (max-width: 768px) {
		width: 100%;
		align-items: baseline; 
	}
	&.max_price{
		width: calc(100% - 310px);
		align-items: baseline;
		flex-direction: row;
		flex-wrap: wrap;
		p {
			margin-top: 10px;
			width: 100%;
		}
		@media (max-width: 768px) {
			width: 100%;
		}
	}
	&.htl_mf {
		label.prefix-lbl input{
			padding-left: 45px;
		}
	}

	&.wlt_optns{
		width: calc(100% - 291px);
		@media (max-width: 768px) {
			width: 100%;
		}
		flex-wrap: wrap;
		align-items: center;
		>div:first-of-type {
			width: 335px;
			@media (max-width: 768px) {
				width: 100%;
			}
			margin-right: 12px;
		}
		>p{
			margin-top: 10px;
			width: 100%;
		}
	}
	&.wlt_force_disable, &.auto_select_insurance, &.f_autobook_add_on_failure {
		width: calc(100% - 290px);
		@media (max-width: 768px) {
			width: 100%;
		}
	}
	&.flt_dynmic_price {
		margin-bottom: 20px;
	}
	&.emp_eligible {
		.policy-drpdwn {
			margin-bottom: 15px;
		}
	}
	&.p_addons, &.personal_book, &.req_serv, &.flt_dynmic_price, &.emp_eligible, &.guest_house_policy{
		width : auto;
		flex-direction : column;
		align-items: baseline;
	}
	&.cabin{
		width: calc(100% - 291px);
		@media (max-width: 768px) {
			width: 100%;
		}
	}
	&.cab_class{
		position: static;
	} 
	&.anc_wlt_addon{
		width:  calc(100% - 291px);
		@media (max-width: 768px) {
			width: 100%;
		}
	}   

	.InnerSelct__option  {
		display: flex;
		align-items: center;
	}
	.InnerSelct__value-container {
		padding: 10px;
	}


	&.bkng_aprvl_optns, &.intl_bkng_aprvl_optns{
		width: calc(100% - 291px);
		@media (max-width: 768px) {
			width: 100%;
		}
		flex-wrap: wrap;
		align-items: center;
		>div:first-of-type {
			width: 335px;
			@media (max-width: 768px) {
				width: 100%;
			}
			margin-right: 12px;
		}
		p {
			margin-bottom: 0;
		}
		>div:last-of-type {
			width: 335px;
			@media (max-width: 768px) {
				width: 100%;
			}
			margin-top: 12px;
		}
		.policy-pref-drpdwn .prefix {
			min-width: 115px;
		}
	}

	&.in_pol_def_mgr, &.oop_def_mgr, &.intl_in_pol_def_mgr, &.intl_oop_def_mgr  {
		flex-direction: column;
		.policy-drpdwn {
			margin-bottom: 15px;
		}
	}
	.tabdrpdwnWrap{
		.infotooltp{
			position: absolute;
			right: -35px;
			top: 15px;
		}
	}


	&.in_pol_def_mgr , &.oop_def_mgr , &.cnd_aprvl_mgr, &.intl_in_pol_def_mgr, &.intl_oop_def_mgr {
		.opt-icon {
			position: relative;
			.secondary-icon {
				position: absolute;
				right: -3px;
				bottom: -1px;
				font-size: 10px;
				text-align: center;
				line-height: 12px;
				width: 12px;
				height: 12px;
				background: gray;
				color: #fff;
				border-radius: 50%;
			}
		}
	}

	&.cnd_aprvl_mgr, &.intl_cnd_aprvl_mgr, &.time_class {
		align-items: end;
		flex-wrap: wrap;
		width: calc(100% - 291px);
		@media (max-width: 768px) {
			width: 100%;
		}
		.policy-pref-drpdwn .prefix {
			min-width: 115px;
		}
		>.policy-drpdwn {
			margin-right: 5px;
			width: 335px;
			@media (max-width: 768px) {
				width: 100%;
			}
			&:first-child {
				width: 235px;
			}
			&:nth-child(2) {
				width: 150px;
			}
			&:first-of-type, &:nth-of-type(2) {
				.InnerSelct__control {
					background: #f2f2f2;
				}
			}
		}
		>div {
			min-width: 100px;
			margin-bottom: 10px;
			&:nth-child(3) {
				width: 100px;
			}
			&.tabdrpdwnWrap:first-of-type{
				margin-top:12px;
			}
			&:last-of-type {
				width: auto;
				@media (max-width: 768px) {
					width: 100%;
				}
			}
		}
		>div>.tabDropdown {
			width: 335px;
			@media (max-width: 768px) {
				width: 100%;
			}
		}
	}
	&.wlt_auto_book_approval {
		width: calc(100% - 290px);
		@media (max-width: 768px) {
			width: 100%;
		}
		>p{
			width: 100%
		}
	}

	&.sharedCabSubFld {
		&.descFld {
			margin-top: 10px;
			width: 330px;

			p {
				width: 100%
			}
		}

		&.emptyFld {
			margin-top: 10px;

			p {
					color: #A5572A;
					font-size: 12px;
					line-size: 14px;
				}
		}

		&.inpFlds {
			align-items: center;
			gap: 8px;
			margin-top: 16px;

			& > div:nth-of-type(2) {
				margin-top: 26px;
			}
		}
	}
`;

export const AddMoreBtn = styled.span`
	color: #f17531;
	font-size: 14px;
	font-weight: bold;
	display: flex;
	align-items: center;
	cursor: pointer;
	border: 1px solid rgba(241, 117, 49, 0.5);
	padding: 14px 10px;
	width: ${(props) => props.width ? props.width : '335px'};
	justify-content: ${(props) => props.justify ? props.justify : 'flex-start'};
	border-radius: 4px;
	margin-top: ${(props) => props.marginTop ? props.marginTop : '0px'};
	@media (max-width: 768px) {
		width: 100%;
	}
`;
export const Plus = styled.span`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	background: #fdf1ed;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	font-size: 18px;
	margin-right: 10px;
`;

export const DeleteIcon = styled.span`
	margin-left: 10px;
	align-self: center;
	& > span.deleteIconGrey {
		margin-right: 0 !important;
	}
	& .deleteIconGrey:hover {
		background-position: -2px -51px;
	}
`;

export const InfoIcon = styled.span`
	position: relative;
	cursor: pointer;
	color: #fff;
	.ico-pol {
		filter: invert(44%) sepia(62%) saturate(5122%) hue-rotate(191deg) brightness(103%) contrast(106%)
	}
	& .tooltip-wrap{
		display: none;
	}
	&:hover .tooltip-wrap {
		display: block;
	}
	.content {
		h5 {
			margin-bottom: 10px;
			font-weight: 700;
		}
	}
`;
