import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import {
	logOmniClick
} from '../logging/omnitureService';
import { getSearchParams, getDate, getCountText } from './Util';
import { LOB } from '../shortListNShare/config';
import { GUEST_NAME, GUEST_FLAG } from '../shortListNShare/config';

// track wallet Events
export const trackShortListEvents = (eventType, isMobile) => {
	logOmniClick(`shortlist_click${isMobile ? '_mob' : ''}`, {
		prop54: eventType
	});
};

export const validHotelDate = (dateStr) => {
	if (typeof dateStr !== 'string' && dateStr.length !== 8) {
		return '';
	}
	const month = dateStr.slice(0, 2);
	const date = dateStr.slice(2, 4);
	const year = dateStr.slice(4);
	return getDate(`${year}-${month}-${date}`);
};

export const appendWishId = (url, wishId) => {
	const iD = wishId ? wishId : getSearchParams().wishlistId;
	return `${url}?wishlistId=${iD}`;
};

export const sortFlightData = (groups) => {
	try {
		const sortedDate = groups.sort(function(a, b) {
			const date1 = new Date(a.date || (a.group && a.group.depDateTime) || (a.productMetadata && a.productMetadata.depDateTime));
			const date2 = new Date(b.date || (b.group && b.group.depDateTime) || (b.productMetadata && b.productMetadata.depDateTime));
			return moment.utc(date1.getTime()).diff(moment.utc(date2.getTime()));
		});
		return sortedDate;
	} catch (e) {

	}
};

export const sortHotelData = (groups) => {
	try {
		const sortedDate = groups.sort(function(a, b) {
			const date1 = a && a.date || validHotelDate((a.group && a.group.checkin) || (a.productMetadata && a.productMetadata.checkin));
			const date2 = b && b.date || validHotelDate((b.group && b.group.checkin) || (b.productMetadata && b.productMetadata.checkin));
			return moment.utc(date1).diff(moment.utc(date2));
		});
		return sortedDate;
	} catch (e) {

	}
};

export const sortLobData = (flightGroup, hotelGroup) => {
	const sortedFlightData = sortFlightData(flightGroup);
	const sortedHotelData = sortHotelData(hotelGroup);
	const sortedData = {};
	for (const fData of sortedFlightData) {
		sortedData[fData.grpKey] = fData.group;
	}
	for (const hData of sortedHotelData) {
		sortedData[hData.grpKey] = hData.group;
	}
	return sortedData;
};

export const sortGrpDetails = (groupDetails) => {
	const flightGroup = [];
	const hotelGroup = [];
	for (let grpKey in groupDetails) {
	  let group = groupDetails[grpKey];
	  const lob = group && group.lob;
	  if (lob && lob === LOB.HOTEL) {
		  hotelGroup.push({ grpKey, group });
	  } else if (lob && lob === LOB.FLIGHT) {
		  flightGroup.push({ grpKey, group });
	  }
	}
	return sortLobData(flightGroup, hotelGroup);
};

export const getTravellerName = (recipientDetails = {}, bookingFor = {}) => {
	let { name: travellerName = '', bf } = bookingFor;
	const { email } = recipientDetails;

	if ((bf && bf === GUEST_FLAG) || isEmpty(travellerName)) {
		travellerName = email ? `${GUEST_NAME + ' (' + email + ')'}` : GUEST_NAME;
	}
	return travellerName;
};

export const getTravDetTxt = ({ adultCount, childCount, infantCount }) =>
	`${getCountText(adultCount, 'Adult')} ${childCount ? '+' : ''} ${getCountText(childCount, 'Child', 'Children')} ${infantCount ? '+' : ''} ${getCountText(infantCount, 'Infant')}`;
