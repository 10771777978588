import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	SignInContainer,
	InputLogin
} from './LoginPageStyles';

import { validateEmail } from 'reduxStore/actions';
import { STATES, INVALID_EMAIL_FORMAT } from 'reduxStore/actionConstants';
import CorporateModalWithHeader from 'UtilityComponents/CorpModalWithHeader';
import * as ActionConstants from 'reduxStore/actionConstants';
import { logOmniClick } from 'omnitures';
import { urlParam, isDeviceMobile } from 'UtilityComponents/Util';
import { ErrorText, GenericDiv, GenericP, Heading } from 'commonStyles';
import Button from 'common/Button/Button';
import { PATHS } from 'AppConstants';

export class SSOLoginFormComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

  updateUserName = (evt) => {
  	this.setState({
  		userName: evt.target.value
  	});
  };

  onSubmitForm = (evt) => {
  	logOmniClick('mybusinessadmin:login:submitclicked|v2|corporate', {
  		prop54: 'mybusinessadmin:login:submitclicked|v2|corporate'
  	});
  	evt.preventDefault();
  	if (!this.state.userName) {
  		return;
  	}
  	if (!validateEmail(this.state.userName)) {
  		this.props.dispatch({
  			type: ActionConstants.INVALID_EMAIL_FORMAT,
  			payload: {
  				loginState: ActionConstants.STATES.FAILED,
  				errorType: ActionConstants.INVALID_EMAIL_FORMAT,
  				errorMsg: ActionConstants.ERR_MSGS[ActionConstants.INVALID_EMAIL_FORMAT]
  			}
  		});
  		return;
  	}
  	SSOLoginFormComponent.proceedToSSOLoginUrl(this.state.userName);
  }

  static proceedToSSOLoginUrl(username) {
  	if (window) {
  		let mobileParams = isDeviceMobile() ? '&deviceType=mobile' : '';
  		window.location.href = '/sso/login?user=' + username.trim() + mobileParams;
  	}
  }

  routeToNextPage = (errorLink) => {
  	window.location.href = errorLink;
  }

  render() {
  	let userEmailFromUrl = urlParam('user');
  	if (validateEmail(userEmailFromUrl)) {
  		SSOLoginFormComponent.proceedToSSOLoginUrl(userEmailFromUrl);
  		return;
  	}
  	let errorInLogin = urlParam('error');
  	let traceId = urlParam('traceId');
  	let failed = this.props.loginState === STATES.FAILED;
  	let error = this.props.errorMsg;
  	let errorType = this.props.errorType;
  	let emailBorder = errorType === INVALID_EMAIL_FORMAT ? '1px solid red' : '';
  	let positiveCallBackCB = () => {
  		if (errorLink) {
  			this.routeToNextPage(errorLink);
  		} else {}
  	};
  	let negativeButtonCallback = () => {
  		this.routeToNextPage(PATHS.SSO_LOGIN);
  	};
  	const { reasonString, errorLinkText, errorLink } = ActionConstants.SSO_ERR_CONFIGS?.[errorInLogin] || {};
  	return (
	<div>
		<SignInContainer height='300px'>
			{errorInLogin && <CorporateModalWithHeader
				show={true + ''} title='Error in login' content={reasonString}
				positiveButtonCB={positiveCallBackCB}
				positiveButton={errorLinkText}
				negativeButton='OK'
				negativeButtonCB={negativeButtonCallback}
				traceId={traceId}
			/>}
			<form onSubmit={this.onSubmitForm}>
				<fieldset>
					<Heading marBt='20px' font_size='1.17em' f_weight='normal' as='h3'>Single Sign On</Heading>
					<GenericDiv bottom_M={25}>
						<GenericP bottom_M={5}>
							<label>Work Email*</label>
						</GenericP>
						<InputLogin
							type='text' id='name' placeholder='Email' border={emailBorder}
							onChange={this.updateUserName}
						/>
					</GenericDiv>
					{failed && error && <ErrorText as='p' margin='12 0 0 0' $width='100%' top_M={12} text_align='center'>
						*{error}
                         </ErrorText>}
					<GenericP top_M={30} text_align='center'>
						<Button
							btnType='Capsule' btnWidth='150px' btnTxt='SUBMIT'
							type='submit'
						/>
					</GenericP>
				</fieldset>
			</form>
		</SignInContainer>
	</div>
  	);
  }
}

const mapStateToProps = (state) => ({
	loginState: (state.login && state.login.loginPayload) ? state.login.loginPayload.loginState : null,
	errorMsg: (state.login && state.login.loginPayload) ? state.login.loginPayload.errorMsg : null,
	errorType: (state.login && state.login.loginPayload) ? state.login.loginPayload.errorType : null,
	popupError: (state.login && state.login.loginPayload) ? state.login.loginPayload.popupError : null,
	nextPageUrl: (state.login && state.login.loginPayload) ? state.login.loginPayload.nextPageUrl : null
});
const mapDispatchToProps = (dispatch) => ({
	dispatch: dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(SSOLoginFormComponent);
