import styled, { css } from 'styled-components';
import {SortArrow} from '../Styles';

export const Sort = styled.div`
    display: flex;
    cursor: pointer;
    position: relative;
    ${props => props.isDisabled && css`
        position: relative;
        &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            z-index: 1;
            background: #fff;
            width: 100%;
            height: 100%;
            opacity: 0.66;
        }`
    }
`

export const SortDirection = styled(SortArrow)`
    transform: ${props => !props.reverse ? "rotate(180deg)" : "rotate(0)"};
`