import React from 'react';
import { ListingDtlsRow } from './ListRowStyles';

const ListRow = (props) => {
	const { children, pointerCursor = true, hoverShadow } = props;
	return (
		<ListingDtlsRow
			className={`${pointerCursor && !hoverShadow ? 'hover-list-item' : ''}`}
			{...props}
			align={props.align || 'center'}
			justify={props.justify || 'center'}
			height={props.height || '50px'}
			left_P={props.left_P || '20'}
			right_P={props.right_P || '20'}>
			{children}
		</ListingDtlsRow>

	);
};

export default ListRow;
