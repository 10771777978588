import styled, { css } from 'styled-components';

export const HeaderWrpapper = styled.header`
	min-height: 60px;
	font-family: "lato";
	position: ${(p) => p.mmtHeader ? 'fixed' : 'static'};
	top: 0;
	left: 0;
	width: 100%;
	z-index: 5;
	background: transparent;
	padding: 0;
	${(props) => !props.showNav && css`
			height: 0;
			width: 0;`
}
	${(props) => !props.showHeader && css`
			overflow: hidden;`
}
`;

export const HeaderSep = styled.span`
		display: inline-block;
		border-right: 1px solid #171a3a;
		margin: 0 7px;
		height: 27px;
`;
