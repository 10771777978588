import styled from 'styled-components';
import { ASSET_PATH } from '../../../../assetsPath';

export const RightMenuList = styled.ul`
 list-style: none;
  @media (max-width: 700px) {
      padding-left: 0px;
    };
`;
export const ProfileMenuItem = styled.div`
    float: left;
    position: relative;
    margin-top: 18px;
    height:66px !important;
    padding-right: 25px;
`;
export const ProfileViewSpan = styled.span`
    display: inline-block;
    max-width: 144px;
    box-sizing: border-box;
     @media (max-width: 700px) {
      padding-left: 0px;
    };
`;
export const LoggedInUserAnchor = styled.a`
    padding: 15px 14px 21px;
    text-transform: uppercase;
    color: #4a4a4a;
    font-size: 10px;
    padding-top: 22px;
    padding-bottom: 14px;
`;
export const LoginUserIcon = styled.span`
    background-position: -94px 0 !important;
    width: 13px;
    height: 12px;
    margin-right: 5px;
    background:url(${ASSET_PATH.IMAGES + 'common_header.sprite_biz.png'}) no-repeat;
    display: inline-block;
`;
export const ProfileOverlay = styled.div`
    display:block;
    background-color:white;
    z-index: 1000;
    transition: .4s all ease-in-out 1ms;
    max-height: 0;
    top: 80px;
    width: 290px;
    position: absolute;
    right: -120px;
    margin-left: 12px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
    
    &:focus{
      opacity:1
    }
    &:active{
      opacity:1
    }
    @media(max-width: 700px){
       right:0px;
    }
`;
export const SwitchSection = styled.div`
    border: 1px solid #e6e6e6;
    padding: 16px;
    position: absolute;
    top: -24px;
    background-color:white;
    box-sizing: border-box;
    display: block;
    font-size: 14px;
    @media(max-width: 700px){
      right: 0px;
    }
    
`;
export const SwitchProfile = styled.div`
    box-sizing: unset;
    width: 220px;
    padding: 4px 8px;
    height: 24px;
    border-radius: 19px;
    background-color: #fff;
    border: 1px solid #cacaca;
    margin-top: 5px;
    display: flex;
     @media (max-width: 700px) {
        width: 189px;
    };
`;
export const SwitchLinkPersonal = styled.p`
    width: 50%;
    padding: 0px;
    display: inline-block;
    text-align: center;
    font-family: lato-bold,Arial,Helvetica,sans-serif!important;
    color: #4a4a4a;
    font-size: 14px;
    border-radius: 15px;
    cursor: pointer;
    text-decoration: none;
    outline: 0;
    margin: auto;
    
`;
export const SwitchLinkCorporate = styled.p`
    height: 100%;
    width:50%;
    margin: auto;
    text-align: center;
    display: inline-block;
    font-family: lato-bold,Arial,Helvetica,sans-serif!important;
    color: #4a4a4a;
    font-size: 14px;
    border-radius: 15px;
    outline: 0;
    background-image: linear-gradient(94deg,#f0772c,#f95776);
    color: #fff;
    margin: auto;
    height: auto;
    padding: 3px 0px 4px;
    @media(max-width: 700px){
      display: flex;
      margin: auto;
      padding-left: 16px;
    }
`;
export const ProfileListMenuItem = styled.li`
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 30px;
    font-size: 18px;
    color: #4a4a4a;
    cursor:pointer;
    z-index: 100;
    text-decoration: none;
    opacity:1;
    &:hover{
      background-color:#d8d8d8;
    };
    @media(max-width: 700px){
      height: 45px;
      font-size: 14px;
      padding-top: 13px;
      padding-left: 23px;
    }
`;
export const ProfileListMenuItemLink = styled.span`
    text-decoration: none;
    color: #4a4a4a;
    &:hover{
      text-decoration: none;
    }
`;
