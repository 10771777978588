import axios from 'axios';
import { getAxiosConfig } from '../apisSetup/apiConfig';
import { PATHS } from 'AppConstants';

const ccbAxiosApiCaller = getAxiosConfig(PATHS.CCB_URL, true, {
}, false);

export const CancelToken = axios.CancelToken;

let CancelTokenSource;

export const getGrp = () => ccbAxiosApiCaller.get(PATHS.GET_GROUP);

export const validateEmpApi = (payload) => {
	CancelTokenSource = CancelToken.source();
	return ccbAxiosApiCaller.post(PATHS.VALIDATE_EMP,
		payload,
		{ cancelToken: CancelTokenSource.token,
			headers: {
				'Content-Type': 'multipart/form-data'
			} });
};

export const createGrpApi = (payload) => ccbAxiosApiCaller.put(PATHS.CREATE_GROUP, payload);

export const getConflictingGrpsApi = () => ccbAxiosApiCaller.get(PATHS.GET_CONFLICTING_GRPS);

export const resolveConflictsApi = (apiData) => ccbAxiosApiCaller.post(PATHS.RESOLVE_CONFLICTS, apiData);

export const uploadCsvApi = ({ apiData, onUploadProgress, sendInvitation }) => {
	CancelTokenSource = CancelToken.source();
	return ccbAxiosApiCaller.post(PATHS.GRP_BULK_UPLOAD,
		apiData,
		{ cancelToken: CancelTokenSource.token,
			params: {
				sendInviteMail: sendInvitation
			},
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress });
};

export const cancelRequest = (msg) => {
	CancelTokenSource && CancelTokenSource.cancel(msg);
};

export const deleteGroup = (apiData) => ccbAxiosApiCaller.put(PATHS.DELETE_GROUPS, apiData);

export const downloadGrpData = (apidata) => ccbAxiosApiCaller.post(PATHS.DWL_EMP_DATA, apidata);

export const downloadGrpCSv = (apidata) => ccbAxiosApiCaller.get(PATHS.DWL_EMP_JOB, { params: apidata });
