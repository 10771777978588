import React, { Component } from 'react';
import loadable from 'react-loadable';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { AppHeader, LoginPageRightMenu } from 'common/Header';
import LoginPageComponent from './Login/LoginPageComponent';
import { APPLICATION_CONSTANTS, PATHS, REPORTS_URLS, WALLET_URLS } from 'AppConstants';
import { isDeviceMobile, urlParam } from 'UtilityComponents/Util';
import Header from './landingSignupFlow/components/Header/HeaderComponent';
import BizHeader from './bizHeader/index';
import GlobalStyle from 'common/GlobalStyles/GlobalStyles';
import MetaTags from 'common/metaTags';
import ChatBot from 'common/ChatBot/ChatBot';
import {
	getCallbackSupport
} from 'reduxStore/actions/bizHeaderActions';
import { hidePop } from 'reduxStore/actions/popupActions';
import { defaultRadioFields } from 'common/HelpSection/callbackConfig';

const TripTagPage = loadable({
	loader: () => import('./tripTag/TripTagPage' /* webpackChunkName: 'triptag' */),
	loading: () => null,
	modules: ['triptag']
});
const EditTripTagPage = loadable({
	loader: () => import('./tripTag/pages/editTripTag/EditTripTagPage' /* webpackChunkName: 'edittriptag' */),
	loading: () => null,
	modules: ['edittriptag']
});
const InvoicePage = loadable({
	loader: () => import('./invoice' /* webpackChunkName: 'invoice' */),
	loading: () => null,
	modules: ['invoice']
});

const AdminDetailsPage = loadable({
	loader: () => import('./CompanyAdminReferral/AdminDetailsPage' /* webpackChunkName: 'referadmin' */),
	loading: () => null,
	modules: ['referadmin']
});
const ReferCompanyAdmin = loadable({
	loader: () => import('./CompanyAdminReferral/ReferCompanyAdmin' /* webpackChunkName: 'referCompanyAdmin' */),
	loading: () => null,
	modules: ['referCompanyAdmin']
});
const CharteredFlight = loadable({
	loader: () => import('./CharteredFlight/CharteredFlight' /* webpackChunkName: 'chartered' */),
	loading: () => null,
	modules: ['chartered']
});
const WalletPage = loadable({
	loader: () => import('./wallet' /* webpackChunkName: 'wallet' */),
	loading: () => null,
	modules: ['wallet']
});

const EmployeePages = loadable({
	loader: () => import('./Employee' /* webpackChunkName: 'employee' */),
	loading: () => null,
	modules: ['employee']
});

const Policy = loadable({
 	loader: () => import('./policy' /* webpackChunkName: 'policy' */),
 	loading: () => null,
	 modules: ['policy']
});

const GroupsPage = loadable({
	loader: () => import('./Groups' /* webpackChunkName: 'groups' */),
	loading: () => null,
	modules: ['groups']
});
const TrainLanding = loadable({
	loader: () => import('./train/TrainLanding' /* webpackChunkName: 'train' */),
	loading: () => null,
	modules: ['train']
});
const Dashboard = loadable({
	loader: () => import('./dashboard' /* webpackChunkName: 'dashboard' */),
	loading: () => null,
	modules: ['dashboard']
});

import Reports from './reporting';
import StatusPopup from 'common/statusPopup/statusPopup';

const PageNotFound = loadable({
	loader: () => import('UtilityComponents/pageNotFound' /* webpackChunkName: 'notfound' */),
	loading: () => null,
	modules: ['notfound']
});

const TravelReqBooking = loadable({
	loader: () => import('./manageRequestBookings' /* webpackChunkName: 'travelReqBooking' */),
	loading: () => null,
	modules: ['travelReqBooking']
});

const OrganizationDetails = loadable({
	loader: ()=>import('./organizationDetails' /* webpackChunkName: 'orgDetails' */),
	loading: ()=>null,
	modules: ['orgDetails']
});

const loginPath = [PATHS.SSO_LOGIN];
const GROUP_URLS = [PATHS.GROUPS_PAGE, PATHS.GROUPS_DETAIL_PAGE];
const REQUISITION_PATHS = [PATHS.TRAVEL_REQ_FORM, PATHS.TRAVEL_REQUEST_DETAILS];
class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showBot: false,
			helpInHeader: true,
			helpInBot: false
		};
	}

		helpSectionHandler = () =>{
			let defaultState = {
				helpInBot: false, helpInHeader: true
			};
			if (!this.props.requestFormPopUp && this.state.helpInHeader) {
				defaultState = {
					helpInBot: true, helpInHeader: false
				};
			}
			this.setState((prevState) => ({
				...prevState, ...defaultState
			}));
		}

		UNSAFE_componentWillReceiveProps(nextProps) {
			if (nextProps.callbackShared !== this.props.callbackShared && nextProps.callBackSupportCompleted !== this.props.callBackSupportCompleted &&
				!nextProps.callbackShared && nextProps.callBackSupportCompleted) {
				setTimeout(() => {
					this.setState({ showBot: true });
				}, APPLICATION_CONSTANTS.CHATBOT_DELAY);
			}
		}
    // this will validate the current state of app / current page in app and set the login page accordingly
    // todo: dynamic import of menu components
    getRightMenu = ()=> {
    	let isMobile = isDeviceMobile();
    	let deviceType = urlParam('device_type');
    	if (deviceType === 'ios' || deviceType === 'android') {
    		isMobile = true;
    	}
    	return (
	<Switch>
		<Route path='/' component={LoginPageRightMenu} />
		<Route exact path={PATHS.SSO_LOGIN} component={isMobile ? {} : LoginPageRightMenu} />
	</Switch>
    	);
    }

    componentDidMount() {
    	this.props.getCallbackSupport();
    }
		handleStatusModal = () => {
			this.props.hidePop();
		}
		// todo: dynamic import of container components
    getContainer =()=> {
    	let isAdmin = urlParam('isAdmin');
    	let showAdmin = isAdmin && isAdmin === 'true';

    	return (
	<Switch>
		<Route exact path={loginPath} component={LoginPageComponent} />
		<Route exact path={PATHS.INVOICE_PAGE} render={() => <InvoicePage />} />
		<Route exact path={WALLET_URLS} render={() => <WalletPage />} />
		<Route
			exact path={PATHS.TRIP_TAG} render={() => <TripTagPage
				menuClick={this.menuClick}
				menuOpen={this.state && this.state.menuOpen}
			/>}
		/>
		<Route
			exact path={PATHS.TRIP_TAG_EDIT} render={() => <EditTripTagPage
				menuClick={this.menuClick}
				menuOpen={this.state && this.state.menuOpen}
			/>}
		/>
		{showAdmin && <Route
			exact path={PATHS.REFERRAL_COMPANY_ADMIN} render={() => <AdminDetailsPage
				menuClick={this.menuClick}
				menuOpen={this.state && this.state.menuOpen}
			/>}
		/>}
		{!showAdmin && <Route
			exact path={PATHS.REFERRAL_COMPANY_ADMIN} render={() => <ReferCompanyAdmin
				menuClick={this.menuClick}
				menuOpen={this.state && this.state.menuOpen}
			/>}
		/>}
		<Route exact path={PATHS.CHARTERED_FLIGHT} render={() => <CharteredFlight />} />
		<Route exact path={PATHS.POLICY_PAGE} render={() => <Policy />} />
		<Route exact path={PATHS.ORGANIZATION_DETAILS_PAGE} render={()=><OrganizationDetails />} />
		<Route exact path={PATHS.EMPLOYEE_PAGE} render={() => <EmployeePages />} />
		<Route exact path={PATHS.DASHBOARD} render={() => <Dashboard />} />
		<Route exact path={GROUP_URLS} render={() => <GroupsPage />} />
		<Route exact path={PATHS.TRAIN_LANDING} render={() => <TrainLanding isMobile={this.props.isMobile} />} />
		<Route exact path={REPORTS_URLS} render={() => <Reports />} />
		<Route exact path={PATHS.TRAVEL_REQUEST_AND_BOOKING} render={() => <TravelReqBooking />} />
		<Route render={({ staticContext }) => <PageNotFound staticContext={staticContext} />} />
	</Switch>
    	);
    }

    getHeader =()=> {
    	let isMobile = false;
    	let deviceType = urlParam('device_type');
    	if (deviceType === 'ios' || deviceType === 'android') {
   	isMobile = true;
    	}
    	const { helpInHeader } = this.state;
    	let isMobileDevice = this.props.isMobile || isDeviceMobile();

    	if (isMobile) return null;
    	const noHeaderPath = [...REQUISITION_PATHS];
    	const HeaderBarPath = [PATHS.BROWSER_HCSHEADER, PATHS.DASHBOARD, (PATHS.INVOICE_PAGE),
    		PATHS.TRIP_TAG, PATHS.TRIP_TAG_EDIT, PATHS.WALLET_PAGE, PATHS.POLICY_PAGE, PATHS.EMPLOYEE_PAGE,
    		...GROUP_URLS, ...REPORTS_URLS, PATHS.ORGANIZATION_DETAILS_PAGE, PATHS.TRAVEL_REQUEST_AND_BOOKING];
    	return (
	<Switch>
		<Route exact path={[PATHS.PROCEED_TO_CORP, PATHS.PROVISIONAL_ADMIN_PAGE]} render={() => <div />} />
		<Route exact path={PATHS.REFERRAL_COMPANY_ADMIN} render={() => <div />} />
		<Route
			exact path={HeaderBarPath} render={() => <BizHeader
				helpInHeader={helpInHeader} helpSectionHandler={this.helpSectionHandler} menuClick={this.menuClick}
				menuOpen={this.state && this.state.menuOpen}
			/>}
		/>
		<Route path={[PATHS.CHARTERED_FLIGHT]} render={() => <Header />} />
		<Route
			exact path={PATHS.TRAIN_LANDING}
			render={() => isMobileDevice ? null : <BizHeader helpInHeader={helpInHeader} helpSectionHandler={this.helpSectionHandler} mmtHeader />}
		/>
		<Route path={noHeaderPath} render={() => null} />
		<Route path='/' render={() => <AppHeader children={this.getRightMenu()} />} />
	</Switch>
    	);
    }

    menuClick =(menuOpen)=> {
    	this.setState({ menuOpen: menuOpen });
    }

    render() {
    	const { helpInBot } = this.state;
    	const { showHelp } = this.props.headerOptions || {};

    	return (
	<div className='App'>
		<MetaTags />
		<GlobalStyle />
		{this.getHeader()}
		{this.getContainer()}
		{showHelp && <ChatBot radioField={defaultRadioFields} showBot={this.state.showBot} helpInBot={helpInBot} helpSectionHandler={this.helpSectionHandler} />}
		{this.props.showPop === true && <StatusPopup {...this.props.popData} handleModal={this.handleStatusModal} />}
	</div>
    	);
    }
}

const mapStateToProps = (store) => ({
	callbackShared: store.bizHeader && store.bizHeader.callbackShared,
	callBackSupportCompleted: store.bizHeader && store.bizHeader.callBackSupportCompleted,
	showPop: store.popup.showPop,
	popData: store.popup.popData,
	requestFormPopUp: store.bizHeader.requestFormPopUp,
	headerOptions: store.bizHeader && store.bizHeader.headerOptions
});

export default connect(mapStateToProps, { getCallbackSupport, hidePop })(withRouter(App));
