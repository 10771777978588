import React from 'react';
import {
	APPLICATION_CONSTANTS,
	FILTER_CONSTANTS,
	SORT_KEY,
	EMPLOYEE_CONSTANTS,
	REGEX_PATTERNS,
	PATHS,
	GROUP_CONSTANTS
} from 'AppConstants';
import {
	isValidName,
	email,
	formatName,
	validPhone,
	formatAmount,
	getXrequestId
} from 'UtilityComponents/Util';

const xreqId = getXrequestId();

import ReminderFrequency from 'common/ReminderFrequency/ReminderFrequency';
import { ASSET_PATH } from 'assetsPath';
import { EmployeeIcons } from './styles';
import { EMPLOYEE_STORE_CONST } from 'reduxStore/actionConstants';

const OnboardImage = `${ASSET_PATH.IMAGES}employee/employee-onboard.png`;

const validEmail = (value) => !email(value);

export const ValidInternationalPhone = (value) => value && REGEX_PATTERNS.INTERNATIONAL_PHONE_REGEX.test(value);

const validName = (value) => !isValidName(value);

export const PAGE_HEAD_TEXT = 'Employees';

const searchBoxTxt = 'Search and Select...';
const confirmTxt = 'Confirm as ';

export const INDIA_FIELDS_CONSTANT = {
	maxLen: '10',
	customValidation: {
		validator: validPhone,
		msg: 'Invalid phone number, must be 10 digits'
	}
};
export const OTHER_COUNTRIES_FIELDS_CONSTANT = {
	maxLen: '15',
	customValidation: {
		validator: ValidInternationalPhone,
		msg: 'Not a valid phone number'
	}
};

export const SORT_PROP = {
	key: 'name',
	value: SORT_KEY.ASCENDING
};

export const EMPLOYEE_LIST_HEADER = {
	list: [
		{
			text: 'EMPLOYEE',
			isSort: true,
			key: SORT_PROP.key
		},
		{
			text: 'EMAIL'
		},
		{
			text: 'MANAGER'
		},
		{
			text: ''
		}
	]
};

export const EMPLOYEE_LIST_GRP_HEADER = {
	list: [
		{
			text: 'EMPLOYEE',
			isSort: true,
			key: SORT_PROP.key
		},
		{
			text: 'EMAIL'
		},
		{
			text: 'GROUP'
		},
		{
			text: 'MANAGER'
		},
		{
			text: ''
		}
	]
};

export const HRMS_TEXTS = {
	heading: 'Integrate with your HRMS?',
	statusMsgs: {
		[EMPLOYEE_CONSTANTS.HRMS_STATUS.SUCCESS]: {
			text: 'HRMS Connection Successful, <span class="yellow-text">Sync Pending!</span>',
			bg: '#fff',
			bdrClr: '#D8D8D8',
			color: '#007E7D'
		},
		[EMPLOYEE_CONSTANTS.HRMS_STATUS.PENDING]: {
			text: 'HRMS connection in progress',
			bg: '#E6FFF9',
			color: '#007E7D'
		},
		[EMPLOYEE_CONSTANTS.HRMS_STATUS.FAILED]: {
			text: 'HRMS connection failed',
			bg: '#fff',
			color: '#EB2026'
		},
		[EMPLOYEE_CONSTANTS.HRMS_STATUS.SYNCED]: {
			text: '<p class="fs-16 mb-5"> <b>HRMS Employees synced</b></p><p>Latest employee List synced at </p>',
			bg: '#fff',
			color: '#000',
			rightIcon: true
		}
	},
	desc: 'We can seamlessly integrate with your HRMS. Please ',
	cta: 'click here to know more',
	icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAG1BMVEUAAAB1dXV1dXV1dXV3d3d1dXV1dXV7e3t1dXWcD+rmAAAACHRSTlMAoOTCI39VENkd9SgAAACmSURBVEjH7ZIxCsJAFEQjRm21EFKLYErRwpTRE6QJWKYzpQQ8gFlW3rHFQtAV/xRit69+zLLzJ4lE/s3QlbbQslSCO5tCB2tTSHOuickBKlMYg7cjjtCYwghudkRGb5c1QJQ1KcKIy+yFeV3nsAseDQnK4pP+TSiUsN8+2SweoL6RQSmq9GIzVGIRXg1iKgKcCljJS/1061atpVV762hsIT0lkcgX7mj2SkVEWx78AAAAAElFTkSuQmCC'
};

export const BULK_STATUS_CONFIG = {
	[APPLICATION_CONSTANTS.SUCCESS]: {
		icon: 'yellowTick',
		button: false,
		head: 'Uploaded Employee details',
		desc: 'Employee Details have been uploaded and will be processed in a while.',
		emailDesc: 'The status will be shared with you on Email in next 15 mins.',
		cta: 'Ok Got It',
		bdrClr: '#cf8100'
	},
	[EMPLOYEE_CONSTANTS.PREF_UPLOAD_SUCCESS]: {
		icon: 'yellowTick',
		button: false,
		head: 'Uploaded Employee details',
		desc: 'Employee Details have been uploaded and will be processed in a while.',
		cta: 'Ok Got It',
		bdrClr: '#cf8100'
	},
	[APPLICATION_CONSTANTS.SUCCESS]: {
		icon: 'yellowTick',
		button: false,
		head: 'Uploaded Employee details',
		desc: 'Employee Details have been uploaded and will be processed in a while. The status will be shared with you on Email in next 15 mins.',
		cta: 'Ok Got It',
		bdrClr: '#cf8100'
	},
	[APPLICATION_CONSTANTS.PARTIAL]: {
		icon: 'yellowTick',
		button: 'Download CSV',
		head: 'Partial Success !',
		cta: 'close',
		desc: 'We could only upload few of the employee details successfully.',
		desc2: '<b>Please download the CSV file</b> containing rest of the employee list which where unsuccessull. Kindly correct the details and reupload to add them.',
		bdrClr: '#cf8100'
	},
	[APPLICATION_CONSTANTS.FAILED]: {
		icon: 'redCross',
		button: false,
		head: 'Upload Failed',
		cta: 'Ok Got It',
		desc: 'We couldn’t upload the employee details due to some technical errors, Kindly try re-uploading the file after some time.',
		bdrClr: '#e02020'
	},
	[APPLICATION_CONSTANTS.PENDING]: {
		icon: '',
		button: false,
		head: 'Uploading employee details...',
		cta: 'Cancel Upload',
		desc: 'This may take a while. We will notify you when the upload is complete. Feel free to carry on with your usual office work.',
		bdrClr: '#ff674b'
	}
};

const genderField = {
	name: 'gender',
	placeHolder: 'Gender*',
	value: '',
	validation: {
		required: {
			msg: 'Required!'
		}
	},
	options: [{
		value: 'M',
		label: 'Male'
	},
	{
		value: 'F',
		label: 'Female'
	}
	],
	type: 'select',
	outLabel: true,
	errorOnBlur: true,
	customClass: 'col-2',
	trackVal: 'Employee_Invite_',
	isMandatory: true
};

const fnameField = {
	name: 'fname',
	label: '',
	placeHolder: 'First Name',
	value: '',
	nocHangeError: false,
	errorOnBlur: true,
	validation: {
		required: {
			msg: 'Required!'
		},
		custom: {
			validator: validName,
			msg: 'Not a valid Name'
		}
	},
	formatSave: formatName,
	type: 'text',
	isMandatory: true,
	outLabel: true,
	customClass: 'col-5',
	trackVal: 'Employee_Invite_'
};

const lnameField = {
	name: 'lname',
	label: '',
	placeHolder: 'Last Name',
	value: '',
	nocHangeError: false,
	errorOnBlur: true,
	validation: {
		required: {
			msg: 'Required!'
		},
		custom: {
			validator: validName,
			msg: 'Not a valid Name'
		}
	},
	formatSave: formatName,
	type: 'text',
	isMandatory: true,
	outLabel: true,
	customClass: 'col-5',
	trackVal: 'Employee_Invite_'
};

const emailField = {
	value: '',
	name: 'emailId',
	label: '',
	placeHolder: 'Employee  work email id',
	type: 'email',
	validation: {
		required: {
			msg: 'Required!'
		},
		custom: {
			validator: validEmail,
			msg: 'Enter Valid Email'
		}
	},
	errorOnBlur: true,
	nocHangeError: true,
	isMandatory: true,
	outLabel: true,
	trackVal: 'Employee_Invite_'
};

export const mobField = {
	name: 'mobileNo',
	label: '',
	value: '',
	maxLen: INDIA_FIELDS_CONSTANT.maxLen,
	placeHolder: 'Employee mobile number',
	validation: {
		required: {
			msg: 'Required!'
		},
		custom: INDIA_FIELDS_CONSTANT.customValidation
	},
	type: 'tel',
	countryCode: APPLICATION_CONSTANTS.INDIA_COUNTRY_CODE,
	isMandatory: true,
	outLabel: true,
	errorOnBlur: true,
	trackVal: 'Employee_Invite_',
	customClass: 'phonefield'
};

export const orgField = {
	name: 'groupId',
	value: {
		value: parseInt(APPLICATION_CONSTANTS.ORGANIZATION_LEVEL_GROUP_ID),
		label: APPLICATION_CONSTANTS.ORGANIZATION_LEVEL_GROUP_NAME
	},
	placeHolder: 'Group* ',

	options: [],
	isOpenTop: true,
	type: 'select',
	outLabel: true,
	errorOnBlur: true,
	trackVal: 'Employee_Invite_',
	customClass: 'label-left',
	label: 'Group Name'
};

export const inviteFields = [
	{
		name: 'emp',
		label: 'Employee details',
		type: 'label',
		outLabel: true,
		customClass: 'mb0'
	},
	genderField,
	fnameField,
	lnameField,
	emailField,
	mobField,
	{
		name: 'org',
		label: 'Organisation details',
		type: 'label',
		outLabel: true
	},
	orgField
];

export const removableFields = [
	{
		index: 0,
		name: 'dpt',
		placeHolder: 'Department',
		value: '',
		nocHangeError: true,
		errorOnBlur: true,
		type: 'text',
		outLabel: true,
		className: 'isRemove',
		isRemove: true,
		trackVal: 'Employee_Invite_',
		customClass: 'label-left',
		label: 'Department'
	}, {
		index: 1,
		name: 'empId',
		placeHolder: 'Employee ID',
		value: '',
		nocHangeError: true,
		errorOnBlur: true,
		type: 'text',
		outLabel: true,
		className: 'isRemove',
		isRemove: true,
		trackVal: 'Employee_Invite_',
		customClass: 'label-left',
		label: 'Employee ID'
	}, {
		index: 2,
		name: 'cc',
		placeHolder: 'Cost Center',
		value: '',
		nocHangeError: true,
		errorOnBlur: true,
		type: 'text',
		outLabel: true,
		className: 'isRemove',
		isRemove: true,
		trackVal: 'Employee_Invite_',
		customClass: 'label-left',
		label: 'Cost Center'
	}
];

export const GROUP_FIELDS_NAME = {
	GROUP_NAME: GROUP_CONSTANTS.GROUP_NAME,
	GROUP_DESC: GROUP_CONSTANTS.GROUP_DESC,
	ENABLE_2FA: GROUP_CONSTANTS.ENABLE_2FA
};
export const newGroupFields = {
	[GROUP_FIELDS_NAME.GROUP_NAME]: {
		value: '',
		name: GROUP_FIELDS_NAME.GROUP_NAME,
		label: '',
		placeHolder: 'Enter group Name',
		type: 'text',
		validation: {
			required: {
				msg: 'Required!'
			}
		},
		errorOnBlur: true,
		isMandatory: true,
		outLabel: true
	},
	[GROUP_FIELDS_NAME.GROUP_DESC]: {
		value: '',
		name: GROUP_FIELDS_NAME.GROUP_DESC,
		label: '',
		placeHolder: 'Enter group Description',
		type: 'textArea',
		showLimit: true,
		maxLen: 100,
		rows: 3,
		validation: {
			required: {
				msg: 'Required!'
			}
		},
		errorOnBlur: true,
		isMandatory: true,
		outLabel: true
	}
	/* [GROUP_CONSTANTS.ENABLE_2FA]: {
		showKey: 'mfaVisibility',
		value: '',
		name: GROUP_CONSTANTS.ENABLE_2FA,
		label: 'Enable 2FA',
		placeHolder: '',
		type: 'checkbox',
		showLimit: true,
		maxLen: 100,
		rows: 3,
		options: [
			{ value: 'Enable 2FA', label: 'Enable 2FA' }
		],
		validation: {
			required: {
				msg: 'Required!'
			}
		},
		errorOnBlur: true,
		outLabel: true,
		valueKey: GROUP_CONSTANTS.ENABLE_2FA,
		trackVal: 'Group_create_'
	} */
};

export const inviteModalData = {
	inviteTitle: 'Invite new Employee',
	modifyTitle: 'Modify Details',
	customClass: 'inviteEmpPop',
	subTitle: 'The employee will get the invitation link on his/her mail id mentioned.',
	mobileTitle: 'Invite new Employee',
	submitTxt: 'Next',
	modifySubmitTxt: 'Next',
	modifysubTitle: ''
};

export const ERROR_SUCCES_MSG = {
	[EMPLOYEE_CONSTANTS.ROLE_KEY]: {
		success: 'Employee(s) role updated successfully !',
		error: `We couldn't update role due to technical reasons. Please try again later`
	},
	[EMPLOYEE_CONSTANTS.GROUPS_KEY]: {
		success: 'Added to group successfully !',
		error: `We couldn't move employees to group due to technical reasons. Please try again later`
	},
	[EMPLOYEE_CONSTANTS.CHANGE_GRP_KEY]: {
		success: 'Group created successfully !',
		error: `We couldn't create group due to technical reasons. Please try again later`
	},
	[EMPLOYEE_CONSTANTS.TYPE_KEY]: {
		success: 'Employee Type has been successfully Updated for the selected employee(s)',
		error: `We couldn't update employees type due to technical reasons. Please try again later`
	},
	[EMPLOYEE_CONSTANTS.DELETE_KEY]: {
		success: 'Employee(s) deleted successfully !',
		error: `We couldn't delete employees due to technical reasons. Please try again later`
	},
	[EMPLOYEE_CONSTANTS.REINVITE_KEY]: {
		success: 'Employee(s) reinvited successfully !',
		error: `We couldn't reinvite employee(s) due to technical reasons. Please try again later`
	},
	[EMPLOYEE_CONSTANTS.REVERIFY_KEY]: {
		success: 'Verification link sent successfully !',
		error: `We couldn't reverify employee(s) due to technical reasons. Please try again later`
	},
	[EMPLOYEE_CONSTANTS.RESTORE_KEY]: {
		success: 'Employee(s) restored successfully !',
		error: `We couldn't restore employee(s) due to technical reasons. Please try again later`
	},
	[EMPLOYEE_CONSTANTS.RESTORE_INVITE_KEY]: {
		error: `We couldn't restore employee(s) due to technical reasons. Please try again later`
	},
	[EMPLOYEE_CONSTANTS.MANAGER_KEY]: {
		success: 'Reporting Manager updated successfully !',
		error: `We couldn't update Manager due to technical reasons. Please try again later`
	},
	'modify': {
		success: 'Employee details updated successfully !',
		error: `We couldn't update employee details due to technical reasons. Please try again later`
	},
	'dwlemp': {
		success: 'Employee data downloaded successfully !',
		error: `We couldn't download employee details due to technical reasons. Please try again later`,
		zeroEmpMsg: 'Selected list has zero employees in it, Please change your selection and try again'
	},
	'dwlempurl': {
		success: 'Employee data downloaded successfully !',
		error: `We couldn't download employee details due to technical reasons. Please try again later`,
		expired: 'download link expired'
	}
};

export const changeModalConfigs = {
	[EMPLOYEE_CONSTANTS.ROLE_KEY]: {
		title: confirmTxt,
		customClass: 'empPop',
		subTitle: 'Are you sure you want to change the role of following employee(s) to ',
		mobileTitle: confirmTxt,
		buttontext: 'Confirm Role'
	},
	[EMPLOYEE_CONSTANTS.MANAGER_KEY]: {
		title: 'Update managers',
		customClass: 'empPop',
		subTitle: 'Are you sure you want to update the managers of following employee(s)?',
		mobileTitle: 'Update managers',
		buttontext: 'Select Managers'
	},
	[EMPLOYEE_CONSTANTS.DELETE_KEY]: {
		title: 'Delete Employee ?',
		customClass: 'empPop',
		subTitle: 'Are you sure you want to delete employee(s) from myBiz? The employee would no longer have access to myBiz once deleted.',
		mobileTitle: 'Delete Employee ?',
		buttontext: 'Yes, Delete from myBiz'
	},
	[EMPLOYEE_CONSTANTS.TYPE_KEY]: {
		title: confirmTxt,
		customClass: 'empPop',
		subTitle: 'Are you sure you want to change the type of below employee(s) to?',
		mobileTitle: confirmTxt,
		buttontext: 'Confirm'
	},
	[EMPLOYEE_CONSTANTS.RESTORE_KEY]: {
		title: 'Restore Employee(s)',
		customClass: 'empPop',
		subTitle: 'Restore the below employee(s) to their original state.',
		mobileTitle: 'Restore Employee(s)',
		buttontext: 'RESTORE USERS TO ORIGINAL STATE'
	},
	[EMPLOYEE_CONSTANTS.RESTORE_INVITE_KEY]: {
		title: 'Restore Employee(s)',
		customClass: 'empPop',
		subTitle: 'Restore the below employee(s) to invited state.',
		mobileTitle: 'Restore Employee(s)',
		buttontext: 'RESTORE USERS TO INVITED STATE'
	},
	[EMPLOYEE_CONSTANTS.REINVITE_KEY]: {
		title: 'Re-Invite Employee(s)',
		customClass: 'empPop',
		subTitle: 'Invitation link will be sent to the below employee(s).',
		mobileTitle: 'Re-Invite Employee(s)',
		buttontext: 'SEND INVITE LINK'
	},
	[EMPLOYEE_CONSTANTS.REVERIFY_KEY]: {
		title: 'Verify Employee(s)',
		customClass: 'empPop',
		subTitle: 'Verification link will be sent to the below employee(s)',
		mobileTitle: 'Verify Employee(s)',
		buttontext: 'SEND VERIFICATION LINK'
	},
	[EMPLOYEE_CONSTANTS.GROUPS_KEY]: {
		title: 'Confirm New Group ',
		customClass: 'empPop',
		subTitle: 'Are you sure you want to add the following employee(s) to the group ',
		mobileTitle: 'Confirm New Group',
		buttontext: 'Yes, Transfer Employee(s) to Another Group'
	},
	[EMPLOYEE_CONSTANTS.CHANGE_GRP_KEY]: {
		title: 'Create New Group',
		customClass: 'empGrpPop',
		buttontext: 'CONFIRM',
		defaultErrMsg: 'Please Note: The users will be removed from their previous group',
		subTitle: ''
	}
};

export const EMP_FILTER_CONSTANTS = {
	'ADMIN': {
		label: 'Admin',
		value: 'ADMIN',
		countKey: 'admin'
	},
	'CB': {
		label: 'Central Booker (CB)',
		value: 'CENTRAL_BOOKER',
		countKey: 'centralBooker'
	},
	'TRAVELLER': {
		label: 'Traveller',
		value: 'TRAVELLER',
		countKey: 'traveller'
	},
	'TRAVEL_PLANNER': {
		label: 'Travel Planner',
		value: 'TRAVEL_PLANNER',
		countKey: 'travellerplaner'
	},
	'VIP': {
		label: 'VIP',
		value: 'VIP',
		countKey: 'vip'
	},
	'NORMAL': {
		label: 'Employee',
		value: 'EMP',
		countKey: 'employee'
	},
	'INVITED': {
		label: 'Invited',
		value: 'INVITED',
		countKey: 'invited'
	},
	'PENDING_VERIFICATION': {
		label: 'Pending Verification',
		value: 'PENDING_VERIFICATION',
		countKey: 'pendingVerification'
	},
	'NOT_INVITED': {
		label: 'Not Invited',
		value: 'NOT_INVITED',
		countKey: 'notInvited'
	},
	'DISABLED': {
		label: 'Disabled',
		value: 'DISABLED',
		countKey: 'disabled'
	},
	'VERIFIED': {
		label: 'Verified',
		value: 'VERIFIED',
		countKey: 'verified'
	}
};

export const MANAGER_NOT_SET = {
	label: 'MANAGER NOT SET',
	value: 'BLANK'
};

export const DESIGNATION_NOT_SET = {
	label: 'DESIGNATION NOT SET',
	value: 'BLANK'
};

export const filters = {
	initCount: {},
	[EMPLOYEE_CONSTANTS.GROUPS_KEY]: {
		isGrouped: true,
		label: 'Groups',
		type: FILTER_CONSTANTS.DROPDOWN,
		key: EMPLOYEE_CONSTANTS.GROUPS_KEY,
		isMulti: true,
		hideSelOptions: false,
		value: [],
		options: [],
		trackVal: 'groups',
		selectedCount: 0,
		dynamic: true,
		placeholder: searchBoxTxt
	},
	[EMPLOYEE_CONSTANTS.MANAGER_KEY]: {
		isGrouped: true,
		label: 'Reporting To',
		type: FILTER_CONSTANTS.DROPDOWN,
		key: EMPLOYEE_CONSTANTS.MANAGER_KEY,
		isMulti: true,
		hideSelOptions: false,
		value: [],
		options: [{ ...MANAGER_NOT_SET }],
		trackVal: 'reportingTo',
		selectedCount: 0,
		dynamic: true,
		placeholder: searchBoxTxt
	},
	[EMPLOYEE_CONSTANTS.DESIGNATION_KEY]: {
		isGrouped: true,
		label: 'Designation',
		type: FILTER_CONSTANTS.DROPDOWN,
		key: EMPLOYEE_CONSTANTS.DESIGNATION_KEY,
		isMulti: true,
		hideSelOptions: false,
		value: [],
		options: [{ ...DESIGNATION_NOT_SET }],
		trackVal: 'designation',
		selectedCount: 0,
		dynamic: true,
		placeholder: searchBoxTxt
	},
	[EMPLOYEE_CONSTANTS.ROLE_KEY]: {
		label: 'Role',
		type: FILTER_CONSTANTS.CHECKBOX,
		key: EMPLOYEE_CONSTANTS.ROLE_KEY,
		value: '',
		selectedCount: 0,
		trackVal: 'role_Type',
		options: [
			/* {
				value: EMP_FILTER_CONSTANTS.TRAVEL_PLANNER.value,
				countKey: EMP_FILTER_CONSTANTS.TRAVEL_PLANNER.countKey,
				label: EMP_FILTER_CONSTANTS.TRAVEL_PLANNER.label,
				count: '',
				filterKey: 'roleType',
				key: 'roleType',
				index: 1,
				trackVal: 'role_Type_traveller_planner',
				enableKey: EMP_FILTER_CONSTANTS.TRAVEL_PLANNER.enableKey
			}, */
			{
				value: EMP_FILTER_CONSTANTS.ADMIN.value,
				countKey: EMP_FILTER_CONSTANTS.ADMIN.countKey,
				label: EMP_FILTER_CONSTANTS.ADMIN.label,
				count: '',
				filterKey: 'roleType',
				index: 0,
				trackVal: 'role_Type_admin'
			},
			{
				value: EMP_FILTER_CONSTANTS.CB.value,
				countKey: EMP_FILTER_CONSTANTS.CB.countKey,
				label: EMP_FILTER_CONSTANTS.CB.label,
				count: '',
				filterKey: 'roleType',
				key: 'roleType',
				index: 0,
				trackVal: 'role_Type_central_booker'
			},
			{
				value: EMP_FILTER_CONSTANTS.TRAVELLER.value,
				countKey: EMP_FILTER_CONSTANTS.TRAVELLER.countKey,
				label: EMP_FILTER_CONSTANTS.TRAVELLER.label,
				count: '',
				filterKey: 'roleType',
				key: 'roleType',
				index: 1,
				trackVal: 'role_Type_traveller'
			}
		]
	},
	[EMPLOYEE_CONSTANTS.TYPE_KEY]: {
		label: 'Employee Type',
		type: FILTER_CONSTANTS.CHECKBOX,
		value: '',
		key: EMPLOYEE_CONSTANTS.TYPE_KEY,
		selectedCount: 0,
		trackVal: 'employee_type',
		options: [{
			value: EMP_FILTER_CONSTANTS.VIP.value,
			key: 'empType',
			label: EMP_FILTER_CONSTANTS.VIP.label,
			countKey: EMP_FILTER_CONSTANTS.VIP.countKey,
			checked: false,
			filterKey: 'empType',
			count: '',
			trackVal: 'employee_type_vip',
			index: 0
		},
		{
			value: EMP_FILTER_CONSTANTS.NORMAL.value,
			key: 'empType',
			label: EMP_FILTER_CONSTANTS.NORMAL.label,
			countKey: EMP_FILTER_CONSTANTS.NORMAL.countKey,
			checked: false,
			filterKey: 'empType',
			count: '',
			trackVal: 'employee_type_vip',
			index: 0
		}
		]
	},
	[EMPLOYEE_CONSTANTS.STATE_KEY]: {
		label: 'Employee Status',
		type: FILTER_CONSTANTS.CHECKBOX,
		hideClear: true,
		key: EMPLOYEE_CONSTANTS.STATE_KEY,
		value: '',
		selectedCount: 0,
		trackVal: 'state_type',
		singleSelect: false,
		options: [
			{
				value: EMP_FILTER_CONSTANTS.INVITED.value,
				countKey: EMP_FILTER_CONSTANTS.INVITED.countKey,
				label: EMP_FILTER_CONSTANTS.INVITED.label,
				count: '',
				filterKey: 'stateType',
				index: 0,
				trackVal: 'state_type_invited',
				checked: true
			},
			// {
			// 	value: EMP_FILTER_CONSTANTS.NOT_INVITED.value,
			// 	countKey: EMP_FILTER_CONSTANTS.NOT_INVITED.countKey,
			// 	label: EMP_FILTER_CONSTANTS.NOT_INVITED.label,
			// 	count: '',
			// 	filterKey: 'stateType',
			// 	index: 1,
			// 	trackVal: 'state_type_not_invited',
			// 	checked: true
			// },
			{
				value: EMP_FILTER_CONSTANTS.PENDING_VERIFICATION.value,
				countKey: EMP_FILTER_CONSTANTS.PENDING_VERIFICATION.countKey,
				label: EMP_FILTER_CONSTANTS.PENDING_VERIFICATION.label,
				count: '',
				filterKey: 'stateType',
				index: 0,
				trackVal: 'state_type_pending_verification',
				checked: false
			},
			{
				value: EMP_FILTER_CONSTANTS.DISABLED.value,
				countKey: EMP_FILTER_CONSTANTS.DISABLED.countKey,
				label: EMP_FILTER_CONSTANTS.DISABLED.label,
				count: '',
				filterKey: 'stateType',
				index: 1,
				trackVal: 'state_type_disabled',
				checked: false
			},
			{
				value: EMP_FILTER_CONSTANTS.VERIFIED.value,
				countKey: EMP_FILTER_CONSTANTS.VERIFIED.countKey,
				label: EMP_FILTER_CONSTANTS.VERIFIED.label,
				count: '',
				filterKey: 'stateType',
				index: 1,
				trackVal: 'state_type_verified',
				checked: false
			}
		]
	}
};

export const searchInput = {
	value: '',
	label: '',
	placeholder: 'Group Name',
	type: 'text',
	textType: 'search'
};

export const updatePropListConf = {
	[EMPLOYEE_CONSTANTS.TYPE_KEY]: {
		heading: 'Change Employee type to',
		options: [
			{
				...EMP_FILTER_CONSTANTS.VIP,
				value: [EMP_FILTER_CONSTANTS.VIP.value],
				desc: `To ensure that your leadership team always have a smooth travelling experience, mark them as a VIP Employee and we will take extra care to make their business travel much more special. Only 10 employees or 1% of total employees (whichever is lesser) can be marked as VIP.`
			},
			{
				...EMP_FILTER_CONSTANTS.NORMAL,
				value: [EMP_FILTER_CONSTANTS.NORMAL.value],
				desc: `All onboarded members of your organisation are considered as employees.`
			}
		]
	},
	[EMPLOYEE_CONSTANTS.ROLE_KEY]: {
		heading: 'Change Role to',
		options: [
			{
				...EMP_FILTER_CONSTANTS.ADMIN,
				value: [EMP_FILTER_CONSTANTS.CB.value, EMP_FILTER_CONSTANTS.TRAVEL_PLANNER.value, EMP_FILTER_CONSTANTS.ADMIN.value, EMP_FILTER_CONSTANTS.TRAVELLER.value],
				desc: `An employee who is in charge of overseeing business travel for your organisation. Mark someone as an Admin who can then set up policy, recharge wallet, download reports, and use myBiz to ensure more savings on your organisation’s travel spends.`
			},
			{
				...EMP_FILTER_CONSTANTS.TRAVEL_PLANNER,
				value: [EMP_FILTER_CONSTANTS.TRAVEL_PLANNER.value, EMP_FILTER_CONSTANTS.CB.value, EMP_FILTER_CONSTANTS.TRAVELLER.value],
				desc: `Employees who book for other travelling employees. Mark someone as a travel planer and give them access to features needed to make informed decisions based on the travel needs of your colleagues. This should only be selected if your employees need to fill a requisition form before travelling*`
			},
			{
				...EMP_FILTER_CONSTANTS.CB,
				value: [EMP_FILTER_CONSTANTS.CB.value, EMP_FILTER_CONSTANTS.TRAVELLER.value],
				desc: `Employees who book for other travelling employees. Mark someone as a central booker and give them access to features needed to make informed decisions based on the travel needs of your colleagues.`
			},
			{
				...EMP_FILTER_CONSTANTS.TRAVELLER,
				value: [EMP_FILTER_CONSTANTS.TRAVELLER.value],
				desc: `Employees who, when travelling for a business trip, book for themselves directly. Mark someone as an employee to give access to a world class self-booking platform.`
			}
		]
	}
};

export const updateManagerField = [{
	name: 'manager1',
	value: '',
	level: 1,
	placeHolder: 'Reporting Manager',
	options: [],
	type: 'select',
	outLabel: true,
	errorOnBlur: true,
	isSearchable: true,
	labelKey: 'value',
	trackVal: 'manager_fields',
	customClass: 'mgr-drop label-left',
	label: 'Level 1',
	isClearable: true
},
{
	name: 'manager2',
	level: 2,
	value: '',
	placeHolder: 'Manager - Level 1 (Optional)',
	options: [],
	type: 'select',
	outLabel: true,
	errorOnBlur: true,
	isSearchable: true,
	labelKey: 'value',
	trackVal: 'manager_fields',
	customClass: 'mgr-drop label-left',
	label: 'Level 2',
	isClearable: true
},
{
	name: 'manager3',
	value: '',
	level: 3,
	placeHolder: 'Manager - Level 3 (Optional)',
	options: [],
	type: 'select',
	outLabel: true,
	errorOnBlur: true,
	isSearchable: true,
	labelKey: 'value',
	trackVal: 'manager_fields',
	customClass: 'mgr-drop label-left',
	label: 'Level 3',
	isClearable: true
},
{
	name: 'manager4',
	value: '',
	level: 4,
	placeHolder: 'Manager - Level 4 (Optional)',
	options: [],
	type: 'select',
	outLabel: true,
	errorOnBlur: true,
	isSearchable: true,
	labelKey: 'value',
	trackVal: 'manager_fields',
	customClass: 'mgr-drop label-left',
	label: 'Level 4',
	isClearable: true
},
{
	name: 'manager5',
	value: '',
	level: 5,
	placeHolder: 'Manager - Level 5 (Optional)',
	options: [],
	type: 'select',
	outLabel: true,
	errorOnBlur: true,
	isSearchable: true,
	labelKey: 'value',
	trackVal: 'manager_fields',
	customClass: 'mgr-drop label-left',
	label: 'Level 5',
	isClearable: true
}
];

export const sendInviteField = {
	name: 'sendInvite',
	value: '',
	placeHolder: '',
	options: [{
		value: 'true',
		label: 'Send Invitation Link to the employee'
	}],
	type: 'checkbox',
	customClass: 'mgr-drop',
	trackVal: 'invitationBox'
};

export const modifyEmpFields = [
	{
		name: 'emp',
		label: 'Employee details',
		type: 'label',
		outLabel: true,
		customClass: 'mb0'
	},
	genderField,
	fnameField,
	lnameField,
	{ ...emailField, isDisabled: true },
	mobField,
	{
		name: 'org',
		label: 'Organisation details',
		type: 'label',
		outLabel: true
	},
	orgField
];
export const REMINDER_CONFIG = {
	REMINDER_SETTING: 'Reminder Setting',
	REMINDER_RADIO_TEXT: {
		activeTitle: 'Automailer Enabled - ',
		defaultTitle: 'Enable Automailer - '
	}
};

export const reminderModalDtls = {
	title: 'Reminder Settings',
	desc: 'A notification email will be shared to the invited employee to access myBiz & activate the account',
	details: [{
		isMulti: false,
		value: '',
		name: 'frequency',
		subtitle: 'How frequently do you want to be notified?',
		fieldLabel: 'Frequency',
		key: 'frequency',
		type: 'select',
		classPrefix: 'frequency custom-styles-select',
		formatVal: formatAmount,
		trackVal: 'reminder_settings_frequency',
		validation: {
			required: {
				msg: 'Required!'
			}
		},
		subComponent: ReminderFrequency,
		subValues: {},
		subField: {},
		options: [
			{ value: 'WEEKLY', label: 'Weekly' },
			{ value: 'MONTHLY', label: 'Monthly' }
		],
		errorOnBlur: true,
		isOpenTop: false,
		isMandatory: true
	}
	],
	cta: {
		text: 'Save & enable Mailer',
		type: 'Primary'
	}
};

export const inviteLinkSuccessModal = {
	heading: 'Invitation is on its way!',
	contentText: 'Employee will be notified through the selected channel(s) for activation.',
	btnTxt: 'Invite Another Employee',
	img: 'emailImg.png'
};

export const inviteSuccessModal = {
	heading: 'Employee Added !',
	contentText: 'The new employee has been added successfully.',
	btnTxt: 'Invite Another Employee'
};

export const vipLimitModal = {
	heading: 'VIP Limit Exceeded!',
	contentText: 'You can only mark maximum of 10 employees as VIP’s.',
	btnTxt: 'OK GOT IT',
	img: '/shortlist/attention.png'
};
export const bulkUploadModal = {
	'modalData': {
		title: 'Upload Employee Details in Bulk',
		subTitle: 'Please use the CSV template file given below to upload your employee details.',
		customClass: 'csvReimburse'
	},
	'downloadSection': {
		'title': 'Step 1: Download the template csv file',
		'subTitle': 'Please download the template file given below and fill your employee details as mentioned in the csv file.',
		'templateDetails': [{
			text: 'Download Template',
			url: `${PATHS.CCB_URL}${PATHS.EMP_CSV_DWNLOAD}`
		}]
	},
	'uploadSection': {
		'title': 'Step 2: Upload the filled template csv File',
		'subTitle': 'Make sure you have filled every required detail on CSV file. Drag and drop the file or select by clicking below.'
	},
	'apiUrl': PATHS.EMP_BULK_UPLOAD,
	'formSection': {
		sendInvitation: true,
		employeeType: true
	},
	'submitText': 'Upload Employee Details'
};

export const preferenceUploadModal = {
	'btnTxt': 'Update Preferences',
	'modalData': {
		title: 'Upload Employee Preferences in Bulk',
		subTitle: 'Please use the CSV template file given below to upload the details',
		customClass: 'csvReimburse'
	},
	'downloadSection': {
		'title': 'Step 1: Download the template csv file',
		'subTitle': 'Please download the template file given below and fill the preference details as mentioned in the csv file.',
		'templateDetails': [{
			text: 'Download Template',
			url: `${PATHS.CCB_URL}${PATHS.PREFERENCE_DOWNLOAD_API}`
		}]
	},
	'uploadSection': {
		'title': 'Step 2: Upload the filled template csv File',
		'subTitle': 'Make sure you have filled every required details on CSV file. Drag and drop the file or select by clicking below.'
	},
	'apiUrl': PATHS.PREF_BULK_UPLOAD,
	'submitText': 'Upload CSV File'
};

export const coachmarkTextConf = {
	list: {
		heading: 'Manage Your Employees',
		subHeading: 'View details of your added employees, update information, or delete employees who have left the organisation. You can also download the complete list of your employees'
	},
	top: {
		heading: 'Add Employees',
		subHeading: 'Add employees to the system by sending them an invite mail. If you need to add all your employees in a single-go, you can also use the ‘Bulk Upload’ option.'
	},
	help: {
		heading: 'Refer to Help Section',
		subHeading: 'Revisit the manage and onboarding process of Employees from the Help section here.'
	}
};

export const onboardingModalConfig = {
	heading: 'Get Your Employees Onboarded',
	desc: 'Quick and easy way to manage your employees through a central portal.',
	cta: 'LET\'S GET STARTED',
	img: OnboardImage,
	imgAlt: 'Onboarding Employee',
	features: [
		{
			title: 'Define and Manage Policies',
			desc: '100% policy compliance is ensured while official travels are booked using myBiz.',
			Icon: <EmployeeIcons icon='addCircleIcon' />
		},
		{
			title: 'View Detailed Reports',
			desc: 'Get comprehensive reports on expenditure made by each employee.',
			Icon: <EmployeeIcons icon='starCircleIcon' />
		},
		{
			title: 'Unlock Centralised myBiz Wallet',
			desc: 'Skip reimbursing travel expenses of employees by allowing them to pay from myBiz Wallet.',
			Icon: <EmployeeIcons icon='shieldCircleIcon' />
		}
	]
};

export const empOmniConfig = {
	inviteEmp: {
		prop: 'Employee_Invite_user_clicked',
		page: 'employee'
	},
	onboarding1: {
		prop: 'Employee_onboarding__1/2_next_clicked',
		page: 'employee'
	},
	onboarding2: {
		prop: 'Employee_onboarding__2/2_',
		page: 'employee'
	},
	whatIsThis: {
		prop: 'Employee_whatsThis_clicked',
		page: 'employee'
	},
	help: {
		prop: 'Help_clicked',
		page: 'employee'
	},
	groups: {
		eventName: 'Filter Groups',
		page: 'employee'
	},
	reportingTo: {
		eventName: 'Filter Reporting to',
		page: 'employee'
	},
	designation: {
		eventName: 'Filter Designation',
		page: 'employee'
	},
	role_Type: {
		eventName: 'Filter – Role',
		page: 'employee'
	},
	employee_type: {
		eventName: 'Filter – Employee type',
		page: 'employee'
	},
	state_type: {
		eventName: 'Filter – State type',
		page: 'employee'
	},
	invite_fields: {
		prop: 'Employee_Invite_',
		page: 'employee'
	},
	inviteAddManager: {
		prop: 'Employee_invite_addmanager_clicked',
		page: 'employee'
	},
	manager_fields: {
		prop: 'Employee_invite_',
		page: 'employee'
	},
	manager_invite: {
		prop: 'Employee_bulk_upload_clicked',
		page: 'employee'
	},
	bulkUpload: {
		prop: 'Employee_bulk_upload_clicked',
		page: 'employee'
	},
	editManager: {
		prefix: 'Employee_',
		prop: 'editManager_clicked',
		page: 'employee'
	},
	restoreEmployee: {
		prefix: 'Employee_',
		props: '',
		page: 'employee'
	},
	editEmployeeType: {
		prefix: 'Employee_',
		prop: 'editEmployeeType_clicked',
		page: 'employee'
	},
	editGroup: {
		prefix: 'Employee_',
		grpPrefix: 'Groupdetail_',
		prop: 'editGroup_clicked',
		page: 'employee'
	},
	editRole: {
		prefix: 'Employee_',
		prop: 'editRole_clicked',
		page: 'employee'
	},
	deleteEmployee: {
		prefix: 'Employee_',
		grpPrefix: 'Groupdetail_',
		prop: 'deleteEmployee_clicked',
		page: 'employee'
	},
	downloadClicked: {
		prefix: 'Employee_',
		grpPrefix: 'Groupdetail_',
		prop: 'download_clicked',
		page: 'employee'
	},
	empListCheckBox: {
		prop: 'Employee_listview_checkbox_ '
	},
	groupListCheckBox: {
		prop: 'Groups_listview_checkbox_ '
	},
	listEdit: {
		prop: 'Employee_listview_edit_clicked_',
		page: 'employee'
	},
	listDelete: {
		prop: 'Employee_listview_delete_clicked_',
		page: 'employee'
	},
	reminderSaved: {
		prop: 'Employee_reminder_settings_saved',
		page: 'employee'
	},
	invitationBox: {
		prop: 'Employee_invite_invitation_',
		page: 'employee'
	},
	downloadCsv: {
		prop: 'Employee_bulk_upload_templete_download_clicked',
		page: 'employee'
	},
	uploadingCsv: {
		prop: 'Employee_bulk_upload_add_sheet_clicked',
		page: 'employee'
	},
	bulkuploadClicked: {
		prop: 'Employee_bulk_upload_clicked',
		page: 'employee'
	},
	hrmsClicked: {
		prop: 'HRMS_Click_here_clicked',
		page: 'employee'
	},
	inviteChannelChange: {
		prop: 'invite_channel_change_clicked',
		page: 'employee'
	},
	updateInviteChannel: {
		prop: 'channel_change_saved',
		page: 'employee'
	}
};

export const uplaodStatusPop = {
	[APPLICATION_CONSTANTS.SUCCESS]: {
		icon: '',
		text: ''
	},
	[APPLICATION_CONSTANTS.FAILED]: {
		icon: 'redExclaim',
		heading: 'Something Went Wrong!',
		description: `Apologies! We are not able to reach the servers at this moment. Please try again later. ${xreqId ? ` (error:${xreqId})` : ''}`,
		btnTxt: 'OK, GOT IT'
	},
	[APPLICATION_CONSTANTS.PARTIAL]: {
		icon: 'yellowTick',
		heading: 'Partially Updated!',
		description: `We were not able to update few of the employee details due to technical error. ${xreqId ? ` (error:${xreqId})` : ''}`,
		link: 'Download Failed Employee Details'
	},
	[APPLICATION_CONSTANTS.COMPLETED]: {
		imgStyle: 'gradGreenTick',
		heading: 'Employee Details Have been Successfully Updated !',
		description: '',
		btnTxt: 'OK, GOT IT',
		img: ASSET_PATH.IMAGES + 'employee/employee.png'
	}
};

export const bulkUpldExtensions = {
	'csv': 'csv',
	'vnd.ms-excel': 'vnd.ms-excel',
	'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

export const HELP_TEXT = {
	why: 'Why should I use Employee section?',
	how: 'How to use Employee section?'
};

export const NEW_BULK_UPLD_EMP = 'new';
export const ALL_BULK_UPLD_EMP = 'all';

export const bulkUploadSelectOptions = [
	{
		id: ALL_BULK_UPLD_EMP,
		value: 'All the employees'
	},
	{
		id: NEW_BULK_UPLD_EMP,
		value: 'Newly Added employees only'
	}
];

export const RESTORE_INVITE_LABEL = 'Click here to restore employees to invited state & send invite link.';

export const inviteChannelsFields = {
	email: {
		value: 'EMAIL',
		label: 'Email Id',
		type: 'checkbox',
		trackVal: 'invitationBox',
		subLabel: '- Employee will receive the invitation on the given email id.',
		isDisabled: true,
		isChecked: false,
		key: 'email'
	},
	sms: {
		value: 'SMS',
		label: 'SMS',
		type: 'checkbox',
		trackVal: 'invitationBox',
		subLabel: '- Employee will receive the invitation on the given no. as SMS.',
		isDisabled: false,
		isChecked: false,
		key: 'sms'
	},
	whatsapp: {
		value: 'WHATSAPP',
		label: 'Whatsapp',
		type: 'checkbox',
		trackVal: 'invitationBox',
		subLabel: ' - Employee will receive the invitation on the given no. as whatsapp.',
		isDisabled: false,
		isChecked: false,
		key: 'whatsapp'
	}
};

export const inviteChannelModalData = {
	title: 'Send Invitation To',
	subTitle: 'The below selected communication channels will be used to send the invitation whenever new employees are added.'
};
export const ERROR_CONTENT = 'Something went wrong. Please try again';

export const ERROR_HEADING = 'OOPS!';

export const updateInviteChannelSuccess = {
	data: {
		msg: 'Invitation Channels are updated successfully'
	},
	apiType: EMPLOYEE_STORE_CONST.UPDATE_INVITATION_CHNL,
	showNotify: true
};

export const statusAssociateButtons = {
	[EMP_FILTER_CONSTANTS.INVITED.value]: {
		btnTxt: 'Re-Invite',
		key: EMPLOYEE_CONSTANTS.REINVITE_KEY
	},
	[EMP_FILTER_CONSTANTS.PENDING_VERIFICATION.value]: {
		btnTxt: 'Re-Verify',
		key: EMPLOYEE_CONSTANTS.REVERIFY_KEY
	},
	[EMP_FILTER_CONSTANTS.DISABLED.value]: {
		btnTxt: 'Restore',
		key: EMPLOYEE_CONSTANTS.RESTORE_KEY
	}
};
