import React, { Component } from 'react';
import { LoginPage } from './LoginPageStyles';
import { Col, Grid, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { STATES } from 'reduxStore/actionConstants';

import { PATHS } from 'AppConstants';
import SSOLoginFormComponent from './SSOLoginFormComponent';
import SSOPageIntroComponent from './SSOPageIntroComponent';

// this is a generic page and can be reused for similar templates
class LoginPageComponent extends Component {
	constructor(props) {
		super(props);
	}
	// todo: based on condition , do dynamic import and return component
  getRightPanel =()=> <Switch>
	<Route path={PATHS.SSO_LOGIN}>
		<SSOLoginFormComponent />
	</Route>
                      </Switch>

  // todo: based on condition , do dynamic import and return component
  getLeftPanel =()=> <Switch>
	<Route path={PATHS.SSO_LOGIN} component={SSOPageIntroComponent} />
                     </Switch>

  getLoginBottomBar =()=> (
	<p style={{ color: '#9a9a9a', marginBottom: '20px' }}>*Benefits will be subject to registration of your company on
		myBiz platform. Admin
		user of your company needs to provide PAN and GST number of your company for the purpose of registration.
	</p>);

  getBottomBar =()=> <Switch>
	<Route path={PATHS.LOGINPAGE}>{this.getLoginBottomBar()}</Route>
                     </Switch>

  render() {
  	let leftPanel = this.getLeftPanel();
  	return (
	<LoginPage>
		<Grid>
			<Row style={{ 'marginTop': '40px' }} className='show-grid'>
				<Col xsHidden smHidden mdHidden lg={6}>
					{leftPanel}
				</Col>
				{this.props.loginState !== STATES.DONE &&
				<Col xs={12} md={6}>
					{this.getRightPanel()}
				</Col>}
			</Row>
			<Row style={{ 'marginTop': '40px' }} className='show-grid'>
				<Col lgHidden md={6}>
					{leftPanel}
				</Col>
			</Row>
			<Row style={{ 'marginTop': '40px' }} className='show-grid'>
				<Col lg={12}>
					{this.getBottomBar()}
				</Col>
			</Row>
		</Grid>
	</LoginPage>
  	);
  }
}

const mapStateToProps = (state) => ({
	loginState: (state.login && state.login.loginPayload) ? state.login.loginPayload.loginState : null,
	windowObj: (state.login && state.login.loginPayload) ? state.login.loginPayload.windowObj : null
});
const mapDispatchToProps = (dispatch) => ({
	dispatch: dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageComponent);
