import RadioBtn from '../RadioBtn';
import React from 'react';
import { useState, useEffect } from 'react';
import { RadioWithTextArea } from './HelpSectionStyles';
import { GenericP } from '../Styles';

const NeedHelpRadioBtn = (props) =>{
	const { radioField } = props;
	const getInitialRadioValues = () => {
		let currData = radioField.filter((itm)=>itm.checked)[0];
		if (currData && currData.value === 'others') {
			currData = {
				...currData,
				label: ''
			};
		}

		return currData;
	};
	const [activeRadio, setActiveRadio] = useState(getInitialRadioValues());
	const [radioData, setRadioData] = useState(radioField);
	const [textValue, setTextValue] = useState('');

	useEffect(()=>{
		props.radioButtonHandler(activeRadio);
	}, []);

	const changeHandler = (e) => {
		if (e.target.value !== 'others') {
			setTextValue('');
		}
		const data = radioData.map((item)=>{
			if (e.target.value === item.value) {
				return {
					...item,
					checked: true
				};
			} else {
				return {
					...item,
					checked: false
				};
			}
		});
		setRadioData(data);

		let currData = data.find((itm)=>itm.value === e.target.value) || {};
		if (currData.value === 'others') {
			currData = {
				...currData,
				label: textValue
			};
			setTimeout(()=> {
				document.getElementById('othersText').focus();
			}, 0);
		}
		props.radioButtonHandler(currData);
		setActiveRadio(currData);
	};

	const handleOnBlur = (e) => {
		setTextValue(e.target.value);
		const data = {
			...activeRadio,
			label: e.target.value
		};
		props.radioButtonHandler(data);
	};

	return (
		<>
			{radioData && radioData.map((item, index)=>(
				<GenericP className={item.className} key={item.value} fsize='14px' color='#4a4a4a' bottom_M='10' f_weight={400}>
					<RadioBtn
						RadioText={item.label}
						name={item.name}
						isChecked={item.checked}
						key={index}
						changeHandler={changeHandler}
						value={item.value}
					/>
				</GenericP>
			))}
			{activeRadio.value == 'others' && <RadioWithTextArea id='othersText' placeholder='Write your query here.' onChange={handleOnBlur} value={textValue} />}
		</>
	);
};
export default NeedHelpRadioBtn;
