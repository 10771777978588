import { FlexDiv, GenericDiv } from 'commonStyles';
import styled from 'styled-components';

export const QueryFieldsWrapper = styled(FlexDiv)`
		& .css-t3ipsp-control {
				border-color: #d8d8d8;
				box-shadow: none;
			}

		.InnerSelct__control {
			border-color: #EBEEF2;
			border-radius: 4px 0 0 4px;
			min-height: 32px;
			max-height: 32px;
			padding-left: 5px;
		}

		.InnerSelct__option--is-selected {
			&:after {
					display: none;
			}
		}

		.InnerSelct__control:hover {
			border-color: #EBEEF2;
			box-shadow: none;
			border: 1px solid #EBEEF2;
		}

		/* For input field : Service Request ID, Booking ID, Email ID use cases */
		.materialInput {
			background-color: #fff;
			margin: 0;

			input {
				border-color: #E3E7EB;
				border-left: none;
				border-right: none;
				border-radius: 0;
				padding : 0 8px;
				font-size: 12px;
				line-height: 16px;

				&:focus {
					background-color: #fff;
					border-width: 1px 0;
					border-color: #E3E7EB;
				}

				&::placeholder {
					color: #9B9B9B;
					font-size: 12px;
					font-weight: 700;
					line-height: 16px;
				}

				&:not(:placeholder-shown) {
					background-color: #fff;
				}
			}
		}

		/* For dropdown input field : Search Name usecase */
		.nameInput {
			min-width: 180px;

			.InnerSelct__control {
				border-radius: 0;
				border: 1px solid #E3E7EB;
				border-left: none;
				padding-left: 0;
			}

			.InnerSelct__value-container {
				padding-left: 6px;

				.InnerSelct__single-value {
					.name {
						display:none
					}

					.email {
						color: #000;
						font-weight: 700;
				}
			}
		}
`;

export const SearchIconWrapper = styled(GenericDiv)`
		background : ${(props) => props.isActive ? 'linear-gradient(104.41deg, #FF7E40 0%, #FF405D 99.23%)' : '#0000004D'} ;
		border-left:none;
		cursor: pointer;
		min-width: ${(props) => props.width || '32px'};
`;
