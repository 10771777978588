/**
 * props :
 * top_arr: arrow ontop
 * btm_arr: arrow bottom
 * let_arr: arrow left
 * right_arr: arrow right
 * left: left position of arrow
 * top: top postion of arrow
 */
import React, { Component } from 'react';
import { TooltipWrapper } from './TooltipStyles';

class TooTip extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<TooltipWrapper className='tooltip-wrap' {...this.props}>
				{this.props.children}
			</TooltipWrapper>
		);
	}
}

export default TooTip;
