import styled from 'styled-components';
import { ASSET_PATH } from '../../../assetsPath';

export const FrequencyTab = styled.div `
    background: #f5f5f5;
    min-height: 100px;
    border-radius: 4px;
    padding: 5px 14px 5px 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    & .dropdownWrapper{
        width: 74px;
        margin-left: 8px; 
        margin-right: 8px;
    }
    .freq-dropdown {
        input {
            height: auto;
        }
        .custom-styles-select__control {
						height: 38px;
            padding: 0 5px;
            .custom-styles-select__indicator {
                padding: 0
            }
        }
        .custom-styles-select__option {
            padding: 8px 12px;
            font-size: 12px;
        }
        .custom-styles-select__option--is-focused, .custom-styles-select__option:hover {
            background: #fdf1ea !important;
        }
        .custom-styles-select__option--is-selected {
            background: transparent;
            color: #484848;
            display: flex;
            align-items: center;        
            &:after {
                width: 10px;
                height: 10px;
                content: "";
                display: inline-block;
                background: url(${ASSET_PATH.IMAGES}InvoiceSprite1.png) no-repeat;
                background-size: 150px 100px;
                background-position: 0 -65px;
                position: relative;
                left: 4px;
                top: 1px;
            }
        }
    }
    & .leftTag{
        color: #484848; 
        font-size: 14px;
    }
    & .rightTag{
        color: #484848;
        font-size: 10px;
    }
    .weekDays {
        display: flex;
        margin: 0;
        label {
            border-radius: 4px;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
            background-color: #ffffff;
            padding: 3px 0px;
            cursor: pointer;
            margin-right: 2px;
            margin-bottom: 0 !important;
            width: 20px;
            height: 20px;
            font-size: 12px;
            color: #4a4a4a;
            .pseudoradio {
                display: none;
            }
            position: relative;
            input {
                &:checked +.pseudoradio {
                    position: absolute;
                    border: 0;
                    left: 0px;
                    top: 0px;
                    border-radius: 4px;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #f17531; 
                    +span{
                        z-index: 1;
                        color: #fff;
                    }
                }
            }
            text-align: center;
            span {
                font-size: 12px;
                line-height: 14px;
                width: 100%;
            }
            &.activeDays{background: #f17531;color: #fff;}
        }
    }
`;
export const WeekDays = styled.span `

`;
