import React from 'react';

import { SHORTLIST_SHARE_CONSTANTS, TRF_DETAILS_CONSTANTS } from 'AppConstants';
import { TravelRequestIcons } from '../travRequisition/pages/reqDetails/Styles';
import { COMMENT_STYLE, LOB, LOB_TITLE, NON_COMMENT_STYLE, PENDING_STYLE, toastDataConfig } from '../travRequisition/pages/reqDetails/config';
import { getDate } from '../UtilityComponents/Util';

/**
 * formats travel request details page data
 * @author mybizFe
 * @param {Array} data
 * @return {Object} formatted travel request details page
 */
export const formatTRFData = (data = {}) => {
	const { header, comment, currentApprover, status, reasonForTravel = {}, role, primaryPaxName, tripTags = [], services = [], travelInfo = {}, paxCount } = data;
	let trfData = {
		header,
		comment,
		role,
		currentApprover,
		status
	};
	trfData.travelInformation = [{
		title: 'Booking for',
		info: `${primaryPaxName}${paxCount > 1 ? `, +${paxCount - 1} ` : ''} (${travelInfo.bookingFor})`
	}, {
		title: 'Departure',
		info: getDate(travelInfo.startDate, TRF_DETAILS_CONSTANTS.TRAVEL_INFORMATION_FORMAT, {})
	}];
	if (travelInfo.endDate) {
		trfData.travelInformation.push({
			title: 'Return',
			info: getDate(travelInfo.endDate, TRF_DETAILS_CONSTANTS.TRAVEL_INFORMATION_FORMAT, {})
		});
	}
	let tripTagInformation = tripTags?.map((tripTag, index) => {
		let information = {};
		information.title = tripTag?.attributeName;
		information.info = tripTag?.attributeSelectedValue?.join(', ');
		return information;
	});
	let { reason, comment: travComment } = reasonForTravel;
	let additionalTravelInfo = {
		title: 'Reason for Travel',
		info: reason,
		description: travComment
	};
	trfData.additionalInformation = [...tripTagInformation, additionalTravelInfo];

	let lobdata = [];
	for (let key in services) {
		lobdata.push(getTRFData(services[key], key, primaryPaxName, paxCount));
	}
	trfData.services = [...lobdata];
	return trfData;
};

/**
 * formats booking details for travel page data
 * @author mybizFe
 * @param {Array} serviceData
 * @param {String} lob
 * @param {String} traveller
 * @param {Number} count
 * @return {Object} formatted booking details
 */
const getTRFData = (serviceData, lob, traveller, count) => {
	let trfData = {};
	serviceData.forEach((service, index) => {
		let { workflowId, booked, travelDetails = [], cityName, checkin, checkout, pickupDetails = {}, dropDetails = {}, tripType, cabType, listingPageUrl, paxCount = count } = service;
		let sdata = {
			title: LOB_TITLE[lob],
			listingPageUrl,
			workflowId,
			status: booked ? 'BOOKED' : 'NOT BOOKED'
		};
		switch (lob) {
			case LOB.FLIGHT: {
				sdata.traveller = paxCount > 1 ? `| ${traveller} + ${paxCount - 1} traveller` : `| ${traveller}`;
				sdata.location = `${travelDetails[0]?.from?.cityName} ${tripType === 'R' ? `↔` : `→`} ${travelDetails[0]?.to?.cityName}`;
				sdata.schedule = getDate(travelDetails[0]?.depDate, TRF_DETAILS_CONSTANTS.CARD_DATE_FORMAT, {});
				sdata.schedule += (tripType === 'R' ? ` - ${getDate(travelDetails[0]?.returnDate, TRF_DETAILS_CONSTANTS.CARD_DATE_FORMAT, {})}` : '');
			}
				break;
			case LOB.HOTEL: {
				sdata.traveller = paxCount > 1 ? `| ${traveller} + ${paxCount - 1} traveller` : `| ${traveller}`;
				sdata.location = cityName;
				sdata.schedule = `${getDate(checkin, TRF_DETAILS_CONSTANTS.CARD_DATE_FORMAT, {})} 
			- ${getDate(checkout, TRF_DETAILS_CONSTANTS.CARD_DATE_FORMAT, {})}`;
			}
				break;
			case LOB.BUS: {
				sdata.traveller = paxCount > 1 ? `| ${traveller} + ${paxCount - 1} traveller` : `| ${traveller}`;
				sdata.location = `${pickupDetails?.name} → ${dropDetails?.name}`;
				sdata.schedule = getDate(pickupDetails?.dateTime, TRF_DETAILS_CONSTANTS.CARD_DATE_FORMAT, {});
			}
				break;
			case LOB.CABS: {
				sdata.traveller = paxCount > 1 ? `| ${traveller} + ${paxCount - 1} traveller` : `| ${traveller}`;
				sdata.location = `${pickupDetails?.name} ${cabType === SHORTLIST_SHARE_CONSTANTS.CAB_TYPES.ROUND_TRIP ? `↔` : `→`} ${dropDetails?.name}`;
				sdata.schedule = getDate(pickupDetails?.dateTime, TRF_DETAILS_CONSTANTS.CAB_DATE_FORMAT, {});
				sdata.schedule += (dropDetails?.dateTime ? ` - ${getDate(dropDetails?.dateTime, TRF_DETAILS_CONSTANTS.CAB_DATE_FORMAT, {})}` : '');
			}
				break;
		}
		trfData = { ...sdata };
	});
	return trfData;
};

/**
 * formats notification header styles
 * @author mybizFe
 * @param {String} status
 * @return {Object} formatted notification header styles
 */
export const getNotificationHeader = (status) => {
	switch (status) {
		case TRF_DETAILS_CONSTANTS.APPROVED:
			return { style: NON_COMMENT_STYLE, icon: 'approved' };
		case TRF_DETAILS_CONSTANTS.PENDING:
			return { style: PENDING_STYLE, icon: 'rejected' }; ;

		default:
			return { style: COMMENT_STYLE, icon: 'rejected' };
	}
};

/**
 * formats notification taost data
 * @author mybizFe
 * @param {String} status
 * @param {String} primaryPaxName
 * @param {Number} paxCount
 * @return {Object} formatted notification toast data
 */
export const getNotificationToastData = (status, primaryPaxName, paxCount) => {
	const countText = paxCount > 1 ? ` & ${paxCount - 1} others` : '';
	const toastCompData = {
		toastBannerData: {
			heading: toastDataConfig[status].heading,
			subHeading: `The itinerary request for ${primaryPaxName + countText} ${toastDataConfig[status].endText}`
		},
		IconComponent: () => <TravelRequestIcons icon={`${toastDataConfig[status].icon}`} />
	};
	return toastCompData;
};

/**
 * check whether requisitionId is present in payload
 * @author mybizFe
 * @param {Object} data
 * @return {Boolean} true or false based on the data
 */
export const isReqId = (data) => data && data.requisitionId;
