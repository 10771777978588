import React, { Component } from 'react';

import { logOmniClick } from '../logging/omnitureService';
import { DialogContent, DialogHeader, DialogFooter } from './ModalStyles';
import Modal from 'common/Modal/Modal';

import Button from 'common/Button/Button';

class CorporateModalWithHeader extends Component {
	constructor(props) {
		super(props);
	}

    handleClose = () => {
    	this.props.onClose && this.props.onClose(this.props.selectedValue);
    };

    handleListItemClick = (value) => {
    	this.props.onClose && this.props.onClose(value);
    };

    positiveButtonClick = (event) => {
    	event.preventDefault();
    	logOmniClick('mybusinessadmin:popup:positiveButtonClicked|v2|corporate', {
    		prop54: 'mybusinessadmin:popup:positiveButtonClicked|' + this.props.content + '|v2|corporate'
    	});
    	this.props.positiveButtonCB(event);
    	this.props.onClose && this.props.onClose('positivebuttonclick');
    }

    negativeButtonClick = (event) => {
    	event.preventDefault();
    	logOmniClick('mybusinessadmin:popup:negative|v2|corporate', {
    		prop54: 'mybusinessadmin:popup:negativeButtonClicked|' + this.props.content + '|v2|corporate'
    	});
    	this.props.negativeButtonCB(event);
    	this.props.onClose && this.props.onClose('negativebuttonclick');
    }

    render() {
    	const { classes, onClose, selectedValue, traceId, ...other } = this.props;
    	let show = this.props.show;
    	return (
	<div>
		<Modal
			Modaldata={{
				customWrapperClass: 'zeroPadding'
			}} onClose={this.handleClose} open aria-labelledby='simple-dialog-title' {...other}>
			<DialogHeader
				style={{
					'background-color': '#F85',
					'color': 'white',
					'font-size': '18px',
					'margin': '0px',
					'padding': '10px'
				}} id='simple-dialog-title'>{this.props.title}
			</DialogHeader>
			<DialogContent>{this.props.content}</DialogContent>
			{traceId && <DialogContent>{traceId}</DialogContent>}
			<DialogFooter is2button={this.props.negativeButtonCB}>
				{this.props.positiveButton ?
					<Button
						btnWidth='80px' padding='5px' height='36px' handleClick={this.positiveButtonClick} btnType='Capsule' btnTxt={this.props.positiveButton}
					/> : null}
				{this.props.negativeButtonCB && this.props.negativeButton &&
				<Button
					margin='0 0 0 15px' btnName='Secondary' handleClick={this.negativeButtonClick} btnType='Capsule' btnWidth='auto' padding='5px' height='36px' btnTxt={this.props.negativeButton}
				/>}
			</DialogFooter>
		</Modal>
	</div>

    	);
    }
}

export default (CorporateModalWithHeader);
