import styled, { css } from 'styled-components';

import { ASSET_PATH } from 'assetsPath';
import { FlexDiv, GenericP, GenericSpan } from 'commonStyles';
import { LOB_TITLE } from './config';

export const HeaderContainer = styled.div`
    min-height: 68px;
`;

export const Services = styled.div`
padding: 24px 0px;
border-bottom: 2px solid #F2F2F2;

&:last-child{
  border-bottom: none;
}
`;

export const ServiceDetail = styled(FlexDiv)`
  padding: 20px;
  background: #FFF9F5;
  border-radius: 8px;
  &.Booked{
    background: rgba(233, 246, 234, 0.5);
  }
`;

export const LeftSubContainer = styled(FlexDiv)`
  border-bottom: 2px solid #F2F2F2;
`;

export const TravelRequestIcons = styled(GenericSpan)`
    background: url(${ASSET_PATH.IMAGES}/travreqform/travreq.png) no-repeat;
    background-size: 200px 700px;
    font-size: 0px;
		display: inline-block;
    ${(props) => {
		switch (props.icon) {
			case LOB_TITLE.FLIGHT:
				return css`
                    width: 24px;
                    height: 24px;
                    background-position: -28px -298px;`;
			case LOB_TITLE.HOTEL:
				return css`
                    width: 24px;
                    height: 24px;
                    background-position: -54px -298px;`;
			case LOB_TITLE.BUS:
				return css`
                    width: 24px;
                    height: 24px;
                    background-position: -27px -324px;`;
			case LOB_TITLE.CABS:
				return css`
                width: 24px; 
                height: 24px; 
                background-position: -79px -298px;`;
			case 'greenNotification':
				return css`
                width: 40px; 
                height: 43px; 
				margin-right: 10px;
                background-position: -2px -638px;`;
			case 'RedNotification':
				return css`
						width: 43px; 
						margin-right: 10px;
						height: 43px; 
						background-position: -50px -638px;`;
			case 'approved':
				return css`
						background: url(${ASSET_PATH.IMAGES}/shortlist/shortlist-sprite.png?v=1) no-repeat;
						background-size: 300px 300px;
						width: 40px; 
						height: 43px; 
						background-position: -133px -157px;`;
			case 'rejected':
				return css`
						background: url(${ASSET_PATH.IMAGES}/shortlist/shortlist-sprite.png?v=1) no-repeat;
						background-size: 300px 300px;
						width: 40px; 
						height: 43px; 
						background-position: -200px -157px;`;
			default:
				break;
		}
	}}
`;

export const MailIcon = styled(TravelRequestIcons)`
  padding-left:10px;
  padding-top:10px;
  width: 56px;
  height: 51px;
  margin-right: 20px;
	margin-bottom: -24px;
`;

export const CommentTag = styled(GenericP)`
	white-space: pre-wrap;
	max-width: calc(100% - 100px);
`;
