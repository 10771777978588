import React, { Component } from "react";
import { AppHeaderStyles, AppIcon, LeftAppIcon, MidSpace, RightAppMenu } from "./AppHeaderStyles";
import {ASSET_PATH} from '../../../assetsPath';
import { PATHS } from "AppConstants";

const myBizIcon = ASSET_PATH.IMAGES + 'mybizicon_old.png';

export default class AppHeader extends Component {

    constructor (props) {
        super(props);
    }

    goToHome = (event) => {
        event.preventDefault();
        window.location = PATHS.MYBIZ_URL
    }

    render () {
        return (
            <AppHeaderStyles>
                <LeftAppIcon>
                    <AppIcon src={myBizIcon} onClick={this.goToHome}/>
                </LeftAppIcon>
                <MidSpace/>
                <RightAppMenu>
                    {this.props.children}
                </RightAppMenu>
            </AppHeaderStyles>
        );
    }
}
