import { getDaysDiff } from './Util';
import { PATHS } from '../AppConstants';
import { lobCountDtls, LOB } from '../shortListNShare/config';
import { validHotelDate, sortGrpDetails, getTravDetTxt } from './ShortlistUtils';
import { formatFlightDate, formatHotelDate } from '../shortListNShare/Components/CBTravelList/utils';

export const formatData = (response) => {
	let ret = {};
	const groupsData = {
	};
	let bookingFor = {

	};
	const grpProductIds = {};
	const grpLobIds = {};
	const lobTypes = lobCountDtls;
	if (response.wishlistStatus === 'SHARED') {
		ret.shared = true;
	}
	if (response.wishlistItems && response.wishlistItems.length) {
		bookingFor = response.wishlistMetadata.bookingFor;
		const sortedData = sortGrpDetails(response.wishlistMetadata.grpDetails);
		for (let grp in sortedData) {
			const lob = response.wishlistMetadata.grpDetails[grp].lob;
			groupsData[grp] = {
				products: [],
				type: lob
			};
			if (lobTypes[lob]) {
				lobTypes[lob].count++;
			} else {
				lobTypes[lob] = {
					count: 0
				};
			}
			grpProductIds[grp] = [];
			grpLobIds[grp] = [];
			groupsData[grp].header = getHeader(response.wishlistMetadata.grpDetails[grp]);
		}
		response.wishlistItems.forEach((product) => {
			const grpId = product.productMetadata.groupId;
			if (!groupsData[grpId]) {
				groupsData[grpId] = {
					products: []
				};
				grpProductIds[grp] = [];
				grpLobIds[grp] = [];
			}
			groupsData[grpId].products.push(product);
			grpLobIds[grpId].push(product.productMetadata.lobProductId);
			grpProductIds[grpId].push(product.productId);
		});
	}
	ret.groupsData = groupsData;
	ret.bookingFor = bookingFor;
	ret.grpLobIds = grpLobIds;
	ret.grpProductIds = grpProductIds;
	ret.wishlistId = response.wishlistId;
	ret.lobTypes = lobTypes;
	ret.recipientDetails = response.wishlistMetadata.recipientDetails;
	ret.ownerId = response.wishlistMetadata.ownerMmtUuid;
	return ret;
};

const getHeader = (grpData) => {
	const header = {};
	const subHeading = {};
	const { lob, depCity = {}, cityName, checkin, depDateTime, checkout, roomCount, arrCity = {} } = grpData;
	const travDets = getTravDetTxt(grpData);
	switch (lob) {
		case LOB.HOTEL:
			header.mainHeading = `Hotels in ${cityName}`;
			subHeading.strtdate = formatHotelDate(checkin);
			subHeading.enddate = formatHotelDate(checkout);
			subHeading.nights = getDaysDiff(validHotelDate(checkout), validHotelDate(checkin));
			subHeading.rooms = roomCount;
			subHeading.travCount = travDets;
			break;
		case LOB.FLIGHT:

			header.mainHeading = `Flights from ${depCity.name} to ${arrCity.name}`;
			subHeading.strtdate = formatFlightDate(depDateTime);
			subHeading.travCount = travDets;
			break;

		default:
			break;
	}
	header.subHeading = subHeading;
	return header;
};

export const updateListing = (data, lob, grpId, wishlistId) => {
	const parentWindow = window.opener && window.opener.parent;
	if (parentWindow) {
		const sendData = `shortlist|${wishlistId}|${lob}|${data.length > 1 ? 'GRP' : 'PRD'}|${data.length > 1 ? grpId : data[0]}`;
		parentWindow.postMessage(sendData, window.location.protocol + PATHS.MMT_PATH);
	}
};
