import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { logOmniClick } from '../../logging/omnitureService';
import {
	getDefaultDate,
	getFormattedDate,
	getDateText,
	getDateFilterKeys,
	getShortMonth,
	formatCurrency
} from 'UtilityComponents/Util';
import { APPLICATION_CONSTANTS, REPORTS_CONSTANT, FILTER_CONSTANTS } from 'AppConstants';
import {
	REPORT_TYPE_CONFIG, textsConfig, dateConfig, LIST_HEADING,
	reportTypeConf, reportNameConf, emailTypeConf, frequencyTypeConf,
	dayOfMonthConf, dayOfWeekConf, CHECK_LIST_CONF,
	GRANUALITY_MAP, WEEKDAYS_OPTIONS_MAP, MONTH_DAYS_OPTIONS_MAP, dailyFieldConf, quarterlyFieldConf, REPORT_TYPES
} from '../../reporting/pages/summary/config';
import { BROWSER_URLS } from 'AppConstants';
const monthArr = getDateFilterKeys(APPLICATION_CONSTANTS.TODAY);

/**
 * returns array of object  with values set to render summary list
 * @author mmt8270
 * @param { Object } data response obj from api
 *  @param { String } reportType report type string from url/wallet
 * @return {Array}
 */
export const formatSummaryData = (data, reportType) => data.map((summ) => formatlobSummaryData(summ))
	.filter((rData) => reportType ? rData.type == reportType : true);

/**
 * returns object  with values set to render summary list
 * @author mmt8270
 * @param { Object } summ response obj from api
 * @return {Object}
 */
export const formatlobSummaryData = (summ) => {
	const { numberOfTransactions, type, totalBookings, totalSavings, totalSpend, outOfPolicyBookings,
		startingBalance, closingBalance, rechargedAmount, modificationSavings, claimableGst, convFeesSavings,
		suggestionsMissed, missedSavings, offlineBookings } = summ;
	let retData = {
		icon: REPORT_TYPE_CONFIG[type]?.icon,
		text: REPORT_TYPE_CONFIG[type]?.text,
		detail: [],
		type,
		downloadConfig: REPORT_TYPE_CONFIG[type]?.downloadConfig,
		downloadOpts: REPORT_TYPE_CONFIG[type]?.downloadOpts,
		offlineBookings
	};
	switch (type) {
		case 'WALLET':
			retData.detail.push({
				head: 'Total Transactions',
				value: numberOfTransactions
			});
			retData.detail.push({
				head: 'Starting Balance',
				value: formatCurrency(startingBalance, true)
			});
			retData.detail.push({
				head: 'Closing Balance',
				value: formatCurrency(closingBalance, true)
			});
			retData.detail.push({
				head: 'Recharged Amount',
				value: formatCurrency(rechargedAmount, true)
			});
			break;
		case 'SAVINGS_SUMMARY':
			totalSavings && retData.detail.push({
				head: 'Total Savings',
				value: totalSavings ? formatCurrency(totalSavings, true) : 0,
				color: '#219393'
			});
			modificationSavings && retData.detail.push({
				head: 'Modification Savings',
				value: modificationSavings ? formatCurrency(modificationSavings, true) : 0,
				color: '#219393'
			});
			claimableGst && retData.detail.push({
				head: 'GST Savings',
				value: claimableGst ? formatCurrency(claimableGst, true) : 0,
				color: '#219393'
			});
			convFeesSavings && retData.detail.push({
				head: 'Savings on Convenience Fees',
				value: convFeesSavings ? formatCurrency(convFeesSavings, true) : 0,
				color: '#219393'
			});
			break;
		case 'SAVINGS':
			suggestionsMissed && retData.detail.push({
				head: 'Suggestions Missed',
				value: suggestionsMissed ? suggestionsMissed : 0
			});
			missedSavings && retData.detail.push({
				head: 'Missed Savings',
				value: missedSavings ? missedSavings : 0,
				color: '#219393'
			});
			break;
		default:
			retData.detail.push({
				head: 'Total Bookings',
				value: totalBookings
			});
			retData.detail.push({
				head: 'Total Spend',
				value: totalSpend ? formatCurrency(totalSpend, true) : 0
			});
			totalSavings && retData.detail.push({
				head: 'Total Savings',
				value: totalSavings ? formatCurrency(totalSavings, true) : 0,
				color: '#219393'
			});
			retData.detail.push({
				head: typeof outOfPolicyBookings != 'undefined' ? 'Out of Policy Bookings (%)' : '',
				value: typeof outOfPolicyBookings != 'undefined' ? outOfPolicyBookings : 0
			});
			break;
	}
	return retData;
};

/**
 * returns filter with default date selected
 * @author mmt8270
 * @param { Object } filters group list from config
 * @param { Array } appliedFilters id  of seelcted group on  policy page /url
 * @return {Object}
 */
export const presetFilters = (filters, appliedFilters = []) => {
	const updatedFilter = cloneDeep(filters);
	const query = getDefaultDate(APPLICATION_CONSTANTS.TODAY, REPORTS_CONSTANT.API_DATE_FORMAT, false, APPLICATION_CONSTANTS.DEFAULT_QTR);
	updatedFilter.dateRange.options = getDateFilterObj(updatedFilter.dateRange.options, query.startDate, query.endDate);
	updatedFilter.dateRange.selectedHead = LIST_HEADING.subText;
	updatedFilter.dateRange.value = APPLICATION_CONSTANTS.DEFAULT_QTR;
	let defaultSelected = updatedFilter.dateRange.options.filter((filterItem) => {
		if (filterItem.type === APPLICATION_CONSTANTS.DEFAULT_QTR) {
			filterItem.checked = true;
			return filterItem;
		}
	});
	if (defaultSelected && defaultSelected[0]) {
		appliedFilters.push(defaultSelected[0]);
	}
	return {
		filters: updatedFilter,
		appliedFilters: appliedFilters,
		query
	};
};

/**
 * returns formatted date filter object with months and quarter value
 * @author mmt8270
 * @param { Object } dateFilter group list from config
 * @param { String } from from date tobe selected
 * @param { String } to todate to be selected
 * @return {Object}
 */
const getDateFilterObj = (dateFilter, from, to) => {
	const monthLength = monthArr.length;
	const filterObj = monthArr.slice(0, monthLength - APPLICATION_CONSTANTS.QUARTER).map((m, i) => {
		const monthFlt = getMonthFilter(m);
		monthFlt.index = i;
		return monthFlt;
	});
	dateFilter[0].countKey = FILTER_CONSTANTS.CUSTOM_DATE_FILTER;
	textsConfig[FILTER_CONSTANTS.CUSTOM_DATE_FILTER] = getDateText(from, to, true);
	dateConfig[FILTER_CONSTANTS.CUSTOM_DATE_FILTER] = {
		startDate: from,
		endDate: to
	};
	const qtrFilter = getQtrFilter(monthArr[monthLength - 1]);
	qtrFilter.index = filterObj.length;
	qtrFilter.listHead = 'Last Quarter';
	filterObj.push(qtrFilter);
	dateFilter[0].index = filterObj.length;
	filterObj.push(dateFilter[0]);
	return filterObj;
};

/**
 * returns month filter object
 * @author mmt8270
 * @param { Number } m month for which filter to be created
 * @return {Object}
 */
const getMonthFilter = (m) => {
	const year = APPLICATION_CONSTANTS.TODAY.getFullYear();
	const month = APPLICATION_CONSTANTS.TODAY.getMonth();
	const text = `${getShortMonth(m)} ${year}`;
	textsConfig[m] = text;
	const start = getFormattedDate(new Date(year, m, 1), REPORTS_CONSTANT.API_DATE_FORMAT);
	const end = getFormattedDate(new Date(year, m + 1, 0), REPORTS_CONSTANT.API_DATE_FORMAT);
	let trackText = 'currentmonth';
	if (m === month - 1) {
		trackText = 'previousmonth';
	}
	if (m === month - 2) {
		trackText = 'previousmonth2';
	}
	dateConfig[m] = {
		startDate: start,
		endDate: end
	};
	const fltHeading = (() => {
		switch (month - m) {
			case 0:
				return 'This Month';
			case 1:
				return 'Last Month';
			default:
				return text;
				break;
		}
	})();
	return {
		type: m,
		label: text,
		listHead: fltHeading,
		value: m,
		checked: false,
		start: start,
		end: end,
		key: FILTER_CONSTANTS.DATE_FILTER,
		filterKey: FILTER_CONSTANTS.DATE_FILTER,
		trackVal: 'date_' + trackText
	};
};

/**
 * returns formatted quarter filter object quarter value
 * @author mmt8270
 * @param { Number } qtrStart quarter start month
 * @return {Object}
 */
const getQtrFilter = (qtrStart) => {
	const currDate = APPLICATION_CONSTANTS.TODAY;
	const currMonth = currDate.getMonth();
	const year = currMonth > qtrStart ? currDate.getFullYear() : currDate.getFullYear() - 1;
	const start = getFormattedDate(new Date(year, qtrStart, 1), REPORTS_CONSTANT.API_DATE_FORMAT);
	const end = getFormattedDate(new Date(year, qtrStart + APPLICATION_CONSTANTS.QUARTER, 0), REPORTS_CONSTANT.API_DATE_FORMAT);
	const text = `QTR ${(qtrStart) / APPLICATION_CONSTANTS.QUARTER + 1} - (${getDateText(start, end, false)})`;
	textsConfig[FILTER_CONSTANTS.QTR_FILTER] = text;
	dateConfig[FILTER_CONSTANTS.QTR_FILTER] = {
		startDate: start,
		endDate: end
	};
	return {
		type: FILTER_CONSTANTS.QTR_FILTER,
		label: text,
		start: start,
		key: FILTER_CONSTANTS.DATE_FILTER,
		end: end,
		value: FILTER_CONSTANTS.QTR_FILTER,
		checked: false,
		filterKey: FILTER_CONSTANTS.DATE_FILTER,
		trackVal: 'date_lastquarter'
	};
};

/**
 * get months value array for date filter
 * @author mmt8270
 * @param { Object } filterQuery no  of days covered by filter
 * @param { Object } filter no  of days covered by filter
 * @param { Object } initialQuery no  of days covered by filter
 * @return {Object}
 */
export const getFilteredQuery = (filterQuery, filter, initialQuery) => {
	const key = filter.key;
	filterQuery[REPORTS_CONSTANT.START_DATE] = initialQuery[REPORTS_CONSTANT.START_DATE];
	filterQuery[REPORTS_CONSTANT.END_DATE] = initialQuery[REPORTS_CONSTANT.END_DATE];
	if (filter.selectedCount === 0 && filter.filterType === 'date') {
		const defaultDate = getDefaultDate(APPLICATION_CONSTANTS.TODAY, REPORTS_CONSTANT.API_DATE_FORMAT, monthArr.length - 4);
		filterQuery[REPORTS_CONSTANT.START_DATE] = defaultDate[REPORTS_CONSTANT.START_DATE];
		filterQuery[REPORTS_CONSTANT.END_DATE] = defaultDate[REPORTS_CONSTANT.END_DATE];
	}
	filterQuery[key] = [];
	if (filter.type === FILTER_CONSTANTS.DROPDOWN) {
		filter.value.forEach((val) => {
			filterQuery[key].push(val.value);
		});
	} else if (filter.type === FILTER_CONSTANTS.CHECKBOX) {
		filter.options.forEach((option) => {
			if (option.checked && option.key === FILTER_CONSTANTS.DATE_FILTER) {
				filterQuery[REPORTS_CONSTANT.START_DATE] = option.start;
				filterQuery[REPORTS_CONSTANT.END_DATE] = option.end;
				if (option.type === FILTER_CONSTANTS.CUSTOM_DATE_FILTER) {
					textsConfig[FILTER_CONSTANTS.CUSTOM_DATE_FILTER] = getDateText(option.start, option.end, true);
					dateConfig[FILTER_CONSTANTS.CUSTOM_DATE_FILTER] = {
						startDate: option.start,
						endDate: option.end
					};
					filter.selectedHead = option.listHead;
				}
			} else if (option.checked) {
				filterQuery[key].push(option.value);
			}
		});
	}
	filterQuery.pageNo = 0;

	if (isEmpty(filterQuery[key])) {
		delete filterQuery[key];
	}
	return filterQuery;
};

/**
 * returns object  with values set to all cards
 * @author mmt8054
 * @param { Object } data response obj from api
 * @return {Object}
 */
export const formatCustomReportData = (data) => data.map((item) => {
	const { reportFieldDetails = [], reportType = '' } = item || {};
	return ({
		...item,
		icon: REPORT_TYPE_CONFIG[reportType]?.icon,
		fieldNames: reportFieldDetails.map((field) => field.value)
	});
});

/**
 * returns fields object for report form both new and edit form
 * for edit form based on the apiReponseData updates the value in fields object
 * @author mmt8054
 * @param { Object } apiReponseData response obj from api
 * @return {Object} formatted reponse Object
 */
export const formatReportData = (apiReponseData) => {
	const reportDataObj = {
		reportField: {
			reportTypeField: cloneDeep(reportTypeConf),
			reportNameField: cloneDeep(reportNameConf)
		},
		emailTypeField: cloneDeep(emailTypeConf),
		frequencyField: {
			frequencyTypeField: cloneDeep(frequencyTypeConf),
			graunality: cloneDeep(dayOfWeekConf)
		},
		formattedAdditionalData: [],
		reportAdditionalData: []
	};
	if (apiReponseData) {
		for (let key in apiReponseData) {
			const val = apiReponseData[key];
			switch (key) {
				case 'reportType':
					const reportTypeField = reportDataObj.reportField.reportTypeField;
					const reportFiledOptions = reportTypeField.options;
					for (let option of reportFiledOptions) {
						if (option.value === val) {
							reportTypeField.value = option;
						}
					}
					break;
				case 'reportName':
					const reportNameField = reportDataObj.reportField.reportNameField;
					reportNameField.value = val;
					break;
				case 'emails':
					const emailTypeField = reportDataObj.emailTypeField;
					let emailval = [];
					for (let email of val) {
						emailval.push({ label: email, value: email });
					}
					emailTypeField.value = emailval;
					break;
				case 'frequency':
					const frequencyTypeField = reportDataObj.frequencyField.frequencyTypeField;
					const frequencyOptions = frequencyTypeField.options;
					for (let foption of frequencyOptions) {
						if (foption.value === val) {
							frequencyTypeField.value = foption;
						}
					}
					break;
				case 'frequencyDay':
					let graunality = reportDataObj.frequencyField.graunality;
					const frequency = apiReponseData['frequency'];
					switch (frequency) {
						case GRANUALITY_MAP.DAILY.value:
							graunality = cloneDeep(dailyFieldConf);
							break;
						case GRANUALITY_MAP.WEEKLY.value:
							graunality = cloneDeep(dayOfWeekConf);
							graunality.value = WEEKDAYS_OPTIONS_MAP[val + ''];
							break;
						case GRANUALITY_MAP.MONTHLY.value:
							graunality = cloneDeep(dayOfWeekConf);
							graunality.value = MONTH_DAYS_OPTIONS_MAP[val + ''];
							break;
						case GRANUALITY_MAP.QUARTERLY.value:
							graunality = cloneDeep(quarterlyFieldConf);
							break;
					}
					reportDataObj.frequencyField.graunality = graunality;
					break;
				case 'reportFieldDetails':
					reportDataObj.reportAdditionalData = val;
					break;
			}
		}
	}

	return reportDataObj;
};

/**
 * returns additional (checkbox)fields object for report form both new and edit form
 * for edit form based on the api reponse received in get report stored in reducer
 * updates the value in fields object
 * @author mmt8054
 * @param { Object } masterReport response obj from getAdditionalData for particular
 * report type
 * @param { Object } additionalData object was previously stored in redux store
 *  recieved from getReport api
 * for new  report it will be null
 * @return {Object} formatted reponse Object
 */
export const formatAddReport = (masterReport, additionalData) => {
	let obj = {};

	const formattedAdditionalData = [];
	if (additionalData) {
		for (let data of additionalData) {
			obj[data.key] = cloneDeep(data);
		}
	}
	if (masterReport) {
		for (let report of masterReport) {
			const updateSet = obj[report.key];
			const updateFrom = updateSet || report;
			const { mandatory } = report;
			updateFrom.mandatory = mandatory;
			const { fields, selected, key, value } = updateFrom;
			const reportField = cloneDeep(CHECK_LIST_CONF);
			reportField.isMandatory = mandatory;
			reportField.options = [getOptionObj(updateFrom)];

			reportField.key = key;
			reportField.name = value;
			let subOptions = [];
			let count = 0;
			const choosedFields = fields;
			for (let field of choosedFields) {
				let rep = getOptionObj(field, selected, mandatory);
				subOptions.push(rep);
				if (rep.value) {
					count++;
				}
			}
			reportField.selectedCount = count;
			reportField.checkField.options = subOptions;
			formattedAdditionalData.push(reportField);
		}
	}
	return formattedAdditionalData;
};

/**
 * create and return option object
 * @author mmt8054
 * @param { Object } report creating option object based on this field
 * @param { Object } parentselected used to set value of option object
 * @param { Object } parentMandatory used to set value of option object
 * @return {Object} formatted reponse Object
 */
const getOptionObj = (report, parentselected, parentMandatory) => {
	const { selected, value, key, mandatory } = report;
	let val;
	if (parentselected) {
		val = parentselected;
	} else if (mandatory) {
		val = true;
	} else {
		val = selected;
	}
	const isMandatory = parentMandatory ? parentMandatory : mandatory;
	return {
		value: val,
		label: value,
		key: key,
		isMandatory,
		customClass: isMandatory ? 'mandatory' : '',
		checkOnly: isMandatory
	};
};

/**
 * update granuality in report form based on frequency type selected by the user in
 * new or edit form
 * @author mmt8054
 * @param { Object } fField frequency field from redux store
 * @param { Object } frequency updated in the form
 * @return {Object} return frequencyField
 */
export const formatGranuality = (fField, frequency) => {
	const frequencyField = cloneDeep(fField);
	switch (frequency) {
		case GRANUALITY_MAP.DAILY.value:
			frequencyField.graunality = cloneDeep(dailyFieldConf);
			break;
		case GRANUALITY_MAP.WEEKLY.value:
			frequencyField.graunality = cloneDeep(dayOfWeekConf);
			break;
		case GRANUALITY_MAP.MONTHLY.value:
			frequencyField.graunality = cloneDeep(dayOfMonthConf);
			break;
		case GRANUALITY_MAP.QUARTERLY.value:
			frequencyField.graunality = cloneDeep(quarterlyFieldConf);
			break;
	}
	return frequencyField;
};

/**
 * update additional field in new or edit form based on user selection
 * updates based on 2 levels child and parent
 * parent refers to outer checkbox => if parent is toggled al childs get toggled except
 * the manadtory field which can'be changed
 * child refers to sub checkboxes => if all childs get sleceted parent get's selected
 * and vice a versa
 *
 * @author mmt8054
 * @param { Object } formattedAdditionalData field from redux store
 * @param { Object } payload object from the form
 * @return {Object} return formatted additional object
 */
export const formatAddData = (formattedAdditionalData, payload) => {
	const { index, level } = payload;
	const data = cloneDeep(formattedAdditionalData);
	const item = data[index];
	const childOpt = item.checkField.options;
	if (level === 'parent') {
		let val = item.value;
		let count = 0;
		for (let opt of childOpt) {
			if (!opt.isMandatory) {
				opt.value = val;
			}
			if (opt.value) {
				count++;
			}
		}
		item.checkField.options = childOpt;
		item.selectedCount = count;
	} else {
		let count = 0;
		for (let opt of childOpt) {
			if (opt.value) {
				count++;
			}
		}
		const status = count === childOpt.length;
		if (!item.isMandatory) {
			item.value = status;
			item.options[0].value = status;
		}
		item.selectedCount = count;
	}
	data[index] = item;
	return data;
};

/**
 * formats and returns the options array for email auto suggest api
 * @author mmt8054
 * @param { Object } employees frequency field from redux store
 * @return {Object} return object array of options
 */
export const formatEmployeeData = (employees) => employees.map((employee) => {
	const { businessEmailId } = employee;
	return ({
		value: businessEmailId,
		label: businessEmailId
	});
});

/**
 * Format Additional data for the request payload
 * @author - mmt8760
 *
 * @param {Object} fieldData - selectedField data of a report
 * @return {Object} - request payload data
 */
export const formatAdditionalData = (fieldData) => {
	let additionalData = [];
	for (let addFiled of fieldData) {
		let { checkField, name, options = [] } = addFiled;
		let val = [];
		for (let opt of checkField.options) {
			const { key, label, value, isMandatory } = opt || {};
			const respObj = {
				key,
				value: label,
				selected: value,
				isMandatory
			};
			val.push(respObj);
		}

		const { key, label, value, isMandatory } = options[0] || {};
		additionalData.push(
			{
				key,
				value: label,
				selected: value,
				isMandatory,
				fields: val
			}
		);
	}
	return additionalData;
};

/**
 * prepares the request object for all form fields
 * @author mmt8054
 * @param { Object } payload frequency field from redux store
 * @return {Object} return request object for form save
 */
export const formatReportRequest = (payload) => {
	const requestObj = {};
	for (let fieldType in payload) {
		const fieldData = payload[fieldType];
		switch (fieldType) {
			case 'reportField':
				const { reportTypeField, reportNameField } = fieldData;
				requestObj['reportType'] = reportTypeField?.value?.value;
				requestObj['reportName'] = reportNameField?.value;
				break;
			case 'emailTypeField':
				const emailval = fieldData?.value;
				let emails = [];
				for (let email of emailval) {
					emails.push(email.value);
				}
				requestObj['emails'] = emails;
				break;
			case 'frequencyField':
				const { frequencyTypeField, graunality } = fieldData;
				requestObj['frequency'] = frequencyTypeField?.value?.value;
				const granVal = graunality?.value;
				requestObj['frequencyDay'] = typeof granVal === 'object' ? granVal?.value : granVal;
				break;
			case 'formattedAdditionalData':
				requestObj['reportFieldDetails'] = formatAdditionalData(fieldData);
				break;
			case 'crId':
				requestObj['crId'] = fieldData;
				break;
			case 'disabled':
				requestObj['disabled'] = fieldData;
				break;
		}
	}
	return { reportData: requestObj };
};

export const omnitureLogging = (prop, eventName) => {
	let pageName = 'mybusinessadmin:reports:revamplanding|corporate';
	logOmniClick(eventName, {
		prop54: prop,
		pageName: pageName
	});
};

const START_TIME = '00:00:00';
const END_TIME = '23:59:59';

export const addTimeStamp = (params) => {
	const tempParams = cloneDeep(params);
	if (tempParams?.filter?.startDate) {
		tempParams.filter.startDate = `${tempParams.filter.startDate} ${START_TIME}`;
	}
	if (tempParams?.filter?.endDate) {
		tempParams.filter.endDate = `${tempParams.filter.endDate} ${END_TIME}`;
	}
	return tempParams;
};

/**
 * formate each custom report field
 * @param {Object} reportField
 * @param {Object} frequencyField
 * @param {Object} updatedDataField
 * @return {Object}
 */
export const reportFieldFormatter = (reportField, frequencyField, updatedDataField) => {
	const { reportNameField, reportTypeField = {} } = reportField || {};
	const { frequencyTypeField } = frequencyField;
	const { name } = updatedDataField;
	let result = {};
	switch (name) {
		case 'reportName':
			result = {
				reportField: {
					reportNameField: cloneDeep(updatedDataField),
					reportTypeField
				}
			};
			break;
		case 'reportType':
			result = {
				reportField: {
					reportTypeField: cloneDeep(updatedDataField),
					reportNameField
				}
			};
			break;
		case 'emailAddress':
			result = {
				emailTypeField: cloneDeep(updatedDataField)
			};
			break;
		case 'dayOfWeek':
			result = {
				frequencyField: {
					frequencyTypeField,
					graunality: cloneDeep(updatedDataField)
				}
			};
			break;
		default:
	}
	return result;
};

export const isreportsPage = () => {
	if (typeof window !== 'undefined' && typeof window.location !== 'undefined' &&
		window.location.pathname === BROWSER_URLS.REPORT_WALLET_DETAIL) {
		return true;
	}
	return false;
};

/**
 * return default report level based on type of report and ab experiment value
 * @param {String} reportType
 * @param {Number} abKey
 * @return {String} return default report level
 */
export const getReportLevel = (reportType, abKey) => {
	if (reportType === REPORT_TYPES.BUS_TYPE || reportType === REPORT_TYPES.CAB_TYPE || reportType === REPORT_TYPES.TRAIN_TYPE) {
		return (reportType === REPORT_TYPES.BUS_TYPE || reportType === REPORT_TYPES.TRAIN_TYPE) ? REPORTS_CONSTANT.ACTION_LEVEL : REPORTS_CONSTANT.INVOICE_LEVEL;
	}
	if (abKey === 1) {
		return REPORTS_CONSTANT.INVOICE_LEVEL;
	}
	if (abKey === 2) {
		return REPORTS_CONSTANT.ACTION_LEVEL;
	}
	return (reportType === REPORT_TYPES.BUS_TYPE || reportType === REPORT_TYPES.TRAIN_TYPE) ? REPORTS_CONSTANT.ACTION_LEVEL : REPORTS_CONSTANT.INVOICE_LEVEL;
};

export const formatGst2AReport = (emails, activeGranuality, crId) => {
	const requestObj = {};
	requestObj['reportType'] = 'GST_2A_REPORT';
	requestObj['reportName'] = 'Gst2A';
	requestObj['emails'] = emails;
	requestObj['frequency'] = activeGranuality?.value;
	requestObj['frequencyDay'] = 15;
	requestObj['crId'] = crId;
	return requestObj;
};
