import { reduce, filter, contains, toLower, toString, compose } from 'ramda';

import { APPLICATION_CONSTANTS } from 'AppConstants';
import { sortData } from './functionalUtility';
import { logOmniClick } from '../logging/omnitureService';
import { omnitureConfig } from '../Groups/config';
import { empOmniConfig } from '../Employee/config';

const isOrgGroup = (id) => id.toString() === APPLICATION_CONSTANTS.ORGANIZATION_LEVEL_GROUP_ID;

const filterGroupData = (accu, currVal) => {
	const { orgData, nonOrgData } = accu;
	isOrgGroup(currVal.groupId) ? orgData.push(currVal) : nonOrgData.push(currVal);
	return { ...accu };
};

export const groupFilterData = (data) => reduce(filterGroupData, { orgData: [], nonOrgData: [] }, data);

export const getGroupDetails = (id, grps) => {
	if (!id || !grps) return {};
	const getId = (el) => el === id.toString();
	const filterbyId = compose(getId, toString);
	return filter((d) => filterbyId(d.groupId), grps)[0];
};

export const setSelectedGroups = ( payload, selectedList ) => {
	try {
		const { groupList = [], checked, selectAll } = payload;
		groupList.forEach((item) => {
			item.checked = checked;
			if (checked) {
				selectedList[item.groupId] = true;
			} else {
				delete selectedList[item.groupId];
			}
		});
		return {
			selectedGroups: groupList.length === 0 ? {} : { ...selectedList },
			isAllGrpSelected: selectAll
		};
	} catch (err) {}
};

export const doSorting = ( payload, isNumericField ) => {
	try {
		const { list, key, sortState } = payload;
		return sortData(list, key, sortState, isNumericField);
	} catch (err) {
		return payload.list;
	}
};

export const filterGroupByName = ( filterString, grps ) => {
	try {
		const fString = compose(contains, toLower);
		const filterBy = fString(filterString);
		const filterGrps = compose(filterBy, toLower);
		return filter((d) => filterGrps(d.groupName), grps);
	} catch (err) {
		return grps;
	}
};

export const omnitureLogging = (prop, page) => {
	let eventName = 'Corporate_Menubar_group_and_policies';
	if (page === 'detailPage') {
		eventName = 'Corporate_Menubar_group_details';
	} else if (page === 'employee') {
		eventName = 'Corporate_Menubar_ManageTeam';
	}
	logOmniClick(eventName, {
		prop54: prop
	});
};

export const logCheckBoxOmniture = (checked, suffix) => {
	const { prop } = omnitureConfig.groupListCheckBox;
	const status = checked ? 'selected' : 'unselected';
	omnitureLogging(`${prop}${status}_${suffix}`, 'detailPage');
};

export const omniture = (omniObj) => {
	const { prop, page } = omniObj;
	omnitureLogging( prop, page);
};

export const trackFilterEvent = (prop) => {
	omnitureLogging( prop, 'employee');
};
