import { PATHS } from 'AppConstants';
import { getAxiosConfig } from '../apisSetup/apiConfig';

const ccbAxiosApiCaller = getAxiosConfig(PATHS.CCB_URL, true, {
}, false);

export const getBookingsData = (apidata = {}) => ccbAxiosApiCaller.post(PATHS.GET_BOOKING_LIST, apidata);

export const getFilterCountData = (apidata = {}) => ccbAxiosApiCaller.post(PATHS.GET_BOOKINGS_FILTERS_COUNT, apidata);

export const modifiyBookingApi = (apidata = {}) => ccbAxiosApiCaller.post(PATHS.MODIFY_BOOKING, apidata);
