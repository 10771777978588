import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { ThemeProvider } from 'styled-components';

import { AngleArrow } from '../Styles';
import { ASSET_PATH } from '../../../assetsPath';
import * as S from './ChatBotStyle';

import {
	handlingModalOpeningClosing
} from '../../redux/actions/bizHeaderActions';
import { PATHS } from 'AppConstants';
import HelpSection from '../HelpSection/HelpSection';
import Theme from 'common/Theme';
import { logOmniClick } from 'omnitures';

const chatConfig = {
	heading: 'Need help?',
	subHeading: 'Connect with your dedicated support manager',
	imgSrc: ASSET_PATH.IMAGES + 'chat.png',
	mobileImgSrc: ASSET_PATH.IMAGES + 'mobileChat.png'
};

const ChatBot = ({ botClickHandler,
	showBot = false,
	radioField,
	requestFormPopUpProp = false,
	pathUrl = false,
	showHelpSection,
	showSecondaryBtn = true,
	hide,
	isMobile,
	helpInBot,
	helpSectionHandler,
	...props }) => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const [dock, setDock] = useState(false);
	const [detailBox, setDetailBox] = useState(false);
	const isContactUs = pathname === PATHS.CONTACT_US;

	useEffect(() => {
		clickHandler(isContactUs ? isContactUs : showHelpSection, false);
	}, [showHelpSection, clickHandler, isContactUs]);

	useEffect(() => {
		if (showBot) {
			setTimeout(() => {
				setDock(true);
			}, 5000);
		}
	}, [showBot]);

	const clickHandler = useCallback((state, initialCall = true) => {
		if (initialCall) {
			helpSectionHandler && helpSectionHandler(state);
		}
		setDetailBox(state);
		openSupportPopUp(state);
		botClickHandler && botClickHandler(state);
		if (!isContactUs) {
			logOmniClick('Requestcallback_clicked', {
				prop54: 'Requestcallback_clicked'
			});
		}
	}, [botClickHandler, helpSectionHandler, isContactUs, openSupportPopUp]);

	const openSupportPopUp = useCallback((state) => {
		const payload = {
			requestFormPopUp: state,
			showPopUp: false,
			confirmPopUp: false
		};
		dispatch(handlingModalOpeningClosing(payload));
	}, [dispatch]);

	const dockHandler = useCallback((status, e) => {
		if (typeof status === 'boolean') {
			e.stopPropagation();
			setTimeout(() => {
				setDock(status);
			});
		} else {
			const state = !detailBox;
			clickHandler(state);
		}
	}, [detailBox, clickHandler]);

	return (
		<ThemeProvider theme={Theme}>
			{showBot &&
			<S.Rectangle onClick={dockHandler} dock={dock}>
				<S.ChatIcon
					crossOrigin='anonymous'
					src={isMobile ? chatConfig.mobileImgSrc : chatConfig.imgSrc} alt=''
				/>
				<S.ContentWraper $direction='column' $cursor='pointer'>
					<S.HeadText>{chatConfig.heading}</S.HeadText>
					{!isMobile && <S.SubHead>{chatConfig.subHeading}</S.SubHead>}
				</S.ContentWraper>
				{!isMobile && <AngleArrow color='#008cff' $top='50px' pos_right='15px' onClick={(e) => dockHandler(true, e)} />}
			</S.Rectangle>}
			{detailBox && <HelpSection
				{...props}
				show={detailBox}
				disablePopUp={dockHandler}
				radioField={radioField}
				requestFormPopUpProp={requestFormPopUpProp}
				pathUrl={pathUrl}
				showSecondaryBtn={isMobile ? false : showSecondaryBtn}
				isMobile={isMobile}
				showWhatsappLink={isMobile}
			/>}
		</ThemeProvider>
	);
};

export default ChatBot;
