// NPM Dependencies
import React, { useState, useCallback, useMemo } from 'react';
import Select, { components } from 'react-select';

// Styles
import { DropDownWrapper } from './DropdownStyles';

// Config
import { dropDownConfig } from './DropDownConfig';

const { ValueContainer, Placeholder } = components;

const DropDown = ({
	field = {},
	customComponents,
	customStyles,
	keepPlaceholder,
	placeholder,
	getOptionLabel,
	getOptionValue,
	handleChange,
	width,
	padding,
	border_B,
	f_Size,
	borderLess = false,
	onInputChange,
	loadingMessage
}) => {
	const { selectedValue, options, isDisabled, isMulti, formatOptionLabel, value: fieldValue } = field;

	const [value, setValue] = useState(fieldValue || '');
	const [isFocused, setIsFocused] = useState(false);

	const focusHandler = useCallback((focusState) => () => {
		setIsFocused(focusState);
	}, []);

	const components = useMemo(() => {
		const CustomValueContainer = ({ children, ...props }) => (
			<ValueContainer {...props}>
				<Placeholder {...props} isFocused={isFocused}>
					{props.selectProps.placeholder}
				</Placeholder>
				{React.Children.map(children, (child) =>
                	child && child.type !== Placeholder ? child : null)}
			</ValueContainer>
		);

		if (keepPlaceholder) {
			return ({
				...customComponents,
				ValueContainer: CustomValueContainer
			});
		}
		return ({
			...customComponents
		});
	}, [isFocused, customComponents, keepPlaceholder]);

	const onChangeHandler = useCallback((value) => {
		handleChange(value);
		setValue(value);
	}, [handleChange]);

	const styles = useMemo(() => {
		if (keepPlaceholder) {
			return ({
				...dropDownConfig,
				...customStyles
			});
		}
		return ({
			...customStyles
		});
	}, [customStyles, keepPlaceholder]);

	return (
		<DropDownWrapper
			borderLess={borderLess}
			style={{ width: width ? width : `100%` }}
			padding={padding}
			f_Size={f_Size}
			border_B={border_B}>
			<Select
				classNamePrefix='InnerSelct'
				simpleValue
				value={value || selectedValue}
				onChange={onChangeHandler}
				options={options}
				isDisabled={isDisabled}
				isMulti={isMulti}
				selectedValue={selectedValue}
				formatOptionLabel={formatOptionLabel}
				placeholder={placeholder}
				components={components}
				styles={styles}
				getOptionLabel={getOptionLabel}
				getOptionValue={getOptionValue}
				onInputChange={onInputChange}
				noOptionsMessage={()=>loadingMessage || dropDownConfig.noOptionsTxt}
			/>
		</DropDownWrapper>
	);
};

export default DropDown;
