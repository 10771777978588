import styled, { css } from 'styled-components';
import { ASSET_PATH } from '../../assetsPath';
import { GenericSpan } from '../common/Styles';

export const SpriteIcon = styled(GenericSpan) `
	width: 35px;
	height: 26px;
	background: url(${ASSET_PATH.IMAGES}adminWalletSprite.png?v=1) no-repeat;
	background-size: 223px 196px;
	display: inline-block;
	${(props) => {
		switch (props.icon) {
			case 'greenWallet':
				return css`
					background-position: -2px -30px;`;
			case 'blueInfoIcon':
				return css`
					width: 17px;
					height: 20px;
					background-position: -20px -6px;`;
			case 'yellowWallet':
				return css`
					background-position: -42px -30px;`;
			case 'orangeWallet':
				return css`
					background-position: -82px -30px;`;
			case 'alertIcon':
				return css`
					width: 24px;
					height: 26px;
					background-position: -47px -1px;`;
			case 'exclaimYellow':
				return css`
					width: 72px;
					height: 72px;
					background-position: -47px -1px;`;
			case 'exclaimRed':
				return css`
					width: 72px;
					height: 72px;
					background-position: -47px -1px;`;
			case 'FLIGHT':
				return css`
					width: 19px;
					height: 14px;
					background-position: 0px -64px;`;
			case 'HOTEL':
				return css`
					width: 19px;
					height: 16px;
					background-position: -22px -64px;`;
			case 'BUS':
				return css`
					width: 19px;
					height: 14px;
					background-position: -43px -64px;`;
			case 'CABS':
				return css`
					width: 19px;
					height: 14px;
					background-position: -62px -64px;`;
			case 'TRAIN':
				return css`
							width: 19px;
							height: 19px;
							background-position: -83px -61px;`;
			case 'miscellaneous':
				return css`
					width: 19px;
					height: 14px;
					background-position: -77px -8px;`;
			case 'instantRefund':
				return css`
					width: 33px;
					height: 27px;
					background-position:-1px -129px;;
					margin-right:5px;
				`;
			case 'reports':
				return css`
					width: 33px;
					height: 27px;
					background-position:-78px -129px;
					margin-right:5px;
				`;
			case 'oneClick':
				return css`
					width: 33px;
					height: 27px;
					background-position:-39px -130px;
					margin-right:5px;`
				;
			case 'greenCircular':
				return css`
					width: 16px;
					height: 16px;
					background-position: -159px -128px;
					display: inline-block;
					margin-right:5px;
					`;
			case 'addMoney':
				return css`
					width: 16px;
					height: 17px;
					background-position: -179px -130px;
					display: inline-block;
					margin-right:5px;`;
			case 'how':
				return css`
					width: 17px;
					height: 17px;
					background-position: -199px -129px;
					display: inline-block;
					margin-right:5px;`;
			case 'guideArw':
				return css`
					width: 17px;
					height: 38px;
					background-position: -65px -85px;
					display: inline-block;
					margin-right:5px;	`;
			case 'default':
				return css`
					width: 19px;
					height: 14px;
					background-position: -77px -8px;`;
			case 'noresult':
				return css`
					width: 50px;
					height: 38px;
					background-position: -1px -87px;`;
			case 'rewards':
				return css`
					width: 23px;
					height: 26px;
					background-position: -6px -166px;`;

			default:
				break;
		}
	}}
`;

export const DotIcon = styled.span`
	width: ${(props) => (props.size || 6)}px;
	height: ${(props) => (props.size || 6)}px;
	border-radius: 50%;
	display: inline-block;
	background-image: ${(props) => props.$bg || 'linear-gradient(0,#000,#000)'};
	vertical-align: middle;
`;

export const BenefitSpriteIcon = styled(GenericSpan) `
	background: url(${ASSET_PATH.IMAGES}walletBenefitsSprite.png) no-repeat;
	width: 40px;
	height: 40px;
	background-size: 304px 261px;
	display: inline-block;
	${(props) => {
		switch (props.icon) {
			case 'intro':
				return css`
					width: 232px;
					height: 201px;
					background-position: -5px -5px;`;
			case 'reconcile':
				return css`
					background-position: -247px -129px;`;
			case 'card':
				return css`
					background-position: -5px -216px;`;
			case 'star':
				return css`
					background-position: -55px -216px;`;
			case 'redAlert':
				return css`
					width: 52px;
					height: 52px;
					background-position: -247px -67px;`;
			case 'reminder':
				return css`
					background-position: -122px -216px;`;
			case 'orangeAlert':
				return css`
					width: 52px;
					height: 52px;
					background-position:-247px -5px;`;
		}
	}}
`;
