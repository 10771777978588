import { urlMap, showOptions, EXPENSE_KEYS, CLAIMS_STATE_CONFIG } from '../bizHeader/config';
import { setProfileData, checkLoggedIn } from 'UtilityComponents/Util';
import { V2_APPLICATION_PATH } from 'AppConstants';

export const formatOptions = (data = {}, diceEnabled, locationData = {}) => {
	const options = data.headers;
	const result = {
		options: [],
		links: [{ 'text': 'Admin', 'key': 'DSH' }],
		more: {
			active: false,
			options: []
		},
		isAdmin: false
	};
	if (!options) {
		return result;
	}
	let pagelocation = locationData;
	if (typeof window !== 'undefined') {
		pagelocation = window.location;
	}
	let searchUrl = pagelocation.pathname;
	let hashUrl = pagelocation.hash;
	let searchQry = pagelocation.search;
	hashUrl = hashUrl && hashUrl.split('?')[0];
	if (hashUrl) {
		searchUrl = searchUrl + hashUrl;
	}

	let tFeatureCount = 0;
	let isActiveUrl = {};
	for (const i in urlMap) {
		if (searchUrl === urlMap[i].url || (searchUrl === V2_APPLICATION_PATH + urlMap[i].url)) {
			isActiveUrl = urlMap[i];
		}
	}
	if (isActiveUrl.isLoggedIn) {
		checkLoggedIn(searchUrl);
	}
	result.isAdmin = isActiveUrl.isAdmin;
	result.isLoggedIn = isActiveUrl.isLoggedIn;
	result.showHelp = isActiveUrl.showHelp;
	result.links = isActiveUrl.links || result.links;
	const optLen = options.length;
	for (let i = 0; i < optLen; i++) {
		const opt = options[i];
		const optData = {};
		const showExp = typeof diceEnabled !== 'undefined';
		const { EXPGRP, MYCLAIMS } = EXPENSE_KEYS;
		const { key = '', options: optionsData } = opt;
		if ((key === EXPGRP || key === MYCLAIMS)) { // to be removed
			if (showExp) {
				opt.hide = diceEnabled ? key === EXPGRP : key === MYCLAIMS;
			} else {
				opt.hide = true;
			}
		}
		if (opt.hide) {
			continue;
		}
		if (optionsData) {
			optData.options = [];
			optionsData.forEach((subopt) => {
				const subData = {};
				const subTcount = setOptData(subopt, result, searchUrl, searchQry, subData, tFeatureCount);
				tFeatureCount = subTcount;
				optData.active = optData.active || subData.active;
				optData.options.push(subData);
			});
		}
		const tcount = setOptData(opt, result, searchUrl, searchQry, optData, tFeatureCount);
		tFeatureCount = tcount;
		if (result.options.length < showOptions) {
			result.options.push(optData);
		} else {
			if (optData.active) {
				result.more.active = true;
			}
			result.more.options.push(optData);
		}
	}
	result.totalFeatures = tFeatureCount + 1;
	return result;
};

const setOptData = (opt, result, searchUrl, searchQry, data = {}, initCount = 0) => {
	let tFeatureCount = initCount;
	const urlOpts = urlMap[opt.key] || {};
	data.label = opt.text;
	data.value = opt.key;
	data.component = opt.component;
	if (opt.isNew) {
		data.tagConfig = {
			text: 'NEW'
		};
	}
	if (urlOpts.url) {
		data.url = urlOpts.url;
		data.trackVal = urlOpts.trackVal;
		data.isAdmin = urlOpts.isAdmin;
	}
	const feature = urlOpts.feature;
	if (feature) {
		tFeatureCount++;
		data.feature = feature;
		data.index = tFeatureCount;
	}
	const tagConfig = urlOpts.tagConfig;
	if (tagConfig) {
		data.tagConfig = tagConfig;
	}
	const param = urlOpts.param;
	data.param = param;
	if (opt.options) {
		return tFeatureCount;
	}
	data.active = searchUrl === urlOpts.url || searchUrl === (V2_APPLICATION_PATH + urlOpts.url);
	if (param && searchQry) {
		data.active = data.active && searchQry.includes(param);
	}
	if (urlOpts.links && false) {
		urlOpts.links.forEach((ln) => {
			const selOpt = options.filter((opt) => opt.key === ln.key)[0];
			ln.text = selOpt ? selOpt.text : ln.text;
		});
	}
	if (data.active) {
		result.links = urlOpts.links;
	}
	return tFeatureCount;
};
export const formatProfileData = (data = {}) => {
	let profileData = {};
	const empDetails = data.employee;
	const orgDetails = data.organization;
	const { requisition_enabled, centralBooker, manage_booking: isManageBookingEnabled } = data.features;
	if (empDetails) {
		profileData = setProfileData(empDetails);
	}
	if (!orgDetails.tneStatus) {
		orgDetails.tneStatus = CLAIMS_STATE_CONFIG.NOT_DONE;
	}
	return {
		profileData,
		features: {
			requistionEnabled: requisition_enabled,
			centralBooker,
			isManageBookingEnabled
		},
		orgDetails
	};
};

export const validateSessionCoachmark = (sessionName) => {
	const session = sessionStorage.getItem(sessionName);
	if (session) {
		return false;
	}
	sessionStorage.setItem(sessionName, true);
	return true;
};
