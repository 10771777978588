import styled from 'styled-components';

export const DialogHeader = styled.p`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  padding: 10px;
  overflow: hidden;
  color: #ffffff;
  background-image: linear-gradient(92deg, #f0772c, #f95776);
  @media (max-width: 700px) {
     font-size: 12px;
  };
`;

export const DialogContent = styled.p`
  min-height: 50px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  background-color: white;
  flex-direction: inherit;
  padding-top: 8%;
  width: 475px;
  min-height: 60px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
   @media (max-width: 700px) {
     font-size: 12px;
     width: 100%;
  };
`;
export const DialogFooter = styled.div`
  width: auto !important;
  display: table !important;
  margin: auto auto 26px auto;
  padding: 0px;
  overflow: hidden;
  background-color: transparent;
  flex-direction: row-reverse;
  color: #000;
`;
