import styled from 'styled-components';

import { FlexDiv, Container } from '../Styles';

export const BreadCrumb = styled(Container)`
    padding: 15px 0;
		margin-bottom: ${(props) => props.bottom_M ? props.bottom_M : 0}px;
    font-size: 14px;
`;

export const Wrapper = styled(FlexDiv)`
	border-bottom: 1px solid #dddddd;
`;
export const ListItem = styled.li`
    margin-right: 20px;
    position: relative;
    display: flex;
    align-items: center;
    &:after{
        width: 0;
        height: 0;
        content: "";
        position: absolute;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #9b9b9b;
        border-right: 5px solid transparent;
        top: 5px;
        right: -16px;
        z-index: 0;
    }
    &:before{
        width: 0;
        height: 0;
        content: "";
        position: absolute;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #efefef;
        border-right: 5px solid transparent;
        top: 5px;
        right: -14px;
        z-index: 1;
    }
    &:last-child:before,&:last-child:after{
        border:0 !important;
    }
    &:last-child a{
        pointer-events:none;
        color:#4a4a4a;
    }
    & a{
        color:#008cff;
        font-weight:700;
    }
`;
