import { PATHS } from '../AppConstants';
import { getCookie, getXrequestId } from '../UtilityComponents/Util';
import { getAxiosConfig } from './apiConfig';

const axiosConfigSSR = (config = {}) => {
	const { isServer = false } = config;

	let baseUrl = '';
	let timeout = 30000;
	let headers = {};
	if (isServer) {
		timeout = 500;
		const mmtAuth = config['mmt-auth'] || '';
		baseUrl = PATHS.SERVER_CCB_URL;
		headers = {
			'mmt-auth': mmtAuth,
			'Cookie': `mmt-auth: ${mmtAuth};`,
			'X-Request-ID': getXrequestId()
		};
	} else {
		baseUrl = PATHS.CCB_URL;
		headers = {
			'mmt-auth': getCookie('mmt-auth')
		};
	}
	return getAxiosConfig(
		baseUrl,
		true,
		headers,
		false,
		timeout
	);
};

export default axiosConfigSSR;
