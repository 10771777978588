import React, { Component } from 'react';
import { ListingHeader, HeadingText, SelctionList, SelctionItem, CheckAllWrapper, ChkBoxDownArrow } from './ListHeaderStyles';
import CheckBox from '../CheckBoxGrp/CheckBoxGrp';
import SortList from '../SortList/SortList';

class ListHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chkBoxTooltip: false
		};
		this.handlechkBoxTooltip = this.handlechkBoxTooltip.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handlechkBoxTooltip() {
		const { chkBoxTooltip } = this.state;
		this.setState({ chkBoxTooltip: !chkBoxTooltip });
	}
	toggleAll(state) {
		const { toggleAll } = this.props;
		this.setState({ chkBoxTooltip: false });
		toggleAll(state);
	}
	handleChange(e) {
		this.toggleAll(e.target.checked);
	}
	render() {
		const { headerConfig, noResult, isAllSelected, handleSorting, disableSort = false, showCheckBox = true, reverseSort = false } = this.props;
		const { chkBoxTooltip } = this.state;
		return (
			<ListingHeader isDisabled={noResult} {...this.props} justify='flex-start'>
				{showCheckBox && (
				<CheckAllWrapper>
					{showCheckBox && <CheckBox item='' isChecked={isAllSelected} handleChange={this.handleChange} />}
					{false && <ChkBoxDownArrow onClick={this.handlechkBoxTooltip} />}
					{false && chkBoxTooltip &&
					<SelctionList>
						<SelctionItem onClick={this.toggleAll.bind(this, true)}>All</SelctionItem>
						<SelctionItem onClick={this.toggleAll.bind(this, false)}>None</SelctionItem>
					</SelctionList>}
				</CheckAllWrapper>
				)}
				{headerConfig && headerConfig.list.map((item, i) => (
					<HeadingText key={i}>
						{item.isSort ? (
							<SortList
								isDisabled={disableSort}
								label={item.text}
								handleSort={handleSorting.bind(this, item)}
								reverse={reverseSort}
							/>) : item.text}
					</HeadingText>
				))}
			</ListingHeader>
		);
	}
}

export default ListHeader;
