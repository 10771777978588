import styled from 'styled-components';

const setBgColor = (theme) => {
	switch (theme) {
		case 'yellow':
			return `background: linear-gradient(90deg, #f3c852, #f89d19);`;
		case 'red':
			return `background: linear-gradient(90deg, #f5515f, #9f0404);`;
		case 'green':
			return `background: linear-gradient(90deg, #43e1a8, #28beb2);`;
		case 'blue':
			return `background: linear-gradient(90deg, #00d2ff, #3a7bd5);`;
		case 'black':
			return `background: linear-gradient(90deg, #000000, #000000);`;
		case 'reqgreen':
			return `background: linear-gradient(134.06deg, #1B9564 1.58%, #39D546 100%);`;
		case 'reqyellow':
			return `background: linear-gradient(315deg, #F8CD46 0%, #F4A900 100%);`;
		case 'reqgrey':
			return `background: #ADB5BD;`;
		case 'reqred':
			return `background: linear-gradient(355.52deg, #F5515F 3.32%, #9F0404 96.05%);`;
		case 'newlaunch':
			return `background: linear-gradient(87.29deg, #C86DD7 0%, #3023AE 100%);`;
		default:
			break;
	}
};

const StatusTag = styled.span`
    display: inline-flex;
    padding: ${(p) => p.padd ? p.padd : '2px 8px'};
    margin: ${(p) => p.margin ? p.margin : ''};
		vertical-align: ${(props) => props.valign ? props.valign : 'baseline'};
    align-items: ${(p) => p.align ? p.align : 'center'};;
    justify-content: center;
    font-size: ${(p) => p.f_size || '10px'};
    font-weight: 700;
    color: #fff;
    border-radius:${(p) => p.bdrRad ? p.bdrRad : '8px'};
    flex-shrink: 0;
    text-transform: uppercase;
    ${(p) => p.theme && `
        ${setBgColor(p.theme)};
    `}
		${(p) => p.gradient && `
			background: ${p.gradient};
	`}
`;

export default StatusTag;
