import { Body } from 'commonStyles';
import styled from 'styled-components';

export const LoginPage = styled(Body)`
    padding-top:40px;
    justify-contents:center;
    background:#371f30;
    text-align:left;
    height:100vh;
    flex-wrap:wrap;
    overflow:overlay;
    @media (max-width: 700px) {
    padding-top:20px;
    padding-bottom:20px;
    };
`;

export const BenifitList = styled.ul`
  list-style-type: none;
`;

export const BenifitListHeader = styled.div`
    font-family: 'lato-bold', Arial, Helvetica, sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 41px;
    margin-bottom: 8px;
    color: #fff;
    @media (max-width: 700px) {
    font-size: 24px;
    font-weight: 500;
    };
    `;

export const Benifit = styled.div`
    color: #f0772d;
    font-size: 20px;
    font-family: 'lato-bold', Arial, Helvetica, sans-serif;
    margin-bottom: 6px;
    margin-left: -16px;
    line-height: 25px;
    @media (max-width: 700px) {
    font-size: 16px;
    };`;
export const BenifitInfo = styled(BenifitList)`
    list-style-type: disc;
    padding-left: 0px;
    color: #fff;
    opacity: 0.7;
    font-size: 16px;
    line-height: 18px;
    font-family: 'lato-light', Arial, Helvetica, sans-serif;
    position: relative !important;
    @media (max-width: 700px) {
    font-size: 14px;
    };`;

export const SignInContainer = styled.div`
    width: 100%;
    height:${(props) => props.height ? props.height : 480}px;
    padding: 44px 40px 20px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #fff;
    color: #000;
    @media (max-width: 700px) {
     height:480px;
    };
    `;
export const InputLogin = styled.input`
    width:100%;
    border: ${(props) => props.border ? props.border : 'solid 1px #e3e3e3'};
    background-color: rgb(250, 255, 189) !important;
    border-radius: 4px;
    height: 48px;
    text-align: left;
    height: 40px;
    padding: 5px 12px;
    outline: none;
    font-size: 14px;
    line-height: 15px;
    box-shadow: none;
    placeholder:${(props) => props.placeholder};
    type:${(props) => props.type};
     @media (max-width: 700px) {
      font-size: 12px;
      height: 36px;
      line-height: 12px;
    };
`;
