import { INVOICE_CONSTANTS, APPLICATION_CONSTANTS } from 'AppConstants';
import {
	formatAmount,
	evaluateString
} from 'UtilityComponents/Util';
import SubComp from 'common/ReminderFrequency/ReminderFrequency';
import { getConsulData } from 'UtilityComponents/index';

const consulData = getConsulData('config').INVOICE || {};

export const dateConfig = {};

export const WEEK_DAYS = getConsulData('config')['FREQUENCY']['WEEK_DAYS'];

export const filters = evaluateString(consulData.filters)(INVOICE_CONSTANTS, APPLICATION_CONSTANTS);

export const bundleObject = evaluateString(consulData.bundleObject)(INVOICE_CONSTANTS);

export const reminderModalDtls = evaluateString(consulData.reminderModalDtls)(formatAmount, SubComp);

export const { HELP_TEXT, VIEW_LESS, VIEW_ALL, pageLinks, listHeader, InvoiceAirlines, searchInput, textsConfig } = consulData;

export const bundleData = {
	count: 0,
	bundleCount: 0,
	bundleData: {}
};
