export const errorMessagesObj = {
	InvalidFileExtension: 'Please upload PDF,JPG and PNG file.',
	duplicateFile: 'This File is already Uploaded.',
	errorUpload: 'Not able to upload file',
	maxSizeError: 'Max file size is 50KB'
};

export const possibleUploadStates = {
	empty: 'empty',
	loading: 'loading',
	done: 'done',
	failed: 'failed'
};

export const fileUploadConfig = {
	initState: {
		emptyUploaderObject: {
			uploadStatus: possibleUploadStates.empty,
			fileName: '',
			fileProgress: 0,
			fileData: '',
			fileExtension: '',
			fileLastModified: ''
		}
	},
	errorInitState: {
		emptyUploaderObject: {
			uploadStatus: possibleUploadStates.empty,
			fileName: '',
			fileProgress: 0,
			fileData: '',
			fileExtension: '',
			fileLastModified: '',
			error: true
		}
	}
};

export const allowedCSVExtensions = {
	'csv': 'csv',
	'vnd.ms-excel': 'vnd.ms-excel',
	'png': 'png'
};
