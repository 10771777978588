import React, { Component } from 'react';
import { Radiolabel, RadioCustom, RadioSpanText, RadioSubText, Text } from './Style';
import IconHover from 'common/iconHover/iconHover';

class Radio extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const disabledStyle = this.props.disabled === 'disabled' ? 'disabledStyle' : '';
		const isChecked = this.props.isChecked;
		const iconToolTipData = this.props.iconToolTipData;
		const infoIcon = this.props.infoIcon;
		const { changeHandler, customStyle } = this.props;
		return (
			<Radiolabel className={`${disabledStyle} ${customStyle}`}>
				<input
					disabled={disabledStyle}
					checked={isChecked} type='radio' name={this.props.name}
					onChange={changeHandler ? changeHandler : null} value={this.props.value}
				/>
				<RadioCustom className={`pseudoradio ${disabledStyle} ${customStyle}`}>
					<span className='inner' />
				</RadioCustom>
				<Text className={customStyle}>
					<RadioSpanText>
						{this.props.RadioText || this.props.label}
					</RadioSpanText>
					{this.props.infoText &&
						<RadioSubText className={customStyle}>
							({this.props.infoText})
						</RadioSubText>}
				</Text>
				{infoIcon && <IconHover input={{ iconToolTipData }} />}
			</Radiolabel>
		);
	}
}

export default Radio;
