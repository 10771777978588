import React from 'react';

import { GROUP_CONSTANTS, APPLICATION_CONSTANTS } from 'AppConstants';
import { ASSET_PATH } from 'assetsPath';
import { GroupIcons, UnorderedList, ListItem } from './Style';
import { getXrequestId } from 'UtilityComponents/Util';

const OnboardImage = `${ASSET_PATH.IMAGES}groupsOnboard.png`;
const xreqId = getXrequestId();

export const newGroupContent = {
	heading1: 'Start creating your groups.',
	heading2: 'Groups helps to set & manage your employee travel policies.',
	subHeading: 'Please note* - An employee cannot be a member of more than one group,',
	newGroupBtn: 'Create New Group',
	bulkImportBtn: 'Bulk Upload / Edit (CSV) '
};

export const reviewBulkUlpoadContent = {
	heading: 'Groups With Similar Name, Confirmation Required !',
	subHeading: `We found different goups with similar names in the uploaded file, 
								kindly review & confirm to add to the below list.`,
	btnText: 'Review & Confirm'
};

export const uploadStatusToast = {
	success: {
		heading: 'Uploaded group details',
		subHeading: 'The new groups are successfully uploaded and added to your list.'
	},
	failure: {
		heading: 'Upload Failed !',
		subHeading: `We couldn’t upload the complete group details due to some technical errors, Kindly try 
									re-uploading the file after some time. ${xreqId ? ` (error:${xreqId})` : ''}`
	}
};

export const uploadToastStyle = {
	width: '950px',
	top: '155px',
	left: '370px',
	height: '107px',
	bottom: 'auto',
	boxShadow: 'none',
	padding: '0px 50px 15px 29px',
	headSize: '24px',
	subHSize: '12px',
	color: '#4a4a4a'
};

export const newGrpBtnStyle = {
	btnName: 'Primary',
	height: '48px',
	padding: '12px 18px'
};

export const PAGE_HEAD = 'Groups';

export const newGroupFields = {
	[GROUP_CONSTANTS.GROUP_NAME]: {
		value: '',
		name: GROUP_CONSTANTS.GROUP_NAME,
		label: '',
		placeHolder: 'Enter group Name',
		type: 'text',
		validation: {
			required: {
				msg: 'Required!'
			}
		},
		errorOnBlur: true,
		isMandatory: true,
		outLabel: true,
		valueKey: 'groupName',
		trackVal: 'Group_create_'
	},
	[GROUP_CONSTANTS.GROUP_DESC]: {
		value: '',
		name: GROUP_CONSTANTS.GROUP_DESC,
		label: '',
		placeHolder: 'Enter group Description',
		type: 'textArea',
		showLimit: true,
		maxLen: 100,
		rows: 3,
		validation: {
			required: {
				msg: 'Required!'
			}
		},
		errorOnBlur: true,
		isMandatory: true,
		outLabel: true,
		valueKey: 'desc',
		trackVal: 'Group_create_'
	},
	[GROUP_CONSTANTS.ENABLE_2FA]: {
		showKey: 'mfaVisibility',
		value: '',
		name: GROUP_CONSTANTS.ENABLE_2FA,
		label: 'Enable 2FA',
		placeHolder: '',
		type: 'checkbox',
		showLimit: true,
		maxLen: 100,
		rows: 3,
		options: [
			{ value: 'Enable 2FA', label: 'Enable 2FA' }
		],
		validation: {
			required: {
				msg: 'Required!'
			}
		},
		errorOnBlur: true,
		outLabel: true,
		valueKey: GROUP_CONSTANTS.ENABLE_2FA,
		trackVal: 'Group_create_'
	}
};

export const GROUP_MODAL_DATA = {
	[GROUP_CONSTANTS.CREATE_GRP_MODAL_TYPE.CREATE_GRP]: {
		title: 'New Group Details',
		customClass: 'grpAddPop',
		subTitle: '',
		mobileTitle: 'New Group Details',
		buttontext: 'Create New Group',
		showMembersList: true,
		showInput: true,
		showSuccessModal: true,
		successMsg: ''
	},
	[GROUP_CONSTANTS.CREATE_GRP_MODAL_TYPE.ADD_MEMBER]: {
		title: 'Add Members',
		customClass: 'grpAddPop',
		subTitle: 'Only mybiz employees registered can be added to groups.',
		mobileTitle: 'Add Members',
		buttontext: 'Save',
		showMembersList: true,
		showInput: false,
		showSuccessModal: false,
		successMsg: 'Members Added Successfully!'
	},
	[GROUP_CONSTANTS.CREATE_GRP_MODAL_TYPE.EDIT_GRP]: {
		title: 'Edit Group Details',
		customClass: 'grpAddPop',
		subTitle: '',
		mobileTitle: 'Edit Group Details',
		buttontext: 'Save',
		showMembersList: false,
		showInput: true,
		showSuccessModal: false,
		successMsg: 'Group Updated Successfully!'
	}
};

export const employeeSearchField = {
	name: 'employee',
	value: '',
	placeHolder: 'Search Employee Name or email id to add',
	validation: {},
	options: [],
	type: 'select',
	isMandatory: true,
	outLabel: true,
	errorOnBlur: true,
	isSearchable: true,
	customClass: 'mgr-drop',
	labelKey: 'value',
	showSearchIcon: true,
	selectStyles: {
		valueContainer: (provided, state)=>( {
			...provided,
			gridTemplateColumns: ' min-content'
		}),
		placeholder: (provided, state)=>( {
			...provided,
			gridArea: '1/2/1/2'
		}),
		input: (provided, state)=>( {
			...provided,
			gridArea: '1/2/1/2'
		})
	}
};

export const fileUploaderConfig = {
	title: 'Click to select or Drag & drop CSV file',
	errorMsg: 'Please upload CSV file.',
	subTitle: 'Download this CSV template, fill the group members details and upload it in this section. Do not upload more than 3000 records in a single go',
	loaderMsg: 'Uploading CSV File ...'
};

export const GROUP_LIST_HEADER = {
	list: [
		{
			text: 'GROUP NAME',
			isSort: true,
			key: 'groupName'
		},
		{
			text: 'MEMBERS',
			isSort: true,
			key: 'empCount'
		},
		{
			text: 'ACTION'
		},
		{
			text: ''
		}
	]
};

export const coachmarkTextConf = {
	org: {
		heading: 'Default group for your Organisation',
		subHeading: (
			<UnorderedList>
				<ListItem>All employees who are not assigned any group by default join this group</ListItem>
				<ListItem>Default policy will be applied to this group</ListItem>
			</UnorderedList>
		)
	},
	upload: {
		heading: 'Create Groups Here',
		subHeading: 'You can select employees from the list of existing employees and assign them a group. Or, you can add multiple employees with the ‘Bulk Upload’ option.'
	},
	help: {
		heading: 'Refer to Help Section',
		subHeading: 'Revisit the benefits and onboarding process of Groups from the Help section here.'
	}
};

export const conflictingModalData = {
	title: 'Similar Groups Name Found',
	customClass: 'cnflctngModal',
	subTitle: 'Tell us what to do with these groups',
	mobileTitle: 'Similar Groups Name Found',
	buttontext: 'Continue'
};

export const bulkUploadModal = {
	modalData: {
		title: 'Bulk Upload Group Members',
		subTitle: 'Please download the CSV template file, add the required group names against corresponding employees & upload it below. ',
		customClass: 'csvReimburse'
	}
};

export const BULK_STATUS_CONFIG = {
	[APPLICATION_CONSTANTS.SUCCESS]: {
		icon: 'yellowTick',
		button: false,
		head: 'Uploaded group details',
		desc: 'Group Details have been uploaded and will be processed in a while. The status will be shared with you on Email in next 15 mins.',
		cta: 'Ok Got It',
		bdrClr: '#cf8100'
	},
	[APPLICATION_CONSTANTS.PARTIAL]: {
		icon: 'yellowTick',
		button: 'Download CSV',
		head: 'Partial Success !',
		cta: 'close',
		desc: 'We could only upload few of the groups successfully.',
		desc2: '<b>Please download the CSV file</b> containing rest of the groups list which where unsuccessull. Kindly correct the details and reupload to add them.',
		bdrClr: '#cf8100'
	},
	[APPLICATION_CONSTANTS.FAILED]: {
		icon: 'redCross',
		button: false,
		head: 'Upload Failed',
		cta: 'Ok Got It',
		desc: `We couldn’t upload the complete group details due to some technical errors, Kindly try re-uploading the file after some time. ${xreqId ? ` (error:${xreqId})` : ''}`,
		bdrClr: '#e02020'
	},
	[APPLICATION_CONSTANTS.PENDING]: {
		icon: '',
		button: false,
		head: 'Uploading group details...',
		cta: 'Cancel Upload',
		desc: 'This may take a while. We will notify you when the upload is complete. Feel free to carry on with your usual office work.',
		bdrClr: '#ff674b'
	}
};
export const grpOnboardingModalConfig = {
	heading: 'Create a Group Now!',
	desc: 'Create a group to manage travel policies for a set of employees with different designations or bands.',
	cta: 'LET\'S GET STARTED',
	img: OnboardImage,
	imgAlt: 'Onboarding Employee',
	features: [
		{
			title: 'Set Policy',
			desc: 'Define policies and wallet usage on the basis of grade, department and designation.',
			Icon: <GroupIcons icon='circleIcon' />
		},
		{
			title: 'Manage Expenditure',
			desc: 'Keep a track of all travel spends and accrued savings at group level.',
			Icon: <GroupIcons icon='circleIcon' />
		},
		{
			title: 'Define Approval Levels',
			desc: 'Set different approval managers for different groups, if needed.',
			Icon: <GroupIcons icon='circleIcon' />
		}
	]
};

export const omnitureConfig = {
	onboarding1: {
		prop: 'Groups_onboarding__1/2_next_clicked'
	},
	onboarding2: {
		prop: 'Groups_onboarding__2/2_'
	},
	createGroup: {
		prop: 'Groups_create_clicked'
	},
	bulkUpload: {
		prop: 'Groups_Bulk_clicked'
	},
	deleteGroup: {
		prop: 'Groups_Delete_clicked'
	},
	groupListCheckBox: {
		prop: 'Groups_listview _checkbox_ '
	},
	groupListArrow: {
		prop: 'Groups_listview_open_clicked_'
	},
	searchGroup: {
		prop: '_Search_type '
	},
	editGrpDetails: {
		prop: 'Groups_edit_groups_details_clicked_type '
	},
	editGrpPolicy: {
		prop: 'Groups_edit_View_Policy_clicked_type '
	},
	addMember: {
		prop: 'Groups_addMember clicked_type '
	}
};

export const GROUP_CREATE_SUCCESS_MODAL = {
	NEW: {
		heading: 'New Group created !',
		subHeading: 'Add policy for this group',
		btnTxt1: 'Define new travel policy',
		btnSubTxt1: 'Define travel budgets, approvals, trip tags, privileges & more',
		btnTxt2: 'Define new expense policy',
		btnSubTxt2: 'Define expense budgets, approvals, trip tags, privileges & more'
	},
	OLD: {
		heading: 'Group Policy !',
		subHeading: 'Policy for this group',
		btnTxt1: 'View Travel policy',
		btnSubTxt1: 'Travel budgets, approvals, trip tags, privileges & more',
		btnTxt2: 'View Expense policy',
		btnSubTxt2: 'Expense budgets, approvals, trip tags, privileges & more'
	}
};

export const MSG_DATA = {
	'dwlGrp': {
		success: 'Group data downloaded successfully !',
		error: `We couldn't download group due to technical reasons. Please try again later`,
		zeroEmpMsg: 'Selected groups has zero employees in it, Please change your selection and try again'
	},
	'deleteGrp': {
		success: 'Groups deleted successfully !',
		error: `We couldn't delete group due to technical reasons. Please try again later`
	},
	'deleteGrpModal': {
		title: 'Delete Group ?',
		subTitle: 'Are you sure you want to delete group(s) from myBiz? The group would no longer available  once deleted.'
	}
};

export const ADMIN_GRP_DLT_MSG = 'Admin Group cannot be deleted.';

export const statusModalData = {
	img: ASSET_PATH.IMAGES + '/emailImg.png',
	title: `Bulk Download Request Detected`,
	titleAlign: 'center',
	customClass: 'empstatuspop',
	imgStyle: 'dwlSuccs'
};

export const HELP_TEXT = {
	why: 'Why should I use Groups?',
	how: 'How to use Groups?'
};
