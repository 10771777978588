import { ORGANIZATION_DETAILS_CONSTANT } from "AppConstants";

export const apiKeyFields = [
	{
		options: [],
		outLabel: true,
		label: 'Select Expense Partner',
		placeHolder: 'Select Expense Partner',
		name: ORGANIZATION_DETAILS_CONSTANT.CLIENT_KEY
	},
	{

		name: ORGANIZATION_DETAILS_CONSTANT.EXP_CLIENT_ID,
		outLabel: true,
		isDisabled: true,
		label: 'Expense Client Id',
		type: 'text'
	}, {

		outLabel: true,
		name: ORGANIZATION_DETAILS_CONSTANT.EXT_ORG_ID,
		isDisabled: true,
		label: 'External Org Id',
		type: 'text'
	}
];