import { PATHS } from 'AppConstants';
import React, { Component } from 'react';
import { AccntText, SignUpBtn } from './LoginStyledComponents';

export default class LoginPageRightMenu extends Component {
	render() {
		return (
			<div>
				<AccntText>
					Don’t have account?
				</AccntText>
				<SignUpBtn href={PATHS.LANDING_LOGIN_PAGE}>
					SIGN UP
				</SignUpBtn>
			</div>
		);
	}
}
