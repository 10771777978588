export const dropDownConfig = {
	placeholder: (provided, state) =>({
		...provided,
		position: 'absolute',
		top: state.hasValue || state.isFocused || state.selectProps.inputValue ? '0px' : '50%',
		transform: state.hasValue || state.isFocused || state.selectProps.inputValue ? 'none' : 'translateY(-50%)',
		fontSize: (state.hasValue || state.isFocused || state.selectProps.inputValue) ? '11px' : '14px',
		fontWeight: 700,
		color: (state.hasValue || state.selectProps.inputValue) ? '#9b9b9b' : (state.isFocused && !state.hasValue) ? '#ff6544' : '#4a4a4a',
		lineHeight: 'inherit',
		fontFamily: 'lato',
		textTransform: 'uppercase',
		transition: 'top 0.2s, font-size 0.2s'
	}),
	control: (provided, state) => ({
		...provided,
		padding: '4px 10px 2px 8px',
		backgroundColor: state.isFocused ? '#fff9f7' : '#f7f7f7'
	}),
	container: (provided, state) => ({
		...provided,
		fontSize: '14px',
		fontWeight: 700,
		color: '#4a4a4a',
		lineHeight: 'inherit',
		fontFamily: 'lato'
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 4
	}),
	menuList: (provided) => ({
		...provided,
		maxHeight: '200px'
	}),
	input: (provided) => ({
		...provided,
		color: '#000',
		fontWeight: '900',
		padding: '10px 10px 9px 5px'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#000',
		fontWeight: '900',
		padding: '4px 0'
	}),
	noOptionsTxt: 'No options found'
};
