import styled, { css } from 'styled-components';
import { SpriteIcon, GenericSpan, FlexDiv } from './Styles';

export const SectionTitle = styled.h3 `
	color:#000;
	font-size:24px;
	font-weight:700;
	margin-bottom:20px;
`;

export const SubText = styled.span `
	font-size: 14px;
	color: #9b9b9b;
	margin-left: 5px;
	font-weight: 400;
`;

export const TopSection = styled.div `
	padding:0 20px;
	margin-bottom:30px;
`;

export const PaginationWrapper = styled.div `
	display:flex;
	justify-content:space-between
`;

export const ListWrapper = styled.div`
	border: 1px solid ${(props) => props.bdrColor || '#ddd'}; 
	border-radius: ${(props) => props.bdrRad || '5px'}; 
	border-width: ${(props) => props.bdrWidth || '1px'};
	box-shadow: ${(p)=> p.shadow || 'none'};
	background: ${(p) => p.background || '#fff'};
	position: relative;
	${(props) => props.active && css`
		position: relative;
		z-index: 2`
}
`;
export const RowWrap = styled(FlexDiv)`
	&>span {
		${(props) => {
		switch (props.type) {
			case 'invoice':
				return css`
					width: 90px;
					&:first-of-type {
						width: 65px;
					}
					&:nth-of-type(2),&:last-of-type {
						width: 160px;
					}
			`;
			case 'wallet':
				return css`
					width: 90px;
					min-width: 90px;
					&:first-of-type {
						min-width: 65px;
						width: 65px;
					}
					&:nth-of-type(2), &:nth-of-type(3) {
						width: 160px;
						min-width: 160px;
					}
					&:last-of-type{
						width: 126px;
						min-width: 126px;
					}
			`;
			case 'reimburse':
				return css`
					width: 160px;
					&:first-of-type {
						min-width: ${(props) => props.firstColWidth ? props.firstColWidth : '30px'};
						width: ${(props) => props.firstColWidth ? props.firstColWidth : '30px'};
					}
					&:nth-of-type(2) {
						width: 220px;
						min-width: 220px;
					}
					&:nth-of-type(3) {
						width: 310px;
						min-width: 220px;
					}
					&:nth-of-type(4) {
						min-width: 125px;
						width: 165px;
					}
					&:nth-of-type(5) {
						min-width: 85px;
						width: 120px;
					}
					&:last-of-type{
						width: auto;
						height: 20px;
					}
			`;
			case 'reimburse_done':
				return css`
					width: 160px;
					&:first-of-type {
						min-width: 30px;
						width: 30px
					}
					&:nth-of-type(2) {
						width: 200px;
						min-width: 200px;
					}
					&:nth-of-type(3) {
						min-width: 250px;
						width: 250px;
					}
					&:nth-of-type(4) {
						max-width: 200px;
					}
					&:last-of-type{
						max-width: 150px;
					}
			`;
			case 'expense':
				return css`
					width: 90px;
					&:first-of-type {
						min-width: ${(props) => props.firstColWidth};
						width: ${(props) => props.firstColWidth};
					}
					&:nth-of-type(2), &:nth-of-type(3){
						min-width: 125px;
						width: 125px;
					}
					&:nth-of-type(4) {
						min-width: 90px;
						width: 93px;
					}
					&:last-of-type{
						width: 10px;
					}
			`;
			case 'otherCategory': {
				return css`
					width: 85px;
					&:first-of-type {
						width: 150px;
					}
			`;
			}
			case 'myBizCategory': {
				return css`
					width: 100px;
					&:first-of-type {
						width: 180px;
					}
					&:nth-of-type(2){
						width: 400px;
					}
			`;
			}
			case 'employee':
				return css`
					width: 160px;
					&:first-of-type {
						min-width: 40px;
						width: 40px;
					}
					&:nth-of-type(2) {
						width: 250px;
						min-width: 250px;
					}
					&:nth-of-type(3) {
						width: 180px;
						min-width: 180px;
					}
					&:nth-of-type(4) {
						min-width: 110px;
						width: 110px;
					}
					&:nth-of-type(5) {
						min-width: 225px;
						width: 225px;
					}
					&:last-of-type {
						width: auto;
						height: 20px;
					}
			`;
			case 'groups':
				return css`
					width: 180px;
					&:first-of-type {
						min-width: ${(props) => props.firstColWidth};
						width: ${(props) => props.firstColWidth};
					}
					&:nth-of-type(2) {
						width: 405px;
						min-width: 405px;
					}
					&:last-of-type{
						width: 10px;
					}
			`;
			case 'employeenogrp':
				return css`
						width: 235px;
						min-width: 235px;
					&:first-of-type {
						min-width: 40px;
						width: 40px;
					}
					&:nth-of-type(2) {
						width: 275px;
						min-width: 275px;
					}
					&:last-of-type {
						max-width: 30px;
						min-width: auto;
						width: 30px;
					}
				`;
			case 'cabpolicy': {
				return css`
					flex-grow: 1;
					flex-shrink: 1;
					width: 20%;
					border-right: 1px solid #dddddd;
					align-items: center;
					height: 100%;
					max-width: 120px;
					margin: 0;
					padding-left: 15px;
					&:first-of-type{
						padding-left: 0;
					}
					&:last-of-type{
						width: auto;
						min-width: auto;
						border-right: none;
					}
					&.ellipses {
						max-width: 120px;
					}
			`;
			}
			case 'hotelpolicy': {
				return css`
					flex-grow: 1;
					flex-shrink: 1;
					width: 20%;
					align-items: center;
					height: 100%;
					max-width: 100px;
					margin: 0;
					padding-left: 15px;
					&:first-of-type{
						padding-left: 0;
						max-width: 35px;
						font-weight: 700;
					}
					&:nth-of-type(2),&:nth-of-type(3) {
						width: 135px;
						max-width: 135px;
					}
					&:nth-of-type(3) {
						width: 140px;
						max-width: 140px;
						color: #F17531;
					}
					&:last-of-type{
						width: auto;
						min-width: auto;
						border-right: none;
					}
			`;
			}
			case 'reportsSummary':
				return css`
					width: 135px;
					min-width: 135px;
					&:first-of-type {
						min-width: 30px;
						width: 30px;
					}
					&:nth-of-type(2) {
						width: 180px;
						min-width: 180px;
					}
					&:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5) {
						
					}
					&:nth-last-of-type(2) {
						flex-grow: 1;
					}
					&:last-of-type{
						width: 40px;
						min-width: 40px;
						margin-right: 0;
					}
			`;
		}
	}
}
    }
`;

export const RowWrapper = styled.div`
	max-height: ${(props) => props.pageCount ? `${(props.pageCount * (props.rowHt || 50))}px` : (props.pageCount === 0 ? 'none' : '100px') };
	overflow-y: ${(props) => props.pageCount === 0 ? '' : 'auto'};
	${(props) => props.active && css`
		position: relative;`}
`;
export const NewTag = styled.span`
	border-radius:4px;
	background:#d6f6f3;
	color:#1a7971;
	font-size:11px;
	font-weight:600;
	padding:2px 4px;
	margin-left:5px;
`;
export const CheckAllWrapper = styled.span`
	width: auto;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	p {
		align-items: flex-start;
		margin: 0;
	}
`;
export const IconWrapper = styled.span`
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
`;
export const TypeIcon = styled(SpriteIcon)`
	width: 19px;
	height: 14px;
	background-position: ${(props) => props.type == 'flight' ?
		'-21px -45px' : (props.type == 'hotel' ?
			'1px -43px' : (props.type == 'cab' ?
				'1px -84px' : (props.type == 'cab' ?
					'-20px -84px' : '-20px -8px' )))
}
`;
export const InfoList = styled(GenericSpan)`
	margin-right:20px;
	&.ellipses,.ellipses {
		max-width: 100%;
	}
	&.groupInfoCell {
		height: 100%;
		padding-top: 10px;
	}
`;
