import styled from 'styled-components';

export const FeatureButton = styled.a`
    background:#ffffff;
    border-radius:4px;
    color:#000;
    padding:8px 10px;
    font-size:12px;
    margin-left:10px;
    font-weight:700;
    &.previousBtn{
    background:transparent;
    border:1px solid #fff;
    color:#fff;
    }
`;

export const FeatureInfoWrapper = styled.div`
    position: absolute;
    left: ${(props) => props.infoLeft};
    top: ${(props) => props.infoTop};
    &+* {
        position: relative;
    }
`;
export const FeatureBackground = styled.div`
    background: rgba(0,0,0,.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
`;

export const FeatureActiveBg = styled.div`
    width: calc(100% + ${(props) => props.left_P ? 2 * (props.left_P ) : '20'}px);
    height: calc(100% + ${(props) => props.top_P ? 2 * (props.top_P) : '30'}px);
    position: absolute;
    top: ${(props) => props.top_P ? props.top_P : '-15'}px;
    left: ${(props) => props.left_P ? props.top_P : '-10'}px;
    background: ${(props) => props.featureBg || '#fff'};
    z-index: 0;
    border-radius: 5px;
`;
export const SubHeading = styled.span`
    font-size: 16px;
    font-weight: bold;
`;

export const OnboardingTooltip = styled.div`
  color: #fff;
  position: absolute;
  border-radius: 5px;
  padding: 10px 15px;
  width: ${(props) => props.$width ? props.$width : '300px'};
  z-index: 11;
	top:  ${(props) => props.posTop ? props.posTop : 'auto'};
	bottom:  ${(props) => props.posBtm ? props.posBtm : 'auto'};
	left:  ${(props) => props.posLeft ? props.posLeft : 'auto'};
	right:  ${(props) => props.posLeft ? props.posRight : 'auto'};
	transform:  ${(props) => props.transform ? props.transform : 'none'};
  .padgVertical {
    padding: 5px 0;
  }
  .guideArw {
    width: 34px;
    height: 45px;
  }
`;

export const ArrowWrapper = styled.div`
	position: absolute;
	transform: rotate(${(props) => props.rotateDeg ? props.rotateDeg : 0}deg);
	left: ${(props) => props.arrowLeft ? props.arrowLeft : 'auto'};
	right: ${((props) => props.arrowRight ? props.arrowRight : 'auto')};
	top:  ${((props) => props.arrowTop ? props.arrowTop : 'auto')};
	bottom:  ${((props) => props.arrowBtm ? props.arrowBtm : 'auto')};
	& .guideArw {
		margin-top: 10px;
	}
`;
