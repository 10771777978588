import React from 'react';
import { connect } from 'react-redux';

import {
	ProfileOverlay, RightMenuList, ProfileListMenuItemLink,
	SwitchLinkCorporate, SwitchLinkPersonal, SwitchProfile, SwitchSection, ProfileListMenuItem
} from './ProfileMenuStyles';
import { HCS_CONST } from '../../../redux/actionConstants';
import { logoutFromHcs } from '../../../redux/actions/hcsActionDispatcher';
import CorpModalWithHeader from '../../../UtilityComponents/CorpModalWithHeader';
import { logOmniClick } from '../../../logging/omnitureService';
import { isDeviceMobile } from '../../../UtilityComponents/Util';
import { PATHS } from '../../../AppConstants';

class ProfileMenu extends React.Component {
	constructor(props) {
		super(props);
		this.resetState(null);
	}

	logout = (source) => {
		if (source == HCS_CONST.LOGOUT_CLICKED) {
			let email = '';
			if (this.props.payload && this.props.payload.userData && this.props.payload.userData.primaryEmailId) {
				email = this.props.payload.userData.primaryEmailId;
			}
			let clickEvent = 'MyBiz_Header_Menu_logout';
			logOmniClick(clickEvent, {
				prop54: clickEvent + '|v2|corporate',
				eVar34: email
			});
		}
		this.props.dispatch(logoutFromHcs(source));
	}

	showUserConfirmation = (events) => {
		this.props.dispatch({
			type: HCS_CONST.ASK_FOR_USER_CONFIRMATION,
			payload: {
				state: HCS_CONST.ASK_FOR_USER_CONFIRMATION,
				next_action: HCS_CONST.SHOW_SUCCESS_POPUP,
				popupMsg: HCS_CONST.MSG_TAKE_USER_CONFIRMATION,
				userData: this.props.payload ? this.props.payload.userData : {}
			}
		});
	}

	moveToPersonal = (events) => {
		let nextLocation = PATHS.MMT_MYBIZ;
		if (this.props.payload && this.props.payload.source == HCS_CONST.LOGOUT_CLICKED) {
			nextLocation = PATHS.LANDING_LOGIN_PAGE;
		}
		if (isDeviceMobile()) {
			if (window) {
				window.location = PATHS.MMT_APP_LINK;
			}
		} else if (window) {
			window.location = nextLocation;
		}
	}

	moveToCorporateLogin = () => {
		if (window) window.location = PATHS.MYBIZ_URL;
	}

	resetState = (event) => {
		this.props.dispatch({
			type: HCS_CONST.RESET,
			payload: { userData: this.props.payload ? this.props.payload.userData : {} }
		});
	}

	proceedToLink = (link, clickEvent, isMyBizLink) => {
		this.sendOmnitureEvent(clickEvent);
		if (!isMyBizLink && isDeviceMobile()) {
			if (window) {
				window.location = PATHS.MMT_APP_LINK;
			}
		} else if (window) {
			if (isMyBizLink) {
				let win = window.open(link, '_blank');
				win.focus();
			} else {
				window.location = link;
			}
		}
	}

	sendOmnitureEvent = (clickEventName) => {
		let email = '';
		if (this.props.payload && this.props.payload.userData && this.props.payload.userData.primaryEmailId) {
			email = this.props.payload.userData.primaryEmailId;
		}
		logOmniClick(clickEventName, {
			prop54: clickEventName + '|v2|corporate',
			eVar34: email
		});
	}

	render() {
		let showPopup = this.props.payload && this.props.payload.next_action == HCS_CONST.SHOW_SUCCESS_POPUP;
		let popupMsg = this.props.payload ? this.props.payload.popupMsg : 'Continue';
		let userstate = this.props.payload ? this.props.payload.state : null;
		let userName = (this.props.payload && this.props.payload.userData &&
			this.props.payload.userData.personalDetails &&
			this.props.payload.userData.personalDetails.name && this.props.payload.userData.personalDetails.name.firstName) ? this.props.payload.userData.personalDetails.name.firstName : '';
		let positiveButtonCB = this.logout;
		if (userstate == HCS_CONST.LOGOUT_SUCCESS) {
			this.resetState(null);
			this.moveToPersonal(null);
			showPopup = false;
		}
		let negativeButton = null;
		if (this.props.payload && this.props.payload.state == HCS_CONST.ASK_FOR_USER_CONFIRMATION) {
			negativeButton = 'Not now';
			positiveButtonCB = () => {
				this.logout(HCS_CONST.PERSONAL_CLICKED);
			};
		}

		return (
			<>
				{showPopup &&
					<CorpModalWithHeader
						show={showPopup + ''}
						open={showPopup}
						title='Are you sure?'
						content={popupMsg}
						positiveButtonCB={positiveButtonCB}
						positiveButton='Logout'
						negativeButton={negativeButton}
						negativeButtonCB={this.resetState}
					/>}
				{this.getProfileMenu(userName)}
			</>
		);
	}

	getFirstLevelMobileMenu = () => (
		<div>
			<p className='font14'>You are in </p>
			<SwitchProfile>
				<SwitchLinkPersonal onClick={this.showUserConfirmation}>Personal</SwitchLinkPersonal>
				<SwitchLinkCorporate>myBiz</SwitchLinkCorporate>
			</SwitchProfile>
			<ProfileListMenuItem>
				<ProfileListMenuItemLink
					onClick={() => {
						this.setState({ secondRightMenu: true });
						this.sendOmnitureEvent('MyBiz_Header_Flights');
					}}>
					My Profile
				</ProfileListMenuItemLink>
			</ProfileListMenuItem>
			{this.getProfileMenuListItem(PATHS.FLIGHTS_PAGE_URL, 'MyBiz_Header_Flights', false, 'Flights')}
			{this.getProfileMenuListItem(PATHS.HOTEL_PAGE_URL, 'MyBiz_Header_Hotels', false, 'Hotels')}
			{this.getProfileMenuListItem(PATHS.BUS_TICKETS_URL, 'MyBiz_Header_Bus', false, 'Bus')}
			{this.getProfileMenuListItem(PATHS.VENUES_MICE, 'MyBiz_Header_Venues', true, 'Venues')}
			{this.getProfileMenuListItem(PATHS.MMT_GIFT_CARD_URL, 'MyBiz_Header_GiftCards', false, 'Gift cards')}
			{this.getProfileMenuListItem(PATHS.MMT_GIFT_CARD_URL, 'MyBiz_Header_Call_Section', false, 'Call support')}
		</div>
	)

	getUserProfileMenu = () => (
		<div>
			{!(this.state && this.state.secondRightMenu) &&
			<div>
				<p className='font14'>You are in </p>
				<SwitchProfile>
					<SwitchLinkPersonal onClick={this.showUserConfirmation}>Personal</SwitchLinkPersonal>
					<SwitchLinkCorporate>myBiz</SwitchLinkCorporate>
				</SwitchProfile>
			</div>}
			{this.getProfileMenuListItem(PATHS.MMT_PROFILE, 'MyBiz_Header_Account' + '_My_Account', false, 'My Account')}
			{this.getProfileMenuListItem(PATHS.BOOKING_SUMMARY_URL, 'MyBiz_Header_Account' + '_My_Trips', false, 'My Trips')}
			{this.getProfileMenuListItem(PATHS.BOOKING_SUMMARY_URL, 'MyBiz_Header_Account' + '_Print_Tickets', false, ' Print E-ticket')}
			{this.getProfileMenuListItem(PATHS.BOOKING_SUMMARY_URL, 'MyBiz_Header_Account' + '_Cancel_Bookings', false, ' Cancel Bookings')}
			{this.getProfileMenuListItem(PATHS.MMT_TRAVEL_REQUEST_PAGE, 'MyBiz_Header_Account' + '_Travel_Requests', true, 'Travel requests')}
			{this.getProfileMenuListItem(PATHS.MMT_TRAVEL_REQUEST_PAGE, 'MyBiz_Header_Account' + '_Pending_Approval', true, 'Pending Approval')}
		</div>
	)

	getProfileMenu = (isMobile) => (
		<ProfileOverlay className='dropdown-content'>
			<SwitchSection>
				<RightMenuList>
					{(!(this.state && this.state.secondRightMenu) && isMobile) ? this.getFirstLevelMobileMenu() : this.getUserProfileMenu()}
					<ProfileListMenuItem onClick={() => {
						this.logout(HCS_CONST.LOGOUT_CLICKED);
					}}>
						Logout
					</ProfileListMenuItem>
				</RightMenuList>
			</SwitchSection>
		</ProfileOverlay>)

	getProfileMenuListItem = (url, clickEvent, isMyBizLink, menuName) => <ProfileListMenuItem>
		<ProfileListMenuItemLink
			onClick={() => this.proceedToLink(url, clickEvent, isMyBizLink)}>
			{menuName}
		</ProfileListMenuItemLink>
	</ProfileListMenuItem>
}

const mapStateToProps = (store) => ({
	payload: (store.hcs && store.hcs.hcspayload) ? store.hcs.hcspayload : null
});
const mapDispatchToProps = (dispatch) => ({
	dispatch: dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
