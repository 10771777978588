import { PATHS } from 'AppConstants';
import { getAxiosConfig } from '../apisSetup/apiConfig';

const ccbAxiosApiCaller = getAxiosConfig(PATHS.CCB_URL, true, {
}, false);

export const getTRFDetails = (apiData) => {
	const { requisitionId, authCode } = apiData;
	const api = apiData.requisitionId ? PATHS.TRAVEL_REQUEST_DETAILS_API + requisitionId : PATHS.TRAVEL_REQUEST_DETAILS_API_AUTHCODE + authCode;
	return ccbAxiosApiCaller(api);
};
export const putApproveReject = (apiData) => {
	const { requisitionId, authCode, ...bodyData } = apiData;
	const api = apiData.requisitionId ? PATHS.APPR_REJ_DETAILS_API + requisitionId : PATHS.APPR_REJ_DETAILS_API_AUTHCODE + authCode;
	return ccbAxiosApiCaller.put(api, bodyData);
};

export const putMarkBooked = (apidata) => ccbAxiosApiCaller.put(PATHS.MARK_AS_BOOKED_API, apidata);

export const cancelRequestApi = (apidata) => ccbAxiosApiCaller.put(PATHS.CANCEL_TRAVEL_API, apidata);
