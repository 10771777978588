import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import {
	LabelText,
	BoxWrapperRow,
	InfoText
} from './InputStyles';

const TextNumberInput = ({
	value,
	onChange,
	label,
	required,
	inputProps = {},
	type = 'text',
	forwardedRef,
	disabled,
	message,
	...props
}) => (
	<BoxWrapperRow className='materialInput' {...props}>
		<input
			{...inputProps}
			type={type}
			value={value || ''}
			onChange={onChange}
			required
			ref={forwardedRef}
			disabled={disabled}
			className={value ? 'valid' : ''}
		/>
		{label && (
		<LabelText>
			{label}
			{required && (
			<span className='redText font14 latoBold'>*</span>
			)}
		</LabelText>
		)}
		{message && (
		<InfoText color={message.color}>{message.text}</InfoText>
		)}

	</BoxWrapperRow>
);

TextNumberInput.propTypes = {
	label: PropTypes.string,
	onChange: PropTypes.func,
	type: PropTypes.oneOf(['text', 'number'])
};

export default forwardRef( (props, ref) => <TextNumberInput {...props} forwardedRef={ref} />);
