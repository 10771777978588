import React, {
    useState
} from 'react';
import {Sort, SortDirection} from './SortListStyles';

const SortList = (props) => {
    const { reverse = false, label, isDisabled } = props;
    const [ reverseState, setReverse] = useState(reverse);

    const handleSortClick = () => {
        if(isDisabled) {
            return;
        }
        const isRev = !reverseState
        props.handleSort(isRev);
        setReverse(isRev);
    }
    return (
        <Sort onClick ={handleSortClick} isDisabled={isDisabled}>
            <span>{label}</span>
            <SortDirection reverse = {reverseState}/>
        </Sort>
    )
}

export default SortList