import Axios from 'axios';
import { getXrequestId } from 'UtilityComponents/Util';

export const getmybizAxiosConfig = (customHeaders) => {
	const requestId = typeof window == 'undefined' ? '' : getXrequestId && getXrequestId();
	let mybizConfig = {
		baseURL: '//mybiz.makemytrip.com',
		timeout: 30000,
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'X-Request-ID': requestId
		}
	};

	if (customHeaders) {
		mybizConfig['headers'] = { ...mybizConfig['headers'], ...customHeaders };
	}
	return Axios.create(mybizConfig);
};
