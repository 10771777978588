import { FlexDiv, GenericP, GenericSpan } from 'commonStyles';
import {
	INVOICE_CONSTANTS,
	WALLET_CONSTANTS,
	APPLICATION_CONSTANTS,
	FILTER_CONSTANTS
} from '../AppConstants';
import {
	formatCurrency, formatAmount, validAmount, validDays
} from '../UtilityComponents/Util';

import React from 'react';
import { DotIcon, SpriteIcon } from './WalletStyles';

export const textsConfig = {
	onBoarding: {
		balance: {
			heading: 'Wallet Summary',
			text: 'View your wallet balance here . For making quick payments, our customers keep sufficient wallet balance.'
		},
		recharge: {
			heading: 'Wallet Recharge',
			text: 'Recharge your wallet through our payment gateway or via bank transfer to your unique virtual account number.'
		},
		list: {
			heading: 'Wallet Transactions Summary',
			text: 'View all your wallet transactions here or download the report in excel format.'
		},
		reminder: {
			heading: 'Low Balance Reminders',
			text: 'Set the amount limit'
		},
		help: {
			heading: 'Refer to Help Section',
			text: 'Revisit the benefits and onboarding process of myBiz Wallet from the Help section here.'
		},
		getStarted: {
			heading: 'You can always access help section from here',
			text: ''
		}
	},
	REMINDER_RADIO_TEXT: {
		activeTitle: 'Reminder Enabled - ',
		defaultTitle: 'Enable Reminder - '
	},
	REMINDER_SETTING: 'Reminder Settings',
	REMINDER_AMOUNT: 'wallet balance is below ',
	REMINDER_FREQUECNY: ' Remind me ',
	REMINDER_DAYS: 'wallet balance is insufficient for ',
	FILTER_QUERY_FAILED: 'Try removing some filters to see more results',
	INIT_QUERY_FAILED: 'Your wallet transactions will be shown here once you make booking payement via wallet.',
	getStarted: {
		HEADING: 'Get Started with myBiz Wallet',
		SUBHEADING: 'Quick and easy way to manage who has access to your corporate travel account.',
		BENEFITS: [{
			title: 'Quick Payments',
			description: 'Skip the long process of adding debit/credit card information every time.',
			image: 'card'
		},
		{
			title: 'Real-time Reportage',
			description: 'View or download summary of all wallet transactions with ease.',
			image: 'reconcile'
		},
		{
			title: 'Recharge Reminders',
			description: 'Set reminders to receive notifications whenever your wallet needs recharge.',
			image: 'reminder'
		}
		]
	},
	RECHARGE_SUCCESS_TEXT: 'Recharge successful, amount has been credited to your wallet.',
	ACCOUNT_DETAILS: {
		MAIL_SENT_TXT: 'Mail Send Successfully',
		COPIED_CTA_TEXT: 'Copied to clipboard',
		MAIL_FAIL_TXT: 'Mail Send Failed',
		HEADING: 'Recharge Via Bank Transfer',
		SUB_HEADING: 'Transfer the recharge amount to the virtual account assigned uniquely to you and your wallet will reflect the recharge within 3 hours.',
		ACCOUNT_CTA: 'Copy Account Details',
		MAIL_CTA: 'Self Email',
		COPIED_TEXT: 'The virtual account number has been uniquely assigned to your organisation. Transfer the recharge amount for a hassle-free/ single-step transaction.'
	},
	REWARD_FILTER_QUERY_FAILED: 'Try changing filters to see more results',
	NO_REWARDS_FOUND: 'No Rewards found',
	AVAILABLE_REWARDS_BALANCE: '- Available Rewards Balance',
	REWARD_INIT_QUERY_FAILED: 'Your Expenses will be shown here once they are available for selected Criterion.'
};
export const dateConfig = {

};

export const getBalanceCardData = (balanceDet, walletReminderExp, reminderSettings) => {
	if (!balanceDet) {
		return {
			error: true,
			type: 'apierror'
		};
	}
	let {
		daysLeft,
		rate,
		amount,
		minDays,
		defMinDays,
		walletStatus,
		minAmount,
		defMinAmount
	} = balanceDet;
	switch (walletStatus) {
		case 'LOOKS_GOOD':
			return {
				type: 'good',
				title: 'Available Balance:',
				tag: 'LOOKS GOOD',
				walletIcon: 'greenWallet',
				amount: amount,
				showAsterik: walletReminderExp && rate > 0,
				asterisk: {
					text: `Sufficient for <b>${daysLeft} Days of Booking</b>`,
					tooltip: {
						title: 'Based on your average daily wallet spend ( Run Rate )',
						text1: `Your Run rate: ${APPLICATION_CONSTANTS.RUPEE} ${formatCurrency(rate)}`,
						text2: `Days you can book with this run rate: ${formatCurrency(amount, true)} / ${formatCurrency(rate)} = ${daysLeft} Days`
					}
				}
			};
			break;
		case 'ZERO_BALANCE':
			const showMinAmt = defMinAmount > minAmount ? defMinAmount : minAmount;
			return {
				type: 'empty',
				title: 'Available Balance:',
				walletIcon: 'orangeWallet',
				amount: 0,
				showAsterik: walletReminderExp,
				asterisk: {
					text: `A recharge of ${formatCurrency(showMinAmt, true)} would be a good start `
				}
			};
		case 'RECHARGE_NEEDED':
			const showMinDays = (reminderSettings && reminderSettings.daysLeft) || defMinDays;
			return {
				type: 'low',
				title: 'Low Balance:',
				walletIcon: 'yellowWallet',
				tag: 'Recharge Needed',
				amount: amount,
				showAsterik: walletReminderExp && rate > 0,
				asterisk: {
					text: `Not Sufficient for next ${showMinDays} Days of Booking `,
					tooltip: rate > 0 ? {
						title: 'Based on your average daily wallet spend ( Run Rate )',
						text1: `Your Run rate: ${APPLICATION_CONSTANTS.RUPEE} ${formatCurrency(rate)}`,
						text2: `Days you can book with this run rate: ${formatCurrency(amount, true)} / ${formatCurrency(rate)} = ${daysLeft} Days`
					} : false
				}
			};
		default:
			return {

			};
	}
};

export const RECHARGETEXT = {
	title: 'Add Money To Wallet',
	ctaText: 'Recharge Now',
	transferDtls: {
		title: 'Recharge Via Bank Transfer',
		showNewTag: true,
		subtitle: 'Wallet gets automatically recharged within 3hrs of bank transfer.',
		cta: 'View Account Details'
	},
	asterisk: 'For seamless booking, most of our customers keep adequate balance in wallet'

};

export const headerConfig = {
	list: [{
		text: 'UNIQUE ID'
	}, {
		text: 'Booked By/Added By'
	}, {
		text: 'TYPE'
	}, {
		text: 'TXN. DATE',
		trackVal: 'txndate',
		key: WALLET_CONSTANTS.TRXN_SORT
	}, {
		text: 'AMOUNT'
	}, {
		text: 'REASON'
	}]
};

export const cashbackHeaderConfig = {
	list: [{
		text: 'AMOUNT'
	}, {
		text: 'Unique ID'
	}, {
		text: 'TYPE'
	}, {
		text: 'TXN. DATE',
		isSort: true,
		trackVal: 'txndate',
		key: WALLET_CONSTANTS.TRXN_SORT
	}, {
		text: 'AMOUNT'
	}, {
		text: 'REASON'
	}]
};

export const FILTERS_COUNTS_CONFIG = {
	[WALLET_CONSTANTS.FLIGHT_TYPE]: WALLET_CONSTANTS.FLIGHT_TYPE,
	[WALLET_CONSTANTS.FLIGHT_TYPE]: WALLET_CONSTANTS.FLIGHT_TYPE,
	[WALLET_CONSTANTS.HOTEL_TYPE]: WALLET_CONSTANTS.HOTEL_TYPE,
	[WALLET_CONSTANTS.MISC_TYPE]: WALLET_CONSTANTS.MISC_TYPE,
	[WALLET_CONSTANTS.CABS_TYPE]: WALLET_CONSTANTS.CABS_TYPE,
	[WALLET_CONSTANTS.BUS_TYPE]: WALLET_CONSTANTS.BUS_TYPE,
	[WALLET_CONSTANTS.TRAIN_TYPE]: WALLET_CONSTANTS.TRAIN_TYPE,
	[WALLET_CONSTANTS.DEBIT]: WALLET_CONSTANTS.DEBIT_COUNT,
	[WALLET_CONSTANTS.CREDIT]: WALLET_CONSTANTS.CREDIT_COUNT,
	[WALLET_CONSTANTS.E_COLLECTION]: WALLET_CONSTANTS.E_COLLECTION_COUNT,
	[WALLET_CONSTANTS.ONLINE]: WALLET_CONSTANTS.ONLINE_COUNT,
	[WALLET_CONSTANTS.OFFLINE]: WALLET_CONSTANTS.OFFLINE_COUNT
};
export const FILTERS_VALUES = {
	'lob': [WALLET_CONSTANTS.FLIGHT_TYPE, WALLET_CONSTANTS.HOTEL_TYPE, WALLET_CONSTANTS.MISC_TYPE, WALLET_CONSTANTS.CABS_TYPE, WALLET_CONSTANTS.BUS_TYPE],
	'topup': [WALLET_CONSTANTS.ONLINE_COUNT, WALLET_CONSTANTS.E_COLLECTION_COUNT, WALLET_CONSTANTS.OFFLINE_COUNT],
	'txnType': [WALLET_CONSTANTS.DEBIT_COUNT, WALLET_CONSTANTS.CREDIT_COUNT]
};

export const defaultCountConfig = {
	[WALLET_CONSTANTS.FLIGHT_TYPE]: 0,
	[WALLET_CONSTANTS.HOTEL_TYPE]: 0,
	[WALLET_CONSTANTS.MISC_TYPE]: 0,
	[WALLET_CONSTANTS.CABS_TYPE]: 0,
	[WALLET_CONSTANTS.BUS_TYPE]: 0,
	[WALLET_CONSTANTS.TRAIN_TYPE]: 0,
	[WALLET_CONSTANTS.DEBIT_COUNT]: 0,
	[WALLET_CONSTANTS.CREDIT_COUNT]: 0,
	[WALLET_CONSTANTS.E_COLLECTION_COUNT]: 0,
	[WALLET_CONSTANTS.ONLINE_COUNT]: 0,
	[WALLET_CONSTANTS.OFFLINE_COUNT]: 0
};

export const filters = {
	initCount: {},
	tripTags: {
		isGrouped: true,
		label: 'Trip Tags',
		type: FILTER_CONSTANTS.DROPDOWN,
		key: INVOICE_CONSTANTS.TRIP_TAG_FILTER,
		isMulti: true,
		hideSelOptions: false,
		value: [],
		options: [],
		trackVal: 'triptag',
		selectedCount: 0
	},
	dateRange: {
		label: 'Transaction Date',
		type: FILTER_CONSTANTS.CHECKBOX,
		startkey: 'startDate',
		endKey: 'endDate',
		value: '',
		trackVal: 'date',
		selectedCount: 0,
		options: [{
			alwaysEnable: true,
			label: 'Enter Dates',
			type: FILTER_CONSTANTS.CUSTOM_DATE_FILTER,
			value: '',
			trackVal: 'date_custom',
			checked: false,
			key: FILTER_CONSTANTS.DATE_FILTER,
			filterKey: 'dateRange',
			disabledDays: {
				before: WALLET_CONSTANTS.DISABLE_CALENDER_BEFORE,
				after: APPLICATION_CONSTANTS.TODAY
			}
		}

		]
	},
	orderType: {
		label: 'Trip Type',
		type: FILTER_CONSTANTS.CHECKBOX,
		value: '',
		key: 'lob',
		showClear: false,
		trackVal: 'triptype',
		selectedCount: 0,
		options: [{
			value: WALLET_CONSTANTS.FLIGHT_TYPE,
			countKey: WALLET_CONSTANTS.FLIGHT_TYPE,
			label: 'Flights',
			count: '',
			filterKey: 'orderType',
			index: 0,
			trackVal: 'triptype_flight'
		},
		{
			value: WALLET_CONSTANTS.HOTEL_TYPE,
			countKey: WALLET_CONSTANTS.HOTEL_TYPE,
			label: 'Hotels',
			count: '',
			filterKey: 'orderType',
			trackVal: 'triptype_hotel',
			index: 1
		},
		{
			value: WALLET_CONSTANTS.CABS_TYPE,
			countKey: WALLET_CONSTANTS.CABS_TYPE,
			label: 'Cabs',
			count: '',
			trackVal: 'triptype_cab',
			filterKey: 'orderType',
			index: 2
		},
		{
			value: WALLET_CONSTANTS.BUS_TYPE,
			countKey: WALLET_CONSTANTS.BUS_TYPE,
			label: 'Bus',
			count: '',
			trackVal: 'triptype_bus',
			filterKey: 'orderType',
			index: 3
		},
		{
			value: WALLET_CONSTANTS.TRAIN_TYPE,
			countKey: WALLET_CONSTANTS.TRAIN_TYPE,
			label: 'Train',
			count: '',
			trackVal: 'triptype_train',
			filterKey: 'orderType',
			index: 4
		}

		]
	},
	transType: {
		label: 'Transaction Type',
		type: FILTER_CONSTANTS.CHECKBOX,
		value: '',
		key: 'txnType',
		trackVal: 'txntype',
		showClear: false,
		selectedCount: 0,
		options: [{
			value: WALLET_CONSTANTS.DEBIT,
			countKey: WALLET_CONSTANTS.DEBIT_COUNT,
			label: 'Debit',
			count: '',
			filterKey: 'transType',
			index: 0,
			trackVal: 'txntype_debit'
		},
		{
			value: WALLET_CONSTANTS.CREDIT,
			countKey: WALLET_CONSTANTS.CREDIT_COUNT,
			label: 'Credit',
			count: '',
			filterKey: 'transType',
			trackVal: 'txntype_credit',
			index: 1
		}

		]
	},
	paymentMethod: {
		label: 'Recharge Method',
		type: FILTER_CONSTANTS.CHECKBOX,
		value: '',
		showClear: false,
		key: 'topup',
		trackVal: 'topupmethod',
		selectedCount: 0,
		options: [{
			value: WALLET_CONSTANTS.ONLINE,
			countKey: WALLET_CONSTANTS.ONLINE_COUNT,
			showCount: false,
			label: 'myBiz Payment Gateway',
			filterKey: 'paymentMethod',
			trackVal: 'topupmethod_online',
			count: '',
			index: 0
		},
		{
			value: WALLET_CONSTANTS.E_COLLECTION,
			countKey: WALLET_CONSTANTS.E_COLLECTION_COUNT,
			label: 'Bank Transfer to Virtual Account',
			showCount: false,
			filterKey: 'paymentMethod',
			trackVal: 'topupmethod_banktransfer',
			count: '',
			index: 1
		},
		{
			value: WALLET_CONSTANTS.OFFLINE,
			countKey: WALLET_CONSTANTS.OFFLINE_COUNT,
			label: 'Others',
			showCount: false,
			filterKey: 'paymentMethod',
			trackVal: 'topupmethod_others',
			count: '',
			index: 2
		}

		]
	}
};

export const defaultSubHeadeing = 'Last month till date';

export const reminderModalDtls = {
	title: 'Reminder Settings',
	desc: 'You will be notified through email whenever the wallet balance falls below specified amount.',
	details: [{
		isMulti: false,
		value: '',
		name: 'amount_limit',
		subtitle: 'Min amount below which you need to be notified',
		fieldLabel: 'Amount limit',
		key: 'amount_limit',
		type: 'text',
		formatVal: formatAmount,
		trackVal: 'reminder_settings_amount',
		validation: {
			required: {
				msg: 'Required!'
			},
			custom: {
				validator: validAmount,
				msg: 'Enter Valid amount'
			}
		},
		errorOnBlur: true,
		className: 'rem-amount',
		isMandatory: true
	},
	{
		isMulti: false,
		value: '',
		errorOnBlur: true,
		name: 'days_left',
		scrollIntoView: true,
		fieldLabel: 'Days left',
		key: 'days_left',
		type: 'number',
		subtitle: 'Min no. of days your balance can suffice below which you need to be notified',
		trackVal: 'reminder_settings_days',
		validation: {
			custom: {
				validator: validDays,
				msg: 'Max 365 days is allowed'
			}
		}
	}
	],
	cta: {
		text: 'Save & Add Reminder',
		type: 'Primary'
	}
};

export const HELP_TEXT = {
	why: 'Why should I use myBiz Wallet?',
	how: 'How to use myBiz Wallet?'
};

export const walletBenefits = [
	{
		text: 'Instant Refunds',
		icon: 'instantRefund'
	},
	{
		text: 'Real-Time Reports On Expenditure',
		icon: 'reports'
	},
	{
		text: 'One-Click Payment',
		icon: 'oneClick'
	}
];

export const rechargeValues = {
	name: 'recharge',
	label: 'Recharge Amount (₹)',
	validation: {
		required: {
			msg: 'Required!'
		}
	},
	value: '50,000',
	activeIndex: 1,
	options: [
		{
			label: {
				labelHead: '₹ 10,000'
			},
			value: '10,000'
		},
		{
			label: {
				labelHead: '₹ 50,000'
			},
			value: '50,000'
		},
		{
			label: {
				labelHead: '₹ 1,00,000'
			},
			value: '1,00,000'
		},
		{
			label: {
				labelHead: '₹ 5,00,000'
			},
			value: '5,00,000'
		}
	],
	type: 'tab',
	isMandatory: true,
	mmtTheme: true
};

export const walletInfoList = [
	'Select your hotel/flight/cab etc',
	'Fill required details',
	'Select myBiz wallet as your payment mode.'
];

export const faqs = [
	{
		ques: 'Q. Does wallet money has any expiry date ?',
		ans: 'A. No, the money in myBiz wallet has no expiry date.'
	},
	{
		ques: 'Q. Can I take my money out at any point of time ?',
		ans: 'A. Yes, you can raise request with our customer support.'
	},
	{
		ques: 'Q. Is my money in myBiz wallet secure ?',
		ans: 'A. Yes , a new user needs to verify via OTP.'
	}
];

export const RechargeOverlayModaldata = {
	title: 'Recharge Online',
	subTitle: 'You will be redirected to our payment page to proceed with the recharge.',
	img: null,
	imgstyle: null
};

export const WALLET_OVERLAY_CONST = {
	CPY_ACC_DETAILS: 'copyAccountDetails',
	RCHRG_ONLINE: 'rechargeOnline'
};

export const TABLE_PROPS = {
	container: {
		classes: { root: 'report_table' }
	},
	table: {
		stickyHeader: true
	},
	bodyRowChild: {
		classes: {
			root: 'report_table'
		}
	}
};

const cellProps = (customConfig = {}) => {
	const { customBodyClasses = {},
		customHeadClass = {},
		customCellProps = {} } = customConfig;
	return ({
		bodyCellProps: {
			size: 'medium',
			padding: '16px 9px',
			classes: {
				root: 'wallet_cell',
				...customBodyClasses
			},
			...customCellProps
		},
		headerCellProps: {
			size: 'medium',
			padding: '16px 9px',
			classes: {
				root: 'wallet_cell wallet_cell_head',
				...customHeadClass
			},
			...customCellProps
		}
	});
};

export const walletColumns = [
	{
		header: 'AMOUNT',
		accessor: 'txnAmount',
		formatter: (value)=><b>{value}</b>,
		...(cellProps({ customCellProps: { width: '130px', padding: '12px 9px' } }))
	},
	{
		header: 'Unique ID',
		accessor: 'uniqueId',

		formatter: (value)=>(
			<FlexDiv align='center'>
				<SpriteIcon icon={value.lob} right_M='10' />
				<div>
					<GenericP fsize='14px'>{value.bookingId}</GenericP>
					<GenericP color='#4a4a4a' fsize='12px'>{value.reason}</GenericP>
				</div>
			</FlexDiv>),
		...(cellProps({ customCellProps: { width: '130px', padding: '12px 9px' } }))
	},
	{
		header: 'EMAIL',
		accessor: 'emailId',

		formatter: (value)=>value,
		...(cellProps({ customCellProps: { width: '130px', padding: '12px 9px' } }))
	},
	{
		header: 'TYPE',
		accessor: 'type',

		formatter: (value)=><GenericP><DotIcon $bg={value.dotColor} /><GenericSpan transform='capitalize' left_M='3'>{value.type}</GenericSpan></GenericP>,
		...(cellProps({ customCellProps: { width: '130px', padding: '12px 9px' } }))
	},
	{
		header: 'TX DATE',
		accessor: 'txnDate',
		isSort: true,
		formatter: (value)=>value,
		...(cellProps({ customCellProps: { width: '130px', padding: '12px 9px' } }))
	},
	{
		header: 'EXPIRING ON',
		accessor: 'expiry',

		formatter: (value)=><GenericP color='#A5572A'>{value}</GenericP>,
		...(cellProps({ customCellProps: { width: '130px', padding: '12px 9px' } }))
	}
];

export const bonusFilterConfig = [{
	label: 'Flight',
	value: WALLET_CONSTANTS.FLIGHT_TYPE
},
{
	label: 'Hotel',
	value: WALLET_CONSTANTS.HOTEL_TYPE
},
{
	label: 'Cab',
	value: WALLET_CONSTANTS.CABS_TYPE
},
{
	label: 'Bus',
	value: WALLET_CONSTANTS.BUS_TYPE
},
{
	label: 'Train',
	value: WALLET_CONSTANTS.TRAIN_TYPE
}];

export const cashBacklobFilter = {
	isMulti: true,
	options: bonusFilterConfig,
	label: 'Booking Type'
};

export const cashBackTextConfig = {
	EXPIRY_TEXT: 'Expiring on',
	TITLE: 'Rewards & Cashback'
};
