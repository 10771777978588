if (!global._babelPolyfill) {
	require('@babel/polyfill');
}

import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';

import App from './App';
import configureStore from './redux/store';
import { urlParam, getCookie, getOrgId, getProfileDataProps, shouldForwardProp } from 'UtilityComponents/Util';
import { trackerPubSub, OMNI_EVENT } from './logging/omnitureService';
import { APPLICATION_PATH, V2_APPLICATION_PATH } from 'AppConstants';
import { StyleSheetManager } from 'styled-components';

const preloadedState = window.__PRELOADED_STATE__;
const { store, persistor } = configureStore(preloadedState);

delete window.__PRELOADED_STATE__;

trackerPubSub.subscribe(OMNI_EVENT, () => {
	if (typeof window !== 'undefined' && typeof window.s != 'undefined') {
		let s = window.s;
		let isOmni = false;
		const urlPath = window.location.pathname;
		let isReportDetailPage = urlPath.includes(APPLICATION_PATH + '/reportsDetail');
		const url = isReportDetailPage ? APPLICATION_PATH + '/reportsDetail' : urlPath;
		switch (url) {
			case APPLICATION_PATH + '/emailverification':
				isOmni = true;
				s.pageName = 'mybusinessadmin:email_verification:landing_new_v2|corporate';
				document.title = 'Email verification';
				break;
			case APPLICATION_PATH + '/forgotPassword':
				isOmni = true;
				s.pageName = 'mybusinessadmin:forgot_password:landing_new_v2|corporate';
				document.title = 'Forgot password';
				break;
			case APPLICATION_PATH + '/userlogin':
				isOmni = true;
				s.pageName = 'mybusinessadmin:loginPage:landing_new_v2|corporate';
				document.title = 'Corporate Login Page, Admin Login';
				break;
			case APPLICATION_PATH + '/ssoLogin': {
				isOmni = true;
				s.pageName = 'mybusinessadmin:sso_login:landing_new_v2|corporate';
				break;
			}
			case APPLICATION_PATH + '/tripTag':
			case APPLICATION_PATH + '/tripTag/new': {
				isOmni = true;
				s.pageName = 'mybusinessadmin:triptags:landing_new_v3|corporate';
			}
				break;
			case APPLICATION_PATH + '/referCompanyAdmin':
				const isAdmin = urlParam('isAdmin');
				const showAdmin = isAdmin && isAdmin === 'true';
				isOmni = true;
				if (showAdmin) {
					s.pageName = 'mybiz:become_admin_page';
				} else {
					s.pageName = 'mybiz:refer_admin_page';
				}
				break;
			case APPLICATION_PATH + '/invoice': {
				isOmni = true;
				s.pageName = 'mybusinessadmin:invoices:landing_new_v3|corporate';
			}
				break;
			case APPLICATION_PATH + '/policy': {
				isOmni = true;
				s.pageName = 'mybusinessadmin:policies:landing_new_v3|corporate';
			}
				break;
			case APPLICATION_PATH + '/reports': {
				isOmni = true;
				s.pageName = 'mybusinessadmin:reports:landing_new_v3|corporate';
			}
				break;
			case APPLICATION_PATH + `/reportsDetail`: {
				isOmni = true;
				s.pageName = `mybusinessadmin:${urlPath}:landing_new_v3|corporate`;
			}
				break;
		}
		if (isOmni ) {
			s.eVar1 = navigator.userAgent;
			const mmtUuid = getProfileDataProps('mmtUuid');
			s.eVar34 = mmtUuid;
			const orgId = getOrgId();
			const mmtAuth = getCookie('mmt-auth');

			if (mmtAuth) {
				const isAdmin = getProfileDataProps('isAdmin');
				s.eVar20 = mmtUuid;
				s.prop62 = `${orgId}||${isAdmin ? 'y' : 'n'}`;
				s.prop24 = 'mybiz admin';
			}
			let sCode = s.t();
			if (sCode) document.write(sCode);
		}
	}
});

window.onload = () => {
	Loadable.preloadReady().then(() => {
		hydrate(
			<BrowserRouter basename={V2_APPLICATION_PATH}>
				<Provider store={store}>
					<PersistGate
						loading={null}
						persistor={persistor}>
						<StyleSheetManager shouldForwardProp={shouldForwardProp}>
							<App />
						</StyleSheetManager>
					</PersistGate>
				</Provider>
			</BrowserRouter>,
			document.querySelector('#root')
		);
	});
};
