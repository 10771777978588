import React from 'react';
import { FrequencyTab } from './ReminderFrequencyStyles';
import { GenericDiv, FlexDiv } from 'commonStyles';
import { SelectBox, RadioButtonGroup } from 'common/FormField/formField';
import { WeekDayfield, MonthField, DayField, DailyField, WeekField } from './frequencyConfig';

export const MonthlyData = ({ onChange }) => (
	<FrequencyTab>
		<FlexDiv fsize='14' bottom_M='15' align='center'>
			<p className='leftTag'>Every:</p>
			<GenericDiv fsize='14' className='dropdownWrapper freq-dropdown'>
				<SelectBox noScroll onChange={onChange(MonthField)} bottom_M='0' field={MonthField} />
			</GenericDiv>
			<p className='rightTag'>Month(s)</p>
		</FlexDiv>
		<FlexDiv fsize='14' align='center' justify='flex-start'>
			<p className='leftTag'>On Day:</p>
			<GenericDiv fsize='14' className='dropdownWrapper freq-dropdown'>
				<SelectBox noScroll onChange={onChange(DayField)} bottom_M='0' field={DayField} />
			</GenericDiv>
		</FlexDiv>
	</FrequencyTab>
);

export const WeeklyData = ({ onChange }) => (
	<FrequencyTab>
		<FlexDiv fsize='14' bottom_M='15' align='center'>
			<p className='leftTag'>Every:</p>
			<GenericDiv fsize='14' className='dropdownWrapper freq-dropdown'>
				<SelectBox noScroll onChange={onChange(WeekDayfield)} bottom_M='0' field={WeekDayfield} />
			</GenericDiv>
			<p className='rightTag'>Week(s)</p>
		</FlexDiv>
		<FlexDiv fsize='14' className='weeks'>
			<RadioButtonGroup onChange={onChange(WeekField)} field={WeekField} />

		</FlexDiv>
	</FrequencyTab>
);

export const DailyData = ({ onChange }) => (
	<FrequencyTab>
		<FlexDiv fsize='14' bottom_M='15' align='center'>
			<p className='leftTag'>Every:</p>
			<GenericDiv fsize='14' className='dropdownWrapper freq-dropdown'>
				<SelectBox noScroll onChange={onChange(DailyField)} bottom_M='0' field={DailyField} />
			</GenericDiv>
			<p className='rightTag'>Day(s)</p>
		</FlexDiv>
	</FrequencyTab>
);

const GranularData = ({ mainField, onChange }) => {
	const handleChange = (field)=>(value) => {
		onChange(field);
	};
	switch (mainField.value.value) {
		case 'WEEKLY':
			mainField.subFields = [WeekDayfield, WeekField];
			return <WeeklyData onChange={handleChange} />;
		case 'MONTHLY':
			mainField.subFields = [MonthField, DayField];
			return <MonthlyData onChange={handleChange} />;
		case 'DAILY':
			mainField.subFields = [DailyField];
			return <DailyData onChange={handleChange} />;
		default:
			mainField.subFields = [DailyField];
			return <DailyData onChange={handleChange} />;
	}
};

export default GranularData;
