import styled from "styled-components";

export const AccntText = styled.span`
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    margin-right: 16px;
    @media (max-width: 700px) {
    display:none;
    };
    letter-spacing: 0.4px;`;

export const SignUpBtn = styled.a`
    background: #fff;
    width: 115px;
    color: #f0772d;
    display: inline-block;
    font-size: 14px;
    font-family: 'lato-bold', Arial, Helvetica, sans-serif;
    border-radius: 40px;
    text-align: center;
    line-height: 14px;
    text-decoration: none !important;
    @media (max-width: 700px) {
    width: 75px;
    font-size: 12px;
    };
    padding: 12px 0px 10px;`;
