import styled, { keyframes, css } from 'styled-components';
import { FlexDiv, GenericP } from '../Styles';

export const openAnim = keyframes`
	0% {width:102px;}
	100% {width:308px;}
`;

export const closeAninm = keyframes`
	0% {width:308px;}
	100% {width:102px;}
`;

export const Rectangle = styled(FlexDiv)`
	${() => css`animation: ${closeAninm} 0.5s ease-in-out forwards ;`}
	&:hover {
		${() => css`animation: ${openAnim} 0.5s ease-in-out forwards ;`}
		>div, i{
			overflow: visible;
			opacity: 1;
		}
	}
	${({ dock }) => !dock && css`animation: ${openAnim} 0.5s ease-in-out forwards ;`}
	${({ dock }) => !dock && `
		>div, i{
			overflow: visible;
			opacity: 1;
		}
	`}
	${({ theme: { down, breakpoints } }) =>`
	width: 102px;
	height: 102px;
	padding: 15px 16px 15px 15px;
	border-radius: 50px 50px 0px;
	box-shadow: 0 3px 22px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px rgba(241, 117, 49, 0.3);
	background-color: #ffffff;
	position: fixed;
	right: 20px;
	bottom: 50px;
	overflow: hidden;
	cursor: pointer;
	z-index: 6;
	${() => css`animation: ${closeAninm} 0.5s ease-in-out forwards ;`}
	>div, i{
		overflow: hidden;
		transition: opacity 500ms ease-in-out;
	}
	i {
		opacity: 0;
	}

	
	${down(breakpoints.md)} {
		animation: none;
		border-radius: 29px;
		padding: 4px;
		background-color: #FF664B;
		bottom: 90px;
		z-index: 5;
		height: 56px;
    width: 130px;
		>div, i{
			overflow: visible;
			opacity: 1;
		}
		&:hover {
			animation: none;
		}
	 }
	
	`}`;

export const ChatIcon = styled.img(
	({ theme: { down, breakpoints } }) => `
		width: 72px;
		height: 72px;
		object-fit: contain;
		position: absolute;
		left: 15px;
		${down(breakpoints.md)} {
			width: 48px;
			height: 48px;
			left: 3px;
			top: 3px;
		 }
`
);

export const HeadText = styled(GenericP)(
	({ theme: { down, breakpoints } }) =>`
	width: 90px;
	height: 22px;
	margin: 5px 76px 4px 15px;
	font-size: 18px;
	font-weight: bold;
	color: #202e4a;
	${down(breakpoints.md)} {
		font-size: 12px;
		color: #fff;
		font-weight: 500;
	 }
`
);

export const SubHead = styled(GenericP) `
	width: 170px;
	height: 34px;
	margin: 4px 0px 7px 15px;
	font-size: 14px;
	color: #4a4a4a;
`;

export const ContentWraper = styled(FlexDiv)(
	({ theme: { down, breakpoints } }) =>`
	position: absolute;
	left: 85px;
	${down(breakpoints.md)} {
		left: 70px;
		top: 15px;
	 }
`
);
